import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: Date,
  /** 
 * A field whose value conforms to the standard E.164 format as specified in:
   * https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234.
 */
  PhoneNumber: string,
  /** Return the formatted time string in hh:mm:ss 24-hour format with leading 0. https://date-fns.org/v2.8.1/docs/formatISO */
  Time: any,
  Upload: any,
  /** 
 * A field whose value conforms to the standard internet email address format as
   * specified in RFC822: https://www.w3.org/Protocols/rfc822/.
 */
  EmailAddress: any,
};

export type ActivateDormantUserPayload = {
   __typename?: 'ActivateDormantUserPayload',
  user?: Maybe<User>,
};

export type ActiveUserPayload = {
   __typename?: 'ActiveUserPayload',
  user?: Maybe<User>,
};

export type AdminAllianceUserUpsert = {
  schoolName: Scalars['String'],
  major?: Maybe<Scalars['String']>,
  grade: AllianceUserGrade,
  schoolType: AllianceUserSchoolType,
  schoolId: Scalars['Int'],
};

export type AdminBlogPost = {
   __typename?: 'AdminBlogPost',
  id: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  url: Scalars['String'],
  createdAt: Scalars['Date'],
};

export type AdminBlogPostConnection = {
   __typename?: 'AdminBlogPostConnection',
  nodes: Array<AdminBlogPost>,
  nextCursor?: Maybe<Scalars['String']>,
};

export type AdminUserInfoUpdate = {
  phoneNumber?: Maybe<Scalars['PhoneNumber']>,
  email?: Maybe<Scalars['EmailAddress']>,
  emailReceipt?: Maybe<Scalars['Boolean']>,
  smsReceipt?: Maybe<Scalars['Boolean']>,
  adminAllianceUserUpsert?: Maybe<AdminAllianceUserUpsert>,
};

export type Alliance = {
   __typename?: 'Alliance',
  id?: Maybe<Scalars['ID']>,
  allianceName?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  expiredAt: Scalars['Date'],
  prefixUserName?: Maybe<Scalars['String']>,
  templatePath?: Maybe<Scalars['String']>,
  domain?: Maybe<Scalars['String']>,
  siteUrl?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export type AllianceConnection = {
   __typename?: 'AllianceConnection',
  nodes: Array<Maybe<Alliance>>,
  totalCount: Scalars['Int'],
};

export type AllianceCreateInput = {
  allianceName: Scalars['String'],
  expiredAt: Scalars['Date'],
  prefixUserName: Scalars['String'],
  domain: Scalars['String'],
  templatePath: Scalars['String'],
  token?: Maybe<Scalars['String']>,
};

export type AllianceCreatePayload = {
   __typename?: 'AllianceCreatePayload',
  alliance?: Maybe<Alliance>,
};

export type AllianceFilters = {
  allianceName?: Maybe<Scalars['String']>,
  expiredAt?: Maybe<Scalars['Date']>,
};

export type AllianceRegisterInput = {
  userName: Scalars['String'],
  password: Scalars['String'],
  name: Scalars['String'],
  email: Scalars['String'],
  phoneNumber: Scalars['String'],
  exam: Array<ExamToTake>,
  examSubjects: Array<Scalars['ID']>,
  service?: Maybe<ServiceType>,
  emailReceipt: Scalars['Boolean'],
  smsReceipt: Scalars['Boolean'],
  joinRoute: JoinRoute,
  authSMSInput: AuthSmsInput,
  schoolId: Scalars['Int'],
  major: Scalars['String'],
  grade: AllianceUserGrade,
  studentId: Scalars['String'],
};

export type AllianceRegisterPayload = {
   __typename?: 'AllianceRegisterPayload',
  user: User,
};

export type AllianceUser = {
   __typename?: 'AllianceUser',
  id: Scalars['ID'],
  /** 제휴대학 */
  alliance?: Maybe<Alliance>,
  /** 학교ID */
  schoolId: Scalars['Int'],
  /** 대학교이름 */
  schoolName: Scalars['String'],
  user: User,
  /** 전공 */
  major?: Maybe<Scalars['String']>,
  /** 학년 */
  grade: AllianceUserGrade,
  /** 상태(재학, 중퇴, 졸업) */
  status: AllianceUserStatus,
  /** 학교 구분 */
  schoolType?: Maybe<AllianceUserSchoolType>,
  /** 대학교 학생의 고유 ID */
  orgUserId?: Maybe<Scalars['String']>,
  /** 학번 */
  studentId?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  duplicatedUser?: Maybe<DuplicatedUser>,
};

export type AllianceUserConnection = {
   __typename?: 'AllianceUserConnection',
  nodes: Array<AllianceUser>,
  totalCount: Scalars['Int'],
};

export type AllianceUserContentHistory = {
   __typename?: 'AllianceUserContentHistory',
  id: Scalars['String'],
  name: Scalars['String'],
  major: Scalars['String'],
  grade: Scalars['String'],
  lastLogin: Scalars['Date'],
  contentName: Scalars['String'],
  createdAt: Scalars['Date'],
  serviceType: Scalars['String'],
  contentType: Scalars['String'],
  contentCategoryType: Scalars['String'],
  contentPrice: Scalars['Int'],
};

export type AllianceUserContentHistoryConnection = {
   __typename?: 'AllianceUserContentHistoryConnection',
  nodes: Array<Maybe<AllianceUserContentHistory>>,
};

export type AllianceUserContentHistoryFilters = {
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  allianceName: Scalars['String'],
};

export type AllianceUserCreateInput = {
  schoolId: Scalars['Int'],
  userID: Scalars['ID'],
  schoolName: Scalars['String'],
  major?: Maybe<Scalars['String']>,
  grade: AllianceUserGrade,
  schoolType: AllianceUserSchoolType,
};

export type AllianceUserCreatePayload = {
   __typename?: 'AllianceUserCreatePayload',
  allianceUser?: Maybe<AllianceUser>,
};

export type AllianceUserFilters = {
  universityId?: Maybe<Scalars['Int']>,
  major?: Maybe<Scalars['String']>,
  grade?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
};

export enum AllianceUserGrade {
  /** 1학년 */
  GRADE_1 = 'GRADE_1',
  /** 2학년 */
  GRADE_2 = 'GRADE_2',
  /** 3학년 */
  GRADE_3 = 'GRADE_3',
  /** 4학년 */
  GRADE_4 = 'GRADE_4',
  /** 5학년 이상 */
  GRADE_5_OR_ABOVE = 'GRADE_5_OR_ABOVE',
  /** 졸업 */
  GRADUATED = 'GRADUATED',
  /** 중퇴 */
  WITHDREW = 'WITHDREW',
  /** 기타 */
  NONE = 'NONE'
}

export enum AllianceUserSchoolType {
  /** 고등학교 */
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  /** 대학교 */
  COLLEGE = 'COLLEGE',
  /** 대학원 */
  GRADUATE_SCHOOL = 'GRADUATE_SCHOOL'
}

export enum AllianceUserStatus {
  /** 재학 */
  ENROLLED = 'ENROLLED',
  /** 중퇴 */
  WITHDRAWAL = 'WITHDRAWAL',
  /** 좋업 */
  GRADUATION = 'GRADUATION'
}

export type AllianceUserUpdateInput = {
  id?: Maybe<Scalars['ID']>,
  schoolId: Scalars['Int'],
  schoolName: Scalars['String'],
  major: Scalars['String'],
  grade: AllianceUserGrade,
};

export type AllianceUserUpdatePayload = {
   __typename?: 'AllianceUserUpdatePayload',
  allianceUser?: Maybe<AllianceUser>,
};

export type AssignmentFileMission = {
   __typename?: 'AssignmentFileMission',
  useTemplate: Scalars['Boolean'],
  templateFile?: Maybe<File>,
};

export type AssignmentFileMissionCreateInput = {
  useTemplate: Scalars['Boolean'],
  templateFile?: Maybe<Scalars['Upload']>,
};

export type AssignmentFileMissionUpdateInput = {
  useTemplate?: Maybe<Scalars['Boolean']>,
  templateFile?: Maybe<Scalars['Upload']>,
};

export type AssignmentLinkMission = {
   __typename?: 'AssignmentLinkMission',
  useUrlCheck: Scalars['Boolean'],
  hashTags?: Maybe<Scalars['String']>,
  siteName?: Maybe<Scalars['String']>,
  boardName?: Maybe<Scalars['String']>,
  boardUrl?: Maybe<Scalars['String']>,
  minLinkCountLimit: Scalars['Int'],
};

export type AssignmentLinkMissionCreateInput = {
  useUrlCheck?: Maybe<Scalars['Boolean']>,
  hashTags?: Maybe<Scalars['String']>,
  siteName?: Maybe<Scalars['String']>,
  boardName?: Maybe<Scalars['String']>,
  boardUrl?: Maybe<Scalars['String']>,
  communityBoardId?: Maybe<Scalars['String']>,
  minLinkCountLimit?: Maybe<Scalars['Int']>,
};

export type AssignmentLinkMissionUpdateInput = {
  useUrlCheck?: Maybe<Scalars['Boolean']>,
  hashTags?: Maybe<Scalars['String']>,
  siteName?: Maybe<Scalars['String']>,
  boardName?: Maybe<Scalars['String']>,
  boardUrl?: Maybe<Scalars['String']>,
  communityBoardId?: Maybe<Scalars['String']>,
  minLinkCountLimit?: Maybe<Scalars['Int']>,
};

export type Attachment = {
   __typename?: 'Attachment',
  id: Scalars['ID'],
  name: Scalars['String'],
  size: Scalars['Int'],
  type: Scalars['String'],
  url: Scalars['String'],
  comment?: Maybe<IntegratedCommunityComment>,
  post?: Maybe<IntegratedCommunityPost>,
};

export type AttachmentCompleteUploadInput = {
  attachmentID: Scalars['ID'],
};

export type AttachmentCompleteUploadPayload = {
   __typename?: 'AttachmentCompleteUploadPayload',
  attachment: Attachment,
};

export type AttachmentPrepareUploadInput = {
  commentID?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  postID?: Maybe<Scalars['ID']>,
  size: Scalars['Int'],
  type: Scalars['String'],
};

export type AttachmentPrepareUploadPayload = {
   __typename?: 'AttachmentPrepareUploadPayload',
  attachment: Attachment,
  signedURL: Scalars['String'],
};

export type AuthSmsInput = {
  id: Scalars['ID'],
  phone: Scalars['String'],
  code: Scalars['String'],
};

export type AuthUser = {
   __typename?: 'AuthUser',
  id: Scalars['ID'],
};

export type Banner = {
   __typename?: 'Banner',
  id: Scalars['ID'],
  title: Scalars['String'],
  slideLabel?: Maybe<Scalars['String']>,
  mainImage: File,
  backgroundImage?: Maybe<File>,
  activateNavigationImage?: Maybe<File>,
  deactivateNavigationImage?: Maybe<File>,
  url: Scalars['String'],
  click: Scalars['Int'],
  status: BannerStatus,
  priority?: Maybe<Scalars['Int']>,
  bannerGroup: BannerGroup,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  backgroundColor?: Maybe<Scalars['String']>,
};

export type BannerConnection = {
   __typename?: 'BannerConnection',
  nodes: Array<Banner>,
  totalCount: Scalars['Int'],
};

export type BannerCreatePayload = {
   __typename?: 'BannerCreatePayload',
  banner?: Maybe<Banner>,
};

export type BannerFilters = {
  q?: Maybe<Scalars['String']>,
  bannerGroupID?: Maybe<Scalars['ID']>,
  status?: Maybe<BannerStatus>,
};

export type BannerGroup = {
   __typename?: 'BannerGroup',
  id: Scalars['ID'],
  codeName?: Maybe<Scalars['String']>,
  key: Scalars['String'],
  description: Scalars['String'],
  service: ServiceType,
  activeBanners: Array<Banner>,
  advisedMainImageSize?: Maybe<Scalars['String']>,
  advisedBackgroundImageSize?: Maybe<Scalars['String']>,
  advisedNavigationImageSize?: Maybe<Scalars['String']>,
  bannerCapacity?: Maybe<Scalars['Int']>,
};

export type BannerGroupConnection = {
   __typename?: 'BannerGroupConnection',
  nodes: Array<BannerGroup>,
  totalCount: Scalars['Int'],
};

export type BannerGroupCreateInput = {
  codeName?: Maybe<Scalars['String']>,
  key: Scalars['String'],
  service: ServiceType,
  description: Scalars['String'],
  advisedMainImageSize?: Maybe<Scalars['String']>,
  advisedBackgroundImageSize?: Maybe<Scalars['String']>,
  advisedNavigationImageSize?: Maybe<Scalars['String']>,
  bannerCapacity?: Maybe<Scalars['Int']>,
};

export type BannerGroupCreatePayload = {
   __typename?: 'BannerGroupCreatePayload',
  bannerGroup?: Maybe<BannerGroup>,
};

export type BannerGroupDeleteManyPayload = {
   __typename?: 'BannerGroupDeleteManyPayload',
  bannerGroups: Array<BannerGroup>,
};

export type BannerGroupFilters = {
  q?: Maybe<Scalars['String']>,
  service?: Maybe<ServiceType>,
};

export type BannerGroupOrder = {
  field: BannerGroupOrderField,
  direction: OrderDirection,
};

export enum BannerGroupOrderField {
  ID = 'ID'
}

export type BannerGroupUpdateInput = {
  codeName?: Maybe<Scalars['String']>,
  service?: Maybe<ServiceType>,
  description?: Maybe<Scalars['String']>,
  activeBannerIDs?: Maybe<Array<Scalars['ID']>>,
  advisedMainImageSize?: Maybe<Scalars['String']>,
  advisedBackgroundImageSize?: Maybe<Scalars['String']>,
  advisedNavigationImageSize?: Maybe<Scalars['String']>,
  bannerCapacity?: Maybe<Scalars['Int']>,
};

export type BannerGroupUpdatePayload = {
   __typename?: 'BannerGroupUpdatePayload',
  bannerGroup?: Maybe<BannerGroup>,
};

export type BannerInput = {
  url?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  slideLabel?: Maybe<Scalars['String']>,
  mainImage?: Maybe<Scalars['Upload']>,
  backgroundImage?: Maybe<Scalars['Upload']>,
  activateNavigationImage?: Maybe<Scalars['Upload']>,
  deactivateNavigationImage?: Maybe<Scalars['Upload']>,
  bannerGroupID?: Maybe<Scalars['ID']>,
  backgroundColor?: Maybe<Scalars['String']>,
};

export type BannerOrder = {
  field: BannerOrderField,
  direction: OrderDirection,
};

export enum BannerOrderField {
  ID = 'ID',
  CLICK = 'CLICK',
  PRIORITY = 'PRIORITY'
}

export enum BannerStatus {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE'
}

export type BannerUpdatePayload = {
   __typename?: 'BannerUpdatePayload',
  banner?: Maybe<Banner>,
};

export type Benefit = {
   __typename?: 'Benefit',
  key: Scalars['String'],
  value: Scalars['Int'],
  content?: Maybe<Content>,
};

export enum BenefitType {
  CONTENT_VIDEO = 'CONTENT_VIDEO',
  CONTENT_DOCUMENT = 'CONTENT_DOCUMENT',
  CONTENT_FREEPASS = 'CONTENT_FREEPASS',
  COUPON = 'COUPON',
  POINT = 'POINT',
  EXAM = 'EXAM'
}

export type Board = {
   __typename?: 'Board',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
};

export type Book = {
   __typename?: 'Book',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  authorName?: Maybe<Scalars['String']>,
  authorIntroduction: Scalars['String'],
  content?: Maybe<Content>,
  expectedQuestionSet?: Maybe<Exam>,
  mockExams: Array<Exam>,
  mockExamTargetCompanies: Array<Company>,
  link: Scalars['String'],
  description: Scalars['String'],
  examDescription: Scalars['String'],
  year: Scalars['String'],
  studyLink?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
};

export type BookConnection = {
   __typename?: 'BookConnection',
  nodes: Array<Book>,
  totalCount: Scalars['Int'],
};

export type BookCreateInput = {
  content: ContentCreateInput,
  name: Scalars['String'],
  authorName: Scalars['String'],
  authorIntroduction: Scalars['String'],
  year: Scalars['String'],
  expectedQuestionSetID?: Maybe<Scalars['ID']>,
  mockExamIDs?: Maybe<Array<Scalars['ID']>>,
  mockExamTargetCompanyIDs?: Maybe<Array<Scalars['ID']>>,
  link: Scalars['String'],
  description: Scalars['String'],
  examDescription?: Maybe<Scalars['String']>,
  studyLink?: Maybe<Scalars['String']>,
};

export type BookCreatePayload = {
   __typename?: 'BookCreatePayload',
  book?: Maybe<Book>,
};

export type BookDeleteManyPayload = {
   __typename?: 'BookDeleteManyPayload',
  books?: Maybe<Array<Book>>,
};

export type BookDeletePayload = {
   __typename?: 'BookDeletePayload',
  book?: Maybe<Book>,
};

export type BookFilters = {
  q?: Maybe<Scalars['String']>,
  contentSubject?: Maybe<Scalars['String']>,
  hasExam?: Maybe<Scalars['Boolean']>,
};

export type BookOrder = {
  field: BookOrderField,
  direction: OrderDirection,
};

export enum BookOrderField {
  ID = 'ID'
}

export type BookUpdateInput = {
  content?: Maybe<ContentUpdateInput>,
  name?: Maybe<Scalars['String']>,
  authorName?: Maybe<Scalars['String']>,
  authorIntroduction?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['String']>,
  expectedQuestionSetID?: Maybe<Scalars['ID']>,
  mockExamIDs?: Maybe<Array<Scalars['ID']>>,
  mockExamTargetCompanyIDs?: Maybe<Array<Scalars['ID']>>,
  link?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  examDescription?: Maybe<Scalars['String']>,
  studyLink?: Maybe<Scalars['String']>,
};

export type BookUpdatePayload = {
   __typename?: 'BookUpdatePayload',
  book?: Maybe<Book>,
};

export enum BucketMode {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  PRIVATE_PDF = 'PRIVATE_PDF',
  ENCRYPT = 'ENCRYPT'
}

export type Campaign = {
   __typename?: 'Campaign',
  id: Scalars['ID'],
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  startAt?: Maybe<Scalars['Date']>,
  endAt?: Maybe<Scalars['Date']>,
  name: Scalars['String'],
  noticeTitle: Scalars['String'],
  pcLink?: Maybe<Scalars['String']>,
  mobileLink?: Maybe<Scalars['String']>,
  communityPostURL?: Maybe<Scalars['String']>,
  possibleDuplicated?: Maybe<Scalars['Boolean']>,
  service?: Maybe<ServiceType>,
  type: CampaignType,
  target: CampaignTarget,
  hasUsers?: Maybe<Scalars['Boolean']>,
  campaignUsers: Array<Maybe<CampaignUser>>,
  campaignBenefits: Array<Maybe<CampaignBenefit>>,
  registeredUsers: Array<User>,
  data?: Maybe<CampaignData>,
  isForBraze?: Maybe<Scalars['Boolean']>,
};

export type CampaignBenefit = {
   __typename?: 'CampaignBenefit',
  id: Scalars['ID'],
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  benefitType?: Maybe<BenefitType>,
  benefit?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
};

export type CampaignBenefitInput = {
  campaignID: Scalars['ID'],
  benefitType: BenefitType,
  benefit?: Maybe<Scalars['String']>,
  isActive: Scalars['Boolean'],
};

export type CampaignBenefitMutationPayload = {
   __typename?: 'CampaignBenefitMutationPayload',
  status: Scalars['String'],
};

export enum CampaignCategory {
  NONE = 'NONE',
  MAJOR = 'MAJOR',
  PUBLIC = 'PUBLIC',
  TOEIC_SPEAKING = 'TOEIC_SPEAKING',
  OPIC = 'OPIC',
  SEVEN_GONG = 'SEVEN_GONG'
}

export type CampaignConnection = {
   __typename?: 'CampaignConnection',
  nodes: Array<Maybe<Campaign>>,
  totalCount: Scalars['Int'],
};

export type CampaignConnectionForDataExtract = {
   __typename?: 'CampaignConnectionForDataExtract',
  nodes: Array<Maybe<Campaign>>,
};

export type CampaignCreateOrUpdatePayload = {
   __typename?: 'CampaignCreateOrUpdatePayload',
  campaign?: Maybe<Campaign>,
};

export type CampaignData = {
   __typename?: 'CampaignData',
  category: CampaignCategory,
  name: Scalars['String'],
  industry?: Maybe<Scalars['String']>,
  etc?: Maybe<Scalars['String']>,
};

export type CampaignDataFilter = {
  category?: Maybe<Array<CampaignCategory>>,
  name?: Maybe<Array<Scalars['String']>>,
  industry?: Maybe<Array<Scalars['String']>>,
  etc?: Maybe<Array<Scalars['String']>>,
};

export type CampaignDataInput = {
  category: CampaignCategory,
  name: Scalars['String'],
  industry: Scalars['String'],
  etc: Scalars['String'],
};

export type CampaignDeletePayload = {
   __typename?: 'CampaignDeletePayload',
  campaign?: Maybe<Campaign>,
};

export type CampaignFilters = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  startAt?: Maybe<Scalars['String']>,
  endAt?: Maybe<Scalars['String']>,
  type?: Maybe<CampaignType>,
  data?: Maybe<CampaignDataFilter>,
};

export type CampaignInput = {
  name?: Maybe<Scalars['String']>,
  noticeTitle?: Maybe<Scalars['String']>,
  startAt?: Maybe<Scalars['Date']>,
  endAt?: Maybe<Scalars['Date']>,
  type?: Maybe<CampaignType>,
  target?: Maybe<CampaignTarget>,
  pcLink?: Maybe<Scalars['String']>,
  mobileLink?: Maybe<Scalars['String']>,
  communityPostURL?: Maybe<Scalars['String']>,
  possibleDuplicated?: Maybe<Scalars['Boolean']>,
  service?: Maybe<ServiceType>,
  data?: Maybe<CampaignDataInput>,
  isForBraze?: Maybe<Scalars['Boolean']>,
};

export type CampaignOrder = {
  field: CampaignOrderField,
  direction: OrderDirection,
};

export enum CampaignOrderField {
  ID = 'ID'
}

export enum CampaignTarget {
  ALL_USER = 'ALL_USER',
  NEW_JOIN_USER = 'NEW_JOIN_USER'
}

export enum CampaignType {
  GENERAL = 'GENERAL',
  EVENT = 'EVENT'
}

export type CampaignUser = {
   __typename?: 'CampaignUser',
  id: Scalars['ID'],
  joinAt: Scalars['Date'],
  name: Scalars['String'],
  allowMarketing: Scalars['Boolean'],
  phoneNumber?: Maybe<Scalars['PhoneNumber']>,
  email?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
  isRegistered: Scalars['Boolean'],
};

export type CampaignUserCreateInput = {
  joinAt?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  allowMarketing: Scalars['Boolean'],
  phoneNumber?: Maybe<Scalars['PhoneNumber']>,
  email?: Maybe<Scalars['String']>,
};

export type CampaignUserMutationPayload = {
   __typename?: 'CampaignUserMutationPayload',
  status?: Maybe<Scalars['String']>,
};

export type CloneExamCreatePayload = {
   __typename?: 'CloneExamCreatePayload',
  result: Scalars['Boolean'],
  message?: Maybe<Scalars['String']>,
  contentPurchase?: Maybe<ContentPurchase>,
};

export type CommunityBoard = {
   __typename?: 'CommunityBoard',
  id: Scalars['ID'],
  title: Scalars['String'],
};

export type CommunityPost = {
   __typename?: 'CommunityPost',
  id: Scalars['ID'],
  url: Scalars['String'],
  title: Scalars['String'],
  content: Scalars['String'],
  views: Scalars['Int'],
  likes: Scalars['Int'],
  dislikes: Scalars['Int'],
  rating?: Maybe<CommunityPostRating>,
  board: CommunityBoard,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type CommunityPostRating = {
   __typename?: 'CommunityPostRating',
  score: Scalars['Float'],
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type Company = {
   __typename?: 'Company',
  id: Scalars['ID'],
  name: Scalars['String'],
  createdAt: Scalars['Date'],
};

export type CompanyConnection = {
   __typename?: 'CompanyConnection',
  nodes: Array<Company>,
  totalCount: Scalars['Int'],
};

export type CompanyCreatePayload = {
   __typename?: 'CompanyCreatePayload',
  company?: Maybe<Company>,
};

export type CompanyDeleteManyPayload = {
   __typename?: 'CompanyDeleteManyPayload',
  companies?: Maybe<Array<Company>>,
};

export type CompanyDeletePayload = {
   __typename?: 'CompanyDeletePayload',
  company?: Maybe<Company>,
};

export type CompanyFilters = {
  q?: Maybe<Scalars['String']>,
};

export type CompanyInput = {
  name?: Maybe<Scalars['String']>,
};

export type CompanyOrder = {
  field: CompanyOrderField,
  direction: OrderDirection,
};

export enum CompanyOrderField {
  ID = 'ID',
  NAME = 'NAME'
}

export type CompanyUpdatePayload = {
   __typename?: 'CompanyUpdatePayload',
  company?: Maybe<Company>,
};

export enum ConfirmState {
  CREATED = 'CREATED',
  CONFIRM_OPERATOR = 'CONFIRM_OPERATOR',
  CONFIRM_ACCOUNTANT = 'CONFIRM_ACCOUNTANT',
  COMPLETE = 'COMPLETE'
}

export type ConnectedContent = {
   __typename?: 'ConnectedContent',
  id: Scalars['ID'],
  content: Content,
};

export type ConnectedContentDeletePayload = {
   __typename?: 'ConnectedContentDeletePayload',
  status?: Maybe<Scalars['String']>,
};

export type Content = {
   __typename?: 'Content',
  id: Scalars['ID'],
  type: ContentType,
  subject: Scalars['String'],
  service: ServiceType,
  image?: Maybe<File>,
  /** 사용기한이 없을 경우 무제한 이용가능 */
  expireDays?: Maybe<Scalars['Int']>,
  publishedAt?: Maybe<Scalars['Date']>,
  salesPeriod: Array<Maybe<Scalars['Date']>>,
  active: Scalars['Boolean'],
  priority?: Maybe<Scalars['Int']>,
  keywords: Array<Scalars['String']>,
  visibility: Visibility,
  includesInvoice: Scalars['Boolean'],
  teachers: Array<Teacher>,
  teacherCommissions?: Maybe<Array<TeacherCommissions>>,
  categoryParents?: Maybe<Array<ContentCategoryParent>>,
  selectedCategoryIDs?: Maybe<Array<Scalars['ID']>>,
  /** 할인 전 가격 */
  originalPrice?: Maybe<Scalars['Int']>,
  /** price가 null일 경우 비매품. true 반환 */
  price?: Maybe<Scalars['Int']>,
  isNotForSale: Scalars['Boolean'],
  tag?: Maybe<ContentTag>,
  reviews?: Maybe<ReviewConnection>,
  salesCount: Scalars['Int'],
  maxSellableCount?: Maybe<Scalars['Int']>,
  studentCount: Scalars['Int'],
  hasContent?: Maybe<Scalars['Boolean']>,
  isFree?: Maybe<Scalars['Boolean']>,
  saleStatus?: Maybe<SaleStatus>,
  /** 컨텐츠 판매 기록 */
  contentPurchases?: Maybe<ContentPurchaseConnection>,
  video?: Maybe<VideoProduct>,
  freepass?: Maybe<FreepassProduct>,
  offline?: Maybe<Offline>,
  document?: Maybe<DocumentProduct>,
  book?: Maybe<Book>,
  exam?: Maybe<Exam>,
  board?: Maybe<CommunityBoard>,
};


export type ContentReviewsArgs = {
  filterBy?: Maybe<ReviewFilters>,
  orderBy?: Maybe<ReviewOrder>,
  pagination?: Maybe<Pagination>
};


export type ContentSalesCountArgs = {
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  token?: Maybe<Scalars['String']>
};


export type ContentContentPurchasesArgs = {
  filterBy?: Maybe<ContentPurchaseFilters>,
  orderBy?: Maybe<ContentPurchaseOrder>,
  pagination?: Maybe<Pagination>
};

export type ContentCategory = {
   __typename?: 'ContentCategory',
  id: Scalars['ID'],
  name: Scalars['String'],
  parentCategory: ContentCategoryParent,
  isSelected: Scalars['Boolean'],
};

export type ContentCategoryParent = {
   __typename?: 'ContentCategoryParent',
  id: Scalars['ID'],
  name: Scalars['String'],
  key: Scalars['String'],
  childrenCategories: Array<ContentCategory>,
};


export type ContentCategoryParentChildrenCategoriesArgs = {
  selectedOnly?: Maybe<Scalars['Boolean']>
};

export type ContentConnection = {
   __typename?: 'ContentConnection',
  nodes?: Maybe<Array<Content>>,
  totalCount: Scalars['Int'],
};

export type ContentCreateInput = {
  subject: Scalars['String'],
  type?: Maybe<ContentType>,
  service?: Maybe<ServiceType>,
  publishedAt?: Maybe<Scalars['Date']>,
  salesPeriod: Array<Maybe<Scalars['Date']>>,
  expireDays?: Maybe<Scalars['Int']>,
  originalPrice?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Int']>,
  active: Scalars['Boolean'],
  priority?: Maybe<Scalars['Int']>,
  visibility: Visibility,
  teacherIDs?: Maybe<Array<Scalars['ID']>>,
  teacherCommissions?: Maybe<Array<TeacherCommissionInput>>,
  categoryChildrenIDs?: Maybe<Array<Scalars['ID']>>,
  keywords?: Maybe<Array<Scalars['String']>>,
  maxSellableCount?: Maybe<Scalars['Int']>,
  image?: Maybe<Scalars['Upload']>,
  tag?: Maybe<ContentTag>,
  includesInvoice?: Maybe<Scalars['Boolean']>,
};

export type ContentCreatePayload = {
   __typename?: 'ContentCreatePayload',
  content?: Maybe<Content>,
};

export type ContentCuration = {
   __typename?: 'ContentCuration',
  id: Scalars['ID'],
  name: Scalars['String'],
  code: Scalars['String'],
  contents: Array<Content>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type ContentCurationConnection = {
   __typename?: 'ContentCurationConnection',
  nodes: Array<ContentCuration>,
  totalCount: Scalars['Int'],
};

export type ContentCurationCreatePayload = {
   __typename?: 'ContentCurationCreatePayload',
  contentCuration?: Maybe<ContentCuration>,
};

export type ContentCurationDeleteManyPayload = {
   __typename?: 'ContentCurationDeleteManyPayload',
  contentCurations: Array<ContentCuration>,
};

export type ContentCurationDeletePayload = {
   __typename?: 'ContentCurationDeletePayload',
  contentCuration?: Maybe<ContentCuration>,
};

export type ContentCurationFilters = {
  q?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  createdAt?: Maybe<DateRange>,
  updatedAt?: Maybe<DateRange>,
};

export type ContentCurationInput = {
  name?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  contentIDs?: Maybe<Array<Scalars['ID']>>,
};

export type ContentCurationOrder = {
  field: ContentCurationOrderField,
  direction: OrderDirection,
};

export enum ContentCurationOrderField {
  ID = 'ID',
  NAME = 'NAME',
  CODE = 'CODE',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type ContentCurationUpdatePayload = {
   __typename?: 'ContentCurationUpdatePayload',
  contentCuration?: Maybe<ContentCuration>,
};

export type ContentDeleteManyPayload = {
   __typename?: 'ContentDeleteManyPayload',
  contents?: Maybe<Array<Content>>,
};

export type ContentDeletePayload = {
   __typename?: 'ContentDeletePayload',
  content?: Maybe<Content>,
};

export type ContentExamUpdateInput = {
  expireDays?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
};

export type ContentFilters = {
  q?: Maybe<Scalars['String']>,
  ids?: Maybe<Array<Scalars['ID']>>,
  type?: Maybe<ContentType>,
  active?: Maybe<Scalars['Boolean']>,
  visibility?: Maybe<Array<Scalars['Int']>>,
  categories?: Maybe<Array<Scalars['String']>>,
};

export type ContentOrder = {
  field: ContentOrderField,
  direction: OrderDirection,
};

export enum ContentOrderField {
  ID = 'ID',
  PRIORITY = 'PRIORITY'
}

export type ContentPurchase = {
   __typename?: 'ContentPurchase',
  id: Scalars['ID'],
  user?: Maybe<User>,
  price: Scalars['Int'],
  pointDiscount: Scalars['Int'],
  couponDiscount: Scalars['Int'],
  content: Content,
  parentContent?: Maybe<Content>,
  childrenContents?: Maybe<Array<Content>>,
  offlineOptionSelectedContentIDs?: Maybe<Array<Scalars['ID']>>,
  createdAt: Scalars['Date'],
  expiredAt?: Maybe<Scalars['Date']>,
  contentPurchaseDetail?: Maybe<ContentPurchaseDetail>,
  order?: Maybe<Order>,
  refund?: Maybe<Refund>,
};

export type ContentPurchaseConnection = {
   __typename?: 'ContentPurchaseConnection',
  nodes: Array<ContentPurchase>,
  totalCount: Scalars['Int'],
};

export type ContentPurchaseDetail = Video | Resume | Document | ExamAttempt | Freepass;

export type ContentPurchaseFilters = {
  contentIDs?: Maybe<Array<Scalars['ID']>>,
  keyword?: Maybe<Scalars['String']>,
  createdAt?: Maybe<DateRange>,
  smsReceipt?: Maybe<Scalars['Boolean']>,
  emailReceipt?: Maybe<Scalars['Boolean']>,
  leftUser?: Maybe<Scalars['Boolean']>,
  orderID?: Maybe<Scalars['ID']>,
  contentType?: Maybe<ContentType>,
  userID?: Maybe<Scalars['ID']>,
};

export type ContentPurchaseOrder = {
  field: ContentPurchaseOrderField,
  direction: OrderDirection,
};

export enum ContentPurchaseOrderField {
  ID = 'ID'
}

export type ContentPurchaseRank = {
   __typename?: 'ContentPurchaseRank',
  content: Content,
  contentType: ContentPurchaseRankContentType,
  salesAmount: Scalars['Float'],
  salesCount: Scalars['Int'],
  playTime: Scalars['Int'],
  playCount: Scalars['Int'],
  playUserCount: Scalars['Int'],
  payType: ContentPurchaseRankPayType,
  rateOfOrderField: Scalars['Float'],
  salesAmountRate: Scalars['Float'],
  salesCountRate: Scalars['Float'],
  playTimeRate: Scalars['Float'],
  playCountRate: Scalars['Float'],
  playUserCountRate?: Maybe<Scalars['Float']>,
};


export type ContentPurchaseRankPlayTimeArgs = {
  unit?: Maybe<TimeUnit>
};

export enum ContentPurchaseRankContentType {
  TOTAL = 'TOTAL',
  FREEPASS = 'FREEPASS',
  VIDEO = 'VIDEO',
  VIDEO_FROM_FREEPASS = 'VIDEO_FROM_FREEPASS',
  OFFLINE_LECTURE = 'OFFLINE_LECTURE'
}

export type ContentPurchaseRankFilters = {
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  service: ContentPurchaseRankServiceType,
  contentType: ContentPurchaseRankContentType,
  payType: ContentPurchaseRankPayType,
  period: ContentPurchaseRankPeriod,
};

export type ContentPurchaseRankGroup = {
   __typename?: 'ContentPurchaseRankGroup',
  date: Scalars['Date'],
  contentPurchaseRanks: Array<ContentPurchaseRank>,
  totalCount: Scalars['Int'],
  summary: ContentPurchaseRankSummary,
};

export type ContentPurchaseRankGroupConnection = {
   __typename?: 'ContentPurchaseRankGroupConnection',
  nodes: Array<ContentPurchaseRankGroup>,
  nodesOfLastYear: Array<ContentPurchaseRankGroup>,
  nodesTotalCount: Scalars['Int'],
  nodesOfLastYearTotalCount: Scalars['Int'],
};

export type ContentPurchaseRankOrder = {
  field: ContentPurchaseRankOrderField,
  direction: OrderDirection,
};

export enum ContentPurchaseRankOrderField {
  SALES_AMOUNT = 'SALES_AMOUNT',
  SALES_COUNT = 'SALES_COUNT',
  PLAY_COUNT = 'PLAY_COUNT',
  PLAY_TIME = 'PLAY_TIME'
}

export enum ContentPurchaseRankPayType {
  TOTAL = 'TOTAL',
  FREE = 'FREE',
  PAID = 'PAID'
}

export enum ContentPurchaseRankPeriod {
  MONTHLY = 'MONTHLY',
  TOTAL = 'TOTAL'
}

export enum ContentPurchaseRankServiceType {
  TOTAL = 'TOTAL',
  WEPORT = 'WEPORT',
  NCS = 'NCS',
  ENGINIC = 'ENGINIC',
  OFFLINE = 'OFFLINE',
  TALKERBE = 'TALKERBE',
  SEVEN_GONG = 'SEVEN_GONG'
}

export type ContentPurchaseRankSummary = {
   __typename?: 'ContentPurchaseRankSummary',
  totalSalesAmount: Scalars['Float'],
  totalSalesCount: Scalars['Int'],
  totalPlayTime: Scalars['Float'],
  totalPlayCount: Scalars['Int'],
  totalPlayUserCount: Scalars['Int'],
};


export type ContentPurchaseRankSummaryTotalPlayTimeArgs = {
  unit?: Maybe<TimeUnit>
};

export type ContentPurchaseStat = {
   __typename?: 'ContentPurchaseStat',
  startDate: Scalars['Date'],
  salesCount: Scalars['Int'],
  salesAmount: Scalars['Int'],
  customerCount: Scalars['Int'],
};

export type ContentPurchaseStatConnection = {
   __typename?: 'ContentPurchaseStatConnection',
  nodes: Array<ContentPurchaseStat>,
  nodesOfLastYear: Array<ContentPurchaseStat>,
  growthVSLastYear: Array<ContentPurchaseStatGrowth>,
  nodesOfLastHalf: Array<ContentPurchaseStat>,
  growthVSLastHalf: Array<ContentPurchaseStatGrowth>,
  growthSummaryVSLastYear: ContentPurchaseStatGrowthSummary,
  growthSummaryVSLastHalf: ContentPurchaseStatGrowthSummary,
  summary: ContentPurchaseStatSummary,
  weeklySummaryOfLastWeek: ContentPurchaseStatSummary,
  weeklySummaryOfLastYear: ContentPurchaseStatSummary,
  yearlySummaryOfLastYear: ContentPurchaseStatSummary,
  yearlySummaryOfLastHalf: ContentPurchaseStatSummary,
};

export enum ContentPurchaseStatContentType {
  TOTAL = 'TOTAL',
  FREEPASS = 'FREEPASS',
  NORMAL = 'NORMAL',
  DOCUMENT = 'DOCUMENT'
}

export type ContentPurchaseStatFilters = {
  service: ContentPurchaseStatServiceFilter,
  contentType: ContentPurchaseStatContentType,
  period: StatPeriod,
  date: Scalars['Date'],
};

export type ContentPurchaseStatGrowth = {
   __typename?: 'ContentPurchaseStatGrowth',
  startDate: Scalars['Date'],
  salesAmountGrowthAmount: Scalars['Float'],
  salesCountGrowthAmount: Scalars['Int'],
  customerCountGrowthAmount: Scalars['Int'],
  salesCountGrowthRate: Scalars['Float'],
  salesAmountGrowthRate: Scalars['Float'],
  customerCountGrowthRate: Scalars['Float'],
};

export type ContentPurchaseStatGrowthSummary = {
   __typename?: 'ContentPurchaseStatGrowthSummary',
  totalSalesAmountGrowthAmount: Scalars['Float'],
  totalSalesCountGrowthAmount: Scalars['Int'],
  totalCustomerCountGrowthAmount: Scalars['Int'],
  totalSalesAmountGrowthRate: Scalars['Float'],
  totalSalesCountGrowthRate: Scalars['Float'],
  totalCustomerCountGrowthRate: Scalars['Float'],
};

export enum ContentPurchaseStatServiceFilter {
  TOTAL = 'TOTAL',
  WEPORT = 'WEPORT',
  NCS = 'NCS',
  ENGINIC = 'ENGINIC',
  OFFLINE = 'OFFLINE',
  TALKERBE = 'TALKERBE',
  SEVEN_GONG = 'SEVEN_GONG',
  COMPANY = 'COMPANY'
}

export type ContentPurchaseStatSummary = {
   __typename?: 'ContentPurchaseStatSummary',
  totalSalesAmount: Scalars['Float'],
  totalSalesCount: Scalars['Int'],
  totalCustomerCount: Scalars['Int'],
};

export type ContentRefundDetail = {
   __typename?: 'ContentRefundDetail',
  refundRatio: Scalars['Float'],
  netSalesAmount: Scalars['Int'],
};

export type ContentRefundDetailSummary = {
   __typename?: 'ContentRefundDetailSummary',
  totalRefundRatio: Scalars['Float'],
  totalNetSalesAmount: Scalars['Float'],
};

export type ContentRefundStat = {
   __typename?: 'ContentRefundStat',
  startDate: Scalars['Date'],
  refundCount: Scalars['Int'],
  refundAmount: Scalars['Int'],
};

export type ContentRefundStatConnection = {
   __typename?: 'ContentRefundStatConnection',
  nodes: Array<ContentRefundStat>,
  nodesOfLastYear: Array<ContentRefundStat>,
  nodesForSlack: Array<ContentRefundStat>,
  refundDetails: Array<ContentRefundDetail>,
  refundDetailsOfLastYear: Array<ContentRefundDetail>,
  growthVSLastYear: Array<ContentRefundStatGrowth>,
  growthSummaryVSLastYear: ContentRefundStatGrowthSummary,
  summary: ContentRefundStatSummary,
  weeklySummaryOfLastWeek: ContentRefundStatSummary,
  weeklySummaryOfLastYear: ContentRefundStatSummary,
  yearlySummaryOfLastYear: ContentRefundStatSummary,
  refundDetailsSummary: ContentRefundDetailSummary,
  refundDetailsSummaryOfLastYear: ContentRefundDetailSummary,
};

export type ContentRefundStatGrowth = {
   __typename?: 'ContentRefundStatGrowth',
  startDate: Scalars['Date'],
  refundCountGrowthAmount: Scalars['Int'],
  refundAmountGrowthAmount: Scalars['Int'],
  refundCountGrowthRate: Scalars['Float'],
  refundAmountGrowthRate: Scalars['Float'],
};

export type ContentRefundStatGrowthSummary = {
   __typename?: 'ContentRefundStatGrowthSummary',
  totalRefundAmountGrowthAmount: Scalars['Int'],
  totalRefundCountGrowthAmount: Scalars['Int'],
  totalRefundAmountGrowthRate: Scalars['Float'],
  totalRefundCountGrowthRate: Scalars['Float'],
};

export type ContentRefundStatSummary = {
   __typename?: 'ContentRefundStatSummary',
  totalRefundAmount: Scalars['Float'],
  totalRefundCount: Scalars['Int'],
};

export enum ContentTag {
  NONE = 'NONE',
  HOT = 'HOT',
  BEST = 'BEST',
  NEW = 'NEW'
}

export enum ContentType {
  BOOK = 'BOOK',
  DOCUMENT = 'DOCUMENT',
  EXAM = 'EXAM',
  VIDEO_LECTURE = 'VIDEO_LECTURE',
  OFFLINE_LECTURE = 'OFFLINE_LECTURE',
  FREEPASS = 'FREEPASS',
  CONSULT_RESUME = 'CONSULT_RESUME',
  CONSULT_INTERVIEW = 'CONSULT_INTERVIEW',
  LINK = 'LINK'
}

export type ContentUpdateInput = {
  subject?: Maybe<Scalars['String']>,
  salesPeriod?: Maybe<Array<Maybe<Scalars['Date']>>>,
  publishedAt?: Maybe<Scalars['Date']>,
  expireDays?: Maybe<Scalars['Int']>,
  originalPrice?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Int']>,
  active?: Maybe<Scalars['Boolean']>,
  priority?: Maybe<Scalars['Int']>,
  visibility?: Maybe<Visibility>,
  teacherIDs?: Maybe<Array<Scalars['ID']>>,
  teacherCommissions?: Maybe<Array<TeacherCommissionInput>>,
  categoryChildrenIDs?: Maybe<Array<Scalars['ID']>>,
  keywords?: Maybe<Array<Scalars['String']>>,
  maxSellableCount?: Maybe<Scalars['Int']>,
  image?: Maybe<Scalars['Upload']>,
  tag?: Maybe<ContentTag>,
  includesInvoice?: Maybe<Scalars['Boolean']>,
};

export type ContentUpdatePayload = {
   __typename?: 'ContentUpdatePayload',
  content?: Maybe<Content>,
};

export type Coupon = {
   __typename?: 'Coupon',
  id: Scalars['ID'],
  name: Scalars['String'],
  registerablePeriod: Array<Maybe<Scalars['Date']>>,
  type: CouponType,
  expireDays?: Maybe<Scalars['Int']>,
  couponNumbers: Array<CouponNumber>,
  couponCategory?: Maybe<CouponCategory>,
  couponData?: Maybe<Array<CouponData>>,
};

export enum CouponCategory {
  DISCOUNT = 'DISCOUNT',
  CREATE_ORDER = 'CREATE_ORDER',
  REGISTER_COUPON = 'REGISTER_COUPON'
}

export type CouponConnection = {
   __typename?: 'CouponConnection',
  nodes?: Maybe<Array<Maybe<Coupon>>>,
  totalCount: Scalars['Int'],
};

export type CouponCreateOrder = {
   __typename?: 'CouponCreateOrder',
  contentID: Scalars['ID'],
};

export type CouponData = CouponDiscount | CouponCreateOrder | CouponRegisterCoupon;

export type CouponDeletePayload = {
   __typename?: 'CouponDeletePayload',
  coupon?: Maybe<Coupon>,
};

export type CouponDiscount = {
   __typename?: 'CouponDiscount',
  amount: Scalars['Float'],
  service?: Maybe<Scalars['String']>,
  contentIDs?: Maybe<Array<Scalars['ID']>>,
  categories?: Maybe<Array<Scalars['String']>>,
  contentType?: Maybe<Scalars['String']>,
};

export type CouponFilters = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  couponNumber?: Maybe<Scalars['String']>,
  /** 입력한 컨텐츠ID를 구매했을 시 자동 지급되는 쿠폰을 찾는다 */
  contentID?: Maybe<Scalars['ID']>,
};

export type CouponInput = {
  type?: Maybe<CouponType>,
};

export type CouponNumber = {
   __typename?: 'CouponNumber',
  id: Scalars['ID'],
  number: Scalars['String'],
  connectedContents: Array<Maybe<ConnectedContent>>,
};

export type CouponOrder = {
  field: CouponOrderField,
  direction: OrderDirection,
};

export enum CouponOrderField {
  ID = 'ID'
}

export type CouponRegisterCoupon = {
   __typename?: 'CouponRegisterCoupon',
  couponNumber: Scalars['String'],
};

export enum CouponType {
  GENERAL = 'GENERAL',
  DUPLICABLE = 'DUPLICABLE',
  BRAZE = 'BRAZE'
}

export type CouponUpdatePayload = {
   __typename?: 'CouponUpdatePayload',
  coupon?: Maybe<Coupon>,
};

export type CreateConnectedContentPayload = {
   __typename?: 'CreateConnectedContentPayload',
  status?: Maybe<Scalars['String']>,
};

export type DailyStudyTime = {
   __typename?: 'DailyStudyTime',
  date: Scalars['Date'],
  studyTime: Scalars['Int'],
};


export type DateRange = {
  gt?: Maybe<Scalars['Date']>,
  gte?: Maybe<Scalars['Date']>,
  lt?: Maybe<Scalars['Date']>,
  lte?: Maybe<Scalars['Date']>,
};

export enum DiscountType {
  PERCENTAGE = 'PERCENTAGE',
  PRICE = 'PRICE'
}

export type Document = {
   __typename?: 'Document',
  id: Scalars['ID'],
  printCount: Scalars['Int'],
  /** 최대 인쇄가능횟수. 문서 구매방식에 따라 결정됨. 예) freepass: 무제한, 쿠폰 등 무료구매 : 0회, 나머지 : maxPrintLimitDefault */
  maxPrintLimit: Scalars['Int'],
  printLog?: Maybe<Array<PrintLog>>,
  documentProduct: DocumentProduct,
};

export type DocumentConnection = {
   __typename?: 'DocumentConnection',
  nodes: Array<Document>,
  totalCount: Scalars['Int'],
};

export type DocumentFilters = {
  q?: Maybe<Scalars['String']>,
};

export type DocumentOrder = {
  field: DocumentOrderField,
  direction: OrderDirection,
};

export enum DocumentOrderField {
  ID = 'ID'
}

export type DocumentProduct = {
   __typename?: 'DocumentProduct',
  id: Scalars['ID'],
  content: Content,
  viewCount: Scalars['Int'],
  description: Scalars['String'],
  file?: Maybe<File>,
  printLimitDefault: Scalars['Int'],
  shortDescription?: Maybe<Scalars['String']>,
  contentSummary: Scalars['String'],
};

export type DocumentProductConnection = {
   __typename?: 'DocumentProductConnection',
  nodes: Array<DocumentProduct>,
  totalCount: Scalars['Int'],
};

export type DocumentProductFilters = {
  q?: Maybe<Scalars['String']>,
};

export type DocumentProductOrder = {
  field: DocumentProductOrderField,
  direction: OrderDirection,
};

export enum DocumentProductOrderField {
  ID = 'ID'
}

export type DuplicatedUser = {
   __typename?: 'DuplicatedUser',
  id?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};


export type EmailSendMessageForFindIdPayload = {
   __typename?: 'emailSendMessageForFindIDPayload',
  email: Scalars['EmailAddress'],
};

export type EmailSendMessageForFindPwPayload = {
   __typename?: 'emailSendMessageForFindPWPayload',
  email: Scalars['EmailAddress'],
  recoveryID: Scalars['ID'],
  key: Scalars['String'],
};

export type Exam = {
   __typename?: 'Exam',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  content: Content,
  defaultEvent?: Maybe<ExamEvent>,
  events: Array<ExamEvent>,
  section: ExamSection,
  sections: Array<ExamSection>,
  averageScore?: Maybe<Scalars['Float']>,
  examQuestions?: Maybe<ExamQuestionConnection>,
  attempts?: Maybe<ExamAttemptConnection>,
  reportIsReady: Scalars['Boolean'],
  createdAt: Scalars['Date'],
  type: ExamType,
  company: Company,
};


export type ExamSectionArgs = {
  id: Scalars['ID']
};


export type ExamAverageScoreArgs = {
  targetCompanyID?: Maybe<Scalars['ID']>
};


export type ExamExamQuestionsArgs = {
  pagination?: Maybe<Pagination>
};

export type ExamAttempt = {
   __typename?: 'ExamAttempt',
  id: Scalars['ID'],
  targetCompany?: Maybe<Company>,
  event: ExamEvent,
  user?: Maybe<User>,
  tempAccount?: Maybe<TempAccount>,
  comment?: Maybe<Scalars['String']>,
  score: Scalars['Int'],
  rank?: Maybe<Scalars['Float']>,
  percentileRank?: Maybe<Scalars['Float']>,
  sectionResult?: Maybe<ExamAttemptSectionResult>,
  resultsBySection: Array<ExamAttemptSectionResult>,
  resultsByQuestionType: Array<ExamAttemptQuestionTypeResult>,
  createdAt?: Maybe<Scalars['Date']>,
  takenAt?: Maybe<Scalars['Date']>,
};


export type ExamAttemptRankArgs = {
  targetCompanyID?: Maybe<Scalars['ID']>
};


export type ExamAttemptPercentileRankArgs = {
  targetCompanyID?: Maybe<Scalars['ID']>
};


export type ExamAttemptSectionResultArgs = {
  examSectionID: Scalars['ID']
};


export type ExamAttemptResultsByQuestionTypeArgs = {
  sectionID: Scalars['ID']
};

export type ExamAttemptAnswer = {
   __typename?: 'ExamAttemptAnswer',
  id: Scalars['ID'],
  examQuestion: ExamQuestion,
  value?: Maybe<Scalars['Int']>,
  isCorrect: Scalars['Boolean'],
};

export type ExamAttemptAnswerInput = {
  examQuestionID: Scalars['ID'],
  value?: Maybe<Scalars['Int']>,
};

export type ExamAttemptConnection = {
   __typename?: 'ExamAttemptConnection',
  nodes?: Maybe<Array<Maybe<ExamAttempt>>>,
  totalCount: Scalars['Int'],
};

export type ExamAttemptFilters = {
  q?: Maybe<Scalars['String']>,
  examID?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<DateRange>,
};

export type ExamAttemptGetOrCreatePayload = {
   __typename?: 'ExamAttemptGetOrCreatePayload',
  examAttempt?: Maybe<ExamAttempt>,
};

export type ExamAttemptInput = {
  targetCompanyID?: Maybe<Scalars['ID']>,
};

export type ExamAttemptOrder = {
  field: ExamAttemptOrderField,
  direction: OrderDirection,
};

export enum ExamAttemptOrderField {
  ID = 'ID'
}

export type ExamAttemptQuestionTypeResult = {
   __typename?: 'ExamAttemptQuestionTypeResult',
  questionType: ExamQuestionType,
  score: Scalars['Int'],
  correctRate?: Maybe<Scalars['Float']>,
};

export type ExamAttemptSectionResult = {
   __typename?: 'ExamAttemptSectionResult',
  isTaken: Scalars['Boolean'],
  section: ExamSection,
  comment?: Maybe<Scalars['String']>,
  score: Scalars['Int'],
  correctRate: Scalars['Float'],
  rank?: Maybe<Scalars['Float']>,
  percentileRank?: Maybe<Scalars['Float']>,
  answers: Array<Maybe<ExamAttemptAnswer>>,
};


export type ExamAttemptSectionResultRankArgs = {
  targetCompanyID?: Maybe<Scalars['ID']>
};


export type ExamAttemptSectionResultPercentileRankArgs = {
  targetCompanyID?: Maybe<Scalars['ID']>
};

export type ExamAttemptSubmitAnswerManyPayload = {
   __typename?: 'ExamAttemptSubmitAnswerManyPayload',
  examAttempt?: Maybe<ExamAttempt>,
  examAttemptAnswers: Array<ExamAttemptAnswer>,
  type?: Maybe<ExamType>,
};

export type ExamAttemptUpdatePayload = {
   __typename?: 'ExamAttemptUpdatePayload',
  examAttempt?: Maybe<ExamAttempt>,
};

export type ExamConnection = {
   __typename?: 'ExamConnection',
  nodes?: Maybe<Array<Maybe<Exam>>>,
  totalCount: Scalars['Int'],
};

export type ExamEvent = {
   __typename?: 'ExamEvent',
  id: Scalars['ID'],
  exam?: Maybe<Exam>,
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ExamFilters = {
  contentIDs?: Maybe<Array<Scalars['ID']>>,
  subject?: Maybe<Scalars['String']>,
};

export type ExamInput = {
  defaultEventID?: Maybe<Scalars['ID']>,
  reportIsReady?: Maybe<Scalars['Boolean']>,
  companyID?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  priority?: Maybe<Scalars['Int']>,
  type?: Maybe<Scalars['Int']>,
  expireDays?: Maybe<Scalars['Int']>,
  content?: Maybe<ContentExamUpdateInput>,
};

export type ExamMission = {
   __typename?: 'ExamMission',
  content: Content,
  standardScore: Scalars['Float'],
};

export type ExamMissionCreateInput = {
  contentID: Scalars['ID'],
  standardScore: Scalars['Float'],
};

export type ExamMissionUpdateInput = {
  contentID?: Maybe<Scalars['ID']>,
  standardScore?: Maybe<Scalars['Float']>,
};

export type ExamOrder = {
  field: ExamOrderField,
  direction: OrderDirection,
};

export enum ExamOrderField {
  ID = 'ID'
}

export type ExamQuestion = {
   __typename?: 'ExamQuestion',
  id: Scalars['ID'],
  page?: Maybe<Scalars['Int']>,
  number?: Maybe<Scalars['Int']>,
  correctRate: Scalars['Float'],
  score: Scalars['Float'],
  type?: Maybe<ExamQuestionType>,
  question: Question,
};

export type ExamQuestionConnection = {
   __typename?: 'ExamQuestionConnection',
  nodes: Array<ExamQuestion>,
  totalCount: Scalars['Int'],
};

export type ExamQuestionCreateInput = {
  sectionID: Scalars['ID'],
  questionTypeID: Scalars['ID'],
  number: Scalars['Int'],
  score?: Maybe<Scalars['Float']>,
  questionCreateInput: QuestionCreateInput,
};

export type ExamQuestionCreatePayload = {
   __typename?: 'ExamQuestionCreatePayload',
  examQuestion?: Maybe<ExamQuestion>,
};

export type ExamQuestionDeleteManyPayload = {
   __typename?: 'ExamQuestionDeleteManyPayload',
  examQuestions?: Maybe<Array<ExamQuestion>>,
};

export type ExamQuestionDeletePayload = {
   __typename?: 'ExamQuestionDeletePayload',
  examQuestion?: Maybe<ExamQuestion>,
};

export type ExamQuestionFilters = {
  sectionID?: Maybe<Scalars['ID']>,
};

export type ExamQuestionOrder = {
  field: ExamQuestionOrderField,
  direction: OrderDirection,
};

export enum ExamQuestionOrderField {
  QUESTION_NUMBER = 'QUESTION_NUMBER'
}

export type ExamQuestionType = {
   __typename?: 'ExamQuestionType',
  id: Scalars['ID'],
  name: Scalars['String'],
  section: ExamSection,
  averageScore?: Maybe<Scalars['Float']>,
  correctRate?: Maybe<Scalars['Float']>,
  contentsRecommendation?: Maybe<Scalars['String']>,
};


export type ExamQuestionTypeAverageScoreArgs = {
  segment?: Maybe<ExamSegment>
};


export type ExamQuestionTypeCorrectRateArgs = {
  segment?: Maybe<ExamSegment>
};

export type ExamQuestionTypeConnection = {
   __typename?: 'ExamQuestionTypeConnection',
  nodes: Array<ExamQuestionType>,
  totalCount: Scalars['Int'],
};

export type ExamQuestionTypeDeletePayload = {
   __typename?: 'ExamQuestionTypeDeletePayload',
  examQuestionType?: Maybe<ExamQuestionType>,
};

export type ExamQuestionTypeFilters = {
  examSectionID?: Maybe<Scalars['ID']>,
};

export type ExamQuestionTypeInput = {
  name?: Maybe<Scalars['String']>,
};

export type ExamQuestionTypeOrder = {
  field: ExamQuestionTypeOrderField,
  direction: OrderDirection,
};

export enum ExamQuestionTypeOrderField {
  ID = 'ID'
}

export type ExamQuestionTypeUpdatePayload = {
   __typename?: 'ExamQuestionTypeUpdatePayload',
  examQuestionType?: Maybe<ExamQuestionType>,
};

export type ExamQuestionUpdateInput = {
  sectionID?: Maybe<Scalars['ID']>,
  questionTypeID?: Maybe<Scalars['ID']>,
  number?: Maybe<Scalars['Int']>,
  score?: Maybe<Scalars['Float']>,
  questionUpdateInput: QuestionUpdateInput,
};

export type ExamQuestionUpdatePayload = {
   __typename?: 'ExamQuestionUpdatePayload',
  examQuestion?: Maybe<ExamQuestion>,
};

export type ExamSection = {
   __typename?: 'ExamSection',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  averageScore?: Maybe<Scalars['Float']>,
  prevScoresForPass?: Maybe<Array<PrevScoreForPass>>,
  timeLimit?: Maybe<Scalars['Int']>,
  questions?: Maybe<ExamQuestionConnection>,
  examQuestionTypes?: Maybe<Array<ExamQuestionType>>,
};


export type ExamSectionAverageScoreArgs = {
  targetCompanyID?: Maybe<Scalars['ID']>
};


export type ExamSectionTimeLimitArgs = {
  unit?: Maybe<TimeUnit>
};


export type ExamSectionQuestionsArgs = {
  orderBy?: Maybe<ExamQuestionOrder>,
  pagination?: Maybe<Pagination>
};

export type ExamSectionConnection = {
   __typename?: 'ExamSectionConnection',
  nodes: Array<ExamSection>,
  totalCount: Scalars['Int'],
};

export type ExamSectionFilters = {
  examID?: Maybe<Scalars['ID']>,
};

export type ExamSectionInput = {
  examQuestionTypeIDs?: Maybe<Array<Scalars['ID']>>,
  name?: Maybe<Scalars['String']>,
  prevScoresForPass?: Maybe<Array<PrevScoreForPassInput>>,
};

export type ExamSectionOrder = {
  field: ExamSectionOrderField,
  direction: OrderDirection,
};

export enum ExamSectionOrderField {
  ID = 'ID'
}

export type ExamSectionUpdatePayload = {
   __typename?: 'ExamSectionUpdatePayload',
  examSection?: Maybe<ExamSection>,
};

export enum ExamSegment {
  TOP10_PERCENT = 'TOP10_PERCENT',
  TOP20_PERCENT = 'TOP20_PERCENT',
  TOP30_PERCENT = 'TOP30_PERCENT'
}

export type ExamSubject = {
   __typename?: 'ExamSubject',
  id: Scalars['ID'],
  category: WishExamCategory,
  examToTake: ExamToTake,
  subject: Scalars['String'],
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type ExamSubjectConnection = {
   __typename?: 'ExamSubjectConnection',
  nodes?: Maybe<Array<Maybe<ExamSubject>>>,
  totalCount: Scalars['Int'],
};

export type ExamSubjectsInput = {
  examCategory?: Maybe<WishExamCategory>,
  examToTake?: Maybe<ExamToTake>,
};

export enum ExamToTake {
  WEPORT = 'WEPORT',
  NCS = 'NCS',
  ENGINIC = 'ENGINIC',
  TALKERBE = 'TALKERBE',
  SEVEN_GONG = 'SEVEN_GONG',
  WEPORT_ENGINIC = 'WEPORT_ENGINIC'
}

export enum ExamType {
  /** 일반형 */
  GENERAL = 'GENERAL',
  /** 실전형 */
  PRACTICE = 'PRACTICE'
}

export type ExamUpdatePayload = {
   __typename?: 'ExamUpdatePayload',
  exam?: Maybe<Exam>,
};

export type FailedUser = {
   __typename?: 'FailedUser',
  userID: Scalars['ID'],
  error: Scalars['String'],
};

export type File = {
   __typename?: 'File',
  id: Scalars['ID'],
  url?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  mimeType?: Maybe<Scalars['String']>,
};

export type FileUploadPayload = {
   __typename?: 'FileUploadPayload',
  file?: Maybe<File>,
};

export type FindIdByPhoneNumberPayload = {
   __typename?: 'findIDByPhoneNumberPayload',
  username: Scalars['String'],
};

export type FindPwByPhoneNumberPayload = {
   __typename?: 'findPWByPhoneNumberPayload',
  recoveryID: Scalars['ID'],
  key: Scalars['String'],
};

export type Freepass = {
   __typename?: 'Freepass',
  id: Scalars['ID'],
  addedDocuments: Array<Document>,
  printedDocuments: Array<Document>,
  printedDocumentCount: Scalars['Int'],
  freepassProduct: FreepassProduct,
};

export type FreepassInvoice = {
   __typename?: 'FreepassInvoice',
  invoice: Invoice,
  playtime: Scalars['Int'],
  netSales: Scalars['Float'],
  subject?: Maybe<Scalars['String']>,
  service?: Maybe<ServiceType>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type FreepassProduct = {
   __typename?: 'FreepassProduct',
  id: Scalars['ID'],
  content: Content,
  commission?: Maybe<Scalars['Int']>,
  videoTotalTime: Scalars['Int'],
  package: Package,
  includeInvoice: Scalars['Boolean'],
  refundNote: Scalars['String'],
  detailHTML: Scalars['String'],
  note: Scalars['String'],
  maxBuyContentCount?: Maybe<Scalars['Int']>,
  maxPrintableDocumentLimit?: Maybe<Scalars['Int']>,
  maxPrintLimitPerDocument?: Maybe<Scalars['Int']>,
  paybackType: PaybackType,
  paybackSet: PaybackSet,
};

export type FreepassProductConnection = {
   __typename?: 'FreepassProductConnection',
  nodes: Array<FreepassProduct>,
  totalCount: Scalars['Int'],
};

export type FreepassProductFilters = {
  q?: Maybe<Scalars['String']>,
};

export type FreepassProductOrder = {
  field: FreepassProductOrderField,
  direction: OrderDirection,
};

export enum FreepassProductOrderField {
  ID = 'ID'
}

export type HtmlText = {
   __typename?: 'HtmlText',
  id: Scalars['ID'],
  text: Scalars['String'],
};

export type HtmlTextConnection = {
   __typename?: 'HtmlTextConnection',
  nodes: Array<HtmlText>,
  totalCount: Scalars['Int'],
};

export type HtmlTextCreatePayload = {
   __typename?: 'HtmlTextCreatePayload',
  htmlText?: Maybe<HtmlText>,
};

export type HtmlTextDeleteManyPayload = {
   __typename?: 'HtmlTextDeleteManyPayload',
  htmlTexts?: Maybe<Array<HtmlText>>,
};

export type HtmlTextDeletePayload = {
   __typename?: 'HtmlTextDeletePayload',
  htmlText?: Maybe<HtmlText>,
};

export type HtmlTextFilters = {
  text?: Maybe<Scalars['String']>,
};

export type HtmlTextInput = {
  text?: Maybe<Scalars['String']>,
};

export type HtmlTextOrder = {
  field: HtmlTextOrderField,
  direction: OrderDirection,
};

export enum HtmlTextOrderField {
  ID = 'ID'
}

export type HtmlTextUpdatePayload = {
   __typename?: 'HtmlTextUpdatePayload',
  htmlText?: Maybe<HtmlText>,
};

export type InfobankSmsSendResult = {
   __typename?: 'InfobankSMSSendResult',
  messageID?: Maybe<Scalars['String']>,
  destination?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  errorText?: Maybe<Scalars['String']>,
};

export type IntegerRange = {
  gt?: Maybe<Scalars['Int']>,
  gte?: Maybe<Scalars['Int']>,
  lt?: Maybe<Scalars['Int']>,
  lte?: Maybe<Scalars['Int']>,
};

export type IntegratedCommunityBoard = {
   __typename?: 'IntegratedCommunityBoard',
  id: Scalars['ID'],
  title: Scalars['String'],
  description?: Maybe<Scalars['String']>,
};

export type IntegratedCommunityBoardPayload = {
   __typename?: 'IntegratedCommunityBoardPayload',
  totalCount: Scalars['Int'],
  nodes?: Maybe<Array<IntegratedCommunityBoard>>,
};

export type IntegratedCommunityComment = {
   __typename?: 'integratedCommunityComment',
  id: Scalars['ID'],
};

export type IntegratedCommunityPost = {
   __typename?: 'integratedCommunityPost',
  id: Scalars['ID'],
  allowTrackback?: Maybe<Scalars['Boolean']>,
  anonymous?: Maybe<Scalars['Boolean']>,
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
  boardID: Scalars['ID'],
  commentStatus?: Maybe<PostCommentStatus>,
  containsImage?: Maybe<Scalars['Boolean']>,
  containsYoutube?: Maybe<Scalars['Boolean']>,
  content: Scalars['String'],
  contentText: Scalars['String'],
  imageSrc?: Maybe<Scalars['String']>,
  isNotice?: Maybe<Scalars['Boolean']>,
  notifyMessage?: Maybe<Scalars['Boolean']>,
  status: PostStatus,
  tags?: Maybe<Array<Scalars['String']>>,
  title: Scalars['String'],
  youtubeSrc?: Maybe<Scalars['String']>,
};

export type IntegratedCommunityPostUpdateInput = {
  anonymous?: Maybe<Scalars['Boolean']>,
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
  content: Scalars['String'],
  contentText: Scalars['String'],
  title: Scalars['String'],
};

export type IntegratedCommunityPostUpdatePayload = {
   __typename?: 'IntegratedCommunityPostUpdatePayload',
  post: IntegratedCommunityPost,
};

export type Invoice = {
   __typename?: 'Invoice',
  id: Scalars['ID'],
  subject?: Maybe<Scalars['String']>,
  freepassInvoice?: Maybe<FreepassInvoice>,
  salesByContent: Array<InvoiceSaleByContent>,
  refundsByContent: Array<InvoiceRefundByContent>,
  freepassNetSalesAmount?: Maybe<Scalars['Float']>,
  contentGrossSalesAmount?: Maybe<Scalars['Float']>,
  contentPGCommissionAmount?: Maybe<Scalars['Float']>,
  contentNetSalesAmount?: Maybe<Scalars['Float']>,
  refundPriceAmount?: Maybe<Scalars['Float']>,
  refundPGCommissionAmount?: Maybe<Scalars['Float']>,
  netRefundsAmount?: Maybe<Scalars['Float']>,
  /** freepassNetSalesAmount + contentNetSalesAmount - netRefundsAmount */
  paidAmount?: Maybe<Scalars['Float']>,
  confirmState?: Maybe<ConfirmState>,
  incomeTax?: Maybe<Scalars['Int']>,
  localTax?: Maybe<Scalars['Int']>,
  totalTax?: Maybe<Scalars['Int']>,
  /** amountAtPaid - totalTax */
  totalNetAmount: Scalars['Float'],
  teacher: Teacher,
  year: Scalars['Int'],
  month: Scalars['Int'],
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type InvoiceConnection = {
   __typename?: 'InvoiceConnection',
  nodes?: Maybe<Array<Maybe<Invoice>>>,
  totalCount: Scalars['Int'],
};

export type InvoiceFilters = {
  id?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<DateRange>,
  teacherID?: Maybe<Scalars['ID']>,
  onlineOrOffline?: Maybe<OnlineOrOffline>,
};

export type InvoiceOrder = {
  field: InvoiceOrderField,
  direction: OrderDirection,
};

export enum InvoiceOrderField {
  ID = 'ID',
  CREATED_AT = 'CREATED_AT'
}

export type InvoiceRefundByContent = {
   __typename?: 'InvoiceRefundByContent',
  invoice: Invoice,
  content: Content,
  refundPrice: Scalars['Float'],
  netRefund: Scalars['Float'],
  quantity: Scalars['Int'],
  pgCommission?: Maybe<Scalars['Float']>,
  teacherContractRate: Scalars['Float'],
  teacherContentParticipationRate: Scalars['Float'],
};

export type InvoiceSaleByContent = {
   __typename?: 'InvoiceSaleByContent',
  invoice: Invoice,
  content: Content,
  quantity: Scalars['Int'],
  teacherContractRate: Scalars['Float'],
  /** 소수점 3번째 자리수에서 반올림 해주어야 함 */
  teacherContentParticipationRate: Scalars['Float'],
  pgCommission: Scalars['Float'],
  /** 판매 금액 */
  grossSales: Scalars['Int'],
  /** 판매금액에서 수수료와 정산율을 제한 정산 금액 */
  netSales: Scalars['Float'],
};

export enum JoinRoute {
  NAVER_SEARCH = 'NAVER_SEARCH',
  GOOGLE_SEARCH = 'GOOGLE_SEARCH',
  FACEBOOK_AND_INSTAGRAM = 'FACEBOOK_AND_INSTAGRAM',
  NAVER_CAFE = 'NAVER_CAFE',
  INTERNET_BANNER_AD = 'INTERNET_BANNER_AD',
  OPEN_CHAT = 'OPEN_CHAT',
  YOUTUBE = 'YOUTUBE',
  WEPORT_BOOK = 'WEPORT_BOOK',
  FRIENDS_INTRODUCTION = 'FRIENDS_INTRODUCTION',
  ETC = 'ETC'
}

export type Keyword = {
   __typename?: 'Keyword',
  id: Scalars['ID'],
  code: Scalars['String'],
  keywords: Array<Scalars['String']>,
};

export enum LeaveReason {
  CONTENT_LACK = 'CONTENT_LACK',
  SLOW_PROCESS = 'SLOW_PROCESS',
  RE_SIGNUP = 'RE_SIGNUP',
  INCONVENIENCE = 'INCONVENIENCE',
  PASS = 'PASS',
  ETC = 'ETC'
}

export type LectureMission = {
   __typename?: 'LectureMission',
  content: Content,
  standardProgressRate: Scalars['Float'],
};

export type LectureMissionCreateInput = {
  contentID: Scalars['ID'],
};

export type LectureMissionUpdateInput = {
  contentID?: Maybe<Scalars['ID']>,
  standardProgressRate?: Maybe<Scalars['Float']>,
};

export type LoginPayload = {
   __typename?: 'LoginPayload',
  status: LoginStatus,
  user?: Maybe<User>,
  /** 
 * Inactive User일 시에
   * 휴면 해제를 위한 토큰
 */
  token?: Maybe<Scalars['String']>,
};

export enum LoginStatus {
  SUCCESS = 'SUCCESS',
  INACTIVE = 'INACTIVE',
  INVAILD_INPUT = 'INVAILD_INPUT'
}

export type Major = {
   __typename?: 'Major',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type MajorConnection = {
   __typename?: 'MajorConnection',
  nodes: Array<Major>,
  totalCount: Scalars['Int'],
};

export type MajorFilters = {
  q?: Maybe<Scalars['String']>,
};

export type MajorOrder = {
  field: MajorOrderField,
  direction: OrderDirection,
};

export enum MajorOrderField {
  ID = 'ID',
  NAME = 'NAME'
}

export type Membership = {
   __typename?: 'Membership',
  id: Scalars['ID'],
  role?: Maybe<MembershipRole>,
  user?: Maybe<User>,
  team?: Maybe<Team>,
  createdAt?: Maybe<Scalars['Date']>,
};

export type MembershipConnection = {
   __typename?: 'MembershipConnection',
  edges: Array<Membership>,
  totalCount: Scalars['Int'],
};

export type MembershipCreatePayload = {
   __typename?: 'MembershipCreatePayload',
  membership?: Maybe<Membership>,
};

export type MembershipDeleteManyPayload = {
   __typename?: 'MembershipDeleteManyPayload',
  memberships?: Maybe<Array<Membership>>,
};

export type MembershipDeletePayload = {
   __typename?: 'MembershipDeletePayload',
  membership?: Maybe<Membership>,
};

export type MembershipFilters = {
  role?: Maybe<MembershipRole>,
  userID?: Maybe<Scalars['ID']>,
  teamID?: Maybe<Scalars['ID']>,
};

export type MembershipInput = {
  role?: Maybe<MembershipRole>,
  userID?: Maybe<Scalars['ID']>,
  teamID?: Maybe<Scalars['ID']>,
};

export type MembershipOrder = {
  field: MembershipOrderField,
  direction?: Maybe<OrderDirection>,
};

export enum MembershipOrderField {
  ID = 'ID'
}

export enum MembershipRole {
  MEMBER = 'MEMBER'
}

export type MembershipUpdatePayload = {
   __typename?: 'MembershipUpdatePayload',
  membership?: Maybe<Membership>,
};

export type Menu = {
   __typename?: 'Menu',
  id: Scalars['ID'],
  code: Scalars['String'],
  title: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  items: Array<MenuItem>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type MenuConnection = {
   __typename?: 'MenuConnection',
  nodes?: Maybe<Array<Maybe<Menu>>>,
  totalCount: Scalars['Int'],
};

export type MenuCreateInput = {
  code: Scalars['String'],
  title: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  items?: Maybe<Array<MenuItemInput>>,
};

export type MenuCreatePayload = {
   __typename?: 'MenuCreatePayload',
  menu?: Maybe<Menu>,
};

export type MenuDeleteManyPayload = {
   __typename?: 'MenuDeleteManyPayload',
  menus?: Maybe<Array<Maybe<Menu>>>,
};

export type MenuDeletePayload = {
   __typename?: 'MenuDeletePayload',
  menu?: Maybe<Menu>,
};

export type MenuFilters = {
  q?: Maybe<Scalars['String']>,
  menuID?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type MenuItem = {
   __typename?: 'MenuItem',
  label: Scalars['String'],
  open: Scalars['Boolean'],
  linkURL?: Maybe<Scalars['String']>,
  children: Array<MenuItem>,
};

export type MenuItemInput = {
  label: Scalars['String'],
  open: Scalars['Boolean'],
  linkURL?: Maybe<Scalars['String']>,
  children: Array<MenuItemInput>,
};

export type MenuOrder = {
  field: MenuOrderField,
  direction: OrderDirection,
};

export enum MenuOrderField {
  ID = 'ID'
}

export type MenuUpdateInput = {
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  items?: Maybe<Array<MenuItemInput>>,
};

export type MenuUpdatePayload = {
   __typename?: 'MenuUpdatePayload',
  menu?: Maybe<Menu>,
};

export type MissionDetails = AssignmentFileMission | AssignmentLinkMission | LectureMission | ExamMission | ReviewMission;

export enum MissionType {
  ASSIGNMENT_FILE = 'ASSIGNMENT_FILE',
  ASSIGNMENT_LINK = 'ASSIGNMENT_LINK',
  TAKE_LECTURE = 'TAKE_LECTURE',
  TAKE_EXAM = 'TAKE_EXAM',
  REVIEW = 'REVIEW'
}

export type Mutation = {
   __typename?: 'Mutation',
  allianceCreate?: Maybe<AllianceCreatePayload>,
  allianceUserCreate?: Maybe<AllianceUserCreatePayload>,
  allianceUserUpdate?: Maybe<AllianceUserUpdatePayload>,
  bannerCreate?: Maybe<BannerCreatePayload>,
  bannerUpdate?: Maybe<BannerUpdatePayload>,
  bannerGroupCreate?: Maybe<BannerGroupCreatePayload>,
  bannerGroupUpdate?: Maybe<BannerGroupUpdatePayload>,
  bannerClickLog?: Maybe<Scalars['Boolean']>,
  bannerGroupDeleteMany?: Maybe<BannerGroupDeleteManyPayload>,
  bookCreate?: Maybe<BookCreatePayload>,
  bookUpdate?: Maybe<BookUpdatePayload>,
  bookDelete?: Maybe<BookDeletePayload>,
  bookDeleteMany?: Maybe<BookDeleteManyPayload>,
  bookYearInit: Scalars['Boolean'],
  campaignCreate?: Maybe<CampaignCreateOrUpdatePayload>,
  campaignUpdate?: Maybe<CampaignCreateOrUpdatePayload>,
  campaignDelete?: Maybe<CampaignDeletePayload>,
  campaignBenefitCreate?: Maybe<CampaignBenefitMutationPayload>,
  campaignBenefitUpdate?: Maybe<CampaignBenefitMutationPayload>,
  campaignBenefitDelete?: Maybe<CampaignBenefitMutationPayload>,
  campaignUserCreateMany?: Maybe<CampaignUserMutationPayload>,
  campaignUserDeleteMany?: Maybe<CampaignUserMutationPayload>,
  provideCampaignBenefits: ProvideCampaignBenefitPayload,
  companyCreate?: Maybe<CompanyCreatePayload>,
  companyUpdate?: Maybe<CompanyUpdatePayload>,
  companyDelete?: Maybe<CompanyDeletePayload>,
  companyDeleteMany?: Maybe<CompanyDeleteManyPayload>,
  contentCreate?: Maybe<ContentCreatePayload>,
  contentUpdate?: Maybe<ContentUpdatePayload>,
  contentDelete?: Maybe<ContentDeletePayload>,
  contentDeleteMany?: Maybe<ContentDeleteManyPayload>,
  contentCurationCreate?: Maybe<ContentCurationCreatePayload>,
  contentCurationUpdate?: Maybe<ContentCurationUpdatePayload>,
  contentCurationDelete?: Maybe<ContentCurationDeletePayload>,
  contentCurationDeleteMany?: Maybe<ContentCurationDeleteManyPayload>,
  couponUpdate?: Maybe<CouponUpdatePayload>,
  couponDelete?: Maybe<CouponDeletePayload>,
  couponConnectedContentDelete?: Maybe<ConnectedContentDeletePayload>,
  couponConnectedContentCreate?: Maybe<CreateConnectedContentPayload>,
  createCloneExam: CloneExamCreatePayload,
  provideFreeContent: ProvideFreeContentPayload,
  provideFreeContentForUsers: ProvideFreeContentForUsersPayload,
  contentPurchaseRanksDailyUpdate: Scalars['Boolean'],
  contentPurchaseStatDailyUpdate: Scalars['Boolean'],
  contentPurchaseStatDailyUpdateAll: Scalars['Boolean'],
  contentRefundStatDailyUpdate: Scalars['Boolean'],
  contentRefundStatDailyUpdateAll: Scalars['Boolean'],
  examUpdate: ExamUpdatePayload,
  examSectionUpdate: ExamSectionUpdatePayload,
  examQuestionTypeUpdate: ExamQuestionTypeUpdatePayload,
  examQuestionTypeDelete: ExamQuestionTypeDeletePayload,
  examQuestionCreate: ExamQuestionCreatePayload,
  examQuestionUpdate: ExamQuestionUpdatePayload,
  examQuestionDelete: ExamQuestionDeletePayload,
  examQuestionDeleteMany: ExamQuestionDeleteManyPayload,
  examAttemptGetOrCreate: ExamAttemptGetOrCreatePayload,
  examAttemptUpdate: ExamAttemptUpdatePayload,
  examAttemptAnswerSubmitMany?: Maybe<ExamAttemptSubmitAnswerManyPayload>,
  fileUpload: FileUploadPayload,
  htmlTextCreate?: Maybe<HtmlTextCreatePayload>,
  htmlTextUpdate?: Maybe<HtmlTextUpdatePayload>,
  htmlTextDelete?: Maybe<HtmlTextDeletePayload>,
  htmlTextDeleteMany?: Maybe<HtmlTextDeleteManyPayload>,
  menuCreate?: Maybe<MenuCreatePayload>,
  menuUpdate?: Maybe<MenuUpdatePayload>,
  menuDelete?: Maybe<MenuDeletePayload>,
  menuDeleteMany?: Maybe<MenuDeleteManyPayload>,
  offlineCreate?: Maybe<OfflineCreatePayload>,
  offlineUpdate?: Maybe<OfflineUpdatePayload>,
  offlineRepurchaseDiscountUpdate?: Maybe<OfflineRepurchaseDiscountUpdatePayload>,
  offlineChildrenContentsUpdate?: Maybe<OfflineChildrenContentsUpdatePayload>,
  offlineStudentClassChange: OfflineStudentClassChangePayload,
  permissionGrant?: Maybe<PermissionGrantPayload>,
  permissionRevoke?: Maybe<PermissionRevokePayload>,
  postCurationUpdate?: Maybe<PostCurationUpdatePayload>,
  /** 환불처리를 진행한다. 구매한 컨텐츠와 혜택으로 지급된 추가 컨텐츠 / 쿠폰등까지 모두 회수한다. */
  refundCreate?: Maybe<RefundCreatePayload>,
  resumeProductCreate?: Maybe<ResumeProductCreatePayload>,
  resumeProductUpdate?: Maybe<ResumeProductUpdatePayload>,
  resumeProductDelete?: Maybe<ResumeProductDeletePayload>,
  resumeProductDeleteMany?: Maybe<ResumeProductDeleteManyPayload>,
  resumeCreate?: Maybe<ResumeCreatePayload>,
  resumeUpdate?: Maybe<ResumeUpdatePayload>,
  reviewCreate?: Maybe<ReviewCreatePayload>,
  reviewUpdate?: Maybe<ReviewUpdatePayload>,
  reviewDelete?: Maybe<ReviewDeletePayload>,
  reviewDeleteMany?: Maybe<ReviewDeleteManyPayload>,
  scheduleCreate?: Maybe<ScheduleCreatePayload>,
  scheduleUpdate?: Maybe<ScheduleUpdatePayload>,
  scheduleDelete?: Maybe<ScheduleDeletePayload>,
  scheduleDeleteMany?: Maybe<ScheduleDeleteManyPayload>,
  scheduleEventCreate?: Maybe<ScheduleEventCreatePayload>,
  scheduleEventUpdate?: Maybe<ScheduleEventUpdatePayload>,
  scheduleEventDelete?: Maybe<ScheduleEventDeletePayload>,
  scheduleEventDeleteMany?: Maybe<ScheduleEventDeleteManyPayload>,
  schedulesUpdateIfAutoUpdate?: Maybe<ScheduleUpdateLambdaHandlerPayload>,
  smsRejectCreate?: Maybe<SmsReject>,
  studyCreate?: Maybe<StudyCreatePayload>,
  studyEventCreate?: Maybe<StudyEventCreatePayload>,
  studyBenefitCreate?: Maybe<StudyBenefitCreatePayload>,
  studyMissionCreate?: Maybe<StudyMissionCreatePayload>,
  studyEventBulkCreate?: Maybe<StudyEventBulkCreatePayload>,
  studyUpdate?: Maybe<StudyUpdatePayload>,
  studyBenefitUpdate?: Maybe<StudyBenefitUpdatePayload>,
  studyMissionUpdate?: Maybe<StudyMissionUpdatePayload>,
  studyDelete?: Maybe<StudyDeletePayload>,
  studyDeleteMany?: Maybe<StudyDeleteManyPayload>,
  studyBenefitDelete?: Maybe<StudyBenefitDeletePayload>,
  studyMissionDelete?: Maybe<StudyMissionDeletePayload>,
  studyMissionDeleteMany?: Maybe<StudyMissionDeleteManyPayload>,
  bulkUpdateStudyEventInStudy?: Maybe<StudyCreatePayload>,
  studyRegister?: Maybe<UserStudyRegisterPayload>,
  provideUserStudyBenefit?: Maybe<UserStudyBenefitCreatePayload>,
  userStudyEventsForcePass?: Maybe<UserStudyEventForcePassPayload>,
  userStudyEventsPass?: Maybe<UserStudyEventPassPayload>,
  userStudyEventsFail?: Maybe<UserStudyEventFailPayload>,
  userStudyMissionUpdate?: Maybe<UserStudyMissionUpdatePayload>,
  userStudyMissionsUpdate?: Maybe<UserStudyMissionsUpdatePayload>,
  userStudyMissionAssignmentLinkUpload?: Maybe<UserStudyMissionAssignmentLinkUploadPayload>,
  userStudyMissionAssignmentLinkUploadCommunity?: Maybe<UserStudyMissionAssignmentLinkUploadPayload>,
  userStudyMissionReviewUpload?: Maybe<UserStudyMissionReviewUploadPayload>,
  userStudyMissionReviewUploadCommunity?: Maybe<UserStudyMissionReviewUploadPayload>,
  userStudyMissionAssignmentFileUpload?: Maybe<UserStudyMissionAssignmentFileUploadPayload>,
  attachmentPrepareUpload?: Maybe<AttachmentPrepareUploadPayload>,
  attachmentCompleteUpload?: Maybe<AttachmentCompleteUploadPayload>,
  integratedCommunityPostUpdate?: Maybe<IntegratedCommunityPostUpdatePayload>,
  /** 스터디 미션 진행상태를 최신화합니다. */
  userStudyEventUpdateStateWhereEnded: Array<UserStudyEvent>,
  /** 스터디 강의 재생시간을 최신화합니다. */
  userStudyUpdatePlaytimesWhereInProgress: Array<UserStudy>,
  /** 유저에게 중복 지급된 스터디 미션을 회수/삭제한다 */
  userStudyMissionDeleteDuplicated?: Maybe<Array<Maybe<Array<UserStudyMission>>>>,
  teacherCreate?: Maybe<TeacherCreatePayload>,
  teacherUpdate?: Maybe<TeacherUpdatePayload>,
  teacherDelete?: Maybe<TeacherDeletePayload>,
  teacherCurationCreate?: Maybe<TeacherCurationCreatePayload>,
  teacherCurationUpdate?: Maybe<TeacherCurationUpdatePayload>,
  teacherCurationDelete?: Maybe<TeacherCurationDeletePayload>,
  teacherCurationDeleteMany?: Maybe<TeacherCurationDeleteManyPayload>,
  teamCreate?: Maybe<TeamCreatePayload>,
  teamUpdate?: Maybe<TeamUpdatePayload>,
  teamDelete?: Maybe<TeamDeletePayload>,
  teamDeleteMany?: Maybe<TeamDeleteManyPayload>,
  membershipCreate?: Maybe<MembershipCreatePayload>,
  membershipUpdate?: Maybe<MembershipUpdatePayload>,
  membershipDelete?: Maybe<MembershipDeletePayload>,
  membershipDeleteMany?: Maybe<MembershipDeleteManyPayload>,
  login?: Maybe<LoginPayload>,
  logout?: Maybe<Scalars['Boolean']>,
  tempAccountSignup?: Maybe<TempAccountSignupPayload>,
  tempAccountLogout?: Maybe<Scalars['Boolean']>,
  tempAccountLinkToUser?: Maybe<TempAccountLinkToUserPayload>,
  phoneSendMessageForFindID?: Maybe<PhoneSendMessageForFindIdPayload>,
  phoneSendMessageForFindPW?: Maybe<PhoneSendMessageForFindPwPayload>,
  emailSendMessageForFindID?: Maybe<EmailSendMessageForFindIdPayload>,
  emailSendMessageForFindPW?: Maybe<EmailSendMessageForFindPwPayload>,
  findIDByPhoneNumber?: Maybe<FindIdByPhoneNumberPayload>,
  findPWByPhoneNumber?: Maybe<FindPwByPhoneNumberPayload>,
  updatePW?: Maybe<UpdatePwPayload>,
  updatePWByUsername?: Maybe<UpdatePwByUsernamePayload>,
  updateUserInfo?: Maybe<UserInfoUpdatePayload>,
  adminUpdateUserInfo?: Maybe<UserInfoUpdatePayload>,
  activateUser: ActiveUserPayload,
  userDeviceDelete?: Maybe<UserDeviceDeletePayload>,
  userPointCreate?: Maybe<UserPointCreatePayload>,
  userPointUpdate?: Maybe<UserPointUpdatePayload>,
  userPointDelete?: Maybe<UserPointDeletePayload>,
  userPointDeleteMany?: Maybe<UserPointDeleteManyPayload>,
  userRegisterStatDailyUpdate: Scalars['Boolean'],
  videoUpdate: VideoUpdatePayload,
  /** 
 * 선택한 강의를 재생할 수 있는 url을 리턴한다.
   * 뮤테이션 실행 시 강의 재생로그가 기록된다.
 */
  videoLessonPlay: Scalars['String'],
  videoProductCreate?: Maybe<VideoProductCreatePayload>,
  videoProductUpdate?: Maybe<VideoProductUpdatePayload>,
  videoProductDelete?: Maybe<VideoProductDeletePayload>,
  videoProductDeleteMany?: Maybe<VideoProductDeleteManyPayload>,
  passwordCheck: Scalars['Boolean'],
  messageSendToPhone: Scalars['String'],
  messageSendToCurrentUserPhone: Scalars['String'],
  phoneCodeAuthenticate: Scalars['String'],
  leave: UserLeavePayload,
  deleteUser: UserLeavePayload,
  activateDormantUser: ActivateDormantUserPayload,
  supportTicketCreate?: Maybe<SupportTicketCreatePayload>,
  supportTicketUpdate?: Maybe<SupportTicketUpdatePayload>,
  supportTicketAnswerTemplateCreate?: Maybe<SupportTicketAnswerTemplateCreatePayload>,
  supportTicketAnswerTemplateUpdate?: Maybe<SupportTicketAnswerTemplateUpdatePayload>,
  supportTicketCategoryCreate?: Maybe<SupportTicketCategoryCreatePayload>,
  supportTicketCategoryUpdate?: Maybe<SupportTicketCategoryUpdatePayload>,
  supportTicketAdminCreate?: Maybe<SupportTicketAdminCreatePayload>,
  supportTicketAdminUpdate?: Maybe<SupportTicketAdminUpdatePayload>,
  validateUsername?: Maybe<Scalars['Boolean']>,
  sendAuthSMS?: Maybe<Scalars['String']>,
  verifyAuthSMS?: Maybe<Scalars['Boolean']>,
  nativeRegister?: Maybe<NativeRegisterPayload>,
  snsRegister?: Maybe<SnsRegisterPayload>,
  allianceRegister?: Maybe<AllianceRegisterPayload>,
};


export type MutationAllianceCreateArgs = {
  input: AllianceCreateInput
};


export type MutationAllianceUserCreateArgs = {
  input: AllianceUserCreateInput
};


export type MutationAllianceUserUpdateArgs = {
  input: AllianceUserUpdateInput
};


export type MutationBannerCreateArgs = {
  input?: Maybe<BannerInput>
};


export type MutationBannerUpdateArgs = {
  id: Scalars['ID'],
  input: BannerInput
};


export type MutationBannerGroupCreateArgs = {
  input: BannerGroupCreateInput
};


export type MutationBannerGroupUpdateArgs = {
  id: Scalars['ID'],
  input: BannerGroupUpdateInput
};


export type MutationBannerClickLogArgs = {
  bannerID: Scalars['ID']
};


export type MutationBannerGroupDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationBookCreateArgs = {
  input: BookCreateInput
};


export type MutationBookUpdateArgs = {
  id: Scalars['ID'],
  input: BookUpdateInput
};


export type MutationBookDeleteArgs = {
  id: Scalars['ID']
};


export type MutationBookDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationCampaignCreateArgs = {
  input: CampaignInput
};


export type MutationCampaignUpdateArgs = {
  id: Scalars['ID'],
  input: CampaignInput
};


export type MutationCampaignDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCampaignBenefitCreateArgs = {
  input: CampaignBenefitInput
};


export type MutationCampaignBenefitUpdateArgs = {
  benefitID: Scalars['ID'],
  input: CampaignBenefitInput
};


export type MutationCampaignBenefitDeleteArgs = {
  benefitID: Scalars['ID']
};


export type MutationCampaignUserCreateManyArgs = {
  campaignID: Scalars['ID'],
  inputs: Array<CampaignUserCreateInput>
};


export type MutationCampaignUserDeleteManyArgs = {
  campaignID: Scalars['ID']
};


export type MutationProvideCampaignBenefitsArgs = {
  campaignID: Scalars['ID'],
  userID: Scalars['ID']
};


export type MutationCompanyCreateArgs = {
  input: CompanyInput
};


export type MutationCompanyUpdateArgs = {
  id: Scalars['ID'],
  input: CompanyInput
};


export type MutationCompanyDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCompanyDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationContentCreateArgs = {
  input: ContentCreateInput
};


export type MutationContentUpdateArgs = {
  id: Scalars['ID'],
  input: ContentUpdateInput
};


export type MutationContentDeleteArgs = {
  id: Scalars['ID']
};


export type MutationContentDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationContentCurationCreateArgs = {
  input: ContentCurationInput
};


export type MutationContentCurationUpdateArgs = {
  id: Scalars['ID'],
  input: ContentCurationInput
};


export type MutationContentCurationDeleteArgs = {
  id: Scalars['ID']
};


export type MutationContentCurationDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationCouponUpdateArgs = {
  id: Scalars['ID'],
  input: CouponInput
};


export type MutationCouponDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCouponConnectedContentDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCouponConnectedContentCreateArgs = {
  couponNumber: Scalars['String'],
  contentID: Scalars['ID']
};


export type MutationCreateCloneExamArgs = {
  parentUserContentID: Scalars['ID']
};


export type MutationProvideFreeContentArgs = {
  userID: Scalars['ID'],
  contentID: Scalars['ID']
};


export type MutationProvideFreeContentForUsersArgs = {
  userID: Array<Scalars['ID']>,
  contentID: Scalars['ID']
};


export type MutationContentPurchaseRanksDailyUpdateArgs = {
  date?: Maybe<Scalars['Date']>,
  token?: Maybe<Scalars['String']>
};


export type MutationContentPurchaseStatDailyUpdateArgs = {
  service?: Maybe<ContentPurchaseStatServiceFilter>,
  contentType?: Maybe<ContentPurchaseStatContentType>,
  token?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['Date']>
};


export type MutationContentPurchaseStatDailyUpdateAllArgs = {
  token?: Maybe<Scalars['String']>
};


export type MutationContentRefundStatDailyUpdateArgs = {
  service?: Maybe<ContentPurchaseStatServiceFilter>,
  contentType?: Maybe<ContentPurchaseStatContentType>,
  token?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['Date']>
};


export type MutationContentRefundStatDailyUpdateAllArgs = {
  token?: Maybe<Scalars['String']>
};


export type MutationExamUpdateArgs = {
  id: Scalars['ID'],
  input: ExamInput
};


export type MutationExamSectionUpdateArgs = {
  id: Scalars['ID'],
  input: ExamSectionInput
};


export type MutationExamQuestionTypeUpdateArgs = {
  id: Scalars['ID'],
  input: ExamQuestionTypeInput
};


export type MutationExamQuestionTypeDeleteArgs = {
  id: Scalars['ID']
};


export type MutationExamQuestionCreateArgs = {
  input: ExamQuestionCreateInput
};


export type MutationExamQuestionUpdateArgs = {
  id: Scalars['ID'],
  input: ExamQuestionUpdateInput
};


export type MutationExamQuestionDeleteArgs = {
  id: Scalars['ID']
};


export type MutationExamQuestionDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationExamAttemptGetOrCreateArgs = {
  userID: Scalars['ID'],
  examEventID: Scalars['ID'],
  targetCompanyID?: Maybe<Scalars['ID']>
};


export type MutationExamAttemptUpdateArgs = {
  id: Scalars['ID'],
  input: ExamAttemptInput
};


export type MutationExamAttemptAnswerSubmitManyArgs = {
  examAttemptID: Scalars['ID'],
  inputs: Array<ExamAttemptAnswerInput>
};


export type MutationFileUploadArgs = {
  file?: Maybe<Scalars['Upload']>,
  mode?: Maybe<BucketMode>
};


export type MutationHtmlTextCreateArgs = {
  input: HtmlTextInput
};


export type MutationHtmlTextUpdateArgs = {
  id: Scalars['ID'],
  input: HtmlTextInput
};


export type MutationHtmlTextDeleteArgs = {
  id: Scalars['ID']
};


export type MutationHtmlTextDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationMenuCreateArgs = {
  input: MenuCreateInput
};


export type MutationMenuUpdateArgs = {
  id: Scalars['ID'],
  input: MenuUpdateInput
};


export type MutationMenuDeleteArgs = {
  id: Scalars['ID']
};


export type MutationMenuDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationOfflineCreateArgs = {
  input: OfflineCreateInput
};


export type MutationOfflineUpdateArgs = {
  id: Scalars['ID'],
  input: OfflineUpdateInput
};


export type MutationOfflineRepurchaseDiscountUpdateArgs = {
  offlineID: Scalars['ID'],
  inputs?: Maybe<Array<OfflineRepurchaseDiscountInput>>
};


export type MutationOfflineChildrenContentsUpdateArgs = {
  offlineID: Scalars['ID'],
  childrenOfflineIDs: Array<Scalars['ID']>
};


export type MutationOfflineStudentClassChangeArgs = {
  userContentID: Scalars['ID'],
  contentID: Scalars['ID']
};


export type MutationPermissionGrantArgs = {
  teamID: Scalars['ID'],
  scope: PermissionScope
};


export type MutationPermissionRevokeArgs = {
  id: Scalars['ID']
};


export type MutationPostCurationUpdateArgs = {
  id: Scalars['ID'],
  input: PostCurationInput
};


export type MutationRefundCreateArgs = {
  input: RefundCreateInput
};


export type MutationResumeProductCreateArgs = {
  input: ResumeProductInput
};


export type MutationResumeProductUpdateArgs = {
  id: Scalars['ID'],
  input: ResumeProductInput
};


export type MutationResumeProductDeleteArgs = {
  id: Scalars['ID']
};


export type MutationResumeProductDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationResumeCreateArgs = {
  input: ResumeCreateInput
};


export type MutationResumeUpdateArgs = {
  id: Scalars['ID'],
  input: ResumeUpdateInput
};


export type MutationReviewCreateArgs = {
  input: ReviewCreateInput
};


export type MutationReviewUpdateArgs = {
  id: Scalars['ID'],
  input: ReviewInput
};


export type MutationReviewDeleteArgs = {
  id: Scalars['ID']
};


export type MutationReviewDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationScheduleCreateArgs = {
  input: ScheduleInput
};


export type MutationScheduleUpdateArgs = {
  id: Scalars['ID'],
  input: ScheduleInput
};


export type MutationScheduleDeleteArgs = {
  id: Scalars['ID']
};


export type MutationScheduleDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationScheduleEventCreateArgs = {
  input: ScheduleEventCreateInput
};


export type MutationScheduleEventUpdateArgs = {
  id: Scalars['ID'],
  input: ScheduleEventUpdateInput
};


export type MutationScheduleEventDeleteArgs = {
  id: Scalars['ID']
};


export type MutationScheduleEventDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationSchedulesUpdateIfAutoUpdateArgs = {
  token: Scalars['String']
};


export type MutationSmsRejectCreateArgs = {
  input: SmsRejectInput
};


export type MutationStudyCreateArgs = {
  input: StudyCreateInput
};


export type MutationStudyEventCreateArgs = {
  input: StudyEventInput
};


export type MutationStudyBenefitCreateArgs = {
  input: StudyBenefitInput
};


export type MutationStudyMissionCreateArgs = {
  input: StudyMissionCreateInput
};


export type MutationStudyEventBulkCreateArgs = {
  inputs: Array<StudyEventInput>
};


export type MutationStudyUpdateArgs = {
  id: Scalars['ID'],
  input: StudyUpdateInput
};


export type MutationStudyBenefitUpdateArgs = {
  id: Scalars['ID'],
  input: StudyBenefitInput
};


export type MutationStudyMissionUpdateArgs = {
  id: Scalars['ID'],
  input: StudyMissionUpdateInput
};


export type MutationStudyDeleteArgs = {
  id: Scalars['ID']
};


export type MutationStudyDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationStudyBenefitDeleteArgs = {
  id: Scalars['ID']
};


export type MutationStudyMissionDeleteArgs = {
  id: Scalars['ID']
};


export type MutationStudyMissionDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationBulkUpdateStudyEventInStudyArgs = {
  studyID: Scalars['ID'],
  input: Array<StudyEventBulkInput>
};


export type MutationStudyRegisterArgs = {
  input: StudyRegisterInput
};


export type MutationProvideUserStudyBenefitArgs = {
  input: UserStudyBenefitCreateInput
};


export type MutationUserStudyEventsForcePassArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationUserStudyEventsPassArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationUserStudyEventsFailArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationUserStudyMissionUpdateArgs = {
  id: Scalars['ID'],
  input: UserStudyMissionUpdateInput
};


export type MutationUserStudyMissionsUpdateArgs = {
  ids: Array<Scalars['ID']>,
  input?: Maybe<UserStudyMissionUpdateInput>
};


export type MutationUserStudyMissionAssignmentLinkUploadArgs = {
  id: Scalars['ID'],
  input: UserStudyMissionAssignmentLinkUploadInput
};


export type MutationUserStudyMissionAssignmentLinkUploadCommunityArgs = {
  id: Scalars['ID'],
  input: UserStudyMissionAssignmentLinkUploadCommunityInput
};


export type MutationUserStudyMissionReviewUploadArgs = {
  id: Scalars['ID'],
  input: UserStudyMissionReviewUploadInput
};


export type MutationUserStudyMissionReviewUploadCommunityArgs = {
  id: Scalars['ID'],
  input: UserStudyMissionReviewUploadCommunityInput
};


export type MutationUserStudyMissionAssignmentFileUploadArgs = {
  id: Scalars['ID'],
  input: UserStudyMissionAssignmentFileUploadInput
};


export type MutationAttachmentPrepareUploadArgs = {
  input: AttachmentPrepareUploadInput
};


export type MutationAttachmentCompleteUploadArgs = {
  input: AttachmentCompleteUploadInput
};


export type MutationIntegratedCommunityPostUpdateArgs = {
  id: Scalars['ID'],
  input: IntegratedCommunityPostUpdateInput
};


export type MutationUserStudyEventUpdateStateWhereEndedArgs = {
  token?: Maybe<Scalars['String']>
};


export type MutationUserStudyMissionDeleteDuplicatedArgs = {
  studyMissionID: Scalars['ID']
};


export type MutationTeacherCreateArgs = {
  input: TeacherInput
};


export type MutationTeacherUpdateArgs = {
  id: Scalars['ID'],
  input: TeacherInput
};


export type MutationTeacherDeleteArgs = {
  id: Scalars['ID']
};


export type MutationTeacherCurationCreateArgs = {
  input: TeacherCurationInput
};


export type MutationTeacherCurationUpdateArgs = {
  id: Scalars['ID'],
  input: TeacherCurationInput
};


export type MutationTeacherCurationDeleteArgs = {
  id: Scalars['ID']
};


export type MutationTeacherCurationDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationTeamCreateArgs = {
  input: TeamInput
};


export type MutationTeamUpdateArgs = {
  id: Scalars['ID'],
  input: TeamInput
};


export type MutationTeamDeleteArgs = {
  id: Scalars['ID']
};


export type MutationTeamDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationMembershipCreateArgs = {
  input: MembershipInput
};


export type MutationMembershipUpdateArgs = {
  id: Scalars['ID'],
  input: MembershipInput
};


export type MutationMembershipDeleteArgs = {
  id: Scalars['ID']
};


export type MutationMembershipDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationLoginArgs = {
  username: Scalars['String'],
  password: Scalars['String']
};


export type MutationTempAccountSignupArgs = {
  input?: Maybe<TempAccountInput>
};


export type MutationPhoneSendMessageForFindIdArgs = {
  phoneNumber: Scalars['PhoneNumber'],
  name: Scalars['String']
};


export type MutationPhoneSendMessageForFindPwArgs = {
  phoneNumber: Scalars['PhoneNumber'],
  name: Scalars['String'],
  username: Scalars['String']
};


export type MutationEmailSendMessageForFindIdArgs = {
  email: Scalars['EmailAddress'],
  name: Scalars['String']
};


export type MutationEmailSendMessageForFindPwArgs = {
  email: Scalars['EmailAddress'],
  name: Scalars['String'],
  username: Scalars['String']
};


export type MutationFindIdByPhoneNumberArgs = {
  phoneNumber: Scalars['PhoneNumber'],
  name: Scalars['String'],
  code: Scalars['String']
};


export type MutationFindPwByPhoneNumberArgs = {
  phoneNumber: Scalars['PhoneNumber'],
  name: Scalars['String'],
  username: Scalars['String'],
  code: Scalars['String']
};


export type MutationUpdatePwArgs = {
  currentPW: Scalars['String'],
  newPW: Scalars['String'],
  checkNewPW: Scalars['String']
};


export type MutationUpdatePwByUsernameArgs = {
  recoveryPWAuthID: Scalars['ID'],
  recoveryPWAuthKey: Scalars['String'],
  username: Scalars['String'],
  newPW: Scalars['String'],
  checkNewPW: Scalars['String']
};


export type MutationUpdateUserInfoArgs = {
  id: Scalars['ID'],
  input: UserInfoInput
};


export type MutationAdminUpdateUserInfoArgs = {
  id: Scalars['ID'],
  input: AdminUserInfoUpdate
};


export type MutationActivateUserArgs = {
  token: Scalars['String']
};


export type MutationUserDeviceDeleteArgs = {
  userID: Scalars['ID'],
  deviceID: Scalars['String']
};


export type MutationUserPointCreateArgs = {
  input: UserPointCreateInput
};


export type MutationUserPointUpdateArgs = {
  id: Scalars['ID'],
  input: UserPointUpdateInput
};


export type MutationUserPointDeleteArgs = {
  id: Scalars['ID']
};


export type MutationUserPointDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationUserRegisterStatDailyUpdateArgs = {
  category?: Maybe<UserRegisterCategory>,
  token?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['Date']>
};


export type MutationVideoUpdateArgs = {
  id: Scalars['ID']
};


export type MutationVideoLessonPlayArgs = {
  videoID: Scalars['ID'],
  lessonNumber: Scalars['Int'],
  isMobile: Scalars['Boolean']
};


export type MutationVideoProductCreateArgs = {
  input: VideoProductInput
};


export type MutationVideoProductUpdateArgs = {
  id: Scalars['ID'],
  input: VideoProductInput
};


export type MutationVideoProductDeleteArgs = {
  id: Scalars['ID']
};


export type MutationVideoProductDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationPasswordCheckArgs = {
  password: Scalars['String']
};


export type MutationMessageSendToPhoneArgs = {
  phoneNumber: Scalars['PhoneNumber']
};


export type MutationMessageSendToCurrentUserPhoneArgs = {
  phoneNumber: Scalars['PhoneNumber']
};


export type MutationPhoneCodeAuthenticateArgs = {
  phoneNumber: Scalars['PhoneNumber'],
  code: Scalars['String']
};


export type MutationLeaveArgs = {
  reason: LeaveReason,
  message?: Maybe<Scalars['String']>
};


export type MutationDeleteUserArgs = {
  userID: Scalars['ID'],
  reason: LeaveReason,
  message?: Maybe<Scalars['String']>
};


export type MutationActivateDormantUserArgs = {
  token: Scalars['String']
};


export type MutationSupportTicketCreateArgs = {
  input: SupportTicketInput
};


export type MutationSupportTicketUpdateArgs = {
  id: Scalars['ID'],
  input: SupportTicketInput
};


export type MutationSupportTicketAnswerTemplateCreateArgs = {
  input: SupportTicketAnswerTemplateInput
};


export type MutationSupportTicketAnswerTemplateUpdateArgs = {
  id: Scalars['ID'],
  input: SupportTicketAnswerTemplateInput
};


export type MutationSupportTicketCategoryCreateArgs = {
  input: SupportTicketCategoryInput
};


export type MutationSupportTicketCategoryUpdateArgs = {
  id: Scalars['ID'],
  input: SupportTicketCategoryInput
};


export type MutationSupportTicketAdminCreateArgs = {
  input: SupportTicketAdminCreateInput
};


export type MutationSupportTicketAdminUpdateArgs = {
  id: Scalars['ID'],
  input: SupportTicketAdminUpdateInput
};


export type MutationValidateUsernameArgs = {
  userName: Scalars['String']
};


export type MutationSendAuthSmsArgs = {
  phone: Scalars['String']
};


export type MutationVerifyAuthSmsArgs = {
  input: AuthSmsInput
};


export type MutationNativeRegisterArgs = {
  input: NativeRegisterInput
};


export type MutationSnsRegisterArgs = {
  input: SnsRegisterInput
};


export type MutationAllianceRegisterArgs = {
  input: AllianceRegisterInput
};

export type NativeRegisterInput = {
  userName: Scalars['String'],
  password: Scalars['String'],
  name: Scalars['String'],
  email: Scalars['String'],
  phoneNumber: Scalars['String'],
  exam: Array<ExamToTake>,
  examSubjects: Array<Scalars['ID']>,
  service?: Maybe<ServiceType>,
  emailReceipt: Scalars['Boolean'],
  smsReceipt: Scalars['Boolean'],
  joinRoute: JoinRoute,
  authSMSInput: AuthSmsInput,
};

export type NativeRegisterPayload = {
   __typename?: 'NativeRegisterPayload',
  user: User,
};

export type NotificationSms = {
   __typename?: 'NotificationSMS',
  title: Scalars['String'],
  innerText: Scalars['String'],
};

export type NotificationSmsCreateInput = {
  title: Scalars['String'],
  innerText: Scalars['String'],
};

export type NotificationSmsSet = {
   __typename?: 'NotificationSMSSet',
  isActive: Scalars['Boolean'],
  classroom: Scalars['String'],
  notificationSMS: Array<NotificationSms>,
};

export type Offline = {
   __typename?: 'Offline',
  content: Content,
  descriptionHTML: Scalars['String'],
  cautionHTML: Scalars['String'],
  offlineRepurchaseDiscounts: Array<OfflineRepurchaseDiscount>,
  contentPurchases: Array<ContentPurchase>,
  id: Scalars['ID'],
  shortDescription: Scalars['String'],
  usePayback: Scalars['Boolean'],
  isRecruitAllTime: Scalars['Boolean'],
  coursePeriod?: Maybe<Array<Scalars['Date']>>,
  courseStartDate?: Maybe<Scalars['Date']>,
  courseEndDate?: Maybe<Scalars['Date']>,
  weekday?: Maybe<Array<Weekday>>,
  classStartTime?: Maybe<Scalars['Time']>,
  classEndTime?: Maybe<Scalars['Time']>,
  /** 
 * 수강안내 문자 관련.
   * 장소/설문/강의일정/유의사항/수강혜택/교재안내 등
 */
  useMessage: Scalars['Boolean'],
  notificationSMSSet: NotificationSmsSet,
  surveyUrl?: Maybe<Scalars['String']>,
  isOfflineConsult: Scalars['Boolean'],
  /** 오프라인 강의끼리 포함관계가 있는 경우 있음(예: 김태성 CJ면접반) */
  children: Array<Offline>,
  rank: Scalars['Int'],
  defaultTeacherForImage?: Maybe<Teacher>,
  tags: Array<OfflineTag>,
};

export type OfflineChildrenContentsUpdatePayload = {
   __typename?: 'OfflineChildrenContentsUpdatePayload',
  offlineChildrenContents?: Maybe<Array<Offline>>,
};

export type OfflineConnection = {
   __typename?: 'OfflineConnection',
  nodes: Array<Offline>,
  totalCount: Scalars['Int'],
};

export type OfflineCreateInput = {
  content: ContentCreateInput,
  descriptionHTML: HtmlTextInput,
  cautionHTML: HtmlTextInput,
  shortDescription: Scalars['String'],
  usePayback: Scalars['Boolean'],
  courseStartDate: Scalars['Date'],
  courseEndDate: Scalars['Date'],
  weekday: Array<Weekday>,
  classStartTime: Scalars['Time'],
  classEndTime: Scalars['Time'],
  mainBannerID?: Maybe<Scalars['ID']>,
  placeID?: Maybe<Scalars['ID']>,
  tags?: Maybe<Array<OfflineTag>>,
  defaultImageTeacherID: Scalars['ID'],
  children?: Maybe<Array<Scalars['ID']>>,
  rank?: Maybe<Scalars['Int']>,
  useMessage: Scalars['Boolean'],
  notificationSMS?: Maybe<Array<NotificationSmsCreateInput>>,
  surveyUrl?: Maybe<Scalars['String']>,
};

export type OfflineCreatePayload = {
   __typename?: 'OfflineCreatePayload',
  offline?: Maybe<Offline>,
};

export type OfflineFilters = {
  contentID?: Maybe<Scalars['ID']>,
  contentSubject?: Maybe<Scalars['String']>,
  serviceType?: Maybe<ServiceType>,
  isActive?: Maybe<Scalars['Boolean']>,
  courseStartDate?: Maybe<DateRange>,
  courseEndDate?: Maybe<DateRange>,
  teacherID?: Maybe<Scalars['ID']>,
};

export type OfflineOrder = {
  field: OfflineOrderField,
  direction: OrderDirection,
};

export enum OfflineOrderField {
  ID = 'ID'
}

export type OfflineRepurchaseDiscount = {
   __typename?: 'OfflineRepurchaseDiscount',
  discountValue: Scalars['Float'],
  discountType: DiscountType,
  targetOffline: Offline,
  isDeleted: Scalars['Boolean'],
};


export type OfflineRepurchaseDiscountDiscountValueArgs = {
  unit?: Maybe<PercentageUnit>
};

export type OfflineRepurchaseDiscountInput = {
  discountType: DiscountType,
  discountValue: Scalars['Float'],
  targetOfflineID: Scalars['ID'],
  isDeleted: Scalars['Boolean'],
};

export type OfflineRepurchaseDiscountUpdatePayload = {
   __typename?: 'OfflineRepurchaseDiscountUpdatePayload',
  offlineRepurchaseDiscounts?: Maybe<Array<OfflineRepurchaseDiscount>>,
};

export type OfflineSendDailyMessagePayload = {
   __typename?: 'offlineSendDailyMessagePayload',
  offline: Offline,
  totalCount: Scalars['Int'],
  succeedCount: Scalars['Int'],
  failedCount: Scalars['Int'],
  succeedResults: Array<InfobankSmsSendResult>,
  failedResults: Array<InfobankSmsSendResult>,
};

export type OfflineStudentClassChangePayload = {
   __typename?: 'OfflineStudentClassChangePayload',
  contentPurchase?: Maybe<ContentPurchase>,
};

export enum OfflineTag {
  SPECIAL_SALE = 'SPECIAL_SALE',
  DEADLINE_IMMINENT = 'DEADLINE_IMMINENT',
  FIRST_COME = 'FIRST_COME',
  NEW = 'NEW',
  HOT = 'HOT'
}

export type OfflineUpdateInput = {
  content?: Maybe<ContentUpdateInput>,
  descriptionHTML?: Maybe<HtmlTextInput>,
  cautionHTML?: Maybe<HtmlTextInput>,
  shortDescription?: Maybe<Scalars['String']>,
  usePayback?: Maybe<Scalars['Boolean']>,
  courseStartDate?: Maybe<Scalars['Date']>,
  courseEndDate?: Maybe<Scalars['Date']>,
  weekday?: Maybe<Array<Weekday>>,
  classStartTime?: Maybe<Scalars['Time']>,
  classEndTime?: Maybe<Scalars['Time']>,
  mainBannerID?: Maybe<Scalars['ID']>,
  placeID?: Maybe<Scalars['ID']>,
  tags?: Maybe<Array<OfflineTag>>,
  defaultImageTeacherID?: Maybe<Scalars['ID']>,
  children?: Maybe<Array<Scalars['ID']>>,
  rank?: Maybe<Scalars['Int']>,
  useMessage?: Maybe<Scalars['Boolean']>,
  notificationSMS?: Maybe<Array<NotificationSmsCreateInput>>,
  surveyUrl?: Maybe<Scalars['String']>,
};

export type OfflineUpdatePayload = {
   __typename?: 'OfflineUpdatePayload',
  offline?: Maybe<Offline>,
};

export enum OnlineOrOffline {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}

export type Order = {
   __typename?: 'Order',
  contentPurchase?: Maybe<Array<ContentPurchase>>,
  id: Scalars['ID'],
  createdAt: Scalars['Date'],
  device?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['PhoneNumber']>,
  status: OrderStatus,
  tempAccountID?: Maybe<Scalars['ID']>,
  type?: Maybe<OrderType>,
  userID?: Maybe<Scalars['ID']>,
  isTestOrder: Scalars['Boolean'],
  payment?: Maybe<Array<Payment>>,
  refund?: Maybe<Refund>,
  usedCouponNumber?: Maybe<CouponNumber>,
};

export enum OrderBy {
  CREATED_AT = 'CREATED_AT',
  VIEWS = 'VIEWS',
  LIKES = 'LIKES'
}

export type OrderConnection = {
   __typename?: 'OrderConnection',
  nodes: Array<Maybe<Order>>,
  totalCount: Scalars['Int'],
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type OrderFilters = {
  userID?: Maybe<Scalars['ID']>,
};

export enum OrderStatus {
  WAIT = 'WAIT',
  FAIL = 'FAIL',
  COMPLETE = 'COMPLETE',
  REFUND = 'REFUND',
  PARTIAL_REFUND = 'PARTIAL_REFUND'
}

export enum OrderType {
  USER = 'USER',
  TEMP_ACCOUNT = 'TEMP_ACCOUNT'
}

export type Package = {
   __typename?: 'Package',
  id: Scalars['ID'],
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  contents?: Maybe<ContentConnection>,
};


export type PackageContentsArgs = {
  filterBy?: Maybe<ContentFilters>,
  orderBy?: Maybe<ContentOrder>,
  pagination?: Maybe<Pagination>
};

export type PackageConnection = {
   __typename?: 'PackageConnection',
  nodes: Array<Package>,
  totalCount: Scalars['Int'],
};

export type PackageFilters = {
  q?: Maybe<Scalars['String']>,
};

export type PackageOrder = {
  field: PackageOrderField,
  direction: OrderDirection,
};

export enum PackageOrderField {
  ID = 'ID'
}

export type Pagination = {
  page: Scalars['Int'],
  pageSize: Scalars['Int'],
};

export enum PassingCriteria {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL'
}

export type PaybackSet = {
   __typename?: 'PaybackSet',
  usePaybackIfAttendance: Scalars['Boolean'],
  usePaybackIfAchieveScore: Scalars['Boolean'],
  usePeriodExtension: Scalars['Boolean'],
  minAttendDayForPayback?: Maybe<Scalars['Int']>,
  minPlaytimeForAttendance?: Maybe<Scalars['Int']>,
  scoreLimitForPayback?: Maybe<Scalars['Int']>,
};

export enum PaybackType {
  NO_PAYBACK = 'NO_PAYBACK',
  ATTENDANCE = 'ATTENDANCE',
  ACHIEVE_SCORE = 'ACHIEVE_SCORE',
  ATTENDANCE_AND_ACHIEVE_SCORE = 'ATTENDANCE_AND_ACHIEVE_SCORE',
  NO_PAYBACK_WITH_EXTENSION = 'NO_PAYBACK_WITH_EXTENSION',
  ATTENDANCE_WITH_EXTENSION = 'ATTENDANCE_WITH_EXTENSION',
  ACHIEVE_SCORE_WITH_EXTENSION = 'ACHIEVE_SCORE_WITH_EXTENSION',
  ATTENDANCE_AND_ACHIEVE_SCORE_WITH_EXTENSION = 'ATTENDANCE_AND_ACHIEVE_SCORE_WITH_EXTENSION'
}

export type Payment = {
   __typename?: 'Payment',
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  paymentMethod?: Maybe<PaymentMethod>,
  transactionID?: Maybe<Scalars['String']>,
  state?: Maybe<PaymentState>,
  order?: Maybe<Order>,
  etag?: Maybe<Scalars['String']>,
  isTestPayment?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['Date']>,
  paidAt?: Maybe<Scalars['Date']>,
  refund?: Maybe<Array<Maybe<Refund>>>,
};

export type PaymentConnection = {
   __typename?: 'PaymentConnection',
  nodes: Array<Payment>,
  totalCount: Scalars['Int'],
};

export type PaymentFilters = {
  paymentType?: Maybe<PaymentMethod>,
  transactionID?: Maybe<Scalars['String']>,
  orderID?: Maybe<Scalars['ID']>,
};

export enum PaymentMethod {
  METHOD_LEGACY_CARD = 'METHOD_LEGACY_CARD',
  METHOD_LEGACY_MOBILE = 'METHOD_LEGACY_MOBILE',
  METHOD_LEGACY_BANKTRANS = 'METHOD_LEGACY_BANKTRANS',
  METHOD_LEGACY_NOBANK = 'METHOD_LEGACY_NOBANK',
  METHOD_IAMPORT_UPLUS_CARD = 'METHOD_IAMPORT_UPLUS_CARD',
  METHOD_IAMPORT_UPLUS_BANKTRANS = 'METHOD_IAMPORT_UPLUS_BANKTRANS',
  METHOD_IAMPORT_UPLUS_PHONE = 'METHOD_IAMPORT_UPLUS_PHONE',
  METHOD_IAMPORT_KAKAOPAY_CARD = 'METHOD_IAMPORT_KAKAOPAY_CARD',
  METHOD_OFFLINE_CARD = 'METHOD_OFFLINE_CARD',
  METHOD_OFFLINE_CASH = 'METHOD_OFFLINE_CASH',
  METHOD_OFFLINE_NOBANK = 'METHOD_OFFLINE_NOBANK',
  METHOD_KAKAO_KAKAOPAY_CARD = 'METHOD_KAKAO_KAKAOPAY_CARD'
}

export enum PaymentState {
  WAIT = 'WAIT',
  FAIL = 'FAIL',
  COMPLETE = 'COMPLETE'
}

export enum PercentageUnit {
  /** 0~100으로 표기 */
  PERCENTAGE = 'PERCENTAGE',
  /** 0~1로 표기 */
  DECIMAL_POINT = 'DECIMAL_POINT'
}

export type Permission = {
   __typename?: 'Permission',
  id?: Maybe<Scalars['ID']>,
  scope: PermissionScope,
  team?: Maybe<Team>,
  grantedAt?: Maybe<Scalars['Date']>,
};

export type PermissionConnection = {
   __typename?: 'PermissionConnection',
  nodes: Array<Permission>,
  totalCount: Scalars['Int'],
};

export type PermissionFilters = {
  scope?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['ID']>,
  teamID?: Maybe<Scalars['ID']>,
};

export type PermissionGrantPayload = {
   __typename?: 'PermissionGrantPayload',
  permission?: Maybe<Permission>,
};

export type PermissionOrder = {
  field: PermissionOrderField,
  direction: OrderDirection,
};

export enum PermissionOrderField {
  ID = 'ID'
}

export type PermissionRevokePayload = {
   __typename?: 'PermissionRevokePayload',
  permission?: Maybe<Permission>,
};

export enum PermissionScope {
  SUPERUSER = 'SUPERUSER',
  ALLIANCE_ADMIN = 'ALLIANCE_ADMIN',
  ALLIANCE_MANAGER = 'ALLIANCE_MANAGER',
  ALLIANCE_ULSAN = 'ALLIANCE_ULSAN',
  ALLIANCE_DONGA = 'ALLIANCE_DONGA',
  ALLIANCE_YOUNGNAM = 'ALLIANCE_YOUNGNAM',
  ALLIANCE_PUKYONG = 'ALLIANCE_PUKYONG',
  ALLIANCE_UOS = 'ALLIANCE_UOS',
  ALLIANCE_KEIMYUNG = 'ALLIANCE_KEIMYUNG',
  ALLIANCE_DONGGUK = 'ALLIANCE_DONGGUK',
  ALLIANCE_MULTICAMPUS = 'ALLIANCE_MULTICAMPUS',
  ALLIANCE_GYEONGSANG = 'ALLIANCE_GYEONGSANG',
  ALLIANCE_INJE = 'ALLIANCE_INJE',
  ALLIANCE_KOREA = 'ALLIANCE_KOREA',
  TEACHER = 'TEACHER',
  BANNER_ADMIN = 'BANNER_ADMIN',
  BANNER_SAVE = 'BANNER_SAVE',
  BANNER_CREATE = 'BANNER_CREATE',
  BANNER_UPDATE = 'BANNER_UPDATE',
  BANNERGROUP_CREATE = 'BANNERGROUP_CREATE',
  BANNERGROUP_UPDATE = 'BANNERGROUP_UPDATE',
  BOOK_ADMIN = 'BOOK_ADMIN',
  BOOK_SAVE = 'BOOK_SAVE',
  BOOK_UPDATE = 'BOOK_UPDATE',
  BOOK_DELETE = 'BOOK_DELETE',
  CAMPAIGN_ADMIN = 'CAMPAIGN_ADMIN',
  CAMPAIGN_CREATE = 'CAMPAIGN_CREATE',
  CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE',
  CAMPAIGN_DELETE = 'CAMPAIGN_DELETE',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_SAVE = 'COMPANY_SAVE',
  COMPANY_CREATE = 'COMPANY_CREATE',
  COMPANY_UPDATE = 'COMPANY_UPDATE',
  COMPANY_DELETE = 'COMPANY_DELETE',
  CONTENT_ADMIN = 'CONTENT_ADMIN',
  CONTENT_CREATE = 'CONTENT_CREATE',
  CONTENT_UPDATE = 'CONTENT_UPDATE',
  CONTENT_DELETE = 'CONTENT_DELETE',
  CONTENT_PURCHASE_ADMIN = 'CONTENT_PURCHASE_ADMIN',
  CONTENT_PURCHASE_READ = 'CONTENT_PURCHASE_READ',
  COUPON_ADMIN = 'COUPON_ADMIN',
  COUPON_CREATE = 'COUPON_CREATE',
  COUPON_UPDATE = 'COUPON_UPDATE',
  COUPON_DELETE = 'COUPON_DELETE',
  DOCUMENT_PRODUCT_ADMIN = 'DOCUMENT_PRODUCT_ADMIN',
  DOCUMENT_PRODUCT_CREATE = 'DOCUMENT_PRODUCT_CREATE',
  DOCUMENT_PRODUCT_UPDATE = 'DOCUMENT_PRODUCT_UPDATE',
  DOCUMENT_PRODUCT_DELETE = 'DOCUMENT_PRODUCT_DELETE',
  EXAM_ADMIN = 'EXAM_ADMIN',
  EXAM_CREATE = 'EXAM_CREATE',
  EXAM_UPDATE = 'EXAM_UPDATE',
  EXAM_DELETE = 'EXAM_DELETE',
  EXTRACT_DB_ADMIN = 'EXTRACT_DB_ADMIN',
  EXTRACT_DB_READ = 'EXTRACT_DB_READ',
  FREEPASS_PRODUCT_ADMIN = 'FREEPASS_PRODUCT_ADMIN',
  FREEPASS_PRODUCT_CREATE = 'FREEPASS_PRODUCT_CREATE',
  FREEPASS_PRODUCT_UPDATE = 'FREEPASS_PRODUCT_UPDATE',
  FREEPASS_PRODUCT_DELETE = 'FREEPASS_PRODUCT_DELETE',
  FILE_ADMIN = 'FILE_ADMIN',
  FILE_READ = 'FILE_READ',
  FILE_UPDATE = 'FILE_UPDATE',
  INVOICE_ADMIN = 'INVOICE_ADMIN',
  INVOICE_READ = 'INVOICE_READ',
  MAJOR_ADMIN = 'MAJOR_ADMIN',
  MAJOR_CREATE = 'MAJOR_CREATE',
  MAJOR_UPDATE = 'MAJOR_UPDATE',
  MAJOR_DELETE = 'MAJOR_DELETE',
  MEMBERSHIP_ADMIN = 'MEMBERSHIP_ADMIN',
  MEMBERSHIP_READ = 'MEMBERSHIP_READ',
  MEMBERSHIP_CREATE = 'MEMBERSHIP_CREATE',
  MEMBERSHIP_UPDATE = 'MEMBERSHIP_UPDATE',
  MEMBERSHIP_DELETE = 'MEMBERSHIP_DELETE',
  MENU_ADMIN = 'MENU_ADMIN',
  MENU_SAVE = 'MENU_SAVE',
  MENU_CREATE = 'MENU_CREATE',
  MENU_UPDATE = 'MENU_UPDATE',
  MENU_DELETE = 'MENU_DELETE',
  OFFLINE_ADMIN = 'OFFLINE_ADMIN',
  OFFLINE_CREATE = 'OFFLINE_CREATE',
  OFFLINE_UPDATE = 'OFFLINE_UPDATE',
  OFFLINE_DELETE = 'OFFLINE_DELETE',
  PACKAGE_ADMIN = 'PACKAGE_ADMIN',
  PACKAGE_CREATE = 'PACKAGE_CREATE',
  PACKAGE_UPDATE = 'PACKAGE_UPDATE',
  PACKAGE_DELETE = 'PACKAGE_DELETE',
  RESUME_PRODUCT_ADMIN = 'RESUME_PRODUCT_ADMIN',
  RESUME_PRODUCT_SAVE = 'RESUME_PRODUCT_SAVE',
  RESUME_PRODUCT_CREATE = 'RESUME_PRODUCT_CREATE',
  RESUME_PRODUCT_UPDATE = 'RESUME_PRODUCT_UPDATE',
  RESUME_PRODUCT_DELETE = 'RESUME_PRODUCT_DELETE',
  REVIEW_ADMIN = 'REVIEW_ADMIN',
  REVIEW_UPDATE = 'REVIEW_UPDATE',
  REVIEW_DELETE = 'REVIEW_DELETE',
  SCHEDULE_ADMIN = 'SCHEDULE_ADMIN',
  SCHEDULE_SAVE = 'SCHEDULE_SAVE',
  SCHEDULE_CREATE = 'SCHEDULE_CREATE',
  SCHEDULE_UPDATE = 'SCHEDULE_UPDATE',
  SCHEDULE_DELETE = 'SCHEDULE_DELETE',
  SCHOOL_ADMIN = 'SCHOOL_ADMIN',
  SCHOOL_CREATE = 'SCHOOL_CREATE',
  SCHOOL_UPDATE = 'SCHOOL_UPDATE',
  SCHOOL_DELETE = 'SCHOOL_DELETE',
  STUDY_ADMIN = 'STUDY_ADMIN',
  STUDY_READ = 'STUDY_READ',
  STUDY_CREATE = 'STUDY_CREATE',
  STUDY_UPDATE = 'STUDY_UPDATE',
  STUDY_DELETE = 'STUDY_DELETE',
  SUPPORTTICKET_ADMIN = 'SUPPORTTICKET_ADMIN',
  SUPPORTTICKET_READ = 'SUPPORTTICKET_READ',
  SUPPORTTICKET_CREATE = 'SUPPORTTICKET_CREATE',
  SUPPORTTICKET_UPDATE = 'SUPPORTTICKET_UPDATE',
  SUPPORTTICKET_DELETE = 'SUPPORTTICKET_DELETE',
  TEACHER_ADMIN = 'TEACHER_ADMIN',
  TEACHERCURATION_ADMIN = 'TEACHERCURATION_ADMIN',
  TEACHERCURATION_CREATE = 'TEACHERCURATION_CREATE',
  TEACHERCURATION_UPDATE = 'TEACHERCURATION_UPDATE',
  TEACHERCURATION_DELETE = 'TEACHERCURATION_DELETE',
  TEAM_ADMIN = 'TEAM_ADMIN',
  TEAM_READ = 'TEAM_READ',
  TEAM_CREATE = 'TEAM_CREATE',
  TEAM_UPDATE = 'TEAM_UPDATE',
  TEAM_DELETE = 'TEAM_DELETE',
  PERMISSION_ADMIN = 'PERMISSION_ADMIN',
  PERMISSION_SAVE = 'PERMISSION_SAVE',
  PERMISSION_READ = 'PERMISSION_READ',
  PERMISSION_GRANT = 'PERMISSION_GRANT',
  PERMISSION_REVOKE = 'PERMISSION_REVOKE',
  POSTCURATION_ADMIN = 'POSTCURATION_ADMIN',
  POSTCURATION_SAVE = 'POSTCURATION_SAVE',
  POSTCURATION_UPDATE = 'POSTCURATION_UPDATE',
  USER_ADMIN = 'USER_ADMIN',
  USER_READ = 'USER_READ',
  CONTENTCURATION_ADMIN = 'CONTENTCURATION_ADMIN',
  CONTENTCURATION_SAVE = 'CONTENTCURATION_SAVE',
  CONTENTCURATION_CREATE = 'CONTENTCURATION_CREATE',
  CONTENTCURATION_UPDATE = 'CONTENTCURATION_UPDATE',
  CONTENTCURATION_DELETE = 'CONTENTCURATION_DELETE',
  VIDEO_ADMIN = 'VIDEO_ADMIN',
  VIDEO_CREATE = 'VIDEO_CREATE',
  VIDEO_UPDATE = 'VIDEO_UPDATE',
  VIDEO_DELETE = 'VIDEO_DELETE',
  VIDEOLESSON_ADMIN = 'VIDEOLESSON_ADMIN',
  VIDEOLESSON_CREATE = 'VIDEOLESSON_CREATE',
  VIDEOLESSON_UPDATE = 'VIDEOLESSON_UPDATE',
  VIDEOLESSON_DELETE = 'VIDEOLESSON_DELETE',
  HTMLTEXT_ADMIN = 'HTMLTEXT_ADMIN',
  HTMLTEXT_CREATE = 'HTMLTEXT_CREATE',
  HTMLTEXT_UPDATE = 'HTMLTEXT_UPDATE',
  HTMLTEXT_DELETE = 'HTMLTEXT_DELETE',
  STAT_ADMIN = 'STAT_ADMIN',
  STAT_READ = 'STAT_READ'
}


export type PhoneSendMessageForFindIdPayload = {
   __typename?: 'phoneSendMessageForFindIDPayload',
  code: Scalars['String'],
};

export type PhoneSendMessageForFindPwPayload = {
   __typename?: 'phoneSendMessageForFindPWPayload',
  code: Scalars['String'],
};

export type PhoneUpdateInput = {
  phoneNumber?: Maybe<Scalars['PhoneNumber']>,
  token?: Maybe<Scalars['String']>,
};

export enum PlayerType {
  PLAYER_STWAY = 'PLAYER_STWAY',
  PLAYER_KOLLUS = 'PLAYER_KOLLUS'
}

export type Post = {
   __typename?: 'Post',
  id: Scalars['ID'],
  url?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  views: Scalars['Int'],
  likes: Scalars['Int'],
  board?: Maybe<Board>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export enum PostCommentStatus {
  ALLOW = 'ALLOW',
  DENY = 'DENY'
}

export type PostCuration = {
   __typename?: 'PostCuration',
  id: Scalars['ID'],
  code: Scalars['String'],
  title: Scalars['String'],
  maxPostLimit: Scalars['Int'],
  boardURL: Scalars['String'],
  configs: Array<PostCurationConfig>,
  posts?: Maybe<Array<Maybe<Post>>>,
  boards?: Maybe<Array<Maybe<Board>>>,
};

export type PostCurationConfig = {
   __typename?: 'PostCurationConfig',
  isAuto: Scalars['Boolean'],
  category?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  postID?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<OrderBy>,
  sourceBoardID?: Maybe<Scalars['ID']>,
};

export type PostCurationConfigInput = {
  isAuto: Scalars['Boolean'],
  category?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  postID?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<OrderBy>,
  sourceBoardID?: Maybe<Scalars['ID']>,
};

export type PostCurationConnection = {
   __typename?: 'PostCurationConnection',
  nodes?: Maybe<Array<Maybe<PostCuration>>>,
  totalCount: Scalars['Int'],
};

export type PostCurationFilters = {
  q?: Maybe<Scalars['String']>,
};

export type PostCurationInput = {
  title?: Maybe<Scalars['String']>,
  boardURL?: Maybe<Scalars['String']>,
  configs?: Maybe<Array<Maybe<PostCurationConfigInput>>>,
};

export type PostCurationOrder = {
  field: PostCurationOrderField,
  direction: OrderDirection,
};

export enum PostCurationOrderField {
  ID = 'ID'
}

export type PostCurationUpdatePayload = {
   __typename?: 'PostCurationUpdatePayload',
  postCuration?: Maybe<PostCuration>,
};

export enum PostStatus {
  PUBLIC = 'PUBLIC',
  TEMP = 'TEMP'
}

export type PrevScoreForPass = {
   __typename?: 'PrevScoreForPass',
  seasonName: Scalars['String'],
  score: Scalars['Int'],
};

export type PrevScoreForPassInput = {
  seasonName?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['Int']>,
};

export type PrintLog = {
   __typename?: 'PrintLog',
  printedAt?: Maybe<Scalars['Date']>,
  printInfo?: Maybe<Scalars['String']>,
};

export type ProvideCampaignBenefitPayload = {
   __typename?: 'ProvideCampaignBenefitPayload',
  message?: Maybe<Scalars['String']>,
  provideResults?: Maybe<Array<Maybe<ProvideResultProps>>>,
};

export type ProvideFreeContentForUsersPayload = {
   __typename?: 'provideFreeContentForUsersPayload',
  successCount: Scalars['Int'],
  failureCount: Scalars['Int'],
  failedUsers: Array<FailedUser>,
  results: Array<ProvideFreeContentPayload>,
};

export type ProvideFreeContentPayload = {
   __typename?: 'ProvideFreeContentPayload',
  result: Scalars['Boolean'],
  contentPurchase?: Maybe<ContentPurchase>,
  message?: Maybe<Scalars['String']>,
};

export enum Provider {
  LOCAL = 'LOCAL',
  KAKAO = 'KAKAO',
  NAVER = 'NAVER'
}

export type ProvideResultProps = {
   __typename?: 'ProvideResultProps',
  benefit?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
};

export type Query = {
   __typename?: 'Query',
  adminBlogPosts: AdminBlogPostConnection,
  alliance?: Maybe<Alliance>,
  alliances: AllianceConnection,
  allianceByUserID?: Maybe<Array<Maybe<Alliance>>>,
  allianceByCookie?: Maybe<Alliance>,
  allianceUser?: Maybe<AllianceUser>,
  allianceUsers: AllianceUserConnection,
  banner?: Maybe<Banner>,
  banners: BannerConnection,
  bannersByID: Array<Banner>,
  bannerByCode?: Maybe<BannerGroup>,
  bannerGroup?: Maybe<BannerGroup>,
  bannerGroups: BannerGroupConnection,
  bannerGroupsByID: Array<BannerGroup>,
  book?: Maybe<Book>,
  books: BookConnection,
  booksByID: Array<Book>,
  campaign?: Maybe<Campaign>,
  campaigns?: Maybe<CampaignConnection>,
  campaignsForDataExtract?: Maybe<CampaignConnectionForDataExtract>,
  company?: Maybe<Company>,
  companies?: Maybe<CompanyConnection>,
  companiesByID: Array<Maybe<Company>>,
  content?: Maybe<Content>,
  contents?: Maybe<ContentConnection>,
  contentsByID: Array<Content>,
  contentsByStudyEventID: Array<Content>,
  contentsOnSale?: Maybe<Array<Content>>,
  contentWithValidate?: Maybe<Content>,
  contentCategoryList: Array<ContentCategoryParent>,
  contentCuration?: Maybe<ContentCuration>,
  contentCurationByCode?: Maybe<ContentCuration>,
  contentCurations: ContentCurationConnection,
  coupon?: Maybe<Coupon>,
  coupons: CouponConnection,
  couponNumber?: Maybe<CouponNumber>,
  document?: Maybe<Document>,
  documents: DocumentConnection,
  documentProduct?: Maybe<DocumentProduct>,
  documentProducts: DocumentProductConnection,
  contentPurchase?: Maybe<ContentPurchase>,
  contentPurchases: ContentPurchaseConnection,
  contentPurchaseRankGroup: ContentPurchaseRankGroupConnection,
  contentPurchaseStats: ContentPurchaseStatConnection,
  contentRefundStats: ContentRefundStatConnection,
  loginPageURL: Scalars['String'],
  myPageURL: Scalars['String'],
  exam?: Maybe<Exam>,
  exams: ExamConnection,
  examsByID: Array<Maybe<Exam>>,
  examSection?: Maybe<ExamSection>,
  examSections: ExamSectionConnection,
  examQuestion?: Maybe<ExamQuestion>,
  examQuestions: ExamQuestionConnection,
  examQuestionType?: Maybe<ExamQuestionType>,
  examQuestionTypes: ExamQuestionTypeConnection,
  examQuestionTypesByID: Array<Maybe<ExamQuestionType>>,
  examAttempt?: Maybe<ExamAttempt>,
  examAttempts?: Maybe<ExamAttemptConnection>,
  getFile: File,
  freepass?: Maybe<Freepass>,
  freepassProduct?: Maybe<FreepassProduct>,
  freepassProducts: FreepassProductConnection,
  htmlText?: Maybe<HtmlText>,
  htmlTexts: HtmlTextConnection,
  invoice?: Maybe<Invoice>,
  invoices: InvoiceConnection,
  invoicesByID: Array<Maybe<Invoice>>,
  major?: Maybe<Major>,
  majors: MajorConnection,
  menu?: Maybe<Menu>,
  menuByCode?: Maybe<Menu>,
  menus: MenuConnection,
  menusByIDs: Array<Maybe<Menu>>,
  offline?: Maybe<Offline>,
  offlines: OfflineConnection,
  offlineSendDailyMessage: Array<OfflineSendDailyMessagePayload>,
  offlineByIDs: Array<Offline>,
  offlineByContentID?: Maybe<Offline>,
  offlinesByContentIDs: Array<Offline>,
  order?: Maybe<Order>,
  orders?: Maybe<OrderConnection>,
  package?: Maybe<Package>,
  packages: PackageConnection,
  payment?: Maybe<Payment>,
  payments?: Maybe<PaymentConnection>,
  paymentState?: Maybe<Payment>,
  permission?: Maybe<Permission>,
  permissions: PermissionConnection,
  permissionsByID: Array<Maybe<Permission>>,
  permissionsByCurrentUser: Array<Permission>,
  isCurrentUserAllowed?: Maybe<Scalars['Boolean']>,
  postCuration?: Maybe<PostCuration>,
  postCurations: PostCurationConnection,
  postCurationsByID: Array<Maybe<PostCuration>>,
  refund?: Maybe<Refund>,
  refunds?: Maybe<RefundConnection>,
  resumeProduct?: Maybe<ResumeProduct>,
  resumeProducts: ResumeProductConnection,
  resume?: Maybe<Resume>,
  resumes: ResumeConnection,
  review?: Maybe<Review>,
  reviews: ReviewConnection,
  schedule?: Maybe<Schedule>,
  schedules: ScheduleConnection,
  schedulesByID: Array<Maybe<Schedule>>,
  scheduleEvent?: Maybe<ScheduleEvent>,
  scheduleEvents: ScheduleEventConnection,
  school?: Maybe<School>,
  schools: SchoolConnection,
  userSchools: UserSchoolConnection,
  majorFromUserSchools: UserSchoolMajorConnection,
  smsReject?: Maybe<SmsReject>,
  study?: Maybe<Study>,
  studies: StudyConnection,
  studyByIDs: Array<Maybe<Study>>,
  openedStudies: Array<Study>,
  closedStudies: Array<Study>,
  studyEvent?: Maybe<StudyEvent>,
  studyEventsByIDs: Array<Maybe<StudyEvent>>,
  studyEvents: StudyEventConnection,
  studyMission?: Maybe<StudyMission>,
  studyMissions: StudyMissionConnection,
  userStudy?: Maybe<UserStudy>,
  userStudiesByUserID?: Maybe<Array<UserStudy>>,
  userStudiesOfCurrentUser?: Maybe<Array<UserStudy>>,
  userStudyEvent?: Maybe<UserStudyEvent>,
  userStudyEvents?: Maybe<UserStudyEventConnection>,
  userStudyMission?: Maybe<UserStudyMission>,
  userStudies: UserStudyConnection,
  userStudyMissions: UserStudyMissionConnection,
  integratedCommunityBoards: IntegratedCommunityBoardPayload,
  teacher?: Maybe<Teacher>,
  teachers: TeacherConnection,
  teachersByID: Array<Maybe<Teacher>>,
  teacherCuration?: Maybe<TeacherCuration>,
  teacherCurationByCode?: Maybe<TeacherCuration>,
  teacherCurations: TeacherCurationConnection,
  team?: Maybe<Team>,
  teams: TeamConnection,
  teamsByID: Array<Maybe<Team>>,
  membership?: Maybe<Membership>,
  memberships?: Maybe<MembershipConnection>,
  membershipsByIDs: Array<Maybe<Membership>>,
  isAdminMember?: Maybe<Scalars['Boolean']>,
  viewer?: Maybe<User>,
  currentUser?: Maybe<User>,
  currentTempAccount?: Maybe<TempAccount>,
  user?: Maybe<User>,
  users: UserConnection,
  usersByID: Array<Maybe<User>>,
  userDevices: UserDeviceConnection,
  userPoint?: Maybe<UserPoint>,
  userPoints?: Maybe<UserPointConnection>,
  userPointsByID: Array<Maybe<UserPoint>>,
  userRegisterStats: UserRegisterStatConnection,
  userSignupStats: UserSignupStatConnection,
  userVisitStats: UserVisitStatConnection,
  userAllianceStats: UserAllianceStatConnection,
  video?: Maybe<Video>,
  videos: VideoConnection,
  videoProduct?: Maybe<VideoProduct>,
  videoProducts: VideoProductConnection,
  videoLessonProduct?: Maybe<VideoLessonProduct>,
  videoLessonProducts: VideoLessonProductConnection,
  recoveryPasswordAuthByIDAndKey?: Maybe<RecoveryPasswordAuth>,
  supportTicket?: Maybe<SupportTicket>,
  supportTickets?: Maybe<SupportTicketConnection>,
  supportTicketAnswerTemplate?: Maybe<SupportTicketAnswerTemplate>,
  supportTicketAnswerTemplates?: Maybe<SupportTicketAnswerTemplateConnection>,
  supportTicketAnswerTemplatesByID: Array<Maybe<SupportTicketAnswerTemplate>>,
  supportTicketCategory?: Maybe<SupportTicketCategory>,
  supportTicketCategories?: Maybe<SupportTicketCategoryConnection>,
  supportTicketCategoryServiceMap?: Maybe<SupportTicketCategoryServiceMap>,
  supportTicketCategoryServiceMaps?: Maybe<SupportTicketCategoryServiceMapConnection>,
  supportTicketCategoryServiceMapsByID: Array<Maybe<SupportTicketCategoryServiceMap>>,
  newRegisteredSupportTickets: Array<SupportTicket>,
  keyword?: Maybe<Keyword>,
  servicePurchaseStats: ServicePurchaseStatConnection,
  allianceUserContentHistorys: AllianceUserContentHistoryConnection,
  examSubjects: ExamSubjectConnection,
};


export type QueryAdminBlogPostsArgs = {
  pageSize?: Maybe<Scalars['Int']>,
  cursor?: Maybe<Scalars['String']>
};


export type QueryAllianceArgs = {
  id: Scalars['ID']
};


export type QueryAlliancesArgs = {
  filterBy?: Maybe<AllianceFilters>
};


export type QueryAllianceByUserIdArgs = {
  id?: Maybe<Scalars['ID']>
};


export type QueryAllianceUserArgs = {
  id: Scalars['ID']
};


export type QueryAllianceUsersArgs = {
  filterBy?: Maybe<AllianceUserFilters>
};


export type QueryBannerArgs = {
  id: Scalars['ID']
};


export type QueryBannersArgs = {
  filterBy?: Maybe<BannerFilters>,
  orderBy?: Maybe<BannerOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryBannersByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryBannerByCodeArgs = {
  codeName: Scalars['String']
};


export type QueryBannerGroupArgs = {
  id: Scalars['ID']
};


export type QueryBannerGroupsArgs = {
  filterBy?: Maybe<BannerGroupFilters>,
  orderBy?: Maybe<BannerGroupOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryBannerGroupsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryBookArgs = {
  id: Scalars['ID']
};


export type QueryBooksArgs = {
  filterBy?: Maybe<BookFilters>,
  orderBy?: Maybe<BookOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryBooksByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryCampaignArgs = {
  id: Scalars['ID']
};


export type QueryCampaignsArgs = {
  filterBy?: Maybe<CampaignFilters>,
  orderBy?: Maybe<CampaignOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCampaignsForDataExtractArgs = {
  filterBy: CampaignFilters
};


export type QueryCompanyArgs = {
  id: Scalars['ID']
};


export type QueryCompaniesArgs = {
  filterBy?: Maybe<CompanyFilters>,
  orderBy?: Maybe<CompanyOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCompaniesByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryContentArgs = {
  id: Scalars['ID']
};


export type QueryContentsArgs = {
  filterBy?: Maybe<ContentFilters>,
  orderBy?: Maybe<ContentOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryContentsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryContentsByStudyEventIdArgs = {
  studyEventID: Scalars['ID'],
  missionType: MissionType
};


export type QueryContentsOnSaleArgs = {
  type: ContentType
};


export type QueryContentWithValidateArgs = {
  id: Scalars['ID'],
  contentType?: Maybe<ContentType>
};


export type QueryContentCategoryListArgs = {
  contentType: ContentType,
  service: ServiceType
};


export type QueryContentCurationArgs = {
  id: Scalars['ID']
};


export type QueryContentCurationByCodeArgs = {
  code: Scalars['String']
};


export type QueryContentCurationsArgs = {
  filterBy?: Maybe<ContentCurationFilters>,
  orderBy?: Maybe<ContentCurationOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCouponArgs = {
  id: Scalars['ID']
};


export type QueryCouponsArgs = {
  filterBy?: Maybe<CouponFilters>,
  orderBy?: Maybe<CouponOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCouponNumberArgs = {
  number: Scalars['String']
};


export type QueryDocumentArgs = {
  id: Scalars['ID']
};


export type QueryDocumentsArgs = {
  filterBy?: Maybe<DocumentFilters>,
  orderBy?: Maybe<DocumentOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryDocumentProductArgs = {
  id: Scalars['ID']
};


export type QueryDocumentProductsArgs = {
  filterBy?: Maybe<DocumentProductFilters>,
  orderBy?: Maybe<DocumentProductOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryContentPurchaseArgs = {
  id: Scalars['ID']
};


export type QueryContentPurchasesArgs = {
  filterBy?: Maybe<ContentPurchaseFilters>,
  orderBy?: Maybe<ContentPurchaseOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryContentPurchaseRankGroupArgs = {
  filterBy: ContentPurchaseRankFilters,
  orderBy: ContentPurchaseRankOrder,
  pagination?: Maybe<Pagination>,
  token?: Maybe<Scalars['String']>
};


export type QueryContentPurchaseStatsArgs = {
  filterBy: ContentPurchaseStatFilters,
  token?: Maybe<Scalars['String']>
};


export type QueryContentRefundStatsArgs = {
  filterBy: ContentPurchaseStatFilters,
  token?: Maybe<Scalars['String']>
};


export type QueryExamArgs = {
  id: Scalars['ID']
};


export type QueryExamsArgs = {
  filterBy?: Maybe<ExamFilters>,
  orderBy?: Maybe<ExamOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryExamsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryExamSectionArgs = {
  id: Scalars['ID']
};


export type QueryExamSectionsArgs = {
  filterBy?: Maybe<ExamSectionFilters>,
  orderBy?: Maybe<ExamSectionOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryExamQuestionArgs = {
  id: Scalars['ID']
};


export type QueryExamQuestionsArgs = {
  filterBy?: Maybe<ExamQuestionFilters>,
  orderBy?: Maybe<ExamQuestionOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryExamQuestionTypeArgs = {
  id: Scalars['ID']
};


export type QueryExamQuestionTypesArgs = {
  filterBy?: Maybe<ExamQuestionTypeFilters>,
  orderBy?: Maybe<ExamQuestionTypeOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryExamQuestionTypesByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryExamAttemptArgs = {
  id: Scalars['ID']
};


export type QueryExamAttemptsArgs = {
  filterBy?: Maybe<ExamAttemptFilters>,
  orderBy?: Maybe<ExamAttemptOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryGetFileArgs = {
  id: Scalars['ID']
};


export type QueryFreepassArgs = {
  id: Scalars['ID']
};


export type QueryFreepassProductArgs = {
  id: Scalars['ID']
};


export type QueryFreepassProductsArgs = {
  filterBy?: Maybe<FreepassProductFilters>,
  orderBy?: Maybe<FreepassProductOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryHtmlTextArgs = {
  id: Scalars['ID']
};


export type QueryHtmlTextsArgs = {
  filterBy?: Maybe<HtmlTextFilters>,
  orderBy?: Maybe<HtmlTextOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']
};


export type QueryInvoicesArgs = {
  filterBy?: Maybe<InvoiceFilters>,
  orderBy?: Maybe<InvoiceOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryInvoicesByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryMajorArgs = {
  id: Scalars['ID']
};


export type QueryMajorsArgs = {
  filterBy?: Maybe<MajorFilters>,
  orderBy?: Maybe<MajorOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryMenuArgs = {
  id: Scalars['ID']
};


export type QueryMenuByCodeArgs = {
  code: Scalars['String']
};


export type QueryMenusArgs = {
  filterBy?: Maybe<MenuFilters>,
  orderBy?: Maybe<MenuOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryMenusByIDsArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryOfflineArgs = {
  id: Scalars['ID']
};


export type QueryOfflinesArgs = {
  filterBy?: Maybe<OfflineFilters>,
  orderBy?: Maybe<OfflineOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryOfflineByIDsArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryOfflineByContentIdArgs = {
  contentID: Scalars['ID']
};


export type QueryOfflinesByContentIDsArgs = {
  contentIDs: Array<Scalars['ID']>
};


export type QueryOrderArgs = {
  id: Scalars['ID']
};


export type QueryOrdersArgs = {
  filterBy?: Maybe<OrderFilters>,
  pagination?: Maybe<Pagination>
};


export type QueryPackageArgs = {
  id: Scalars['ID']
};


export type QueryPackagesArgs = {
  filterBy?: Maybe<PackageFilters>,
  orderBy?: Maybe<PackageOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryPaymentArgs = {
  id: Scalars['ID']
};


export type QueryPaymentsArgs = {
  filterBy?: Maybe<PaymentFilters>,
  pagination?: Maybe<Pagination>
};


export type QueryPaymentStateArgs = {
  id: Scalars['ID']
};


export type QueryPermissionArgs = {
  id: Scalars['ID']
};


export type QueryPermissionsArgs = {
  filterBy?: Maybe<PermissionFilters>,
  orderBy?: Maybe<PermissionOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryPermissionsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryIsCurrentUserAllowedArgs = {
  scope?: Maybe<PermissionScope>
};


export type QueryPostCurationArgs = {
  id: Scalars['ID']
};


export type QueryPostCurationsArgs = {
  filterBy?: Maybe<PostCurationFilters>,
  orderBy?: Maybe<PostCurationOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryPostCurationsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryRefundArgs = {
  id: Scalars['ID']
};


export type QueryRefundsArgs = {
  filterBy?: Maybe<RefundFilters>,
  pagination?: Maybe<Pagination>
};


export type QueryResumeProductArgs = {
  id: Scalars['ID']
};


export type QueryResumeProductsArgs = {
  filterBy?: Maybe<ResumeProductFilters>,
  orderBy?: Maybe<ResumeProductOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryResumeArgs = {
  id: Scalars['ID']
};


export type QueryResumesArgs = {
  filterBy?: Maybe<ResumeFilters>,
  orderBy?: Maybe<ResumeOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryReviewArgs = {
  id: Scalars['ID']
};


export type QueryReviewsArgs = {
  filterBy?: Maybe<ReviewFilters>,
  orderBy?: Maybe<ReviewOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryScheduleArgs = {
  id: Scalars['ID']
};


export type QuerySchedulesArgs = {
  filterBy?: Maybe<ScheduleFilters>,
  orderBy?: Maybe<ScheduleOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySchedulesByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryScheduleEventArgs = {
  id: Scalars['ID']
};


export type QueryScheduleEventsArgs = {
  filterBy?: Maybe<ScheduleEventFilters>,
  orderBy?: Maybe<ScheduleEventOrder>,
  pagination?: Maybe<Pagination>,
  options?: Maybe<ScheduleEventOptions>
};


export type QuerySchoolArgs = {
  id: Scalars['ID']
};


export type QuerySchoolsArgs = {
  filterBy?: Maybe<SchoolFilters>,
  orderBy?: Maybe<SchoolOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUserSchoolsArgs = {
  filterBy?: Maybe<UserSchoolFilters>,
  orderBy?: Maybe<UserSchoolOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryMajorFromUserSchoolsArgs = {
  filterBy?: Maybe<UserSchoolMajorFilters>,
  orderBy?: Maybe<UserSchoolMajorOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySmsRejectArgs = {
  phoneNumber: Scalars['PhoneNumber']
};


export type QueryStudyArgs = {
  id: Scalars['ID']
};


export type QueryStudiesArgs = {
  filterBy?: Maybe<StudyFilters>,
  orderBy?: Maybe<StudyOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryStudyByIDsArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryOpenedStudiesArgs = {
  count?: Maybe<Scalars['Int']>
};


export type QueryClosedStudiesArgs = {
  count: Scalars['Int']
};


export type QueryStudyEventArgs = {
  id: Scalars['ID']
};


export type QueryStudyEventsByIDsArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryStudyEventsArgs = {
  filterBy?: Maybe<StudyEventFilters>,
  orderBy?: Maybe<StudyEventOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryStudyMissionArgs = {
  id: Scalars['ID']
};


export type QueryStudyMissionsArgs = {
  filterBy?: Maybe<StudyMissionFilters>,
  orderBy?: Maybe<StudyMissionOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUserStudyArgs = {
  id: Scalars['ID']
};


export type QueryUserStudiesByUserIdArgs = {
  userID: Scalars['ID']
};


export type QueryUserStudyEventArgs = {
  id: Scalars['ID']
};


export type QueryUserStudyEventsArgs = {
  filterBy?: Maybe<UserStudyEventFilters>,
  orderBy?: Maybe<UserStudyEventOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUserStudyMissionArgs = {
  id: Scalars['ID']
};


export type QueryUserStudiesArgs = {
  filterBy?: Maybe<UserStudyFilters>,
  orderBy?: Maybe<UserStudyOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUserStudyMissionsArgs = {
  filterBy?: Maybe<UserStudyMissionFilters>,
  orderBy?: Maybe<UserStudyMissionOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryIntegratedCommunityBoardsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryTeacherArgs = {
  id: Scalars['ID']
};


export type QueryTeachersArgs = {
  filterBy?: Maybe<TeacherFilters>,
  orderBy?: Maybe<TeacherOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryTeachersByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryTeacherCurationArgs = {
  id: Scalars['ID']
};


export type QueryTeacherCurationByCodeArgs = {
  code: Scalars['String']
};


export type QueryTeacherCurationsArgs = {
  filterBy?: Maybe<TeacherCurationFilters>,
  orderBy?: Maybe<TeacherCurationOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryTeamArgs = {
  id: Scalars['ID']
};


export type QueryTeamsArgs = {
  filterBy?: Maybe<TeamFilters>,
  orderBy?: Maybe<TeamOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryTeamsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryMembershipArgs = {
  id: Scalars['ID']
};


export type QueryMembershipsArgs = {
  filterBy?: Maybe<MembershipFilters>,
  orderBy?: Maybe<MembershipOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryMembershipsByIDsArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryUserArgs = {
  id: Scalars['ID']
};


export type QueryUsersArgs = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUsersByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryUserDevicesArgs = {
  filterBy?: Maybe<UserDeviceFilters>,
  orderBy?: Maybe<UserDeviceOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUserPointArgs = {
  id: Scalars['ID']
};


export type QueryUserPointsArgs = {
  filterBy?: Maybe<UserPointFilters>,
  orderBy?: Maybe<UserPointOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUserPointsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryUserRegisterStatsArgs = {
  filterBy: UserRegisterStatFilters,
  token?: Maybe<Scalars['String']>
};


export type QueryUserSignupStatsArgs = {
  filterBy: UserRegisterStatFilters,
  token?: Maybe<Scalars['String']>
};


export type QueryUserVisitStatsArgs = {
  filterBy: UserRegisterStatFilters,
  token?: Maybe<Scalars['String']>
};


export type QueryUserAllianceStatsArgs = {
  filterBy: UserAllianceStatFilters,
  token?: Maybe<Scalars['String']>
};


export type QueryVideoArgs = {
  id: Scalars['ID']
};


export type QueryVideosArgs = {
  filterBy?: Maybe<VideoFilters>,
  orderBy?: Maybe<VideoOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryVideoProductArgs = {
  id: Scalars['ID']
};


export type QueryVideoProductsArgs = {
  filterBy?: Maybe<VideoProductFilters>,
  orderBy?: Maybe<VideoProductOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryVideoLessonProductArgs = {
  id: Scalars['ID']
};


export type QueryVideoLessonProductsArgs = {
  filterBy?: Maybe<VideoLessonProductFilters>,
  orderBy?: Maybe<VideoLessonProductOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryRecoveryPasswordAuthByIdAndKeyArgs = {
  id: Scalars['ID'],
  key: Scalars['String']
};


export type QuerySupportTicketArgs = {
  id: Scalars['ID']
};


export type QuerySupportTicketsArgs = {
  filterBy?: Maybe<SupportTicketFilters>,
  orderBy?: Maybe<SupportTicketOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySupportTicketAnswerTemplateArgs = {
  id: Scalars['ID']
};


export type QuerySupportTicketAnswerTemplatesArgs = {
  filterBy?: Maybe<SupportTicketAnswerTemplateFilters>,
  orderBy?: Maybe<SupportTicketAnswerTemplateOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySupportTicketAnswerTemplatesByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QuerySupportTicketCategoryArgs = {
  id: Scalars['ID']
};


export type QuerySupportTicketCategoriesArgs = {
  filterBy?: Maybe<SupportTicketCategoryFilters>,
  orderBy?: Maybe<SupportTicketCategoryOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySupportTicketCategoryServiceMapArgs = {
  id: Scalars['ID']
};


export type QuerySupportTicketCategoryServiceMapsArgs = {
  filterBy?: Maybe<SupportTicketCategoryServiceMapFilters>,
  orderBy?: Maybe<SupportTicketCategoryServiceMapOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySupportTicketCategoryServiceMapsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryNewRegisteredSupportTicketsArgs = {
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  token?: Maybe<Scalars['String']>
};


export type QueryKeywordArgs = {
  code: Scalars['String']
};


export type QueryServicePurchaseStatsArgs = {
  filterBy: ServicePurchaseStatFilters,
  pagination?: Maybe<Pagination>,
  token?: Maybe<Scalars['String']>
};


export type QueryAllianceUserContentHistorysArgs = {
  filterBy: AllianceUserContentHistoryFilters,
  token?: Maybe<Scalars['String']>
};


export type QueryExamSubjectsArgs = {
  input: ExamSubjectsInput
};

export type Question = {
   __typename?: 'Question',
  id: Scalars['ID'],
  questionField1: Scalars['String'],
  questionField2?: Maybe<Scalars['String']>,
  explanation: Scalars['String'],
  answer?: Maybe<Scalars['Int']>,
  examQuestion: ExamQuestion,
};

export type QuestionCreateInput = {
  questionField1: Scalars['String'],
  questionField2?: Maybe<Scalars['String']>,
  answer: Scalars['Int'],
  explanation: Scalars['String'],
};

export type QuestionUpdateInput = {
  questionField1?: Maybe<Scalars['String']>,
  questionField2?: Maybe<Scalars['String']>,
  answer?: Maybe<Scalars['Int']>,
  explanation?: Maybe<Scalars['String']>,
};

export type RecoveryPasswordAuth = {
   __typename?: 'RecoveryPasswordAuth',
  id: Scalars['ID'],
  isValid: Scalars['Boolean'],
  user: User,
};

export type Refund = {
   __typename?: 'Refund',
  id: Scalars['ID'],
  contentPurchase: ContentPurchase,
  payment: Payment,
  amount: Scalars['Int'],
  message?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type RefundConnection = {
   __typename?: 'RefundConnection',
  nodes?: Maybe<Array<Maybe<Refund>>>,
};

export type RefundCreateInput = {
  contentPurchaseID: Scalars['ID'],
  paymentID: Scalars['ID'],
  amount: Scalars['Int'],
  message?: Maybe<Scalars['String']>,
};

export type RefundCreatePayload = {
   __typename?: 'RefundCreatePayload',
  refund?: Maybe<Refund>,
};

export type RefundFilters = {
  paymentID?: Maybe<Scalars['ID']>,
  contentPurchaseID?: Maybe<Scalars['ID']>,
};

export enum Residence {
  SEOUL = 'SEOUL',
  BUSAN = 'BUSAN',
  DAEGU = 'DAEGU',
  INCHEON = 'INCHEON',
  GWANGJU = 'GWANGJU',
  DAEJEON = 'DAEJEON',
  ULSAN = 'ULSAN',
  SEJONG = 'SEJONG',
  GYEONGGI = 'GYEONGGI',
  GANGWON = 'GANGWON',
  CHUNGCHEONGBUK = 'CHUNGCHEONGBUK',
  CHUNGCHEONGNAM = 'CHUNGCHEONGNAM',
  JEOLLABUK = 'JEOLLABUK',
  JEOLLANAM = 'JEOLLANAM',
  GYEONGSANGBUK = 'GYEONGSANGBUK',
  GYEONGSANGNAM = 'GYEONGSANGNAM',
  JEJU = 'JEJU'
}

export type Resume = {
   __typename?: 'Resume',
  id: Scalars['ID'],
  user: User,
  wishCompany?: Maybe<Company>,
  school?: Maybe<School>,
  major?: Maybe<Major>,
  userGPA?: Maybe<Scalars['Float']>,
  maxGPA: Scalars['Float'],
  resumeText?: Maybe<Scalars['String']>,
  resumeFile?: Maybe<File>,
  correctionFile?: Maybe<File>,
  correctionHTML?: Maybe<Scalars['String']>,
  languageTestSubject?: Maybe<Scalars['String']>,
  languageTestScore?: Maybe<Scalars['String']>,
  correctionStep: ResumeCorrectionStep,
  /** 자기소개서 공개 여부 */
  willOpen: Scalars['Boolean'],
  writtenAt?: Maybe<Scalars['Date']>,
  reviewedAt?: Maybe<Scalars['Date']>,
  isCompanyAccepted: Scalars['Boolean'],
  successfulCandidateReward?: Maybe<Scalars['Int']>,
  resumeProduct: ResumeProduct,
  /** 유저가 선택한 자기소개서 옵션 */
  options: Array<ResumeProductOption>,
  otherActivities: Array<Scalars['String']>,
  qna?: Maybe<Array<ResumeQnA>>,
  /** 자소서 첨삭 이력 */
  resumeHistory?: Maybe<Array<Resume>>,
};

export type ResumeConnection = {
   __typename?: 'ResumeConnection',
  nodes: Array<Resume>,
  totalCount: Scalars['Int'],
};

export enum ResumeCorrectionLimitType {
  BASIC = 'BASIC',
  UNLIMITED = 'UNLIMITED',
  TALKERBE = 'TALKERBE'
}

export enum ResumeCorrectionStep {
  REVIEWING = 'REVIEWING',
  COMPLETED = 'COMPLETED'
}

export type ResumeCreateInput = {
  purchaseID: Scalars['ID'],
  willOpen: Scalars['Boolean'],
  wishCompanyID?: Maybe<Scalars['ID']>,
  schoolID?: Maybe<Scalars['ID']>,
  majorID?: Maybe<Scalars['ID']>,
  userGPA?: Maybe<Scalars['Float']>,
  maxGPA: Scalars['Float'],
  resumeText?: Maybe<Scalars['String']>,
  resumeFile?: Maybe<Scalars['Upload']>,
  languageTestSubject?: Maybe<Scalars['String']>,
  languageTestScore?: Maybe<Scalars['String']>,
  otherActivities?: Maybe<Array<Scalars['String']>>,
  userResumeAnswer: Array<UserResumeAnswer>,
};

export type ResumeCreatePayload = {
   __typename?: 'ResumeCreatePayload',
  resume?: Maybe<Resume>,
};

export type ResumeFilters = {
  id?: Maybe<Scalars['ID']>,
  username?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  teacherID?: Maybe<Scalars['ID']>,
};

export type ResumeOrder = {
  field: ResumeOrderField,
  direction: OrderDirection,
};

export enum ResumeOrderField {
  ID = 'ID'
}

export type ResumeProduct = {
   __typename?: 'ResumeProduct',
  id: Scalars['ID'],
  content: Content,
  /** 기본 첨삭 소요시간 */
  defaultCorrectionTime?: Maybe<Scalars['String']>,
  caution?: Maybe<Scalars['String']>,
  introductionHTML: Scalars['String'],
  introductionImageFile: File,
  successfulCandidateSampleFile?: Maybe<File>,
  successfulCandidateFile?: Maybe<File>,
  exampleCorrectionFile?: Maybe<File>,
  isAvailableInHoliday: Scalars['Boolean'],
  isAvailableInWeekend: Scalars['Boolean'],
  /** 자기소개서 첨삭 횟수 유형 */
  correctionLimitType: ResumeCorrectionLimitType,
  /** 일일 최대 구매 가능횟수 */
  maxBuyableLimitPerDay?: Maybe<Scalars['Int']>,
  dailyQuantityLeft?: Maybe<Scalars['Int']>,
  isAvailableToSale?: Maybe<Scalars['Boolean']>,
  /** 자기소개서 옵션 */
  options: Array<ResumeProductOption>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type ResumeProductConnection = {
   __typename?: 'ResumeProductConnection',
  nodes: Array<ResumeProduct>,
  totalCount: Scalars['Int'],
};

export type ResumeProductCreatePayload = {
   __typename?: 'ResumeProductCreatePayload',
  resumeProduct?: Maybe<ResumeProduct>,
};

export type ResumeProductDeleteManyPayload = {
   __typename?: 'ResumeProductDeleteManyPayload',
  resumeProducts?: Maybe<Array<ResumeProduct>>,
};

export type ResumeProductDeletePayload = {
   __typename?: 'ResumeProductDeletePayload',
  resumeProduct?: Maybe<ResumeProduct>,
};

export type ResumeProductFilters = {
  contentID?: Maybe<Scalars['ID']>,
  subject?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
};

export type ResumeProductInput = {
  content?: Maybe<ContentCreateInput>,
  caution?: Maybe<Scalars['String']>,
  defaultCorrectionTime?: Maybe<Scalars['String']>,
  introductionHTML?: Maybe<Scalars['String']>,
  introductionImageFile?: Maybe<Scalars['Upload']>,
  successfulCandidateSampleFileID?: Maybe<Scalars['ID']>,
  successfulCandidateFileID?: Maybe<Scalars['ID']>,
  exampleCorrectionFileID?: Maybe<Scalars['ID']>,
  isAvailableInHoliday?: Maybe<Scalars['Boolean']>,
  isAvailableInWeekend?: Maybe<Scalars['Boolean']>,
  correctionLimitType?: Maybe<ResumeCorrectionLimitType>,
  maxBuyableLimitPerDay?: Maybe<Scalars['Int']>,
  teacherIDs?: Maybe<Array<Scalars['ID']>>,
};

export type ResumeProductOption = {
   __typename?: 'ResumeProductOption',
  id: Scalars['ID'],
  category: ResumeProductOptionCategory,
  name: Scalars['String'],
  description: Scalars['String'],
  priority: Scalars['Int'],
  price: Scalars['Int'],
};

export enum ResumeProductOptionCategory {
  CORRECTION_PERIOD = 'CORRECTION_PERIOD',
  CORRECTION_FORM = 'CORRECTION_FORM'
}

export type ResumeProductOrder = {
  field: ResumeProductOrderField,
  direction: OrderDirection,
};

export enum ResumeProductOrderField {
  ID = 'ID'
}

export type ResumeProductUpdatePayload = {
   __typename?: 'ResumeProductUpdatePayload',
  resumeProduct?: Maybe<ResumeProduct>,
};

export type ResumeQnA = {
   __typename?: 'ResumeQnA',
  questionNumber: Scalars['Int'],
  question: Scalars['String'],
  answer?: Maybe<Scalars['String']>,
};

export type ResumeUpdateInput = {
  correctionHTML?: Maybe<Scalars['String']>,
  correctionFile?: Maybe<Scalars['Upload']>,
  correctionStep?: Maybe<ResumeCorrectionStep>,
};

export type ResumeUpdatePayload = {
   __typename?: 'ResumeUpdatePayload',
  resume?: Maybe<Resume>,
};

export type Review = {
   __typename?: 'Review',
  id: Scalars['ID'],
  subject: Scalars['String'],
  bodyHTML: Scalars['String'],
  author: User,
  isPrivate: Scalars['Boolean'],
  score: Scalars['Int'],
  content: Content,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type ReviewConnection = {
   __typename?: 'ReviewConnection',
  nodes: Array<Review>,
  totalCount: Scalars['Int'],
  averageScore: Scalars['Float'],
};

export type ReviewCreateInput = {
  contentID: Scalars['ID'],
  title: Scalars['String'],
  content: Scalars['String'],
  score: Scalars['Int'],
};

export type ReviewCreatePayload = {
   __typename?: 'ReviewCreatePayload',
  review?: Maybe<CommunityPost>,
};

export type ReviewDeleteManyPayload = {
   __typename?: 'ReviewDeleteManyPayload',
  reviews?: Maybe<Array<Review>>,
};

export type ReviewDeletePayload = {
   __typename?: 'ReviewDeletePayload',
  review?: Maybe<Review>,
};

export type ReviewFilters = {
  reviewID?: Maybe<Scalars['ID']>,
  reviewSubject?: Maybe<Scalars['String']>,
  reviewBodyHTML?: Maybe<Scalars['String']>,
  reviewSubjectAndBodyHTML?: Maybe<Scalars['String']>,
  authorName?: Maybe<Scalars['String']>,
  contentID?: Maybe<Scalars['ID']>,
  contentSubject?: Maybe<Scalars['String']>,
  contentType?: Maybe<ContentType>,
  teacherID?: Maybe<Scalars['ID']>,
  service?: Maybe<ServiceType>,
  isPrivate?: Maybe<Scalars['Boolean']>,
  score?: Maybe<Scalars['Int']>,
  scoreRange?: Maybe<IntegerRange>,
  createdAt?: Maybe<DateRange>,
  categories?: Maybe<Array<Scalars['String']>>,
};

export type ReviewInput = {
  subject?: Maybe<Scalars['String']>,
  isPrivate?: Maybe<Scalars['Boolean']>,
};

export type ReviewMission = {
   __typename?: 'ReviewMission',
  useUrlCheck: Scalars['Boolean'],
  hashTags?: Maybe<Scalars['String']>,
  siteName?: Maybe<Scalars['String']>,
  boardName?: Maybe<Scalars['String']>,
  boardUrl?: Maybe<Scalars['String']>,
  minLinkCountLimit: Scalars['Int'],
};

export type ReviewMissionCreateInput = {
  useUrlCheck?: Maybe<Scalars['Boolean']>,
  hashTags?: Maybe<Scalars['String']>,
  siteName?: Maybe<Scalars['String']>,
  boardName?: Maybe<Scalars['String']>,
  boardUrl?: Maybe<Scalars['String']>,
  communityBoardId?: Maybe<Scalars['String']>,
  minLinkCountLimit?: Maybe<Scalars['Int']>,
};

export type ReviewMissionUpdateInput = {
  useUrlCheck?: Maybe<Scalars['Boolean']>,
  hashTags?: Maybe<Scalars['String']>,
  siteName?: Maybe<Scalars['String']>,
  boardName?: Maybe<Scalars['String']>,
  boardUrl?: Maybe<Scalars['String']>,
  communityBoardId?: Maybe<Scalars['String']>,
  minLinkCountLimit?: Maybe<Scalars['Int']>,
};

export type ReviewOrder = {
  field: ReviewOrderField,
  direction: OrderDirection,
};

export enum ReviewOrderField {
  ID = 'ID',
  SCORE = 'SCORE',
  CREATED_AT = 'CREATED_AT'
}

export type ReviewUpdatePayload = {
   __typename?: 'ReviewUpdatePayload',
  review?: Maybe<Review>,
};

export enum SaleStatus {
  WAIT = 'WAIT',
  ON_SALE = 'ON_SALE',
  SOLD_OUT = 'SOLD_OUT',
  CLOSED = 'CLOSED'
}

export type Schedule = {
   __typename?: 'Schedule',
  id: Scalars['ID'],
  title: ScheduleList,
  description?: Maybe<Scalars['String']>,
  events?: Maybe<ScheduleEventConnection>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};


export type ScheduleEventsArgs = {
  filterBy?: Maybe<ScheduleEventFilters>,
  orderBy?: Maybe<ScheduleEventOrder>,
  pagination?: Maybe<Pagination>,
  options?: Maybe<ScheduleEventOptions>
};

export type ScheduleConnection = {
   __typename?: 'ScheduleConnection',
  nodes: Array<Schedule>,
  totalCount: Scalars['Int'],
};

export type ScheduleCreatePayload = {
   __typename?: 'ScheduleCreatePayload',
  schedule?: Maybe<Schedule>,
};

export type ScheduleDeleteManyPayload = {
   __typename?: 'ScheduleDeleteManyPayload',
  schedules?: Maybe<Array<Schedule>>,
};

export type ScheduleDeletePayload = {
   __typename?: 'ScheduleDeletePayload',
  schedule?: Maybe<Schedule>,
};

export type ScheduleEvent = {
   __typename?: 'ScheduleEvent',
  id: Scalars['ID'],
  scheduleID: Scalars['ID'],
  sequence: Scalars['Int'],
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  useAutoUpdate: Scalars['Boolean'],
  autoUpdateWeekday?: Maybe<Weekday>,
  autoUpdateEndDate?: Maybe<Scalars['Date']>,
  description?: Maybe<Scalars['String']>,
};

export type ScheduleEventConnection = {
   __typename?: 'ScheduleEventConnection',
  nodes: Array<ScheduleEvent>,
  totalCount: Scalars['Int'],
};

export type ScheduleEventCreateInput = {
  scheduleID: Scalars['ID'],
  sequence: Scalars['Int'],
  description?: Maybe<Scalars['String']>,
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  useAutoUpdate: Scalars['Boolean'],
  autoUpdateWeekday?: Maybe<Weekday>,
  autoUpdateEndDate?: Maybe<Scalars['Date']>,
};

export type ScheduleEventCreatePayload = {
   __typename?: 'ScheduleEventCreatePayload',
  scheduleEvent?: Maybe<ScheduleEvent>,
};

export type ScheduleEventDeleteManyPayload = {
   __typename?: 'ScheduleEventDeleteManyPayload',
  scheduleEvents?: Maybe<Array<ScheduleEvent>>,
};

export type ScheduleEventDeletePayload = {
   __typename?: 'ScheduleEventDeletePayload',
  scheduleEvent?: Maybe<ScheduleEvent>,
};

export type ScheduleEventFilters = {
  scheduleID?: Maybe<Scalars['ID']>,
  sequence?: Maybe<Scalars['Int']>,
  startDate?: Maybe<DateRange>,
  endDate?: Maybe<DateRange>,
};

export type ScheduleEventOptions = {
  coalesceLatestOne?: Maybe<Scalars['Boolean']>,
};

export type ScheduleEventOrder = {
  field: ScheduleEventOrderField,
  direction?: Maybe<OrderDirection>,
};

export enum ScheduleEventOrderField {
  ID = 'ID',
  SEQUENCE = 'SEQUENCE',
  ENDDATE = 'ENDDATE'
}

export type ScheduleEventUpdateInput = {
  scheduleID?: Maybe<Scalars['ID']>,
  sequence?: Maybe<Scalars['Int']>,
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  useAutoUpdate?: Maybe<Scalars['Boolean']>,
  autoUpdateWeekday?: Maybe<Weekday>,
  autoUpdateEndDate?: Maybe<Scalars['Date']>,
  description?: Maybe<Scalars['String']>,
};

export type ScheduleEventUpdatePayload = {
   __typename?: 'ScheduleEventUpdatePayload',
  scheduleEvent?: Maybe<ScheduleEvent>,
};

export type ScheduleFilters = {
  title?: Maybe<ScheduleList>,
};

export type ScheduleInput = {
  title?: Maybe<ScheduleList>,
  description?: Maybe<Scalars['String']>,
};

export enum ScheduleList {
  WEPORT_FREEPASS = 'WEPORT_FREEPASS',
  NCS_FREEPASS = 'NCS_FREEPASS',
  TALKERBE_TOEICSPECKING = 'TALKERBE_TOEICSPECKING',
  SAMSUNG_FREEPASS = 'SAMSUNG_FREEPASS',
  TALKERBE_OPIC = 'TALKERBE_OPIC',
  TALKERBE_FREEPASS = 'TALKERBE_FREEPASS',
  SEVENGONG_FREEPASS = 'SEVENGONG_FREEPASS'
}

export type ScheduleOrder = {
  field: ScheduleOrderField,
  direction?: Maybe<OrderDirection>,
};

export enum ScheduleOrderField {
  ID = 'ID',
  TITLE = 'TITLE',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type ScheduleUpdateLambdaHandlerPayload = {
   __typename?: 'ScheduleUpdateLambdaHandlerPayload',
  updatedScheduleEvents: Array<Maybe<ScheduleEvent>>,
};

export type ScheduleUpdatePayload = {
   __typename?: 'ScheduleUpdatePayload',
  schedule?: Maybe<Schedule>,
};

export type School = {
   __typename?: 'School',
  id: Scalars['ID'],
  schoolName: Scalars['String'],
  campusName?: Maybe<Scalars['String']>,
};

export type SchoolConnection = {
   __typename?: 'SchoolConnection',
  nodes: Array<School>,
  totalCount: Scalars['Int'],
};

export type SchoolFilters = {
  q?: Maybe<Scalars['String']>,
};

export type SchoolOrder = {
  field: SchoolOrderField,
  direction: OrderDirection,
};

export enum SchoolOrderField {
  ID = 'ID',
  SCHOOL_NAME = 'SCHOOL_NAME',
  CAMPUS_NAME = 'CAMPUS_NAME'
}

export type ServicePurchaseStat = {
   __typename?: 'ServicePurchaseStat',
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  nodes: Array<ServicePurchaseStatDetail>,
};

export type ServicePurchaseStatConnection = {
   __typename?: 'ServicePurchaseStatConnection',
  nodes: Array<ServicePurchaseStat>,
  totalCount: Scalars['Int'],
};

export type ServicePurchaseStatDetail = {
   __typename?: 'ServicePurchaseStatDetail',
  salesAmount: Scalars['Int'],
  service: ServicePurchaseStatServiceType,
};

export type ServicePurchaseStatFilters = {
  period: StatPeriod,
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
};

export enum ServicePurchaseStatServiceType {
  WEPORT = 'WEPORT',
  NCS = 'NCS',
  ENGINIC = 'ENGINIC',
  OFFLINE = 'OFFLINE',
  TALKERBE = 'TALKERBE',
  SEVEN_GONG = 'SEVEN_GONG',
  COMPANY = 'COMPANY'
}

export enum ServiceType {
  WEPORT = 'WEPORT',
  TALKERBE = 'TALKERBE',
  NCS = 'NCS',
  ENGINIC = 'ENGINIC',
  SEVEN_GONG = 'SEVEN_GONG',
  COMMUNITY = 'COMMUNITY'
}

export type SmsReject = {
   __typename?: 'SmsReject',
  id: Scalars['ID'],
  createdAt: Scalars['Date'],
  cid: Scalars['String'],
  phoneNumber: Scalars['PhoneNumber'],
  /** 발신번호 */
  sendPhone: Scalars['String'],
  /** 080번호 */
  orgNumber: Scalars['String'],
  regDate: Scalars['String'],
};

export type SmsRejectInput = {
  cid?: Maybe<Scalars['String']>,
  phoneNumber: Scalars['PhoneNumber'],
  /** 발신번호 */
  sendPhone: Scalars['String'],
  /** 080번호 */
  orgNumber: Scalars['String'],
  regDate?: Maybe<Scalars['String']>,
};

export type SnsCallBack = {
  snsAccessToken: Scalars['String'],
  provider: Provider,
};

export type SnsRegisterInput = {
  email: Scalars['String'],
  phoneNumber: Scalars['String'],
  exam: Array<ExamToTake>,
  examSubjects: Array<Scalars['ID']>,
  service?: Maybe<ServiceType>,
  emailReceipt: Scalars['Boolean'],
  smsReceipt: Scalars['Boolean'],
  joinRoute: JoinRoute,
  authSMSInput?: Maybe<AuthSmsInput>,
  snsCallBack: SnsCallBack,
};

export type SnsRegisterPayload = {
   __typename?: 'SNSRegisterPayload',
  user: User,
};

export enum StatPeriod {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export type Study = {
   __typename?: 'Study',
  id: Scalars['ID'],
  service: ServiceType,
  title: Scalars['String'],
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  createdAt: Scalars['Date'],
  participantsLimit: Scalars['Boolean'],
  participantsLimitNumber?: Maybe<Scalars['Int']>,
  recruitPeriodStartDate?: Maybe<Scalars['Date']>,
  recruitPeriodEndDate?: Maybe<Scalars['Date']>,
  isDuplicateAllow: Scalars['Boolean'],
  duplicateRestrictStudies?: Maybe<Array<Study>>,
  pcLink?: Maybe<Scalars['String']>,
  mobileLink?: Maybe<Scalars['String']>,
  communityPostLink?: Maybe<Scalars['String']>,
  noticeLink: Scalars['String'],
  previousLink: Scalars['String'],
  completeBody: Scalars['String'],
  participantsNumber: Scalars['Int'],
  brazeEventProperties: StudyBrazeEventProperties,
  kakaoOpenChatUrl?: Maybe<Scalars['String']>,
  preNoticeUrl: Scalars['String'],
  isPrivate: Scalars['Boolean'],
  events?: Maybe<StudyEventConnection>,
  userStudyEvents?: Maybe<UserStudyEventConnection>,
};


export type StudyEventsArgs = {
  filterBy?: Maybe<StudyEventFilters>,
  orderBy?: Maybe<StudyEventOrder>,
  pagination?: Maybe<Pagination>
};


export type StudyUserStudyEventsArgs = {
  filterBy?: Maybe<UserStudyEventFilters>,
  orderBy?: Maybe<UserStudyEventOrder>,
  pagination?: Maybe<Pagination>
};

export type StudyBenefit = {
   __typename?: 'StudyBenefit',
  id: Scalars['ID'],
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  benefitType: BenefitType,
  benefit?: Maybe<Benefit>,
  studyEvent?: Maybe<StudyEvent>,
  provideDate?: Maybe<Scalars['Date']>,
};

export type StudyBenefitCreatePayload = {
   __typename?: 'StudyBenefitCreatePayload',
  studyBenefit?: Maybe<StudyBenefit>,
};

export type StudyBenefitDeletePayload = {
   __typename?: 'StudyBenefitDeletePayload',
  studyBenefit?: Maybe<StudyBenefit>,
};

export type StudyBenefitInput = {
  studyEventID: Scalars['ID'],
  benefitType: BenefitType,
  benefitID: Scalars['ID'],
  provideDate?: Maybe<Scalars['Date']>,
};

export type StudyBenefitUpdatePayload = {
   __typename?: 'StudyBenefitUpdatePayload',
  studyBenefit?: Maybe<StudyBenefit>,
};

export type StudyBrazeEventProperties = {
   __typename?: 'StudyBrazeEventProperties',
  /** 스터디_구분 */
  studyClassification?: Maybe<Scalars['String']>,
  /** 스터디_이름 */
  studyName?: Maybe<Scalars['String']>,
  /** 스터디_기업 */
  studyCompany?: Maybe<Scalars['String']>,
};

export type StudyBrazeEventPropertiesInput = {
  /** 스터디_구분 */
  studyClassification?: Maybe<Scalars['String']>,
  /** 스터디_이름 */
  studyName?: Maybe<Scalars['String']>,
  /** 스터디_기업 */
  studyCompany?: Maybe<Scalars['String']>,
};

export type StudyConnection = {
   __typename?: 'StudyConnection',
  nodes: Array<Study>,
  totalCount: Scalars['Int'],
};

export type StudyCreateInput = {
  service: ServiceType,
  title: Scalars['String'],
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  recruitPeriodStartDate: Scalars['Date'],
  recruitPeriodEndDate: Scalars['Date'],
  participantsLimit: Scalars['Boolean'],
  participantsLimitNumber?: Maybe<Scalars['Int']>,
  isDuplicateAllow: Scalars['Boolean'],
  duplicateRestrictStudyIDs?: Maybe<Array<Scalars['ID']>>,
  pcLink?: Maybe<Scalars['String']>,
  mobileLink?: Maybe<Scalars['String']>,
  communityPostLink?: Maybe<Scalars['String']>,
  noticeLink: Scalars['String'],
  previousLink: Scalars['String'],
  completeBody: Scalars['String'],
  brazeEventProperties?: Maybe<StudyBrazeEventPropertiesInput>,
  kakaoOpenChatUrl?: Maybe<Scalars['String']>,
  preNoticeUrl: Scalars['String'],
  isPrivate?: Maybe<Scalars['Boolean']>,
};

export type StudyCreatePayload = {
   __typename?: 'StudyCreatePayload',
  study?: Maybe<Study>,
};

export type StudyDeleteManyPayload = {
   __typename?: 'StudyDeleteManyPayload',
  studies?: Maybe<Array<Study>>,
};

export type StudyDeletePayload = {
   __typename?: 'StudyDeletePayload',
  study?: Maybe<Study>,
};

export type StudyEvent = {
   __typename?: 'StudyEvent',
  id: Scalars['ID'],
  study: Study,
  sequence: Scalars['Int'],
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  benefits?: Maybe<Array<StudyBenefit>>,
  userStudyEvents?: Maybe<UserStudyEventConnection>,
  missions?: Maybe<Array<StudyMission>>,
  participantsNumber: Scalars['Int'],
  passedParticipantsNumber: Scalars['Int'],
};


export type StudyEventUserStudyEventsArgs = {
  filterBy?: Maybe<UserStudyEventFilters>,
  orderBy?: Maybe<UserStudyEventOrder>,
  pagination?: Maybe<Pagination>
};

export type StudyEventBulkCreatePayload = {
   __typename?: 'StudyEventBulkCreatePayload',
  studyEvents: Array<StudyEvent>,
};

export type StudyEventBulkInput = {
  sequence: Scalars['Int'],
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
};

export type StudyEventConnection = {
   __typename?: 'StudyEventConnection',
  nodes: Array<StudyEvent>,
  totalCount: Scalars['Int'],
};

export type StudyEventCreatePayload = {
   __typename?: 'StudyEventCreatePayload',
  studyEvent?: Maybe<StudyEvent>,
};

export type StudyEventFilters = {
  q?: Maybe<Scalars['String']>,
  studyID?: Maybe<Scalars['ID']>,
};

export type StudyEventInput = {
  studyID: Scalars['ID'],
  sequence: Scalars['Int'],
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
};

export type StudyEventOrder = {
  field: StudyEventOrderField,
  direction: OrderDirection,
};

export enum StudyEventOrderField {
  ID = 'ID',
  SEQUENCE = 'SEQUENCE'
}

export type StudyFilters = {
  q?: Maybe<Scalars['String']>,
  isPrivate?: Maybe<Scalars['Boolean']>,
};

export type StudyMission = {
   __typename?: 'StudyMission',
  id: Scalars['ID'],
  studyEvent: StudyEvent,
  dueDateStartAt: Scalars['Date'],
  dueDateEndAt: Scalars['Date'],
  title: Scalars['String'],
  missionType: MissionType,
  exposeImmediately: Scalars['Boolean'],
  exposeStartAt?: Maybe<Scalars['Date']>,
  passingCriteria: PassingCriteria,
  description?: Maybe<Scalars['String']>,
  missionDetails?: Maybe<MissionDetails>,
  /** 미션이 이미 시작한 경우 일부 상태값을 변경할 수 없음. */
  isMutable: Scalars['Boolean'],
};

export type StudyMissionConnection = {
   __typename?: 'StudyMissionConnection',
  nodes: Array<StudyMission>,
  totalCount: Scalars['Int'],
};

export type StudyMissionCreateInput = {
  studyEventID: Scalars['ID'],
  missionType: MissionType,
  studyMissionDetailCreateInput: StudyMissionDetailCreateInput,
};

export type StudyMissionCreatePayload = {
   __typename?: 'StudyMissionCreatePayload',
  studyMission?: Maybe<StudyMission>,
};

export type StudyMissionDeleteManyPayload = {
   __typename?: 'StudyMissionDeleteManyPayload',
  studyMissions?: Maybe<Array<StudyMission>>,
};

export type StudyMissionDeletePayload = {
   __typename?: 'StudyMissionDeletePayload',
  studyMission?: Maybe<StudyMission>,
};

export type StudyMissionDetailCreateInput = {
  assignmentFileMissionCreateInput?: Maybe<AssignmentFileMissionCreateInput>,
  assignmentLinkMissionCreateInput?: Maybe<AssignmentLinkMissionCreateInput>,
  lectureMissionCreateInput?: Maybe<LectureMissionCreateInput>,
  examMissionCreateInput?: Maybe<ExamMissionCreateInput>,
  reviewMissionCreateInput?: Maybe<ReviewMissionCreateInput>,
};

export type StudyMissionFilters = {
  studyMissionID?: Maybe<Scalars['ID']>,
  studyEventID?: Maybe<Scalars['ID']>,
  studyID?: Maybe<Scalars['ID']>,
  missionType?: Maybe<MissionType>,
};

export type StudyMissionOrder = {
  field: StudyMissionOrderField,
  direction: OrderDirection,
};

export enum StudyMissionOrderField {
  ID = 'ID'
}

export type StudyMissionUpdateInput = {
  studyEventID?: Maybe<Scalars['ID']>,
  dueDateStartAt?: Maybe<Scalars['Date']>,
  dueDateEndAt?: Maybe<Scalars['Date']>,
  title?: Maybe<Scalars['String']>,
  missionType?: Maybe<MissionType>,
  exposeImmediately?: Maybe<Scalars['Boolean']>,
  exposeStartAt?: Maybe<Scalars['Date']>,
  passingCriteria?: Maybe<PassingCriteria>,
  description?: Maybe<Scalars['String']>,
  assignmentFileMissionUpdateInput?: Maybe<AssignmentFileMissionUpdateInput>,
  assignmentLinkMissionUpdateInput?: Maybe<AssignmentLinkMissionUpdateInput>,
  lectureMissionUpdateInput?: Maybe<LectureMissionUpdateInput>,
  examMissionUpdateInput?: Maybe<ExamMissionUpdateInput>,
  reviewMissionUpdateInput?: Maybe<ReviewMissionUpdateInput>,
};

export type StudyMissionUpdatePayload = {
   __typename?: 'StudyMissionUpdatePayload',
  studyMission?: Maybe<StudyMission>,
};

export type StudyOrder = {
  field: StudyOrderField,
  direction: OrderDirection,
};

export enum StudyOrderField {
  ID = 'ID'
}

export type StudyRegisterInput = {
  userID: Scalars['ID'],
  studyID: Scalars['ID'],
};

export type StudyUpdateInput = {
  service?: Maybe<ServiceType>,
  title?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  recruitPeriodStartDate?: Maybe<Scalars['Date']>,
  recruitPeriodEndDate?: Maybe<Scalars['Date']>,
  participantsLimit?: Maybe<Scalars['Boolean']>,
  participantsLimitNumber?: Maybe<Scalars['Int']>,
  isDuplicateAllow?: Maybe<Scalars['Boolean']>,
  duplicateRestrictStudyIDs?: Maybe<Array<Scalars['ID']>>,
  pcLink?: Maybe<Scalars['String']>,
  mobileLink?: Maybe<Scalars['String']>,
  communityPostLink?: Maybe<Scalars['String']>,
  noticeLink?: Maybe<Scalars['String']>,
  previousLink?: Maybe<Scalars['String']>,
  completeBody?: Maybe<Scalars['String']>,
  brazeEventProperties?: Maybe<StudyBrazeEventPropertiesInput>,
  kakaoOpenChatUrl?: Maybe<Scalars['String']>,
  preNoticeUrl?: Maybe<Scalars['String']>,
  isPrivate?: Maybe<Scalars['Boolean']>,
};

export type StudyUpdatePayload = {
   __typename?: 'StudyUpdatePayload',
  study?: Maybe<Study>,
};

export type StudyUserContent = {
   __typename?: 'StudyUserContent',
  id: Scalars['ID'],
  userStudyEvent: UserStudyEvent,
  contentPurchase: ContentPurchase,
};

export enum SubjectCurriculum {
  LIBERAL_ARTS = 'LIBERAL_ARTS',
  NATURAL_SCIENCES = 'NATURAL_SCIENCES',
  ETC = 'ETC'
}

export type SupportTicket = {
   __typename?: 'SupportTicket',
  id: Scalars['ID'],
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  repliedAt?: Maybe<Scalars['Date']>,
  service?: Maybe<ServiceType>,
  writer?: Maybe<User>,
  writerName?: Maybe<Scalars['String']>,
  category: SupportTicketCategory,
  subject: Scalars['String'],
  bodyHTML?: Maybe<Scalars['String']>,
  emailReceipt: Scalars['Boolean'],
  file?: Maybe<File>,
  reply?: Maybe<Scalars['String']>,
  replyUser?: Maybe<User>,
  replyFile?: Maybe<File>,
  processState?: Maybe<SupportTicketProcessState>,
  ticketType: SupportTicketType,
};

export type SupportTicketAdminCreateInput = {
  service: ServiceType,
  writerID: Scalars['ID'],
  categoryID: Scalars['ID'],
  subject?: Maybe<Scalars['String']>,
  bodyHTML?: Maybe<Scalars['String']>,
  file?: Maybe<Scalars['Upload']>,
  ticketType: SupportTicketType,
  processState?: Maybe<SupportTicketProcessState>,
  replyTitle?: Maybe<Scalars['String']>,
  reply?: Maybe<Scalars['String']>,
  replyFile?: Maybe<Scalars['Upload']>,
};

export type SupportTicketAdminCreatePayload = {
   __typename?: 'SupportTicketAdminCreatePayload',
  supportTicket?: Maybe<SupportTicket>,
};

export type SupportTicketAdminUpdateInput = {
  service?: Maybe<ServiceType>,
  categoryID?: Maybe<Scalars['ID']>,
  subject?: Maybe<Scalars['String']>,
  bodyHTML?: Maybe<Scalars['String']>,
  file?: Maybe<Scalars['Upload']>,
  replyTitle?: Maybe<Scalars['String']>,
  reply?: Maybe<Scalars['String']>,
  replyFile?: Maybe<Scalars['Upload']>,
  processState?: Maybe<SupportTicketProcessState>,
};

export type SupportTicketAdminUpdatePayload = {
   __typename?: 'SupportTicketAdminUpdatePayload',
  supportTicket?: Maybe<SupportTicket>,
};

export type SupportTicketAnswerTemplate = {
   __typename?: 'SupportTicketAnswerTemplate',
  id: Scalars['ID'],
  subject: Scalars['String'],
  isActive: Scalars['Boolean'],
  descriptionHTML: Scalars['String'],
};

export type SupportTicketAnswerTemplateConnection = {
   __typename?: 'SupportTicketAnswerTemplateConnection',
  nodes?: Maybe<Array<Maybe<SupportTicketAnswerTemplate>>>,
  totalCount: Scalars['Int'],
};

export type SupportTicketAnswerTemplateCreatePayload = {
   __typename?: 'SupportTicketAnswerTemplateCreatePayload',
  supportTicketAnswerTemplate?: Maybe<SupportTicketAnswerTemplate>,
};

export type SupportTicketAnswerTemplateFilters = {
  subject?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
};

export type SupportTicketAnswerTemplateInput = {
  subject?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  descriptionID?: Maybe<Scalars['ID']>,
};

export type SupportTicketAnswerTemplateOrder = {
  field: SupportTicketAnswerTemplateOrderField,
  direction: OrderDirection,
};

export enum SupportTicketAnswerTemplateOrderField {
  ID = 'ID'
}

export type SupportTicketAnswerTemplateUpdatePayload = {
   __typename?: 'SupportTicketAnswerTemplateUpdatePayload',
  supportTicketAnswerTemplate?: Maybe<SupportTicketAnswerTemplate>,
};

export type SupportTicketCategory = {
   __typename?: 'SupportTicketCategory',
  id: Scalars['ID'],
  name: Scalars['String'],
  parentID?: Maybe<Scalars['ID']>,
  isActive: Scalars['Boolean'],
  services?: Maybe<Array<Maybe<SupportTicketCategoryServiceMap>>>,
};

export type SupportTicketCategoryConnection = {
   __typename?: 'SupportTicketCategoryConnection',
  nodes?: Maybe<Array<Maybe<SupportTicketCategory>>>,
  totalCount: Scalars['Int'],
};

export type SupportTicketCategoryCreatePayload = {
   __typename?: 'SupportTicketCategoryCreatePayload',
  supportTicketCategory?: Maybe<SupportTicketCategory>,
};

export type SupportTicketCategoryFilters = {
  name?: Maybe<Scalars['String']>,
  parentID?: Maybe<Scalars['ID']>,
  isActive?: Maybe<Scalars['Boolean']>,
  service?: Maybe<ServiceType>,
};

export type SupportTicketCategoryInput = {
  name?: Maybe<Scalars['String']>,
  parentID?: Maybe<Scalars['ID']>,
  isActive?: Maybe<Scalars['Boolean']>,
};

export type SupportTicketCategoryOrder = {
  field: SupportTicketCategoryOrderField,
  direction: OrderDirection,
};

export enum SupportTicketCategoryOrderField {
  ID = 'ID'
}

export type SupportTicketCategoryServiceMap = {
   __typename?: 'SupportTicketCategoryServiceMap',
  id: Scalars['ID'],
  service: ServiceType,
  category?: Maybe<SupportTicketCategory>,
};

export type SupportTicketCategoryServiceMapConnection = {
   __typename?: 'SupportTicketCategoryServiceMapConnection',
  nodes?: Maybe<Array<Maybe<SupportTicketCategoryServiceMap>>>,
  totalCount: Scalars['Int'],
};

export type SupportTicketCategoryServiceMapFilters = {
  service?: Maybe<ServiceType>,
};

export type SupportTicketCategoryServiceMapOrder = {
  field: SupportTicketCategoryServiceMapOrderField,
  direction: OrderDirection,
};

export enum SupportTicketCategoryServiceMapOrderField {
  ID = 'ID'
}

export type SupportTicketCategoryUpdatePayload = {
   __typename?: 'SupportTicketCategoryUpdatePayload',
  supportTicketCategory?: Maybe<SupportTicketCategory>,
};

export type SupportTicketConnection = {
   __typename?: 'SupportTicketConnection',
  nodes?: Maybe<Array<Maybe<SupportTicket>>>,
  totalCount: Scalars['Int'],
};

export type SupportTicketCreatePayload = {
   __typename?: 'SupportTicketCreatePayload',
  supportTicket?: Maybe<SupportTicket>,
};

export type SupportTicketFilters = {
  subject?: Maybe<Scalars['String']>,
  service?: Maybe<ServiceType>,
  writerName?: Maybe<Scalars['String']>,
  writer?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  ticketType?: Maybe<SupportTicketType>,
  createdAt?: Maybe<DateRange>,
  processState?: Maybe<SupportTicketProcessState>,
  replyUser?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['ID']>,
};

export type SupportTicketInput = {
  service?: Maybe<ServiceType>,
  writerID?: Maybe<Scalars['ID']>,
  writerName?: Maybe<Scalars['String']>,
  categoryID?: Maybe<Scalars['ID']>,
  subject?: Maybe<Scalars['String']>,
  bodyHTML?: Maybe<Scalars['String']>,
  emailReceipt?: Maybe<Scalars['Boolean']>,
  fileID?: Maybe<Scalars['ID']>,
  replyTitle?: Maybe<Scalars['String']>,
  reply?: Maybe<Scalars['String']>,
  replyID?: Maybe<Scalars['ID']>,
  replyFileID?: Maybe<Scalars['ID']>,
  processState?: Maybe<SupportTicketProcessState>,
  ticketType?: Maybe<SupportTicketType>,
};

export type SupportTicketOrder = {
  field: SupportTicketOrderField,
  direction: OrderDirection,
};

export enum SupportTicketOrderField {
  ID = 'ID'
}

export enum SupportTicketProcessState {
  REQUESTED = 'REQUESTED',
  DUPLICATED = 'DUPLICATED',
  COMPLETED = 'COMPLETED'
}

export enum SupportTicketType {
  ONLINE_INQUIRY = 'ONLINE_INQUIRY',
  PHONE_INQUIRY = 'PHONE_INQUIRY'
}

export type SupportTicketUpdatePayload = {
   __typename?: 'SupportTicketUpdatePayload',
  supportTicket?: Maybe<SupportTicket>,
};

export type Teacher = {
   __typename?: 'Teacher',
  id: Scalars['ID'],
  user?: Maybe<User>,
  name: Scalars['String'],
  priority: Scalars['Int'],
  videoContents?: Maybe<ContentConnection>,
  isActive: Scalars['Boolean'],
  includeInvoice: Scalars['Boolean'],
  mobileMainImage?: Maybe<File>,
  mobileThumbnailImage?: Maybe<File>,
  mobileDescriptionTitle: Scalars['String'],
  mobileDescriptionDetail: Scalars['String'],
  aboutDetailImage?: Maybe<File>,
  aboutListImage?: Maybe<File>,
  aboutRollingImage?: Maybe<File>,
  mainPageImage?: Maybe<File>,
  offlineLectureThumbnailImage?: Maybe<File>,
  videoListImage: File,
  aboutListText: Scalars['String'],
  aboutDetailText: Scalars['String'],
  videoListText: Scalars['String'],
  reviewScoreURL: Scalars['String'],
  mainImageLink: Scalars['String'],
  contactURL: Scalars['String'],
  hasResumeProduct: Scalars['Boolean'],
};


export type TeacherVideoContentsArgs = {
  fitlerBy?: Maybe<ContentFilters>,
  orderBy?: Maybe<ContentOrder>,
  pagination?: Maybe<Pagination>
};

export type TeacherCommissionInput = {
  teacherID: Scalars['ID'],
  partialContract: Scalars['Int'],
  totalContract: Scalars['Int'],
};

export type TeacherCommissions = {
   __typename?: 'TeacherCommissions',
  teacherID: Scalars['ID'],
  partialContract: Scalars['Int'],
  totalContract: Scalars['Int'],
};

export type TeacherConnection = {
   __typename?: 'TeacherConnection',
  nodes?: Maybe<Array<Maybe<Teacher>>>,
  totalCount: Scalars['Int'],
};

export type TeacherCreatePayload = {
   __typename?: 'TeacherCreatePayload',
  teacher?: Maybe<Teacher>,
};

export type TeacherCuration = {
   __typename?: 'TeacherCuration',
  id: Scalars['ID'],
  name: Scalars['String'],
  code: Scalars['String'],
  teachers: Array<Teacher>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type TeacherCurationConnection = {
   __typename?: 'TeacherCurationConnection',
  nodes: Array<TeacherCuration>,
  totalCount: Scalars['Int'],
};

export type TeacherCurationCreatePayload = {
   __typename?: 'TeacherCurationCreatePayload',
  teacherCuration?: Maybe<TeacherCuration>,
};

export type TeacherCurationDeleteManyPayload = {
   __typename?: 'TeacherCurationDeleteManyPayload',
  teacherCurations: Array<TeacherCuration>,
};

export type TeacherCurationDeletePayload = {
   __typename?: 'TeacherCurationDeletePayload',
  teacherCuration?: Maybe<TeacherCuration>,
};

export type TeacherCurationFilters = {
  q?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  createdAt?: Maybe<DateRange>,
  updatedAt?: Maybe<DateRange>,
};

export type TeacherCurationInput = {
  name?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  teacherIDs?: Maybe<Array<Scalars['ID']>>,
};

export type TeacherCurationOrder = {
  field: TeacherCurationOrderField,
  direction: OrderDirection,
};

export enum TeacherCurationOrderField {
  ID = 'ID',
  NAME = 'NAME',
  CODE = 'CODE',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type TeacherCurationUpdatePayload = {
   __typename?: 'TeacherCurationUpdatePayload',
  teacherCuration?: Maybe<TeacherCuration>,
};

export type TeacherDeletePayload = {
   __typename?: 'TeacherDeletePayload',
  teacher?: Maybe<Teacher>,
};

export type TeacherFilters = {
  teacherID?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  service?: Maybe<ServiceType>,
};

export type TeacherInput = {
  userID?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  priority?: Maybe<Scalars['Int']>,
  isActive?: Maybe<Scalars['Boolean']>,
  includeInvoice?: Maybe<Scalars['Boolean']>,
  mobileMainImage?: Maybe<Scalars['Upload']>,
  mobileThumbnailImage?: Maybe<Scalars['Upload']>,
  mobileDescriptionTitle?: Maybe<Scalars['String']>,
  mobileDescriptionDetail?: Maybe<Scalars['String']>,
  aboutDetailImage?: Maybe<Scalars['Upload']>,
  aboutListImage?: Maybe<Scalars['Upload']>,
  aboutRollingImage?: Maybe<Scalars['Upload']>,
  mainPageImage?: Maybe<Scalars['Upload']>,
  offlineLectureThumbnailImage?: Maybe<Scalars['Upload']>,
  videoListImage?: Maybe<Scalars['Upload']>,
  aboutListText?: Maybe<Scalars['String']>,
  aboutDetailText?: Maybe<Scalars['String']>,
  videoListText?: Maybe<Scalars['String']>,
  reviewScoreURL?: Maybe<Scalars['String']>,
  mainImageLink?: Maybe<Scalars['String']>,
  contactURL?: Maybe<Scalars['String']>,
};

export type TeacherOrder = {
  field: TeacherOrderField,
  direction: OrderDirection,
};

export enum TeacherOrderField {
  ID = 'ID',
  PRIORITY = 'PRIORITY'
}

export type TeacherUpdatePayload = {
   __typename?: 'TeacherUpdatePayload',
  teacher?: Maybe<Teacher>,
};

export type Team = {
   __typename?: 'Team',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  members: MembershipConnection,
  permissions: PermissionConnection,
  createdAt?: Maybe<Scalars['Date']>,
};

export type TeamConnection = {
   __typename?: 'TeamConnection',
  nodes: Array<Team>,
  totalCount: Scalars['Int'],
};

export type TeamCreatePayload = {
   __typename?: 'TeamCreatePayload',
  team?: Maybe<Team>,
};

export type TeamDeleteManyPayload = {
   __typename?: 'TeamDeleteManyPayload',
  teams?: Maybe<Array<Team>>,
};

export type TeamDeletePayload = {
   __typename?: 'TeamDeletePayload',
  team?: Maybe<Team>,
};

export type TeamFilters = {
  q?: Maybe<Scalars['String']>,
};

export type TeamInput = {
  name?: Maybe<Scalars['String']>,
};

export type TeamOrder = {
  field: TeamOrderField,
  direction?: Maybe<OrderDirection>,
};

export enum TeamOrderField {
  ID = 'ID'
}

export type TeamUpdatePayload = {
   __typename?: 'TeamUpdatePayload',
  team?: Maybe<Team>,
};

export type TempAccount = {
   __typename?: 'TempAccount',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['PhoneNumber']>,
  examAttemptByExamID?: Maybe<ExamAttempt>,
  linkedUser?: Maybe<User>,
};


export type TempAccountExamAttemptByExamIdArgs = {
  examID: Scalars['ID']
};

export type TempAccountInput = {
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['PhoneNumber']>,
};

export type TempAccountLinkToUserPayload = {
   __typename?: 'TempAccountLinkToUserPayload',
  tempAccount?: Maybe<TempAccount>,
};

export type TempAccountSignupPayload = {
   __typename?: 'TempAccountSignupPayload',
  tempAccount?: Maybe<TempAccount>,
};


export enum TimeUnit {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}

export type UpdatePwByUsernamePayload = {
   __typename?: 'UpdatePWByUsernamePayload',
  user?: Maybe<User>,
};

export type UpdatePwPayload = {
   __typename?: 'UpdatePWPayload',
  user?: Maybe<User>,
};


/** 
 * 이메일은 현재 제휴 유저를 포함한 이메일 양식에 부합하지 않은 유저들이 있는 관계로
 * EmailType을 사용할 수 없음
 */
export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  authUser?: Maybe<AuthUser>,
  allianceUser?: Maybe<AllianceUser>,
  username: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['PhoneNumber']>,
  isAllowed: Scalars['Boolean'],
  totalCouponCount: Scalars['Int'],
  totalPoint?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['Date']>,
  lastLoginAt?: Maybe<Scalars['Date']>,
  emailReceipt?: Maybe<Scalars['Boolean']>,
  smsReceipt?: Maybe<Scalars['Boolean']>,
  birth?: Maybe<Scalars['Date']>,
  examToTake?: Maybe<Array<Maybe<ExamToTake>>>,
  joinRoute?: Maybe<JoinRoute>,
  allianceInfoCompleted?: Maybe<Scalars['Boolean']>,
  residence?: Maybe<Residence>,
  subjectCurriculum?: Maybe<SubjectCurriculum>,
  externalCompany?: Maybe<Scalars['String']>,
  device?: Maybe<Scalars['String']>,
  devices: Array<Maybe<UserDevice>>,
  pointHistory: Array<Maybe<UserPoint>>,
  service?: Maybe<ServiceType>,
  order?: Maybe<Array<Order>>,
  didParticipateStudy: Scalars['Boolean'],
  examSubject?: Maybe<Array<Maybe<ExamSubject>>>,
};


/** 
 * 이메일은 현재 제휴 유저를 포함한 이메일 양식에 부합하지 않은 유저들이 있는 관계로
 * EmailType을 사용할 수 없음
 */
export type UserIsAllowedArgs = {
  scope: PermissionScope
};

export type UserAllianceStat = {
   __typename?: 'UserAllianceStat',
  startDate: Scalars['Date'],
  userRegisterCount: Scalars['Int'],
  userVisitCount: Scalars['Int'],
  userVideoCount: Scalars['Int'],
  userDocumentCount: Scalars['Int'],
  userExamCount: Scalars['Int'],
};

export type UserAllianceStatConnection = {
   __typename?: 'UserAllianceStatConnection',
  nodes: Array<UserAllianceStat>,
  total: Scalars['Int'],
};

export type UserAllianceStatFilters = {
  category: Scalars['String'],
  period: StatPeriod,
  date: Scalars['Date'],
  endDate?: Maybe<Scalars['Date']>,
  pagination?: Maybe<Pagination>,
};

export type UserConnection = {
   __typename?: 'UserConnection',
  nodes?: Maybe<Array<Maybe<User>>>,
  totalCount: Scalars['Int'],
};

export type UserDevice = {
   __typename?: 'UserDevice',
  id?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['Date']>,
  userID?: Maybe<Scalars['ID']>,
  deviceID?: Maybe<Scalars['String']>,
  deviceName?: Maybe<Scalars['String']>,
  deviceNickName?: Maybe<Scalars['String']>,
  isForFreepass?: Maybe<Scalars['Boolean']>,
  playerType?: Maybe<PlayerType>,
  deviceNickNameUpdatedAt?: Maybe<Scalars['Date']>,
  isDeleted?: Maybe<Scalars['Boolean']>,
  isSystem?: Maybe<Scalars['Boolean']>,
};

export type UserDeviceConnection = {
   __typename?: 'UserDeviceConnection',
  nodes: Array<UserDevice>,
  totalCount: Scalars['Int'],
};

export type UserDeviceDeletePayload = {
   __typename?: 'UserDeviceDeletePayload',
  userDevice?: Maybe<UserDevice>,
};

export type UserDeviceFilters = {
  userID?: Maybe<Scalars['ID']>,
};

export type UserDeviceOrder = {
  field: UserDeviceOrderField,
  direction: OrderDirection,
};

export enum UserDeviceOrderField {
  ID = 'ID',
  USERID = 'USERID'
}

export type UserFilters = {
  q?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  authUserID?: Maybe<Scalars['ID']>,
  username?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  isAllianceUser?: Maybe<Scalars['Boolean']>,
  schoolName?: Maybe<Scalars['String']>,
  grade?: Maybe<AllianceUserGrade>,
};

export type UserInfoInput = {
  birth?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['EmailAddress']>,
  emailReceipt?: Maybe<Scalars['Boolean']>,
  smsReceipt?: Maybe<Scalars['Boolean']>,
  phone?: Maybe<PhoneUpdateInput>,
  examToTake?: Maybe<Array<Maybe<ExamToTake>>>,
  residence?: Maybe<Residence>,
  subjectCurriculum?: Maybe<SubjectCurriculum>,
  joinRoute?: Maybe<JoinRoute>,
};

export type UserInfoUpdatePayload = {
   __typename?: 'UserInfoUpdatePayload',
  user?: Maybe<User>,
};

export type UserLeaveLog = {
   __typename?: 'UserLeaveLog',
  id: Scalars['ID'],
  name: Scalars['String'],
  email: Scalars['EmailAddress'],
  phoneNumber: Scalars['PhoneNumber'],
  birth: Scalars['String'],
  reason: LeaveReason,
  message: Scalars['String'],
  userID: Scalars['ID'],
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type UserLeavePayload = {
   __typename?: 'UserLeavePayload',
  userleavelog?: Maybe<UserLeaveLog>,
};

export type UserMajor = {
   __typename?: 'UserMajor',
  major: Scalars['String'],
  majorName: Scalars['String'],
};

export type UserOrder = {
  field: UserOrderField,
  direction: OrderDirection,
};

export enum UserOrderField {
  ID = 'ID'
}

export type UserPoint = {
   __typename?: 'UserPoint',
  id: Scalars['ID'],
  point: Scalars['Int'],
  message: Scalars['String'],
  user: User,
  state: UserPointState,
  createdAt: Scalars['Date'],
};

export type UserPointConnection = {
   __typename?: 'UserPointConnection',
  nodes: Array<UserPoint>,
  totalCount: Scalars['Int'],
};

export type UserPointCreateInput = {
  message: Scalars['String'],
  point: Scalars['Int'],
  userID: Scalars['ID'],
  state: UserPointState,
};

export type UserPointCreatePayload = {
   __typename?: 'UserPointCreatePayload',
  userPoint?: Maybe<UserPoint>,
};

export type UserPointDeleteManyPayload = {
   __typename?: 'UserPointDeleteManyPayload',
  userPoints?: Maybe<Array<UserPoint>>,
};

export type UserPointDeletePayload = {
   __typename?: 'UserPointDeletePayload',
  userPoint?: Maybe<UserPoint>,
};

export type UserPointFilters = {
  q?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<DateRange>,
  state?: Maybe<UserPointState>,
};

export type UserPointOrder = {
  field: UserPointOrderField,
  direction: OrderDirection,
};

export enum UserPointOrderField {
  ID = 'ID'
}

export enum UserPointState {
  STATE_WAIT = 'STATE_WAIT',
  STATE_COMPLETE = 'STATE_COMPLETE'
}

export type UserPointUpdateInput = {
  message?: Maybe<Scalars['String']>,
  point?: Maybe<Scalars['Int']>,
  state?: Maybe<UserPointState>,
};

export type UserPointUpdatePayload = {
   __typename?: 'UserPointUpdatePayload',
  userPoint?: Maybe<UserPoint>,
};

export enum UserRegisterCategory {
  TOTAL = 'TOTAL',
  TOTALWITHOUTALLIANCE = 'TOTALWITHOUTALLIANCE',
  ALLIANCE = 'ALLIANCE',
  WEPORT = 'WEPORT',
  NCS = 'NCS',
  ENGINIC = 'ENGINIC',
  TALKERBE = 'TALKERBE',
  SEVEN_GONG = 'SEVEN_GONG',
  NAVER = 'NAVER',
  KAKAO = 'KAKAO',
  MOBILE = 'MOBILE',
  EXAM_TO_TAKE_WEPORT = 'EXAM_TO_TAKE_WEPORT',
  EXAM_TO_TAKE_NCS = 'EXAM_TO_TAKE_NCS',
  EXAM_TO_TAKE_ENGINIC = 'EXAM_TO_TAKE_ENGINIC',
  EXAM_TO_TAKE_TOSOPIC = 'EXAM_TO_TAKE_TOSOPIC',
  EXAM_TO_TAKE_7_GONG = 'EXAM_TO_TAKE_7GONG',
  EXAM_TO_TAKE_WEPORT_ENGINIC = 'EXAM_TO_TAKE_WEPORT_ENGINIC'
}

export type UserRegisterStat = {
   __typename?: 'UserRegisterStat',
  startDate: Scalars['Date'],
  userRegisterCount: Scalars['Int'],
};

export type UserRegisterStatConnection = {
   __typename?: 'UserRegisterStatConnection',
  nodes: Array<UserRegisterStat>,
  nodesOfLastYear: Array<UserRegisterStat>,
  summary: UserRegisterStatSummary,
  growthRate: Array<UserRegisterStatGrowthRate>,
  growthRateSummary: UserRegisterStatGrowthRateSummary,
  weeklySummaryOfLastWeek: UserRegisterStatSummary,
  weeklySummaryOfLastYear: UserRegisterStatSummary,
  yearlySummaryOfLastYear: UserRegisterStatSummary,
};

export type UserRegisterStatFilters = {
  category: UserRegisterCategory,
  period: StatPeriod,
  date: Scalars['Date'],
  endDate?: Maybe<Scalars['Date']>,
  pagination?: Maybe<Pagination>,
};

export type UserRegisterStatGrowthRate = {
   __typename?: 'UserRegisterStatGrowthRate',
  startDate: Scalars['Date'],
  userRegisterCountGrowthRate: Scalars['Float'],
};

export type UserRegisterStatGrowthRateSummary = {
   __typename?: 'UserRegisterStatGrowthRateSummary',
  totalUserRegisterCountGrowthRate: Scalars['Float'],
};

export enum UserRegisterStatOrderField {
  ID = 'ID'
}

export type UserRegisterStatSummary = {
   __typename?: 'UserRegisterStatSummary',
  totalUserRegisterCount: Scalars['Int'],
};

export type UserResumeAnswer = {
  questionID: Scalars['ID'],
  answer?: Maybe<Scalars['String']>,
};

export type UserSchool = {
   __typename?: 'UserSchool',
  seq: Scalars['Int'],
  schoolType: Scalars['String'],
  schoolName: Scalars['String'],
};

export type UserSchoolConnection = {
   __typename?: 'UserSchoolConnection',
  nodes: Array<UserSchool>,
  totalCount: Scalars['Int'],
};

export type UserSchoolFilters = {
  seq?: Maybe<Scalars['Int']>,
  schoolType?: Maybe<Scalars['String']>,
  schoolName?: Maybe<Scalars['String']>,
};

export type UserSchoolMajor = {
   __typename?: 'UserSchoolMajor',
  userMajor: Array<UserMajor>,
};

export type UserSchoolMajorConnection = {
   __typename?: 'UserSchoolMajorConnection',
  nodes: Array<UserSchoolMajor>,
  totalCount: Scalars['Int'],
};

export type UserSchoolMajorFilters = {
  schoolName?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
};

export type UserSchoolMajorOrder = {
  field?: Maybe<UserSchoolMajorOrderField>,
  direction?: Maybe<OrderDirection>,
};

export enum UserSchoolMajorOrderField {
  SCHOOL_NAME = 'SCHOOL_NAME',
  MAJOR = 'MAJOR',
  MAJOR_NAME = 'MAJOR_NAME'
}

export type UserSchoolOrder = {
  field?: Maybe<UserSchoolOrderField>,
  direction?: Maybe<OrderDirection>,
};

export enum UserSchoolOrderField {
  SCHOOL_NAME = 'SCHOOL_NAME',
  SEQ = 'SEQ'
}

export type UserSignupStatConnection = {
   __typename?: 'UserSignupStatConnection',
  nodes: Array<UserRegisterStat>,
  total: Scalars['Int'],
};

export type UserStudy = {
   __typename?: 'UserStudy',
  id: Scalars['ID'],
  user: User,
  study: Study,
  userStudyEvents?: Maybe<Array<UserStudyEvent>>,
  state: UserStudyState,
  createdAt: Scalars['Date'],
  totalLearningTime: Scalars['Int'],
  rank: Scalars['Int'],
  percentileRank: Scalars['Float'],
  dailyStudyTimes: Array<DailyStudyTime>,
  totalLecturePlayTime: Scalars['Int'],
  totalLectureRealPlayTime: Scalars['Int'],
  dailyLectureRealPlayTimes: Array<DailyStudyTime>,
};


export type UserStudyTotalLearningTimeArgs = {
  unit?: Maybe<TimeUnit>
};


export type UserStudyDailyStudyTimesArgs = {
  unit?: Maybe<TimeUnit>
};


export type UserStudyTotalLecturePlayTimeArgs = {
  unit?: Maybe<TimeUnit>
};


export type UserStudyTotalLectureRealPlayTimeArgs = {
  unit?: Maybe<TimeUnit>
};


export type UserStudyDailyLectureRealPlayTimesArgs = {
  unit?: Maybe<TimeUnit>
};

export type UserStudyBenefitCreateInput = {
  userID: Scalars['ID'],
  studyEventID: Scalars['ID'],
  userStudyEventID: Scalars['ID'],
};

export type UserStudyBenefitCreatePayload = {
   __typename?: 'UserStudyBenefitCreatePayload',
  studyUserContents?: Maybe<Array<Maybe<StudyUserContent>>>,
};

export type UserStudyConnection = {
   __typename?: 'UserStudyConnection',
  nodes: Array<UserStudy>,
  totalCount: Scalars['Int'],
};

export type UserStudyEvent = {
   __typename?: 'UserStudyEvent',
  id: Scalars['ID'],
  study: Study,
  studyEvent: StudyEvent,
  user: User,
  state: UserStudyEventState,
  userStudy: UserStudy,
  userStudyMissionsAndContents: UserStudyMissionsAndContents,
  isPreviousSequenceFailed: Scalars['Boolean'],
  createdAt: Scalars['Date'],
  rank: Scalars['Int'],
  percentileRank: Scalars['Float'],
  lectureProgressRate: Scalars['Float'],
  lectureRealTimeProgressRate: Scalars['Float'],
  totalLecturePlayTime: Scalars['Int'],
  totalLectureRealPlayTime: Scalars['Int'],
};


export type UserStudyEventTotalLecturePlayTimeArgs = {
  unit?: Maybe<TimeUnit>
};


export type UserStudyEventTotalLectureRealPlayTimeArgs = {
  unit?: Maybe<TimeUnit>
};

export type UserStudyEventConnection = {
   __typename?: 'UserStudyEventConnection',
  nodes: Array<UserStudyEvent>,
  totalCount: Scalars['Int'],
};

export type UserStudyEventFailPayload = {
   __typename?: 'UserStudyEventFailPayload',
  userStudyEvents?: Maybe<Array<UserStudyEvent>>,
};

export type UserStudyEventFilters = {
  id?: Maybe<Scalars['ID']>,
  studyID?: Maybe<Scalars['ID']>,
  studyEventID?: Maybe<Scalars['ID']>,
  userStudyID?: Maybe<Scalars['ID']>,
  studyEventSequence?: Maybe<Scalars['Int']>,
  states?: Maybe<Array<UserStudyEventState>>,
  phoneNumber?: Maybe<Scalars['String']>,
  /** 실명 */
  name?: Maybe<Scalars['String']>,
  /** 아이디 */
  username?: Maybe<Scalars['String']>,
};

export type UserStudyEventForcePassPayload = {
   __typename?: 'UserStudyEventForcePassPayload',
  userStudyEvents?: Maybe<Array<UserStudyEvent>>,
  forcePassCount: Scalars['Int'],
};

export type UserStudyEventOrder = {
  field: UserStudyEventOrderField,
  direction: OrderDirection,
};

export enum UserStudyEventOrderField {
  ID = 'ID'
}

export type UserStudyEventPassPayload = {
   __typename?: 'UserStudyEventPassPayload',
  userStudyEvents?: Maybe<Array<UserStudyEvent>>,
};

export enum UserStudyEventState {
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  CHECKING = 'CHECKING',
  FAIL = 'FAIL',
  PASS = 'PASS',
  FORCED_PASS = 'FORCED_PASS'
}

export type UserStudyFilters = {
  studyID?: Maybe<Scalars['ID']>,
  userStudyState?: Maybe<UserStudyState>,
  isStudyPrivate?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
};

export type UserStudyMission = {
   __typename?: 'UserStudyMission',
  id: Scalars['ID'],
  user: User,
  userStudyEvent: UserStudyEvent,
  studyMission: StudyMission,
  state: UserStudyMissionState,
  isSubmitted: Scalars['Boolean'],
  userStudyMissionDetails?: Maybe<Array<Maybe<UserStudyMissionDetail>>>,
  createdAt: Scalars['Date'],
};

export type UserStudyMissionAssignmentFile = {
   __typename?: 'UserStudyMissionAssignmentFile',
  file?: Maybe<File>,
};

export type UserStudyMissionAssignmentFileUploadInput = {
  fileIDs: Array<Scalars['ID']>,
};

export type UserStudyMissionAssignmentFileUploadPayload = {
   __typename?: 'UserStudyMissionAssignmentFileUploadPayload',
  userStudyMission: UserStudyMission,
};

export type UserStudyMissionAssignmentLink = {
   __typename?: 'UserStudyMissionAssignmentLink',
  communityPostId?: Maybe<Scalars['String']>,
  link?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  contentText?: Maybe<Scalars['String']>,
  attachmentsIds?: Maybe<Array<Scalars['ID']>>,
  isAnonymous?: Maybe<Scalars['Boolean']>,
};

export type UserStudyMissionAssignmentLinkUploadCommunityInput = {
  allowTrackback?: Maybe<Scalars['Boolean']>,
  anonymous?: Maybe<Scalars['Boolean']>,
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
  boardID: Scalars['ID'],
  commentStatus?: Maybe<PostCommentStatus>,
  containsImage?: Maybe<Scalars['Boolean']>,
  containsYoutube?: Maybe<Scalars['Boolean']>,
  content: Scalars['String'],
  contentText: Scalars['String'],
  imageSrc?: Maybe<Scalars['String']>,
  isNotice?: Maybe<Scalars['Boolean']>,
  notifyMessage?: Maybe<Scalars['Boolean']>,
  status: PostStatus,
  tags?: Maybe<Array<Scalars['String']>>,
  title: Scalars['String'],
  youtubeSrc?: Maybe<Scalars['String']>,
};

export type UserStudyMissionAssignmentLinkUploadInput = {
  links: Array<Scalars['String']>,
};

export type UserStudyMissionAssignmentLinkUploadPayload = {
   __typename?: 'UserStudyMissionAssignmentLinkUploadPayload',
  userStudyMission: UserStudyMission,
};

export type UserStudyMissionConnection = {
   __typename?: 'UserStudyMissionConnection',
  nodes: Array<UserStudyMission>,
  totalCount: Scalars['Int'],
};

export type UserStudyMissionDetail = UserStudyMissionAssignmentFile | UserStudyMissionAssignmentLink | UserStudyMissionLecture | UserStudyMissionExam | UserStudyMissionReview;

export type UserStudyMissionExam = {
   __typename?: 'UserStudyMissionExam',
  studyUserContent: ContentPurchase,
  score: Scalars['Float'],
};

export type UserStudyMissionFilters = {
  studyID?: Maybe<Scalars['ID']>,
  userID?: Maybe<Scalars['ID']>,
};

export type UserStudyMissionLecture = {
   __typename?: 'UserStudyMissionLecture',
  studyUserContent: ContentPurchase,
  completedVideoLessonCount: Scalars['Int'],
  totalVideoLessonCount: Scalars['Int'],
  progressRate: Scalars['Float'],
  realTimeProgressRate: Scalars['Float'],
};

export type UserStudyMissionOrder = {
  field: UserStudyMissionOrderField,
  direction: OrderDirection,
};

export enum UserStudyMissionOrderField {
  ID = 'ID',
  STATE = 'STATE'
}

export type UserStudyMissionReview = {
   __typename?: 'UserStudyMissionReview',
  communityPostId?: Maybe<Scalars['String']>,
  link?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  contentText?: Maybe<Scalars['String']>,
  attachmentsIds?: Maybe<Array<Scalars['ID']>>,
  isAnonymous?: Maybe<Scalars['Boolean']>,
};

export type UserStudyMissionReviewUploadCommunityInput = {
  allowTrackback?: Maybe<Scalars['Boolean']>,
  anonymous?: Maybe<Scalars['Boolean']>,
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
  boardID: Scalars['ID'],
  commentStatus?: Maybe<PostCommentStatus>,
  containsImage?: Maybe<Scalars['Boolean']>,
  containsYoutube?: Maybe<Scalars['Boolean']>,
  content: Scalars['String'],
  contentText: Scalars['String'],
  imageSrc?: Maybe<Scalars['String']>,
  isNotice?: Maybe<Scalars['Boolean']>,
  notifyMessage?: Maybe<Scalars['Boolean']>,
  status: PostStatus,
  tags?: Maybe<Array<Scalars['String']>>,
  title: Scalars['String'],
  youtubeSrc?: Maybe<Scalars['String']>,
};

export type UserStudyMissionReviewUploadInput = {
  links: Array<Scalars['String']>,
};

export type UserStudyMissionReviewUploadPayload = {
   __typename?: 'UserStudyMissionReviewUploadPayload',
  userStudyMission: UserStudyMission,
};

export type UserStudyMissionsAndContents = {
   __typename?: 'UserStudyMissionsAndContents',
  userStudyMissions?: Maybe<Array<UserStudyMission>>,
  studyUserContents?: Maybe<Array<ContentPurchase>>,
};

export enum UserStudyMissionState {
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  CHECKING = 'CHECKING',
  FAIL = 'FAIL',
  PASS = 'PASS',
  FORCED_PASS = 'FORCED_PASS'
}

export type UserStudyMissionsUpdatePayload = {
   __typename?: 'UserStudyMissionsUpdatePayload',
  userStudyMissions?: Maybe<Array<UserStudyMission>>,
};

export type UserStudyMissionUpdateInput = {
  state?: Maybe<UserStudyMissionState>,
};

export type UserStudyMissionUpdatePayload = {
   __typename?: 'UserStudyMissionUpdatePayload',
  userStudyMission?: Maybe<UserStudyMission>,
};

export type UserStudyOrder = {
  field: UserStudyOrderField,
  direction: OrderDirection,
};

export enum UserStudyOrderField {
  ID = 'ID',
  STATE = 'STATE'
}

export type UserStudyRegisterPayload = {
   __typename?: 'UserStudyRegisterPayload',
  userStudy: UserStudy,
};

export enum UserStudyState {
  INACTIVE = 'INACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  CHECKING = 'CHECKING',
  FAIL = 'FAIL',
  PASS = 'PASS',
  FORCED_PASS = 'FORCED_PASS'
}

export type UserVisitStatConnection = {
   __typename?: 'UserVisitStatConnection',
  nodes: Array<UserRegisterStat>,
};

export type Video = {
   __typename?: 'Video',
  /** 구매 번호(contentPurchaseID와 동일) */
  id: Scalars['ID'],
  user: User,
  content: Content,
  createdAt: Scalars['Date'],
  expiredAt?: Maybe<Scalars['Date']>,
  videoLessonPlayLogs?: Maybe<Array<VideoLessonPlayLog>>,
  videoLessons: Array<VideoLesson>,
  totalRealPlayTime: Scalars['Int'],
  completedVideoLessonCount: Scalars['Int'],
};


export type VideoVideoLessonPlayLogsArgs = {
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>
};


export type VideoTotalRealPlayTimeArgs = {
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  unit?: Maybe<TimeUnit>
};


export type VideoCompletedVideoLessonCountArgs = {
  standardProgressRate: Scalars['Float'],
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>
};

export type VideoConnection = {
   __typename?: 'VideoConnection',
  nodes: Array<Video>,
  totalCount: Scalars['Int'],
};

export type VideoFilters = {
  ids?: Maybe<Array<Scalars['ID']>>,
  contentIDs?: Maybe<Array<Scalars['ID']>>,
  subject?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['ID']>,
};

export type VideoLesson = {
   __typename?: 'VideoLesson',
  videoLessonProduct: VideoLessonProduct,
  videoLessonPlayLogs?: Maybe<Array<VideoLessonPlayLog>>,
  totalRealPlayTime: Scalars['Int'],
  totalPlayTime: Scalars['Int'],
  progressRate: Scalars['Float'],
};


export type VideoLessonVideoLessonPlayLogsArgs = {
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>
};


export type VideoLessonTotalRealPlayTimeArgs = {
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  unit?: Maybe<TimeUnit>
};


export type VideoLessonTotalPlayTimeArgs = {
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  unit?: Maybe<TimeUnit>
};


export type VideoLessonProgressRateArgs = {
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>
};

export type VideoLessonPlayLog = {
   __typename?: 'VideoLessonPlayLog',
  videoLessonProduct: VideoLessonProduct,
  playTime: Scalars['Int'],
  realPlayTime: Scalars['Int'],
  progressRate: Scalars['Float'],
};


export type VideoLessonPlayLogPlayTimeArgs = {
  unit?: Maybe<TimeUnit>
};


export type VideoLessonPlayLogRealPlayTimeArgs = {
  unit?: Maybe<TimeUnit>
};

export type VideoLessonProduct = {
   __typename?: 'VideoLessonProduct',
  id: Scalars['ID'],
  subject: Scalars['String'],
  lessonNumber: Scalars['Int'],
  runtime: Scalars['Int'],
  videoProduct: VideoProduct,
  isDeleted: Scalars['Boolean'],
  /** 강의자료(첨부파일) */
  teachingMaterialFile?: Maybe<File>,
  playTime: Scalars['Int'],
};


export type VideoLessonProductRuntimeArgs = {
  unit?: Maybe<TimeUnit>
};


export type VideoLessonProductPlayTimeArgs = {
  unit?: Maybe<TimeUnit>,
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>
};

export type VideoLessonProductConnection = {
   __typename?: 'VideoLessonProductConnection',
  nodes: Array<VideoLessonProduct>,
  totalCount: Scalars['Int'],
};

export type VideoLessonProductFilters = {
  q?: Maybe<Scalars['String']>,
  contentPurchaseID?: Maybe<Scalars['ID']>,
  videoProductID?: Maybe<Scalars['ID']>,
  isDeleted?: Maybe<Scalars['Boolean']>,
};

export type VideoLessonProductOrder = {
  field: VideoLessonProductOrderField,
  direction: OrderDirection,
};

export enum VideoLessonProductOrderField {
  ID = 'ID'
}

export type VideoOrder = {
  field: VideoOrderField,
  direction: OrderDirection,
};

export enum VideoOrderField {
  ID = 'ID'
}

export type VideoProduct = {
   __typename?: 'VideoProduct',
  id: Scalars['ID'],
  content: Content,
  shortDescription?: Maybe<Scalars['String']>,
  descriptionHTML: Scalars['String'],
  package?: Maybe<Package>,
  videoProductImage?: Maybe<File>,
  book?: Maybe<Book>,
  activeVideoLessonProducts?: Maybe<Array<VideoLessonProduct>>,
  videoLessonProducts: VideoLessonProductConnection,
};


export type VideoProductVideoLessonProductsArgs = {
  filterBy?: Maybe<VideoLessonProductFilters>,
  orderBy?: Maybe<VideoLessonProductOrder>,
  pagination?: Maybe<Pagination>
};

export type VideoProductConnection = {
   __typename?: 'VideoProductConnection',
  nodes: Array<VideoProduct>,
  totalCount: Scalars['Int'],
};

export type VideoProductCreatePayload = {
   __typename?: 'VideoProductCreatePayload',
  videoProduct?: Maybe<VideoProduct>,
};

export type VideoProductDeleteManyPayload = {
   __typename?: 'VideoProductDeleteManyPayload',
  videoProducts?: Maybe<Array<VideoProduct>>,
};

export type VideoProductDeletePayload = {
   __typename?: 'VideoProductDeletePayload',
  videoProduct?: Maybe<VideoProduct>,
};

export type VideoProductFilters = {
  name?: Maybe<Scalars['String']>,
  ids?: Maybe<Array<Scalars['ID']>>,
  contentIDs?: Maybe<Array<Scalars['ID']>>,
};

export type VideoProductInput = {
  subject: Scalars['String'],
  shortDescription?: Maybe<Scalars['String']>,
  descriptionHTML: Scalars['String'],
  packageID: Scalars['ID'],
};

export type VideoProductOrder = {
  field: VideoProductOrderField,
  direction: OrderDirection,
};

export enum VideoProductOrderField {
  ID = 'ID'
}

export type VideoProductUpdatePayload = {
   __typename?: 'VideoProductUpdatePayload',
  videoProduct?: Maybe<VideoProduct>,
};

export type VideoUpdatePayload = {
   __typename?: 'VideoUpdatePayload',
  video?: Maybe<Video>,
};

export enum Visibility {
  HIDDEN = 'HIDDEN',
  SEARCH = 'SEARCH',
  ALWAYS = 'ALWAYS',
  NO_SEARCH = 'NO_SEARCH'
}

export enum Weekday {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export enum WishExamCategory {
  COMPANY = 'COMPANY',
  INDUSTRY = 'INDUSTRY',
  DEPARTMENT = 'DEPARTMENT'
}

export type GqlAllianceUserContentQueryVariables = {
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  allianceName: Scalars['String']
};


export type GqlAllianceUserContentQuery = (
  { __typename?: 'Query' }
  & { allianceUserContentHistorys: (
    { __typename?: 'AllianceUserContentHistoryConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'AllianceUserContentHistory' }
      & Pick<AllianceUserContentHistory, 'id' | 'name' | 'major' | 'grade' | 'lastLogin' | 'contentName' | 'createdAt' | 'serviceType' | 'contentType' | 'contentCategoryType' | 'contentPrice'>
    )>> }
  ) }
);

export type GqlCurrentUserQueryVariables = {};


export type GqlCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
    & { allianceUser: Maybe<(
      { __typename?: 'AllianceUser' }
      & Pick<AllianceUser, 'id' | 'schoolId' | 'schoolName'>
    )> }
  )> }
);

export type GqlSalesStatsQueryVariables = {
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  period: StatPeriod,
  pagination?: Maybe<Pagination>
};


export type GqlSalesStatsQuery = (
  { __typename?: 'Query' }
  & { servicePurchaseStats: (
    { __typename?: 'ServicePurchaseStatConnection' }
    & Pick<ServicePurchaseStatConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'ServicePurchaseStat' }
      & Pick<ServicePurchaseStat, 'startDate' | 'endDate'>
      & { nodes: Array<(
        { __typename?: 'ServicePurchaseStatDetail' }
        & Pick<ServicePurchaseStatDetail, 'salesAmount' | 'service'>
      )> }
    )> }
  ) }
);

export type GqlAlliancesQueryVariables = {
  filterBy?: Maybe<AllianceFilters>
};


export type GqlAlliancesQuery = (
  { __typename?: 'Query' }
  & { alliances: (
    { __typename?: 'AllianceConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Alliance' }
      & Pick<Alliance, 'id' | 'allianceName' | 'createdAt' | 'expiredAt' | 'prefixUserName' | 'templatePath' | 'domain'>
    )>> }
  ) }
);

export type GqlUserAllianceStatsQueryVariables = {
  date: Scalars['Date'],
  endDate?: Maybe<Scalars['Date']>,
  period: StatPeriod,
  category: Scalars['String'],
  pagination: Pagination
};


export type GqlUserAllianceStatsQuery = (
  { __typename?: 'Query' }
  & { userAllianceStats: (
    { __typename?: 'UserAllianceStatConnection' }
    & Pick<UserAllianceStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserAllianceStat' }
      & Pick<UserAllianceStat, 'startDate' | 'userRegisterCount' | 'userVisitCount' | 'userVideoCount' | 'userDocumentCount' | 'userExamCount'>
    )> }
  ) }
);

export type GqlServiceTypeSignUpStatsQueryVariables = {
  date: Scalars['Date'],
  endDate?: Maybe<Scalars['Date']>,
  period: StatPeriod,
  pagination: Pagination
};


export type GqlServiceTypeSignUpStatsQuery = (
  { __typename?: 'Query' }
  & { signUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ), weportSignUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ), ncsSignUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ), sevenGongSignUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ), enginicSignUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ), talkerbeSignUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ), weportEngicSignUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ) }
);

export type GqlUserSignupStatsQueryVariables = {
  date: Scalars['Date'],
  endDate?: Maybe<Scalars['Date']>,
  period: StatPeriod,
  pagination: Pagination
};


export type GqlUserSignupStatsQuery = (
  { __typename?: 'Query' }
  & { totalSignUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ), signUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ), allianceSignUpUser: (
    { __typename?: 'UserSignupStatConnection' }
    & Pick<UserSignupStatConnection, 'total'>
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ), userVisitStats: (
    { __typename?: 'UserVisitStatConnection' }
    & { nodes: Array<(
      { __typename?: 'UserRegisterStat' }
      & Pick<UserRegisterStat, 'startDate' | 'userRegisterCount'>
    )> }
  ) }
);

export type GqlMajorListQueryVariables = {
  schoolName?: Maybe<Scalars['String']>
};


export type GqlMajorListQuery = (
  { __typename?: 'Query' }
  & { majorFromUserSchools: (
    { __typename?: 'UserSchoolMajorConnection' }
    & Pick<UserSchoolMajorConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'UserSchoolMajor' }
      & { userMajor: Array<(
        { __typename?: 'UserMajor' }
        & Pick<UserMajor, 'major' | 'majorName'>
      )> }
    )> }
  ) }
);

export type GqlUserSchoolsQueryVariables = {
  schoolName?: Maybe<Scalars['String']>,
  schoolType?: Maybe<Scalars['String']>,
  seq?: Maybe<Scalars['Int']>
};


export type GqlUserSchoolsQuery = (
  { __typename?: 'Query' }
  & { userSchools: (
    { __typename?: 'UserSchoolConnection' }
    & Pick<UserSchoolConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'UserSchool' }
      & Pick<UserSchool, 'schoolName' | 'schoolType' | 'seq'>
    )> }
  ) }
);

export type AuthLoginMutationVariables = {
  username: Scalars['String'],
  password: Scalars['String']
};


export type AuthLoginMutation = (
  { __typename?: 'Mutation' }
  & { login: Maybe<(
    { __typename?: 'LoginPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type AuthLogoutMutationVariables = {};


export type AuthLogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type AuthCheckQueryVariables = {};


export type AuthCheckQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isAdminMember'>
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'username' | 'email' | 'phoneNumber'>
  )> }
);

export type AuthGetPermissionsQueryVariables = {};


export type AuthGetPermissionsQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { isAllianceManager: User['isAllowed'], isSuperUser: User['isAllowed'], isTeacher: User['isAllowed'], canExtractDB: User['isAllowed'] }
  )> }
);

export type AdminBlogQueryVariables = {
  cursor?: Maybe<Scalars['String']>
};


export type AdminBlogQuery = (
  { __typename?: 'Query' }
  & { adminBlogPosts: (
    { __typename?: 'AdminBlogPostConnection' }
    & Pick<AdminBlogPostConnection, 'nextCursor'>
    & { nodes: Array<(
      { __typename?: 'AdminBlogPost' }
      & Pick<AdminBlogPost, 'id' | 'title' | 'url' | 'createdAt'>
    )> }
  ) }
);

export type FileUploadMutationVariables = {
  file?: Maybe<Scalars['Upload']>,
  mode?: Maybe<BucketMode>
};


export type FileUploadMutation = (
  { __typename?: 'Mutation' }
  & { fileUpload: (
    { __typename?: 'FileUploadPayload' }
    & { file: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'url'>
    )> }
  ) }
);

export type ExamSectionQueryVariables = {
  id: Scalars['ID']
};


export type ExamSectionQuery = (
  { __typename?: 'Query' }
  & { examSection: Maybe<(
    { __typename?: 'ExamSection' }
    & Pick<ExamSection, 'id'>
    & { examQuestionTypes: Maybe<Array<(
      { __typename?: 'ExamQuestionType' }
      & Pick<ExamQuestionType, 'id' | 'name'>
    )>> }
  )> }
);

export type ExamGetDetailQueryVariables = {
  examID: Scalars['ID']
};


export type ExamGetDetailQuery = (
  { __typename?: 'Query' }
  & { exam: Maybe<(
    { __typename?: 'Exam' }
    & Pick<Exam, 'id'>
    & { sections: Array<(
      { __typename?: 'ExamSection' }
      & Pick<ExamSection, 'id' | 'name'>
      & { questions: Maybe<(
        { __typename?: 'ExamQuestionConnection' }
        & Pick<ExamQuestionConnection, 'totalCount'>
        & { nodes: Array<(
          { __typename?: 'ExamQuestion' }
          & Pick<ExamQuestion, 'id' | 'number'>
        )> }
      )> }
    )> }
  )> }
);

export type ExamAttemptGetOrCreateMutationVariables = {
  userID: Scalars['ID'],
  examEventID: Scalars['ID'],
  targetCompanyID?: Maybe<Scalars['ID']>
};


export type ExamAttemptGetOrCreateMutation = (
  { __typename?: 'Mutation' }
  & { examAttemptGetOrCreate: (
    { __typename?: 'ExamAttemptGetOrCreatePayload' }
    & { examAttempt: Maybe<(
      { __typename?: 'ExamAttempt' }
      & Pick<ExamAttempt, 'id'>
    )> }
  ) }
);

export type ExamAttemptAnswerSubmitManyMutationVariables = {
  examAttemptID: Scalars['ID'],
  inputs: Array<ExamAttemptAnswerInput>
};


export type ExamAttemptAnswerSubmitManyMutation = (
  { __typename?: 'Mutation' }
  & { examAttemptAnswerSubmitMany: Maybe<(
    { __typename?: 'ExamAttemptSubmitAnswerManyPayload' }
    & { examAttemptAnswers: Array<(
      { __typename?: 'ExamAttemptAnswer' }
      & Pick<ExamAttemptAnswer, 'id'>
    )> }
  )> }
);

export type ExcelDownloadButton_ContentPurchasesQueryVariables = {
  id: Scalars['ID']
};


export type ExcelDownloadButton_ContentPurchasesQuery = (
  { __typename?: 'Query' }
  & { content: Maybe<(
    { __typename?: 'Content' }
    & { contentPurchases: Maybe<(
      { __typename?: 'ContentPurchaseConnection' }
      & { nodes: Array<(
        { __typename?: 'ContentPurchase' }
        & Pick<ContentPurchase, 'id' | 'price' | 'pointDiscount' | 'couponDiscount' | 'offlineOptionSelectedContentIDs'>
        & { user: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username' | 'name' | 'phoneNumber' | 'email'>
        )>, order: Maybe<(
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'createdAt' | 'status'>
          & { usedCouponNumber: Maybe<(
            { __typename?: 'CouponNumber' }
            & Pick<CouponNumber, 'number'>
          )>, payment: Maybe<Array<(
            { __typename?: 'Payment' }
            & Pick<Payment, 'createdAt' | 'paymentMethod' | 'amount'>
          )>> }
        )>, refund: Maybe<(
          { __typename?: 'Refund' }
          & Pick<Refund, 'id' | 'createdAt' | 'amount' | 'message'>
        )> }
      )> }
    )> }
  )> }
);

export type AddContent_OfflineChildrenContentsUpdateMutationVariables = {
  offlineID: Scalars['ID'],
  childrenOfflineIDs: Array<Scalars['ID']>
};


export type AddContent_OfflineChildrenContentsUpdateMutation = (
  { __typename?: 'Mutation' }
  & { offlineChildrenContentsUpdate: Maybe<(
    { __typename?: 'OfflineChildrenContentsUpdatePayload' }
    & { offlineChildrenContents: Maybe<Array<(
      { __typename?: 'Offline' }
      & Pick<Offline, 'id'>
    )>> }
  )> }
);

export type ContentList_BulkAdd_OfflineByIDsQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type ContentList_BulkAdd_OfflineByIDsQuery = (
  { __typename?: 'Query' }
  & { offlineByIDs: Array<(
    { __typename?: 'Offline' }
    & Pick<Offline, 'id' | 'courseStartDate' | 'courseEndDate' | 'classStartTime' | 'classEndTime'>
    & { content: (
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'subject' | 'service' | 'studentCount'>
      & { teachers: Array<(
        { __typename?: 'Teacher' }
        & Pick<Teacher, 'name'>
      )> }
    ) }
  )> }
);

export type LectureCategory_ContentCategoryListQueryVariables = {
  contentType: ContentType,
  service: ServiceType
};


export type LectureCategory_ContentCategoryListQuery = (
  { __typename?: 'Query' }
  & { contentCategoryList: Array<(
    { __typename?: 'ContentCategoryParent' }
    & Pick<ContentCategoryParent, 'id' | 'name'>
    & { childrenCategories: Array<(
      { __typename?: 'ContentCategory' }
      & Pick<ContentCategory, 'id' | 'name'>
    )> }
  )> }
);

export type RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutationVariables = {
  offlineID: Scalars['ID'],
  inputs?: Maybe<Array<OfflineRepurchaseDiscountInput>>
};


export type RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation = (
  { __typename?: 'Mutation' }
  & { offlineRepurchaseDiscountUpdate: Maybe<(
    { __typename?: 'OfflineRepurchaseDiscountUpdatePayload' }
    & { offlineRepurchaseDiscounts: Maybe<Array<(
      { __typename?: 'OfflineRepurchaseDiscount' }
      & Pick<OfflineRepurchaseDiscount, 'discountValue' | 'discountType'>
      & { targetOffline: (
        { __typename?: 'Offline' }
        & Pick<Offline, 'id' | 'courseStartDate' | 'courseEndDate'>
        & { content: (
          { __typename?: 'Content' }
          & Pick<Content, 'id' | 'subject'>
          & { teachers: Array<(
            { __typename?: 'Teacher' }
            & Pick<Teacher, 'name'>
          )> }
        ) }
      ) }
    )>> }
  )> }
);

export type ImportButton_OfflineByContentIdQueryVariables = {
  contentID: Scalars['ID']
};


export type ImportButton_OfflineByContentIdQuery = (
  { __typename?: 'Query' }
  & { offlineByContentID: Maybe<(
    { __typename?: 'Offline' }
    & Pick<Offline, 'id' | 'courseStartDate' | 'courseEndDate'>
    & { offlineRepurchaseDiscounts: Array<(
      { __typename?: 'OfflineRepurchaseDiscount' }
      & Pick<OfflineRepurchaseDiscount, 'discountValue' | 'discountType'>
      & { targetOffline: (
        { __typename?: 'Offline' }
        & Pick<Offline, 'id'>
        & { content: (
          { __typename?: 'Content' }
          & Pick<Content, 'id' | 'subject'>
          & { teachers: Array<(
            { __typename?: 'Teacher' }
            & Pick<Teacher, 'name'>
          )> }
        ) }
      ) }
    )> }
  )> }
);

export type BulkAddListButton_OfflineByIDsQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type BulkAddListButton_OfflineByIDsQuery = (
  { __typename?: 'Query' }
  & { offlineByIDs: Array<(
    { __typename?: 'Offline' }
    & Pick<Offline, 'id' | 'courseStartDate' | 'courseEndDate'>
    & { content: (
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'subject'>
      & { teachers: Array<(
        { __typename?: 'Teacher' }
        & Pick<Teacher, 'name'>
      )> }
    ) }
  )> }
);

export type BulkImportButton_OfflinesByContentIDsQueryVariables = {
  contentIDs: Array<Scalars['ID']>
};


export type BulkImportButton_OfflinesByContentIDsQuery = (
  { __typename?: 'Query' }
  & { offlinesByContentIDs: Array<(
    { __typename?: 'Offline' }
    & Pick<Offline, 'id' | 'courseStartDate' | 'courseEndDate'>
    & { content: (
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'subject'>
      & { teachers: Array<(
        { __typename?: 'Teacher' }
        & Pick<Teacher, 'name'>
      )> }
    ), offlineRepurchaseDiscounts: Array<(
      { __typename?: 'OfflineRepurchaseDiscount' }
      & { targetOffline: (
        { __typename?: 'Offline' }
        & Pick<Offline, 'id'>
      ) }
    )> }
  )> }
);

export type ClassChangeForm_OfflineStudentClassChangeMutationVariables = {
  userContentID: Scalars['ID'],
  contentID: Scalars['ID']
};


export type ClassChangeForm_OfflineStudentClassChangeMutation = (
  { __typename?: 'Mutation' }
  & { offlineStudentClassChange: (
    { __typename?: 'OfflineStudentClassChangePayload' }
    & { contentPurchase: Maybe<(
      { __typename?: 'ContentPurchase' }
      & Pick<ContentPurchase, 'id'>
      & { content: (
        { __typename?: 'Content' }
        & Pick<Content, 'id' | 'subject'>
      ) }
    )> }
  ) }
);

export type BenefitsDialog_ProvideFreeContentMutationVariables = {
  userID: Scalars['ID'],
  contentID: Scalars['ID']
};


export type BenefitsDialog_ProvideFreeContentMutation = (
  { __typename?: 'Mutation' }
  & { provideFreeContent: (
    { __typename?: 'ProvideFreeContentPayload' }
    & { contentPurchase: Maybe<(
      { __typename?: 'ContentPurchase' }
      & Pick<ContentPurchase, 'id'>
      & { content: (
        { __typename?: 'Content' }
        & Pick<Content, 'id'>
      ) }
    )> }
  ) }
);

export type UserStudyEventExportQueryVariables = {
  filterBy?: Maybe<UserStudyEventFilters>,
  orderBy?: Maybe<UserStudyEventOrder>,
  pagination?: Maybe<Pagination>
};


export type UserStudyEventExportQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'UserStudyEventConnection' }
    & { data: Array<(
      { __typename?: 'UserStudyEvent' }
      & Pick<UserStudyEvent, 'createdAt' | 'state'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'username' | 'phoneNumber' | 'createdAt'>
      ) }
    )> }
  )> }
);

export type UserStudyEventShow_UserStudyMissionUpdateMutationVariables = {
  id: Scalars['ID'],
  input: UserStudyMissionUpdateInput
};


export type UserStudyEventShow_UserStudyMissionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { userStudyMissionUpdate: Maybe<(
    { __typename?: 'UserStudyMissionUpdatePayload' }
    & { userStudyMission: Maybe<(
      { __typename?: 'UserStudyMission' }
      & Pick<UserStudyMission, 'id' | 'state'>
    )> }
  )> }
);

export type UserStudyEventListUserStudyEventsForcePassMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type UserStudyEventListUserStudyEventsForcePassMutation = (
  { __typename?: 'Mutation' }
  & { userStudyEventsForcePass: Maybe<(
    { __typename?: 'UserStudyEventForcePassPayload' }
    & Pick<UserStudyEventForcePassPayload, 'forcePassCount'>
    & { userStudyEvents: Maybe<Array<(
      { __typename?: 'UserStudyEvent' }
      & Pick<UserStudyEvent, 'id' | 'state'>
    )>> }
  )> }
);

export type UserStudyEventListUserStudyEventsPassMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type UserStudyEventListUserStudyEventsPassMutation = (
  { __typename?: 'Mutation' }
  & { userStudyEventsPass: Maybe<(
    { __typename?: 'UserStudyEventPassPayload' }
    & { userStudyEvents: Maybe<Array<(
      { __typename?: 'UserStudyEvent' }
      & Pick<UserStudyEvent, 'id' | 'state'>
    )>> }
  )> }
);

export type UserStudyEventListUserStudyEventsFailMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type UserStudyEventListUserStudyEventsFailMutation = (
  { __typename?: 'Mutation' }
  & { userStudyEventsFail: Maybe<(
    { __typename?: 'UserStudyEventFailPayload' }
    & { userStudyEvents: Maybe<Array<(
      { __typename?: 'UserStudyEvent' }
      & Pick<UserStudyEvent, 'id' | 'state'>
    )>> }
  )> }
);

export type BannerGroupFragmentFragment = (
  { __typename?: 'BannerGroup' }
  & Pick<BannerGroup, 'id' | 'codeName' | 'key' | 'service' | 'description' | 'bannerCapacity' | 'advisedMainImageSize' | 'advisedBackgroundImageSize' | 'advisedNavigationImageSize'>
  & { activeBanners: Array<(
    { __typename?: 'Banner' }
    & Pick<Banner, 'id'>
  )> }
);

export type BannerGroupGetListQueryVariables = {
  filterBy?: Maybe<BannerGroupFilters>,
  orderBy?: Maybe<BannerGroupOrder>,
  pagination?: Maybe<Pagination>
};


export type BannerGroupGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'BannerGroupConnection' }
    & { total: BannerGroupConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'BannerGroup' }
      & BannerGroupFragmentFragment
    )> }
  ) }
);

export type BannerGroupGetQueryVariables = {
  id: Scalars['ID']
};


export type BannerGroupGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'BannerGroup' }
    & BannerGroupFragmentFragment
  )> }
);

export type BannerGroupGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type BannerGroupGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'BannerGroup' }
    & BannerGroupFragmentFragment
  )> }
);

export type BannerGroupCreateMutationVariables = {
  input: BannerGroupCreateInput
};


export type BannerGroupCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'BannerGroupCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'BannerGroup' }
      & BannerGroupFragmentFragment
    )> }
  )> }
);

export type BannerGroupUpdateMutationVariables = {
  id: Scalars['ID'],
  input: BannerGroupUpdateInput
};


export type BannerGroupUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'BannerGroupUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'BannerGroup' }
      & BannerGroupFragmentFragment
    )> }
  )> }
);

export type BannerGroupDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type BannerGroupDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'BannerGroupDeleteManyPayload' }
    & { data: Array<(
      { __typename?: 'BannerGroup' }
      & BannerGroupFragmentFragment
    )> }
  )> }
);

export type BannerFragmentFragment = (
  { __typename?: 'Banner' }
  & Pick<Banner, 'id' | 'title' | 'slideLabel' | 'url' | 'backgroundColor' | 'click' | 'status' | 'priority' | 'createdAt' | 'updatedAt'>
  & { mainImage: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  ), backgroundImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, activateNavigationImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, deactivateNavigationImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, bannerGroup: (
    { __typename?: 'BannerGroup' }
    & Pick<BannerGroup, 'id'>
  ) }
);

export type BannerGetListQueryVariables = {
  filterBy?: Maybe<BannerFilters>,
  orderBy?: Maybe<BannerOrder>,
  pagination?: Maybe<Pagination>
};


export type BannerGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'BannerConnection' }
    & { total: BannerConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Banner' }
      & BannerFragmentFragment
    )> }
  ) }
);

export type BannerGetQueryVariables = {
  id: Scalars['ID']
};


export type BannerGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Banner' }
    & BannerFragmentFragment
  )> }
);

export type BannerGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type BannerGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'Banner' }
    & BannerFragmentFragment
  )> }
);

export type BannerCreateMutationVariables = {
  input: BannerInput
};


export type BannerCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'BannerCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Banner' }
      & BannerFragmentFragment
    )> }
  )> }
);

export type BannerUpdateMutationVariables = {
  id: Scalars['ID'],
  input: BannerInput
};


export type BannerUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'BannerUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Banner' }
      & BannerFragmentFragment
    )> }
  )> }
);

export type BookFragmentFragment = (
  { __typename?: 'Book' }
  & Pick<Book, 'id' | 'name' | 'authorName' | 'year' | 'description' | 'studyLink' | 'link' | 'examDescription' | 'createdAt'>
  & { content: Maybe<(
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'price' | 'subject' | 'tag' | 'visibility'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'url'>
    )> }
  )>, expectedQuestionSet: Maybe<(
    { __typename?: 'Exam' }
    & Pick<Exam, 'id'>
  )>, mockExams: Array<(
    { __typename?: 'Exam' }
    & Pick<Exam, 'id'>
  )>, mockExamTargetCompanies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  )> }
);

export type BookGetListQueryVariables = {
  filterBy?: Maybe<BookFilters>,
  orderBy?: Maybe<BookOrder>,
  pagination?: Maybe<Pagination>
};


export type BookGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'BookConnection' }
    & { total: BookConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Book' }
      & BookFragmentFragment
    )> }
  ) }
);

export type BookGetQueryVariables = {
  id: Scalars['ID']
};


export type BookGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Book' }
    & BookFragmentFragment
  )> }
);

export type BookGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type BookGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'Book' }
    & BookFragmentFragment
  )> }
);

export type BookUpdateMutationVariables = {
  id: Scalars['ID'],
  input: BookUpdateInput
};


export type BookUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'BookUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Book' }
      & BookFragmentFragment
    )> }
  )> }
);

export type BookDeleteMutationVariables = {
  id: Scalars['ID']
};


export type BookDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'BookDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Book' }
      & BookFragmentFragment
    )> }
  )> }
);

export type BookDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type BookDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'BookDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'Book' }
      & BookFragmentFragment
    )>> }
  )> }
);

export type CampaignFragmentFragment = (
  { __typename?: 'Campaign' }
  & Pick<Campaign, 'id' | 'createdAt' | 'startAt' | 'endAt' | 'name' | 'noticeTitle'>
);

export type CampaignDetailFragmentFragment = (
  { __typename?: 'Campaign' }
  & Pick<Campaign, 'hasUsers'>
  & { data: Maybe<(
    { __typename?: 'CampaignData' }
    & Pick<CampaignData, 'category' | 'name' | 'industry' | 'etc'>
  )> }
  & CampaignFragmentFragment
);

export type CampaignGetListQueryVariables = {
  filterBy?: Maybe<CampaignFilters>,
  orderBy?: Maybe<CampaignOrder>,
  pagination?: Maybe<Pagination>
};


export type CampaignGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'CampaignConnection' }
    & { total: CampaignConnection['totalCount'] }
    & { data: Array<Maybe<(
      { __typename?: 'Campaign' }
      & CampaignFragmentFragment
    )>> }
  )> }
);

export type CampaignGetQueryVariables = {
  id: Scalars['ID']
};


export type CampaignGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Campaign' }
    & CampaignDetailFragmentFragment
  )> }
);

export type CampaignCreateMutationVariables = {
  input: CampaignInput
};


export type CampaignCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CampaignCreateOrUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Campaign' }
      & CampaignFragmentFragment
    )> }
  )> }
);

export type CampaignUpdateMutationVariables = {
  id: Scalars['ID'],
  input: CampaignInput
};


export type CampaignUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CampaignCreateOrUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Campaign' }
      & CampaignFragmentFragment
    )> }
  )> }
);

export type CampaignUserCreateManyMutationVariables = {
  campaignID: Scalars['ID'],
  inputs: Array<CampaignUserCreateInput>
};


export type CampaignUserCreateManyMutation = (
  { __typename?: 'Mutation' }
  & { campaignUserCreateMany: Maybe<(
    { __typename?: 'CampaignUserMutationPayload' }
    & Pick<CampaignUserMutationPayload, 'status'>
  )> }
);

export type CampaignUsersGetQueryVariables = {
  campaignID: Scalars['ID']
};


export type CampaignUsersGetQuery = (
  { __typename?: 'Query' }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id'>
    & { campaignUsers: Array<Maybe<(
      { __typename?: 'CampaignUser' }
      & Pick<CampaignUser, 'joinAt' | 'name' | 'allowMarketing' | 'phoneNumber' | 'email'>
      & { user: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'createdAt' | 'service' | 'examToTake'>
      )> }
    )>> }
  )> }
);

export type CampaignUserDeleteManyMutationVariables = {
  campaignID: Scalars['ID']
};


export type CampaignUserDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { campaignUserDeleteMany: Maybe<(
    { __typename?: 'CampaignUserMutationPayload' }
    & Pick<CampaignUserMutationPayload, 'status'>
  )> }
);

export type CompanyFragmentFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'createdAt'>
);

export type CompanyGetListQueryVariables = {
  filterBy?: Maybe<CompanyFilters>,
  orderBy?: Maybe<CompanyOrder>,
  pagination?: Maybe<Pagination>
};


export type CompanyGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'CompanyConnection' }
    & { total: CompanyConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Company' }
      & CompanyFragmentFragment
    )> }
  )> }
);

export type CompanyGetQueryVariables = {
  id: Scalars['ID']
};


export type CompanyGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Company' }
    & CompanyFragmentFragment
  )> }
);

export type CompanyGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type CompanyGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'Company' }
    & CompanyFragmentFragment
  )>> }
);

export type CompanyCreateMutationVariables = {
  input: CompanyInput
};


export type CompanyCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CompanyCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Company' }
      & CompanyFragmentFragment
    )> }
  )> }
);

export type CompanyUpdateMutationVariables = {
  id: Scalars['ID'],
  input: CompanyInput
};


export type CompanyUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CompanyUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Company' }
      & CompanyFragmentFragment
    )> }
  )> }
);

export type CompanyDeleteMutationVariables = {
  id: Scalars['ID']
};


export type CompanyDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CompanyDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Company' }
      & CompanyFragmentFragment
    )> }
  )> }
);

export type CompanyDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type CompanyDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CompanyDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'Company' }
      & CompanyFragmentFragment
    )>> }
  )> }
);

export type ContentCurationFragmentFragment = (
  { __typename?: 'ContentCuration' }
  & Pick<ContentCuration, 'id' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  & { contents: Array<(
    { __typename?: 'Content' }
    & Pick<Content, 'id'>
  )> }
);

export type ContentCurationGetQueryVariables = {
  id: Scalars['ID']
};


export type ContentCurationGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ContentCuration' }
    & ContentCurationFragmentFragment
  )> }
);

export type ContentCurationGetListQueryVariables = {
  filterBy?: Maybe<ContentCurationFilters>,
  orderBy?: Maybe<ContentCurationOrder>,
  pagination?: Maybe<Pagination>
};


export type ContentCurationGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ContentCurationConnection' }
    & { total: ContentCurationConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'ContentCuration' }
      & ContentCurationFragmentFragment
    )> }
  ) }
);

export type ContentCurationCreateMutationVariables = {
  input: ContentCurationInput
};


export type ContentCurationCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ContentCurationCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'ContentCuration' }
      & ContentCurationFragmentFragment
    )> }
  )> }
);

export type ContentCurationUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ContentCurationInput
};


export type ContentCurationUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ContentCurationUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'ContentCuration' }
      & ContentCurationFragmentFragment
    )> }
  )> }
);

export type ContentCurationDeleteMutationVariables = {
  id: Scalars['ID']
};


export type ContentCurationDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ContentCurationDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'ContentCuration' }
      & ContentCurationFragmentFragment
    )> }
  )> }
);

export type ContentCurationDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type ContentCurationDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ContentCurationDeleteManyPayload' }
    & { data: Array<(
      { __typename?: 'ContentCuration' }
      & ContentCurationFragmentFragment
    )> }
  )> }
);

export type ContentPurchaseFragmentFragment = (
  { __typename?: 'ContentPurchase' }
  & Pick<ContentPurchase, 'id' | 'price' | 'createdAt' | 'expiredAt' | 'pointDiscount' | 'couponDiscount'>
  & { user: Maybe<(
    { __typename?: 'User' }
    & ContentPurchaseUserFragment
  )>, content: (
    { __typename?: 'Content' }
    & ContentPurchaseContentFragment
  ), order: Maybe<(
    { __typename?: 'Order' }
    & ContentPurchaseOrderFragment
  )> }
);

export type ContentPurchaseUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'username' | 'phoneNumber' | 'email' | 'smsReceipt' | 'emailReceipt'>
);

export type ContentPurchaseContentFragment = (
  { __typename?: 'Content' }
  & Pick<Content, 'id' | 'subject' | 'active' | 'keywords'>
);

export type ContentPurchaseOrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'status' | 'createdAt'>
  & { payment: Maybe<Array<(
    { __typename?: 'Payment' }
    & ContentPurchasePaymentFragment
  )>> }
);

export type ContentPurchasePaymentFragment = (
  { __typename?: 'Payment' }
  & Pick<Payment, 'id' | 'amount' | 'paymentMethod' | 'transactionID' | 'paidAt'>
);

export type ContentPurchaseGetQueryVariables = {
  id: Scalars['ID']
};


export type ContentPurchaseGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ContentPurchase' }
    & ContentPurchaseFragmentFragment
  )> }
);

export type ContentPurchaseListQueryVariables = {
  filterBy?: Maybe<ContentPurchaseFilters>,
  orderBy?: Maybe<ContentPurchaseOrder>,
  pagination?: Maybe<Pagination>
};


export type ContentPurchaseListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ContentPurchaseConnection' }
    & { total: ContentPurchaseConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'ContentPurchase' }
      & ContentPurchaseFragmentFragment
    )> }
  ) }
);

export type ContentFragmentFragment = (
  { __typename?: 'Content' }
  & Pick<Content, 'id' | 'subject' | 'type' | 'service' | 'salesPeriod' | 'active' | 'priority' | 'keywords' | 'visibility' | 'includesInvoice'>
);

export type ContentGetListQueryVariables = {
  filterBy?: Maybe<ContentFilters>,
  orderBy?: Maybe<ContentOrder>,
  pagination?: Maybe<Pagination>
};


export type ContentGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ContentConnection' }
    & { total: ContentConnection['totalCount'] }
    & { data: Maybe<Array<(
      { __typename?: 'Content' }
      & ContentFragmentFragment
    )>> }
  )> }
);

export type ContentGetQueryVariables = {
  id: Scalars['ID']
};


export type ContentGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Content' }
    & ContentFragmentFragment
  )> }
);

export type ContentGetWithValidateQueryVariables = {
  id: Scalars['ID'],
  contentType?: Maybe<ContentType>
};


export type ContentGetWithValidateQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Content' }
    & ContentFragmentFragment
  )> }
);

export type ContentGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type ContentGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'Content' }
    & ContentFragmentFragment
  )> }
);

export type ContentCreateMutationVariables = {
  input: ContentCreateInput
};


export type ContentCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ContentCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Content' }
      & ContentFragmentFragment
    )> }
  )> }
);

export type ContentUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ContentUpdateInput
};


export type ContentUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ContentUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Content' }
      & ContentFragmentFragment
    )> }
  )> }
);

export type ContentDeleteMutationVariables = {
  id: Scalars['ID']
};


export type ContentDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ContentDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Content' }
      & ContentFragmentFragment
    )> }
  )> }
);

export type ContentDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type ContentDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ContentDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'Content' }
      & ContentFragmentFragment
    )>> }
  )> }
);

export type CouponNumberFragmentFragment = (
  { __typename?: 'CouponNumber' }
  & Pick<CouponNumber, 'id' | 'number'>
);

export type BenefitDialog_CouponNumberGetQueryVariables = {
  number: Scalars['String']
};


export type BenefitDialog_CouponNumberGetQuery = (
  { __typename?: 'Query' }
  & { couponNumber: Maybe<(
    { __typename?: 'CouponNumber' }
    & CouponNumberFragmentFragment
  )> }
);

export type CouponFragmentFragment = (
  { __typename?: 'Coupon' }
  & Pick<Coupon, 'id' | 'name' | 'type'>
);

export type CouponDetailFragmentFragment = (
  { __typename?: 'Coupon' }
  & { couponNumbers: Array<(
    { __typename?: 'CouponNumber' }
    & Pick<CouponNumber, 'id' | 'number'>
    & { connectedContents: Array<Maybe<(
      { __typename?: 'ConnectedContent' }
      & Pick<ConnectedContent, 'id'>
      & { content: (
        { __typename?: 'Content' }
        & Pick<Content, 'id' | 'subject' | 'type'>
      ) }
    )>> }
  )> }
  & CouponFragmentFragment
);

export type CouponGetListQueryVariables = {
  filterBy?: Maybe<CouponFilters>,
  orderBy?: Maybe<CouponOrder>,
  pagination?: Maybe<Pagination>
};


export type CouponGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'CouponConnection' }
    & { total: CouponConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'Coupon' }
      & CouponFragmentFragment
    )>>> }
  ) }
);

export type CouponGetQueryVariables = {
  id: Scalars['ID']
};


export type CouponGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Coupon' }
    & CouponDetailFragmentFragment
  )> }
);

export type CouponUpdateMutationVariables = {
  id: Scalars['ID'],
  input: CouponInput
};


export type CouponUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CouponUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Coupon' }
      & CouponFragmentFragment
    )> }
  )> }
);

export type CouponDeleteMutationVariables = {
  id: Scalars['ID']
};


export type CouponDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CouponDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Coupon' }
      & CouponFragmentFragment
    )> }
  )> }
);

export type ConnectedContentDeleteMutationVariables = {
  couponContentMapID: Scalars['ID']
};


export type ConnectedContentDeleteMutation = (
  { __typename?: 'Mutation' }
  & { couponConnectedContentDelete: Maybe<(
    { __typename?: 'ConnectedContentDeletePayload' }
    & Pick<ConnectedContentDeletePayload, 'status'>
  )> }
);

export type ConnectedContentCreateMutationVariables = {
  couponNumber: Scalars['String'],
  contentID: Scalars['ID']
};


export type ConnectedContentCreateMutation = (
  { __typename?: 'Mutation' }
  & { couponConnectedContentCreate: Maybe<(
    { __typename?: 'CreateConnectedContentPayload' }
    & Pick<CreateConnectedContentPayload, 'status'>
  )> }
);

export type EventCampaignDetailFragmentFragment = (
  { __typename?: 'Campaign' }
  & Pick<Campaign, 'pcLink' | 'mobileLink' | 'communityPostURL' | 'possibleDuplicated' | 'service' | 'target' | 'isForBraze'>
  & { data: Maybe<(
    { __typename?: 'CampaignData' }
    & Pick<CampaignData, 'category' | 'name' | 'industry' | 'etc'>
  )>, campaignBenefits: Array<Maybe<(
    { __typename?: 'CampaignBenefit' }
    & Pick<CampaignBenefit, 'id' | 'benefitType' | 'benefit' | 'name' | 'isActive'>
  )>> }
  & CampaignFragmentFragment
);

export type EventCampaignGetQueryVariables = {
  id: Scalars['ID']
};


export type EventCampaignGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Campaign' }
    & EventCampaignDetailFragmentFragment
  )> }
);

export type CampaignBenefitCreateMutationVariables = {
  input: CampaignBenefitInput
};


export type CampaignBenefitCreateMutation = (
  { __typename?: 'Mutation' }
  & { campaignBenefitCreate: Maybe<(
    { __typename?: 'CampaignBenefitMutationPayload' }
    & Pick<CampaignBenefitMutationPayload, 'status'>
  )> }
);

export type CampaignBenefitUpdateMutationVariables = {
  benefitID: Scalars['ID'],
  input: CampaignBenefitInput
};


export type CampaignBenefitUpdateMutation = (
  { __typename?: 'Mutation' }
  & { campaignBenefitUpdate: Maybe<(
    { __typename?: 'CampaignBenefitMutationPayload' }
    & Pick<CampaignBenefitMutationPayload, 'status'>
  )> }
);

export type CampaignBenefitDeleteMutationVariables = {
  benefitID: Scalars['ID']
};


export type CampaignBenefitDeleteMutation = (
  { __typename?: 'Mutation' }
  & { campaignBenefitDelete: Maybe<(
    { __typename?: 'CampaignBenefitMutationPayload' }
    & Pick<CampaignBenefitMutationPayload, 'status'>
  )> }
);

export type ExamAttemptFragmentFragment = (
  { __typename?: 'ExamAttempt' }
  & Pick<ExamAttempt, 'id' | 'score' | 'createdAt'>
  & { event: (
    { __typename?: 'ExamEvent' }
    & { exam: Maybe<(
      { __typename?: 'Exam' }
      & Pick<Exam, 'id'>
    )> }
  ), user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username' | 'createdAt'>
  )>, tempAccount: Maybe<(
    { __typename?: 'TempAccount' }
    & Pick<TempAccount, 'name' | 'phoneNumber'>
  )>, targetCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  )>, resultsBySection: Array<(
    { __typename?: 'ExamAttemptSectionResult' }
    & Pick<ExamAttemptSectionResult, 'score'>
    & { section: (
      { __typename?: 'ExamSection' }
      & Pick<ExamSection, 'id' | 'name'>
    ) }
  )> }
);

export type ExamAttemptGetListQueryVariables = {
  filterBy?: Maybe<ExamAttemptFilters>,
  orderBy?: Maybe<ExamAttemptOrder>,
  pagination?: Maybe<Pagination>
};


export type ExamAttemptGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ExamAttemptConnection' }
    & { total: ExamAttemptConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'ExamAttempt' }
      & ExamAttemptFragmentFragment
    )>>> }
  )> }
);

export type ExamAttemptGetQueryVariables = {
  id: Scalars['ID']
};


export type ExamAttemptGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ExamAttempt' }
    & ExamAttemptFragmentFragment
  )> }
);

export type ExamQuestionTypeFragmentFragment = (
  { __typename?: 'ExamQuestionType' }
  & Pick<ExamQuestionType, 'id' | 'name'>
);

export type ExamQuestionTypeGetListQueryVariables = {
  filterBy?: Maybe<ExamQuestionTypeFilters>,
  orderBy?: Maybe<ExamQuestionTypeOrder>,
  pagination?: Maybe<Pagination>
};


export type ExamQuestionTypeGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ExamQuestionTypeConnection' }
    & { total: ExamQuestionTypeConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'ExamQuestionType' }
      & ExamQuestionTypeFragmentFragment
    )> }
  ) }
);

export type ExamQuestionTypeGetQueryVariables = {
  id: Scalars['ID']
};


export type ExamQuestionTypeGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ExamQuestionType' }
    & ExamQuestionTypeFragmentFragment
  )> }
);

export type ExamQuestionTypeGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type ExamQuestionTypeGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'ExamQuestionType' }
    & ExamQuestionTypeFragmentFragment
  )>> }
);

export type ExamQuestionTypeUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ExamQuestionTypeInput
};


export type ExamQuestionTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'ExamQuestionTypeUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'ExamQuestionType' }
      & ExamQuestionTypeFragmentFragment
    )> }
  ) }
);

export type ExamQuestionTypeDeleteMutationVariables = {
  id: Scalars['ID']
};


export type ExamQuestionTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'ExamQuestionTypeDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'ExamQuestionType' }
      & ExamQuestionTypeFragmentFragment
    )> }
  ) }
);

export type ExamQuestionFragmentFragment = (
  { __typename?: 'ExamQuestion' }
  & Pick<ExamQuestion, 'id' | 'number'>
  & { type: Maybe<(
    { __typename?: 'ExamQuestionType' }
    & Pick<ExamQuestionType, 'id' | 'name'>
  )>, question: (
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'questionField1' | 'questionField2' | 'explanation' | 'answer'>
  ) }
);

export type ExamQuestionGetListQueryVariables = {
  filterBy?: Maybe<ExamQuestionFilters>,
  orderBy?: Maybe<ExamQuestionOrder>,
  pagination?: Maybe<Pagination>
};


export type ExamQuestionGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ExamQuestionConnection' }
    & { total: ExamQuestionConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'ExamQuestion' }
      & ExamQuestionFragmentFragment
    )> }
  ) }
);

export type ExamQuestionGetQueryVariables = {
  id: Scalars['ID']
};


export type ExamQuestionGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ExamQuestion' }
    & ExamQuestionFragmentFragment
  )> }
);

export type ExamQuestionCreateMutationVariables = {
  input: ExamQuestionCreateInput
};


export type ExamQuestionCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'ExamQuestionCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'ExamQuestion' }
      & ExamQuestionFragmentFragment
    )> }
  ) }
);

export type ExamQuestionUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ExamQuestionUpdateInput
};


export type ExamQuestionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'ExamQuestionUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'ExamQuestion' }
      & ExamQuestionFragmentFragment
    )> }
  ) }
);

export type ExamQuestionDeleteMutationVariables = {
  id: Scalars['ID']
};


export type ExamQuestionDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'ExamQuestionDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'ExamQuestion' }
      & ExamQuestionFragmentFragment
    )> }
  ) }
);

export type ExamQuestionDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type ExamQuestionDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'ExamQuestionDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'ExamQuestion' }
      & ExamQuestionFragmentFragment
    )>> }
  ) }
);

export type ExamSectionFragmentFragment = (
  { __typename?: 'ExamSection' }
  & Pick<ExamSection, 'id' | 'name' | 'averageScore'>
  & { prevScoresForPass: Maybe<Array<(
    { __typename?: 'PrevScoreForPass' }
    & Pick<PrevScoreForPass, 'seasonName' | 'score'>
  )>>, questions: Maybe<(
    { __typename?: 'ExamQuestionConnection' }
    & Pick<ExamQuestionConnection, 'totalCount'>
  )>, examQuestionTypes: Maybe<Array<(
    { __typename?: 'ExamQuestionType' }
    & Pick<ExamQuestionType, 'id' | 'name'>
  )>> }
);

export type ExamSectionGetListQueryVariables = {
  filterBy?: Maybe<ExamSectionFilters>,
  orderBy?: Maybe<ExamSectionOrder>,
  pagination?: Maybe<Pagination>
};


export type ExamSectionGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ExamSectionConnection' }
    & { total: ExamSectionConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'ExamSection' }
      & ExamSectionFragmentFragment
    )> }
  ) }
);

export type ExamSectionGetQueryVariables = {
  id: Scalars['ID']
};


export type ExamSectionGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ExamSection' }
    & ExamSectionFragmentFragment
  )> }
);

export type ExamSectionUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ExamSectionInput
};


export type ExamSectionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'ExamSectionUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'ExamSection' }
      & ExamSectionFragmentFragment
    )> }
  ) }
);

export type ExamFragmentFragment = (
  { __typename?: 'Exam' }
  & Pick<Exam, 'id' | 'name' | 'createdAt' | 'type'>
  & { content: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'price' | 'expireDays'>
  ), attempts: Maybe<(
    { __typename?: 'ExamAttemptConnection' }
    & Pick<ExamAttemptConnection, 'totalCount'>
  )>, defaultEvent: Maybe<(
    { __typename?: 'ExamEvent' }
    & Pick<ExamEvent, 'id'>
  )>, events: Array<(
    { __typename?: 'ExamEvent' }
    & Pick<ExamEvent, 'id' | 'createdAt'>
    & { exam: Maybe<(
      { __typename?: 'Exam' }
      & Pick<Exam, 'name'>
    )> }
  )> }
);

export type ExamDetailFragmentFragment = (
  { __typename?: 'Exam' }
  & Pick<Exam, 'reportIsReady'>
  & { examQuestions: Maybe<(
    { __typename?: 'ExamQuestionConnection' }
    & Pick<ExamQuestionConnection, 'totalCount'>
  )>, sections: Array<(
    { __typename?: 'ExamSection' }
    & Pick<ExamSection, 'id' | 'name'>
    & { prevScoresForPass: Maybe<Array<(
      { __typename?: 'PrevScoreForPass' }
      & Pick<PrevScoreForPass, 'seasonName' | 'score'>
    )>>, examQuestionTypes: Maybe<Array<(
      { __typename?: 'ExamQuestionType' }
      & Pick<ExamQuestionType, 'id' | 'name'>
    )>> }
  )> }
  & ExamFragmentFragment
);

export type ExamGetListQueryVariables = {
  filterBy?: Maybe<ExamFilters>,
  orderBy?: Maybe<ExamOrder>,
  pagination?: Maybe<Pagination>
};


export type ExamGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ExamConnection' }
    & { total: ExamConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'Exam' }
      & ExamFragmentFragment
    )>>> }
  ) }
);

export type ExamGetQueryVariables = {
  id: Scalars['ID']
};


export type ExamGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Exam' }
    & { sections: Array<(
      { __typename?: 'ExamSection' }
      & { questions: Maybe<(
        { __typename?: 'ExamQuestionConnection' }
        & { nodes: Array<(
          { __typename?: 'ExamQuestion' }
          & Pick<ExamQuestion, 'id' | 'number'>
          & { type: Maybe<(
            { __typename?: 'ExamQuestionType' }
            & Pick<ExamQuestionType, 'id' | 'name'>
          )>, question: (
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'questionField1' | 'questionField2' | 'explanation' | 'answer'>
          ) }
        )> }
      )> }
    )> }
    & ExamDetailFragmentFragment
  )> }
);

export type ExamGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type ExamGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'Exam' }
    & ExamFragmentFragment
  )>> }
);

export type ExamUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ExamInput
};


export type ExamUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'ExamUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Exam' }
      & ExamDetailFragmentFragment
    )> }
  ) }
);

export type InvoiceFragmentFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'freepassNetSalesAmount' | 'contentGrossSalesAmount' | 'contentPGCommissionAmount' | 'contentNetSalesAmount' | 'refundPriceAmount' | 'refundPGCommissionAmount' | 'netRefundsAmount' | 'paidAmount' | 'incomeTax' | 'localTax' | 'totalTax' | 'totalNetAmount' | 'createdAt' | 'updatedAt'>
  & { teacher: (
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id' | 'name'>
  ) }
);

export type InvoiceDetailFragmentFragment = (
  { __typename?: 'Invoice' }
  & { freepassInvoice: Maybe<(
    { __typename?: 'FreepassInvoice' }
    & Pick<FreepassInvoice, 'playtime' | 'netSales' | 'service' | 'subject'>
  )>, salesByContent: Array<(
    { __typename?: 'InvoiceSaleByContent' }
    & Pick<InvoiceSaleByContent, 'quantity' | 'teacherContractRate' | 'teacherContentParticipationRate' | 'pgCommission' | 'grossSales' | 'netSales'>
    & { content: (
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'service' | 'subject' | 'price'>
    ) }
  )>, refundsByContent: Array<(
    { __typename?: 'InvoiceRefundByContent' }
    & Pick<InvoiceRefundByContent, 'refundPrice' | 'netRefund' | 'quantity' | 'pgCommission' | 'teacherContractRate' | 'teacherContentParticipationRate'>
    & { content: (
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'service' | 'subject' | 'price'>
    ) }
  )> }
  & InvoiceFragmentFragment
);

export type InvoiceGetListQueryVariables = {
  filterBy?: Maybe<InvoiceFilters>,
  orderBy?: Maybe<InvoiceOrder>,
  pagination?: Maybe<Pagination>
};


export type InvoiceGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'InvoiceConnection' }
    & { total: InvoiceConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    )>>> }
  ) }
);

export type InvoiceGetQueryVariables = {
  id: Scalars['ID']
};


export type InvoiceGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceDetailFragmentFragment
  )> }
);

export type MembershipFragmentFragment = (
  { __typename?: 'Membership' }
  & Pick<Membership, 'id' | 'role' | 'createdAt'>
  & { team: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )>, user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type MembershipGetListQueryVariables = {
  filterBy?: Maybe<MembershipFilters>,
  orderBy?: Maybe<MembershipOrder>,
  pagination?: Maybe<Pagination>
};


export type MembershipGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'MembershipConnection' }
    & { total: MembershipConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Membership' }
      & MembershipFragmentFragment
    )> }
  )> }
);

export type MembershipGetQueryVariables = {
  id: Scalars['ID']
};


export type MembershipGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Membership' }
    & MembershipFragmentFragment
  )> }
);

export type MembershipCreateMutationVariables = {
  input: MembershipInput
};


export type MembershipCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'MembershipCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Membership' }
      & MembershipFragmentFragment
    )> }
  )> }
);

export type MembershipUpdateMutationVariables = {
  id: Scalars['ID'],
  input: MembershipInput
};


export type MembershipUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'MembershipUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Membership' }
      & MembershipFragmentFragment
    )> }
  )> }
);

export type MembershipDeleteMutationVariables = {
  id: Scalars['ID']
};


export type MembershipDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'MembershipDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Membership' }
      & MembershipFragmentFragment
    )> }
  )> }
);

export type MembershipDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type MembershipDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'MembershipDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'Membership' }
      & MembershipFragmentFragment
    )>> }
  )> }
);

export type MenuFragmentFragment = (
  { __typename?: 'Menu' }
  & Pick<Menu, 'id' | 'code' | 'title' | 'description' | 'createdAt'>
);

export type MenuDetailFragmentFragment = (
  { __typename?: 'Menu' }
  & { items: Array<(
    { __typename?: 'MenuItem' }
    & Pick<MenuItem, 'label' | 'open' | 'linkURL'>
    & { children: Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'label' | 'open' | 'linkURL'>
      & { children: Array<(
        { __typename?: 'MenuItem' }
        & Pick<MenuItem, 'label' | 'open' | 'linkURL'>
        & { children: Array<(
          { __typename?: 'MenuItem' }
          & Pick<MenuItem, 'label' | 'open' | 'linkURL'>
        )> }
      )> }
    )> }
  )> }
  & MenuFragmentFragment
);

export type MenuGetListQueryVariables = {
  filterBy?: Maybe<MenuFilters>,
  orderBy?: Maybe<MenuOrder>,
  pagination?: Maybe<Pagination>
};


export type MenuGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'MenuConnection' }
    & { total: MenuConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'Menu' }
      & MenuFragmentFragment
    )>>> }
  ) }
);

export type MenuGetQueryVariables = {
  id: Scalars['ID']
};


export type MenuGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Menu' }
    & MenuDetailFragmentFragment
  )> }
);

export type MenuCreateMutationVariables = {
  input: MenuCreateInput
};


export type MenuCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'MenuCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Menu' }
      & MenuDetailFragmentFragment
    )> }
  )> }
);

export type MenuUpdateMutationVariables = {
  id: Scalars['ID'],
  input: MenuUpdateInput
};


export type MenuUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'MenuUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Menu' }
      & MenuDetailFragmentFragment
    )> }
  )> }
);

export type MenuDeleteMutationVariables = {
  id: Scalars['ID']
};


export type MenuDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'MenuDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Menu' }
      & MenuFragmentFragment
    )> }
  )> }
);

export type MenuDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type MenuDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'MenuDeleteManyPayload' }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'Menu' }
      & MenuFragmentFragment
    )>>> }
  )> }
);

export type OfflineContentDetailContentFragmentFragment = (
  { __typename?: 'Content' }
  & Pick<Content, 'id' | 'service' | 'subject' | 'active' | 'visibility' | 'studentCount' | 'maxSellableCount' | 'saleStatus' | 'originalPrice' | 'price' | 'selectedCategoryIDs'>
  & { image: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, teachers: Array<(
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id' | 'name'>
  )>, teacherCommissions: Maybe<Array<(
    { __typename?: 'TeacherCommissions' }
    & Pick<TeacherCommissions, 'teacherID' | 'partialContract' | 'totalContract'>
  )>>, categoryParents: Maybe<Array<(
    { __typename?: 'ContentCategoryParent' }
    & Pick<ContentCategoryParent, 'name'>
    & { childrenCategories: Array<(
      { __typename?: 'ContentCategory' }
      & Pick<ContentCategory, 'id' | 'name' | 'isSelected'>
    )> }
  )>>, contentPurchases: Maybe<(
    { __typename?: 'ContentPurchaseConnection' }
    & { nodes: Array<(
      { __typename?: 'ContentPurchase' }
      & Pick<ContentPurchase, 'id'>
      & { user: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'phoneNumber' | 'email'>
      )> }
    )> }
  )> }
);

export type OfflineContentFragmentFragment = (
  { __typename?: 'Offline' }
  & Pick<Offline, 'id' | 'courseStartDate' | 'courseEndDate' | 'descriptionHTML' | 'cautionHTML' | 'shortDescription' | 'usePayback' | 'weekday' | 'classStartTime' | 'classEndTime' | 'tags' | 'useMessage'>
  & { offlineRepurchaseDiscounts: Array<(
    { __typename?: 'OfflineRepurchaseDiscount' }
    & Pick<OfflineRepurchaseDiscount, 'discountType' | 'discountValue' | 'isDeleted'>
    & { targetOffline: (
      { __typename?: 'Offline' }
      & Pick<Offline, 'id' | 'courseStartDate' | 'courseEndDate'>
      & { content: (
        { __typename?: 'Content' }
        & Pick<Content, 'id' | 'subject'>
        & { teachers: Array<(
          { __typename?: 'Teacher' }
          & Pick<Teacher, 'id' | 'name'>
        )> }
      ) }
    ) }
  )>, defaultTeacherForImage: Maybe<(
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id'>
    & { offlineLectureThumbnailImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'url'>
    )> }
  )>, children: Array<(
    { __typename?: 'Offline' }
    & Pick<Offline, 'id' | 'courseStartDate' | 'courseEndDate' | 'classStartTime' | 'classEndTime'>
    & { content: (
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'service' | 'subject' | 'studentCount'>
      & { teachers: Array<(
        { __typename?: 'Teacher' }
        & Pick<Teacher, 'id' | 'name'>
      )> }
    ) }
  )>, content: (
    { __typename?: 'Content' }
    & OfflineContentDetailContentFragmentFragment
  ) }
);

export type OfflineContentGetListQueryVariables = {
  filterBy?: Maybe<OfflineFilters>,
  orderBy?: Maybe<OfflineOrder>,
  pagination?: Maybe<Pagination>
};


export type OfflineContentGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'OfflineConnection' }
    & { total: OfflineConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Offline' }
      & OfflineContentFragmentFragment
    )> }
  ) }
);

export type OfflineContentGetQueryVariables = {
  id: Scalars['ID']
};


export type OfflineContentGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Offline' }
    & OfflineContentFragmentFragment
  )> }
);

export type OfflineContentGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type OfflineContentGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'Offline' }
    & OfflineContentFragmentFragment
  )> }
);

export type OfflineContentCreateMutationVariables = {
  input: OfflineCreateInput
};


export type OfflineContentCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'OfflineCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Offline' }
      & OfflineContentFragmentFragment
    )> }
  )> }
);

export type OfflineContentUpdateMutationVariables = {
  id: Scalars['ID'],
  input: OfflineUpdateInput
};


export type OfflineContentUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'OfflineUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Offline' }
      & OfflineContentFragmentFragment
    )> }
  )> }
);

export type OrderFragmentFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'createdAt'>
  & { contentPurchase: Maybe<Array<(
    { __typename?: 'ContentPurchase' }
    & Pick<ContentPurchase, 'id' | 'price' | 'pointDiscount' | 'couponDiscount' | 'expiredAt'>
    & { content: (
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'subject'>
    ) }
  )>>, payment: Maybe<Array<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'amount' | 'state' | 'paymentMethod' | 'transactionID'>
  )>>, refund: Maybe<(
    { __typename?: 'Refund' }
    & Pick<Refund, 'id' | 'createdAt' | 'amount' | 'message'>
  )> }
);

export type OrderGetListQueryVariables = {
  filterBy?: Maybe<OrderFilters>,
  pagination?: Maybe<Pagination>
};


export type OrderGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'OrderConnection' }
    & { total: OrderConnection['totalCount'] }
    & { data: Array<Maybe<(
      { __typename?: 'Order' }
      & OrderFragmentFragment
    )>> }
  )> }
);

export type PaymentStateFragment = (
  { __typename?: 'Payment' }
  & Pick<Payment, 'id' | 'transactionID' | 'state'>
);

export type PaymentStateGetQueryVariables = {
  id: Scalars['ID']
};


export type PaymentStateGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Payment' }
    & PaymentStateFragment
  )> }
);

export type PaymentFragmentFragment = (
  { __typename?: 'Payment' }
  & Pick<Payment, 'id' | 'paymentMethod' | 'amount'>
  & { order: Maybe<(
    { __typename?: 'Order' }
    & PaymentOrderFragmentFragment
  )>, refund: Maybe<Array<Maybe<(
    { __typename?: 'Refund' }
    & PaymentRefunndFragmentFragment
  )>>> }
);

export type PaymentOrderFragmentFragment = (
  { __typename?: 'Order' }
  & { refund: Maybe<(
    { __typename?: 'Refund' }
    & Pick<Refund, 'createdAt' | 'amount' | 'message'>
  )> }
);

export type PaymentRefunndFragmentFragment = (
  { __typename?: 'Refund' }
  & Pick<Refund, 'id' | 'createdAt' | 'amount' | 'message'>
);

export type PaymentGetListQueryVariables = {
  filterBy?: Maybe<PaymentFilters>,
  pagination?: Maybe<Pagination>
};


export type PaymentGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'PaymentConnection' }
    & { total: PaymentConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Payment' }
      & PaymentFragmentFragment
    )> }
  )> }
);

export type PermissionFragmentFragment = (
  { __typename?: 'Permission' }
  & Pick<Permission, 'id' | 'scope' | 'grantedAt'>
);

export type PermissionGetListQueryVariables = {
  filterBy?: Maybe<PermissionFilters>,
  orderBy?: Maybe<PermissionOrder>,
  pagination?: Maybe<Pagination>
};


export type PermissionGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'PermissionConnection' }
    & { total: PermissionConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Permission' }
      & PermissionFragmentFragment
    )> }
  ) }
);

export type PermissionGetQueryVariables = {
  id: Scalars['ID']
};


export type PermissionGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Permission' }
    & PermissionFragmentFragment
  )> }
);

export type PermissionGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type PermissionGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'Permission' }
    & PermissionFragmentFragment
  )>> }
);

export type PermissionCreateMutationVariables = {
  teamID: Scalars['ID'],
  scope: PermissionScope
};


export type PermissionCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'PermissionGrantPayload' }
    & { data: Maybe<(
      { __typename?: 'Permission' }
      & PermissionFragmentFragment
    )> }
  )> }
);

export type PermissionDeleteMutationVariables = {
  id: Scalars['ID']
};


export type PermissionDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'PermissionRevokePayload' }
    & { data: Maybe<(
      { __typename?: 'Permission' }
      & PermissionFragmentFragment
    )> }
  )> }
);

export type PostCurationFragmentFragment = (
  { __typename?: 'PostCuration' }
  & Pick<PostCuration, 'id' | 'code' | 'title' | 'boardURL' | 'maxPostLimit'>
  & { boards: Maybe<Array<Maybe<(
    { __typename?: 'Board' }
    & Pick<Board, 'id' | 'title'>
  )>>>, configs: Array<(
    { __typename?: 'PostCurationConfig' }
    & Pick<PostCurationConfig, 'isAuto' | 'category' | 'title' | 'postID' | 'orderBy' | 'sourceBoardID'>
  )> }
);

export type PostCurationGetListQueryVariables = {
  filterBy?: Maybe<PostCurationFilters>,
  orderBy?: Maybe<PostCurationOrder>,
  pagination?: Maybe<Pagination>
};


export type PostCurationGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'PostCurationConnection' }
    & { total: PostCurationConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'PostCuration' }
      & PostCurationFragmentFragment
    )>>> }
  ) }
);

export type PostCurationUpdateMutationVariables = {
  id: Scalars['ID'],
  input: PostCurationInput
};


export type PostCurationUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'PostCurationUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'PostCuration' }
      & PostCurationFragmentFragment
    )> }
  )> }
);

export type PostCurationGetQueryVariables = {
  id: Scalars['ID']
};


export type PostCurationGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'PostCuration' }
    & PostCurationFragmentFragment
  )> }
);

export type RefundFragmentFragment = (
  { __typename?: 'Refund' }
  & Pick<Refund, 'id' | 'amount' | 'createdAt' | 'message'>
);

export type RefundGetQueryVariables = {
  id: Scalars['ID']
};


export type RefundGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Refund' }
    & RefundFragmentFragment
  )> }
);

export type RefundCreateMutationVariables = {
  input: RefundCreateInput
};


export type RefundCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'RefundCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Refund' }
      & RefundFragmentFragment
    )> }
  )> }
);

export type ResumeProductFragmentFragment = (
  { __typename?: 'ResumeProduct' }
  & Pick<ResumeProduct, 'id' | 'defaultCorrectionTime' | 'caution' | 'isAvailableInHoliday' | 'isAvailableInWeekend' | 'correctionLimitType' | 'maxBuyableLimitPerDay' | 'dailyQuantityLeft' | 'isAvailableToSale' | 'createdAt'>
  & { content: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'subject' | 'service' | 'salesPeriod' | 'active' | 'priority' | 'visibility' | 'price' | 'expireDays'>
    & { teachers: Array<(
      { __typename?: 'Teacher' }
      & Pick<Teacher, 'id' | 'name'>
    )> }
  ), introductionImageFile: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  ) }
);

export type ResumeProductGetListQueryVariables = {
  filterBy?: Maybe<ResumeProductFilters>,
  orderBy?: Maybe<ResumeProductOrder>,
  pagination?: Maybe<Pagination>
};


export type ResumeProductGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ResumeProductConnection' }
    & { total: ResumeProductConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'ResumeProduct' }
      & ResumeProductFragmentFragment
    )> }
  ) }
);

export type ResumeProductGetQueryVariables = {
  id: Scalars['ID']
};


export type ResumeProductGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ResumeProduct' }
    & ResumeProductFragmentFragment
  )> }
);

export type ResumeProductCreateMutationVariables = {
  input: ResumeProductInput
};


export type ResumeProductCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ResumeProductCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'ResumeProduct' }
      & ResumeProductFragmentFragment
    )> }
  )> }
);

export type ResumeProductUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ResumeProductInput
};


export type ResumeProductUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ResumeProductUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'ResumeProduct' }
      & ResumeProductFragmentFragment
    )> }
  )> }
);

export type ResumeProductDeleteMutationVariables = {
  id: Scalars['ID']
};


export type ResumeProductDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ResumeProductDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'ResumeProduct' }
      & ResumeProductFragmentFragment
    )> }
  )> }
);

export type ResumeProductDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type ResumeProductDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ResumeProductDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'ResumeProduct' }
      & ResumeProductFragmentFragment
    )>> }
  )> }
);

export type ResumeFragmentFragment = (
  { __typename?: 'Resume' }
  & Pick<Resume, 'id' | 'correctionStep' | 'writtenAt' | 'reviewedAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'username' | 'name'>
  ), wishCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )>, options: Array<(
    { __typename?: 'ResumeProductOption' }
    & Pick<ResumeProductOption, 'category' | 'name'>
  )>, resumeProduct: (
    { __typename?: 'ResumeProduct' }
    & Pick<ResumeProduct, 'correctionLimitType'>
    & { content: (
      { __typename?: 'Content' }
      & { teachers: Array<(
        { __typename?: 'Teacher' }
        & Pick<Teacher, 'name'>
      )> }
    ) }
  ) }
);

export type ResumeDetailFragmentFragment = (
  { __typename?: 'Resume' }
  & Pick<Resume, 'userGPA' | 'maxGPA' | 'resumeText' | 'correctionHTML' | 'languageTestSubject' | 'languageTestScore' | 'willOpen' | 'reviewedAt' | 'isCompanyAccepted' | 'successfulCandidateReward'>
  & { school: Maybe<(
    { __typename?: 'School' }
    & Pick<School, 'schoolName' | 'campusName'>
  )>, major: Maybe<(
    { __typename?: 'Major' }
    & Pick<Major, 'name'>
  )>, resumeFile: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, correctionFile: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, resumeHistory: Maybe<Array<(
    { __typename?: 'Resume' }
    & Pick<Resume, 'id' | 'writtenAt' | 'reviewedAt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'username' | 'name'>
    ), wishCompany: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
    )>, resumeProduct: (
      { __typename?: 'ResumeProduct' }
      & { content: (
        { __typename?: 'Content' }
        & { teachers: Array<(
          { __typename?: 'Teacher' }
          & Pick<Teacher, 'name'>
        )> }
      ) }
    ) }
  )>> }
  & ResumeFragmentFragment
);

export type ResumeGetQueryVariables = {
  id: Scalars['ID']
};


export type ResumeGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Resume' }
    & ResumeDetailFragmentFragment
  )> }
);

export type ResumeGetListQueryVariables = {
  filterBy?: Maybe<ResumeFilters>,
  orderBy?: Maybe<ResumeOrder>,
  pagination?: Maybe<Pagination>
};


export type ResumeGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ResumeConnection' }
    & { total: ResumeConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Resume' }
      & ResumeFragmentFragment
    )> }
  ) }
);

export type ResumeUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ResumeUpdateInput
};


export type ResumeUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ResumeUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Resume' }
      & ResumeDetailFragmentFragment
    )> }
  )> }
);

export type ReviewFragmentFragment = (
  { __typename?: 'Review' }
  & Pick<Review, 'id' | 'subject' | 'bodyHTML' | 'isPrivate' | 'score' | 'createdAt'>
  & { author: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'username'>
  ), content: (
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'active' | 'service' | 'subject'>
    & { teachers: Array<(
      { __typename?: 'Teacher' }
      & Pick<Teacher, 'id' | 'name'>
    )> }
  ) }
);

export type ReviewGetListQueryVariables = {
  filterBy?: Maybe<ReviewFilters>,
  orderBy?: Maybe<ReviewOrder>,
  pagination?: Maybe<Pagination>
};


export type ReviewGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ReviewConnection' }
    & { total: ReviewConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Review' }
      & ReviewFragmentFragment
    )> }
  ) }
);

export type ReviewGetQueryVariables = {
  id: Scalars['ID']
};


export type ReviewGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Review' }
    & ReviewFragmentFragment
  )> }
);

export type ReviewUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ReviewInput
};


export type ReviewUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ReviewUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Review' }
      & ReviewFragmentFragment
    )> }
  )> }
);

export type ReviewDeleteMutationVariables = {
  id: Scalars['ID']
};


export type ReviewDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ReviewDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Review' }
      & ReviewFragmentFragment
    )> }
  )> }
);

export type ReviewDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type ReviewDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ReviewDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'Review' }
      & ReviewFragmentFragment
    )>> }
  )> }
);

export type ScheduleEventFragmentFragment = (
  { __typename?: 'ScheduleEvent' }
  & Pick<ScheduleEvent, 'id' | 'scheduleID' | 'sequence' | 'startDate' | 'endDate' | 'useAutoUpdate' | 'autoUpdateWeekday' | 'autoUpdateEndDate' | 'description'>
);

export type ScheduleEventGetListQueryVariables = {
  filterBy?: Maybe<ScheduleEventFilters>,
  orderBy?: Maybe<ScheduleEventOrder>,
  pagination?: Maybe<Pagination>
};


export type ScheduleEventGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ScheduleEventConnection' }
    & { total: ScheduleEventConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'ScheduleEvent' }
      & ScheduleEventFragmentFragment
    )> }
  ) }
);

export type ScheduleEventGetQueryVariables = {
  id: Scalars['ID']
};


export type ScheduleEventGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ScheduleEvent' }
    & ScheduleEventFragmentFragment
  )> }
);

export type ScheduleEventCreateMutationVariables = {
  input: ScheduleEventCreateInput
};


export type ScheduleEventCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ScheduleEventCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'ScheduleEvent' }
      & ScheduleEventFragmentFragment
    )> }
  )> }
);

export type ScheduleEventUpdateMutationVariables = {
  id: Scalars['ID'],
  input: ScheduleEventUpdateInput
};


export type ScheduleEventUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ScheduleEventUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'ScheduleEvent' }
      & ScheduleEventFragmentFragment
    )> }
  )> }
);

export type ScheduleFragmentFragment = (
  { __typename?: 'Schedule' }
  & Pick<Schedule, 'id' | 'title'>
);

export type ScheduleGetListQueryVariables = {
  filterBy?: Maybe<ScheduleFilters>,
  orderBy?: Maybe<ScheduleOrder>,
  pagination?: Maybe<Pagination>
};


export type ScheduleGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ScheduleConnection' }
    & { total: ScheduleConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Schedule' }
      & ScheduleFragmentFragment
    )> }
  ) }
);

export type ScheduleGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type ScheduleGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'Schedule' }
    & ScheduleFragmentFragment
  )>> }
);

export type StudyBenefitCreateMutationVariables = {
  input: StudyBenefitInput
};


export type StudyBenefitCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyBenefitCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyBenefit' }
      & StudyBenefitFragmentFragment
    )> }
  )> }
);

export type StudyBenefitFragmentFragment = (
  { __typename?: 'StudyBenefit' }
  & Pick<StudyBenefit, 'id' | 'benefitType'>
  & { benefit: Maybe<(
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'key' | 'value'>
  )> }
);

export type StudyBenefitUpdateMutationVariables = {
  id: Scalars['ID'],
  input: StudyBenefitInput
};


export type StudyBenefitUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyBenefitUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyBenefit' }
      & StudyBenefitFragmentFragment
    )> }
  )> }
);

export type StudyBenefitDeleteMutationVariables = {
  id: Scalars['ID']
};


export type StudyBenefitDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyBenefitDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyBenefit' }
      & StudyBenefitFragmentFragment
    )> }
  )> }
);

export type StudyEventFragmentFragment = (
  { __typename?: 'StudyEvent' }
  & Pick<StudyEvent, 'id' | 'sequence' | 'startDate' | 'endDate'>
);

export type StudyEventGetListQueryVariables = {
  filterBy?: Maybe<StudyEventFilters>,
  orderBy?: Maybe<StudyEventOrder>,
  pagination?: Maybe<Pagination>
};


export type StudyEventGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'StudyEventConnection' }
    & { total: StudyEventConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'StudyEvent' }
      & StudyEventFragmentFragment
    )> }
  ) }
);

export type StudyEventCreateMutationVariables = {
  input: StudyEventInput
};


export type StudyEventCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyEventCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyEvent' }
      & StudyEventFragmentFragment
    )> }
  )> }
);

export type StudyEventBulkCreateMutationVariables = {
  inputs: Array<StudyEventInput>
};


export type StudyEventBulkCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyEventBulkCreatePayload' }
    & { data: Array<(
      { __typename?: 'StudyEvent' }
      & StudyEventFragmentFragment
    )> }
  )> }
);

export type StudyEventGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type StudyEventGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'StudyEvent' }
    & StudyEventFragmentFragment
  )>> }
);

export type BulkUpdateStudyEventInStudyMutationVariables = {
  studyID: Scalars['ID'],
  input: Array<StudyEventBulkInput>
};


export type BulkUpdateStudyEventInStudyMutation = (
  { __typename?: 'Mutation' }
  & { bulkUpdateStudyEventInStudy: Maybe<(
    { __typename?: 'StudyCreatePayload' }
    & { study: Maybe<(
      { __typename?: 'Study' }
      & Pick<Study, 'id' | 'title' | 'startDate' | 'endDate'>
    )> }
  )> }
);

export type StudyLectureMissionFragmentFragment = (
  { __typename?: 'StudyMission' }
  & Pick<StudyMission, 'id' | 'title' | 'missionType' | 'dueDateStartAt' | 'dueDateEndAt' | 'isMutable'>
  & { missionDetails: Maybe<{ __typename?: 'AssignmentFileMission' } | { __typename?: 'AssignmentLinkMission' } | (
    { __typename?: 'LectureMission' }
    & { content: (
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'subject' | 'type'>
    ) }
  ) | { __typename?: 'ExamMission' } | { __typename?: 'ReviewMission' }>, studyEvent: (
    { __typename?: 'StudyEvent' }
    & Pick<StudyEvent, 'id' | 'sequence' | 'startDate' | 'endDate'>
    & { study: (
      { __typename?: 'Study' }
      & Pick<Study, 'id' | 'title' | 'startDate' | 'endDate'>
    ) }
  ) }
);

export type StudyLectureMissionGetListQueryVariables = {
  filterBy?: Maybe<StudyMissionFilters>,
  orderBy?: Maybe<StudyMissionOrder>,
  pagination?: Maybe<Pagination>
};


export type StudyLectureMissionGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'StudyMissionConnection' }
    & { total: StudyMissionConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'StudyMission' }
      & StudyLectureMissionFragmentFragment
    )> }
  ) }
);

export type StudyLectureMissionGetQueryVariables = {
  id: Scalars['ID']
};


export type StudyLectureMissionGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'StudyMission' }
    & StudyLectureMissionFragmentFragment
  )> }
);

export type StudyLectureMissionCreateMutationVariables = {
  input: StudyMissionCreateInput
};


export type StudyLectureMissionCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyMission' }
      & StudyLectureMissionFragmentFragment
    )> }
  )> }
);

export type StudyLectureMissionUpdateMutationVariables = {
  id: Scalars['ID'],
  input: StudyMissionUpdateInput
};


export type StudyLectureMissionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyMission' }
      & StudyLectureMissionFragmentFragment
    )> }
  )> }
);

export type StudyLectureMissionDeleteMutationVariables = {
  id: Scalars['ID']
};


export type StudyLectureMissionDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyMission' }
      & Pick<StudyMission, 'id'>
    )> }
  )> }
);

export type StudyLectureMissionDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type StudyLectureMissionDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'StudyMission' }
      & Pick<StudyMission, 'id'>
    )>> }
  )> }
);

export type StudyLinkMissionFragmentFragment = (
  { __typename?: 'StudyMission' }
  & Pick<StudyMission, 'id' | 'title' | 'missionType' | 'dueDateStartAt' | 'dueDateEndAt' | 'isMutable'>
  & { missionDetails: Maybe<{ __typename?: 'AssignmentFileMission' } | (
    { __typename?: 'AssignmentLinkMission' }
    & Pick<AssignmentLinkMission, 'useUrlCheck' | 'siteName' | 'boardName' | 'boardUrl' | 'hashTags' | 'minLinkCountLimit'>
  ) | { __typename?: 'LectureMission' } | { __typename?: 'ExamMission' } | { __typename?: 'ReviewMission' }>, studyEvent: (
    { __typename?: 'StudyEvent' }
    & Pick<StudyEvent, 'id' | 'sequence' | 'startDate' | 'endDate'>
    & { study: (
      { __typename?: 'Study' }
      & Pick<Study, 'id' | 'title' | 'startDate' | 'endDate'>
    ) }
  ) }
);

export type StudyLinkMissionGetListQueryVariables = {
  filterBy?: Maybe<StudyMissionFilters>,
  orderBy?: Maybe<StudyMissionOrder>,
  pagination?: Maybe<Pagination>
};


export type StudyLinkMissionGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'StudyMissionConnection' }
    & { total: StudyMissionConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'StudyMission' }
      & StudyLinkMissionFragmentFragment
    )> }
  ) }
);

export type StudyLinkMissionGetQueryVariables = {
  id: Scalars['ID']
};


export type StudyLinkMissionGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'StudyMission' }
    & StudyLinkMissionFragmentFragment
  )> }
);

export type StudyLinkMissionCreateMutationVariables = {
  input: StudyMissionCreateInput
};


export type StudyLinkMissionCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyMission' }
      & StudyLinkMissionFragmentFragment
    )> }
  )> }
);

export type StudyLinkMissionUpdateMutationVariables = {
  id: Scalars['ID'],
  input: StudyMissionUpdateInput
};


export type StudyLinkMissionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyMission' }
      & StudyLinkMissionFragmentFragment
    )> }
  )> }
);

export type StudyLinkMissionDeleteMutationVariables = {
  id: Scalars['ID']
};


export type StudyLinkMissionDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyMission' }
      & Pick<StudyMission, 'id'>
    )> }
  )> }
);

export type StudyLinkMissionDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type StudyLinkMissionDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'StudyMission' }
      & Pick<StudyMission, 'id'>
    )>> }
  )> }
);

export type StudyReviewMissionFragmentFragment = (
  { __typename?: 'StudyMission' }
  & Pick<StudyMission, 'id' | 'title' | 'missionType' | 'dueDateStartAt' | 'dueDateEndAt' | 'isMutable'>
  & { missionDetails: Maybe<{ __typename?: 'AssignmentFileMission' } | { __typename?: 'AssignmentLinkMission' } | { __typename?: 'LectureMission' } | { __typename?: 'ExamMission' } | (
    { __typename?: 'ReviewMission' }
    & Pick<ReviewMission, 'useUrlCheck' | 'siteName' | 'boardName' | 'boardUrl' | 'hashTags' | 'minLinkCountLimit'>
  )>, studyEvent: (
    { __typename?: 'StudyEvent' }
    & Pick<StudyEvent, 'id' | 'sequence' | 'startDate' | 'endDate'>
    & { study: (
      { __typename?: 'Study' }
      & Pick<Study, 'id' | 'title' | 'startDate' | 'endDate'>
    ) }
  ) }
);

export type StudyReviewMissionGetListQueryVariables = {
  filterBy?: Maybe<StudyMissionFilters>,
  orderBy?: Maybe<StudyMissionOrder>,
  pagination?: Maybe<Pagination>
};


export type StudyReviewMissionGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'StudyMissionConnection' }
    & { total: StudyMissionConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'StudyMission' }
      & StudyReviewMissionFragmentFragment
    )> }
  ) }
);

export type StudyReviewMissionGetQueryVariables = {
  id: Scalars['ID']
};


export type StudyReviewMissionGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'StudyMission' }
    & StudyReviewMissionFragmentFragment
  )> }
);

export type StudyReviewMissionCreateMutationVariables = {
  input: StudyMissionCreateInput
};


export type StudyReviewMissionCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyMission' }
      & StudyReviewMissionFragmentFragment
    )> }
  )> }
);

export type StudyReviewMissionUpdateMutationVariables = {
  id: Scalars['ID'],
  input: StudyMissionUpdateInput
};


export type StudyReviewMissionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyMission' }
      & StudyReviewMissionFragmentFragment
    )> }
  )> }
);

export type StudyReviewMissionDeleteMutationVariables = {
  id: Scalars['ID']
};


export type StudyReviewMissionDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'StudyMission' }
      & Pick<StudyMission, 'id'>
    )> }
  )> }
);

export type StudyReviewMissionDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type StudyReviewMissionDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyMissionDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'StudyMission' }
      & Pick<StudyMission, 'id'>
    )>> }
  )> }
);

export type UserStudyEventLogQueryVariables = {
  id: Scalars['ID'],
  userStudyEventfilterBy?: Maybe<UserStudyEventFilters>,
  userStudyEventorderBy?: Maybe<UserStudyEventOrder>,
  pagination?: Maybe<Pagination>
};


export type UserStudyEventLogQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Study' }
    & Pick<Study, 'id'>
    & { userStudyEvents: Maybe<(
      { __typename?: 'UserStudyEventConnection' }
      & Pick<UserStudyEventConnection, 'totalCount'>
      & { nodes: Array<(
        { __typename?: 'UserStudyEvent' }
        & Pick<UserStudyEvent, 'createdAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'phoneNumber' | 'name' | 'createdAt'>
        ) }
      )> }
    )> }
  )> }
);

export type UserStudyEventLogTotalCountQueryVariables = {
  id: Scalars['ID']
};


export type UserStudyEventLogTotalCountQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Study' }
    & Pick<Study, 'id'>
    & { userStudyEvents: Maybe<(
      { __typename?: 'UserStudyEventConnection' }
      & Pick<UserStudyEventConnection, 'totalCount'>
    )> }
  )> }
);

export type StudyFragmentFragment = (
  { __typename?: 'Study' }
  & Pick<Study, 'id' | 'title' | 'service' | 'startDate' | 'endDate' | 'recruitPeriodStartDate' | 'recruitPeriodEndDate' | 'createdAt' | 'isDuplicateAllow' | 'participantsNumber' | 'participantsLimit' | 'participantsLimitNumber' | 'pcLink' | 'mobileLink' | 'communityPostLink' | 'preNoticeUrl' | 'noticeLink' | 'previousLink' | 'completeBody' | 'isPrivate'>
  & { brazeEventProperties: (
    { __typename?: 'StudyBrazeEventProperties' }
    & Pick<StudyBrazeEventProperties, 'studyClassification' | 'studyName' | 'studyCompany'>
  ), duplicateRestrictStudies: Maybe<Array<(
    { __typename?: 'Study' }
    & Pick<Study, 'id'>
  )>>, events: Maybe<(
    { __typename?: 'StudyEventConnection' }
    & { nodes: Array<(
      { __typename?: 'StudyEvent' }
      & Pick<StudyEvent, 'id' | 'sequence' | 'startDate' | 'endDate'>
    )> }
  )> }
);

export type StudyGetQueryVariables = {
  id: Scalars['ID']
};


export type StudyGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Study' }
    & StudyFragmentFragment
  )> }
);

export type StudyGetListQueryVariables = {
  filterBy?: Maybe<StudyFilters>,
  orderBy?: Maybe<StudyOrder>,
  pagination?: Maybe<Pagination>
};


export type StudyGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'StudyConnection' }
    & { total: StudyConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Study' }
      & StudyFragmentFragment
    )> }
  ) }
);

export type StudyGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type StudyGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'Study' }
    & StudyFragmentFragment
  )>> }
);

export type StudyCreateMutationVariables = {
  input: StudyCreateInput
};


export type StudyCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Study' }
      & StudyFragmentFragment
    )> }
  )> }
);

export type StudyUpdateMutationVariables = {
  id: Scalars['ID'],
  input: StudyUpdateInput
};


export type StudyUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Study' }
      & StudyFragmentFragment
    )> }
  )> }
);

export type StudyDeleteMutationVariables = {
  id: Scalars['ID']
};


export type StudyDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Study' }
      & StudyFragmentFragment
    )> }
  )> }
);

export type StudyDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type StudyDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'StudyDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'Study' }
      & StudyFragmentFragment
    )>> }
  )> }
);

export type BenefitDialog_StudyQueryVariables = {
  id: Scalars['ID']
};


export type BenefitDialog_StudyQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Study' }
    & FullStudyFragmentFragment
  )> }
);

export type FullStudyFragmentFragment = (
  { __typename?: 'Study' }
  & Pick<Study, 'id' | 'title' | 'service' | 'startDate' | 'endDate' | 'createdAt' | 'isDuplicateAllow' | 'participantsLimit' | 'participantsLimitNumber' | 'previousLink' | 'completeBody' | 'isPrivate'>
  & { events: Maybe<(
    { __typename?: 'StudyEventConnection' }
    & { nodes: Array<(
      { __typename?: 'StudyEvent' }
      & FullStudyEventFragmentFragment
    )> }
  )> }
);

export type FullStudyEventFragmentFragment = (
  { __typename?: 'StudyEvent' }
  & Pick<StudyEvent, 'id' | 'sequence' | 'endDate'>
  & { benefits: Maybe<Array<(
    { __typename?: 'StudyBenefit' }
    & FullStudyBenefitFragmentFragment
  )>> }
);

export type FullStudyBenefitFragmentFragment = (
  { __typename?: 'StudyBenefit' }
  & Pick<StudyBenefit, 'id' | 'benefitType' | 'provideDate'>
  & { studyEvent: Maybe<(
    { __typename?: 'StudyEvent' }
    & Pick<StudyEvent, 'id' | 'sequence' | 'startDate' | 'endDate'>
  )>, benefit: Maybe<(
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'key' | 'value'>
  )> }
);

export type SupportTicketAnswerTemplateFragmentFragment = (
  { __typename?: 'SupportTicketAnswerTemplate' }
  & Pick<SupportTicketAnswerTemplate, 'id' | 'subject' | 'isActive' | 'descriptionHTML'>
);

export type SupportTicketAnswerTemplateListQueryVariables = {
  filterBy?: Maybe<SupportTicketAnswerTemplateFilters>,
  orderBy?: Maybe<SupportTicketAnswerTemplateOrder>,
  pagination?: Maybe<Pagination>
};


export type SupportTicketAnswerTemplateListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'SupportTicketAnswerTemplateConnection' }
    & { total: SupportTicketAnswerTemplateConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'SupportTicketAnswerTemplate' }
      & SupportTicketAnswerTemplateFragmentFragment
    )>>> }
  )> }
);

export type SupportTicketAnswerTemplateGetQueryVariables = {
  id: Scalars['ID']
};


export type SupportTicketAnswerTemplateGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'SupportTicketAnswerTemplate' }
    & SupportTicketAnswerTemplateFragmentFragment
  )> }
);

export type SupportTicketAnswerTemplateGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type SupportTicketAnswerTemplateGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'SupportTicketAnswerTemplate' }
    & SupportTicketAnswerTemplateFragmentFragment
  )>> }
);

export type SupportTicketCategoryFragmentFragment = (
  { __typename?: 'SupportTicketCategory' }
  & Pick<SupportTicketCategory, 'id' | 'name' | 'parentID' | 'isActive'>
);

export type SupportTicketCategoryListQueryVariables = {
  filterBy?: Maybe<SupportTicketCategoryFilters>,
  orderBy?: Maybe<SupportTicketCategoryOrder>,
  pagination?: Maybe<Pagination>
};


export type SupportTicketCategoryListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'SupportTicketCategoryConnection' }
    & { total: SupportTicketCategoryConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'SupportTicketCategory' }
      & SupportTicketCategoryFragmentFragment
    )>>> }
  )> }
);

export type SupportTicketUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'name'>
);

export type SupportTicketFragmentFragment = (
  { __typename?: 'SupportTicket' }
  & Pick<SupportTicket, 'id' | 'createdAt' | 'updatedAt' | 'repliedAt' | 'service' | 'writerName' | 'subject' | 'bodyHTML' | 'processState' | 'reply' | 'ticketType'>
  & { writer: Maybe<(
    { __typename?: 'User' }
    & SupportTicketUserFragment
  )>, category: (
    { __typename?: 'SupportTicketCategory' }
    & Pick<SupportTicketCategory, 'id' | 'name'>
  ), file: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'url'>
  )>, replyUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username'>
  )>, replyFile: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'url'>
  )> }
);

export type SupportTicketListQueryVariables = {
  filterBy?: Maybe<SupportTicketFilters>,
  orderBy?: Maybe<SupportTicketOrder>,
  pagination?: Maybe<Pagination>
};


export type SupportTicketListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'SupportTicketConnection' }
    & { total: SupportTicketConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'SupportTicket' }
      & SupportTicketFragmentFragment
    )>>> }
  )> }
);

export type SupportTicketGetQueryVariables = {
  id: Scalars['ID']
};


export type SupportTicketGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'SupportTicket' }
    & SupportTicketFragmentFragment
  )> }
);

export type SupportTicketUpdateMutationVariables = {
  id: Scalars['ID'],
  input: SupportTicketInput
};


export type SupportTicketUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'SupportTicketUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'SupportTicket' }
      & SupportTicketFragmentFragment
    )> }
  )> }
);

export type SupportTicketCreateMutationVariables = {
  input: SupportTicketInput
};


export type SupportTicketCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'SupportTicketCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'SupportTicket' }
      & SupportTicketFragmentFragment
    )> }
  )> }
);

export type SupportTicketAdminCreateMutationVariables = {
  input: SupportTicketAdminCreateInput
};


export type SupportTicketAdminCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'SupportTicketAdminCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'SupportTicket' }
      & SupportTicketFragmentFragment
    )> }
  )> }
);

export type SupportTicketAdminUpdateMutationVariables = {
  id: Scalars['ID'],
  input: SupportTicketAdminUpdateInput
};


export type SupportTicketAdminUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'SupportTicketAdminUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'SupportTicket' }
      & SupportTicketFragmentFragment
    )> }
  )> }
);

export type TeacherCurationFragmentFragment = (
  { __typename?: 'TeacherCuration' }
  & Pick<TeacherCuration, 'id' | 'name' | 'code' | 'createdAt' | 'updatedAt'>
  & { teachers: Array<(
    { __typename?: 'Teacher' }
    & Pick<Teacher, 'id' | 'name'>
  )> }
);

export type TeacherCurationGetListQueryVariables = {
  filterBy?: Maybe<TeacherCurationFilters>,
  orderBy?: Maybe<TeacherCurationOrder>,
  pagination?: Maybe<Pagination>
};


export type TeacherCurationGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'TeacherCurationConnection' }
    & { total: TeacherCurationConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'TeacherCuration' }
      & TeacherCurationFragmentFragment
    )> }
  ) }
);

export type TeacherCurationGetQueryVariables = {
  id: Scalars['ID']
};


export type TeacherCurationGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'TeacherCuration' }
    & TeacherCurationFragmentFragment
  )> }
);

export type TeacherCurationCreateMutationVariables = {
  input: TeacherCurationInput
};


export type TeacherCurationCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TeacherCurationCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'TeacherCuration' }
      & TeacherCurationFragmentFragment
    )> }
  )> }
);

export type TeacherCurationUpdateMutationVariables = {
  id: Scalars['ID'],
  input: TeacherCurationInput
};


export type TeacherCurationUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TeacherCurationUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'TeacherCuration' }
      & TeacherCurationFragmentFragment
    )> }
  )> }
);

export type TeacherCurationDeleteMutationVariables = {
  id: Scalars['ID']
};


export type TeacherCurationDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TeacherCurationDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'TeacherCuration' }
      & TeacherCurationFragmentFragment
    )> }
  )> }
);

export type TeacherFragmentFragment = (
  { __typename?: 'Teacher' }
  & Pick<Teacher, 'id' | 'name' | 'priority' | 'isActive'>
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type TeacherDetailFragmentFragment = (
  { __typename?: 'Teacher' }
  & Pick<Teacher, 'includeInvoice' | 'aboutListText' | 'mobileDescriptionTitle' | 'mobileDescriptionDetail' | 'contactURL' | 'videoListText' | 'mainImageLink'>
  & { aboutDetailImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, aboutListImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, aboutRollingImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, mainPageImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, offlineLectureThumbnailImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, mobileMainImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, mobileThumbnailImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  )>, videoListImage: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'url'>
  ) }
  & TeacherFragmentFragment
);

export type TeacherGetListQueryVariables = {
  filterBy?: Maybe<TeacherFilters>,
  orderBy?: Maybe<TeacherOrder>,
  pagination?: Maybe<Pagination>
};


export type TeacherGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'TeacherConnection' }
    & { total: TeacherConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'Teacher' }
      & TeacherFragmentFragment
    )>>> }
  ) }
);

export type TeacherGetQueryVariables = {
  id: Scalars['ID']
};


export type TeacherGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Teacher' }
    & TeacherDetailFragmentFragment
  )> }
);

export type TeacherGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type TeacherGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'Teacher' }
    & TeacherDetailFragmentFragment
  )>> }
);

export type TeacherCreateMutationVariables = {
  input: TeacherInput
};


export type TeacherCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TeacherCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Teacher' }
      & TeacherDetailFragmentFragment
    )> }
  )> }
);

export type TeacherUpdateMutationVariables = {
  id: Scalars['ID'],
  input: TeacherInput
};


export type TeacherUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TeacherUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Teacher' }
      & TeacherDetailFragmentFragment
    )> }
  )> }
);

export type TeamFragmentFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name' | 'createdAt'>
  & { members: (
    { __typename?: 'MembershipConnection' }
    & Pick<MembershipConnection, 'totalCount'>
  ) }
);

export type TeamGetListQueryVariables = {
  filterBy?: Maybe<TeamFilters>,
  orderBy?: Maybe<TeamOrder>,
  pagination?: Maybe<Pagination>
};


export type TeamGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'TeamConnection' }
    & { total: TeamConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Team' }
      & TeamFragmentFragment
    )> }
  ) }
);

export type TeamGetQueryVariables = {
  id: Scalars['ID']
};


export type TeamGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Team' }
    & TeamFragmentFragment
  )> }
);

export type TeamGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type TeamGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'Team' }
    & TeamFragmentFragment
  )>> }
);

export type TeamCreateMutationVariables = {
  input: TeamInput
};


export type TeamCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TeamCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Team' }
      & TeamFragmentFragment
    )> }
  )> }
);

export type TeamUpdateMutationVariables = {
  id: Scalars['ID'],
  input: TeamInput
};


export type TeamUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TeamUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Team' }
      & TeamFragmentFragment
    )> }
  )> }
);

export type TeamDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type TeamDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TeamDeleteManyPayload' }
    & { data: Maybe<Array<(
      { __typename?: 'Team' }
      & TeamFragmentFragment
    )>> }
  )> }
);

export type UserDeviceFragmentFragment = (
  { __typename?: 'UserDevice' }
  & Pick<UserDevice, 'id' | 'createdAt' | 'userID' | 'deviceID' | 'deviceName' | 'deviceNickName' | 'isForFreepass' | 'playerType' | 'deviceNickNameUpdatedAt' | 'isDeleted' | 'isSystem'>
);

export type UserDeviceGetListQueryVariables = {
  filterBy?: Maybe<UserDeviceFilters>,
  orderBy?: Maybe<UserDeviceOrder>,
  pagination?: Maybe<Pagination>
};


export type UserDeviceGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'UserDeviceConnection' }
    & { total: UserDeviceConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'UserDevice' }
      & UserDeviceFragmentFragment
    )> }
  ) }
);

export type UserDeviceDeleteMutationVariables = {
  userID: Scalars['ID'],
  deviceID: Scalars['String']
};


export type UserDeviceDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'UserDeviceDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'UserDevice' }
      & UserDeviceFragmentFragment
    )> }
  )> }
);

export type UserStudyEventFragmentFragment = (
  { __typename?: 'UserStudyEvent' }
  & Pick<UserStudyEvent, 'id' | 'state' | 'createdAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'username' | 'phoneNumber' | 'createdAt'>
  ), study: (
    { __typename?: 'Study' }
    & Pick<Study, 'id' | 'title' | 'startDate' | 'endDate'>
  ), userStudy: (
    { __typename?: 'UserStudy' }
    & { userStudyEvents: Maybe<Array<(
      { __typename?: 'UserStudyEvent' }
      & Pick<UserStudyEvent, 'id' | 'state'>
      & { studyEvent: (
        { __typename?: 'StudyEvent' }
        & Pick<StudyEvent, 'sequence' | 'startDate' | 'endDate'>
      ), userStudyMissionsAndContents: (
        { __typename?: 'UserStudyMissionsAndContents' }
        & { userStudyMissions: Maybe<Array<(
          { __typename?: 'UserStudyMission' }
          & Pick<UserStudyMission, 'id' | 'state'>
          & { studyMission: (
            { __typename?: 'StudyMission' }
            & Pick<StudyMission, 'dueDateStartAt' | 'dueDateEndAt'>
            & { missionDetails: Maybe<(
              { __typename?: 'AssignmentFileMission' }
              & Pick<AssignmentFileMission, 'useTemplate'>
              & { templateFile: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'url' | 'name' | 'mimeType'>
              )> }
            ) | (
              { __typename?: 'AssignmentLinkMission' }
              & Pick<AssignmentLinkMission, 'useUrlCheck' | 'minLinkCountLimit'>
            ) | (
              { __typename?: 'LectureMission' }
              & Pick<LectureMission, 'standardProgressRate'>
              & { content: (
                { __typename?: 'Content' }
                & Pick<Content, 'id' | 'subject'>
              ) }
            ) | (
              { __typename?: 'ExamMission' }
              & Pick<ExamMission, 'standardScore'>
              & { content: (
                { __typename?: 'Content' }
                & Pick<Content, 'id' | 'subject'>
              ) }
            ) | (
              { __typename?: 'ReviewMission' }
              & Pick<ReviewMission, 'useUrlCheck' | 'minLinkCountLimit'>
            )> }
          ), userStudyMissionDetails: Maybe<Array<Maybe<(
            { __typename?: 'UserStudyMissionAssignmentFile' }
            & { file: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'url' | 'name' | 'mimeType'>
            )> }
          ) | (
            { __typename?: 'UserStudyMissionAssignmentLink' }
            & Pick<UserStudyMissionAssignmentLink, 'link'>
          ) | (
            { __typename?: 'UserStudyMissionLecture' }
            & Pick<UserStudyMissionLecture, 'completedVideoLessonCount'>
          ) | (
            { __typename?: 'UserStudyMissionExam' }
            & Pick<UserStudyMissionExam, 'score'>
          ) | (
            { __typename?: 'UserStudyMissionReview' }
            & Pick<UserStudyMissionReview, 'link'>
          )>>> }
        )>> }
      ) }
    )>> }
  ) }
);

export type UserStudyEventGetListQueryVariables = {
  filterBy?: Maybe<UserStudyEventFilters>,
  orderBy?: Maybe<UserStudyEventOrder>,
  pagination?: Maybe<Pagination>
};


export type UserStudyEventGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'UserStudyEventConnection' }
    & { total: UserStudyEventConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'UserStudyEvent' }
      & UserStudyEventFragmentFragment
    )> }
  )> }
);

export type UserStudyEventGetQueryVariables = {
  id: Scalars['ID']
};


export type UserStudyEventGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'UserStudyEvent' }
    & UserStudyEventFragmentFragment
  )> }
);

export type UserStudyMissionFragmentFragment = (
  { __typename?: 'UserStudyMission' }
  & Pick<UserStudyMission, 'createdAt'>
  & { studyMission: (
    { __typename?: 'StudyMission' }
    & Pick<StudyMission, 'dueDateStartAt' | 'dueDateEndAt'>
    & { studyEvent: (
      { __typename?: 'StudyEvent' }
      & Pick<StudyEvent, 'sequence'>
    ) }
  ) }
);

export type UserStudyMissionGetListQueryVariables = {
  filterBy?: Maybe<UserStudyMissionFilters>,
  orderBy?: Maybe<UserStudyMissionOrder>,
  pagination?: Maybe<Pagination>
};


export type UserStudyMissionGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'UserStudyMissionConnection' }
    & { total: UserStudyMissionConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'UserStudyMission' }
      & UserStudyMissionFragmentFragment
    )> }
  ) }
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'name' | 'email' | 'phoneNumber' | 'createdAt' | 'externalCompany' | 'joinRoute'>
  & { authUser: Maybe<(
    { __typename?: 'AuthUser' }
    & Pick<AuthUser, 'id'>
  )>, allianceUser: Maybe<(
    { __typename?: 'AllianceUser' }
    & Pick<AllianceUser, 'schoolType' | 'schoolName' | 'major' | 'grade' | 'schoolId' | 'studentId'>
  )> }
);

export type UserDetailFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'lastLoginAt' | 'emailReceipt' | 'smsReceipt' | 'birth' | 'subjectCurriculum' | 'examToTake' | 'residence'>
  & { order: Maybe<Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
    & { contentPurchase: Maybe<Array<(
      { __typename?: 'ContentPurchase' }
      & Pick<ContentPurchase, 'id'>
      & { content: (
        { __typename?: 'Content' }
        & Pick<Content, 'id' | 'subject'>
      ) }
    )>> }
  )>>, devices: Array<Maybe<(
    { __typename?: 'UserDevice' }
    & Pick<UserDevice, 'id' | 'createdAt' | 'deviceID' | 'deviceName' | 'deviceNickName' | 'isForFreepass' | 'isDeleted'>
  )>>, examSubject: Maybe<Array<Maybe<(
    { __typename?: 'ExamSubject' }
    & Pick<ExamSubject, 'id' | 'category' | 'examToTake' | 'subject'>
  )>>> }
  & UserFragmentFragment
);

export type UserGetListQueryVariables = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type UserGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'UserConnection' }
    & { total: UserConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & UserDetailFragmentFragment
    )>>> }
  ) }
);

export type UserGetQueryVariables = {
  id: Scalars['ID']
};


export type UserGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'User' }
    & UserDetailFragmentFragment
  )> }
);

export type UserGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type UserGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )>> }
);

export type UserPointCreateMutationVariables = {
  input: UserPointCreateInput
};


export type UserPointCreateMutation = (
  { __typename?: 'Mutation' }
  & { userPointCreate: Maybe<(
    { __typename?: 'UserPointCreatePayload' }
    & { userPoint: Maybe<(
      { __typename?: 'UserPoint' }
      & Pick<UserPoint, 'id' | 'createdAt' | 'point' | 'message'>
    )> }
  )> }
);

export type UserUpdateMutationVariables = {
  id: Scalars['ID'],
  input: AdminUserInfoUpdate
};


export type UserUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'UserInfoUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'User' }
      & UserDetailFragmentFragment
    )> }
  )> }
);

export type UserDeleteMutationVariables = {
  userID: Scalars['ID'],
  reason: LeaveReason,
  message?: Maybe<Scalars['String']>
};


export type UserDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'UserLeavePayload' }
    & { data: Maybe<(
      { __typename?: 'UserLeaveLog' }
      & Pick<UserLeaveLog, 'id' | 'userID' | 'reason' | 'message'>
    )> }
  ) }
);

export type GqlUserExportDataQueryVariables = {
  filterBy?: Maybe<UserFilters>,
  pagination?: Maybe<Pagination>
};


export type GqlUserExportDataQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'UserConnection' }
    & { total: UserConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'externalCompany' | 'id' | 'username' | 'name' | 'email' | 'phoneNumber' | 'birth' | 'examToTake' | 'residence' | 'createdAt' | 'lastLoginAt'>
      & { authUser: Maybe<(
        { __typename?: 'AuthUser' }
        & Pick<AuthUser, 'id'>
      )>, allianceUser: Maybe<(
        { __typename?: 'AllianceUser' }
        & Pick<AllianceUser, 'schoolName' | 'major' | 'grade' | 'studentId'>
      )> }
    )>>> }
  ) }
);

export type StudyMissionCreate_StudyQueryVariables = {
  id: Scalars['ID']
};


export type StudyMissionCreate_StudyQuery = (
  { __typename?: 'Query' }
  & { study: Maybe<(
    { __typename?: 'Study' }
    & Pick<Study, 'id' | 'title' | 'startDate' | 'endDate'>
  )> }
);

export type AlliancePermissionByCurrentUserQueryVariables = {};


export type AlliancePermissionByCurrentUserQuery = (
  { __typename?: 'Query' }
  & { permissionsByCurrentUser: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'scope'>
  )> }
);

export type GetAllianceUniversityByCurrentUserQueryVariables = {};


export type GetAllianceUniversityByCurrentUserQuery = (
  { __typename?: 'Query' }
  & { allianceByUserID: Maybe<Array<Maybe<(
    { __typename?: 'Alliance' }
    & Pick<Alliance, 'allianceName' | 'templatePath' | 'expiredAt'>
  )>>> }
);

export type CampaignsForDataExtractNewQueryVariables = {
  filterBy: CampaignFilters
};


export type CampaignsForDataExtractNewQuery = (
  { __typename?: 'Query' }
  & { campaignsForDataExtract: Maybe<(
    { __typename?: 'CampaignConnectionForDataExtract' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'name' | 'type'>
      & { data: Maybe<(
        { __typename?: 'CampaignData' }
        & Pick<CampaignData, 'category' | 'name' | 'industry' | 'etc'>
      )>, campaignUsers: Array<Maybe<(
        { __typename?: 'CampaignUser' }
        & Pick<CampaignUser, 'id' | 'joinAt' | 'name' | 'phoneNumber' | 'email' | 'allowMarketing'>
      )>> }
    )>> }
  )> }
);

export type ContentsQueryVariables = {
  editingContentIDs?: Maybe<Array<Scalars['ID']>>,
  startDate: Scalars['Date'],
  endDate: Scalars['Date']
};


export type ContentsQuery = (
  { __typename?: 'Query' }
  & { contents: Maybe<(
    { __typename?: 'ContentConnection' }
    & { nodes: Maybe<Array<(
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'type' | 'service' | 'subject'>
      & { video: Maybe<(
        { __typename?: 'VideoProduct' }
        & Pick<VideoProduct, 'id'>
        & { videoLessonProducts: (
          { __typename?: 'VideoLessonProductConnection' }
          & { nodes: Array<(
            { __typename?: 'VideoLessonProduct' }
            & Pick<VideoLessonProduct, 'id' | 'lessonNumber' | 'subject' | 'playTime'>
          )> }
        ) }
      )> }
    )>> }
  )> }
);

export type ThisYearContentReport_ContentPurchaseRankGroupFragment = (
  { __typename?: 'ContentPurchaseRankGroupConnection' }
  & ThisYearContentReportView_ContentPurchaseRankGroupFragment
);

export type LastYearContentReport_ContentPurchaseRankGroupFragment = (
  { __typename?: 'ContentPurchaseRankGroupConnection' }
  & LastYearContentReportView_ContentPurchaseRankGroupFragment
);

export type LastYearContentReportView_ContentPurchaseRankGroupFragment = (
  { __typename?: 'ContentPurchaseRankGroupConnection' }
  & { nodesOfLastYear: Array<(
    { __typename?: 'ContentPurchaseRankGroup' }
    & Pick<ContentPurchaseRankGroup, 'date'>
    & { summary: (
      { __typename?: 'ContentPurchaseRankSummary' }
      & Pick<ContentPurchaseRankSummary, 'totalSalesAmount' | 'totalSalesCount' | 'totalPlayCount' | 'totalPlayUserCount' | 'totalPlayTime'>
    ), contentPurchaseRanks: Array<(
      { __typename?: 'ContentPurchaseRank' }
      & Pick<ContentPurchaseRank, 'contentType' | 'payType' | 'salesAmount' | 'salesCount' | 'playCount' | 'playUserCount' | 'playTime' | 'rateOfOrderField'>
      & { content: (
        { __typename?: 'Content' }
        & Pick<Content, 'id' | 'subject'>
      ) }
    )> }
  )> }
);

export type ThisYearContentReportView_ContentPurchaseRankGroupFragment = (
  { __typename?: 'ContentPurchaseRankGroupConnection' }
  & { nodes: Array<(
    { __typename?: 'ContentPurchaseRankGroup' }
    & Pick<ContentPurchaseRankGroup, 'date'>
    & { summary: (
      { __typename?: 'ContentPurchaseRankSummary' }
      & Pick<ContentPurchaseRankSummary, 'totalSalesAmount' | 'totalSalesCount' | 'totalPlayCount' | 'totalPlayUserCount' | 'totalPlayTime'>
    ), contentPurchaseRanks: Array<(
      { __typename?: 'ContentPurchaseRank' }
      & Pick<ContentPurchaseRank, 'contentType' | 'payType' | 'salesAmount' | 'salesCount' | 'playCount' | 'playUserCount' | 'playTime' | 'rateOfOrderField'>
      & { content: (
        { __typename?: 'Content' }
        & Pick<Content, 'id' | 'subject'>
      ) }
    )> }
  )> }
);

export type PurchaseRankGroupContentPurchaseStatsQueryVariables = {
  startDate: Scalars['Date'],
  endDate: Scalars['Date'],
  service: ContentPurchaseRankServiceType,
  contentType: ContentPurchaseRankContentType,
  payType: ContentPurchaseRankPayType,
  field: ContentPurchaseRankOrderField,
  pageSize: Scalars['Int'],
  period: ContentPurchaseRankPeriod,
  page: Scalars['Int'],
  withPlayUserCount: Scalars['Boolean']
};


export type PurchaseRankGroupContentPurchaseStatsQuery = (
  { __typename?: 'Query' }
  & { thisYearContentPurchaseRankGroup: (
    { __typename?: 'ContentPurchaseRankGroupConnection' }
    & { nodes: Array<(
      { __typename?: 'ContentPurchaseRankGroup' }
      & Pick<ContentPurchaseRankGroup, 'date' | 'totalCount'>
      & { summary: (
        { __typename?: 'ContentPurchaseRankSummary' }
        & Pick<ContentPurchaseRankSummary, 'totalSalesAmount' | 'totalSalesCount' | 'totalPlayCount' | 'totalPlayUserCount' | 'totalPlayTime'>
      ), contentPurchaseRanks: Array<(
        { __typename?: 'ContentPurchaseRank' }
        & Pick<ContentPurchaseRank, 'contentType' | 'payType' | 'salesAmount' | 'salesCount' | 'playCount' | 'playUserCount' | 'playTime' | 'rateOfOrderField'>
        & { content: (
          { __typename?: 'Content' }
          & Pick<Content, 'id' | 'subject'>
        ) }
      )> }
    )> }
  ), lastYearContentPurchaseRankGroup: (
    { __typename?: 'ContentPurchaseRankGroupConnection' }
    & { nodesOfLastYear: Array<(
      { __typename?: 'ContentPurchaseRankGroup' }
      & Pick<ContentPurchaseRankGroup, 'date'>
      & { summary: (
        { __typename?: 'ContentPurchaseRankSummary' }
        & Pick<ContentPurchaseRankSummary, 'totalSalesAmount' | 'totalSalesCount' | 'totalPlayCount' | 'totalPlayUserCount' | 'totalPlayTime'>
      ), contentPurchaseRanks: Array<(
        { __typename?: 'ContentPurchaseRank' }
        & Pick<ContentPurchaseRank, 'contentType' | 'payType' | 'salesAmount' | 'salesCount' | 'playCount' | 'playUserCount' | 'playTime' | 'rateOfOrderField'>
        & { content: (
          { __typename?: 'Content' }
          & Pick<Content, 'id' | 'subject'>
        ) }
      )> }
    )> }
  ) }
);

export type PurchaseReportContentPurchaseStatsQueryVariables = {
  date: Scalars['Date']
};


export type PurchaseReportContentPurchaseStatsQuery = (
  { __typename?: 'Query' }
  & { totalSalesStats: (
    { __typename?: 'ContentPurchaseStatConnection' }
    & AllReport_ContentPurchaseStatsFragment
  ), weportSalesStats: (
    { __typename?: 'ContentPurchaseStatConnection' }
    & ThisYearServiceReport_ContentPurchaseStatsFragment
  ), ncsSalesStats: (
    { __typename?: 'ContentPurchaseStatConnection' }
    & ThisYearServiceReport_ContentPurchaseStatsFragment
  ), sevenGongSalesStats: (
    { __typename?: 'ContentPurchaseStatConnection' }
    & ThisYearServiceReport_ContentPurchaseStatsFragment
  ), enginicSalesStats: (
    { __typename?: 'ContentPurchaseStatConnection' }
    & ThisYearServiceReport_ContentPurchaseStatsFragment
  ), talkerbeSalesStats: (
    { __typename?: 'ContentPurchaseStatConnection' }
    & ThisYearServiceReport_ContentPurchaseStatsFragment
  ), offlineSalesStats: (
    { __typename?: 'ContentPurchaseStatConnection' }
    & ThisYearServiceReport_ContentPurchaseStatsFragment
  ), companySalesStats: (
    { __typename?: 'ContentPurchaseStatConnection' }
    & ThisYearServiceReport_ContentPurchaseStatsFragment
  ) }
);

export type AllReport_ContentPurchaseStatsFragment = (
  { __typename?: 'ContentPurchaseStatConnection' }
  & AllReportView_ContentPurchaseStatsFragment
);

export type ThisYearServiceReport_ContentPurchaseStatsFragment = (
  { __typename?: 'ContentPurchaseStatConnection' }
  & ThisYearServiceReportView_ContentPurchaseStatsFragment
);

export type LastYearServiceReport_ContentPurchaseStatsFragment = (
  { __typename?: 'ContentPurchaseStatConnection' }
  & LastYearServiceReportView_ContentPurchaseStatsFragment
);

export type AllReportView_ContentPurchaseStatsFragment = (
  { __typename?: 'ContentPurchaseStatConnection' }
  & { nodes: Array<(
    { __typename?: 'ContentPurchaseStat' }
    & Pick<ContentPurchaseStat, 'salesAmount'>
  )>, nodesOfLastYear: Array<(
    { __typename?: 'ContentPurchaseStat' }
    & Pick<ContentPurchaseStat, 'salesAmount'>
  )>, growthVSLastYear: Array<(
    { __typename?: 'ContentPurchaseStatGrowth' }
    & Pick<ContentPurchaseStatGrowth, 'salesAmountGrowthRate' | 'salesAmountGrowthAmount'>
  )>, summary: (
    { __typename?: 'ContentPurchaseStatSummary' }
    & Pick<ContentPurchaseStatSummary, 'totalSalesAmount'>
  ), yearlySummaryOfLastYear: (
    { __typename?: 'ContentPurchaseStatSummary' }
    & Pick<ContentPurchaseStatSummary, 'totalSalesAmount'>
  ), growthSummaryVSLastYear: (
    { __typename?: 'ContentPurchaseStatGrowthSummary' }
    & Pick<ContentPurchaseStatGrowthSummary, 'totalSalesAmountGrowthRate' | 'totalSalesAmountGrowthAmount'>
  ) }
);

export type LastYearServiceReportView_ContentPurchaseStatsFragment = (
  { __typename?: 'ContentPurchaseStatConnection' }
  & { nodesOfLastYear: Array<(
    { __typename?: 'ContentPurchaseStat' }
    & Pick<ContentPurchaseStat, 'salesAmount'>
  )>, yearlySummaryOfLastYear: (
    { __typename?: 'ContentPurchaseStatSummary' }
    & Pick<ContentPurchaseStatSummary, 'totalSalesAmount'>
  ) }
);

export type ThisYearServiceReportView_ContentPurchaseStatsFragment = (
  { __typename?: 'ContentPurchaseStatConnection' }
  & { nodes: Array<(
    { __typename?: 'ContentPurchaseStat' }
    & Pick<ContentPurchaseStat, 'salesAmount'>
  )>, summary: (
    { __typename?: 'ContentPurchaseStatSummary' }
    & Pick<ContentPurchaseStatSummary, 'totalSalesAmount'>
  ), nodesOfLastYear: Array<(
    { __typename?: 'ContentPurchaseStat' }
    & Pick<ContentPurchaseStat, 'salesAmount'>
  )>, yearlySummaryOfLastYear: (
    { __typename?: 'ContentPurchaseStatSummary' }
    & Pick<ContentPurchaseStatSummary, 'totalSalesAmount'>
  ), growthVSLastYear: Array<(
    { __typename?: 'ContentPurchaseStatGrowth' }
    & Pick<ContentPurchaseStatGrowth, 'salesAmountGrowthAmount'>
  )>, growthSummaryVSLastYear: (
    { __typename?: 'ContentPurchaseStatGrowthSummary' }
    & Pick<ContentPurchaseStatGrowthSummary, 'totalSalesAmountGrowthAmount'>
  ) }
);

export const BannerGroupFragmentFragmentDoc = gql`
    fragment BannerGroupFragment on BannerGroup {
  id
  codeName
  key
  service
  description
  bannerCapacity
  advisedMainImageSize
  advisedBackgroundImageSize
  advisedNavigationImageSize
  activeBanners {
    id
  }
}
    `;
export const BannerFragmentFragmentDoc = gql`
    fragment BannerFragment on Banner {
  id
  title
  slideLabel
  mainImage {
    id
    url
  }
  backgroundImage {
    id
    url
  }
  activateNavigationImage {
    id
    url
  }
  deactivateNavigationImage {
    id
    url
  }
  url
  backgroundColor
  click
  status
  priority
  url
  bannerGroup {
    id
  }
  createdAt
  updatedAt
}
    `;
export const BookFragmentFragmentDoc = gql`
    fragment BookFragment on Book {
  id
  name
  authorName
  year
  description
  studyLink
  link
  content {
    id
    price
    subject
    tag
    visibility
    image {
      id
      url
    }
  }
  expectedQuestionSet {
    id
  }
  mockExams {
    id
  }
  mockExamTargetCompanies {
    id
  }
  examDescription
  createdAt
}
    `;
export const CampaignFragmentFragmentDoc = gql`
    fragment CampaignFragment on Campaign {
  id
  createdAt
  startAt
  endAt
  name
  noticeTitle
}
    `;
export const CampaignDetailFragmentFragmentDoc = gql`
    fragment CampaignDetailFragment on Campaign {
  ...CampaignFragment
  hasUsers
  data {
    category
    name
    industry
    etc
  }
}
    ${CampaignFragmentFragmentDoc}`;
export const CompanyFragmentFragmentDoc = gql`
    fragment CompanyFragment on Company {
  id
  name
  createdAt
}
    `;
export const ContentCurationFragmentFragmentDoc = gql`
    fragment ContentCurationFragment on ContentCuration {
  id
  name
  code
  contents {
    id
  }
  createdAt
  updatedAt
}
    `;
export const ContentPurchaseUserFragmentDoc = gql`
    fragment ContentPurchaseUser on User {
  id
  name
  username
  phoneNumber
  email
  smsReceipt
  emailReceipt
}
    `;
export const ContentPurchaseContentFragmentDoc = gql`
    fragment ContentPurchaseContent on Content {
  id
  subject
  active
  keywords
}
    `;
export const ContentPurchasePaymentFragmentDoc = gql`
    fragment ContentPurchasePayment on Payment {
  id
  amount
  paymentMethod
  transactionID
  paidAt
}
    `;
export const ContentPurchaseOrderFragmentDoc = gql`
    fragment ContentPurchaseOrder on Order {
  id
  status
  createdAt
  payment {
    ...ContentPurchasePayment
  }
}
    ${ContentPurchasePaymentFragmentDoc}`;
export const ContentPurchaseFragmentFragmentDoc = gql`
    fragment ContentPurchaseFragment on ContentPurchase {
  id
  price
  createdAt
  expiredAt
  price
  pointDiscount
  couponDiscount
  user {
    ...ContentPurchaseUser
  }
  content {
    ...ContentPurchaseContent
  }
  order {
    ...ContentPurchaseOrder
  }
}
    ${ContentPurchaseUserFragmentDoc}
${ContentPurchaseContentFragmentDoc}
${ContentPurchaseOrderFragmentDoc}`;
export const ContentFragmentFragmentDoc = gql`
    fragment ContentFragment on Content {
  id
  subject
  type
  service
  salesPeriod
  active
  priority
  keywords
  visibility
  includesInvoice
}
    `;
export const CouponNumberFragmentFragmentDoc = gql`
    fragment CouponNumberFragment on CouponNumber {
  id
  number
}
    `;
export const CouponFragmentFragmentDoc = gql`
    fragment CouponFragment on Coupon {
  id
  name
  type
}
    `;
export const CouponDetailFragmentFragmentDoc = gql`
    fragment CouponDetailFragment on Coupon {
  ...CouponFragment
  couponNumbers {
    id
    number
    connectedContents {
      id
      content {
        id
        subject
        type
      }
    }
  }
}
    ${CouponFragmentFragmentDoc}`;
export const EventCampaignDetailFragmentFragmentDoc = gql`
    fragment EventCampaignDetailFragment on Campaign {
  ...CampaignFragment
  pcLink
  mobileLink
  communityPostURL
  possibleDuplicated
  service
  target
  isForBraze
  data {
    category
    name
    industry
    etc
  }
  campaignBenefits {
    id
    benefitType
    benefit
    name
    isActive
  }
}
    ${CampaignFragmentFragmentDoc}`;
export const ExamAttemptFragmentFragmentDoc = gql`
    fragment ExamAttemptFragment on ExamAttempt {
  id
  event {
    exam {
      id
    }
  }
  user {
    username
    createdAt
  }
  tempAccount {
    name
    phoneNumber
  }
  targetCompany {
    id
  }
  resultsBySection {
    section {
      id
      name
    }
    score
  }
  score
  createdAt
}
    `;
export const ExamQuestionTypeFragmentFragmentDoc = gql`
    fragment ExamQuestionTypeFragment on ExamQuestionType {
  id
  name
}
    `;
export const ExamQuestionFragmentFragmentDoc = gql`
    fragment ExamQuestionFragment on ExamQuestion {
  id
  number
  type {
    id
    name
  }
  question {
    id
    questionField1
    questionField2
    explanation
    answer
  }
}
    `;
export const ExamSectionFragmentFragmentDoc = gql`
    fragment ExamSectionFragment on ExamSection {
  id
  name
  averageScore
  prevScoresForPass {
    seasonName
    score
  }
  questions {
    totalCount
  }
  examQuestionTypes {
    id
    name
  }
}
    `;
export const ExamFragmentFragmentDoc = gql`
    fragment ExamFragment on Exam {
  id
  name
  content {
    id
    price
    expireDays
  }
  attempts {
    totalCount
  }
  defaultEvent {
    id
  }
  events {
    id
    exam {
      name
    }
    createdAt
  }
  createdAt
  type
}
    `;
export const ExamDetailFragmentFragmentDoc = gql`
    fragment ExamDetailFragment on Exam {
  ...ExamFragment
  examQuestions {
    totalCount
  }
  sections {
    id
    name
    prevScoresForPass {
      seasonName
      score
    }
    examQuestionTypes {
      id
      name
    }
  }
  reportIsReady
}
    ${ExamFragmentFragmentDoc}`;
export const InvoiceFragmentFragmentDoc = gql`
    fragment InvoiceFragment on Invoice {
  id
  freepassNetSalesAmount
  contentGrossSalesAmount
  contentPGCommissionAmount
  contentNetSalesAmount
  refundPriceAmount
  refundPGCommissionAmount
  netRefundsAmount
  paidAmount
  incomeTax
  localTax
  totalTax
  totalNetAmount
  teacher {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const InvoiceDetailFragmentFragmentDoc = gql`
    fragment InvoiceDetailFragment on Invoice {
  ...InvoiceFragment
  freepassInvoice {
    playtime
    netSales
    service
    subject
  }
  salesByContent {
    content {
      id
      service
      subject
      price
    }
    quantity
    teacherContractRate
    teacherContentParticipationRate
    pgCommission
    grossSales
    netSales
  }
  refundsByContent {
    content {
      id
      service
      subject
      price
    }
    refundPrice
    netRefund
    quantity
    pgCommission
    teacherContractRate
    teacherContentParticipationRate
  }
}
    ${InvoiceFragmentFragmentDoc}`;
export const MembershipFragmentFragmentDoc = gql`
    fragment MembershipFragment on Membership {
  id
  team {
    id
  }
  user {
    id
  }
  role
  createdAt
}
    `;
export const MenuFragmentFragmentDoc = gql`
    fragment MenuFragment on Menu {
  id
  code
  title
  description
  createdAt
}
    `;
export const MenuDetailFragmentFragmentDoc = gql`
    fragment MenuDetailFragment on Menu {
  ...MenuFragment
  items {
    label
    open
    linkURL
    children {
      label
      open
      linkURL
      children {
        label
        open
        linkURL
        children {
          label
          open
          linkURL
        }
      }
    }
  }
}
    ${MenuFragmentFragmentDoc}`;
export const OfflineContentDetailContentFragmentFragmentDoc = gql`
    fragment OfflineContentDetailContentFragment on Content {
  id
  service
  subject
  active
  visibility
  image {
    id
    url
  }
  teachers {
    id
    name
  }
  teacherCommissions {
    teacherID
    partialContract
    totalContract
  }
  studentCount
  maxSellableCount
  saleStatus
  categoryParents {
    name
    childrenCategories {
      id
      name
      isSelected
    }
  }
  contentPurchases {
    nodes {
      id
      user {
        id
        name
        phoneNumber
        email
      }
    }
  }
  maxSellableCount
  studentCount
  originalPrice
  price
  saleStatus
  selectedCategoryIDs
}
    `;
export const OfflineContentFragmentFragmentDoc = gql`
    fragment OfflineContentFragment on Offline {
  id
  courseStartDate
  courseEndDate
  descriptionHTML
  cautionHTML
  offlineRepurchaseDiscounts {
    discountType
    discountValue
    targetOffline {
      id
      courseStartDate
      courseEndDate
      content {
        id
        subject
        teachers {
          id
          name
        }
      }
    }
    isDeleted
  }
  defaultTeacherForImage {
    id
    offlineLectureThumbnailImage {
      id
      url
    }
  }
  shortDescription
  usePayback
  weekday
  classStartTime
  classEndTime
  tags
  useMessage
  children {
    id
    courseStartDate
    courseEndDate
    classStartTime
    classEndTime
    content {
      id
      service
      subject
      studentCount
      teachers {
        id
        name
      }
    }
  }
  content {
    ...OfflineContentDetailContentFragment
  }
}
    ${OfflineContentDetailContentFragmentFragmentDoc}`;
export const OrderFragmentFragmentDoc = gql`
    fragment OrderFragment on Order {
  id
  createdAt
  contentPurchase {
    id
    content {
      id
      subject
    }
    price
    pointDiscount
    couponDiscount
    expiredAt
  }
  payment {
    id
    amount
    state
    paymentMethod
    transactionID
  }
  refund {
    id
    createdAt
    amount
    message
  }
}
    `;
export const PaymentStateFragmentDoc = gql`
    fragment PaymentState on Payment {
  id
  transactionID
  state
}
    `;
export const PaymentOrderFragmentFragmentDoc = gql`
    fragment PaymentOrderFragment on Order {
  refund {
    createdAt
    amount
    message
  }
}
    `;
export const PaymentRefunndFragmentFragmentDoc = gql`
    fragment PaymentRefunndFragment on Refund {
  id
  createdAt
  amount
  message
}
    `;
export const PaymentFragmentFragmentDoc = gql`
    fragment PaymentFragment on Payment {
  id
  paymentMethod
  amount
  order {
    ...PaymentOrderFragment
  }
  refund {
    ...PaymentRefunndFragment
  }
}
    ${PaymentOrderFragmentFragmentDoc}
${PaymentRefunndFragmentFragmentDoc}`;
export const PermissionFragmentFragmentDoc = gql`
    fragment PermissionFragment on Permission {
  id
  scope
  grantedAt
}
    `;
export const PostCurationFragmentFragmentDoc = gql`
    fragment PostCurationFragment on PostCuration {
  id
  code
  title
  boardURL
  boards {
    id
    title
  }
  maxPostLimit
  configs {
    isAuto
    category
    title
    postID
    orderBy
    sourceBoardID
  }
}
    `;
export const RefundFragmentFragmentDoc = gql`
    fragment RefundFragment on Refund {
  id
  amount
  createdAt
  message
}
    `;
export const ResumeProductFragmentFragmentDoc = gql`
    fragment ResumeProductFragment on ResumeProduct {
  id
  content {
    id
    subject
    service
    salesPeriod
    active
    priority
    visibility
    teachers {
      id
      name
    }
    price
    expireDays
  }
  defaultCorrectionTime
  caution
  isAvailableInHoliday
  isAvailableInWeekend
  correctionLimitType
  maxBuyableLimitPerDay
  dailyQuantityLeft
  isAvailableToSale
  introductionImageFile {
    id
    url
  }
  createdAt
}
    `;
export const ResumeFragmentFragmentDoc = gql`
    fragment ResumeFragment on Resume {
  id
  user {
    username
    name
  }
  wishCompany {
    name
  }
  correctionStep
  writtenAt
  reviewedAt
  options {
    category
    name
  }
  resumeProduct {
    content {
      teachers {
        name
      }
    }
    correctionLimitType
  }
}
    `;
export const ResumeDetailFragmentFragmentDoc = gql`
    fragment ResumeDetailFragment on Resume {
  ...ResumeFragment
  school {
    schoolName
    campusName
  }
  major {
    name
  }
  userGPA
  maxGPA
  resumeText
  resumeFile {
    id
    url
  }
  correctionFile {
    id
    url
  }
  correctionHTML
  languageTestSubject
  languageTestScore
  willOpen
  reviewedAt
  isCompanyAccepted
  successfulCandidateReward
  resumeHistory {
    id
    user {
      username
      name
    }
    wishCompany {
      name
    }
    writtenAt
    reviewedAt
    resumeProduct {
      content {
        teachers {
          name
        }
      }
    }
  }
}
    ${ResumeFragmentFragmentDoc}`;
export const ReviewFragmentFragmentDoc = gql`
    fragment ReviewFragment on Review {
  id
  subject
  bodyHTML
  author {
    id
    email
    name
    username
  }
  isPrivate
  score
  content {
    id
    active
    service
    subject
    teachers {
      id
      name
    }
  }
  createdAt
}
    `;
export const ScheduleEventFragmentFragmentDoc = gql`
    fragment ScheduleEventFragment on ScheduleEvent {
  id
  scheduleID
  sequence
  startDate
  endDate
  useAutoUpdate
  autoUpdateWeekday
  autoUpdateEndDate
  description
}
    `;
export const ScheduleFragmentFragmentDoc = gql`
    fragment ScheduleFragment on Schedule {
  id
  title
}
    `;
export const StudyBenefitFragmentFragmentDoc = gql`
    fragment StudyBenefitFragment on StudyBenefit {
  id
  benefitType
  benefit {
    key
    value
  }
}
    `;
export const StudyEventFragmentFragmentDoc = gql`
    fragment StudyEventFragment on StudyEvent {
  id
  sequence
  startDate
  endDate
}
    `;
export const StudyLectureMissionFragmentFragmentDoc = gql`
    fragment StudyLectureMissionFragment on StudyMission {
  id
  title
  missionType
  missionDetails {
    ... on LectureMission {
      content {
        id
        subject
        type
      }
    }
  }
  studyEvent {
    id
    sequence
    startDate
    endDate
    study {
      id
      title
      startDate
      endDate
    }
  }
  dueDateStartAt
  dueDateEndAt
  isMutable
}
    `;
export const StudyLinkMissionFragmentFragmentDoc = gql`
    fragment StudyLinkMissionFragment on StudyMission {
  id
  title
  missionType
  missionDetails {
    ... on AssignmentLinkMission {
      useUrlCheck
      siteName
      boardName
      boardUrl
      hashTags
      minLinkCountLimit
    }
  }
  studyEvent {
    id
    sequence
    startDate
    endDate
    study {
      id
      title
      startDate
      endDate
    }
  }
  dueDateStartAt
  dueDateEndAt
  isMutable
}
    `;
export const StudyReviewMissionFragmentFragmentDoc = gql`
    fragment StudyReviewMissionFragment on StudyMission {
  id
  title
  missionType
  missionDetails {
    ... on ReviewMission {
      useUrlCheck
      siteName
      boardName
      boardUrl
      hashTags
      minLinkCountLimit
    }
  }
  studyEvent {
    id
    sequence
    startDate
    endDate
    study {
      id
      title
      startDate
      endDate
    }
  }
  dueDateStartAt
  dueDateEndAt
  isMutable
}
    `;
export const StudyFragmentFragmentDoc = gql`
    fragment StudyFragment on Study {
  id
  title
  service
  startDate
  endDate
  recruitPeriodStartDate
  recruitPeriodEndDate
  createdAt
  isDuplicateAllow
  participantsNumber
  participantsLimit
  participantsLimitNumber
  brazeEventProperties {
    studyClassification
    studyName
    studyCompany
  }
  duplicateRestrictStudies {
    id
  }
  pcLink
  mobileLink
  communityPostLink
  preNoticeUrl
  noticeLink
  previousLink
  completeBody
  events {
    nodes {
      id
      sequence
      startDate
      endDate
    }
  }
  isPrivate
}
    `;
export const FullStudyBenefitFragmentFragmentDoc = gql`
    fragment FullStudyBenefitFragment on StudyBenefit {
  id
  benefitType
  studyEvent {
    id
    sequence
    startDate
    endDate
  }
  benefit {
    key
    value
  }
  provideDate
}
    `;
export const FullStudyEventFragmentFragmentDoc = gql`
    fragment FullStudyEventFragment on StudyEvent {
  id
  sequence
  endDate
  benefits {
    ...FullStudyBenefitFragment
  }
}
    ${FullStudyBenefitFragmentFragmentDoc}`;
export const FullStudyFragmentFragmentDoc = gql`
    fragment FullStudyFragment on Study {
  id
  title
  service
  startDate
  endDate
  createdAt
  isDuplicateAllow
  participantsLimit
  participantsLimitNumber
  previousLink
  completeBody
  events {
    nodes {
      ...FullStudyEventFragment
    }
  }
  isPrivate
}
    ${FullStudyEventFragmentFragmentDoc}`;
export const SupportTicketAnswerTemplateFragmentFragmentDoc = gql`
    fragment SupportTicketAnswerTemplateFragment on SupportTicketAnswerTemplate {
  id
  subject
  isActive
  descriptionHTML
}
    `;
export const SupportTicketCategoryFragmentFragmentDoc = gql`
    fragment SupportTicketCategoryFragment on SupportTicketCategory {
  id
  name
  parentID
  isActive
}
    `;
export const SupportTicketUserFragmentDoc = gql`
    fragment SupportTicketUser on User {
  id
  username
  name
}
    `;
export const SupportTicketFragmentFragmentDoc = gql`
    fragment SupportTicketFragment on SupportTicket {
  id
  createdAt
  updatedAt
  repliedAt
  service
  writer {
    ...SupportTicketUser
  }
  writerName
  category {
    id
    name
  }
  file {
    url
  }
  subject
  bodyHTML
  processState
  replyUser {
    username
  }
  reply
  replyFile {
    url
  }
  ticketType
}
    ${SupportTicketUserFragmentDoc}`;
export const TeacherCurationFragmentFragmentDoc = gql`
    fragment TeacherCurationFragment on TeacherCuration {
  id
  name
  code
  teachers {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const TeacherFragmentFragmentDoc = gql`
    fragment TeacherFragment on Teacher {
  id
  name
  priority
  user {
    id
    username
  }
  isActive
}
    `;
export const TeacherDetailFragmentFragmentDoc = gql`
    fragment TeacherDetailFragment on Teacher {
  ...TeacherFragment
  includeInvoice
  aboutDetailImage {
    id
    url
  }
  aboutListImage {
    id
    url
  }
  aboutRollingImage {
    id
    url
  }
  mainPageImage {
    id
    url
  }
  offlineLectureThumbnailImage {
    id
    url
  }
  aboutListText
  mobileMainImage {
    id
    url
  }
  mobileThumbnailImage {
    id
    url
  }
  mobileDescriptionTitle
  mobileDescriptionDetail
  contactURL
  videoListImage {
    id
    url
  }
  videoListText
  mainImageLink
}
    ${TeacherFragmentFragmentDoc}`;
export const TeamFragmentFragmentDoc = gql`
    fragment TeamFragment on Team {
  id
  name
  members {
    totalCount
  }
  createdAt
}
    `;
export const UserDeviceFragmentFragmentDoc = gql`
    fragment UserDeviceFragment on UserDevice {
  id
  createdAt
  userID
  deviceID
  deviceName
  deviceNickName
  isForFreepass
  playerType
  deviceNickNameUpdatedAt
  isDeleted
  isSystem
}
    `;
export const UserStudyEventFragmentFragmentDoc = gql`
    fragment UserStudyEventFragment on UserStudyEvent {
  id
  user {
    id
    name
    username
    phoneNumber
    createdAt
  }
  state
  createdAt
  study {
    id
    title
    startDate
    endDate
  }
  userStudy {
    userStudyEvents {
      id
      state
      studyEvent {
        sequence
        startDate
        endDate
      }
      userStudyMissionsAndContents {
        userStudyMissions {
          id
          studyMission {
            dueDateStartAt
            dueDateEndAt
            missionDetails {
              ... on AssignmentFileMission {
                useTemplate
                templateFile {
                  id
                  url
                  name
                  mimeType
                }
              }
              ... on AssignmentLinkMission {
                useUrlCheck
                minLinkCountLimit
              }
              ... on LectureMission {
                content {
                  id
                  subject
                }
                standardProgressRate
              }
              ... on ExamMission {
                content {
                  id
                  subject
                }
                standardScore
              }
              ... on ReviewMission {
                useUrlCheck
                minLinkCountLimit
              }
            }
          }
          state
          userStudyMissionDetails {
            ... on UserStudyMissionAssignmentFile {
              file {
                id
                url
                name
                mimeType
              }
            }
            ... on UserStudyMissionLecture {
              completedVideoLessonCount
            }
            ... on UserStudyMissionExam {
              score
            }
            ... on UserStudyMissionAssignmentLink {
              link
            }
            ... on UserStudyMissionReview {
              link
            }
          }
        }
      }
    }
  }
}
    `;
export const UserStudyMissionFragmentFragmentDoc = gql`
    fragment UserStudyMissionFragment on UserStudyMission {
  createdAt
  studyMission {
    studyEvent {
      sequence
    }
    dueDateStartAt
    dueDateEndAt
  }
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  username
  name
  email
  phoneNumber
  createdAt
  authUser {
    id
  }
  externalCompany
  joinRoute
  allianceUser {
    schoolType
    schoolName
    major
    grade
    schoolId
    studentId
  }
}
    `;
export const UserDetailFragmentFragmentDoc = gql`
    fragment UserDetailFragment on User {
  ...UserFragment
  lastLoginAt
  emailReceipt
  smsReceipt
  birth
  subjectCurriculum
  examToTake
  residence
  order {
    id
    contentPurchase {
      id
      content {
        id
        subject
      }
    }
  }
  devices {
    id
    createdAt
    deviceID
    deviceName
    deviceNickName
    isForFreepass
    isDeleted
  }
  examSubject {
    id
    category
    examToTake
    subject
  }
}
    ${UserFragmentFragmentDoc}`;
export const ThisYearContentReportView_ContentPurchaseRankGroupFragmentDoc = gql`
    fragment ThisYearContentReportView_ContentPurchaseRankGroup on ContentPurchaseRankGroupConnection {
  nodes {
    date
    summary {
      totalSalesAmount
      totalSalesCount
      totalPlayCount
      totalPlayUserCount
      totalPlayTime(unit: HOURS)
    }
    contentPurchaseRanks {
      contentType
      content {
        id
        subject
      }
      payType
      salesAmount
      salesCount
      playCount
      playUserCount
      playTime(unit: HOURS)
      rateOfOrderField
    }
  }
}
    `;
export const ThisYearContentReport_ContentPurchaseRankGroupFragmentDoc = gql`
    fragment ThisYearContentReport_ContentPurchaseRankGroup on ContentPurchaseRankGroupConnection {
  ...ThisYearContentReportView_ContentPurchaseRankGroup
}
    ${ThisYearContentReportView_ContentPurchaseRankGroupFragmentDoc}`;
export const LastYearContentReportView_ContentPurchaseRankGroupFragmentDoc = gql`
    fragment LastYearContentReportView_ContentPurchaseRankGroup on ContentPurchaseRankGroupConnection {
  nodesOfLastYear {
    date
    summary {
      totalSalesAmount
      totalSalesCount
      totalPlayCount
      totalPlayUserCount
      totalPlayTime(unit: HOURS)
    }
    contentPurchaseRanks {
      contentType
      content {
        id
        subject
      }
      payType
      salesAmount
      salesCount
      playCount
      playUserCount
      playTime(unit: HOURS)
      rateOfOrderField
    }
  }
}
    `;
export const LastYearContentReport_ContentPurchaseRankGroupFragmentDoc = gql`
    fragment LastYearContentReport_ContentPurchaseRankGroup on ContentPurchaseRankGroupConnection {
  ...LastYearContentReportView_ContentPurchaseRankGroup
}
    ${LastYearContentReportView_ContentPurchaseRankGroupFragmentDoc}`;
export const AllReportView_ContentPurchaseStatsFragmentDoc = gql`
    fragment AllReportView_ContentPurchaseStats on ContentPurchaseStatConnection {
  nodes {
    salesAmount
  }
  nodesOfLastYear {
    salesAmount
  }
  growthVSLastYear {
    salesAmountGrowthRate
    salesAmountGrowthAmount
  }
  growthVSLastYear {
    salesAmountGrowthAmount
  }
  summary {
    totalSalesAmount
  }
  yearlySummaryOfLastYear {
    totalSalesAmount
  }
  growthSummaryVSLastYear {
    totalSalesAmountGrowthRate
    totalSalesAmountGrowthAmount
  }
  growthSummaryVSLastYear {
    totalSalesAmountGrowthAmount
  }
}
    `;
export const AllReport_ContentPurchaseStatsFragmentDoc = gql`
    fragment AllReport_ContentPurchaseStats on ContentPurchaseStatConnection {
  ...AllReportView_ContentPurchaseStats
}
    ${AllReportView_ContentPurchaseStatsFragmentDoc}`;
export const ThisYearServiceReportView_ContentPurchaseStatsFragmentDoc = gql`
    fragment ThisYearServiceReportView_ContentPurchaseStats on ContentPurchaseStatConnection {
  nodes {
    salesAmount
  }
  summary {
    totalSalesAmount
  }
  nodesOfLastYear {
    salesAmount
  }
  yearlySummaryOfLastYear {
    totalSalesAmount
  }
  growthVSLastYear {
    salesAmountGrowthAmount
  }
  growthSummaryVSLastYear {
    totalSalesAmountGrowthAmount
  }
}
    `;
export const ThisYearServiceReport_ContentPurchaseStatsFragmentDoc = gql`
    fragment ThisYearServiceReport_ContentPurchaseStats on ContentPurchaseStatConnection {
  ...ThisYearServiceReportView_ContentPurchaseStats
}
    ${ThisYearServiceReportView_ContentPurchaseStatsFragmentDoc}`;
export const LastYearServiceReportView_ContentPurchaseStatsFragmentDoc = gql`
    fragment LastYearServiceReportView_ContentPurchaseStats on ContentPurchaseStatConnection {
  nodesOfLastYear {
    salesAmount
  }
  yearlySummaryOfLastYear {
    totalSalesAmount
  }
}
    `;
export const LastYearServiceReport_ContentPurchaseStatsFragmentDoc = gql`
    fragment LastYearServiceReport_ContentPurchaseStats on ContentPurchaseStatConnection {
  ...LastYearServiceReportView_ContentPurchaseStats
}
    ${LastYearServiceReportView_ContentPurchaseStatsFragmentDoc}`;
export const GqlAllianceUserContentDocument = gql`
    query gqlAllianceUserContent($startDate: Date!, $endDate: Date!, $allianceName: String!) {
  allianceUserContentHistorys(filterBy: {startDate: $startDate, endDate: $endDate, allianceName: $allianceName}) {
    nodes {
      id
      name
      major
      grade
      lastLogin
      contentName
      createdAt
      serviceType
      contentType
      contentCategoryType
      contentPrice
    }
  }
}
    `;

/**
 * __useGqlAllianceUserContentQuery__
 *
 * To run a query within a React component, call `useGqlAllianceUserContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlAllianceUserContentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlAllianceUserContentQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      allianceName: // value for 'allianceName'
 *   },
 * });
 */
export function useGqlAllianceUserContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlAllianceUserContentQuery, GqlAllianceUserContentQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlAllianceUserContentQuery, GqlAllianceUserContentQueryVariables>(GqlAllianceUserContentDocument, baseOptions);
      }
export function useGqlAllianceUserContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlAllianceUserContentQuery, GqlAllianceUserContentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlAllianceUserContentQuery, GqlAllianceUserContentQueryVariables>(GqlAllianceUserContentDocument, baseOptions);
        }
export type GqlAllianceUserContentQueryHookResult = ReturnType<typeof useGqlAllianceUserContentQuery>;
export type GqlAllianceUserContentLazyQueryHookResult = ReturnType<typeof useGqlAllianceUserContentLazyQuery>;
export type GqlAllianceUserContentQueryResult = ApolloReactCommon.QueryResult<GqlAllianceUserContentQuery, GqlAllianceUserContentQueryVariables>;
export const GqlCurrentUserDocument = gql`
    query gqlCurrentUser {
  currentUser {
    id
    name
    allianceUser {
      id
      schoolId
      schoolName
    }
  }
}
    `;

/**
 * __useGqlCurrentUserQuery__
 *
 * To run a query within a React component, call `useGqlCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGqlCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlCurrentUserQuery, GqlCurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlCurrentUserQuery, GqlCurrentUserQueryVariables>(GqlCurrentUserDocument, baseOptions);
      }
export function useGqlCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCurrentUserQuery, GqlCurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlCurrentUserQuery, GqlCurrentUserQueryVariables>(GqlCurrentUserDocument, baseOptions);
        }
export type GqlCurrentUserQueryHookResult = ReturnType<typeof useGqlCurrentUserQuery>;
export type GqlCurrentUserLazyQueryHookResult = ReturnType<typeof useGqlCurrentUserLazyQuery>;
export type GqlCurrentUserQueryResult = ApolloReactCommon.QueryResult<GqlCurrentUserQuery, GqlCurrentUserQueryVariables>;
export const GqlSalesStatsDocument = gql`
    query gqlSalesStats($startDate: Date!, $endDate: Date!, $period: StatPeriod!, $pagination: Pagination) {
  servicePurchaseStats(filterBy: {startDate: $startDate, endDate: $endDate, period: $period}, pagination: $pagination) {
    nodes {
      startDate
      endDate
      nodes {
        salesAmount
        service
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGqlSalesStatsQuery__
 *
 * To run a query within a React component, call `useGqlSalesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlSalesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlSalesStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      period: // value for 'period'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGqlSalesStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlSalesStatsQuery, GqlSalesStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlSalesStatsQuery, GqlSalesStatsQueryVariables>(GqlSalesStatsDocument, baseOptions);
      }
export function useGqlSalesStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlSalesStatsQuery, GqlSalesStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlSalesStatsQuery, GqlSalesStatsQueryVariables>(GqlSalesStatsDocument, baseOptions);
        }
export type GqlSalesStatsQueryHookResult = ReturnType<typeof useGqlSalesStatsQuery>;
export type GqlSalesStatsLazyQueryHookResult = ReturnType<typeof useGqlSalesStatsLazyQuery>;
export type GqlSalesStatsQueryResult = ApolloReactCommon.QueryResult<GqlSalesStatsQuery, GqlSalesStatsQueryVariables>;
export const GqlAlliancesDocument = gql`
    query gqlAlliances($filterBy: AllianceFilters) {
  alliances(filterBy: $filterBy) {
    nodes {
      id
      allianceName
      createdAt
      expiredAt
      prefixUserName
      templatePath
      domain
    }
  }
}
    `;

/**
 * __useGqlAlliancesQuery__
 *
 * To run a query within a React component, call `useGqlAlliancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlAlliancesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlAlliancesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGqlAlliancesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlAlliancesQuery, GqlAlliancesQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlAlliancesQuery, GqlAlliancesQueryVariables>(GqlAlliancesDocument, baseOptions);
      }
export function useGqlAlliancesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlAlliancesQuery, GqlAlliancesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlAlliancesQuery, GqlAlliancesQueryVariables>(GqlAlliancesDocument, baseOptions);
        }
export type GqlAlliancesQueryHookResult = ReturnType<typeof useGqlAlliancesQuery>;
export type GqlAlliancesLazyQueryHookResult = ReturnType<typeof useGqlAlliancesLazyQuery>;
export type GqlAlliancesQueryResult = ApolloReactCommon.QueryResult<GqlAlliancesQuery, GqlAlliancesQueryVariables>;
export const GqlUserAllianceStatsDocument = gql`
    query gqlUserAllianceStats($date: Date!, $endDate: Date, $period: StatPeriod!, $category: String!, $pagination: Pagination!) {
  userAllianceStats(filterBy: {date: $date, endDate: $endDate, period: $period, category: $category, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
      userVisitCount
      userVideoCount
      userDocumentCount
      userExamCount
    }
    total
  }
}
    `;

/**
 * __useGqlUserAllianceStatsQuery__
 *
 * To run a query within a React component, call `useGqlUserAllianceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlUserAllianceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlUserAllianceStatsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      endDate: // value for 'endDate'
 *      period: // value for 'period'
 *      category: // value for 'category'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGqlUserAllianceStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlUserAllianceStatsQuery, GqlUserAllianceStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlUserAllianceStatsQuery, GqlUserAllianceStatsQueryVariables>(GqlUserAllianceStatsDocument, baseOptions);
      }
export function useGqlUserAllianceStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlUserAllianceStatsQuery, GqlUserAllianceStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlUserAllianceStatsQuery, GqlUserAllianceStatsQueryVariables>(GqlUserAllianceStatsDocument, baseOptions);
        }
export type GqlUserAllianceStatsQueryHookResult = ReturnType<typeof useGqlUserAllianceStatsQuery>;
export type GqlUserAllianceStatsLazyQueryHookResult = ReturnType<typeof useGqlUserAllianceStatsLazyQuery>;
export type GqlUserAllianceStatsQueryResult = ApolloReactCommon.QueryResult<GqlUserAllianceStatsQuery, GqlUserAllianceStatsQueryVariables>;
export const GqlServiceTypeSignUpStatsDocument = gql`
    query gqlServiceTypeSignUpStats($date: Date!, $endDate: Date, $period: StatPeriod!, $pagination: Pagination!) {
  signUpUser: userSignupStats(filterBy: {category: TOTALWITHOUTALLIANCE, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
  weportSignUpUser: userSignupStats(filterBy: {category: EXAM_TO_TAKE_WEPORT, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
  ncsSignUpUser: userSignupStats(filterBy: {category: EXAM_TO_TAKE_NCS, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
  sevenGongSignUpUser: userSignupStats(filterBy: {category: EXAM_TO_TAKE_7GONG, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
  enginicSignUpUser: userSignupStats(filterBy: {category: EXAM_TO_TAKE_ENGINIC, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
  talkerbeSignUpUser: userSignupStats(filterBy: {category: EXAM_TO_TAKE_TOSOPIC, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
  weportEngicSignUpUser: userSignupStats(filterBy: {category: EXAM_TO_TAKE_WEPORT_ENGINIC, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
}
    `;

/**
 * __useGqlServiceTypeSignUpStatsQuery__
 *
 * To run a query within a React component, call `useGqlServiceTypeSignUpStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlServiceTypeSignUpStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlServiceTypeSignUpStatsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      endDate: // value for 'endDate'
 *      period: // value for 'period'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGqlServiceTypeSignUpStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlServiceTypeSignUpStatsQuery, GqlServiceTypeSignUpStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlServiceTypeSignUpStatsQuery, GqlServiceTypeSignUpStatsQueryVariables>(GqlServiceTypeSignUpStatsDocument, baseOptions);
      }
export function useGqlServiceTypeSignUpStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlServiceTypeSignUpStatsQuery, GqlServiceTypeSignUpStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlServiceTypeSignUpStatsQuery, GqlServiceTypeSignUpStatsQueryVariables>(GqlServiceTypeSignUpStatsDocument, baseOptions);
        }
export type GqlServiceTypeSignUpStatsQueryHookResult = ReturnType<typeof useGqlServiceTypeSignUpStatsQuery>;
export type GqlServiceTypeSignUpStatsLazyQueryHookResult = ReturnType<typeof useGqlServiceTypeSignUpStatsLazyQuery>;
export type GqlServiceTypeSignUpStatsQueryResult = ApolloReactCommon.QueryResult<GqlServiceTypeSignUpStatsQuery, GqlServiceTypeSignUpStatsQueryVariables>;
export const GqlUserSignupStatsDocument = gql`
    query gqlUserSignupStats($date: Date!, $endDate: Date, $period: StatPeriod!, $pagination: Pagination!) {
  totalSignUpUser: userSignupStats(filterBy: {category: TOTAL, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
  signUpUser: userSignupStats(filterBy: {category: TOTALWITHOUTALLIANCE, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
  allianceSignUpUser: userSignupStats(filterBy: {category: ALLIANCE, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
    total
  }
  userVisitStats: userVisitStats(filterBy: {category: TOTAL, date: $date, endDate: $endDate, period: $period, pagination: $pagination}) {
    nodes {
      startDate
      userRegisterCount
    }
  }
}
    `;

/**
 * __useGqlUserSignupStatsQuery__
 *
 * To run a query within a React component, call `useGqlUserSignupStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlUserSignupStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlUserSignupStatsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      endDate: // value for 'endDate'
 *      period: // value for 'period'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGqlUserSignupStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlUserSignupStatsQuery, GqlUserSignupStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlUserSignupStatsQuery, GqlUserSignupStatsQueryVariables>(GqlUserSignupStatsDocument, baseOptions);
      }
export function useGqlUserSignupStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlUserSignupStatsQuery, GqlUserSignupStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlUserSignupStatsQuery, GqlUserSignupStatsQueryVariables>(GqlUserSignupStatsDocument, baseOptions);
        }
export type GqlUserSignupStatsQueryHookResult = ReturnType<typeof useGqlUserSignupStatsQuery>;
export type GqlUserSignupStatsLazyQueryHookResult = ReturnType<typeof useGqlUserSignupStatsLazyQuery>;
export type GqlUserSignupStatsQueryResult = ApolloReactCommon.QueryResult<GqlUserSignupStatsQuery, GqlUserSignupStatsQueryVariables>;
export const GqlMajorListDocument = gql`
    query gqlMajorList($schoolName: String) {
  majorFromUserSchools(filterBy: {schoolName: $schoolName}, orderBy: {direction: ASC, field: MAJOR}, pagination: {page: 1, pageSize: 10000}) {
    nodes {
      userMajor {
        major
        majorName
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGqlMajorListQuery__
 *
 * To run a query within a React component, call `useGqlMajorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlMajorListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlMajorListQuery({
 *   variables: {
 *      schoolName: // value for 'schoolName'
 *   },
 * });
 */
export function useGqlMajorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlMajorListQuery, GqlMajorListQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlMajorListQuery, GqlMajorListQueryVariables>(GqlMajorListDocument, baseOptions);
      }
export function useGqlMajorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlMajorListQuery, GqlMajorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlMajorListQuery, GqlMajorListQueryVariables>(GqlMajorListDocument, baseOptions);
        }
export type GqlMajorListQueryHookResult = ReturnType<typeof useGqlMajorListQuery>;
export type GqlMajorListLazyQueryHookResult = ReturnType<typeof useGqlMajorListLazyQuery>;
export type GqlMajorListQueryResult = ApolloReactCommon.QueryResult<GqlMajorListQuery, GqlMajorListQueryVariables>;
export const GqlUserSchoolsDocument = gql`
    query gqlUserSchools($schoolName: String, $schoolType: String, $seq: Int) {
  userSchools(filterBy: {schoolName: $schoolName, schoolType: $schoolType, seq: $seq}, orderBy: {direction: ASC, field: SCHOOL_NAME}, pagination: {page: 1, pageSize: 10000}) {
    nodes {
      schoolName
      schoolType
      seq
    }
    totalCount
  }
}
    `;

/**
 * __useGqlUserSchoolsQuery__
 *
 * To run a query within a React component, call `useGqlUserSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlUserSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlUserSchoolsQuery({
 *   variables: {
 *      schoolName: // value for 'schoolName'
 *      schoolType: // value for 'schoolType'
 *      seq: // value for 'seq'
 *   },
 * });
 */
export function useGqlUserSchoolsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlUserSchoolsQuery, GqlUserSchoolsQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlUserSchoolsQuery, GqlUserSchoolsQueryVariables>(GqlUserSchoolsDocument, baseOptions);
      }
export function useGqlUserSchoolsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlUserSchoolsQuery, GqlUserSchoolsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlUserSchoolsQuery, GqlUserSchoolsQueryVariables>(GqlUserSchoolsDocument, baseOptions);
        }
export type GqlUserSchoolsQueryHookResult = ReturnType<typeof useGqlUserSchoolsQuery>;
export type GqlUserSchoolsLazyQueryHookResult = ReturnType<typeof useGqlUserSchoolsLazyQuery>;
export type GqlUserSchoolsQueryResult = ApolloReactCommon.QueryResult<GqlUserSchoolsQuery, GqlUserSchoolsQueryVariables>;
export const AuthLoginDocument = gql`
    mutation AuthLogin($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    user {
      id
    }
  }
}
    `;
export type AuthLoginMutationFn = ApolloReactCommon.MutationFunction<AuthLoginMutation, AuthLoginMutationVariables>;

/**
 * __useAuthLoginMutation__
 *
 * To run a mutation, you first call `useAuthLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLoginMutation, { data, loading, error }] = useAuthLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthLoginMutation, AuthLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthLoginMutation, AuthLoginMutationVariables>(AuthLoginDocument, baseOptions);
      }
export type AuthLoginMutationHookResult = ReturnType<typeof useAuthLoginMutation>;
export type AuthLoginMutationResult = ApolloReactCommon.MutationResult<AuthLoginMutation>;
export type AuthLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthLoginMutation, AuthLoginMutationVariables>;
export const AuthLogoutDocument = gql`
    mutation AuthLogout {
  logout
}
    `;
export type AuthLogoutMutationFn = ApolloReactCommon.MutationFunction<AuthLogoutMutation, AuthLogoutMutationVariables>;

/**
 * __useAuthLogoutMutation__
 *
 * To run a mutation, you first call `useAuthLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLogoutMutation, { data, loading, error }] = useAuthLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthLogoutMutation, AuthLogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthLogoutMutation, AuthLogoutMutationVariables>(AuthLogoutDocument, baseOptions);
      }
export type AuthLogoutMutationHookResult = ReturnType<typeof useAuthLogoutMutation>;
export type AuthLogoutMutationResult = ApolloReactCommon.MutationResult<AuthLogoutMutation>;
export type AuthLogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthLogoutMutation, AuthLogoutMutationVariables>;
export const AuthCheckDocument = gql`
    query AuthCheck {
  currentUser {
    id
    name
    username
    email
    phoneNumber
  }
  isAdminMember
}
    `;

/**
 * __useAuthCheckQuery__
 *
 * To run a query within a React component, call `useAuthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthCheckQuery, AuthCheckQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, baseOptions);
      }
export function useAuthCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthCheckQuery, AuthCheckQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, baseOptions);
        }
export type AuthCheckQueryHookResult = ReturnType<typeof useAuthCheckQuery>;
export type AuthCheckLazyQueryHookResult = ReturnType<typeof useAuthCheckLazyQuery>;
export type AuthCheckQueryResult = ApolloReactCommon.QueryResult<AuthCheckQuery, AuthCheckQueryVariables>;
export const AuthGetPermissionsDocument = gql`
    query AuthGetPermissions {
  currentUser {
    id
    isAllianceManager: isAllowed(scope: ALLIANCE_MANAGER)
    isSuperUser: isAllowed(scope: SUPERUSER)
    isTeacher: isAllowed(scope: TEACHER)
    canExtractDB: isAllowed(scope: EXTRACT_DB_READ)
  }
}
    `;

/**
 * __useAuthGetPermissionsQuery__
 *
 * To run a query within a React component, call `useAuthGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthGetPermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>(AuthGetPermissionsDocument, baseOptions);
      }
export function useAuthGetPermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>(AuthGetPermissionsDocument, baseOptions);
        }
export type AuthGetPermissionsQueryHookResult = ReturnType<typeof useAuthGetPermissionsQuery>;
export type AuthGetPermissionsLazyQueryHookResult = ReturnType<typeof useAuthGetPermissionsLazyQuery>;
export type AuthGetPermissionsQueryResult = ApolloReactCommon.QueryResult<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>;
export const AdminBlogDocument = gql`
    query AdminBlog($cursor: String) {
  adminBlogPosts(pageSize: 10, cursor: $cursor) {
    nodes {
      id
      title
      url
      createdAt
    }
    nextCursor
  }
}
    `;

/**
 * __useAdminBlogQuery__
 *
 * To run a query within a React component, call `useAdminBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBlogQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useAdminBlogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminBlogQuery, AdminBlogQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminBlogQuery, AdminBlogQueryVariables>(AdminBlogDocument, baseOptions);
      }
export function useAdminBlogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminBlogQuery, AdminBlogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminBlogQuery, AdminBlogQueryVariables>(AdminBlogDocument, baseOptions);
        }
export type AdminBlogQueryHookResult = ReturnType<typeof useAdminBlogQuery>;
export type AdminBlogLazyQueryHookResult = ReturnType<typeof useAdminBlogLazyQuery>;
export type AdminBlogQueryResult = ApolloReactCommon.QueryResult<AdminBlogQuery, AdminBlogQueryVariables>;
export const FileUploadDocument = gql`
    mutation FileUpload($file: Upload, $mode: BucketMode) {
  fileUpload(file: $file, mode: $mode) {
    file {
      id
      url
    }
  }
}
    `;
export type FileUploadMutationFn = ApolloReactCommon.MutationFunction<FileUploadMutation, FileUploadMutationVariables>;

/**
 * __useFileUploadMutation__
 *
 * To run a mutation, you first call `useFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUploadMutation, { data, loading, error }] = useFileUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useFileUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FileUploadMutation, FileUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<FileUploadMutation, FileUploadMutationVariables>(FileUploadDocument, baseOptions);
      }
export type FileUploadMutationHookResult = ReturnType<typeof useFileUploadMutation>;
export type FileUploadMutationResult = ApolloReactCommon.MutationResult<FileUploadMutation>;
export type FileUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<FileUploadMutation, FileUploadMutationVariables>;
export const ExamSectionDocument = gql`
    query ExamSection($id: ID!) {
  examSection(id: $id) {
    id
    examQuestionTypes {
      id
      name
    }
  }
}
    `;

/**
 * __useExamSectionQuery__
 *
 * To run a query within a React component, call `useExamSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExamSectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamSectionQuery, ExamSectionQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamSectionQuery, ExamSectionQueryVariables>(ExamSectionDocument, baseOptions);
      }
export function useExamSectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamSectionQuery, ExamSectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamSectionQuery, ExamSectionQueryVariables>(ExamSectionDocument, baseOptions);
        }
export type ExamSectionQueryHookResult = ReturnType<typeof useExamSectionQuery>;
export type ExamSectionLazyQueryHookResult = ReturnType<typeof useExamSectionLazyQuery>;
export type ExamSectionQueryResult = ApolloReactCommon.QueryResult<ExamSectionQuery, ExamSectionQueryVariables>;
export const ExamGetDetailDocument = gql`
    query ExamGetDetail($examID: ID!) {
  exam(id: $examID) {
    id
    sections {
      id
      name
      questions(orderBy: {field: QUESTION_NUMBER, direction: ASC}, pagination: {page: 1, pageSize: 1000}) {
        nodes {
          id
          number
        }
        totalCount
      }
    }
  }
}
    `;

/**
 * __useExamGetDetailQuery__
 *
 * To run a query within a React component, call `useExamGetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamGetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamGetDetailQuery({
 *   variables: {
 *      examID: // value for 'examID'
 *   },
 * });
 */
export function useExamGetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamGetDetailQuery, ExamGetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamGetDetailQuery, ExamGetDetailQueryVariables>(ExamGetDetailDocument, baseOptions);
      }
export function useExamGetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamGetDetailQuery, ExamGetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamGetDetailQuery, ExamGetDetailQueryVariables>(ExamGetDetailDocument, baseOptions);
        }
export type ExamGetDetailQueryHookResult = ReturnType<typeof useExamGetDetailQuery>;
export type ExamGetDetailLazyQueryHookResult = ReturnType<typeof useExamGetDetailLazyQuery>;
export type ExamGetDetailQueryResult = ApolloReactCommon.QueryResult<ExamGetDetailQuery, ExamGetDetailQueryVariables>;
export const ExamAttemptGetOrCreateDocument = gql`
    mutation ExamAttemptGetOrCreate($userID: ID!, $examEventID: ID!, $targetCompanyID: ID) {
  examAttemptGetOrCreate(userID: $userID, examEventID: $examEventID, targetCompanyID: $targetCompanyID) {
    examAttempt {
      id
    }
  }
}
    `;
export type ExamAttemptGetOrCreateMutationFn = ApolloReactCommon.MutationFunction<ExamAttemptGetOrCreateMutation, ExamAttemptGetOrCreateMutationVariables>;

/**
 * __useExamAttemptGetOrCreateMutation__
 *
 * To run a mutation, you first call `useExamAttemptGetOrCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamAttemptGetOrCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examAttemptGetOrCreateMutation, { data, loading, error }] = useExamAttemptGetOrCreateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      examEventID: // value for 'examEventID'
 *      targetCompanyID: // value for 'targetCompanyID'
 *   },
 * });
 */
export function useExamAttemptGetOrCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamAttemptGetOrCreateMutation, ExamAttemptGetOrCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamAttemptGetOrCreateMutation, ExamAttemptGetOrCreateMutationVariables>(ExamAttemptGetOrCreateDocument, baseOptions);
      }
export type ExamAttemptGetOrCreateMutationHookResult = ReturnType<typeof useExamAttemptGetOrCreateMutation>;
export type ExamAttemptGetOrCreateMutationResult = ApolloReactCommon.MutationResult<ExamAttemptGetOrCreateMutation>;
export type ExamAttemptGetOrCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamAttemptGetOrCreateMutation, ExamAttemptGetOrCreateMutationVariables>;
export const ExamAttemptAnswerSubmitManyDocument = gql`
    mutation ExamAttemptAnswerSubmitMany($examAttemptID: ID!, $inputs: [ExamAttemptAnswerInput!]!) {
  examAttemptAnswerSubmitMany(examAttemptID: $examAttemptID, inputs: $inputs) {
    examAttemptAnswers {
      id
    }
  }
}
    `;
export type ExamAttemptAnswerSubmitManyMutationFn = ApolloReactCommon.MutationFunction<ExamAttemptAnswerSubmitManyMutation, ExamAttemptAnswerSubmitManyMutationVariables>;

/**
 * __useExamAttemptAnswerSubmitManyMutation__
 *
 * To run a mutation, you first call `useExamAttemptAnswerSubmitManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamAttemptAnswerSubmitManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examAttemptAnswerSubmitManyMutation, { data, loading, error }] = useExamAttemptAnswerSubmitManyMutation({
 *   variables: {
 *      examAttemptID: // value for 'examAttemptID'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useExamAttemptAnswerSubmitManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamAttemptAnswerSubmitManyMutation, ExamAttemptAnswerSubmitManyMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamAttemptAnswerSubmitManyMutation, ExamAttemptAnswerSubmitManyMutationVariables>(ExamAttemptAnswerSubmitManyDocument, baseOptions);
      }
export type ExamAttemptAnswerSubmitManyMutationHookResult = ReturnType<typeof useExamAttemptAnswerSubmitManyMutation>;
export type ExamAttemptAnswerSubmitManyMutationResult = ApolloReactCommon.MutationResult<ExamAttemptAnswerSubmitManyMutation>;
export type ExamAttemptAnswerSubmitManyMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamAttemptAnswerSubmitManyMutation, ExamAttemptAnswerSubmitManyMutationVariables>;
export const ExcelDownloadButton_ContentPurchasesDocument = gql`
    query ExcelDownloadButton_ContentPurchases($id: ID!) {
  content(id: $id) {
    contentPurchases {
      nodes {
        id
        user {
          id
          username
          name
          phoneNumber
          email
        }
        price
        pointDiscount
        couponDiscount
        offlineOptionSelectedContentIDs
        order {
          id
          createdAt
          status
          usedCouponNumber {
            number
          }
          payment {
            createdAt
            paymentMethod
            amount
          }
        }
        refund {
          id
          createdAt
          amount
          message
        }
      }
    }
  }
}
    `;

/**
 * __useExcelDownloadButton_ContentPurchasesQuery__
 *
 * To run a query within a React component, call `useExcelDownloadButton_ContentPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExcelDownloadButton_ContentPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExcelDownloadButton_ContentPurchasesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExcelDownloadButton_ContentPurchasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExcelDownloadButton_ContentPurchasesQuery, ExcelDownloadButton_ContentPurchasesQueryVariables>) {
        return ApolloReactHooks.useQuery<ExcelDownloadButton_ContentPurchasesQuery, ExcelDownloadButton_ContentPurchasesQueryVariables>(ExcelDownloadButton_ContentPurchasesDocument, baseOptions);
      }
export function useExcelDownloadButton_ContentPurchasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExcelDownloadButton_ContentPurchasesQuery, ExcelDownloadButton_ContentPurchasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExcelDownloadButton_ContentPurchasesQuery, ExcelDownloadButton_ContentPurchasesQueryVariables>(ExcelDownloadButton_ContentPurchasesDocument, baseOptions);
        }
export type ExcelDownloadButton_ContentPurchasesQueryHookResult = ReturnType<typeof useExcelDownloadButton_ContentPurchasesQuery>;
export type ExcelDownloadButton_ContentPurchasesLazyQueryHookResult = ReturnType<typeof useExcelDownloadButton_ContentPurchasesLazyQuery>;
export type ExcelDownloadButton_ContentPurchasesQueryResult = ApolloReactCommon.QueryResult<ExcelDownloadButton_ContentPurchasesQuery, ExcelDownloadButton_ContentPurchasesQueryVariables>;
export const AddContent_OfflineChildrenContentsUpdateDocument = gql`
    mutation AddContent_OfflineChildrenContentsUpdate($offlineID: ID!, $childrenOfflineIDs: [ID!]!) {
  offlineChildrenContentsUpdate(offlineID: $offlineID, childrenOfflineIDs: $childrenOfflineIDs) {
    offlineChildrenContents {
      id
    }
  }
}
    `;
export type AddContent_OfflineChildrenContentsUpdateMutationFn = ApolloReactCommon.MutationFunction<AddContent_OfflineChildrenContentsUpdateMutation, AddContent_OfflineChildrenContentsUpdateMutationVariables>;

/**
 * __useAddContent_OfflineChildrenContentsUpdateMutation__
 *
 * To run a mutation, you first call `useAddContent_OfflineChildrenContentsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContent_OfflineChildrenContentsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContentOfflineChildrenContentsUpdateMutation, { data, loading, error }] = useAddContent_OfflineChildrenContentsUpdateMutation({
 *   variables: {
 *      offlineID: // value for 'offlineID'
 *      childrenOfflineIDs: // value for 'childrenOfflineIDs'
 *   },
 * });
 */
export function useAddContent_OfflineChildrenContentsUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddContent_OfflineChildrenContentsUpdateMutation, AddContent_OfflineChildrenContentsUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<AddContent_OfflineChildrenContentsUpdateMutation, AddContent_OfflineChildrenContentsUpdateMutationVariables>(AddContent_OfflineChildrenContentsUpdateDocument, baseOptions);
      }
export type AddContent_OfflineChildrenContentsUpdateMutationHookResult = ReturnType<typeof useAddContent_OfflineChildrenContentsUpdateMutation>;
export type AddContent_OfflineChildrenContentsUpdateMutationResult = ApolloReactCommon.MutationResult<AddContent_OfflineChildrenContentsUpdateMutation>;
export type AddContent_OfflineChildrenContentsUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<AddContent_OfflineChildrenContentsUpdateMutation, AddContent_OfflineChildrenContentsUpdateMutationVariables>;
export const ContentList_BulkAdd_OfflineByIDsDocument = gql`
    query ContentList_BulkAdd_OfflineByIDs($ids: [ID!]!) {
  offlineByIDs(ids: $ids) {
    id
    courseStartDate
    courseEndDate
    classStartTime
    classEndTime
    content {
      id
      subject
      service
      studentCount
      teachers {
        name
      }
    }
  }
}
    `;

/**
 * __useContentList_BulkAdd_OfflineByIDsQuery__
 *
 * To run a query within a React component, call `useContentList_BulkAdd_OfflineByIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentList_BulkAdd_OfflineByIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentList_BulkAdd_OfflineByIDsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useContentList_BulkAdd_OfflineByIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentList_BulkAdd_OfflineByIDsQuery, ContentList_BulkAdd_OfflineByIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentList_BulkAdd_OfflineByIDsQuery, ContentList_BulkAdd_OfflineByIDsQueryVariables>(ContentList_BulkAdd_OfflineByIDsDocument, baseOptions);
      }
export function useContentList_BulkAdd_OfflineByIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentList_BulkAdd_OfflineByIDsQuery, ContentList_BulkAdd_OfflineByIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentList_BulkAdd_OfflineByIDsQuery, ContentList_BulkAdd_OfflineByIDsQueryVariables>(ContentList_BulkAdd_OfflineByIDsDocument, baseOptions);
        }
export type ContentList_BulkAdd_OfflineByIDsQueryHookResult = ReturnType<typeof useContentList_BulkAdd_OfflineByIDsQuery>;
export type ContentList_BulkAdd_OfflineByIDsLazyQueryHookResult = ReturnType<typeof useContentList_BulkAdd_OfflineByIDsLazyQuery>;
export type ContentList_BulkAdd_OfflineByIDsQueryResult = ApolloReactCommon.QueryResult<ContentList_BulkAdd_OfflineByIDsQuery, ContentList_BulkAdd_OfflineByIDsQueryVariables>;
export const LectureCategory_ContentCategoryListDocument = gql`
    query LectureCategory_ContentCategoryList($contentType: ContentType!, $service: ServiceType!) {
  contentCategoryList(contentType: $contentType, service: $service) {
    id
    name
    childrenCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useLectureCategory_ContentCategoryListQuery__
 *
 * To run a query within a React component, call `useLectureCategory_ContentCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLectureCategory_ContentCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLectureCategory_ContentCategoryListQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useLectureCategory_ContentCategoryListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LectureCategory_ContentCategoryListQuery, LectureCategory_ContentCategoryListQueryVariables>) {
        return ApolloReactHooks.useQuery<LectureCategory_ContentCategoryListQuery, LectureCategory_ContentCategoryListQueryVariables>(LectureCategory_ContentCategoryListDocument, baseOptions);
      }
export function useLectureCategory_ContentCategoryListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LectureCategory_ContentCategoryListQuery, LectureCategory_ContentCategoryListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LectureCategory_ContentCategoryListQuery, LectureCategory_ContentCategoryListQueryVariables>(LectureCategory_ContentCategoryListDocument, baseOptions);
        }
export type LectureCategory_ContentCategoryListQueryHookResult = ReturnType<typeof useLectureCategory_ContentCategoryListQuery>;
export type LectureCategory_ContentCategoryListLazyQueryHookResult = ReturnType<typeof useLectureCategory_ContentCategoryListLazyQuery>;
export type LectureCategory_ContentCategoryListQueryResult = ApolloReactCommon.QueryResult<LectureCategory_ContentCategoryListQuery, LectureCategory_ContentCategoryListQueryVariables>;
export const RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateDocument = gql`
    mutation RepurchaseDiscounts_OfflineRepurchaseDiscountUpdate($offlineID: ID!, $inputs: [OfflineRepurchaseDiscountInput!]) {
  offlineRepurchaseDiscountUpdate(offlineID: $offlineID, inputs: $inputs) {
    offlineRepurchaseDiscounts {
      discountValue
      discountType
      targetOffline {
        id
        content {
          id
          subject
          teachers {
            name
          }
        }
        courseStartDate
        courseEndDate
      }
    }
  }
}
    `;
export type RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutationFn = ApolloReactCommon.MutationFunction<RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation, RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutationVariables>;

/**
 * __useRepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation__
 *
 * To run a mutation, you first call `useRepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [repurchaseDiscountsOfflineRepurchaseDiscountUpdateMutation, { data, loading, error }] = useRepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation({
 *   variables: {
 *      offlineID: // value for 'offlineID'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useRepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation, RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation, RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutationVariables>(RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateDocument, baseOptions);
      }
export type RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutationHookResult = ReturnType<typeof useRepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation>;
export type RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutationResult = ApolloReactCommon.MutationResult<RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation>;
export type RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation, RepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutationVariables>;
export const ImportButton_OfflineByContentIdDocument = gql`
    query ImportButton_OfflineByContentID($contentID: ID!) {
  offlineByContentID(contentID: $contentID) {
    id
    offlineRepurchaseDiscounts {
      discountValue
      discountType
      targetOffline {
        id
        content {
          id
          subject
          teachers {
            name
          }
        }
      }
    }
    courseStartDate
    courseEndDate
  }
}
    `;

/**
 * __useImportButton_OfflineByContentIdQuery__
 *
 * To run a query within a React component, call `useImportButton_OfflineByContentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportButton_OfflineByContentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportButton_OfflineByContentIdQuery({
 *   variables: {
 *      contentID: // value for 'contentID'
 *   },
 * });
 */
export function useImportButton_OfflineByContentIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ImportButton_OfflineByContentIdQuery, ImportButton_OfflineByContentIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ImportButton_OfflineByContentIdQuery, ImportButton_OfflineByContentIdQueryVariables>(ImportButton_OfflineByContentIdDocument, baseOptions);
      }
export function useImportButton_OfflineByContentIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImportButton_OfflineByContentIdQuery, ImportButton_OfflineByContentIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ImportButton_OfflineByContentIdQuery, ImportButton_OfflineByContentIdQueryVariables>(ImportButton_OfflineByContentIdDocument, baseOptions);
        }
export type ImportButton_OfflineByContentIdQueryHookResult = ReturnType<typeof useImportButton_OfflineByContentIdQuery>;
export type ImportButton_OfflineByContentIdLazyQueryHookResult = ReturnType<typeof useImportButton_OfflineByContentIdLazyQuery>;
export type ImportButton_OfflineByContentIdQueryResult = ApolloReactCommon.QueryResult<ImportButton_OfflineByContentIdQuery, ImportButton_OfflineByContentIdQueryVariables>;
export const BulkAddListButton_OfflineByIDsDocument = gql`
    query BulkAddListButton_OfflineByIDs($ids: [ID!]!) {
  offlineByIDs(ids: $ids) {
    id
    content {
      id
      subject
      teachers {
        name
      }
    }
    courseStartDate
    courseEndDate
  }
}
    `;

/**
 * __useBulkAddListButton_OfflineByIDsQuery__
 *
 * To run a query within a React component, call `useBulkAddListButton_OfflineByIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkAddListButton_OfflineByIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkAddListButton_OfflineByIDsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkAddListButton_OfflineByIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BulkAddListButton_OfflineByIDsQuery, BulkAddListButton_OfflineByIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<BulkAddListButton_OfflineByIDsQuery, BulkAddListButton_OfflineByIDsQueryVariables>(BulkAddListButton_OfflineByIDsDocument, baseOptions);
      }
export function useBulkAddListButton_OfflineByIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BulkAddListButton_OfflineByIDsQuery, BulkAddListButton_OfflineByIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BulkAddListButton_OfflineByIDsQuery, BulkAddListButton_OfflineByIDsQueryVariables>(BulkAddListButton_OfflineByIDsDocument, baseOptions);
        }
export type BulkAddListButton_OfflineByIDsQueryHookResult = ReturnType<typeof useBulkAddListButton_OfflineByIDsQuery>;
export type BulkAddListButton_OfflineByIDsLazyQueryHookResult = ReturnType<typeof useBulkAddListButton_OfflineByIDsLazyQuery>;
export type BulkAddListButton_OfflineByIDsQueryResult = ApolloReactCommon.QueryResult<BulkAddListButton_OfflineByIDsQuery, BulkAddListButton_OfflineByIDsQueryVariables>;
export const BulkImportButton_OfflinesByContentIDsDocument = gql`
    query BulkImportButton_OfflinesByContentIDs($contentIDs: [ID!]!) {
  offlinesByContentIDs(contentIDs: $contentIDs) {
    id
    content {
      id
      subject
      teachers {
        name
      }
    }
    offlineRepurchaseDiscounts {
      targetOffline {
        id
      }
    }
    courseStartDate
    courseEndDate
  }
}
    `;

/**
 * __useBulkImportButton_OfflinesByContentIDsQuery__
 *
 * To run a query within a React component, call `useBulkImportButton_OfflinesByContentIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkImportButton_OfflinesByContentIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkImportButton_OfflinesByContentIDsQuery({
 *   variables: {
 *      contentIDs: // value for 'contentIDs'
 *   },
 * });
 */
export function useBulkImportButton_OfflinesByContentIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BulkImportButton_OfflinesByContentIDsQuery, BulkImportButton_OfflinesByContentIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<BulkImportButton_OfflinesByContentIDsQuery, BulkImportButton_OfflinesByContentIDsQueryVariables>(BulkImportButton_OfflinesByContentIDsDocument, baseOptions);
      }
export function useBulkImportButton_OfflinesByContentIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BulkImportButton_OfflinesByContentIDsQuery, BulkImportButton_OfflinesByContentIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BulkImportButton_OfflinesByContentIDsQuery, BulkImportButton_OfflinesByContentIDsQueryVariables>(BulkImportButton_OfflinesByContentIDsDocument, baseOptions);
        }
export type BulkImportButton_OfflinesByContentIDsQueryHookResult = ReturnType<typeof useBulkImportButton_OfflinesByContentIDsQuery>;
export type BulkImportButton_OfflinesByContentIDsLazyQueryHookResult = ReturnType<typeof useBulkImportButton_OfflinesByContentIDsLazyQuery>;
export type BulkImportButton_OfflinesByContentIDsQueryResult = ApolloReactCommon.QueryResult<BulkImportButton_OfflinesByContentIDsQuery, BulkImportButton_OfflinesByContentIDsQueryVariables>;
export const ClassChangeForm_OfflineStudentClassChangeDocument = gql`
    mutation ClassChangeForm_OfflineStudentClassChange($userContentID: ID!, $contentID: ID!) {
  offlineStudentClassChange(userContentID: $userContentID, contentID: $contentID) {
    contentPurchase {
      id
      content {
        id
        subject
      }
    }
  }
}
    `;
export type ClassChangeForm_OfflineStudentClassChangeMutationFn = ApolloReactCommon.MutationFunction<ClassChangeForm_OfflineStudentClassChangeMutation, ClassChangeForm_OfflineStudentClassChangeMutationVariables>;

/**
 * __useClassChangeForm_OfflineStudentClassChangeMutation__
 *
 * To run a mutation, you first call `useClassChangeForm_OfflineStudentClassChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassChangeForm_OfflineStudentClassChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classChangeFormOfflineStudentClassChangeMutation, { data, loading, error }] = useClassChangeForm_OfflineStudentClassChangeMutation({
 *   variables: {
 *      userContentID: // value for 'userContentID'
 *      contentID: // value for 'contentID'
 *   },
 * });
 */
export function useClassChangeForm_OfflineStudentClassChangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClassChangeForm_OfflineStudentClassChangeMutation, ClassChangeForm_OfflineStudentClassChangeMutationVariables>) {
        return ApolloReactHooks.useMutation<ClassChangeForm_OfflineStudentClassChangeMutation, ClassChangeForm_OfflineStudentClassChangeMutationVariables>(ClassChangeForm_OfflineStudentClassChangeDocument, baseOptions);
      }
export type ClassChangeForm_OfflineStudentClassChangeMutationHookResult = ReturnType<typeof useClassChangeForm_OfflineStudentClassChangeMutation>;
export type ClassChangeForm_OfflineStudentClassChangeMutationResult = ApolloReactCommon.MutationResult<ClassChangeForm_OfflineStudentClassChangeMutation>;
export type ClassChangeForm_OfflineStudentClassChangeMutationOptions = ApolloReactCommon.BaseMutationOptions<ClassChangeForm_OfflineStudentClassChangeMutation, ClassChangeForm_OfflineStudentClassChangeMutationVariables>;
export const BenefitsDialog_ProvideFreeContentDocument = gql`
    mutation BenefitsDialog_ProvideFreeContent($userID: ID!, $contentID: ID!) {
  provideFreeContent(userID: $userID, contentID: $contentID) {
    contentPurchase {
      id
      content {
        id
      }
    }
  }
}
    `;
export type BenefitsDialog_ProvideFreeContentMutationFn = ApolloReactCommon.MutationFunction<BenefitsDialog_ProvideFreeContentMutation, BenefitsDialog_ProvideFreeContentMutationVariables>;

/**
 * __useBenefitsDialog_ProvideFreeContentMutation__
 *
 * To run a mutation, you first call `useBenefitsDialog_ProvideFreeContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBenefitsDialog_ProvideFreeContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [benefitsDialogProvideFreeContentMutation, { data, loading, error }] = useBenefitsDialog_ProvideFreeContentMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      contentID: // value for 'contentID'
 *   },
 * });
 */
export function useBenefitsDialog_ProvideFreeContentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BenefitsDialog_ProvideFreeContentMutation, BenefitsDialog_ProvideFreeContentMutationVariables>) {
        return ApolloReactHooks.useMutation<BenefitsDialog_ProvideFreeContentMutation, BenefitsDialog_ProvideFreeContentMutationVariables>(BenefitsDialog_ProvideFreeContentDocument, baseOptions);
      }
export type BenefitsDialog_ProvideFreeContentMutationHookResult = ReturnType<typeof useBenefitsDialog_ProvideFreeContentMutation>;
export type BenefitsDialog_ProvideFreeContentMutationResult = ApolloReactCommon.MutationResult<BenefitsDialog_ProvideFreeContentMutation>;
export type BenefitsDialog_ProvideFreeContentMutationOptions = ApolloReactCommon.BaseMutationOptions<BenefitsDialog_ProvideFreeContentMutation, BenefitsDialog_ProvideFreeContentMutationVariables>;
export const UserStudyEventExportDocument = gql`
    query UserStudyEventExport($filterBy: UserStudyEventFilters, $orderBy: UserStudyEventOrder, $pagination: Pagination) {
  data: userStudyEvents(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      createdAt
      user {
        name
        username
        phoneNumber
        createdAt
      }
      state
    }
  }
}
    `;

/**
 * __useUserStudyEventExportQuery__
 *
 * To run a query within a React component, call `useUserStudyEventExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStudyEventExportQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStudyEventExportQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserStudyEventExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserStudyEventExportQuery, UserStudyEventExportQueryVariables>) {
        return ApolloReactHooks.useQuery<UserStudyEventExportQuery, UserStudyEventExportQueryVariables>(UserStudyEventExportDocument, baseOptions);
      }
export function useUserStudyEventExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStudyEventExportQuery, UserStudyEventExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserStudyEventExportQuery, UserStudyEventExportQueryVariables>(UserStudyEventExportDocument, baseOptions);
        }
export type UserStudyEventExportQueryHookResult = ReturnType<typeof useUserStudyEventExportQuery>;
export type UserStudyEventExportLazyQueryHookResult = ReturnType<typeof useUserStudyEventExportLazyQuery>;
export type UserStudyEventExportQueryResult = ApolloReactCommon.QueryResult<UserStudyEventExportQuery, UserStudyEventExportQueryVariables>;
export const UserStudyEventShow_UserStudyMissionUpdateDocument = gql`
    mutation UserStudyEventShow_UserStudyMissionUpdate($id: ID!, $input: UserStudyMissionUpdateInput!) {
  userStudyMissionUpdate(id: $id, input: $input) {
    userStudyMission {
      id
      state
    }
  }
}
    `;
export type UserStudyEventShow_UserStudyMissionUpdateMutationFn = ApolloReactCommon.MutationFunction<UserStudyEventShow_UserStudyMissionUpdateMutation, UserStudyEventShow_UserStudyMissionUpdateMutationVariables>;

/**
 * __useUserStudyEventShow_UserStudyMissionUpdateMutation__
 *
 * To run a mutation, you first call `useUserStudyEventShow_UserStudyMissionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStudyEventShow_UserStudyMissionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStudyEventShowUserStudyMissionUpdateMutation, { data, loading, error }] = useUserStudyEventShow_UserStudyMissionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserStudyEventShow_UserStudyMissionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserStudyEventShow_UserStudyMissionUpdateMutation, UserStudyEventShow_UserStudyMissionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<UserStudyEventShow_UserStudyMissionUpdateMutation, UserStudyEventShow_UserStudyMissionUpdateMutationVariables>(UserStudyEventShow_UserStudyMissionUpdateDocument, baseOptions);
      }
export type UserStudyEventShow_UserStudyMissionUpdateMutationHookResult = ReturnType<typeof useUserStudyEventShow_UserStudyMissionUpdateMutation>;
export type UserStudyEventShow_UserStudyMissionUpdateMutationResult = ApolloReactCommon.MutationResult<UserStudyEventShow_UserStudyMissionUpdateMutation>;
export type UserStudyEventShow_UserStudyMissionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<UserStudyEventShow_UserStudyMissionUpdateMutation, UserStudyEventShow_UserStudyMissionUpdateMutationVariables>;
export const UserStudyEventListUserStudyEventsForcePassDocument = gql`
    mutation UserStudyEventListUserStudyEventsForcePass($ids: [ID!]!) {
  userStudyEventsForcePass(ids: $ids) {
    userStudyEvents {
      id
      state
    }
    forcePassCount
  }
}
    `;
export type UserStudyEventListUserStudyEventsForcePassMutationFn = ApolloReactCommon.MutationFunction<UserStudyEventListUserStudyEventsForcePassMutation, UserStudyEventListUserStudyEventsForcePassMutationVariables>;

/**
 * __useUserStudyEventListUserStudyEventsForcePassMutation__
 *
 * To run a mutation, you first call `useUserStudyEventListUserStudyEventsForcePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStudyEventListUserStudyEventsForcePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStudyEventListUserStudyEventsForcePassMutation, { data, loading, error }] = useUserStudyEventListUserStudyEventsForcePassMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUserStudyEventListUserStudyEventsForcePassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserStudyEventListUserStudyEventsForcePassMutation, UserStudyEventListUserStudyEventsForcePassMutationVariables>) {
        return ApolloReactHooks.useMutation<UserStudyEventListUserStudyEventsForcePassMutation, UserStudyEventListUserStudyEventsForcePassMutationVariables>(UserStudyEventListUserStudyEventsForcePassDocument, baseOptions);
      }
export type UserStudyEventListUserStudyEventsForcePassMutationHookResult = ReturnType<typeof useUserStudyEventListUserStudyEventsForcePassMutation>;
export type UserStudyEventListUserStudyEventsForcePassMutationResult = ApolloReactCommon.MutationResult<UserStudyEventListUserStudyEventsForcePassMutation>;
export type UserStudyEventListUserStudyEventsForcePassMutationOptions = ApolloReactCommon.BaseMutationOptions<UserStudyEventListUserStudyEventsForcePassMutation, UserStudyEventListUserStudyEventsForcePassMutationVariables>;
export const UserStudyEventListUserStudyEventsPassDocument = gql`
    mutation UserStudyEventListUserStudyEventsPass($ids: [ID!]!) {
  userStudyEventsPass(ids: $ids) {
    userStudyEvents {
      id
      state
    }
  }
}
    `;
export type UserStudyEventListUserStudyEventsPassMutationFn = ApolloReactCommon.MutationFunction<UserStudyEventListUserStudyEventsPassMutation, UserStudyEventListUserStudyEventsPassMutationVariables>;

/**
 * __useUserStudyEventListUserStudyEventsPassMutation__
 *
 * To run a mutation, you first call `useUserStudyEventListUserStudyEventsPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStudyEventListUserStudyEventsPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStudyEventListUserStudyEventsPassMutation, { data, loading, error }] = useUserStudyEventListUserStudyEventsPassMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUserStudyEventListUserStudyEventsPassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserStudyEventListUserStudyEventsPassMutation, UserStudyEventListUserStudyEventsPassMutationVariables>) {
        return ApolloReactHooks.useMutation<UserStudyEventListUserStudyEventsPassMutation, UserStudyEventListUserStudyEventsPassMutationVariables>(UserStudyEventListUserStudyEventsPassDocument, baseOptions);
      }
export type UserStudyEventListUserStudyEventsPassMutationHookResult = ReturnType<typeof useUserStudyEventListUserStudyEventsPassMutation>;
export type UserStudyEventListUserStudyEventsPassMutationResult = ApolloReactCommon.MutationResult<UserStudyEventListUserStudyEventsPassMutation>;
export type UserStudyEventListUserStudyEventsPassMutationOptions = ApolloReactCommon.BaseMutationOptions<UserStudyEventListUserStudyEventsPassMutation, UserStudyEventListUserStudyEventsPassMutationVariables>;
export const UserStudyEventListUserStudyEventsFailDocument = gql`
    mutation UserStudyEventListUserStudyEventsFail($ids: [ID!]!) {
  userStudyEventsFail(ids: $ids) {
    userStudyEvents {
      id
      state
    }
  }
}
    `;
export type UserStudyEventListUserStudyEventsFailMutationFn = ApolloReactCommon.MutationFunction<UserStudyEventListUserStudyEventsFailMutation, UserStudyEventListUserStudyEventsFailMutationVariables>;

/**
 * __useUserStudyEventListUserStudyEventsFailMutation__
 *
 * To run a mutation, you first call `useUserStudyEventListUserStudyEventsFailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStudyEventListUserStudyEventsFailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStudyEventListUserStudyEventsFailMutation, { data, loading, error }] = useUserStudyEventListUserStudyEventsFailMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUserStudyEventListUserStudyEventsFailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserStudyEventListUserStudyEventsFailMutation, UserStudyEventListUserStudyEventsFailMutationVariables>) {
        return ApolloReactHooks.useMutation<UserStudyEventListUserStudyEventsFailMutation, UserStudyEventListUserStudyEventsFailMutationVariables>(UserStudyEventListUserStudyEventsFailDocument, baseOptions);
      }
export type UserStudyEventListUserStudyEventsFailMutationHookResult = ReturnType<typeof useUserStudyEventListUserStudyEventsFailMutation>;
export type UserStudyEventListUserStudyEventsFailMutationResult = ApolloReactCommon.MutationResult<UserStudyEventListUserStudyEventsFailMutation>;
export type UserStudyEventListUserStudyEventsFailMutationOptions = ApolloReactCommon.BaseMutationOptions<UserStudyEventListUserStudyEventsFailMutation, UserStudyEventListUserStudyEventsFailMutationVariables>;
export const BannerGroupGetListDocument = gql`
    query BannerGroupGetList($filterBy: BannerGroupFilters, $orderBy: BannerGroupOrder, $pagination: Pagination) {
  data: bannerGroups(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...BannerGroupFragment
    }
    total: totalCount
  }
}
    ${BannerGroupFragmentFragmentDoc}`;

/**
 * __useBannerGroupGetListQuery__
 *
 * To run a query within a React component, call `useBannerGroupGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerGroupGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerGroupGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBannerGroupGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BannerGroupGetListQuery, BannerGroupGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<BannerGroupGetListQuery, BannerGroupGetListQueryVariables>(BannerGroupGetListDocument, baseOptions);
      }
export function useBannerGroupGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BannerGroupGetListQuery, BannerGroupGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BannerGroupGetListQuery, BannerGroupGetListQueryVariables>(BannerGroupGetListDocument, baseOptions);
        }
export type BannerGroupGetListQueryHookResult = ReturnType<typeof useBannerGroupGetListQuery>;
export type BannerGroupGetListLazyQueryHookResult = ReturnType<typeof useBannerGroupGetListLazyQuery>;
export type BannerGroupGetListQueryResult = ApolloReactCommon.QueryResult<BannerGroupGetListQuery, BannerGroupGetListQueryVariables>;
export const BannerGroupGetDocument = gql`
    query BannerGroupGet($id: ID!) {
  data: bannerGroup(id: $id) {
    ...BannerGroupFragment
  }
}
    ${BannerGroupFragmentFragmentDoc}`;

/**
 * __useBannerGroupGetQuery__
 *
 * To run a query within a React component, call `useBannerGroupGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerGroupGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerGroupGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBannerGroupGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BannerGroupGetQuery, BannerGroupGetQueryVariables>) {
        return ApolloReactHooks.useQuery<BannerGroupGetQuery, BannerGroupGetQueryVariables>(BannerGroupGetDocument, baseOptions);
      }
export function useBannerGroupGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BannerGroupGetQuery, BannerGroupGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BannerGroupGetQuery, BannerGroupGetQueryVariables>(BannerGroupGetDocument, baseOptions);
        }
export type BannerGroupGetQueryHookResult = ReturnType<typeof useBannerGroupGetQuery>;
export type BannerGroupGetLazyQueryHookResult = ReturnType<typeof useBannerGroupGetLazyQuery>;
export type BannerGroupGetQueryResult = ApolloReactCommon.QueryResult<BannerGroupGetQuery, BannerGroupGetQueryVariables>;
export const BannerGroupGetManyDocument = gql`
    query BannerGroupGetMany($ids: [ID!]!) {
  data: bannerGroupsByID(ids: $ids) {
    ...BannerGroupFragment
  }
}
    ${BannerGroupFragmentFragmentDoc}`;

/**
 * __useBannerGroupGetManyQuery__
 *
 * To run a query within a React component, call `useBannerGroupGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerGroupGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerGroupGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBannerGroupGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BannerGroupGetManyQuery, BannerGroupGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<BannerGroupGetManyQuery, BannerGroupGetManyQueryVariables>(BannerGroupGetManyDocument, baseOptions);
      }
export function useBannerGroupGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BannerGroupGetManyQuery, BannerGroupGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BannerGroupGetManyQuery, BannerGroupGetManyQueryVariables>(BannerGroupGetManyDocument, baseOptions);
        }
export type BannerGroupGetManyQueryHookResult = ReturnType<typeof useBannerGroupGetManyQuery>;
export type BannerGroupGetManyLazyQueryHookResult = ReturnType<typeof useBannerGroupGetManyLazyQuery>;
export type BannerGroupGetManyQueryResult = ApolloReactCommon.QueryResult<BannerGroupGetManyQuery, BannerGroupGetManyQueryVariables>;
export const BannerGroupCreateDocument = gql`
    mutation BannerGroupCreate($input: BannerGroupCreateInput!) {
  data: bannerGroupCreate(input: $input) {
    data: bannerGroup {
      ...BannerGroupFragment
    }
  }
}
    ${BannerGroupFragmentFragmentDoc}`;
export type BannerGroupCreateMutationFn = ApolloReactCommon.MutationFunction<BannerGroupCreateMutation, BannerGroupCreateMutationVariables>;

/**
 * __useBannerGroupCreateMutation__
 *
 * To run a mutation, you first call `useBannerGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBannerGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bannerGroupCreateMutation, { data, loading, error }] = useBannerGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBannerGroupCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BannerGroupCreateMutation, BannerGroupCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<BannerGroupCreateMutation, BannerGroupCreateMutationVariables>(BannerGroupCreateDocument, baseOptions);
      }
export type BannerGroupCreateMutationHookResult = ReturnType<typeof useBannerGroupCreateMutation>;
export type BannerGroupCreateMutationResult = ApolloReactCommon.MutationResult<BannerGroupCreateMutation>;
export type BannerGroupCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<BannerGroupCreateMutation, BannerGroupCreateMutationVariables>;
export const BannerGroupUpdateDocument = gql`
    mutation BannerGroupUpdate($id: ID!, $input: BannerGroupUpdateInput!) {
  data: bannerGroupUpdate(id: $id, input: $input) {
    data: bannerGroup {
      ...BannerGroupFragment
    }
  }
}
    ${BannerGroupFragmentFragmentDoc}`;
export type BannerGroupUpdateMutationFn = ApolloReactCommon.MutationFunction<BannerGroupUpdateMutation, BannerGroupUpdateMutationVariables>;

/**
 * __useBannerGroupUpdateMutation__
 *
 * To run a mutation, you first call `useBannerGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBannerGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bannerGroupUpdateMutation, { data, loading, error }] = useBannerGroupUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBannerGroupUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BannerGroupUpdateMutation, BannerGroupUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BannerGroupUpdateMutation, BannerGroupUpdateMutationVariables>(BannerGroupUpdateDocument, baseOptions);
      }
export type BannerGroupUpdateMutationHookResult = ReturnType<typeof useBannerGroupUpdateMutation>;
export type BannerGroupUpdateMutationResult = ApolloReactCommon.MutationResult<BannerGroupUpdateMutation>;
export type BannerGroupUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BannerGroupUpdateMutation, BannerGroupUpdateMutationVariables>;
export const BannerGroupDeleteManyDocument = gql`
    mutation BannerGroupDeleteMany($ids: [ID!]!) {
  data: bannerGroupDeleteMany(ids: $ids) {
    data: bannerGroups {
      ...BannerGroupFragment
    }
  }
}
    ${BannerGroupFragmentFragmentDoc}`;
export type BannerGroupDeleteManyMutationFn = ApolloReactCommon.MutationFunction<BannerGroupDeleteManyMutation, BannerGroupDeleteManyMutationVariables>;

/**
 * __useBannerGroupDeleteManyMutation__
 *
 * To run a mutation, you first call `useBannerGroupDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBannerGroupDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bannerGroupDeleteManyMutation, { data, loading, error }] = useBannerGroupDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBannerGroupDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BannerGroupDeleteManyMutation, BannerGroupDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<BannerGroupDeleteManyMutation, BannerGroupDeleteManyMutationVariables>(BannerGroupDeleteManyDocument, baseOptions);
      }
export type BannerGroupDeleteManyMutationHookResult = ReturnType<typeof useBannerGroupDeleteManyMutation>;
export type BannerGroupDeleteManyMutationResult = ApolloReactCommon.MutationResult<BannerGroupDeleteManyMutation>;
export type BannerGroupDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<BannerGroupDeleteManyMutation, BannerGroupDeleteManyMutationVariables>;
export const BannerGetListDocument = gql`
    query BannerGetList($filterBy: BannerFilters, $orderBy: BannerOrder, $pagination: Pagination) {
  data: banners(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...BannerFragment
    }
    total: totalCount
  }
}
    ${BannerFragmentFragmentDoc}`;

/**
 * __useBannerGetListQuery__
 *
 * To run a query within a React component, call `useBannerGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBannerGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BannerGetListQuery, BannerGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<BannerGetListQuery, BannerGetListQueryVariables>(BannerGetListDocument, baseOptions);
      }
export function useBannerGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BannerGetListQuery, BannerGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BannerGetListQuery, BannerGetListQueryVariables>(BannerGetListDocument, baseOptions);
        }
export type BannerGetListQueryHookResult = ReturnType<typeof useBannerGetListQuery>;
export type BannerGetListLazyQueryHookResult = ReturnType<typeof useBannerGetListLazyQuery>;
export type BannerGetListQueryResult = ApolloReactCommon.QueryResult<BannerGetListQuery, BannerGetListQueryVariables>;
export const BannerGetDocument = gql`
    query BannerGet($id: ID!) {
  data: banner(id: $id) {
    ...BannerFragment
  }
}
    ${BannerFragmentFragmentDoc}`;

/**
 * __useBannerGetQuery__
 *
 * To run a query within a React component, call `useBannerGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBannerGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BannerGetQuery, BannerGetQueryVariables>) {
        return ApolloReactHooks.useQuery<BannerGetQuery, BannerGetQueryVariables>(BannerGetDocument, baseOptions);
      }
export function useBannerGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BannerGetQuery, BannerGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BannerGetQuery, BannerGetQueryVariables>(BannerGetDocument, baseOptions);
        }
export type BannerGetQueryHookResult = ReturnType<typeof useBannerGetQuery>;
export type BannerGetLazyQueryHookResult = ReturnType<typeof useBannerGetLazyQuery>;
export type BannerGetQueryResult = ApolloReactCommon.QueryResult<BannerGetQuery, BannerGetQueryVariables>;
export const BannerGetManyDocument = gql`
    query BannerGetMany($ids: [ID!]!) {
  data: bannersByID(ids: $ids) {
    ...BannerFragment
  }
}
    ${BannerFragmentFragmentDoc}`;

/**
 * __useBannerGetManyQuery__
 *
 * To run a query within a React component, call `useBannerGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBannerGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BannerGetManyQuery, BannerGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<BannerGetManyQuery, BannerGetManyQueryVariables>(BannerGetManyDocument, baseOptions);
      }
export function useBannerGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BannerGetManyQuery, BannerGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BannerGetManyQuery, BannerGetManyQueryVariables>(BannerGetManyDocument, baseOptions);
        }
export type BannerGetManyQueryHookResult = ReturnType<typeof useBannerGetManyQuery>;
export type BannerGetManyLazyQueryHookResult = ReturnType<typeof useBannerGetManyLazyQuery>;
export type BannerGetManyQueryResult = ApolloReactCommon.QueryResult<BannerGetManyQuery, BannerGetManyQueryVariables>;
export const BannerCreateDocument = gql`
    mutation BannerCreate($input: BannerInput!) {
  data: bannerCreate(input: $input) {
    data: banner {
      ...BannerFragment
    }
  }
}
    ${BannerFragmentFragmentDoc}`;
export type BannerCreateMutationFn = ApolloReactCommon.MutationFunction<BannerCreateMutation, BannerCreateMutationVariables>;

/**
 * __useBannerCreateMutation__
 *
 * To run a mutation, you first call `useBannerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBannerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bannerCreateMutation, { data, loading, error }] = useBannerCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBannerCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BannerCreateMutation, BannerCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<BannerCreateMutation, BannerCreateMutationVariables>(BannerCreateDocument, baseOptions);
      }
export type BannerCreateMutationHookResult = ReturnType<typeof useBannerCreateMutation>;
export type BannerCreateMutationResult = ApolloReactCommon.MutationResult<BannerCreateMutation>;
export type BannerCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<BannerCreateMutation, BannerCreateMutationVariables>;
export const BannerUpdateDocument = gql`
    mutation BannerUpdate($id: ID!, $input: BannerInput!) {
  data: bannerUpdate(id: $id, input: $input) {
    data: banner {
      ...BannerFragment
    }
  }
}
    ${BannerFragmentFragmentDoc}`;
export type BannerUpdateMutationFn = ApolloReactCommon.MutationFunction<BannerUpdateMutation, BannerUpdateMutationVariables>;

/**
 * __useBannerUpdateMutation__
 *
 * To run a mutation, you first call `useBannerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBannerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bannerUpdateMutation, { data, loading, error }] = useBannerUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBannerUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BannerUpdateMutation, BannerUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BannerUpdateMutation, BannerUpdateMutationVariables>(BannerUpdateDocument, baseOptions);
      }
export type BannerUpdateMutationHookResult = ReturnType<typeof useBannerUpdateMutation>;
export type BannerUpdateMutationResult = ApolloReactCommon.MutationResult<BannerUpdateMutation>;
export type BannerUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BannerUpdateMutation, BannerUpdateMutationVariables>;
export const BookGetListDocument = gql`
    query BookGetList($filterBy: BookFilters, $orderBy: BookOrder, $pagination: Pagination) {
  data: books(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...BookFragment
    }
    total: totalCount
  }
}
    ${BookFragmentFragmentDoc}`;

/**
 * __useBookGetListQuery__
 *
 * To run a query within a React component, call `useBookGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBookGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookGetListQuery, BookGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<BookGetListQuery, BookGetListQueryVariables>(BookGetListDocument, baseOptions);
      }
export function useBookGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookGetListQuery, BookGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookGetListQuery, BookGetListQueryVariables>(BookGetListDocument, baseOptions);
        }
export type BookGetListQueryHookResult = ReturnType<typeof useBookGetListQuery>;
export type BookGetListLazyQueryHookResult = ReturnType<typeof useBookGetListLazyQuery>;
export type BookGetListQueryResult = ApolloReactCommon.QueryResult<BookGetListQuery, BookGetListQueryVariables>;
export const BookGetDocument = gql`
    query BookGet($id: ID!) {
  data: book(id: $id) {
    ...BookFragment
  }
}
    ${BookFragmentFragmentDoc}`;

/**
 * __useBookGetQuery__
 *
 * To run a query within a React component, call `useBookGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookGetQuery, BookGetQueryVariables>) {
        return ApolloReactHooks.useQuery<BookGetQuery, BookGetQueryVariables>(BookGetDocument, baseOptions);
      }
export function useBookGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookGetQuery, BookGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookGetQuery, BookGetQueryVariables>(BookGetDocument, baseOptions);
        }
export type BookGetQueryHookResult = ReturnType<typeof useBookGetQuery>;
export type BookGetLazyQueryHookResult = ReturnType<typeof useBookGetLazyQuery>;
export type BookGetQueryResult = ApolloReactCommon.QueryResult<BookGetQuery, BookGetQueryVariables>;
export const BookGetManyDocument = gql`
    query BookGetMany($ids: [ID!]!) {
  data: booksByID(ids: $ids) {
    ...BookFragment
  }
}
    ${BookFragmentFragmentDoc}`;

/**
 * __useBookGetManyQuery__
 *
 * To run a query within a React component, call `useBookGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBookGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookGetManyQuery, BookGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<BookGetManyQuery, BookGetManyQueryVariables>(BookGetManyDocument, baseOptions);
      }
export function useBookGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookGetManyQuery, BookGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookGetManyQuery, BookGetManyQueryVariables>(BookGetManyDocument, baseOptions);
        }
export type BookGetManyQueryHookResult = ReturnType<typeof useBookGetManyQuery>;
export type BookGetManyLazyQueryHookResult = ReturnType<typeof useBookGetManyLazyQuery>;
export type BookGetManyQueryResult = ApolloReactCommon.QueryResult<BookGetManyQuery, BookGetManyQueryVariables>;
export const BookUpdateDocument = gql`
    mutation BookUpdate($id: ID!, $input: BookUpdateInput!) {
  data: bookUpdate(id: $id, input: $input) {
    data: book {
      ...BookFragment
    }
  }
}
    ${BookFragmentFragmentDoc}`;
export type BookUpdateMutationFn = ApolloReactCommon.MutationFunction<BookUpdateMutation, BookUpdateMutationVariables>;

/**
 * __useBookUpdateMutation__
 *
 * To run a mutation, you first call `useBookUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookUpdateMutation, { data, loading, error }] = useBookUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookUpdateMutation, BookUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BookUpdateMutation, BookUpdateMutationVariables>(BookUpdateDocument, baseOptions);
      }
export type BookUpdateMutationHookResult = ReturnType<typeof useBookUpdateMutation>;
export type BookUpdateMutationResult = ApolloReactCommon.MutationResult<BookUpdateMutation>;
export type BookUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BookUpdateMutation, BookUpdateMutationVariables>;
export const BookDeleteDocument = gql`
    mutation BookDelete($id: ID!) {
  data: bookDelete(id: $id) {
    data: book {
      ...BookFragment
    }
  }
}
    ${BookFragmentFragmentDoc}`;
export type BookDeleteMutationFn = ApolloReactCommon.MutationFunction<BookDeleteMutation, BookDeleteMutationVariables>;

/**
 * __useBookDeleteMutation__
 *
 * To run a mutation, you first call `useBookDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookDeleteMutation, { data, loading, error }] = useBookDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookDeleteMutation, BookDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<BookDeleteMutation, BookDeleteMutationVariables>(BookDeleteDocument, baseOptions);
      }
export type BookDeleteMutationHookResult = ReturnType<typeof useBookDeleteMutation>;
export type BookDeleteMutationResult = ApolloReactCommon.MutationResult<BookDeleteMutation>;
export type BookDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<BookDeleteMutation, BookDeleteMutationVariables>;
export const BookDeleteManyDocument = gql`
    mutation BookDeleteMany($ids: [ID!]!) {
  data: bookDeleteMany(ids: $ids) {
    data: books {
      ...BookFragment
    }
  }
}
    ${BookFragmentFragmentDoc}`;
export type BookDeleteManyMutationFn = ApolloReactCommon.MutationFunction<BookDeleteManyMutation, BookDeleteManyMutationVariables>;

/**
 * __useBookDeleteManyMutation__
 *
 * To run a mutation, you first call `useBookDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookDeleteManyMutation, { data, loading, error }] = useBookDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBookDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookDeleteManyMutation, BookDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<BookDeleteManyMutation, BookDeleteManyMutationVariables>(BookDeleteManyDocument, baseOptions);
      }
export type BookDeleteManyMutationHookResult = ReturnType<typeof useBookDeleteManyMutation>;
export type BookDeleteManyMutationResult = ApolloReactCommon.MutationResult<BookDeleteManyMutation>;
export type BookDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<BookDeleteManyMutation, BookDeleteManyMutationVariables>;
export const CampaignGetListDocument = gql`
    query CampaignGetList($filterBy: CampaignFilters, $orderBy: CampaignOrder, $pagination: Pagination) {
  data: campaigns(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...CampaignFragment
    }
    total: totalCount
  }
}
    ${CampaignFragmentFragmentDoc}`;

/**
 * __useCampaignGetListQuery__
 *
 * To run a query within a React component, call `useCampaignGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCampaignGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CampaignGetListQuery, CampaignGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<CampaignGetListQuery, CampaignGetListQueryVariables>(CampaignGetListDocument, baseOptions);
      }
export function useCampaignGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignGetListQuery, CampaignGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CampaignGetListQuery, CampaignGetListQueryVariables>(CampaignGetListDocument, baseOptions);
        }
export type CampaignGetListQueryHookResult = ReturnType<typeof useCampaignGetListQuery>;
export type CampaignGetListLazyQueryHookResult = ReturnType<typeof useCampaignGetListLazyQuery>;
export type CampaignGetListQueryResult = ApolloReactCommon.QueryResult<CampaignGetListQuery, CampaignGetListQueryVariables>;
export const CampaignGetDocument = gql`
    query CampaignGet($id: ID!) {
  data: campaign(id: $id) {
    ...CampaignDetailFragment
  }
}
    ${CampaignDetailFragmentFragmentDoc}`;

/**
 * __useCampaignGetQuery__
 *
 * To run a query within a React component, call `useCampaignGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CampaignGetQuery, CampaignGetQueryVariables>) {
        return ApolloReactHooks.useQuery<CampaignGetQuery, CampaignGetQueryVariables>(CampaignGetDocument, baseOptions);
      }
export function useCampaignGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignGetQuery, CampaignGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CampaignGetQuery, CampaignGetQueryVariables>(CampaignGetDocument, baseOptions);
        }
export type CampaignGetQueryHookResult = ReturnType<typeof useCampaignGetQuery>;
export type CampaignGetLazyQueryHookResult = ReturnType<typeof useCampaignGetLazyQuery>;
export type CampaignGetQueryResult = ApolloReactCommon.QueryResult<CampaignGetQuery, CampaignGetQueryVariables>;
export const CampaignCreateDocument = gql`
    mutation CampaignCreate($input: CampaignInput!) {
  data: campaignCreate(input: $input) {
    data: campaign {
      ...CampaignFragment
    }
  }
}
    ${CampaignFragmentFragmentDoc}`;
export type CampaignCreateMutationFn = ApolloReactCommon.MutationFunction<CampaignCreateMutation, CampaignCreateMutationVariables>;

/**
 * __useCampaignCreateMutation__
 *
 * To run a mutation, you first call `useCampaignCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignCreateMutation, { data, loading, error }] = useCampaignCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignCreateMutation, CampaignCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignCreateMutation, CampaignCreateMutationVariables>(CampaignCreateDocument, baseOptions);
      }
export type CampaignCreateMutationHookResult = ReturnType<typeof useCampaignCreateMutation>;
export type CampaignCreateMutationResult = ApolloReactCommon.MutationResult<CampaignCreateMutation>;
export type CampaignCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignCreateMutation, CampaignCreateMutationVariables>;
export const CampaignUpdateDocument = gql`
    mutation CampaignUpdate($id: ID!, $input: CampaignInput!) {
  data: campaignUpdate(id: $id, input: $input) {
    data: campaign {
      ...CampaignFragment
    }
  }
}
    ${CampaignFragmentFragmentDoc}`;
export type CampaignUpdateMutationFn = ApolloReactCommon.MutationFunction<CampaignUpdateMutation, CampaignUpdateMutationVariables>;

/**
 * __useCampaignUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignUpdateMutation, { data, loading, error }] = useCampaignUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignUpdateMutation, CampaignUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignUpdateMutation, CampaignUpdateMutationVariables>(CampaignUpdateDocument, baseOptions);
      }
export type CampaignUpdateMutationHookResult = ReturnType<typeof useCampaignUpdateMutation>;
export type CampaignUpdateMutationResult = ApolloReactCommon.MutationResult<CampaignUpdateMutation>;
export type CampaignUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignUpdateMutation, CampaignUpdateMutationVariables>;
export const CampaignUserCreateManyDocument = gql`
    mutation CampaignUserCreateMany($campaignID: ID!, $inputs: [CampaignUserCreateInput!]!) {
  campaignUserCreateMany(campaignID: $campaignID, inputs: $inputs) {
    status
  }
}
    `;
export type CampaignUserCreateManyMutationFn = ApolloReactCommon.MutationFunction<CampaignUserCreateManyMutation, CampaignUserCreateManyMutationVariables>;

/**
 * __useCampaignUserCreateManyMutation__
 *
 * To run a mutation, you first call `useCampaignUserCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignUserCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignUserCreateManyMutation, { data, loading, error }] = useCampaignUserCreateManyMutation({
 *   variables: {
 *      campaignID: // value for 'campaignID'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCampaignUserCreateManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignUserCreateManyMutation, CampaignUserCreateManyMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignUserCreateManyMutation, CampaignUserCreateManyMutationVariables>(CampaignUserCreateManyDocument, baseOptions);
      }
export type CampaignUserCreateManyMutationHookResult = ReturnType<typeof useCampaignUserCreateManyMutation>;
export type CampaignUserCreateManyMutationResult = ApolloReactCommon.MutationResult<CampaignUserCreateManyMutation>;
export type CampaignUserCreateManyMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignUserCreateManyMutation, CampaignUserCreateManyMutationVariables>;
export const CampaignUsersGetDocument = gql`
    query CampaignUsersGet($campaignID: ID!) {
  campaign(id: $campaignID) {
    id
    campaignUsers {
      joinAt
      name
      allowMarketing
      phoneNumber
      email
      user {
        createdAt
        service
        examToTake
      }
    }
  }
}
    `;

/**
 * __useCampaignUsersGetQuery__
 *
 * To run a query within a React component, call `useCampaignUsersGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignUsersGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignUsersGetQuery({
 *   variables: {
 *      campaignID: // value for 'campaignID'
 *   },
 * });
 */
export function useCampaignUsersGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CampaignUsersGetQuery, CampaignUsersGetQueryVariables>) {
        return ApolloReactHooks.useQuery<CampaignUsersGetQuery, CampaignUsersGetQueryVariables>(CampaignUsersGetDocument, baseOptions);
      }
export function useCampaignUsersGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignUsersGetQuery, CampaignUsersGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CampaignUsersGetQuery, CampaignUsersGetQueryVariables>(CampaignUsersGetDocument, baseOptions);
        }
export type CampaignUsersGetQueryHookResult = ReturnType<typeof useCampaignUsersGetQuery>;
export type CampaignUsersGetLazyQueryHookResult = ReturnType<typeof useCampaignUsersGetLazyQuery>;
export type CampaignUsersGetQueryResult = ApolloReactCommon.QueryResult<CampaignUsersGetQuery, CampaignUsersGetQueryVariables>;
export const CampaignUserDeleteManyDocument = gql`
    mutation CampaignUserDeleteMany($campaignID: ID!) {
  campaignUserDeleteMany(campaignID: $campaignID) {
    status
  }
}
    `;
export type CampaignUserDeleteManyMutationFn = ApolloReactCommon.MutationFunction<CampaignUserDeleteManyMutation, CampaignUserDeleteManyMutationVariables>;

/**
 * __useCampaignUserDeleteManyMutation__
 *
 * To run a mutation, you first call `useCampaignUserDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignUserDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignUserDeleteManyMutation, { data, loading, error }] = useCampaignUserDeleteManyMutation({
 *   variables: {
 *      campaignID: // value for 'campaignID'
 *   },
 * });
 */
export function useCampaignUserDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignUserDeleteManyMutation, CampaignUserDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignUserDeleteManyMutation, CampaignUserDeleteManyMutationVariables>(CampaignUserDeleteManyDocument, baseOptions);
      }
export type CampaignUserDeleteManyMutationHookResult = ReturnType<typeof useCampaignUserDeleteManyMutation>;
export type CampaignUserDeleteManyMutationResult = ApolloReactCommon.MutationResult<CampaignUserDeleteManyMutation>;
export type CampaignUserDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignUserDeleteManyMutation, CampaignUserDeleteManyMutationVariables>;
export const CompanyGetListDocument = gql`
    query CompanyGetList($filterBy: CompanyFilters, $orderBy: CompanyOrder, $pagination: Pagination) {
  data: companies(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...CompanyFragment
    }
    total: totalCount
  }
}
    ${CompanyFragmentFragmentDoc}`;

/**
 * __useCompanyGetListQuery__
 *
 * To run a query within a React component, call `useCompanyGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCompanyGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyGetListQuery, CompanyGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyGetListQuery, CompanyGetListQueryVariables>(CompanyGetListDocument, baseOptions);
      }
export function useCompanyGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyGetListQuery, CompanyGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyGetListQuery, CompanyGetListQueryVariables>(CompanyGetListDocument, baseOptions);
        }
export type CompanyGetListQueryHookResult = ReturnType<typeof useCompanyGetListQuery>;
export type CompanyGetListLazyQueryHookResult = ReturnType<typeof useCompanyGetListLazyQuery>;
export type CompanyGetListQueryResult = ApolloReactCommon.QueryResult<CompanyGetListQuery, CompanyGetListQueryVariables>;
export const CompanyGetDocument = gql`
    query CompanyGet($id: ID!) {
  data: company(id: $id) {
    ...CompanyFragment
  }
}
    ${CompanyFragmentFragmentDoc}`;

/**
 * __useCompanyGetQuery__
 *
 * To run a query within a React component, call `useCompanyGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyGetQuery, CompanyGetQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyGetQuery, CompanyGetQueryVariables>(CompanyGetDocument, baseOptions);
      }
export function useCompanyGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyGetQuery, CompanyGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyGetQuery, CompanyGetQueryVariables>(CompanyGetDocument, baseOptions);
        }
export type CompanyGetQueryHookResult = ReturnType<typeof useCompanyGetQuery>;
export type CompanyGetLazyQueryHookResult = ReturnType<typeof useCompanyGetLazyQuery>;
export type CompanyGetQueryResult = ApolloReactCommon.QueryResult<CompanyGetQuery, CompanyGetQueryVariables>;
export const CompanyGetManyDocument = gql`
    query CompanyGetMany($ids: [ID!]!) {
  data: companiesByID(ids: $ids) {
    ...CompanyFragment
  }
}
    ${CompanyFragmentFragmentDoc}`;

/**
 * __useCompanyGetManyQuery__
 *
 * To run a query within a React component, call `useCompanyGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCompanyGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyGetManyQuery, CompanyGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyGetManyQuery, CompanyGetManyQueryVariables>(CompanyGetManyDocument, baseOptions);
      }
export function useCompanyGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyGetManyQuery, CompanyGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyGetManyQuery, CompanyGetManyQueryVariables>(CompanyGetManyDocument, baseOptions);
        }
export type CompanyGetManyQueryHookResult = ReturnType<typeof useCompanyGetManyQuery>;
export type CompanyGetManyLazyQueryHookResult = ReturnType<typeof useCompanyGetManyLazyQuery>;
export type CompanyGetManyQueryResult = ApolloReactCommon.QueryResult<CompanyGetManyQuery, CompanyGetManyQueryVariables>;
export const CompanyCreateDocument = gql`
    mutation CompanyCreate($input: CompanyInput!) {
  data: companyCreate(input: $input) {
    data: company {
      ...CompanyFragment
    }
  }
}
    ${CompanyFragmentFragmentDoc}`;
export type CompanyCreateMutationFn = ApolloReactCommon.MutationFunction<CompanyCreateMutation, CompanyCreateMutationVariables>;

/**
 * __useCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateMutation, { data, loading, error }] = useCompanyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyCreateMutation, CompanyCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyCreateMutation, CompanyCreateMutationVariables>(CompanyCreateDocument, baseOptions);
      }
export type CompanyCreateMutationHookResult = ReturnType<typeof useCompanyCreateMutation>;
export type CompanyCreateMutationResult = ApolloReactCommon.MutationResult<CompanyCreateMutation>;
export type CompanyCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyCreateMutation, CompanyCreateMutationVariables>;
export const CompanyUpdateDocument = gql`
    mutation CompanyUpdate($id: ID!, $input: CompanyInput!) {
  data: companyUpdate(id: $id, input: $input) {
    data: company {
      ...CompanyFragment
    }
  }
}
    ${CompanyFragmentFragmentDoc}`;
export type CompanyUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyUpdateMutation, CompanyUpdateMutationVariables>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyUpdateMutation, CompanyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyUpdateMutation, CompanyUpdateMutationVariables>(CompanyUpdateDocument, baseOptions);
      }
export type CompanyUpdateMutationHookResult = ReturnType<typeof useCompanyUpdateMutation>;
export type CompanyUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyUpdateMutation, CompanyUpdateMutationVariables>;
export const CompanyDeleteDocument = gql`
    mutation CompanyDelete($id: ID!) {
  data: companyDelete(id: $id) {
    data: company {
      ...CompanyFragment
    }
  }
}
    ${CompanyFragmentFragmentDoc}`;
export type CompanyDeleteMutationFn = ApolloReactCommon.MutationFunction<CompanyDeleteMutation, CompanyDeleteMutationVariables>;

/**
 * __useCompanyDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyDeleteMutation, { data, loading, error }] = useCompanyDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyDeleteMutation, CompanyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyDeleteMutation, CompanyDeleteMutationVariables>(CompanyDeleteDocument, baseOptions);
      }
export type CompanyDeleteMutationHookResult = ReturnType<typeof useCompanyDeleteMutation>;
export type CompanyDeleteMutationResult = ApolloReactCommon.MutationResult<CompanyDeleteMutation>;
export type CompanyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyDeleteMutation, CompanyDeleteMutationVariables>;
export const CompanyDeleteManyDocument = gql`
    mutation CompanyDeleteMany($ids: [ID!]!) {
  data: companyDeleteMany(ids: $ids) {
    data: companies {
      ...CompanyFragment
    }
  }
}
    ${CompanyFragmentFragmentDoc}`;
export type CompanyDeleteManyMutationFn = ApolloReactCommon.MutationFunction<CompanyDeleteManyMutation, CompanyDeleteManyMutationVariables>;

/**
 * __useCompanyDeleteManyMutation__
 *
 * To run a mutation, you first call `useCompanyDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyDeleteManyMutation, { data, loading, error }] = useCompanyDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCompanyDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyDeleteManyMutation, CompanyDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyDeleteManyMutation, CompanyDeleteManyMutationVariables>(CompanyDeleteManyDocument, baseOptions);
      }
export type CompanyDeleteManyMutationHookResult = ReturnType<typeof useCompanyDeleteManyMutation>;
export type CompanyDeleteManyMutationResult = ApolloReactCommon.MutationResult<CompanyDeleteManyMutation>;
export type CompanyDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyDeleteManyMutation, CompanyDeleteManyMutationVariables>;
export const ContentCurationGetDocument = gql`
    query ContentCurationGet($id: ID!) {
  data: contentCuration(id: $id) {
    ...ContentCurationFragment
  }
}
    ${ContentCurationFragmentFragmentDoc}`;

/**
 * __useContentCurationGetQuery__
 *
 * To run a query within a React component, call `useContentCurationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentCurationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentCurationGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentCurationGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentCurationGetQuery, ContentCurationGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentCurationGetQuery, ContentCurationGetQueryVariables>(ContentCurationGetDocument, baseOptions);
      }
export function useContentCurationGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentCurationGetQuery, ContentCurationGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentCurationGetQuery, ContentCurationGetQueryVariables>(ContentCurationGetDocument, baseOptions);
        }
export type ContentCurationGetQueryHookResult = ReturnType<typeof useContentCurationGetQuery>;
export type ContentCurationGetLazyQueryHookResult = ReturnType<typeof useContentCurationGetLazyQuery>;
export type ContentCurationGetQueryResult = ApolloReactCommon.QueryResult<ContentCurationGetQuery, ContentCurationGetQueryVariables>;
export const ContentCurationGetListDocument = gql`
    query ContentCurationGetList($filterBy: ContentCurationFilters, $orderBy: ContentCurationOrder, $pagination: Pagination) {
  data: contentCurations(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ContentCurationFragment
    }
    total: totalCount
  }
}
    ${ContentCurationFragmentFragmentDoc}`;

/**
 * __useContentCurationGetListQuery__
 *
 * To run a query within a React component, call `useContentCurationGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentCurationGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentCurationGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useContentCurationGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentCurationGetListQuery, ContentCurationGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentCurationGetListQuery, ContentCurationGetListQueryVariables>(ContentCurationGetListDocument, baseOptions);
      }
export function useContentCurationGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentCurationGetListQuery, ContentCurationGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentCurationGetListQuery, ContentCurationGetListQueryVariables>(ContentCurationGetListDocument, baseOptions);
        }
export type ContentCurationGetListQueryHookResult = ReturnType<typeof useContentCurationGetListQuery>;
export type ContentCurationGetListLazyQueryHookResult = ReturnType<typeof useContentCurationGetListLazyQuery>;
export type ContentCurationGetListQueryResult = ApolloReactCommon.QueryResult<ContentCurationGetListQuery, ContentCurationGetListQueryVariables>;
export const ContentCurationCreateDocument = gql`
    mutation ContentCurationCreate($input: ContentCurationInput!) {
  data: contentCurationCreate(input: $input) {
    data: contentCuration {
      ...ContentCurationFragment
    }
  }
}
    ${ContentCurationFragmentFragmentDoc}`;
export type ContentCurationCreateMutationFn = ApolloReactCommon.MutationFunction<ContentCurationCreateMutation, ContentCurationCreateMutationVariables>;

/**
 * __useContentCurationCreateMutation__
 *
 * To run a mutation, you first call `useContentCurationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCurationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCurationCreateMutation, { data, loading, error }] = useContentCurationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentCurationCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContentCurationCreateMutation, ContentCurationCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ContentCurationCreateMutation, ContentCurationCreateMutationVariables>(ContentCurationCreateDocument, baseOptions);
      }
export type ContentCurationCreateMutationHookResult = ReturnType<typeof useContentCurationCreateMutation>;
export type ContentCurationCreateMutationResult = ApolloReactCommon.MutationResult<ContentCurationCreateMutation>;
export type ContentCurationCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ContentCurationCreateMutation, ContentCurationCreateMutationVariables>;
export const ContentCurationUpdateDocument = gql`
    mutation ContentCurationUpdate($id: ID!, $input: ContentCurationInput!) {
  data: contentCurationUpdate(id: $id, input: $input) {
    data: contentCuration {
      ...ContentCurationFragment
    }
  }
}
    ${ContentCurationFragmentFragmentDoc}`;
export type ContentCurationUpdateMutationFn = ApolloReactCommon.MutationFunction<ContentCurationUpdateMutation, ContentCurationUpdateMutationVariables>;

/**
 * __useContentCurationUpdateMutation__
 *
 * To run a mutation, you first call `useContentCurationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCurationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCurationUpdateMutation, { data, loading, error }] = useContentCurationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentCurationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContentCurationUpdateMutation, ContentCurationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ContentCurationUpdateMutation, ContentCurationUpdateMutationVariables>(ContentCurationUpdateDocument, baseOptions);
      }
export type ContentCurationUpdateMutationHookResult = ReturnType<typeof useContentCurationUpdateMutation>;
export type ContentCurationUpdateMutationResult = ApolloReactCommon.MutationResult<ContentCurationUpdateMutation>;
export type ContentCurationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ContentCurationUpdateMutation, ContentCurationUpdateMutationVariables>;
export const ContentCurationDeleteDocument = gql`
    mutation ContentCurationDelete($id: ID!) {
  data: contentCurationDelete(id: $id) {
    data: contentCuration {
      ...ContentCurationFragment
    }
  }
}
    ${ContentCurationFragmentFragmentDoc}`;
export type ContentCurationDeleteMutationFn = ApolloReactCommon.MutationFunction<ContentCurationDeleteMutation, ContentCurationDeleteMutationVariables>;

/**
 * __useContentCurationDeleteMutation__
 *
 * To run a mutation, you first call `useContentCurationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCurationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCurationDeleteMutation, { data, loading, error }] = useContentCurationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentCurationDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContentCurationDeleteMutation, ContentCurationDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ContentCurationDeleteMutation, ContentCurationDeleteMutationVariables>(ContentCurationDeleteDocument, baseOptions);
      }
export type ContentCurationDeleteMutationHookResult = ReturnType<typeof useContentCurationDeleteMutation>;
export type ContentCurationDeleteMutationResult = ApolloReactCommon.MutationResult<ContentCurationDeleteMutation>;
export type ContentCurationDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ContentCurationDeleteMutation, ContentCurationDeleteMutationVariables>;
export const ContentCurationDeleteManyDocument = gql`
    mutation ContentCurationDeleteMany($ids: [ID!]!) {
  data: contentCurationDeleteMany(ids: $ids) {
    data: contentCurations {
      ...ContentCurationFragment
    }
  }
}
    ${ContentCurationFragmentFragmentDoc}`;
export type ContentCurationDeleteManyMutationFn = ApolloReactCommon.MutationFunction<ContentCurationDeleteManyMutation, ContentCurationDeleteManyMutationVariables>;

/**
 * __useContentCurationDeleteManyMutation__
 *
 * To run a mutation, you first call `useContentCurationDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCurationDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCurationDeleteManyMutation, { data, loading, error }] = useContentCurationDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useContentCurationDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContentCurationDeleteManyMutation, ContentCurationDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<ContentCurationDeleteManyMutation, ContentCurationDeleteManyMutationVariables>(ContentCurationDeleteManyDocument, baseOptions);
      }
export type ContentCurationDeleteManyMutationHookResult = ReturnType<typeof useContentCurationDeleteManyMutation>;
export type ContentCurationDeleteManyMutationResult = ApolloReactCommon.MutationResult<ContentCurationDeleteManyMutation>;
export type ContentCurationDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<ContentCurationDeleteManyMutation, ContentCurationDeleteManyMutationVariables>;
export const ContentPurchaseGetDocument = gql`
    query ContentPurchaseGet($id: ID!) {
  data: contentPurchase(id: $id) {
    ...ContentPurchaseFragment
  }
}
    ${ContentPurchaseFragmentFragmentDoc}`;

/**
 * __useContentPurchaseGetQuery__
 *
 * To run a query within a React component, call `useContentPurchaseGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentPurchaseGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentPurchaseGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentPurchaseGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentPurchaseGetQuery, ContentPurchaseGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentPurchaseGetQuery, ContentPurchaseGetQueryVariables>(ContentPurchaseGetDocument, baseOptions);
      }
export function useContentPurchaseGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentPurchaseGetQuery, ContentPurchaseGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentPurchaseGetQuery, ContentPurchaseGetQueryVariables>(ContentPurchaseGetDocument, baseOptions);
        }
export type ContentPurchaseGetQueryHookResult = ReturnType<typeof useContentPurchaseGetQuery>;
export type ContentPurchaseGetLazyQueryHookResult = ReturnType<typeof useContentPurchaseGetLazyQuery>;
export type ContentPurchaseGetQueryResult = ApolloReactCommon.QueryResult<ContentPurchaseGetQuery, ContentPurchaseGetQueryVariables>;
export const ContentPurchaseListDocument = gql`
    query ContentPurchaseList($filterBy: ContentPurchaseFilters, $orderBy: ContentPurchaseOrder, $pagination: Pagination) {
  data: contentPurchases(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ContentPurchaseFragment
    }
    total: totalCount
  }
}
    ${ContentPurchaseFragmentFragmentDoc}`;

/**
 * __useContentPurchaseListQuery__
 *
 * To run a query within a React component, call `useContentPurchaseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentPurchaseListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentPurchaseListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useContentPurchaseListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentPurchaseListQuery, ContentPurchaseListQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentPurchaseListQuery, ContentPurchaseListQueryVariables>(ContentPurchaseListDocument, baseOptions);
      }
export function useContentPurchaseListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentPurchaseListQuery, ContentPurchaseListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentPurchaseListQuery, ContentPurchaseListQueryVariables>(ContentPurchaseListDocument, baseOptions);
        }
export type ContentPurchaseListQueryHookResult = ReturnType<typeof useContentPurchaseListQuery>;
export type ContentPurchaseListLazyQueryHookResult = ReturnType<typeof useContentPurchaseListLazyQuery>;
export type ContentPurchaseListQueryResult = ApolloReactCommon.QueryResult<ContentPurchaseListQuery, ContentPurchaseListQueryVariables>;
export const ContentGetListDocument = gql`
    query ContentGetList($filterBy: ContentFilters, $orderBy: ContentOrder, $pagination: Pagination) {
  data: contents(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ContentFragment
    }
    total: totalCount
  }
}
    ${ContentFragmentFragmentDoc}`;

/**
 * __useContentGetListQuery__
 *
 * To run a query within a React component, call `useContentGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useContentGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentGetListQuery, ContentGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentGetListQuery, ContentGetListQueryVariables>(ContentGetListDocument, baseOptions);
      }
export function useContentGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentGetListQuery, ContentGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentGetListQuery, ContentGetListQueryVariables>(ContentGetListDocument, baseOptions);
        }
export type ContentGetListQueryHookResult = ReturnType<typeof useContentGetListQuery>;
export type ContentGetListLazyQueryHookResult = ReturnType<typeof useContentGetListLazyQuery>;
export type ContentGetListQueryResult = ApolloReactCommon.QueryResult<ContentGetListQuery, ContentGetListQueryVariables>;
export const ContentGetDocument = gql`
    query ContentGet($id: ID!) {
  data: content(id: $id) {
    ...ContentFragment
  }
}
    ${ContentFragmentFragmentDoc}`;

/**
 * __useContentGetQuery__
 *
 * To run a query within a React component, call `useContentGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentGetQuery, ContentGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentGetQuery, ContentGetQueryVariables>(ContentGetDocument, baseOptions);
      }
export function useContentGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentGetQuery, ContentGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentGetQuery, ContentGetQueryVariables>(ContentGetDocument, baseOptions);
        }
export type ContentGetQueryHookResult = ReturnType<typeof useContentGetQuery>;
export type ContentGetLazyQueryHookResult = ReturnType<typeof useContentGetLazyQuery>;
export type ContentGetQueryResult = ApolloReactCommon.QueryResult<ContentGetQuery, ContentGetQueryVariables>;
export const ContentGetWithValidateDocument = gql`
    query ContentGetWithValidate($id: ID!, $contentType: ContentType) {
  data: contentWithValidate(id: $id, contentType: $contentType) {
    ...ContentFragment
  }
}
    ${ContentFragmentFragmentDoc}`;

/**
 * __useContentGetWithValidateQuery__
 *
 * To run a query within a React component, call `useContentGetWithValidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentGetWithValidateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentGetWithValidateQuery({
 *   variables: {
 *      id: // value for 'id'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useContentGetWithValidateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentGetWithValidateQuery, ContentGetWithValidateQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentGetWithValidateQuery, ContentGetWithValidateQueryVariables>(ContentGetWithValidateDocument, baseOptions);
      }
export function useContentGetWithValidateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentGetWithValidateQuery, ContentGetWithValidateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentGetWithValidateQuery, ContentGetWithValidateQueryVariables>(ContentGetWithValidateDocument, baseOptions);
        }
export type ContentGetWithValidateQueryHookResult = ReturnType<typeof useContentGetWithValidateQuery>;
export type ContentGetWithValidateLazyQueryHookResult = ReturnType<typeof useContentGetWithValidateLazyQuery>;
export type ContentGetWithValidateQueryResult = ApolloReactCommon.QueryResult<ContentGetWithValidateQuery, ContentGetWithValidateQueryVariables>;
export const ContentGetManyDocument = gql`
    query ContentGetMany($ids: [ID!]!) {
  data: contentsByID(ids: $ids) {
    ...ContentFragment
  }
}
    ${ContentFragmentFragmentDoc}`;

/**
 * __useContentGetManyQuery__
 *
 * To run a query within a React component, call `useContentGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useContentGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentGetManyQuery, ContentGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentGetManyQuery, ContentGetManyQueryVariables>(ContentGetManyDocument, baseOptions);
      }
export function useContentGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentGetManyQuery, ContentGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentGetManyQuery, ContentGetManyQueryVariables>(ContentGetManyDocument, baseOptions);
        }
export type ContentGetManyQueryHookResult = ReturnType<typeof useContentGetManyQuery>;
export type ContentGetManyLazyQueryHookResult = ReturnType<typeof useContentGetManyLazyQuery>;
export type ContentGetManyQueryResult = ApolloReactCommon.QueryResult<ContentGetManyQuery, ContentGetManyQueryVariables>;
export const ContentCreateDocument = gql`
    mutation ContentCreate($input: ContentCreateInput!) {
  data: contentCreate(input: $input) {
    data: content {
      ...ContentFragment
    }
  }
}
    ${ContentFragmentFragmentDoc}`;
export type ContentCreateMutationFn = ApolloReactCommon.MutationFunction<ContentCreateMutation, ContentCreateMutationVariables>;

/**
 * __useContentCreateMutation__
 *
 * To run a mutation, you first call `useContentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentCreateMutation, { data, loading, error }] = useContentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContentCreateMutation, ContentCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ContentCreateMutation, ContentCreateMutationVariables>(ContentCreateDocument, baseOptions);
      }
export type ContentCreateMutationHookResult = ReturnType<typeof useContentCreateMutation>;
export type ContentCreateMutationResult = ApolloReactCommon.MutationResult<ContentCreateMutation>;
export type ContentCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ContentCreateMutation, ContentCreateMutationVariables>;
export const ContentUpdateDocument = gql`
    mutation ContentUpdate($id: ID!, $input: ContentUpdateInput!) {
  data: contentUpdate(id: $id, input: $input) {
    data: content {
      ...ContentFragment
    }
  }
}
    ${ContentFragmentFragmentDoc}`;
export type ContentUpdateMutationFn = ApolloReactCommon.MutationFunction<ContentUpdateMutation, ContentUpdateMutationVariables>;

/**
 * __useContentUpdateMutation__
 *
 * To run a mutation, you first call `useContentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentUpdateMutation, { data, loading, error }] = useContentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContentUpdateMutation, ContentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ContentUpdateMutation, ContentUpdateMutationVariables>(ContentUpdateDocument, baseOptions);
      }
export type ContentUpdateMutationHookResult = ReturnType<typeof useContentUpdateMutation>;
export type ContentUpdateMutationResult = ApolloReactCommon.MutationResult<ContentUpdateMutation>;
export type ContentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ContentUpdateMutation, ContentUpdateMutationVariables>;
export const ContentDeleteDocument = gql`
    mutation ContentDelete($id: ID!) {
  data: contentDelete(id: $id) {
    data: content {
      ...ContentFragment
    }
  }
}
    ${ContentFragmentFragmentDoc}`;
export type ContentDeleteMutationFn = ApolloReactCommon.MutationFunction<ContentDeleteMutation, ContentDeleteMutationVariables>;

/**
 * __useContentDeleteMutation__
 *
 * To run a mutation, you first call `useContentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentDeleteMutation, { data, loading, error }] = useContentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContentDeleteMutation, ContentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ContentDeleteMutation, ContentDeleteMutationVariables>(ContentDeleteDocument, baseOptions);
      }
export type ContentDeleteMutationHookResult = ReturnType<typeof useContentDeleteMutation>;
export type ContentDeleteMutationResult = ApolloReactCommon.MutationResult<ContentDeleteMutation>;
export type ContentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ContentDeleteMutation, ContentDeleteMutationVariables>;
export const ContentDeleteManyDocument = gql`
    mutation ContentDeleteMany($ids: [ID!]!) {
  data: contentDeleteMany(ids: $ids) {
    data: contents {
      ...ContentFragment
    }
  }
}
    ${ContentFragmentFragmentDoc}`;
export type ContentDeleteManyMutationFn = ApolloReactCommon.MutationFunction<ContentDeleteManyMutation, ContentDeleteManyMutationVariables>;

/**
 * __useContentDeleteManyMutation__
 *
 * To run a mutation, you first call `useContentDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentDeleteManyMutation, { data, loading, error }] = useContentDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useContentDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContentDeleteManyMutation, ContentDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<ContentDeleteManyMutation, ContentDeleteManyMutationVariables>(ContentDeleteManyDocument, baseOptions);
      }
export type ContentDeleteManyMutationHookResult = ReturnType<typeof useContentDeleteManyMutation>;
export type ContentDeleteManyMutationResult = ApolloReactCommon.MutationResult<ContentDeleteManyMutation>;
export type ContentDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<ContentDeleteManyMutation, ContentDeleteManyMutationVariables>;
export const BenefitDialog_CouponNumberGetDocument = gql`
    query BenefitDialog_CouponNumberGet($number: String!) {
  couponNumber(number: $number) {
    ...CouponNumberFragment
  }
}
    ${CouponNumberFragmentFragmentDoc}`;

/**
 * __useBenefitDialog_CouponNumberGetQuery__
 *
 * To run a query within a React component, call `useBenefitDialog_CouponNumberGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefitDialog_CouponNumberGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefitDialog_CouponNumberGetQuery({
 *   variables: {
 *      number: // value for 'number'
 *   },
 * });
 */
export function useBenefitDialog_CouponNumberGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BenefitDialog_CouponNumberGetQuery, BenefitDialog_CouponNumberGetQueryVariables>) {
        return ApolloReactHooks.useQuery<BenefitDialog_CouponNumberGetQuery, BenefitDialog_CouponNumberGetQueryVariables>(BenefitDialog_CouponNumberGetDocument, baseOptions);
      }
export function useBenefitDialog_CouponNumberGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenefitDialog_CouponNumberGetQuery, BenefitDialog_CouponNumberGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BenefitDialog_CouponNumberGetQuery, BenefitDialog_CouponNumberGetQueryVariables>(BenefitDialog_CouponNumberGetDocument, baseOptions);
        }
export type BenefitDialog_CouponNumberGetQueryHookResult = ReturnType<typeof useBenefitDialog_CouponNumberGetQuery>;
export type BenefitDialog_CouponNumberGetLazyQueryHookResult = ReturnType<typeof useBenefitDialog_CouponNumberGetLazyQuery>;
export type BenefitDialog_CouponNumberGetQueryResult = ApolloReactCommon.QueryResult<BenefitDialog_CouponNumberGetQuery, BenefitDialog_CouponNumberGetQueryVariables>;
export const CouponGetListDocument = gql`
    query CouponGetList($filterBy: CouponFilters, $orderBy: CouponOrder, $pagination: Pagination) {
  data: coupons(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...CouponFragment
    }
    total: totalCount
  }
}
    ${CouponFragmentFragmentDoc}`;

/**
 * __useCouponGetListQuery__
 *
 * To run a query within a React component, call `useCouponGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCouponGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CouponGetListQuery, CouponGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<CouponGetListQuery, CouponGetListQueryVariables>(CouponGetListDocument, baseOptions);
      }
export function useCouponGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponGetListQuery, CouponGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CouponGetListQuery, CouponGetListQueryVariables>(CouponGetListDocument, baseOptions);
        }
export type CouponGetListQueryHookResult = ReturnType<typeof useCouponGetListQuery>;
export type CouponGetListLazyQueryHookResult = ReturnType<typeof useCouponGetListLazyQuery>;
export type CouponGetListQueryResult = ApolloReactCommon.QueryResult<CouponGetListQuery, CouponGetListQueryVariables>;
export const CouponGetDocument = gql`
    query CouponGet($id: ID!) {
  data: coupon(id: $id) {
    ...CouponDetailFragment
  }
}
    ${CouponDetailFragmentFragmentDoc}`;

/**
 * __useCouponGetQuery__
 *
 * To run a query within a React component, call `useCouponGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCouponGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CouponGetQuery, CouponGetQueryVariables>) {
        return ApolloReactHooks.useQuery<CouponGetQuery, CouponGetQueryVariables>(CouponGetDocument, baseOptions);
      }
export function useCouponGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponGetQuery, CouponGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CouponGetQuery, CouponGetQueryVariables>(CouponGetDocument, baseOptions);
        }
export type CouponGetQueryHookResult = ReturnType<typeof useCouponGetQuery>;
export type CouponGetLazyQueryHookResult = ReturnType<typeof useCouponGetLazyQuery>;
export type CouponGetQueryResult = ApolloReactCommon.QueryResult<CouponGetQuery, CouponGetQueryVariables>;
export const CouponUpdateDocument = gql`
    mutation CouponUpdate($id: ID!, $input: CouponInput!) {
  data: couponUpdate(id: $id, input: $input) {
    data: coupon {
      ...CouponFragment
    }
  }
}
    ${CouponFragmentFragmentDoc}`;
export type CouponUpdateMutationFn = ApolloReactCommon.MutationFunction<CouponUpdateMutation, CouponUpdateMutationVariables>;

/**
 * __useCouponUpdateMutation__
 *
 * To run a mutation, you first call `useCouponUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponUpdateMutation, { data, loading, error }] = useCouponUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCouponUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CouponUpdateMutation, CouponUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CouponUpdateMutation, CouponUpdateMutationVariables>(CouponUpdateDocument, baseOptions);
      }
export type CouponUpdateMutationHookResult = ReturnType<typeof useCouponUpdateMutation>;
export type CouponUpdateMutationResult = ApolloReactCommon.MutationResult<CouponUpdateMutation>;
export type CouponUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CouponUpdateMutation, CouponUpdateMutationVariables>;
export const CouponDeleteDocument = gql`
    mutation CouponDelete($id: ID!) {
  data: couponDelete(id: $id) {
    data: coupon {
      ...CouponFragment
    }
  }
}
    ${CouponFragmentFragmentDoc}`;
export type CouponDeleteMutationFn = ApolloReactCommon.MutationFunction<CouponDeleteMutation, CouponDeleteMutationVariables>;

/**
 * __useCouponDeleteMutation__
 *
 * To run a mutation, you first call `useCouponDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponDeleteMutation, { data, loading, error }] = useCouponDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCouponDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CouponDeleteMutation, CouponDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CouponDeleteMutation, CouponDeleteMutationVariables>(CouponDeleteDocument, baseOptions);
      }
export type CouponDeleteMutationHookResult = ReturnType<typeof useCouponDeleteMutation>;
export type CouponDeleteMutationResult = ApolloReactCommon.MutationResult<CouponDeleteMutation>;
export type CouponDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CouponDeleteMutation, CouponDeleteMutationVariables>;
export const ConnectedContentDeleteDocument = gql`
    mutation ConnectedContentDelete($couponContentMapID: ID!) {
  couponConnectedContentDelete(id: $couponContentMapID) {
    status
  }
}
    `;
export type ConnectedContentDeleteMutationFn = ApolloReactCommon.MutationFunction<ConnectedContentDeleteMutation, ConnectedContentDeleteMutationVariables>;

/**
 * __useConnectedContentDeleteMutation__
 *
 * To run a mutation, you first call `useConnectedContentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectedContentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectedContentDeleteMutation, { data, loading, error }] = useConnectedContentDeleteMutation({
 *   variables: {
 *      couponContentMapID: // value for 'couponContentMapID'
 *   },
 * });
 */
export function useConnectedContentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectedContentDeleteMutation, ConnectedContentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ConnectedContentDeleteMutation, ConnectedContentDeleteMutationVariables>(ConnectedContentDeleteDocument, baseOptions);
      }
export type ConnectedContentDeleteMutationHookResult = ReturnType<typeof useConnectedContentDeleteMutation>;
export type ConnectedContentDeleteMutationResult = ApolloReactCommon.MutationResult<ConnectedContentDeleteMutation>;
export type ConnectedContentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectedContentDeleteMutation, ConnectedContentDeleteMutationVariables>;
export const ConnectedContentCreateDocument = gql`
    mutation ConnectedContentCreate($couponNumber: String!, $contentID: ID!) {
  couponConnectedContentCreate(couponNumber: $couponNumber, contentID: $contentID) {
    status
  }
}
    `;
export type ConnectedContentCreateMutationFn = ApolloReactCommon.MutationFunction<ConnectedContentCreateMutation, ConnectedContentCreateMutationVariables>;

/**
 * __useConnectedContentCreateMutation__
 *
 * To run a mutation, you first call `useConnectedContentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectedContentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectedContentCreateMutation, { data, loading, error }] = useConnectedContentCreateMutation({
 *   variables: {
 *      couponNumber: // value for 'couponNumber'
 *      contentID: // value for 'contentID'
 *   },
 * });
 */
export function useConnectedContentCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConnectedContentCreateMutation, ConnectedContentCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ConnectedContentCreateMutation, ConnectedContentCreateMutationVariables>(ConnectedContentCreateDocument, baseOptions);
      }
export type ConnectedContentCreateMutationHookResult = ReturnType<typeof useConnectedContentCreateMutation>;
export type ConnectedContentCreateMutationResult = ApolloReactCommon.MutationResult<ConnectedContentCreateMutation>;
export type ConnectedContentCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ConnectedContentCreateMutation, ConnectedContentCreateMutationVariables>;
export const EventCampaignGetDocument = gql`
    query EventCampaignGet($id: ID!) {
  data: campaign(id: $id) {
    ...EventCampaignDetailFragment
  }
}
    ${EventCampaignDetailFragmentFragmentDoc}`;

/**
 * __useEventCampaignGetQuery__
 *
 * To run a query within a React component, call `useEventCampaignGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventCampaignGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventCampaignGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventCampaignGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventCampaignGetQuery, EventCampaignGetQueryVariables>) {
        return ApolloReactHooks.useQuery<EventCampaignGetQuery, EventCampaignGetQueryVariables>(EventCampaignGetDocument, baseOptions);
      }
export function useEventCampaignGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventCampaignGetQuery, EventCampaignGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventCampaignGetQuery, EventCampaignGetQueryVariables>(EventCampaignGetDocument, baseOptions);
        }
export type EventCampaignGetQueryHookResult = ReturnType<typeof useEventCampaignGetQuery>;
export type EventCampaignGetLazyQueryHookResult = ReturnType<typeof useEventCampaignGetLazyQuery>;
export type EventCampaignGetQueryResult = ApolloReactCommon.QueryResult<EventCampaignGetQuery, EventCampaignGetQueryVariables>;
export const CampaignBenefitCreateDocument = gql`
    mutation CampaignBenefitCreate($input: CampaignBenefitInput!) {
  campaignBenefitCreate(input: $input) {
    status
  }
}
    `;
export type CampaignBenefitCreateMutationFn = ApolloReactCommon.MutationFunction<CampaignBenefitCreateMutation, CampaignBenefitCreateMutationVariables>;

/**
 * __useCampaignBenefitCreateMutation__
 *
 * To run a mutation, you first call `useCampaignBenefitCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignBenefitCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignBenefitCreateMutation, { data, loading, error }] = useCampaignBenefitCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignBenefitCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignBenefitCreateMutation, CampaignBenefitCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignBenefitCreateMutation, CampaignBenefitCreateMutationVariables>(CampaignBenefitCreateDocument, baseOptions);
      }
export type CampaignBenefitCreateMutationHookResult = ReturnType<typeof useCampaignBenefitCreateMutation>;
export type CampaignBenefitCreateMutationResult = ApolloReactCommon.MutationResult<CampaignBenefitCreateMutation>;
export type CampaignBenefitCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignBenefitCreateMutation, CampaignBenefitCreateMutationVariables>;
export const CampaignBenefitUpdateDocument = gql`
    mutation CampaignBenefitUpdate($benefitID: ID!, $input: CampaignBenefitInput!) {
  campaignBenefitUpdate(benefitID: $benefitID, input: $input) {
    status
  }
}
    `;
export type CampaignBenefitUpdateMutationFn = ApolloReactCommon.MutationFunction<CampaignBenefitUpdateMutation, CampaignBenefitUpdateMutationVariables>;

/**
 * __useCampaignBenefitUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignBenefitUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignBenefitUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignBenefitUpdateMutation, { data, loading, error }] = useCampaignBenefitUpdateMutation({
 *   variables: {
 *      benefitID: // value for 'benefitID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignBenefitUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignBenefitUpdateMutation, CampaignBenefitUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignBenefitUpdateMutation, CampaignBenefitUpdateMutationVariables>(CampaignBenefitUpdateDocument, baseOptions);
      }
export type CampaignBenefitUpdateMutationHookResult = ReturnType<typeof useCampaignBenefitUpdateMutation>;
export type CampaignBenefitUpdateMutationResult = ApolloReactCommon.MutationResult<CampaignBenefitUpdateMutation>;
export type CampaignBenefitUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignBenefitUpdateMutation, CampaignBenefitUpdateMutationVariables>;
export const CampaignBenefitDeleteDocument = gql`
    mutation CampaignBenefitDelete($benefitID: ID!) {
  campaignBenefitDelete(benefitID: $benefitID) {
    status
  }
}
    `;
export type CampaignBenefitDeleteMutationFn = ApolloReactCommon.MutationFunction<CampaignBenefitDeleteMutation, CampaignBenefitDeleteMutationVariables>;

/**
 * __useCampaignBenefitDeleteMutation__
 *
 * To run a mutation, you first call `useCampaignBenefitDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignBenefitDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignBenefitDeleteMutation, { data, loading, error }] = useCampaignBenefitDeleteMutation({
 *   variables: {
 *      benefitID: // value for 'benefitID'
 *   },
 * });
 */
export function useCampaignBenefitDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignBenefitDeleteMutation, CampaignBenefitDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignBenefitDeleteMutation, CampaignBenefitDeleteMutationVariables>(CampaignBenefitDeleteDocument, baseOptions);
      }
export type CampaignBenefitDeleteMutationHookResult = ReturnType<typeof useCampaignBenefitDeleteMutation>;
export type CampaignBenefitDeleteMutationResult = ApolloReactCommon.MutationResult<CampaignBenefitDeleteMutation>;
export type CampaignBenefitDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignBenefitDeleteMutation, CampaignBenefitDeleteMutationVariables>;
export const ExamAttemptGetListDocument = gql`
    query ExamAttemptGetList($filterBy: ExamAttemptFilters, $orderBy: ExamAttemptOrder, $pagination: Pagination) {
  data: examAttempts(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ExamAttemptFragment
    }
    total: totalCount
  }
}
    ${ExamAttemptFragmentFragmentDoc}`;

/**
 * __useExamAttemptGetListQuery__
 *
 * To run a query within a React component, call `useExamAttemptGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamAttemptGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamAttemptGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExamAttemptGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamAttemptGetListQuery, ExamAttemptGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamAttemptGetListQuery, ExamAttemptGetListQueryVariables>(ExamAttemptGetListDocument, baseOptions);
      }
export function useExamAttemptGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamAttemptGetListQuery, ExamAttemptGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamAttemptGetListQuery, ExamAttemptGetListQueryVariables>(ExamAttemptGetListDocument, baseOptions);
        }
export type ExamAttemptGetListQueryHookResult = ReturnType<typeof useExamAttemptGetListQuery>;
export type ExamAttemptGetListLazyQueryHookResult = ReturnType<typeof useExamAttemptGetListLazyQuery>;
export type ExamAttemptGetListQueryResult = ApolloReactCommon.QueryResult<ExamAttemptGetListQuery, ExamAttemptGetListQueryVariables>;
export const ExamAttemptGetDocument = gql`
    query ExamAttemptGet($id: ID!) {
  data: examAttempt(id: $id) {
    ...ExamAttemptFragment
  }
}
    ${ExamAttemptFragmentFragmentDoc}`;

/**
 * __useExamAttemptGetQuery__
 *
 * To run a query within a React component, call `useExamAttemptGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamAttemptGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamAttemptGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExamAttemptGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamAttemptGetQuery, ExamAttemptGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamAttemptGetQuery, ExamAttemptGetQueryVariables>(ExamAttemptGetDocument, baseOptions);
      }
export function useExamAttemptGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamAttemptGetQuery, ExamAttemptGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamAttemptGetQuery, ExamAttemptGetQueryVariables>(ExamAttemptGetDocument, baseOptions);
        }
export type ExamAttemptGetQueryHookResult = ReturnType<typeof useExamAttemptGetQuery>;
export type ExamAttemptGetLazyQueryHookResult = ReturnType<typeof useExamAttemptGetLazyQuery>;
export type ExamAttemptGetQueryResult = ApolloReactCommon.QueryResult<ExamAttemptGetQuery, ExamAttemptGetQueryVariables>;
export const ExamQuestionTypeGetListDocument = gql`
    query ExamQuestionTypeGetList($filterBy: ExamQuestionTypeFilters, $orderBy: ExamQuestionTypeOrder, $pagination: Pagination) {
  data: examQuestionTypes(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ExamQuestionTypeFragment
    }
    total: totalCount
  }
}
    ${ExamQuestionTypeFragmentFragmentDoc}`;

/**
 * __useExamQuestionTypeGetListQuery__
 *
 * To run a query within a React component, call `useExamQuestionTypeGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionTypeGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamQuestionTypeGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExamQuestionTypeGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamQuestionTypeGetListQuery, ExamQuestionTypeGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamQuestionTypeGetListQuery, ExamQuestionTypeGetListQueryVariables>(ExamQuestionTypeGetListDocument, baseOptions);
      }
export function useExamQuestionTypeGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamQuestionTypeGetListQuery, ExamQuestionTypeGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamQuestionTypeGetListQuery, ExamQuestionTypeGetListQueryVariables>(ExamQuestionTypeGetListDocument, baseOptions);
        }
export type ExamQuestionTypeGetListQueryHookResult = ReturnType<typeof useExamQuestionTypeGetListQuery>;
export type ExamQuestionTypeGetListLazyQueryHookResult = ReturnType<typeof useExamQuestionTypeGetListLazyQuery>;
export type ExamQuestionTypeGetListQueryResult = ApolloReactCommon.QueryResult<ExamQuestionTypeGetListQuery, ExamQuestionTypeGetListQueryVariables>;
export const ExamQuestionTypeGetDocument = gql`
    query ExamQuestionTypeGet($id: ID!) {
  data: examQuestionType(id: $id) {
    ...ExamQuestionTypeFragment
  }
}
    ${ExamQuestionTypeFragmentFragmentDoc}`;

/**
 * __useExamQuestionTypeGetQuery__
 *
 * To run a query within a React component, call `useExamQuestionTypeGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionTypeGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamQuestionTypeGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExamQuestionTypeGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamQuestionTypeGetQuery, ExamQuestionTypeGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamQuestionTypeGetQuery, ExamQuestionTypeGetQueryVariables>(ExamQuestionTypeGetDocument, baseOptions);
      }
export function useExamQuestionTypeGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamQuestionTypeGetQuery, ExamQuestionTypeGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamQuestionTypeGetQuery, ExamQuestionTypeGetQueryVariables>(ExamQuestionTypeGetDocument, baseOptions);
        }
export type ExamQuestionTypeGetQueryHookResult = ReturnType<typeof useExamQuestionTypeGetQuery>;
export type ExamQuestionTypeGetLazyQueryHookResult = ReturnType<typeof useExamQuestionTypeGetLazyQuery>;
export type ExamQuestionTypeGetQueryResult = ApolloReactCommon.QueryResult<ExamQuestionTypeGetQuery, ExamQuestionTypeGetQueryVariables>;
export const ExamQuestionTypeGetManyDocument = gql`
    query ExamQuestionTypeGetMany($ids: [ID!]!) {
  data: examQuestionTypesByID(ids: $ids) {
    ...ExamQuestionTypeFragment
  }
}
    ${ExamQuestionTypeFragmentFragmentDoc}`;

/**
 * __useExamQuestionTypeGetManyQuery__
 *
 * To run a query within a React component, call `useExamQuestionTypeGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionTypeGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamQuestionTypeGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useExamQuestionTypeGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamQuestionTypeGetManyQuery, ExamQuestionTypeGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamQuestionTypeGetManyQuery, ExamQuestionTypeGetManyQueryVariables>(ExamQuestionTypeGetManyDocument, baseOptions);
      }
export function useExamQuestionTypeGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamQuestionTypeGetManyQuery, ExamQuestionTypeGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamQuestionTypeGetManyQuery, ExamQuestionTypeGetManyQueryVariables>(ExamQuestionTypeGetManyDocument, baseOptions);
        }
export type ExamQuestionTypeGetManyQueryHookResult = ReturnType<typeof useExamQuestionTypeGetManyQuery>;
export type ExamQuestionTypeGetManyLazyQueryHookResult = ReturnType<typeof useExamQuestionTypeGetManyLazyQuery>;
export type ExamQuestionTypeGetManyQueryResult = ApolloReactCommon.QueryResult<ExamQuestionTypeGetManyQuery, ExamQuestionTypeGetManyQueryVariables>;
export const ExamQuestionTypeUpdateDocument = gql`
    mutation ExamQuestionTypeUpdate($id: ID!, $input: ExamQuestionTypeInput!) {
  data: examQuestionTypeUpdate(id: $id, input: $input) {
    data: examQuestionType {
      ...ExamQuestionTypeFragment
    }
  }
}
    ${ExamQuestionTypeFragmentFragmentDoc}`;
export type ExamQuestionTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<ExamQuestionTypeUpdateMutation, ExamQuestionTypeUpdateMutationVariables>;

/**
 * __useExamQuestionTypeUpdateMutation__
 *
 * To run a mutation, you first call `useExamQuestionTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examQuestionTypeUpdateMutation, { data, loading, error }] = useExamQuestionTypeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamQuestionTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamQuestionTypeUpdateMutation, ExamQuestionTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamQuestionTypeUpdateMutation, ExamQuestionTypeUpdateMutationVariables>(ExamQuestionTypeUpdateDocument, baseOptions);
      }
export type ExamQuestionTypeUpdateMutationHookResult = ReturnType<typeof useExamQuestionTypeUpdateMutation>;
export type ExamQuestionTypeUpdateMutationResult = ApolloReactCommon.MutationResult<ExamQuestionTypeUpdateMutation>;
export type ExamQuestionTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamQuestionTypeUpdateMutation, ExamQuestionTypeUpdateMutationVariables>;
export const ExamQuestionTypeDeleteDocument = gql`
    mutation ExamQuestionTypeDelete($id: ID!) {
  data: examQuestionTypeDelete(id: $id) {
    data: examQuestionType {
      ...ExamQuestionTypeFragment
    }
  }
}
    ${ExamQuestionTypeFragmentFragmentDoc}`;
export type ExamQuestionTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<ExamQuestionTypeDeleteMutation, ExamQuestionTypeDeleteMutationVariables>;

/**
 * __useExamQuestionTypeDeleteMutation__
 *
 * To run a mutation, you first call `useExamQuestionTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examQuestionTypeDeleteMutation, { data, loading, error }] = useExamQuestionTypeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExamQuestionTypeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamQuestionTypeDeleteMutation, ExamQuestionTypeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamQuestionTypeDeleteMutation, ExamQuestionTypeDeleteMutationVariables>(ExamQuestionTypeDeleteDocument, baseOptions);
      }
export type ExamQuestionTypeDeleteMutationHookResult = ReturnType<typeof useExamQuestionTypeDeleteMutation>;
export type ExamQuestionTypeDeleteMutationResult = ApolloReactCommon.MutationResult<ExamQuestionTypeDeleteMutation>;
export type ExamQuestionTypeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamQuestionTypeDeleteMutation, ExamQuestionTypeDeleteMutationVariables>;
export const ExamQuestionGetListDocument = gql`
    query ExamQuestionGetList($filterBy: ExamQuestionFilters, $orderBy: ExamQuestionOrder, $pagination: Pagination) {
  data: examQuestions(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ExamQuestionFragment
    }
    total: totalCount
  }
}
    ${ExamQuestionFragmentFragmentDoc}`;

/**
 * __useExamQuestionGetListQuery__
 *
 * To run a query within a React component, call `useExamQuestionGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamQuestionGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExamQuestionGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamQuestionGetListQuery, ExamQuestionGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamQuestionGetListQuery, ExamQuestionGetListQueryVariables>(ExamQuestionGetListDocument, baseOptions);
      }
export function useExamQuestionGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamQuestionGetListQuery, ExamQuestionGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamQuestionGetListQuery, ExamQuestionGetListQueryVariables>(ExamQuestionGetListDocument, baseOptions);
        }
export type ExamQuestionGetListQueryHookResult = ReturnType<typeof useExamQuestionGetListQuery>;
export type ExamQuestionGetListLazyQueryHookResult = ReturnType<typeof useExamQuestionGetListLazyQuery>;
export type ExamQuestionGetListQueryResult = ApolloReactCommon.QueryResult<ExamQuestionGetListQuery, ExamQuestionGetListQueryVariables>;
export const ExamQuestionGetDocument = gql`
    query ExamQuestionGet($id: ID!) {
  data: examQuestion(id: $id) {
    ...ExamQuestionFragment
  }
}
    ${ExamQuestionFragmentFragmentDoc}`;

/**
 * __useExamQuestionGetQuery__
 *
 * To run a query within a React component, call `useExamQuestionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamQuestionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExamQuestionGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamQuestionGetQuery, ExamQuestionGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamQuestionGetQuery, ExamQuestionGetQueryVariables>(ExamQuestionGetDocument, baseOptions);
      }
export function useExamQuestionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamQuestionGetQuery, ExamQuestionGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamQuestionGetQuery, ExamQuestionGetQueryVariables>(ExamQuestionGetDocument, baseOptions);
        }
export type ExamQuestionGetQueryHookResult = ReturnType<typeof useExamQuestionGetQuery>;
export type ExamQuestionGetLazyQueryHookResult = ReturnType<typeof useExamQuestionGetLazyQuery>;
export type ExamQuestionGetQueryResult = ApolloReactCommon.QueryResult<ExamQuestionGetQuery, ExamQuestionGetQueryVariables>;
export const ExamQuestionCreateDocument = gql`
    mutation ExamQuestionCreate($input: ExamQuestionCreateInput!) {
  data: examQuestionCreate(input: $input) {
    data: examQuestion {
      ...ExamQuestionFragment
    }
  }
}
    ${ExamQuestionFragmentFragmentDoc}`;
export type ExamQuestionCreateMutationFn = ApolloReactCommon.MutationFunction<ExamQuestionCreateMutation, ExamQuestionCreateMutationVariables>;

/**
 * __useExamQuestionCreateMutation__
 *
 * To run a mutation, you first call `useExamQuestionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examQuestionCreateMutation, { data, loading, error }] = useExamQuestionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamQuestionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamQuestionCreateMutation, ExamQuestionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamQuestionCreateMutation, ExamQuestionCreateMutationVariables>(ExamQuestionCreateDocument, baseOptions);
      }
export type ExamQuestionCreateMutationHookResult = ReturnType<typeof useExamQuestionCreateMutation>;
export type ExamQuestionCreateMutationResult = ApolloReactCommon.MutationResult<ExamQuestionCreateMutation>;
export type ExamQuestionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamQuestionCreateMutation, ExamQuestionCreateMutationVariables>;
export const ExamQuestionUpdateDocument = gql`
    mutation ExamQuestionUpdate($id: ID!, $input: ExamQuestionUpdateInput!) {
  data: examQuestionUpdate(id: $id, input: $input) {
    data: examQuestion {
      ...ExamQuestionFragment
    }
  }
}
    ${ExamQuestionFragmentFragmentDoc}`;
export type ExamQuestionUpdateMutationFn = ApolloReactCommon.MutationFunction<ExamQuestionUpdateMutation, ExamQuestionUpdateMutationVariables>;

/**
 * __useExamQuestionUpdateMutation__
 *
 * To run a mutation, you first call `useExamQuestionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examQuestionUpdateMutation, { data, loading, error }] = useExamQuestionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamQuestionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamQuestionUpdateMutation, ExamQuestionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamQuestionUpdateMutation, ExamQuestionUpdateMutationVariables>(ExamQuestionUpdateDocument, baseOptions);
      }
export type ExamQuestionUpdateMutationHookResult = ReturnType<typeof useExamQuestionUpdateMutation>;
export type ExamQuestionUpdateMutationResult = ApolloReactCommon.MutationResult<ExamQuestionUpdateMutation>;
export type ExamQuestionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamQuestionUpdateMutation, ExamQuestionUpdateMutationVariables>;
export const ExamQuestionDeleteDocument = gql`
    mutation ExamQuestionDelete($id: ID!) {
  data: examQuestionDelete(id: $id) {
    data: examQuestion {
      ...ExamQuestionFragment
    }
  }
}
    ${ExamQuestionFragmentFragmentDoc}`;
export type ExamQuestionDeleteMutationFn = ApolloReactCommon.MutationFunction<ExamQuestionDeleteMutation, ExamQuestionDeleteMutationVariables>;

/**
 * __useExamQuestionDeleteMutation__
 *
 * To run a mutation, you first call `useExamQuestionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examQuestionDeleteMutation, { data, loading, error }] = useExamQuestionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExamQuestionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamQuestionDeleteMutation, ExamQuestionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamQuestionDeleteMutation, ExamQuestionDeleteMutationVariables>(ExamQuestionDeleteDocument, baseOptions);
      }
export type ExamQuestionDeleteMutationHookResult = ReturnType<typeof useExamQuestionDeleteMutation>;
export type ExamQuestionDeleteMutationResult = ApolloReactCommon.MutationResult<ExamQuestionDeleteMutation>;
export type ExamQuestionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamQuestionDeleteMutation, ExamQuestionDeleteMutationVariables>;
export const ExamQuestionDeleteManyDocument = gql`
    mutation ExamQuestionDeleteMany($ids: [ID!]!) {
  data: examQuestionDeleteMany(ids: $ids) {
    data: examQuestions {
      ...ExamQuestionFragment
    }
  }
}
    ${ExamQuestionFragmentFragmentDoc}`;
export type ExamQuestionDeleteManyMutationFn = ApolloReactCommon.MutationFunction<ExamQuestionDeleteManyMutation, ExamQuestionDeleteManyMutationVariables>;

/**
 * __useExamQuestionDeleteManyMutation__
 *
 * To run a mutation, you first call `useExamQuestionDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamQuestionDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examQuestionDeleteManyMutation, { data, loading, error }] = useExamQuestionDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useExamQuestionDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamQuestionDeleteManyMutation, ExamQuestionDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamQuestionDeleteManyMutation, ExamQuestionDeleteManyMutationVariables>(ExamQuestionDeleteManyDocument, baseOptions);
      }
export type ExamQuestionDeleteManyMutationHookResult = ReturnType<typeof useExamQuestionDeleteManyMutation>;
export type ExamQuestionDeleteManyMutationResult = ApolloReactCommon.MutationResult<ExamQuestionDeleteManyMutation>;
export type ExamQuestionDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamQuestionDeleteManyMutation, ExamQuestionDeleteManyMutationVariables>;
export const ExamSectionGetListDocument = gql`
    query ExamSectionGetList($filterBy: ExamSectionFilters, $orderBy: ExamSectionOrder, $pagination: Pagination) {
  data: examSections(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ExamSectionFragment
    }
    total: totalCount
  }
}
    ${ExamSectionFragmentFragmentDoc}`;

/**
 * __useExamSectionGetListQuery__
 *
 * To run a query within a React component, call `useExamSectionGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamSectionGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamSectionGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExamSectionGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamSectionGetListQuery, ExamSectionGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamSectionGetListQuery, ExamSectionGetListQueryVariables>(ExamSectionGetListDocument, baseOptions);
      }
export function useExamSectionGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamSectionGetListQuery, ExamSectionGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamSectionGetListQuery, ExamSectionGetListQueryVariables>(ExamSectionGetListDocument, baseOptions);
        }
export type ExamSectionGetListQueryHookResult = ReturnType<typeof useExamSectionGetListQuery>;
export type ExamSectionGetListLazyQueryHookResult = ReturnType<typeof useExamSectionGetListLazyQuery>;
export type ExamSectionGetListQueryResult = ApolloReactCommon.QueryResult<ExamSectionGetListQuery, ExamSectionGetListQueryVariables>;
export const ExamSectionGetDocument = gql`
    query ExamSectionGet($id: ID!) {
  data: examSection(id: $id) {
    ...ExamSectionFragment
  }
}
    ${ExamSectionFragmentFragmentDoc}`;

/**
 * __useExamSectionGetQuery__
 *
 * To run a query within a React component, call `useExamSectionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamSectionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamSectionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExamSectionGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamSectionGetQuery, ExamSectionGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamSectionGetQuery, ExamSectionGetQueryVariables>(ExamSectionGetDocument, baseOptions);
      }
export function useExamSectionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamSectionGetQuery, ExamSectionGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamSectionGetQuery, ExamSectionGetQueryVariables>(ExamSectionGetDocument, baseOptions);
        }
export type ExamSectionGetQueryHookResult = ReturnType<typeof useExamSectionGetQuery>;
export type ExamSectionGetLazyQueryHookResult = ReturnType<typeof useExamSectionGetLazyQuery>;
export type ExamSectionGetQueryResult = ApolloReactCommon.QueryResult<ExamSectionGetQuery, ExamSectionGetQueryVariables>;
export const ExamSectionUpdateDocument = gql`
    mutation ExamSectionUpdate($id: ID!, $input: ExamSectionInput!) {
  data: examSectionUpdate(id: $id, input: $input) {
    data: examSection {
      ...ExamSectionFragment
    }
  }
}
    ${ExamSectionFragmentFragmentDoc}`;
export type ExamSectionUpdateMutationFn = ApolloReactCommon.MutationFunction<ExamSectionUpdateMutation, ExamSectionUpdateMutationVariables>;

/**
 * __useExamSectionUpdateMutation__
 *
 * To run a mutation, you first call `useExamSectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamSectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examSectionUpdateMutation, { data, loading, error }] = useExamSectionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamSectionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamSectionUpdateMutation, ExamSectionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamSectionUpdateMutation, ExamSectionUpdateMutationVariables>(ExamSectionUpdateDocument, baseOptions);
      }
export type ExamSectionUpdateMutationHookResult = ReturnType<typeof useExamSectionUpdateMutation>;
export type ExamSectionUpdateMutationResult = ApolloReactCommon.MutationResult<ExamSectionUpdateMutation>;
export type ExamSectionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamSectionUpdateMutation, ExamSectionUpdateMutationVariables>;
export const ExamGetListDocument = gql`
    query ExamGetList($filterBy: ExamFilters, $orderBy: ExamOrder, $pagination: Pagination) {
  data: exams(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ExamFragment
    }
    total: totalCount
  }
}
    ${ExamFragmentFragmentDoc}`;

/**
 * __useExamGetListQuery__
 *
 * To run a query within a React component, call `useExamGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExamGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamGetListQuery, ExamGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamGetListQuery, ExamGetListQueryVariables>(ExamGetListDocument, baseOptions);
      }
export function useExamGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamGetListQuery, ExamGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamGetListQuery, ExamGetListQueryVariables>(ExamGetListDocument, baseOptions);
        }
export type ExamGetListQueryHookResult = ReturnType<typeof useExamGetListQuery>;
export type ExamGetListLazyQueryHookResult = ReturnType<typeof useExamGetListLazyQuery>;
export type ExamGetListQueryResult = ApolloReactCommon.QueryResult<ExamGetListQuery, ExamGetListQueryVariables>;
export const ExamGetDocument = gql`
    query ExamGet($id: ID!) {
  data: exam(id: $id) {
    ...ExamDetailFragment
    sections {
      questions(orderBy: {field: QUESTION_NUMBER, direction: ASC}, pagination: {page: 1, pageSize: 1000}) {
        nodes {
          id
          number
          type {
            id
            name
          }
          question {
            id
            questionField1
            questionField2
            explanation
            answer
          }
        }
      }
    }
  }
}
    ${ExamDetailFragmentFragmentDoc}`;

/**
 * __useExamGetQuery__
 *
 * To run a query within a React component, call `useExamGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExamGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamGetQuery, ExamGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamGetQuery, ExamGetQueryVariables>(ExamGetDocument, baseOptions);
      }
export function useExamGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamGetQuery, ExamGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamGetQuery, ExamGetQueryVariables>(ExamGetDocument, baseOptions);
        }
export type ExamGetQueryHookResult = ReturnType<typeof useExamGetQuery>;
export type ExamGetLazyQueryHookResult = ReturnType<typeof useExamGetLazyQuery>;
export type ExamGetQueryResult = ApolloReactCommon.QueryResult<ExamGetQuery, ExamGetQueryVariables>;
export const ExamGetManyDocument = gql`
    query ExamGetMany($ids: [ID!]!) {
  data: examsByID(ids: $ids) {
    ...ExamFragment
  }
}
    ${ExamFragmentFragmentDoc}`;

/**
 * __useExamGetManyQuery__
 *
 * To run a query within a React component, call `useExamGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useExamGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExamGetManyQuery, ExamGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<ExamGetManyQuery, ExamGetManyQueryVariables>(ExamGetManyDocument, baseOptions);
      }
export function useExamGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExamGetManyQuery, ExamGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExamGetManyQuery, ExamGetManyQueryVariables>(ExamGetManyDocument, baseOptions);
        }
export type ExamGetManyQueryHookResult = ReturnType<typeof useExamGetManyQuery>;
export type ExamGetManyLazyQueryHookResult = ReturnType<typeof useExamGetManyLazyQuery>;
export type ExamGetManyQueryResult = ApolloReactCommon.QueryResult<ExamGetManyQuery, ExamGetManyQueryVariables>;
export const ExamUpdateDocument = gql`
    mutation ExamUpdate($id: ID!, $input: ExamInput!) {
  data: examUpdate(id: $id, input: $input) {
    data: exam {
      ...ExamDetailFragment
    }
  }
}
    ${ExamDetailFragmentFragmentDoc}`;
export type ExamUpdateMutationFn = ApolloReactCommon.MutationFunction<ExamUpdateMutation, ExamUpdateMutationVariables>;

/**
 * __useExamUpdateMutation__
 *
 * To run a mutation, you first call `useExamUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExamUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [examUpdateMutation, { data, loading, error }] = useExamUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExamUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExamUpdateMutation, ExamUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ExamUpdateMutation, ExamUpdateMutationVariables>(ExamUpdateDocument, baseOptions);
      }
export type ExamUpdateMutationHookResult = ReturnType<typeof useExamUpdateMutation>;
export type ExamUpdateMutationResult = ApolloReactCommon.MutationResult<ExamUpdateMutation>;
export type ExamUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ExamUpdateMutation, ExamUpdateMutationVariables>;
export const InvoiceGetListDocument = gql`
    query InvoiceGetList($filterBy: InvoiceFilters, $orderBy: InvoiceOrder, $pagination: Pagination) {
  data: invoices(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...InvoiceFragment
    }
    total: totalCount
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useInvoiceGetListQuery__
 *
 * To run a query within a React component, call `useInvoiceGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useInvoiceGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceGetListQuery, InvoiceGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceGetListQuery, InvoiceGetListQueryVariables>(InvoiceGetListDocument, baseOptions);
      }
export function useInvoiceGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceGetListQuery, InvoiceGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceGetListQuery, InvoiceGetListQueryVariables>(InvoiceGetListDocument, baseOptions);
        }
export type InvoiceGetListQueryHookResult = ReturnType<typeof useInvoiceGetListQuery>;
export type InvoiceGetListLazyQueryHookResult = ReturnType<typeof useInvoiceGetListLazyQuery>;
export type InvoiceGetListQueryResult = ApolloReactCommon.QueryResult<InvoiceGetListQuery, InvoiceGetListQueryVariables>;
export const InvoiceGetDocument = gql`
    query InvoiceGet($id: ID!) {
  data: invoice(id: $id) {
    ...InvoiceDetailFragment
  }
}
    ${InvoiceDetailFragmentFragmentDoc}`;

/**
 * __useInvoiceGetQuery__
 *
 * To run a query within a React component, call `useInvoiceGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceGetQuery, InvoiceGetQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceGetQuery, InvoiceGetQueryVariables>(InvoiceGetDocument, baseOptions);
      }
export function useInvoiceGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceGetQuery, InvoiceGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceGetQuery, InvoiceGetQueryVariables>(InvoiceGetDocument, baseOptions);
        }
export type InvoiceGetQueryHookResult = ReturnType<typeof useInvoiceGetQuery>;
export type InvoiceGetLazyQueryHookResult = ReturnType<typeof useInvoiceGetLazyQuery>;
export type InvoiceGetQueryResult = ApolloReactCommon.QueryResult<InvoiceGetQuery, InvoiceGetQueryVariables>;
export const MembershipGetListDocument = gql`
    query MembershipGetList($filterBy: MembershipFilters, $orderBy: MembershipOrder, $pagination: Pagination) {
  data: memberships(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: edges {
      ...MembershipFragment
    }
    total: totalCount
  }
}
    ${MembershipFragmentFragmentDoc}`;

/**
 * __useMembershipGetListQuery__
 *
 * To run a query within a React component, call `useMembershipGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMembershipGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MembershipGetListQuery, MembershipGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<MembershipGetListQuery, MembershipGetListQueryVariables>(MembershipGetListDocument, baseOptions);
      }
export function useMembershipGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MembershipGetListQuery, MembershipGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MembershipGetListQuery, MembershipGetListQueryVariables>(MembershipGetListDocument, baseOptions);
        }
export type MembershipGetListQueryHookResult = ReturnType<typeof useMembershipGetListQuery>;
export type MembershipGetListLazyQueryHookResult = ReturnType<typeof useMembershipGetListLazyQuery>;
export type MembershipGetListQueryResult = ApolloReactCommon.QueryResult<MembershipGetListQuery, MembershipGetListQueryVariables>;
export const MembershipGetDocument = gql`
    query MembershipGet($id: ID!) {
  data: membership(id: $id) {
    ...MembershipFragment
  }
}
    ${MembershipFragmentFragmentDoc}`;

/**
 * __useMembershipGetQuery__
 *
 * To run a query within a React component, call `useMembershipGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMembershipGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MembershipGetQuery, MembershipGetQueryVariables>) {
        return ApolloReactHooks.useQuery<MembershipGetQuery, MembershipGetQueryVariables>(MembershipGetDocument, baseOptions);
      }
export function useMembershipGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MembershipGetQuery, MembershipGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MembershipGetQuery, MembershipGetQueryVariables>(MembershipGetDocument, baseOptions);
        }
export type MembershipGetQueryHookResult = ReturnType<typeof useMembershipGetQuery>;
export type MembershipGetLazyQueryHookResult = ReturnType<typeof useMembershipGetLazyQuery>;
export type MembershipGetQueryResult = ApolloReactCommon.QueryResult<MembershipGetQuery, MembershipGetQueryVariables>;
export const MembershipCreateDocument = gql`
    mutation MembershipCreate($input: MembershipInput!) {
  data: membershipCreate(input: $input) {
    data: membership {
      ...MembershipFragment
    }
  }
}
    ${MembershipFragmentFragmentDoc}`;
export type MembershipCreateMutationFn = ApolloReactCommon.MutationFunction<MembershipCreateMutation, MembershipCreateMutationVariables>;

/**
 * __useMembershipCreateMutation__
 *
 * To run a mutation, you first call `useMembershipCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMembershipCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [membershipCreateMutation, { data, loading, error }] = useMembershipCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMembershipCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MembershipCreateMutation, MembershipCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<MembershipCreateMutation, MembershipCreateMutationVariables>(MembershipCreateDocument, baseOptions);
      }
export type MembershipCreateMutationHookResult = ReturnType<typeof useMembershipCreateMutation>;
export type MembershipCreateMutationResult = ApolloReactCommon.MutationResult<MembershipCreateMutation>;
export type MembershipCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<MembershipCreateMutation, MembershipCreateMutationVariables>;
export const MembershipUpdateDocument = gql`
    mutation MembershipUpdate($id: ID!, $input: MembershipInput!) {
  data: membershipUpdate(id: $id, input: $input) {
    data: membership {
      ...MembershipFragment
    }
  }
}
    ${MembershipFragmentFragmentDoc}`;
export type MembershipUpdateMutationFn = ApolloReactCommon.MutationFunction<MembershipUpdateMutation, MembershipUpdateMutationVariables>;

/**
 * __useMembershipUpdateMutation__
 *
 * To run a mutation, you first call `useMembershipUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMembershipUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [membershipUpdateMutation, { data, loading, error }] = useMembershipUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMembershipUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MembershipUpdateMutation, MembershipUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<MembershipUpdateMutation, MembershipUpdateMutationVariables>(MembershipUpdateDocument, baseOptions);
      }
export type MembershipUpdateMutationHookResult = ReturnType<typeof useMembershipUpdateMutation>;
export type MembershipUpdateMutationResult = ApolloReactCommon.MutationResult<MembershipUpdateMutation>;
export type MembershipUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<MembershipUpdateMutation, MembershipUpdateMutationVariables>;
export const MembershipDeleteDocument = gql`
    mutation MembershipDelete($id: ID!) {
  data: membershipDelete(id: $id) {
    data: membership {
      ...MembershipFragment
    }
  }
}
    ${MembershipFragmentFragmentDoc}`;
export type MembershipDeleteMutationFn = ApolloReactCommon.MutationFunction<MembershipDeleteMutation, MembershipDeleteMutationVariables>;

/**
 * __useMembershipDeleteMutation__
 *
 * To run a mutation, you first call `useMembershipDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMembershipDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [membershipDeleteMutation, { data, loading, error }] = useMembershipDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMembershipDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MembershipDeleteMutation, MembershipDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<MembershipDeleteMutation, MembershipDeleteMutationVariables>(MembershipDeleteDocument, baseOptions);
      }
export type MembershipDeleteMutationHookResult = ReturnType<typeof useMembershipDeleteMutation>;
export type MembershipDeleteMutationResult = ApolloReactCommon.MutationResult<MembershipDeleteMutation>;
export type MembershipDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<MembershipDeleteMutation, MembershipDeleteMutationVariables>;
export const MembershipDeleteManyDocument = gql`
    mutation MembershipDeleteMany($ids: [ID!]!) {
  data: membershipDeleteMany(ids: $ids) {
    data: memberships {
      ...MembershipFragment
    }
  }
}
    ${MembershipFragmentFragmentDoc}`;
export type MembershipDeleteManyMutationFn = ApolloReactCommon.MutationFunction<MembershipDeleteManyMutation, MembershipDeleteManyMutationVariables>;

/**
 * __useMembershipDeleteManyMutation__
 *
 * To run a mutation, you first call `useMembershipDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMembershipDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [membershipDeleteManyMutation, { data, loading, error }] = useMembershipDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMembershipDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MembershipDeleteManyMutation, MembershipDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<MembershipDeleteManyMutation, MembershipDeleteManyMutationVariables>(MembershipDeleteManyDocument, baseOptions);
      }
export type MembershipDeleteManyMutationHookResult = ReturnType<typeof useMembershipDeleteManyMutation>;
export type MembershipDeleteManyMutationResult = ApolloReactCommon.MutationResult<MembershipDeleteManyMutation>;
export type MembershipDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<MembershipDeleteManyMutation, MembershipDeleteManyMutationVariables>;
export const MenuGetListDocument = gql`
    query MenuGetList($filterBy: MenuFilters, $orderBy: MenuOrder, $pagination: Pagination) {
  data: menus(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...MenuFragment
    }
    total: totalCount
  }
}
    ${MenuFragmentFragmentDoc}`;

/**
 * __useMenuGetListQuery__
 *
 * To run a query within a React component, call `useMenuGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMenuGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuGetListQuery, MenuGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuGetListQuery, MenuGetListQueryVariables>(MenuGetListDocument, baseOptions);
      }
export function useMenuGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuGetListQuery, MenuGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuGetListQuery, MenuGetListQueryVariables>(MenuGetListDocument, baseOptions);
        }
export type MenuGetListQueryHookResult = ReturnType<typeof useMenuGetListQuery>;
export type MenuGetListLazyQueryHookResult = ReturnType<typeof useMenuGetListLazyQuery>;
export type MenuGetListQueryResult = ApolloReactCommon.QueryResult<MenuGetListQuery, MenuGetListQueryVariables>;
export const MenuGetDocument = gql`
    query MenuGet($id: ID!) {
  data: menu(id: $id) {
    ...MenuDetailFragment
  }
}
    ${MenuDetailFragmentFragmentDoc}`;

/**
 * __useMenuGetQuery__
 *
 * To run a query within a React component, call `useMenuGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMenuGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuGetQuery, MenuGetQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuGetQuery, MenuGetQueryVariables>(MenuGetDocument, baseOptions);
      }
export function useMenuGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuGetQuery, MenuGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuGetQuery, MenuGetQueryVariables>(MenuGetDocument, baseOptions);
        }
export type MenuGetQueryHookResult = ReturnType<typeof useMenuGetQuery>;
export type MenuGetLazyQueryHookResult = ReturnType<typeof useMenuGetLazyQuery>;
export type MenuGetQueryResult = ApolloReactCommon.QueryResult<MenuGetQuery, MenuGetQueryVariables>;
export const MenuCreateDocument = gql`
    mutation MenuCreate($input: MenuCreateInput!) {
  data: menuCreate(input: $input) {
    data: menu {
      ...MenuDetailFragment
    }
  }
}
    ${MenuDetailFragmentFragmentDoc}`;
export type MenuCreateMutationFn = ApolloReactCommon.MutationFunction<MenuCreateMutation, MenuCreateMutationVariables>;

/**
 * __useMenuCreateMutation__
 *
 * To run a mutation, you first call `useMenuCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuCreateMutation, { data, loading, error }] = useMenuCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMenuCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MenuCreateMutation, MenuCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<MenuCreateMutation, MenuCreateMutationVariables>(MenuCreateDocument, baseOptions);
      }
export type MenuCreateMutationHookResult = ReturnType<typeof useMenuCreateMutation>;
export type MenuCreateMutationResult = ApolloReactCommon.MutationResult<MenuCreateMutation>;
export type MenuCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<MenuCreateMutation, MenuCreateMutationVariables>;
export const MenuUpdateDocument = gql`
    mutation MenuUpdate($id: ID!, $input: MenuUpdateInput!) {
  data: menuUpdate(id: $id, input: $input) {
    data: menu {
      ...MenuDetailFragment
    }
  }
}
    ${MenuDetailFragmentFragmentDoc}`;
export type MenuUpdateMutationFn = ApolloReactCommon.MutationFunction<MenuUpdateMutation, MenuUpdateMutationVariables>;

/**
 * __useMenuUpdateMutation__
 *
 * To run a mutation, you first call `useMenuUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuUpdateMutation, { data, loading, error }] = useMenuUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMenuUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MenuUpdateMutation, MenuUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<MenuUpdateMutation, MenuUpdateMutationVariables>(MenuUpdateDocument, baseOptions);
      }
export type MenuUpdateMutationHookResult = ReturnType<typeof useMenuUpdateMutation>;
export type MenuUpdateMutationResult = ApolloReactCommon.MutationResult<MenuUpdateMutation>;
export type MenuUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<MenuUpdateMutation, MenuUpdateMutationVariables>;
export const MenuDeleteDocument = gql`
    mutation MenuDelete($id: ID!) {
  data: menuDelete(id: $id) {
    data: menu {
      ...MenuFragment
    }
  }
}
    ${MenuFragmentFragmentDoc}`;
export type MenuDeleteMutationFn = ApolloReactCommon.MutationFunction<MenuDeleteMutation, MenuDeleteMutationVariables>;

/**
 * __useMenuDeleteMutation__
 *
 * To run a mutation, you first call `useMenuDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuDeleteMutation, { data, loading, error }] = useMenuDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMenuDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MenuDeleteMutation, MenuDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<MenuDeleteMutation, MenuDeleteMutationVariables>(MenuDeleteDocument, baseOptions);
      }
export type MenuDeleteMutationHookResult = ReturnType<typeof useMenuDeleteMutation>;
export type MenuDeleteMutationResult = ApolloReactCommon.MutationResult<MenuDeleteMutation>;
export type MenuDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<MenuDeleteMutation, MenuDeleteMutationVariables>;
export const MenuDeleteManyDocument = gql`
    mutation MenuDeleteMany($ids: [ID!]!) {
  data: menuDeleteMany(ids: $ids) {
    data: menus {
      ...MenuFragment
    }
  }
}
    ${MenuFragmentFragmentDoc}`;
export type MenuDeleteManyMutationFn = ApolloReactCommon.MutationFunction<MenuDeleteManyMutation, MenuDeleteManyMutationVariables>;

/**
 * __useMenuDeleteManyMutation__
 *
 * To run a mutation, you first call `useMenuDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuDeleteManyMutation, { data, loading, error }] = useMenuDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMenuDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MenuDeleteManyMutation, MenuDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<MenuDeleteManyMutation, MenuDeleteManyMutationVariables>(MenuDeleteManyDocument, baseOptions);
      }
export type MenuDeleteManyMutationHookResult = ReturnType<typeof useMenuDeleteManyMutation>;
export type MenuDeleteManyMutationResult = ApolloReactCommon.MutationResult<MenuDeleteManyMutation>;
export type MenuDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<MenuDeleteManyMutation, MenuDeleteManyMutationVariables>;
export const OfflineContentGetListDocument = gql`
    query OfflineContentGetList($filterBy: OfflineFilters, $orderBy: OfflineOrder, $pagination: Pagination) {
  data: offlines(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...OfflineContentFragment
    }
    total: totalCount
  }
}
    ${OfflineContentFragmentFragmentDoc}`;

/**
 * __useOfflineContentGetListQuery__
 *
 * To run a query within a React component, call `useOfflineContentGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfflineContentGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfflineContentGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOfflineContentGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfflineContentGetListQuery, OfflineContentGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<OfflineContentGetListQuery, OfflineContentGetListQueryVariables>(OfflineContentGetListDocument, baseOptions);
      }
export function useOfflineContentGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfflineContentGetListQuery, OfflineContentGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfflineContentGetListQuery, OfflineContentGetListQueryVariables>(OfflineContentGetListDocument, baseOptions);
        }
export type OfflineContentGetListQueryHookResult = ReturnType<typeof useOfflineContentGetListQuery>;
export type OfflineContentGetListLazyQueryHookResult = ReturnType<typeof useOfflineContentGetListLazyQuery>;
export type OfflineContentGetListQueryResult = ApolloReactCommon.QueryResult<OfflineContentGetListQuery, OfflineContentGetListQueryVariables>;
export const OfflineContentGetDocument = gql`
    query OfflineContentGet($id: ID!) {
  data: offline(id: $id) {
    ...OfflineContentFragment
  }
}
    ${OfflineContentFragmentFragmentDoc}`;

/**
 * __useOfflineContentGetQuery__
 *
 * To run a query within a React component, call `useOfflineContentGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfflineContentGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfflineContentGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfflineContentGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfflineContentGetQuery, OfflineContentGetQueryVariables>) {
        return ApolloReactHooks.useQuery<OfflineContentGetQuery, OfflineContentGetQueryVariables>(OfflineContentGetDocument, baseOptions);
      }
export function useOfflineContentGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfflineContentGetQuery, OfflineContentGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfflineContentGetQuery, OfflineContentGetQueryVariables>(OfflineContentGetDocument, baseOptions);
        }
export type OfflineContentGetQueryHookResult = ReturnType<typeof useOfflineContentGetQuery>;
export type OfflineContentGetLazyQueryHookResult = ReturnType<typeof useOfflineContentGetLazyQuery>;
export type OfflineContentGetQueryResult = ApolloReactCommon.QueryResult<OfflineContentGetQuery, OfflineContentGetQueryVariables>;
export const OfflineContentGetManyDocument = gql`
    query OfflineContentGetMany($ids: [ID!]!) {
  data: offlineByIDs(ids: $ids) {
    ...OfflineContentFragment
  }
}
    ${OfflineContentFragmentFragmentDoc}`;

/**
 * __useOfflineContentGetManyQuery__
 *
 * To run a query within a React component, call `useOfflineContentGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfflineContentGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfflineContentGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useOfflineContentGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfflineContentGetManyQuery, OfflineContentGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<OfflineContentGetManyQuery, OfflineContentGetManyQueryVariables>(OfflineContentGetManyDocument, baseOptions);
      }
export function useOfflineContentGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfflineContentGetManyQuery, OfflineContentGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfflineContentGetManyQuery, OfflineContentGetManyQueryVariables>(OfflineContentGetManyDocument, baseOptions);
        }
export type OfflineContentGetManyQueryHookResult = ReturnType<typeof useOfflineContentGetManyQuery>;
export type OfflineContentGetManyLazyQueryHookResult = ReturnType<typeof useOfflineContentGetManyLazyQuery>;
export type OfflineContentGetManyQueryResult = ApolloReactCommon.QueryResult<OfflineContentGetManyQuery, OfflineContentGetManyQueryVariables>;
export const OfflineContentCreateDocument = gql`
    mutation OfflineContentCreate($input: OfflineCreateInput!) {
  data: offlineCreate(input: $input) {
    data: offline {
      ...OfflineContentFragment
    }
  }
}
    ${OfflineContentFragmentFragmentDoc}`;
export type OfflineContentCreateMutationFn = ApolloReactCommon.MutationFunction<OfflineContentCreateMutation, OfflineContentCreateMutationVariables>;

/**
 * __useOfflineContentCreateMutation__
 *
 * To run a mutation, you first call `useOfflineContentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfflineContentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offlineContentCreateMutation, { data, loading, error }] = useOfflineContentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfflineContentCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfflineContentCreateMutation, OfflineContentCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<OfflineContentCreateMutation, OfflineContentCreateMutationVariables>(OfflineContentCreateDocument, baseOptions);
      }
export type OfflineContentCreateMutationHookResult = ReturnType<typeof useOfflineContentCreateMutation>;
export type OfflineContentCreateMutationResult = ApolloReactCommon.MutationResult<OfflineContentCreateMutation>;
export type OfflineContentCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<OfflineContentCreateMutation, OfflineContentCreateMutationVariables>;
export const OfflineContentUpdateDocument = gql`
    mutation OfflineContentUpdate($id: ID!, $input: OfflineUpdateInput!) {
  data: offlineUpdate(id: $id, input: $input) {
    data: offline {
      ...OfflineContentFragment
    }
  }
}
    ${OfflineContentFragmentFragmentDoc}`;
export type OfflineContentUpdateMutationFn = ApolloReactCommon.MutationFunction<OfflineContentUpdateMutation, OfflineContentUpdateMutationVariables>;

/**
 * __useOfflineContentUpdateMutation__
 *
 * To run a mutation, you first call `useOfflineContentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfflineContentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offlineContentUpdateMutation, { data, loading, error }] = useOfflineContentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfflineContentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfflineContentUpdateMutation, OfflineContentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OfflineContentUpdateMutation, OfflineContentUpdateMutationVariables>(OfflineContentUpdateDocument, baseOptions);
      }
export type OfflineContentUpdateMutationHookResult = ReturnType<typeof useOfflineContentUpdateMutation>;
export type OfflineContentUpdateMutationResult = ApolloReactCommon.MutationResult<OfflineContentUpdateMutation>;
export type OfflineContentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OfflineContentUpdateMutation, OfflineContentUpdateMutationVariables>;
export const OrderGetListDocument = gql`
    query OrderGetList($filterBy: OrderFilters, $pagination: Pagination) {
  data: orders(filterBy: $filterBy, pagination: $pagination) {
    data: nodes {
      ...OrderFragment
    }
    total: totalCount
  }
}
    ${OrderFragmentFragmentDoc}`;

/**
 * __useOrderGetListQuery__
 *
 * To run a query within a React component, call `useOrderGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrderGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderGetListQuery, OrderGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderGetListQuery, OrderGetListQueryVariables>(OrderGetListDocument, baseOptions);
      }
export function useOrderGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderGetListQuery, OrderGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderGetListQuery, OrderGetListQueryVariables>(OrderGetListDocument, baseOptions);
        }
export type OrderGetListQueryHookResult = ReturnType<typeof useOrderGetListQuery>;
export type OrderGetListLazyQueryHookResult = ReturnType<typeof useOrderGetListLazyQuery>;
export type OrderGetListQueryResult = ApolloReactCommon.QueryResult<OrderGetListQuery, OrderGetListQueryVariables>;
export const PaymentStateGetDocument = gql`
    query PaymentStateGet($id: ID!) {
  data: paymentState(id: $id) {
    ...PaymentState
  }
}
    ${PaymentStateFragmentDoc}`;

/**
 * __usePaymentStateGetQuery__
 *
 * To run a query within a React component, call `usePaymentStateGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentStateGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentStateGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentStateGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentStateGetQuery, PaymentStateGetQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentStateGetQuery, PaymentStateGetQueryVariables>(PaymentStateGetDocument, baseOptions);
      }
export function usePaymentStateGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentStateGetQuery, PaymentStateGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentStateGetQuery, PaymentStateGetQueryVariables>(PaymentStateGetDocument, baseOptions);
        }
export type PaymentStateGetQueryHookResult = ReturnType<typeof usePaymentStateGetQuery>;
export type PaymentStateGetLazyQueryHookResult = ReturnType<typeof usePaymentStateGetLazyQuery>;
export type PaymentStateGetQueryResult = ApolloReactCommon.QueryResult<PaymentStateGetQuery, PaymentStateGetQueryVariables>;
export const PaymentGetListDocument = gql`
    query PaymentGetList($filterBy: PaymentFilters, $pagination: Pagination) {
  data: payments(filterBy: $filterBy, pagination: $pagination) {
    data: nodes {
      ...PaymentFragment
    }
    total: totalCount
  }
}
    ${PaymentFragmentFragmentDoc}`;

/**
 * __usePaymentGetListQuery__
 *
 * To run a query within a React component, call `usePaymentGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaymentGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentGetListQuery, PaymentGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentGetListQuery, PaymentGetListQueryVariables>(PaymentGetListDocument, baseOptions);
      }
export function usePaymentGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentGetListQuery, PaymentGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentGetListQuery, PaymentGetListQueryVariables>(PaymentGetListDocument, baseOptions);
        }
export type PaymentGetListQueryHookResult = ReturnType<typeof usePaymentGetListQuery>;
export type PaymentGetListLazyQueryHookResult = ReturnType<typeof usePaymentGetListLazyQuery>;
export type PaymentGetListQueryResult = ApolloReactCommon.QueryResult<PaymentGetListQuery, PaymentGetListQueryVariables>;
export const PermissionGetListDocument = gql`
    query PermissionGetList($filterBy: PermissionFilters, $orderBy: PermissionOrder, $pagination: Pagination) {
  data: permissions(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...PermissionFragment
    }
    total: totalCount
  }
}
    ${PermissionFragmentFragmentDoc}`;

/**
 * __usePermissionGetListQuery__
 *
 * To run a query within a React component, call `usePermissionGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePermissionGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PermissionGetListQuery, PermissionGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<PermissionGetListQuery, PermissionGetListQueryVariables>(PermissionGetListDocument, baseOptions);
      }
export function usePermissionGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PermissionGetListQuery, PermissionGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PermissionGetListQuery, PermissionGetListQueryVariables>(PermissionGetListDocument, baseOptions);
        }
export type PermissionGetListQueryHookResult = ReturnType<typeof usePermissionGetListQuery>;
export type PermissionGetListLazyQueryHookResult = ReturnType<typeof usePermissionGetListLazyQuery>;
export type PermissionGetListQueryResult = ApolloReactCommon.QueryResult<PermissionGetListQuery, PermissionGetListQueryVariables>;
export const PermissionGetDocument = gql`
    query PermissionGet($id: ID!) {
  data: permission(id: $id) {
    ...PermissionFragment
  }
}
    ${PermissionFragmentFragmentDoc}`;

/**
 * __usePermissionGetQuery__
 *
 * To run a query within a React component, call `usePermissionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PermissionGetQuery, PermissionGetQueryVariables>) {
        return ApolloReactHooks.useQuery<PermissionGetQuery, PermissionGetQueryVariables>(PermissionGetDocument, baseOptions);
      }
export function usePermissionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PermissionGetQuery, PermissionGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PermissionGetQuery, PermissionGetQueryVariables>(PermissionGetDocument, baseOptions);
        }
export type PermissionGetQueryHookResult = ReturnType<typeof usePermissionGetQuery>;
export type PermissionGetLazyQueryHookResult = ReturnType<typeof usePermissionGetLazyQuery>;
export type PermissionGetQueryResult = ApolloReactCommon.QueryResult<PermissionGetQuery, PermissionGetQueryVariables>;
export const PermissionGetManyDocument = gql`
    query PermissionGetMany($ids: [ID!]!) {
  data: permissionsByID(ids: $ids) {
    ...PermissionFragment
  }
}
    ${PermissionFragmentFragmentDoc}`;

/**
 * __usePermissionGetManyQuery__
 *
 * To run a query within a React component, call `usePermissionGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePermissionGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PermissionGetManyQuery, PermissionGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<PermissionGetManyQuery, PermissionGetManyQueryVariables>(PermissionGetManyDocument, baseOptions);
      }
export function usePermissionGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PermissionGetManyQuery, PermissionGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PermissionGetManyQuery, PermissionGetManyQueryVariables>(PermissionGetManyDocument, baseOptions);
        }
export type PermissionGetManyQueryHookResult = ReturnType<typeof usePermissionGetManyQuery>;
export type PermissionGetManyLazyQueryHookResult = ReturnType<typeof usePermissionGetManyLazyQuery>;
export type PermissionGetManyQueryResult = ApolloReactCommon.QueryResult<PermissionGetManyQuery, PermissionGetManyQueryVariables>;
export const PermissionCreateDocument = gql`
    mutation PermissionCreate($teamID: ID!, $scope: PermissionScope!) {
  data: permissionGrant(teamID: $teamID, scope: $scope) {
    data: permission {
      ...PermissionFragment
    }
  }
}
    ${PermissionFragmentFragmentDoc}`;
export type PermissionCreateMutationFn = ApolloReactCommon.MutationFunction<PermissionCreateMutation, PermissionCreateMutationVariables>;

/**
 * __usePermissionCreateMutation__
 *
 * To run a mutation, you first call `usePermissionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionCreateMutation, { data, loading, error }] = usePermissionCreateMutation({
 *   variables: {
 *      teamID: // value for 'teamID'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function usePermissionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PermissionCreateMutation, PermissionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<PermissionCreateMutation, PermissionCreateMutationVariables>(PermissionCreateDocument, baseOptions);
      }
export type PermissionCreateMutationHookResult = ReturnType<typeof usePermissionCreateMutation>;
export type PermissionCreateMutationResult = ApolloReactCommon.MutationResult<PermissionCreateMutation>;
export type PermissionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<PermissionCreateMutation, PermissionCreateMutationVariables>;
export const PermissionDeleteDocument = gql`
    mutation PermissionDelete($id: ID!) {
  data: permissionRevoke(id: $id) {
    data: permission {
      ...PermissionFragment
    }
  }
}
    ${PermissionFragmentFragmentDoc}`;
export type PermissionDeleteMutationFn = ApolloReactCommon.MutationFunction<PermissionDeleteMutation, PermissionDeleteMutationVariables>;

/**
 * __usePermissionDeleteMutation__
 *
 * To run a mutation, you first call `usePermissionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionDeleteMutation, { data, loading, error }] = usePermissionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PermissionDeleteMutation, PermissionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PermissionDeleteMutation, PermissionDeleteMutationVariables>(PermissionDeleteDocument, baseOptions);
      }
export type PermissionDeleteMutationHookResult = ReturnType<typeof usePermissionDeleteMutation>;
export type PermissionDeleteMutationResult = ApolloReactCommon.MutationResult<PermissionDeleteMutation>;
export type PermissionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PermissionDeleteMutation, PermissionDeleteMutationVariables>;
export const PostCurationGetListDocument = gql`
    query PostCurationGetList($filterBy: PostCurationFilters, $orderBy: PostCurationOrder, $pagination: Pagination) {
  data: postCurations(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...PostCurationFragment
    }
    total: totalCount
  }
}
    ${PostCurationFragmentFragmentDoc}`;

/**
 * __usePostCurationGetListQuery__
 *
 * To run a query within a React component, call `usePostCurationGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCurationGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCurationGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePostCurationGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostCurationGetListQuery, PostCurationGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<PostCurationGetListQuery, PostCurationGetListQueryVariables>(PostCurationGetListDocument, baseOptions);
      }
export function usePostCurationGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostCurationGetListQuery, PostCurationGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostCurationGetListQuery, PostCurationGetListQueryVariables>(PostCurationGetListDocument, baseOptions);
        }
export type PostCurationGetListQueryHookResult = ReturnType<typeof usePostCurationGetListQuery>;
export type PostCurationGetListLazyQueryHookResult = ReturnType<typeof usePostCurationGetListLazyQuery>;
export type PostCurationGetListQueryResult = ApolloReactCommon.QueryResult<PostCurationGetListQuery, PostCurationGetListQueryVariables>;
export const PostCurationUpdateDocument = gql`
    mutation PostCurationUpdate($id: ID!, $input: PostCurationInput!) {
  data: postCurationUpdate(id: $id, input: $input) {
    data: postCuration {
      ...PostCurationFragment
    }
  }
}
    ${PostCurationFragmentFragmentDoc}`;
export type PostCurationUpdateMutationFn = ApolloReactCommon.MutationFunction<PostCurationUpdateMutation, PostCurationUpdateMutationVariables>;

/**
 * __usePostCurationUpdateMutation__
 *
 * To run a mutation, you first call `usePostCurationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCurationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCurationUpdateMutation, { data, loading, error }] = usePostCurationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostCurationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCurationUpdateMutation, PostCurationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCurationUpdateMutation, PostCurationUpdateMutationVariables>(PostCurationUpdateDocument, baseOptions);
      }
export type PostCurationUpdateMutationHookResult = ReturnType<typeof usePostCurationUpdateMutation>;
export type PostCurationUpdateMutationResult = ApolloReactCommon.MutationResult<PostCurationUpdateMutation>;
export type PostCurationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCurationUpdateMutation, PostCurationUpdateMutationVariables>;
export const PostCurationGetDocument = gql`
    query PostCurationGet($id: ID!) {
  data: postCuration(id: $id) {
    ...PostCurationFragment
  }
}
    ${PostCurationFragmentFragmentDoc}`;

/**
 * __usePostCurationGetQuery__
 *
 * To run a query within a React component, call `usePostCurationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCurationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCurationGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostCurationGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostCurationGetQuery, PostCurationGetQueryVariables>) {
        return ApolloReactHooks.useQuery<PostCurationGetQuery, PostCurationGetQueryVariables>(PostCurationGetDocument, baseOptions);
      }
export function usePostCurationGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostCurationGetQuery, PostCurationGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostCurationGetQuery, PostCurationGetQueryVariables>(PostCurationGetDocument, baseOptions);
        }
export type PostCurationGetQueryHookResult = ReturnType<typeof usePostCurationGetQuery>;
export type PostCurationGetLazyQueryHookResult = ReturnType<typeof usePostCurationGetLazyQuery>;
export type PostCurationGetQueryResult = ApolloReactCommon.QueryResult<PostCurationGetQuery, PostCurationGetQueryVariables>;
export const RefundGetDocument = gql`
    query RefundGet($id: ID!) {
  data: refund(id: $id) {
    ...RefundFragment
  }
}
    ${RefundFragmentFragmentDoc}`;

/**
 * __useRefundGetQuery__
 *
 * To run a query within a React component, call `useRefundGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefundGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefundGetQuery, RefundGetQueryVariables>) {
        return ApolloReactHooks.useQuery<RefundGetQuery, RefundGetQueryVariables>(RefundGetDocument, baseOptions);
      }
export function useRefundGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefundGetQuery, RefundGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefundGetQuery, RefundGetQueryVariables>(RefundGetDocument, baseOptions);
        }
export type RefundGetQueryHookResult = ReturnType<typeof useRefundGetQuery>;
export type RefundGetLazyQueryHookResult = ReturnType<typeof useRefundGetLazyQuery>;
export type RefundGetQueryResult = ApolloReactCommon.QueryResult<RefundGetQuery, RefundGetQueryVariables>;
export const RefundCreateDocument = gql`
    mutation RefundCreate($input: RefundCreateInput!) {
  data: refundCreate(input: $input) {
    data: refund {
      ...RefundFragment
    }
  }
}
    ${RefundFragmentFragmentDoc}`;
export type RefundCreateMutationFn = ApolloReactCommon.MutationFunction<RefundCreateMutation, RefundCreateMutationVariables>;

/**
 * __useRefundCreateMutation__
 *
 * To run a mutation, you first call `useRefundCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundCreateMutation, { data, loading, error }] = useRefundCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundCreateMutation, RefundCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<RefundCreateMutation, RefundCreateMutationVariables>(RefundCreateDocument, baseOptions);
      }
export type RefundCreateMutationHookResult = ReturnType<typeof useRefundCreateMutation>;
export type RefundCreateMutationResult = ApolloReactCommon.MutationResult<RefundCreateMutation>;
export type RefundCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundCreateMutation, RefundCreateMutationVariables>;
export const ResumeProductGetListDocument = gql`
    query ResumeProductGetList($filterBy: ResumeProductFilters, $orderBy: ResumeProductOrder, $pagination: Pagination) {
  data: resumeProducts(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ResumeProductFragment
    }
    total: totalCount
  }
}
    ${ResumeProductFragmentFragmentDoc}`;

/**
 * __useResumeProductGetListQuery__
 *
 * To run a query within a React component, call `useResumeProductGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumeProductGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumeProductGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useResumeProductGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResumeProductGetListQuery, ResumeProductGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ResumeProductGetListQuery, ResumeProductGetListQueryVariables>(ResumeProductGetListDocument, baseOptions);
      }
export function useResumeProductGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResumeProductGetListQuery, ResumeProductGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResumeProductGetListQuery, ResumeProductGetListQueryVariables>(ResumeProductGetListDocument, baseOptions);
        }
export type ResumeProductGetListQueryHookResult = ReturnType<typeof useResumeProductGetListQuery>;
export type ResumeProductGetListLazyQueryHookResult = ReturnType<typeof useResumeProductGetListLazyQuery>;
export type ResumeProductGetListQueryResult = ApolloReactCommon.QueryResult<ResumeProductGetListQuery, ResumeProductGetListQueryVariables>;
export const ResumeProductGetDocument = gql`
    query ResumeProductGet($id: ID!) {
  data: resumeProduct(id: $id) {
    ...ResumeProductFragment
  }
}
    ${ResumeProductFragmentFragmentDoc}`;

/**
 * __useResumeProductGetQuery__
 *
 * To run a query within a React component, call `useResumeProductGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumeProductGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumeProductGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumeProductGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResumeProductGetQuery, ResumeProductGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ResumeProductGetQuery, ResumeProductGetQueryVariables>(ResumeProductGetDocument, baseOptions);
      }
export function useResumeProductGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResumeProductGetQuery, ResumeProductGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResumeProductGetQuery, ResumeProductGetQueryVariables>(ResumeProductGetDocument, baseOptions);
        }
export type ResumeProductGetQueryHookResult = ReturnType<typeof useResumeProductGetQuery>;
export type ResumeProductGetLazyQueryHookResult = ReturnType<typeof useResumeProductGetLazyQuery>;
export type ResumeProductGetQueryResult = ApolloReactCommon.QueryResult<ResumeProductGetQuery, ResumeProductGetQueryVariables>;
export const ResumeProductCreateDocument = gql`
    mutation ResumeProductCreate($input: ResumeProductInput!) {
  data: resumeProductCreate(input: $input) {
    data: resumeProduct {
      ...ResumeProductFragment
    }
  }
}
    ${ResumeProductFragmentFragmentDoc}`;
export type ResumeProductCreateMutationFn = ApolloReactCommon.MutationFunction<ResumeProductCreateMutation, ResumeProductCreateMutationVariables>;

/**
 * __useResumeProductCreateMutation__
 *
 * To run a mutation, you first call `useResumeProductCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeProductCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeProductCreateMutation, { data, loading, error }] = useResumeProductCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeProductCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResumeProductCreateMutation, ResumeProductCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ResumeProductCreateMutation, ResumeProductCreateMutationVariables>(ResumeProductCreateDocument, baseOptions);
      }
export type ResumeProductCreateMutationHookResult = ReturnType<typeof useResumeProductCreateMutation>;
export type ResumeProductCreateMutationResult = ApolloReactCommon.MutationResult<ResumeProductCreateMutation>;
export type ResumeProductCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ResumeProductCreateMutation, ResumeProductCreateMutationVariables>;
export const ResumeProductUpdateDocument = gql`
    mutation ResumeProductUpdate($id: ID!, $input: ResumeProductInput!) {
  data: resumeProductUpdate(id: $id, input: $input) {
    data: resumeProduct {
      ...ResumeProductFragment
    }
  }
}
    ${ResumeProductFragmentFragmentDoc}`;
export type ResumeProductUpdateMutationFn = ApolloReactCommon.MutationFunction<ResumeProductUpdateMutation, ResumeProductUpdateMutationVariables>;

/**
 * __useResumeProductUpdateMutation__
 *
 * To run a mutation, you first call `useResumeProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeProductUpdateMutation, { data, loading, error }] = useResumeProductUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeProductUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResumeProductUpdateMutation, ResumeProductUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ResumeProductUpdateMutation, ResumeProductUpdateMutationVariables>(ResumeProductUpdateDocument, baseOptions);
      }
export type ResumeProductUpdateMutationHookResult = ReturnType<typeof useResumeProductUpdateMutation>;
export type ResumeProductUpdateMutationResult = ApolloReactCommon.MutationResult<ResumeProductUpdateMutation>;
export type ResumeProductUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ResumeProductUpdateMutation, ResumeProductUpdateMutationVariables>;
export const ResumeProductDeleteDocument = gql`
    mutation ResumeProductDelete($id: ID!) {
  data: resumeProductDelete(id: $id) {
    data: resumeProduct {
      ...ResumeProductFragment
    }
  }
}
    ${ResumeProductFragmentFragmentDoc}`;
export type ResumeProductDeleteMutationFn = ApolloReactCommon.MutationFunction<ResumeProductDeleteMutation, ResumeProductDeleteMutationVariables>;

/**
 * __useResumeProductDeleteMutation__
 *
 * To run a mutation, you first call `useResumeProductDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeProductDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeProductDeleteMutation, { data, loading, error }] = useResumeProductDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumeProductDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResumeProductDeleteMutation, ResumeProductDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ResumeProductDeleteMutation, ResumeProductDeleteMutationVariables>(ResumeProductDeleteDocument, baseOptions);
      }
export type ResumeProductDeleteMutationHookResult = ReturnType<typeof useResumeProductDeleteMutation>;
export type ResumeProductDeleteMutationResult = ApolloReactCommon.MutationResult<ResumeProductDeleteMutation>;
export type ResumeProductDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ResumeProductDeleteMutation, ResumeProductDeleteMutationVariables>;
export const ResumeProductDeleteManyDocument = gql`
    mutation ResumeProductDeleteMany($ids: [ID!]!) {
  data: resumeProductDeleteMany(ids: $ids) {
    data: resumeProducts {
      ...ResumeProductFragment
    }
  }
}
    ${ResumeProductFragmentFragmentDoc}`;
export type ResumeProductDeleteManyMutationFn = ApolloReactCommon.MutationFunction<ResumeProductDeleteManyMutation, ResumeProductDeleteManyMutationVariables>;

/**
 * __useResumeProductDeleteManyMutation__
 *
 * To run a mutation, you first call `useResumeProductDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeProductDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeProductDeleteManyMutation, { data, loading, error }] = useResumeProductDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useResumeProductDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResumeProductDeleteManyMutation, ResumeProductDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<ResumeProductDeleteManyMutation, ResumeProductDeleteManyMutationVariables>(ResumeProductDeleteManyDocument, baseOptions);
      }
export type ResumeProductDeleteManyMutationHookResult = ReturnType<typeof useResumeProductDeleteManyMutation>;
export type ResumeProductDeleteManyMutationResult = ApolloReactCommon.MutationResult<ResumeProductDeleteManyMutation>;
export type ResumeProductDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<ResumeProductDeleteManyMutation, ResumeProductDeleteManyMutationVariables>;
export const ResumeGetDocument = gql`
    query ResumeGet($id: ID!) {
  data: resume(id: $id) {
    ...ResumeDetailFragment
  }
}
    ${ResumeDetailFragmentFragmentDoc}`;

/**
 * __useResumeGetQuery__
 *
 * To run a query within a React component, call `useResumeGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumeGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumeGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumeGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResumeGetQuery, ResumeGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ResumeGetQuery, ResumeGetQueryVariables>(ResumeGetDocument, baseOptions);
      }
export function useResumeGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResumeGetQuery, ResumeGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResumeGetQuery, ResumeGetQueryVariables>(ResumeGetDocument, baseOptions);
        }
export type ResumeGetQueryHookResult = ReturnType<typeof useResumeGetQuery>;
export type ResumeGetLazyQueryHookResult = ReturnType<typeof useResumeGetLazyQuery>;
export type ResumeGetQueryResult = ApolloReactCommon.QueryResult<ResumeGetQuery, ResumeGetQueryVariables>;
export const ResumeGetListDocument = gql`
    query ResumeGetList($filterBy: ResumeFilters, $orderBy: ResumeOrder, $pagination: Pagination) {
  data: resumes(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ResumeFragment
    }
    total: totalCount
  }
}
    ${ResumeFragmentFragmentDoc}`;

/**
 * __useResumeGetListQuery__
 *
 * To run a query within a React component, call `useResumeGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumeGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumeGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useResumeGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResumeGetListQuery, ResumeGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ResumeGetListQuery, ResumeGetListQueryVariables>(ResumeGetListDocument, baseOptions);
      }
export function useResumeGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResumeGetListQuery, ResumeGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResumeGetListQuery, ResumeGetListQueryVariables>(ResumeGetListDocument, baseOptions);
        }
export type ResumeGetListQueryHookResult = ReturnType<typeof useResumeGetListQuery>;
export type ResumeGetListLazyQueryHookResult = ReturnType<typeof useResumeGetListLazyQuery>;
export type ResumeGetListQueryResult = ApolloReactCommon.QueryResult<ResumeGetListQuery, ResumeGetListQueryVariables>;
export const ResumeUpdateDocument = gql`
    mutation ResumeUpdate($id: ID!, $input: ResumeUpdateInput!) {
  data: resumeUpdate(id: $id, input: $input) {
    data: resume {
      ...ResumeDetailFragment
    }
  }
}
    ${ResumeDetailFragmentFragmentDoc}`;
export type ResumeUpdateMutationFn = ApolloReactCommon.MutationFunction<ResumeUpdateMutation, ResumeUpdateMutationVariables>;

/**
 * __useResumeUpdateMutation__
 *
 * To run a mutation, you first call `useResumeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeUpdateMutation, { data, loading, error }] = useResumeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResumeUpdateMutation, ResumeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ResumeUpdateMutation, ResumeUpdateMutationVariables>(ResumeUpdateDocument, baseOptions);
      }
export type ResumeUpdateMutationHookResult = ReturnType<typeof useResumeUpdateMutation>;
export type ResumeUpdateMutationResult = ApolloReactCommon.MutationResult<ResumeUpdateMutation>;
export type ResumeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ResumeUpdateMutation, ResumeUpdateMutationVariables>;
export const ReviewGetListDocument = gql`
    query ReviewGetList($filterBy: ReviewFilters, $orderBy: ReviewOrder, $pagination: Pagination) {
  data: reviews(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ReviewFragment
    }
    total: totalCount
  }
}
    ${ReviewFragmentFragmentDoc}`;

/**
 * __useReviewGetListQuery__
 *
 * To run a query within a React component, call `useReviewGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useReviewGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewGetListQuery, ReviewGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewGetListQuery, ReviewGetListQueryVariables>(ReviewGetListDocument, baseOptions);
      }
export function useReviewGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewGetListQuery, ReviewGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewGetListQuery, ReviewGetListQueryVariables>(ReviewGetListDocument, baseOptions);
        }
export type ReviewGetListQueryHookResult = ReturnType<typeof useReviewGetListQuery>;
export type ReviewGetListLazyQueryHookResult = ReturnType<typeof useReviewGetListLazyQuery>;
export type ReviewGetListQueryResult = ApolloReactCommon.QueryResult<ReviewGetListQuery, ReviewGetListQueryVariables>;
export const ReviewGetDocument = gql`
    query ReviewGet($id: ID!) {
  data: review(id: $id) {
    ...ReviewFragment
  }
}
    ${ReviewFragmentFragmentDoc}`;

/**
 * __useReviewGetQuery__
 *
 * To run a query within a React component, call `useReviewGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewGetQuery, ReviewGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewGetQuery, ReviewGetQueryVariables>(ReviewGetDocument, baseOptions);
      }
export function useReviewGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewGetQuery, ReviewGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewGetQuery, ReviewGetQueryVariables>(ReviewGetDocument, baseOptions);
        }
export type ReviewGetQueryHookResult = ReturnType<typeof useReviewGetQuery>;
export type ReviewGetLazyQueryHookResult = ReturnType<typeof useReviewGetLazyQuery>;
export type ReviewGetQueryResult = ApolloReactCommon.QueryResult<ReviewGetQuery, ReviewGetQueryVariables>;
export const ReviewUpdateDocument = gql`
    mutation ReviewUpdate($id: ID!, $input: ReviewInput!) {
  data: reviewUpdate(id: $id, input: $input) {
    data: review {
      ...ReviewFragment
    }
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type ReviewUpdateMutationFn = ApolloReactCommon.MutationFunction<ReviewUpdateMutation, ReviewUpdateMutationVariables>;

/**
 * __useReviewUpdateMutation__
 *
 * To run a mutation, you first call `useReviewUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewUpdateMutation, { data, loading, error }] = useReviewUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewUpdateMutation, ReviewUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewUpdateMutation, ReviewUpdateMutationVariables>(ReviewUpdateDocument, baseOptions);
      }
export type ReviewUpdateMutationHookResult = ReturnType<typeof useReviewUpdateMutation>;
export type ReviewUpdateMutationResult = ApolloReactCommon.MutationResult<ReviewUpdateMutation>;
export type ReviewUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewUpdateMutation, ReviewUpdateMutationVariables>;
export const ReviewDeleteDocument = gql`
    mutation ReviewDelete($id: ID!) {
  data: reviewDelete(id: $id) {
    data: review {
      ...ReviewFragment
    }
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type ReviewDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewDeleteMutation, ReviewDeleteMutationVariables>;

/**
 * __useReviewDeleteMutation__
 *
 * To run a mutation, you first call `useReviewDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewDeleteMutation, { data, loading, error }] = useReviewDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewDeleteMutation, ReviewDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewDeleteMutation, ReviewDeleteMutationVariables>(ReviewDeleteDocument, baseOptions);
      }
export type ReviewDeleteMutationHookResult = ReturnType<typeof useReviewDeleteMutation>;
export type ReviewDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewDeleteMutation>;
export type ReviewDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewDeleteMutation, ReviewDeleteMutationVariables>;
export const ReviewDeleteManyDocument = gql`
    mutation ReviewDeleteMany($ids: [ID!]!) {
  data: reviewDeleteMany(ids: $ids) {
    data: reviews {
      ...ReviewFragment
    }
  }
}
    ${ReviewFragmentFragmentDoc}`;
export type ReviewDeleteManyMutationFn = ApolloReactCommon.MutationFunction<ReviewDeleteManyMutation, ReviewDeleteManyMutationVariables>;

/**
 * __useReviewDeleteManyMutation__
 *
 * To run a mutation, you first call `useReviewDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewDeleteManyMutation, { data, loading, error }] = useReviewDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReviewDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewDeleteManyMutation, ReviewDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewDeleteManyMutation, ReviewDeleteManyMutationVariables>(ReviewDeleteManyDocument, baseOptions);
      }
export type ReviewDeleteManyMutationHookResult = ReturnType<typeof useReviewDeleteManyMutation>;
export type ReviewDeleteManyMutationResult = ApolloReactCommon.MutationResult<ReviewDeleteManyMutation>;
export type ReviewDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewDeleteManyMutation, ReviewDeleteManyMutationVariables>;
export const ScheduleEventGetListDocument = gql`
    query ScheduleEventGetList($filterBy: ScheduleEventFilters, $orderBy: ScheduleEventOrder, $pagination: Pagination) {
  data: scheduleEvents(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ScheduleEventFragment
    }
    total: totalCount
  }
}
    ${ScheduleEventFragmentFragmentDoc}`;

/**
 * __useScheduleEventGetListQuery__
 *
 * To run a query within a React component, call `useScheduleEventGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleEventGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleEventGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useScheduleEventGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScheduleEventGetListQuery, ScheduleEventGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ScheduleEventGetListQuery, ScheduleEventGetListQueryVariables>(ScheduleEventGetListDocument, baseOptions);
      }
export function useScheduleEventGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScheduleEventGetListQuery, ScheduleEventGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScheduleEventGetListQuery, ScheduleEventGetListQueryVariables>(ScheduleEventGetListDocument, baseOptions);
        }
export type ScheduleEventGetListQueryHookResult = ReturnType<typeof useScheduleEventGetListQuery>;
export type ScheduleEventGetListLazyQueryHookResult = ReturnType<typeof useScheduleEventGetListLazyQuery>;
export type ScheduleEventGetListQueryResult = ApolloReactCommon.QueryResult<ScheduleEventGetListQuery, ScheduleEventGetListQueryVariables>;
export const ScheduleEventGetDocument = gql`
    query ScheduleEventGet($id: ID!) {
  data: scheduleEvent(id: $id) {
    ...ScheduleEventFragment
  }
}
    ${ScheduleEventFragmentFragmentDoc}`;

/**
 * __useScheduleEventGetQuery__
 *
 * To run a query within a React component, call `useScheduleEventGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleEventGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleEventGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScheduleEventGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScheduleEventGetQuery, ScheduleEventGetQueryVariables>) {
        return ApolloReactHooks.useQuery<ScheduleEventGetQuery, ScheduleEventGetQueryVariables>(ScheduleEventGetDocument, baseOptions);
      }
export function useScheduleEventGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScheduleEventGetQuery, ScheduleEventGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScheduleEventGetQuery, ScheduleEventGetQueryVariables>(ScheduleEventGetDocument, baseOptions);
        }
export type ScheduleEventGetQueryHookResult = ReturnType<typeof useScheduleEventGetQuery>;
export type ScheduleEventGetLazyQueryHookResult = ReturnType<typeof useScheduleEventGetLazyQuery>;
export type ScheduleEventGetQueryResult = ApolloReactCommon.QueryResult<ScheduleEventGetQuery, ScheduleEventGetQueryVariables>;
export const ScheduleEventCreateDocument = gql`
    mutation ScheduleEventCreate($input: ScheduleEventCreateInput!) {
  data: scheduleEventCreate(input: $input) {
    data: scheduleEvent {
      ...ScheduleEventFragment
    }
  }
}
    ${ScheduleEventFragmentFragmentDoc}`;
export type ScheduleEventCreateMutationFn = ApolloReactCommon.MutationFunction<ScheduleEventCreateMutation, ScheduleEventCreateMutationVariables>;

/**
 * __useScheduleEventCreateMutation__
 *
 * To run a mutation, you first call `useScheduleEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleEventCreateMutation, { data, loading, error }] = useScheduleEventCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleEventCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleEventCreateMutation, ScheduleEventCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleEventCreateMutation, ScheduleEventCreateMutationVariables>(ScheduleEventCreateDocument, baseOptions);
      }
export type ScheduleEventCreateMutationHookResult = ReturnType<typeof useScheduleEventCreateMutation>;
export type ScheduleEventCreateMutationResult = ApolloReactCommon.MutationResult<ScheduleEventCreateMutation>;
export type ScheduleEventCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleEventCreateMutation, ScheduleEventCreateMutationVariables>;
export const ScheduleEventUpdateDocument = gql`
    mutation ScheduleEventUpdate($id: ID!, $input: ScheduleEventUpdateInput!) {
  data: scheduleEventUpdate(id: $id, input: $input) {
    data: scheduleEvent {
      ...ScheduleEventFragment
    }
  }
}
    ${ScheduleEventFragmentFragmentDoc}`;
export type ScheduleEventUpdateMutationFn = ApolloReactCommon.MutationFunction<ScheduleEventUpdateMutation, ScheduleEventUpdateMutationVariables>;

/**
 * __useScheduleEventUpdateMutation__
 *
 * To run a mutation, you first call `useScheduleEventUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleEventUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleEventUpdateMutation, { data, loading, error }] = useScheduleEventUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleEventUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleEventUpdateMutation, ScheduleEventUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleEventUpdateMutation, ScheduleEventUpdateMutationVariables>(ScheduleEventUpdateDocument, baseOptions);
      }
export type ScheduleEventUpdateMutationHookResult = ReturnType<typeof useScheduleEventUpdateMutation>;
export type ScheduleEventUpdateMutationResult = ApolloReactCommon.MutationResult<ScheduleEventUpdateMutation>;
export type ScheduleEventUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleEventUpdateMutation, ScheduleEventUpdateMutationVariables>;
export const ScheduleGetListDocument = gql`
    query ScheduleGetList($filterBy: ScheduleFilters, $orderBy: ScheduleOrder, $pagination: Pagination) {
  data: schedules(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ScheduleFragment
    }
    total: totalCount
  }
}
    ${ScheduleFragmentFragmentDoc}`;

/**
 * __useScheduleGetListQuery__
 *
 * To run a query within a React component, call `useScheduleGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useScheduleGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScheduleGetListQuery, ScheduleGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ScheduleGetListQuery, ScheduleGetListQueryVariables>(ScheduleGetListDocument, baseOptions);
      }
export function useScheduleGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScheduleGetListQuery, ScheduleGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScheduleGetListQuery, ScheduleGetListQueryVariables>(ScheduleGetListDocument, baseOptions);
        }
export type ScheduleGetListQueryHookResult = ReturnType<typeof useScheduleGetListQuery>;
export type ScheduleGetListLazyQueryHookResult = ReturnType<typeof useScheduleGetListLazyQuery>;
export type ScheduleGetListQueryResult = ApolloReactCommon.QueryResult<ScheduleGetListQuery, ScheduleGetListQueryVariables>;
export const ScheduleGetManyDocument = gql`
    query ScheduleGetMany($ids: [ID!]!) {
  data: schedulesByID(ids: $ids) {
    ...ScheduleFragment
  }
}
    ${ScheduleFragmentFragmentDoc}`;

/**
 * __useScheduleGetManyQuery__
 *
 * To run a query within a React component, call `useScheduleGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useScheduleGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScheduleGetManyQuery, ScheduleGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<ScheduleGetManyQuery, ScheduleGetManyQueryVariables>(ScheduleGetManyDocument, baseOptions);
      }
export function useScheduleGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScheduleGetManyQuery, ScheduleGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScheduleGetManyQuery, ScheduleGetManyQueryVariables>(ScheduleGetManyDocument, baseOptions);
        }
export type ScheduleGetManyQueryHookResult = ReturnType<typeof useScheduleGetManyQuery>;
export type ScheduleGetManyLazyQueryHookResult = ReturnType<typeof useScheduleGetManyLazyQuery>;
export type ScheduleGetManyQueryResult = ApolloReactCommon.QueryResult<ScheduleGetManyQuery, ScheduleGetManyQueryVariables>;
export const StudyBenefitCreateDocument = gql`
    mutation StudyBenefitCreate($input: StudyBenefitInput!) {
  data: studyBenefitCreate(input: $input) {
    data: studyBenefit {
      ...StudyBenefitFragment
    }
  }
}
    ${StudyBenefitFragmentFragmentDoc}`;
export type StudyBenefitCreateMutationFn = ApolloReactCommon.MutationFunction<StudyBenefitCreateMutation, StudyBenefitCreateMutationVariables>;

/**
 * __useStudyBenefitCreateMutation__
 *
 * To run a mutation, you first call `useStudyBenefitCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyBenefitCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyBenefitCreateMutation, { data, loading, error }] = useStudyBenefitCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyBenefitCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyBenefitCreateMutation, StudyBenefitCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyBenefitCreateMutation, StudyBenefitCreateMutationVariables>(StudyBenefitCreateDocument, baseOptions);
      }
export type StudyBenefitCreateMutationHookResult = ReturnType<typeof useStudyBenefitCreateMutation>;
export type StudyBenefitCreateMutationResult = ApolloReactCommon.MutationResult<StudyBenefitCreateMutation>;
export type StudyBenefitCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyBenefitCreateMutation, StudyBenefitCreateMutationVariables>;
export const StudyBenefitUpdateDocument = gql`
    mutation StudyBenefitUpdate($id: ID!, $input: StudyBenefitInput!) {
  data: studyBenefitUpdate(id: $id, input: $input) {
    data: studyBenefit {
      ...StudyBenefitFragment
    }
  }
}
    ${StudyBenefitFragmentFragmentDoc}`;
export type StudyBenefitUpdateMutationFn = ApolloReactCommon.MutationFunction<StudyBenefitUpdateMutation, StudyBenefitUpdateMutationVariables>;

/**
 * __useStudyBenefitUpdateMutation__
 *
 * To run a mutation, you first call `useStudyBenefitUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyBenefitUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyBenefitUpdateMutation, { data, loading, error }] = useStudyBenefitUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyBenefitUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyBenefitUpdateMutation, StudyBenefitUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyBenefitUpdateMutation, StudyBenefitUpdateMutationVariables>(StudyBenefitUpdateDocument, baseOptions);
      }
export type StudyBenefitUpdateMutationHookResult = ReturnType<typeof useStudyBenefitUpdateMutation>;
export type StudyBenefitUpdateMutationResult = ApolloReactCommon.MutationResult<StudyBenefitUpdateMutation>;
export type StudyBenefitUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyBenefitUpdateMutation, StudyBenefitUpdateMutationVariables>;
export const StudyBenefitDeleteDocument = gql`
    mutation StudyBenefitDelete($id: ID!) {
  data: studyBenefitDelete(id: $id) {
    data: studyBenefit {
      ...StudyBenefitFragment
    }
  }
}
    ${StudyBenefitFragmentFragmentDoc}`;
export type StudyBenefitDeleteMutationFn = ApolloReactCommon.MutationFunction<StudyBenefitDeleteMutation, StudyBenefitDeleteMutationVariables>;

/**
 * __useStudyBenefitDeleteMutation__
 *
 * To run a mutation, you first call `useStudyBenefitDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyBenefitDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyBenefitDeleteMutation, { data, loading, error }] = useStudyBenefitDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyBenefitDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyBenefitDeleteMutation, StudyBenefitDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyBenefitDeleteMutation, StudyBenefitDeleteMutationVariables>(StudyBenefitDeleteDocument, baseOptions);
      }
export type StudyBenefitDeleteMutationHookResult = ReturnType<typeof useStudyBenefitDeleteMutation>;
export type StudyBenefitDeleteMutationResult = ApolloReactCommon.MutationResult<StudyBenefitDeleteMutation>;
export type StudyBenefitDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyBenefitDeleteMutation, StudyBenefitDeleteMutationVariables>;
export const StudyEventGetListDocument = gql`
    query StudyEventGetList($filterBy: StudyEventFilters, $orderBy: StudyEventOrder, $pagination: Pagination) {
  data: studyEvents(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...StudyEventFragment
    }
    total: totalCount
  }
}
    ${StudyEventFragmentFragmentDoc}`;

/**
 * __useStudyEventGetListQuery__
 *
 * To run a query within a React component, call `useStudyEventGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyEventGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyEventGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useStudyEventGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyEventGetListQuery, StudyEventGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyEventGetListQuery, StudyEventGetListQueryVariables>(StudyEventGetListDocument, baseOptions);
      }
export function useStudyEventGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyEventGetListQuery, StudyEventGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyEventGetListQuery, StudyEventGetListQueryVariables>(StudyEventGetListDocument, baseOptions);
        }
export type StudyEventGetListQueryHookResult = ReturnType<typeof useStudyEventGetListQuery>;
export type StudyEventGetListLazyQueryHookResult = ReturnType<typeof useStudyEventGetListLazyQuery>;
export type StudyEventGetListQueryResult = ApolloReactCommon.QueryResult<StudyEventGetListQuery, StudyEventGetListQueryVariables>;
export const StudyEventCreateDocument = gql`
    mutation StudyEventCreate($input: StudyEventInput!) {
  data: studyEventCreate(input: $input) {
    data: studyEvent {
      ...StudyEventFragment
    }
  }
}
    ${StudyEventFragmentFragmentDoc}`;
export type StudyEventCreateMutationFn = ApolloReactCommon.MutationFunction<StudyEventCreateMutation, StudyEventCreateMutationVariables>;

/**
 * __useStudyEventCreateMutation__
 *
 * To run a mutation, you first call `useStudyEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyEventCreateMutation, { data, loading, error }] = useStudyEventCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyEventCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyEventCreateMutation, StudyEventCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyEventCreateMutation, StudyEventCreateMutationVariables>(StudyEventCreateDocument, baseOptions);
      }
export type StudyEventCreateMutationHookResult = ReturnType<typeof useStudyEventCreateMutation>;
export type StudyEventCreateMutationResult = ApolloReactCommon.MutationResult<StudyEventCreateMutation>;
export type StudyEventCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyEventCreateMutation, StudyEventCreateMutationVariables>;
export const StudyEventBulkCreateDocument = gql`
    mutation StudyEventBulkCreate($inputs: [StudyEventInput!]!) {
  data: studyEventBulkCreate(inputs: $inputs) {
    data: studyEvents {
      ...StudyEventFragment
    }
  }
}
    ${StudyEventFragmentFragmentDoc}`;
export type StudyEventBulkCreateMutationFn = ApolloReactCommon.MutationFunction<StudyEventBulkCreateMutation, StudyEventBulkCreateMutationVariables>;

/**
 * __useStudyEventBulkCreateMutation__
 *
 * To run a mutation, you first call `useStudyEventBulkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyEventBulkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyEventBulkCreateMutation, { data, loading, error }] = useStudyEventBulkCreateMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useStudyEventBulkCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyEventBulkCreateMutation, StudyEventBulkCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyEventBulkCreateMutation, StudyEventBulkCreateMutationVariables>(StudyEventBulkCreateDocument, baseOptions);
      }
export type StudyEventBulkCreateMutationHookResult = ReturnType<typeof useStudyEventBulkCreateMutation>;
export type StudyEventBulkCreateMutationResult = ApolloReactCommon.MutationResult<StudyEventBulkCreateMutation>;
export type StudyEventBulkCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyEventBulkCreateMutation, StudyEventBulkCreateMutationVariables>;
export const StudyEventGetManyDocument = gql`
    query StudyEventGetMany($ids: [ID!]!) {
  data: studyEventsByIDs(ids: $ids) {
    ...StudyEventFragment
  }
}
    ${StudyEventFragmentFragmentDoc}`;

/**
 * __useStudyEventGetManyQuery__
 *
 * To run a query within a React component, call `useStudyEventGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyEventGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyEventGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useStudyEventGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyEventGetManyQuery, StudyEventGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyEventGetManyQuery, StudyEventGetManyQueryVariables>(StudyEventGetManyDocument, baseOptions);
      }
export function useStudyEventGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyEventGetManyQuery, StudyEventGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyEventGetManyQuery, StudyEventGetManyQueryVariables>(StudyEventGetManyDocument, baseOptions);
        }
export type StudyEventGetManyQueryHookResult = ReturnType<typeof useStudyEventGetManyQuery>;
export type StudyEventGetManyLazyQueryHookResult = ReturnType<typeof useStudyEventGetManyLazyQuery>;
export type StudyEventGetManyQueryResult = ApolloReactCommon.QueryResult<StudyEventGetManyQuery, StudyEventGetManyQueryVariables>;
export const BulkUpdateStudyEventInStudyDocument = gql`
    mutation BulkUpdateStudyEventInStudy($studyID: ID!, $input: [StudyEventBulkInput!]!) {
  bulkUpdateStudyEventInStudy(studyID: $studyID, input: $input) {
    study {
      id
      title
      startDate
      endDate
    }
  }
}
    `;
export type BulkUpdateStudyEventInStudyMutationFn = ApolloReactCommon.MutationFunction<BulkUpdateStudyEventInStudyMutation, BulkUpdateStudyEventInStudyMutationVariables>;

/**
 * __useBulkUpdateStudyEventInStudyMutation__
 *
 * To run a mutation, you first call `useBulkUpdateStudyEventInStudyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateStudyEventInStudyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateStudyEventInStudyMutation, { data, loading, error }] = useBulkUpdateStudyEventInStudyMutation({
 *   variables: {
 *      studyID: // value for 'studyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateStudyEventInStudyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkUpdateStudyEventInStudyMutation, BulkUpdateStudyEventInStudyMutationVariables>) {
        return ApolloReactHooks.useMutation<BulkUpdateStudyEventInStudyMutation, BulkUpdateStudyEventInStudyMutationVariables>(BulkUpdateStudyEventInStudyDocument, baseOptions);
      }
export type BulkUpdateStudyEventInStudyMutationHookResult = ReturnType<typeof useBulkUpdateStudyEventInStudyMutation>;
export type BulkUpdateStudyEventInStudyMutationResult = ApolloReactCommon.MutationResult<BulkUpdateStudyEventInStudyMutation>;
export type BulkUpdateStudyEventInStudyMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkUpdateStudyEventInStudyMutation, BulkUpdateStudyEventInStudyMutationVariables>;
export const StudyLectureMissionGetListDocument = gql`
    query StudyLectureMissionGetList($filterBy: StudyMissionFilters, $orderBy: StudyMissionOrder, $pagination: Pagination) {
  data: studyMissions(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...StudyLectureMissionFragment
    }
    total: totalCount
  }
}
    ${StudyLectureMissionFragmentFragmentDoc}`;

/**
 * __useStudyLectureMissionGetListQuery__
 *
 * To run a query within a React component, call `useStudyLectureMissionGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyLectureMissionGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyLectureMissionGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useStudyLectureMissionGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyLectureMissionGetListQuery, StudyLectureMissionGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyLectureMissionGetListQuery, StudyLectureMissionGetListQueryVariables>(StudyLectureMissionGetListDocument, baseOptions);
      }
export function useStudyLectureMissionGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyLectureMissionGetListQuery, StudyLectureMissionGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyLectureMissionGetListQuery, StudyLectureMissionGetListQueryVariables>(StudyLectureMissionGetListDocument, baseOptions);
        }
export type StudyLectureMissionGetListQueryHookResult = ReturnType<typeof useStudyLectureMissionGetListQuery>;
export type StudyLectureMissionGetListLazyQueryHookResult = ReturnType<typeof useStudyLectureMissionGetListLazyQuery>;
export type StudyLectureMissionGetListQueryResult = ApolloReactCommon.QueryResult<StudyLectureMissionGetListQuery, StudyLectureMissionGetListQueryVariables>;
export const StudyLectureMissionGetDocument = gql`
    query StudyLectureMissionGet($id: ID!) {
  data: studyMission(id: $id) {
    ...StudyLectureMissionFragment
  }
}
    ${StudyLectureMissionFragmentFragmentDoc}`;

/**
 * __useStudyLectureMissionGetQuery__
 *
 * To run a query within a React component, call `useStudyLectureMissionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyLectureMissionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyLectureMissionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyLectureMissionGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyLectureMissionGetQuery, StudyLectureMissionGetQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyLectureMissionGetQuery, StudyLectureMissionGetQueryVariables>(StudyLectureMissionGetDocument, baseOptions);
      }
export function useStudyLectureMissionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyLectureMissionGetQuery, StudyLectureMissionGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyLectureMissionGetQuery, StudyLectureMissionGetQueryVariables>(StudyLectureMissionGetDocument, baseOptions);
        }
export type StudyLectureMissionGetQueryHookResult = ReturnType<typeof useStudyLectureMissionGetQuery>;
export type StudyLectureMissionGetLazyQueryHookResult = ReturnType<typeof useStudyLectureMissionGetLazyQuery>;
export type StudyLectureMissionGetQueryResult = ApolloReactCommon.QueryResult<StudyLectureMissionGetQuery, StudyLectureMissionGetQueryVariables>;
export const StudyLectureMissionCreateDocument = gql`
    mutation StudyLectureMissionCreate($input: StudyMissionCreateInput!) {
  data: studyMissionCreate(input: $input) {
    data: studyMission {
      ...StudyLectureMissionFragment
    }
  }
}
    ${StudyLectureMissionFragmentFragmentDoc}`;
export type StudyLectureMissionCreateMutationFn = ApolloReactCommon.MutationFunction<StudyLectureMissionCreateMutation, StudyLectureMissionCreateMutationVariables>;

/**
 * __useStudyLectureMissionCreateMutation__
 *
 * To run a mutation, you first call `useStudyLectureMissionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyLectureMissionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyLectureMissionCreateMutation, { data, loading, error }] = useStudyLectureMissionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyLectureMissionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyLectureMissionCreateMutation, StudyLectureMissionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyLectureMissionCreateMutation, StudyLectureMissionCreateMutationVariables>(StudyLectureMissionCreateDocument, baseOptions);
      }
export type StudyLectureMissionCreateMutationHookResult = ReturnType<typeof useStudyLectureMissionCreateMutation>;
export type StudyLectureMissionCreateMutationResult = ApolloReactCommon.MutationResult<StudyLectureMissionCreateMutation>;
export type StudyLectureMissionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyLectureMissionCreateMutation, StudyLectureMissionCreateMutationVariables>;
export const StudyLectureMissionUpdateDocument = gql`
    mutation StudyLectureMissionUpdate($id: ID!, $input: StudyMissionUpdateInput!) {
  data: studyMissionUpdate(id: $id, input: $input) {
    data: studyMission {
      ...StudyLectureMissionFragment
    }
  }
}
    ${StudyLectureMissionFragmentFragmentDoc}`;
export type StudyLectureMissionUpdateMutationFn = ApolloReactCommon.MutationFunction<StudyLectureMissionUpdateMutation, StudyLectureMissionUpdateMutationVariables>;

/**
 * __useStudyLectureMissionUpdateMutation__
 *
 * To run a mutation, you first call `useStudyLectureMissionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyLectureMissionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyLectureMissionUpdateMutation, { data, loading, error }] = useStudyLectureMissionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyLectureMissionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyLectureMissionUpdateMutation, StudyLectureMissionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyLectureMissionUpdateMutation, StudyLectureMissionUpdateMutationVariables>(StudyLectureMissionUpdateDocument, baseOptions);
      }
export type StudyLectureMissionUpdateMutationHookResult = ReturnType<typeof useStudyLectureMissionUpdateMutation>;
export type StudyLectureMissionUpdateMutationResult = ApolloReactCommon.MutationResult<StudyLectureMissionUpdateMutation>;
export type StudyLectureMissionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyLectureMissionUpdateMutation, StudyLectureMissionUpdateMutationVariables>;
export const StudyLectureMissionDeleteDocument = gql`
    mutation StudyLectureMissionDelete($id: ID!) {
  data: studyMissionDelete(id: $id) {
    data: studyMission {
      id
    }
  }
}
    `;
export type StudyLectureMissionDeleteMutationFn = ApolloReactCommon.MutationFunction<StudyLectureMissionDeleteMutation, StudyLectureMissionDeleteMutationVariables>;

/**
 * __useStudyLectureMissionDeleteMutation__
 *
 * To run a mutation, you first call `useStudyLectureMissionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyLectureMissionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyLectureMissionDeleteMutation, { data, loading, error }] = useStudyLectureMissionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyLectureMissionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyLectureMissionDeleteMutation, StudyLectureMissionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyLectureMissionDeleteMutation, StudyLectureMissionDeleteMutationVariables>(StudyLectureMissionDeleteDocument, baseOptions);
      }
export type StudyLectureMissionDeleteMutationHookResult = ReturnType<typeof useStudyLectureMissionDeleteMutation>;
export type StudyLectureMissionDeleteMutationResult = ApolloReactCommon.MutationResult<StudyLectureMissionDeleteMutation>;
export type StudyLectureMissionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyLectureMissionDeleteMutation, StudyLectureMissionDeleteMutationVariables>;
export const StudyLectureMissionDeleteManyDocument = gql`
    mutation StudyLectureMissionDeleteMany($ids: [ID!]!) {
  data: studyMissionDeleteMany(ids: $ids) {
    data: studyMissions {
      id
    }
  }
}
    `;
export type StudyLectureMissionDeleteManyMutationFn = ApolloReactCommon.MutationFunction<StudyLectureMissionDeleteManyMutation, StudyLectureMissionDeleteManyMutationVariables>;

/**
 * __useStudyLectureMissionDeleteManyMutation__
 *
 * To run a mutation, you first call `useStudyLectureMissionDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyLectureMissionDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyLectureMissionDeleteManyMutation, { data, loading, error }] = useStudyLectureMissionDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useStudyLectureMissionDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyLectureMissionDeleteManyMutation, StudyLectureMissionDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyLectureMissionDeleteManyMutation, StudyLectureMissionDeleteManyMutationVariables>(StudyLectureMissionDeleteManyDocument, baseOptions);
      }
export type StudyLectureMissionDeleteManyMutationHookResult = ReturnType<typeof useStudyLectureMissionDeleteManyMutation>;
export type StudyLectureMissionDeleteManyMutationResult = ApolloReactCommon.MutationResult<StudyLectureMissionDeleteManyMutation>;
export type StudyLectureMissionDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyLectureMissionDeleteManyMutation, StudyLectureMissionDeleteManyMutationVariables>;
export const StudyLinkMissionGetListDocument = gql`
    query StudyLinkMissionGetList($filterBy: StudyMissionFilters, $orderBy: StudyMissionOrder, $pagination: Pagination) {
  data: studyMissions(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...StudyLinkMissionFragment
    }
    total: totalCount
  }
}
    ${StudyLinkMissionFragmentFragmentDoc}`;

/**
 * __useStudyLinkMissionGetListQuery__
 *
 * To run a query within a React component, call `useStudyLinkMissionGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyLinkMissionGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyLinkMissionGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useStudyLinkMissionGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyLinkMissionGetListQuery, StudyLinkMissionGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyLinkMissionGetListQuery, StudyLinkMissionGetListQueryVariables>(StudyLinkMissionGetListDocument, baseOptions);
      }
export function useStudyLinkMissionGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyLinkMissionGetListQuery, StudyLinkMissionGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyLinkMissionGetListQuery, StudyLinkMissionGetListQueryVariables>(StudyLinkMissionGetListDocument, baseOptions);
        }
export type StudyLinkMissionGetListQueryHookResult = ReturnType<typeof useStudyLinkMissionGetListQuery>;
export type StudyLinkMissionGetListLazyQueryHookResult = ReturnType<typeof useStudyLinkMissionGetListLazyQuery>;
export type StudyLinkMissionGetListQueryResult = ApolloReactCommon.QueryResult<StudyLinkMissionGetListQuery, StudyLinkMissionGetListQueryVariables>;
export const StudyLinkMissionGetDocument = gql`
    query StudyLinkMissionGet($id: ID!) {
  data: studyMission(id: $id) {
    ...StudyLinkMissionFragment
  }
}
    ${StudyLinkMissionFragmentFragmentDoc}`;

/**
 * __useStudyLinkMissionGetQuery__
 *
 * To run a query within a React component, call `useStudyLinkMissionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyLinkMissionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyLinkMissionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyLinkMissionGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyLinkMissionGetQuery, StudyLinkMissionGetQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyLinkMissionGetQuery, StudyLinkMissionGetQueryVariables>(StudyLinkMissionGetDocument, baseOptions);
      }
export function useStudyLinkMissionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyLinkMissionGetQuery, StudyLinkMissionGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyLinkMissionGetQuery, StudyLinkMissionGetQueryVariables>(StudyLinkMissionGetDocument, baseOptions);
        }
export type StudyLinkMissionGetQueryHookResult = ReturnType<typeof useStudyLinkMissionGetQuery>;
export type StudyLinkMissionGetLazyQueryHookResult = ReturnType<typeof useStudyLinkMissionGetLazyQuery>;
export type StudyLinkMissionGetQueryResult = ApolloReactCommon.QueryResult<StudyLinkMissionGetQuery, StudyLinkMissionGetQueryVariables>;
export const StudyLinkMissionCreateDocument = gql`
    mutation StudyLinkMissionCreate($input: StudyMissionCreateInput!) {
  data: studyMissionCreate(input: $input) {
    data: studyMission {
      ...StudyLinkMissionFragment
    }
  }
}
    ${StudyLinkMissionFragmentFragmentDoc}`;
export type StudyLinkMissionCreateMutationFn = ApolloReactCommon.MutationFunction<StudyLinkMissionCreateMutation, StudyLinkMissionCreateMutationVariables>;

/**
 * __useStudyLinkMissionCreateMutation__
 *
 * To run a mutation, you first call `useStudyLinkMissionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyLinkMissionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyLinkMissionCreateMutation, { data, loading, error }] = useStudyLinkMissionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyLinkMissionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyLinkMissionCreateMutation, StudyLinkMissionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyLinkMissionCreateMutation, StudyLinkMissionCreateMutationVariables>(StudyLinkMissionCreateDocument, baseOptions);
      }
export type StudyLinkMissionCreateMutationHookResult = ReturnType<typeof useStudyLinkMissionCreateMutation>;
export type StudyLinkMissionCreateMutationResult = ApolloReactCommon.MutationResult<StudyLinkMissionCreateMutation>;
export type StudyLinkMissionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyLinkMissionCreateMutation, StudyLinkMissionCreateMutationVariables>;
export const StudyLinkMissionUpdateDocument = gql`
    mutation StudyLinkMissionUpdate($id: ID!, $input: StudyMissionUpdateInput!) {
  data: studyMissionUpdate(id: $id, input: $input) {
    data: studyMission {
      ...StudyLinkMissionFragment
    }
  }
}
    ${StudyLinkMissionFragmentFragmentDoc}`;
export type StudyLinkMissionUpdateMutationFn = ApolloReactCommon.MutationFunction<StudyLinkMissionUpdateMutation, StudyLinkMissionUpdateMutationVariables>;

/**
 * __useStudyLinkMissionUpdateMutation__
 *
 * To run a mutation, you first call `useStudyLinkMissionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyLinkMissionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyLinkMissionUpdateMutation, { data, loading, error }] = useStudyLinkMissionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyLinkMissionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyLinkMissionUpdateMutation, StudyLinkMissionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyLinkMissionUpdateMutation, StudyLinkMissionUpdateMutationVariables>(StudyLinkMissionUpdateDocument, baseOptions);
      }
export type StudyLinkMissionUpdateMutationHookResult = ReturnType<typeof useStudyLinkMissionUpdateMutation>;
export type StudyLinkMissionUpdateMutationResult = ApolloReactCommon.MutationResult<StudyLinkMissionUpdateMutation>;
export type StudyLinkMissionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyLinkMissionUpdateMutation, StudyLinkMissionUpdateMutationVariables>;
export const StudyLinkMissionDeleteDocument = gql`
    mutation StudyLinkMissionDelete($id: ID!) {
  data: studyMissionDelete(id: $id) {
    data: studyMission {
      id
    }
  }
}
    `;
export type StudyLinkMissionDeleteMutationFn = ApolloReactCommon.MutationFunction<StudyLinkMissionDeleteMutation, StudyLinkMissionDeleteMutationVariables>;

/**
 * __useStudyLinkMissionDeleteMutation__
 *
 * To run a mutation, you first call `useStudyLinkMissionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyLinkMissionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyLinkMissionDeleteMutation, { data, loading, error }] = useStudyLinkMissionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyLinkMissionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyLinkMissionDeleteMutation, StudyLinkMissionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyLinkMissionDeleteMutation, StudyLinkMissionDeleteMutationVariables>(StudyLinkMissionDeleteDocument, baseOptions);
      }
export type StudyLinkMissionDeleteMutationHookResult = ReturnType<typeof useStudyLinkMissionDeleteMutation>;
export type StudyLinkMissionDeleteMutationResult = ApolloReactCommon.MutationResult<StudyLinkMissionDeleteMutation>;
export type StudyLinkMissionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyLinkMissionDeleteMutation, StudyLinkMissionDeleteMutationVariables>;
export const StudyLinkMissionDeleteManyDocument = gql`
    mutation StudyLinkMissionDeleteMany($ids: [ID!]!) {
  data: studyMissionDeleteMany(ids: $ids) {
    data: studyMissions {
      id
    }
  }
}
    `;
export type StudyLinkMissionDeleteManyMutationFn = ApolloReactCommon.MutationFunction<StudyLinkMissionDeleteManyMutation, StudyLinkMissionDeleteManyMutationVariables>;

/**
 * __useStudyLinkMissionDeleteManyMutation__
 *
 * To run a mutation, you first call `useStudyLinkMissionDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyLinkMissionDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyLinkMissionDeleteManyMutation, { data, loading, error }] = useStudyLinkMissionDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useStudyLinkMissionDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyLinkMissionDeleteManyMutation, StudyLinkMissionDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyLinkMissionDeleteManyMutation, StudyLinkMissionDeleteManyMutationVariables>(StudyLinkMissionDeleteManyDocument, baseOptions);
      }
export type StudyLinkMissionDeleteManyMutationHookResult = ReturnType<typeof useStudyLinkMissionDeleteManyMutation>;
export type StudyLinkMissionDeleteManyMutationResult = ApolloReactCommon.MutationResult<StudyLinkMissionDeleteManyMutation>;
export type StudyLinkMissionDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyLinkMissionDeleteManyMutation, StudyLinkMissionDeleteManyMutationVariables>;
export const StudyReviewMissionGetListDocument = gql`
    query StudyReviewMissionGetList($filterBy: StudyMissionFilters, $orderBy: StudyMissionOrder, $pagination: Pagination) {
  data: studyMissions(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...StudyReviewMissionFragment
    }
    total: totalCount
  }
}
    ${StudyReviewMissionFragmentFragmentDoc}`;

/**
 * __useStudyReviewMissionGetListQuery__
 *
 * To run a query within a React component, call `useStudyReviewMissionGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyReviewMissionGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyReviewMissionGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useStudyReviewMissionGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyReviewMissionGetListQuery, StudyReviewMissionGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyReviewMissionGetListQuery, StudyReviewMissionGetListQueryVariables>(StudyReviewMissionGetListDocument, baseOptions);
      }
export function useStudyReviewMissionGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyReviewMissionGetListQuery, StudyReviewMissionGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyReviewMissionGetListQuery, StudyReviewMissionGetListQueryVariables>(StudyReviewMissionGetListDocument, baseOptions);
        }
export type StudyReviewMissionGetListQueryHookResult = ReturnType<typeof useStudyReviewMissionGetListQuery>;
export type StudyReviewMissionGetListLazyQueryHookResult = ReturnType<typeof useStudyReviewMissionGetListLazyQuery>;
export type StudyReviewMissionGetListQueryResult = ApolloReactCommon.QueryResult<StudyReviewMissionGetListQuery, StudyReviewMissionGetListQueryVariables>;
export const StudyReviewMissionGetDocument = gql`
    query StudyReviewMissionGet($id: ID!) {
  data: studyMission(id: $id) {
    ...StudyReviewMissionFragment
  }
}
    ${StudyReviewMissionFragmentFragmentDoc}`;

/**
 * __useStudyReviewMissionGetQuery__
 *
 * To run a query within a React component, call `useStudyReviewMissionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyReviewMissionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyReviewMissionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyReviewMissionGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyReviewMissionGetQuery, StudyReviewMissionGetQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyReviewMissionGetQuery, StudyReviewMissionGetQueryVariables>(StudyReviewMissionGetDocument, baseOptions);
      }
export function useStudyReviewMissionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyReviewMissionGetQuery, StudyReviewMissionGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyReviewMissionGetQuery, StudyReviewMissionGetQueryVariables>(StudyReviewMissionGetDocument, baseOptions);
        }
export type StudyReviewMissionGetQueryHookResult = ReturnType<typeof useStudyReviewMissionGetQuery>;
export type StudyReviewMissionGetLazyQueryHookResult = ReturnType<typeof useStudyReviewMissionGetLazyQuery>;
export type StudyReviewMissionGetQueryResult = ApolloReactCommon.QueryResult<StudyReviewMissionGetQuery, StudyReviewMissionGetQueryVariables>;
export const StudyReviewMissionCreateDocument = gql`
    mutation StudyReviewMissionCreate($input: StudyMissionCreateInput!) {
  data: studyMissionCreate(input: $input) {
    data: studyMission {
      ...StudyReviewMissionFragment
    }
  }
}
    ${StudyReviewMissionFragmentFragmentDoc}`;
export type StudyReviewMissionCreateMutationFn = ApolloReactCommon.MutationFunction<StudyReviewMissionCreateMutation, StudyReviewMissionCreateMutationVariables>;

/**
 * __useStudyReviewMissionCreateMutation__
 *
 * To run a mutation, you first call `useStudyReviewMissionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyReviewMissionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyReviewMissionCreateMutation, { data, loading, error }] = useStudyReviewMissionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyReviewMissionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyReviewMissionCreateMutation, StudyReviewMissionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyReviewMissionCreateMutation, StudyReviewMissionCreateMutationVariables>(StudyReviewMissionCreateDocument, baseOptions);
      }
export type StudyReviewMissionCreateMutationHookResult = ReturnType<typeof useStudyReviewMissionCreateMutation>;
export type StudyReviewMissionCreateMutationResult = ApolloReactCommon.MutationResult<StudyReviewMissionCreateMutation>;
export type StudyReviewMissionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyReviewMissionCreateMutation, StudyReviewMissionCreateMutationVariables>;
export const StudyReviewMissionUpdateDocument = gql`
    mutation StudyReviewMissionUpdate($id: ID!, $input: StudyMissionUpdateInput!) {
  data: studyMissionUpdate(id: $id, input: $input) {
    data: studyMission {
      ...StudyReviewMissionFragment
    }
  }
}
    ${StudyReviewMissionFragmentFragmentDoc}`;
export type StudyReviewMissionUpdateMutationFn = ApolloReactCommon.MutationFunction<StudyReviewMissionUpdateMutation, StudyReviewMissionUpdateMutationVariables>;

/**
 * __useStudyReviewMissionUpdateMutation__
 *
 * To run a mutation, you first call `useStudyReviewMissionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyReviewMissionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyReviewMissionUpdateMutation, { data, loading, error }] = useStudyReviewMissionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyReviewMissionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyReviewMissionUpdateMutation, StudyReviewMissionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyReviewMissionUpdateMutation, StudyReviewMissionUpdateMutationVariables>(StudyReviewMissionUpdateDocument, baseOptions);
      }
export type StudyReviewMissionUpdateMutationHookResult = ReturnType<typeof useStudyReviewMissionUpdateMutation>;
export type StudyReviewMissionUpdateMutationResult = ApolloReactCommon.MutationResult<StudyReviewMissionUpdateMutation>;
export type StudyReviewMissionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyReviewMissionUpdateMutation, StudyReviewMissionUpdateMutationVariables>;
export const StudyReviewMissionDeleteDocument = gql`
    mutation StudyReviewMissionDelete($id: ID!) {
  data: studyMissionDelete(id: $id) {
    data: studyMission {
      id
    }
  }
}
    `;
export type StudyReviewMissionDeleteMutationFn = ApolloReactCommon.MutationFunction<StudyReviewMissionDeleteMutation, StudyReviewMissionDeleteMutationVariables>;

/**
 * __useStudyReviewMissionDeleteMutation__
 *
 * To run a mutation, you first call `useStudyReviewMissionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyReviewMissionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyReviewMissionDeleteMutation, { data, loading, error }] = useStudyReviewMissionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyReviewMissionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyReviewMissionDeleteMutation, StudyReviewMissionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyReviewMissionDeleteMutation, StudyReviewMissionDeleteMutationVariables>(StudyReviewMissionDeleteDocument, baseOptions);
      }
export type StudyReviewMissionDeleteMutationHookResult = ReturnType<typeof useStudyReviewMissionDeleteMutation>;
export type StudyReviewMissionDeleteMutationResult = ApolloReactCommon.MutationResult<StudyReviewMissionDeleteMutation>;
export type StudyReviewMissionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyReviewMissionDeleteMutation, StudyReviewMissionDeleteMutationVariables>;
export const StudyReviewMissionDeleteManyDocument = gql`
    mutation StudyReviewMissionDeleteMany($ids: [ID!]!) {
  data: studyMissionDeleteMany(ids: $ids) {
    data: studyMissions {
      id
    }
  }
}
    `;
export type StudyReviewMissionDeleteManyMutationFn = ApolloReactCommon.MutationFunction<StudyReviewMissionDeleteManyMutation, StudyReviewMissionDeleteManyMutationVariables>;

/**
 * __useStudyReviewMissionDeleteManyMutation__
 *
 * To run a mutation, you first call `useStudyReviewMissionDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyReviewMissionDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyReviewMissionDeleteManyMutation, { data, loading, error }] = useStudyReviewMissionDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useStudyReviewMissionDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyReviewMissionDeleteManyMutation, StudyReviewMissionDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyReviewMissionDeleteManyMutation, StudyReviewMissionDeleteManyMutationVariables>(StudyReviewMissionDeleteManyDocument, baseOptions);
      }
export type StudyReviewMissionDeleteManyMutationHookResult = ReturnType<typeof useStudyReviewMissionDeleteManyMutation>;
export type StudyReviewMissionDeleteManyMutationResult = ApolloReactCommon.MutationResult<StudyReviewMissionDeleteManyMutation>;
export type StudyReviewMissionDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyReviewMissionDeleteManyMutation, StudyReviewMissionDeleteManyMutationVariables>;
export const UserStudyEventLogDocument = gql`
    query UserStudyEventLog($id: ID!, $userStudyEventfilterBy: UserStudyEventFilters, $userStudyEventorderBy: UserStudyEventOrder, $pagination: Pagination) {
  data: study(id: $id) {
    id
    userStudyEvents(filterBy: $userStudyEventfilterBy, orderBy: $userStudyEventorderBy, pagination: $pagination) {
      nodes {
        createdAt
        user {
          username
          phoneNumber
          name
          createdAt
        }
      }
      totalCount
    }
  }
}
    `;

/**
 * __useUserStudyEventLogQuery__
 *
 * To run a query within a React component, call `useUserStudyEventLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStudyEventLogQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStudyEventLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userStudyEventfilterBy: // value for 'userStudyEventfilterBy'
 *      userStudyEventorderBy: // value for 'userStudyEventorderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserStudyEventLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserStudyEventLogQuery, UserStudyEventLogQueryVariables>) {
        return ApolloReactHooks.useQuery<UserStudyEventLogQuery, UserStudyEventLogQueryVariables>(UserStudyEventLogDocument, baseOptions);
      }
export function useUserStudyEventLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStudyEventLogQuery, UserStudyEventLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserStudyEventLogQuery, UserStudyEventLogQueryVariables>(UserStudyEventLogDocument, baseOptions);
        }
export type UserStudyEventLogQueryHookResult = ReturnType<typeof useUserStudyEventLogQuery>;
export type UserStudyEventLogLazyQueryHookResult = ReturnType<typeof useUserStudyEventLogLazyQuery>;
export type UserStudyEventLogQueryResult = ApolloReactCommon.QueryResult<UserStudyEventLogQuery, UserStudyEventLogQueryVariables>;
export const UserStudyEventLogTotalCountDocument = gql`
    query UserStudyEventLogTotalCount($id: ID!) {
  data: study(id: $id) {
    id
    userStudyEvents {
      totalCount
    }
  }
}
    `;

/**
 * __useUserStudyEventLogTotalCountQuery__
 *
 * To run a query within a React component, call `useUserStudyEventLogTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStudyEventLogTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStudyEventLogTotalCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserStudyEventLogTotalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserStudyEventLogTotalCountQuery, UserStudyEventLogTotalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<UserStudyEventLogTotalCountQuery, UserStudyEventLogTotalCountQueryVariables>(UserStudyEventLogTotalCountDocument, baseOptions);
      }
export function useUserStudyEventLogTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStudyEventLogTotalCountQuery, UserStudyEventLogTotalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserStudyEventLogTotalCountQuery, UserStudyEventLogTotalCountQueryVariables>(UserStudyEventLogTotalCountDocument, baseOptions);
        }
export type UserStudyEventLogTotalCountQueryHookResult = ReturnType<typeof useUserStudyEventLogTotalCountQuery>;
export type UserStudyEventLogTotalCountLazyQueryHookResult = ReturnType<typeof useUserStudyEventLogTotalCountLazyQuery>;
export type UserStudyEventLogTotalCountQueryResult = ApolloReactCommon.QueryResult<UserStudyEventLogTotalCountQuery, UserStudyEventLogTotalCountQueryVariables>;
export const StudyGetDocument = gql`
    query StudyGet($id: ID!) {
  data: study(id: $id) {
    ...StudyFragment
  }
}
    ${StudyFragmentFragmentDoc}`;

/**
 * __useStudyGetQuery__
 *
 * To run a query within a React component, call `useStudyGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyGetQuery, StudyGetQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyGetQuery, StudyGetQueryVariables>(StudyGetDocument, baseOptions);
      }
export function useStudyGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyGetQuery, StudyGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyGetQuery, StudyGetQueryVariables>(StudyGetDocument, baseOptions);
        }
export type StudyGetQueryHookResult = ReturnType<typeof useStudyGetQuery>;
export type StudyGetLazyQueryHookResult = ReturnType<typeof useStudyGetLazyQuery>;
export type StudyGetQueryResult = ApolloReactCommon.QueryResult<StudyGetQuery, StudyGetQueryVariables>;
export const StudyGetListDocument = gql`
    query StudyGetList($filterBy: StudyFilters, $orderBy: StudyOrder, $pagination: Pagination) {
  data: studies(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...StudyFragment
    }
    total: totalCount
  }
}
    ${StudyFragmentFragmentDoc}`;

/**
 * __useStudyGetListQuery__
 *
 * To run a query within a React component, call `useStudyGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useStudyGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyGetListQuery, StudyGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyGetListQuery, StudyGetListQueryVariables>(StudyGetListDocument, baseOptions);
      }
export function useStudyGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyGetListQuery, StudyGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyGetListQuery, StudyGetListQueryVariables>(StudyGetListDocument, baseOptions);
        }
export type StudyGetListQueryHookResult = ReturnType<typeof useStudyGetListQuery>;
export type StudyGetListLazyQueryHookResult = ReturnType<typeof useStudyGetListLazyQuery>;
export type StudyGetListQueryResult = ApolloReactCommon.QueryResult<StudyGetListQuery, StudyGetListQueryVariables>;
export const StudyGetManyDocument = gql`
    query StudyGetMany($ids: [ID!]!) {
  data: studyByIDs(ids: $ids) {
    ...StudyFragment
  }
}
    ${StudyFragmentFragmentDoc}`;

/**
 * __useStudyGetManyQuery__
 *
 * To run a query within a React component, call `useStudyGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useStudyGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyGetManyQuery, StudyGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyGetManyQuery, StudyGetManyQueryVariables>(StudyGetManyDocument, baseOptions);
      }
export function useStudyGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyGetManyQuery, StudyGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyGetManyQuery, StudyGetManyQueryVariables>(StudyGetManyDocument, baseOptions);
        }
export type StudyGetManyQueryHookResult = ReturnType<typeof useStudyGetManyQuery>;
export type StudyGetManyLazyQueryHookResult = ReturnType<typeof useStudyGetManyLazyQuery>;
export type StudyGetManyQueryResult = ApolloReactCommon.QueryResult<StudyGetManyQuery, StudyGetManyQueryVariables>;
export const StudyCreateDocument = gql`
    mutation StudyCreate($input: StudyCreateInput!) {
  data: studyCreate(input: $input) {
    data: study {
      ...StudyFragment
    }
  }
}
    ${StudyFragmentFragmentDoc}`;
export type StudyCreateMutationFn = ApolloReactCommon.MutationFunction<StudyCreateMutation, StudyCreateMutationVariables>;

/**
 * __useStudyCreateMutation__
 *
 * To run a mutation, you first call `useStudyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyCreateMutation, { data, loading, error }] = useStudyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyCreateMutation, StudyCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyCreateMutation, StudyCreateMutationVariables>(StudyCreateDocument, baseOptions);
      }
export type StudyCreateMutationHookResult = ReturnType<typeof useStudyCreateMutation>;
export type StudyCreateMutationResult = ApolloReactCommon.MutationResult<StudyCreateMutation>;
export type StudyCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyCreateMutation, StudyCreateMutationVariables>;
export const StudyUpdateDocument = gql`
    mutation StudyUpdate($id: ID!, $input: StudyUpdateInput!) {
  data: studyUpdate(id: $id, input: $input) {
    data: study {
      ...StudyFragment
    }
  }
}
    ${StudyFragmentFragmentDoc}`;
export type StudyUpdateMutationFn = ApolloReactCommon.MutationFunction<StudyUpdateMutation, StudyUpdateMutationVariables>;

/**
 * __useStudyUpdateMutation__
 *
 * To run a mutation, you first call `useStudyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyUpdateMutation, { data, loading, error }] = useStudyUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyUpdateMutation, StudyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyUpdateMutation, StudyUpdateMutationVariables>(StudyUpdateDocument, baseOptions);
      }
export type StudyUpdateMutationHookResult = ReturnType<typeof useStudyUpdateMutation>;
export type StudyUpdateMutationResult = ApolloReactCommon.MutationResult<StudyUpdateMutation>;
export type StudyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyUpdateMutation, StudyUpdateMutationVariables>;
export const StudyDeleteDocument = gql`
    mutation StudyDelete($id: ID!) {
  data: studyDelete(id: $id) {
    data: study {
      ...StudyFragment
    }
  }
}
    ${StudyFragmentFragmentDoc}`;
export type StudyDeleteMutationFn = ApolloReactCommon.MutationFunction<StudyDeleteMutation, StudyDeleteMutationVariables>;

/**
 * __useStudyDeleteMutation__
 *
 * To run a mutation, you first call `useStudyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyDeleteMutation, { data, loading, error }] = useStudyDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyDeleteMutation, StudyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyDeleteMutation, StudyDeleteMutationVariables>(StudyDeleteDocument, baseOptions);
      }
export type StudyDeleteMutationHookResult = ReturnType<typeof useStudyDeleteMutation>;
export type StudyDeleteMutationResult = ApolloReactCommon.MutationResult<StudyDeleteMutation>;
export type StudyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyDeleteMutation, StudyDeleteMutationVariables>;
export const StudyDeleteManyDocument = gql`
    mutation StudyDeleteMany($ids: [ID!]!) {
  data: studyDeleteMany(ids: $ids) {
    data: studies {
      ...StudyFragment
    }
  }
}
    ${StudyFragmentFragmentDoc}`;
export type StudyDeleteManyMutationFn = ApolloReactCommon.MutationFunction<StudyDeleteManyMutation, StudyDeleteManyMutationVariables>;

/**
 * __useStudyDeleteManyMutation__
 *
 * To run a mutation, you first call `useStudyDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudyDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studyDeleteManyMutation, { data, loading, error }] = useStudyDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useStudyDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StudyDeleteManyMutation, StudyDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<StudyDeleteManyMutation, StudyDeleteManyMutationVariables>(StudyDeleteManyDocument, baseOptions);
      }
export type StudyDeleteManyMutationHookResult = ReturnType<typeof useStudyDeleteManyMutation>;
export type StudyDeleteManyMutationResult = ApolloReactCommon.MutationResult<StudyDeleteManyMutation>;
export type StudyDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<StudyDeleteManyMutation, StudyDeleteManyMutationVariables>;
export const BenefitDialog_StudyDocument = gql`
    query BenefitDialog_Study($id: ID!) {
  data: study(id: $id) {
    ...FullStudyFragment
  }
}
    ${FullStudyFragmentFragmentDoc}`;

/**
 * __useBenefitDialog_StudyQuery__
 *
 * To run a query within a React component, call `useBenefitDialog_StudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefitDialog_StudyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefitDialog_StudyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBenefitDialog_StudyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BenefitDialog_StudyQuery, BenefitDialog_StudyQueryVariables>) {
        return ApolloReactHooks.useQuery<BenefitDialog_StudyQuery, BenefitDialog_StudyQueryVariables>(BenefitDialog_StudyDocument, baseOptions);
      }
export function useBenefitDialog_StudyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenefitDialog_StudyQuery, BenefitDialog_StudyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BenefitDialog_StudyQuery, BenefitDialog_StudyQueryVariables>(BenefitDialog_StudyDocument, baseOptions);
        }
export type BenefitDialog_StudyQueryHookResult = ReturnType<typeof useBenefitDialog_StudyQuery>;
export type BenefitDialog_StudyLazyQueryHookResult = ReturnType<typeof useBenefitDialog_StudyLazyQuery>;
export type BenefitDialog_StudyQueryResult = ApolloReactCommon.QueryResult<BenefitDialog_StudyQuery, BenefitDialog_StudyQueryVariables>;
export const SupportTicketAnswerTemplateListDocument = gql`
    query SupportTicketAnswerTemplateList($filterBy: SupportTicketAnswerTemplateFilters, $orderBy: SupportTicketAnswerTemplateOrder, $pagination: Pagination) {
  data: supportTicketAnswerTemplates(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...SupportTicketAnswerTemplateFragment
    }
    total: totalCount
  }
}
    ${SupportTicketAnswerTemplateFragmentFragmentDoc}`;

/**
 * __useSupportTicketAnswerTemplateListQuery__
 *
 * To run a query within a React component, call `useSupportTicketAnswerTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketAnswerTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportTicketAnswerTemplateListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSupportTicketAnswerTemplateListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupportTicketAnswerTemplateListQuery, SupportTicketAnswerTemplateListQueryVariables>) {
        return ApolloReactHooks.useQuery<SupportTicketAnswerTemplateListQuery, SupportTicketAnswerTemplateListQueryVariables>(SupportTicketAnswerTemplateListDocument, baseOptions);
      }
export function useSupportTicketAnswerTemplateListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupportTicketAnswerTemplateListQuery, SupportTicketAnswerTemplateListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupportTicketAnswerTemplateListQuery, SupportTicketAnswerTemplateListQueryVariables>(SupportTicketAnswerTemplateListDocument, baseOptions);
        }
export type SupportTicketAnswerTemplateListQueryHookResult = ReturnType<typeof useSupportTicketAnswerTemplateListQuery>;
export type SupportTicketAnswerTemplateListLazyQueryHookResult = ReturnType<typeof useSupportTicketAnswerTemplateListLazyQuery>;
export type SupportTicketAnswerTemplateListQueryResult = ApolloReactCommon.QueryResult<SupportTicketAnswerTemplateListQuery, SupportTicketAnswerTemplateListQueryVariables>;
export const SupportTicketAnswerTemplateGetDocument = gql`
    query SupportTicketAnswerTemplateGet($id: ID!) {
  data: supportTicketAnswerTemplate(id: $id) {
    ...SupportTicketAnswerTemplateFragment
  }
}
    ${SupportTicketAnswerTemplateFragmentFragmentDoc}`;

/**
 * __useSupportTicketAnswerTemplateGetQuery__
 *
 * To run a query within a React component, call `useSupportTicketAnswerTemplateGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketAnswerTemplateGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportTicketAnswerTemplateGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupportTicketAnswerTemplateGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupportTicketAnswerTemplateGetQuery, SupportTicketAnswerTemplateGetQueryVariables>) {
        return ApolloReactHooks.useQuery<SupportTicketAnswerTemplateGetQuery, SupportTicketAnswerTemplateGetQueryVariables>(SupportTicketAnswerTemplateGetDocument, baseOptions);
      }
export function useSupportTicketAnswerTemplateGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupportTicketAnswerTemplateGetQuery, SupportTicketAnswerTemplateGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupportTicketAnswerTemplateGetQuery, SupportTicketAnswerTemplateGetQueryVariables>(SupportTicketAnswerTemplateGetDocument, baseOptions);
        }
export type SupportTicketAnswerTemplateGetQueryHookResult = ReturnType<typeof useSupportTicketAnswerTemplateGetQuery>;
export type SupportTicketAnswerTemplateGetLazyQueryHookResult = ReturnType<typeof useSupportTicketAnswerTemplateGetLazyQuery>;
export type SupportTicketAnswerTemplateGetQueryResult = ApolloReactCommon.QueryResult<SupportTicketAnswerTemplateGetQuery, SupportTicketAnswerTemplateGetQueryVariables>;
export const SupportTicketAnswerTemplateGetManyDocument = gql`
    query SupportTicketAnswerTemplateGetMany($ids: [ID!]!) {
  data: supportTicketAnswerTemplatesByID(ids: $ids) {
    ...SupportTicketAnswerTemplateFragment
  }
}
    ${SupportTicketAnswerTemplateFragmentFragmentDoc}`;

/**
 * __useSupportTicketAnswerTemplateGetManyQuery__
 *
 * To run a query within a React component, call `useSupportTicketAnswerTemplateGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketAnswerTemplateGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportTicketAnswerTemplateGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSupportTicketAnswerTemplateGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupportTicketAnswerTemplateGetManyQuery, SupportTicketAnswerTemplateGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<SupportTicketAnswerTemplateGetManyQuery, SupportTicketAnswerTemplateGetManyQueryVariables>(SupportTicketAnswerTemplateGetManyDocument, baseOptions);
      }
export function useSupportTicketAnswerTemplateGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupportTicketAnswerTemplateGetManyQuery, SupportTicketAnswerTemplateGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupportTicketAnswerTemplateGetManyQuery, SupportTicketAnswerTemplateGetManyQueryVariables>(SupportTicketAnswerTemplateGetManyDocument, baseOptions);
        }
export type SupportTicketAnswerTemplateGetManyQueryHookResult = ReturnType<typeof useSupportTicketAnswerTemplateGetManyQuery>;
export type SupportTicketAnswerTemplateGetManyLazyQueryHookResult = ReturnType<typeof useSupportTicketAnswerTemplateGetManyLazyQuery>;
export type SupportTicketAnswerTemplateGetManyQueryResult = ApolloReactCommon.QueryResult<SupportTicketAnswerTemplateGetManyQuery, SupportTicketAnswerTemplateGetManyQueryVariables>;
export const SupportTicketCategoryListDocument = gql`
    query SupportTicketCategoryList($filterBy: SupportTicketCategoryFilters, $orderBy: SupportTicketCategoryOrder, $pagination: Pagination) {
  data: supportTicketCategories(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...SupportTicketCategoryFragment
    }
    total: totalCount
  }
}
    ${SupportTicketCategoryFragmentFragmentDoc}`;

/**
 * __useSupportTicketCategoryListQuery__
 *
 * To run a query within a React component, call `useSupportTicketCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportTicketCategoryListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSupportTicketCategoryListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupportTicketCategoryListQuery, SupportTicketCategoryListQueryVariables>) {
        return ApolloReactHooks.useQuery<SupportTicketCategoryListQuery, SupportTicketCategoryListQueryVariables>(SupportTicketCategoryListDocument, baseOptions);
      }
export function useSupportTicketCategoryListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupportTicketCategoryListQuery, SupportTicketCategoryListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupportTicketCategoryListQuery, SupportTicketCategoryListQueryVariables>(SupportTicketCategoryListDocument, baseOptions);
        }
export type SupportTicketCategoryListQueryHookResult = ReturnType<typeof useSupportTicketCategoryListQuery>;
export type SupportTicketCategoryListLazyQueryHookResult = ReturnType<typeof useSupportTicketCategoryListLazyQuery>;
export type SupportTicketCategoryListQueryResult = ApolloReactCommon.QueryResult<SupportTicketCategoryListQuery, SupportTicketCategoryListQueryVariables>;
export const SupportTicketListDocument = gql`
    query SupportTicketList($filterBy: SupportTicketFilters, $orderBy: SupportTicketOrder, $pagination: Pagination) {
  data: supportTickets(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...SupportTicketFragment
    }
    total: totalCount
  }
}
    ${SupportTicketFragmentFragmentDoc}`;

/**
 * __useSupportTicketListQuery__
 *
 * To run a query within a React component, call `useSupportTicketListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportTicketListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSupportTicketListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupportTicketListQuery, SupportTicketListQueryVariables>) {
        return ApolloReactHooks.useQuery<SupportTicketListQuery, SupportTicketListQueryVariables>(SupportTicketListDocument, baseOptions);
      }
export function useSupportTicketListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupportTicketListQuery, SupportTicketListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupportTicketListQuery, SupportTicketListQueryVariables>(SupportTicketListDocument, baseOptions);
        }
export type SupportTicketListQueryHookResult = ReturnType<typeof useSupportTicketListQuery>;
export type SupportTicketListLazyQueryHookResult = ReturnType<typeof useSupportTicketListLazyQuery>;
export type SupportTicketListQueryResult = ApolloReactCommon.QueryResult<SupportTicketListQuery, SupportTicketListQueryVariables>;
export const SupportTicketGetDocument = gql`
    query SupportTicketGet($id: ID!) {
  data: supportTicket(id: $id) {
    ...SupportTicketFragment
  }
}
    ${SupportTicketFragmentFragmentDoc}`;

/**
 * __useSupportTicketGetQuery__
 *
 * To run a query within a React component, call `useSupportTicketGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportTicketGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupportTicketGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupportTicketGetQuery, SupportTicketGetQueryVariables>) {
        return ApolloReactHooks.useQuery<SupportTicketGetQuery, SupportTicketGetQueryVariables>(SupportTicketGetDocument, baseOptions);
      }
export function useSupportTicketGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupportTicketGetQuery, SupportTicketGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupportTicketGetQuery, SupportTicketGetQueryVariables>(SupportTicketGetDocument, baseOptions);
        }
export type SupportTicketGetQueryHookResult = ReturnType<typeof useSupportTicketGetQuery>;
export type SupportTicketGetLazyQueryHookResult = ReturnType<typeof useSupportTicketGetLazyQuery>;
export type SupportTicketGetQueryResult = ApolloReactCommon.QueryResult<SupportTicketGetQuery, SupportTicketGetQueryVariables>;
export const SupportTicketUpdateDocument = gql`
    mutation SupportTicketUpdate($id: ID!, $input: SupportTicketInput!) {
  data: supportTicketUpdate(id: $id, input: $input) {
    data: supportTicket {
      ...SupportTicketFragment
    }
  }
}
    ${SupportTicketFragmentFragmentDoc}`;
export type SupportTicketUpdateMutationFn = ApolloReactCommon.MutationFunction<SupportTicketUpdateMutation, SupportTicketUpdateMutationVariables>;

/**
 * __useSupportTicketUpdateMutation__
 *
 * To run a mutation, you first call `useSupportTicketUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supportTicketUpdateMutation, { data, loading, error }] = useSupportTicketUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupportTicketUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SupportTicketUpdateMutation, SupportTicketUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SupportTicketUpdateMutation, SupportTicketUpdateMutationVariables>(SupportTicketUpdateDocument, baseOptions);
      }
export type SupportTicketUpdateMutationHookResult = ReturnType<typeof useSupportTicketUpdateMutation>;
export type SupportTicketUpdateMutationResult = ApolloReactCommon.MutationResult<SupportTicketUpdateMutation>;
export type SupportTicketUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SupportTicketUpdateMutation, SupportTicketUpdateMutationVariables>;
export const SupportTicketCreateDocument = gql`
    mutation SupportTicketCreate($input: SupportTicketInput!) {
  data: supportTicketCreate(input: $input) {
    data: supportTicket {
      ...SupportTicketFragment
    }
  }
}
    ${SupportTicketFragmentFragmentDoc}`;
export type SupportTicketCreateMutationFn = ApolloReactCommon.MutationFunction<SupportTicketCreateMutation, SupportTicketCreateMutationVariables>;

/**
 * __useSupportTicketCreateMutation__
 *
 * To run a mutation, you first call `useSupportTicketCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supportTicketCreateMutation, { data, loading, error }] = useSupportTicketCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupportTicketCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SupportTicketCreateMutation, SupportTicketCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<SupportTicketCreateMutation, SupportTicketCreateMutationVariables>(SupportTicketCreateDocument, baseOptions);
      }
export type SupportTicketCreateMutationHookResult = ReturnType<typeof useSupportTicketCreateMutation>;
export type SupportTicketCreateMutationResult = ApolloReactCommon.MutationResult<SupportTicketCreateMutation>;
export type SupportTicketCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<SupportTicketCreateMutation, SupportTicketCreateMutationVariables>;
export const SupportTicketAdminCreateDocument = gql`
    mutation SupportTicketAdminCreate($input: SupportTicketAdminCreateInput!) {
  data: supportTicketAdminCreate(input: $input) {
    data: supportTicket {
      ...SupportTicketFragment
    }
  }
}
    ${SupportTicketFragmentFragmentDoc}`;
export type SupportTicketAdminCreateMutationFn = ApolloReactCommon.MutationFunction<SupportTicketAdminCreateMutation, SupportTicketAdminCreateMutationVariables>;

/**
 * __useSupportTicketAdminCreateMutation__
 *
 * To run a mutation, you first call `useSupportTicketAdminCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketAdminCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supportTicketAdminCreateMutation, { data, loading, error }] = useSupportTicketAdminCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupportTicketAdminCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SupportTicketAdminCreateMutation, SupportTicketAdminCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<SupportTicketAdminCreateMutation, SupportTicketAdminCreateMutationVariables>(SupportTicketAdminCreateDocument, baseOptions);
      }
export type SupportTicketAdminCreateMutationHookResult = ReturnType<typeof useSupportTicketAdminCreateMutation>;
export type SupportTicketAdminCreateMutationResult = ApolloReactCommon.MutationResult<SupportTicketAdminCreateMutation>;
export type SupportTicketAdminCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<SupportTicketAdminCreateMutation, SupportTicketAdminCreateMutationVariables>;
export const SupportTicketAdminUpdateDocument = gql`
    mutation SupportTicketAdminUpdate($id: ID!, $input: SupportTicketAdminUpdateInput!) {
  data: supportTicketAdminUpdate(id: $id, input: $input) {
    data: supportTicket {
      ...SupportTicketFragment
    }
  }
}
    ${SupportTicketFragmentFragmentDoc}`;
export type SupportTicketAdminUpdateMutationFn = ApolloReactCommon.MutationFunction<SupportTicketAdminUpdateMutation, SupportTicketAdminUpdateMutationVariables>;

/**
 * __useSupportTicketAdminUpdateMutation__
 *
 * To run a mutation, you first call `useSupportTicketAdminUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupportTicketAdminUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supportTicketAdminUpdateMutation, { data, loading, error }] = useSupportTicketAdminUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupportTicketAdminUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SupportTicketAdminUpdateMutation, SupportTicketAdminUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SupportTicketAdminUpdateMutation, SupportTicketAdminUpdateMutationVariables>(SupportTicketAdminUpdateDocument, baseOptions);
      }
export type SupportTicketAdminUpdateMutationHookResult = ReturnType<typeof useSupportTicketAdminUpdateMutation>;
export type SupportTicketAdminUpdateMutationResult = ApolloReactCommon.MutationResult<SupportTicketAdminUpdateMutation>;
export type SupportTicketAdminUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SupportTicketAdminUpdateMutation, SupportTicketAdminUpdateMutationVariables>;
export const TeacherCurationGetListDocument = gql`
    query TeacherCurationGetList($filterBy: TeacherCurationFilters, $orderBy: TeacherCurationOrder, $pagination: Pagination) {
  data: teacherCurations(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...TeacherCurationFragment
    }
    total: totalCount
  }
}
    ${TeacherCurationFragmentFragmentDoc}`;

/**
 * __useTeacherCurationGetListQuery__
 *
 * To run a query within a React component, call `useTeacherCurationGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherCurationGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherCurationGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTeacherCurationGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherCurationGetListQuery, TeacherCurationGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<TeacherCurationGetListQuery, TeacherCurationGetListQueryVariables>(TeacherCurationGetListDocument, baseOptions);
      }
export function useTeacherCurationGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherCurationGetListQuery, TeacherCurationGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeacherCurationGetListQuery, TeacherCurationGetListQueryVariables>(TeacherCurationGetListDocument, baseOptions);
        }
export type TeacherCurationGetListQueryHookResult = ReturnType<typeof useTeacherCurationGetListQuery>;
export type TeacherCurationGetListLazyQueryHookResult = ReturnType<typeof useTeacherCurationGetListLazyQuery>;
export type TeacherCurationGetListQueryResult = ApolloReactCommon.QueryResult<TeacherCurationGetListQuery, TeacherCurationGetListQueryVariables>;
export const TeacherCurationGetDocument = gql`
    query TeacherCurationGet($id: ID!) {
  data: teacherCuration(id: $id) {
    ...TeacherCurationFragment
  }
}
    ${TeacherCurationFragmentFragmentDoc}`;

/**
 * __useTeacherCurationGetQuery__
 *
 * To run a query within a React component, call `useTeacherCurationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherCurationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherCurationGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeacherCurationGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherCurationGetQuery, TeacherCurationGetQueryVariables>) {
        return ApolloReactHooks.useQuery<TeacherCurationGetQuery, TeacherCurationGetQueryVariables>(TeacherCurationGetDocument, baseOptions);
      }
export function useTeacherCurationGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherCurationGetQuery, TeacherCurationGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeacherCurationGetQuery, TeacherCurationGetQueryVariables>(TeacherCurationGetDocument, baseOptions);
        }
export type TeacherCurationGetQueryHookResult = ReturnType<typeof useTeacherCurationGetQuery>;
export type TeacherCurationGetLazyQueryHookResult = ReturnType<typeof useTeacherCurationGetLazyQuery>;
export type TeacherCurationGetQueryResult = ApolloReactCommon.QueryResult<TeacherCurationGetQuery, TeacherCurationGetQueryVariables>;
export const TeacherCurationCreateDocument = gql`
    mutation TeacherCurationCreate($input: TeacherCurationInput!) {
  data: teacherCurationCreate(input: $input) {
    data: teacherCuration {
      ...TeacherCurationFragment
    }
  }
}
    ${TeacherCurationFragmentFragmentDoc}`;
export type TeacherCurationCreateMutationFn = ApolloReactCommon.MutationFunction<TeacherCurationCreateMutation, TeacherCurationCreateMutationVariables>;

/**
 * __useTeacherCurationCreateMutation__
 *
 * To run a mutation, you first call `useTeacherCurationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherCurationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherCurationCreateMutation, { data, loading, error }] = useTeacherCurationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherCurationCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeacherCurationCreateMutation, TeacherCurationCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TeacherCurationCreateMutation, TeacherCurationCreateMutationVariables>(TeacherCurationCreateDocument, baseOptions);
      }
export type TeacherCurationCreateMutationHookResult = ReturnType<typeof useTeacherCurationCreateMutation>;
export type TeacherCurationCreateMutationResult = ApolloReactCommon.MutationResult<TeacherCurationCreateMutation>;
export type TeacherCurationCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<TeacherCurationCreateMutation, TeacherCurationCreateMutationVariables>;
export const TeacherCurationUpdateDocument = gql`
    mutation TeacherCurationUpdate($id: ID!, $input: TeacherCurationInput!) {
  data: teacherCurationUpdate(id: $id, input: $input) {
    data: teacherCuration {
      ...TeacherCurationFragment
    }
  }
}
    ${TeacherCurationFragmentFragmentDoc}`;
export type TeacherCurationUpdateMutationFn = ApolloReactCommon.MutationFunction<TeacherCurationUpdateMutation, TeacherCurationUpdateMutationVariables>;

/**
 * __useTeacherCurationUpdateMutation__
 *
 * To run a mutation, you first call `useTeacherCurationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherCurationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherCurationUpdateMutation, { data, loading, error }] = useTeacherCurationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherCurationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeacherCurationUpdateMutation, TeacherCurationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TeacherCurationUpdateMutation, TeacherCurationUpdateMutationVariables>(TeacherCurationUpdateDocument, baseOptions);
      }
export type TeacherCurationUpdateMutationHookResult = ReturnType<typeof useTeacherCurationUpdateMutation>;
export type TeacherCurationUpdateMutationResult = ApolloReactCommon.MutationResult<TeacherCurationUpdateMutation>;
export type TeacherCurationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TeacherCurationUpdateMutation, TeacherCurationUpdateMutationVariables>;
export const TeacherCurationDeleteDocument = gql`
    mutation TeacherCurationDelete($id: ID!) {
  data: teacherCurationDelete(id: $id) {
    data: teacherCuration {
      ...TeacherCurationFragment
    }
  }
}
    ${TeacherCurationFragmentFragmentDoc}`;
export type TeacherCurationDeleteMutationFn = ApolloReactCommon.MutationFunction<TeacherCurationDeleteMutation, TeacherCurationDeleteMutationVariables>;

/**
 * __useTeacherCurationDeleteMutation__
 *
 * To run a mutation, you first call `useTeacherCurationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherCurationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherCurationDeleteMutation, { data, loading, error }] = useTeacherCurationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeacherCurationDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeacherCurationDeleteMutation, TeacherCurationDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TeacherCurationDeleteMutation, TeacherCurationDeleteMutationVariables>(TeacherCurationDeleteDocument, baseOptions);
      }
export type TeacherCurationDeleteMutationHookResult = ReturnType<typeof useTeacherCurationDeleteMutation>;
export type TeacherCurationDeleteMutationResult = ApolloReactCommon.MutationResult<TeacherCurationDeleteMutation>;
export type TeacherCurationDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TeacherCurationDeleteMutation, TeacherCurationDeleteMutationVariables>;
export const TeacherGetListDocument = gql`
    query TeacherGetList($filterBy: TeacherFilters, $orderBy: TeacherOrder, $pagination: Pagination) {
  data: teachers(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...TeacherFragment
    }
    total: totalCount
  }
}
    ${TeacherFragmentFragmentDoc}`;

/**
 * __useTeacherGetListQuery__
 *
 * To run a query within a React component, call `useTeacherGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTeacherGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherGetListQuery, TeacherGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<TeacherGetListQuery, TeacherGetListQueryVariables>(TeacherGetListDocument, baseOptions);
      }
export function useTeacherGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherGetListQuery, TeacherGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeacherGetListQuery, TeacherGetListQueryVariables>(TeacherGetListDocument, baseOptions);
        }
export type TeacherGetListQueryHookResult = ReturnType<typeof useTeacherGetListQuery>;
export type TeacherGetListLazyQueryHookResult = ReturnType<typeof useTeacherGetListLazyQuery>;
export type TeacherGetListQueryResult = ApolloReactCommon.QueryResult<TeacherGetListQuery, TeacherGetListQueryVariables>;
export const TeacherGetDocument = gql`
    query TeacherGet($id: ID!) {
  data: teacher(id: $id) {
    ...TeacherDetailFragment
  }
}
    ${TeacherDetailFragmentFragmentDoc}`;

/**
 * __useTeacherGetQuery__
 *
 * To run a query within a React component, call `useTeacherGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeacherGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherGetQuery, TeacherGetQueryVariables>) {
        return ApolloReactHooks.useQuery<TeacherGetQuery, TeacherGetQueryVariables>(TeacherGetDocument, baseOptions);
      }
export function useTeacherGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherGetQuery, TeacherGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeacherGetQuery, TeacherGetQueryVariables>(TeacherGetDocument, baseOptions);
        }
export type TeacherGetQueryHookResult = ReturnType<typeof useTeacherGetQuery>;
export type TeacherGetLazyQueryHookResult = ReturnType<typeof useTeacherGetLazyQuery>;
export type TeacherGetQueryResult = ApolloReactCommon.QueryResult<TeacherGetQuery, TeacherGetQueryVariables>;
export const TeacherGetManyDocument = gql`
    query TeacherGetMany($ids: [ID!]!) {
  data: teachersByID(ids: $ids) {
    ...TeacherDetailFragment
  }
}
    ${TeacherDetailFragmentFragmentDoc}`;

/**
 * __useTeacherGetManyQuery__
 *
 * To run a query within a React component, call `useTeacherGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTeacherGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherGetManyQuery, TeacherGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<TeacherGetManyQuery, TeacherGetManyQueryVariables>(TeacherGetManyDocument, baseOptions);
      }
export function useTeacherGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherGetManyQuery, TeacherGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeacherGetManyQuery, TeacherGetManyQueryVariables>(TeacherGetManyDocument, baseOptions);
        }
export type TeacherGetManyQueryHookResult = ReturnType<typeof useTeacherGetManyQuery>;
export type TeacherGetManyLazyQueryHookResult = ReturnType<typeof useTeacherGetManyLazyQuery>;
export type TeacherGetManyQueryResult = ApolloReactCommon.QueryResult<TeacherGetManyQuery, TeacherGetManyQueryVariables>;
export const TeacherCreateDocument = gql`
    mutation TeacherCreate($input: TeacherInput!) {
  data: teacherCreate(input: $input) {
    data: teacher {
      ...TeacherDetailFragment
    }
  }
}
    ${TeacherDetailFragmentFragmentDoc}`;
export type TeacherCreateMutationFn = ApolloReactCommon.MutationFunction<TeacherCreateMutation, TeacherCreateMutationVariables>;

/**
 * __useTeacherCreateMutation__
 *
 * To run a mutation, you first call `useTeacherCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherCreateMutation, { data, loading, error }] = useTeacherCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeacherCreateMutation, TeacherCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TeacherCreateMutation, TeacherCreateMutationVariables>(TeacherCreateDocument, baseOptions);
      }
export type TeacherCreateMutationHookResult = ReturnType<typeof useTeacherCreateMutation>;
export type TeacherCreateMutationResult = ApolloReactCommon.MutationResult<TeacherCreateMutation>;
export type TeacherCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<TeacherCreateMutation, TeacherCreateMutationVariables>;
export const TeacherUpdateDocument = gql`
    mutation TeacherUpdate($id: ID!, $input: TeacherInput!) {
  data: teacherUpdate(id: $id, input: $input) {
    data: teacher {
      ...TeacherDetailFragment
    }
  }
}
    ${TeacherDetailFragmentFragmentDoc}`;
export type TeacherUpdateMutationFn = ApolloReactCommon.MutationFunction<TeacherUpdateMutation, TeacherUpdateMutationVariables>;

/**
 * __useTeacherUpdateMutation__
 *
 * To run a mutation, you first call `useTeacherUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherUpdateMutation, { data, loading, error }] = useTeacherUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeacherUpdateMutation, TeacherUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TeacherUpdateMutation, TeacherUpdateMutationVariables>(TeacherUpdateDocument, baseOptions);
      }
export type TeacherUpdateMutationHookResult = ReturnType<typeof useTeacherUpdateMutation>;
export type TeacherUpdateMutationResult = ApolloReactCommon.MutationResult<TeacherUpdateMutation>;
export type TeacherUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TeacherUpdateMutation, TeacherUpdateMutationVariables>;
export const TeamGetListDocument = gql`
    query TeamGetList($filterBy: TeamFilters, $orderBy: TeamOrder, $pagination: Pagination) {
  data: teams(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...TeamFragment
    }
    total: totalCount
  }
}
    ${TeamFragmentFragmentDoc}`;

/**
 * __useTeamGetListQuery__
 *
 * To run a query within a React component, call `useTeamGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTeamGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamGetListQuery, TeamGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamGetListQuery, TeamGetListQueryVariables>(TeamGetListDocument, baseOptions);
      }
export function useTeamGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamGetListQuery, TeamGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamGetListQuery, TeamGetListQueryVariables>(TeamGetListDocument, baseOptions);
        }
export type TeamGetListQueryHookResult = ReturnType<typeof useTeamGetListQuery>;
export type TeamGetListLazyQueryHookResult = ReturnType<typeof useTeamGetListLazyQuery>;
export type TeamGetListQueryResult = ApolloReactCommon.QueryResult<TeamGetListQuery, TeamGetListQueryVariables>;
export const TeamGetDocument = gql`
    query TeamGet($id: ID!) {
  data: team(id: $id) {
    ...TeamFragment
  }
}
    ${TeamFragmentFragmentDoc}`;

/**
 * __useTeamGetQuery__
 *
 * To run a query within a React component, call `useTeamGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamGetQuery, TeamGetQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamGetQuery, TeamGetQueryVariables>(TeamGetDocument, baseOptions);
      }
export function useTeamGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamGetQuery, TeamGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamGetQuery, TeamGetQueryVariables>(TeamGetDocument, baseOptions);
        }
export type TeamGetQueryHookResult = ReturnType<typeof useTeamGetQuery>;
export type TeamGetLazyQueryHookResult = ReturnType<typeof useTeamGetLazyQuery>;
export type TeamGetQueryResult = ApolloReactCommon.QueryResult<TeamGetQuery, TeamGetQueryVariables>;
export const TeamGetManyDocument = gql`
    query TeamGetMany($ids: [ID!]!) {
  data: teamsByID(ids: $ids) {
    ...TeamFragment
  }
}
    ${TeamFragmentFragmentDoc}`;

/**
 * __useTeamGetManyQuery__
 *
 * To run a query within a React component, call `useTeamGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTeamGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamGetManyQuery, TeamGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamGetManyQuery, TeamGetManyQueryVariables>(TeamGetManyDocument, baseOptions);
      }
export function useTeamGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamGetManyQuery, TeamGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamGetManyQuery, TeamGetManyQueryVariables>(TeamGetManyDocument, baseOptions);
        }
export type TeamGetManyQueryHookResult = ReturnType<typeof useTeamGetManyQuery>;
export type TeamGetManyLazyQueryHookResult = ReturnType<typeof useTeamGetManyLazyQuery>;
export type TeamGetManyQueryResult = ApolloReactCommon.QueryResult<TeamGetManyQuery, TeamGetManyQueryVariables>;
export const TeamCreateDocument = gql`
    mutation TeamCreate($input: TeamInput!) {
  data: teamCreate(input: $input) {
    data: team {
      ...TeamFragment
    }
  }
}
    ${TeamFragmentFragmentDoc}`;
export type TeamCreateMutationFn = ApolloReactCommon.MutationFunction<TeamCreateMutation, TeamCreateMutationVariables>;

/**
 * __useTeamCreateMutation__
 *
 * To run a mutation, you first call `useTeamCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamCreateMutation, { data, loading, error }] = useTeamCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeamCreateMutation, TeamCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TeamCreateMutation, TeamCreateMutationVariables>(TeamCreateDocument, baseOptions);
      }
export type TeamCreateMutationHookResult = ReturnType<typeof useTeamCreateMutation>;
export type TeamCreateMutationResult = ApolloReactCommon.MutationResult<TeamCreateMutation>;
export type TeamCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<TeamCreateMutation, TeamCreateMutationVariables>;
export const TeamUpdateDocument = gql`
    mutation TeamUpdate($id: ID!, $input: TeamInput!) {
  data: teamUpdate(id: $id, input: $input) {
    data: team {
      ...TeamFragment
    }
  }
}
    ${TeamFragmentFragmentDoc}`;
export type TeamUpdateMutationFn = ApolloReactCommon.MutationFunction<TeamUpdateMutation, TeamUpdateMutationVariables>;

/**
 * __useTeamUpdateMutation__
 *
 * To run a mutation, you first call `useTeamUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamUpdateMutation, { data, loading, error }] = useTeamUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeamUpdateMutation, TeamUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TeamUpdateMutation, TeamUpdateMutationVariables>(TeamUpdateDocument, baseOptions);
      }
export type TeamUpdateMutationHookResult = ReturnType<typeof useTeamUpdateMutation>;
export type TeamUpdateMutationResult = ApolloReactCommon.MutationResult<TeamUpdateMutation>;
export type TeamUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TeamUpdateMutation, TeamUpdateMutationVariables>;
export const TeamDeleteManyDocument = gql`
    mutation TeamDeleteMany($ids: [ID!]!) {
  data: teamDeleteMany(ids: $ids) {
    data: teams {
      ...TeamFragment
    }
  }
}
    ${TeamFragmentFragmentDoc}`;
export type TeamDeleteManyMutationFn = ApolloReactCommon.MutationFunction<TeamDeleteManyMutation, TeamDeleteManyMutationVariables>;

/**
 * __useTeamDeleteManyMutation__
 *
 * To run a mutation, you first call `useTeamDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamDeleteManyMutation, { data, loading, error }] = useTeamDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTeamDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TeamDeleteManyMutation, TeamDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<TeamDeleteManyMutation, TeamDeleteManyMutationVariables>(TeamDeleteManyDocument, baseOptions);
      }
export type TeamDeleteManyMutationHookResult = ReturnType<typeof useTeamDeleteManyMutation>;
export type TeamDeleteManyMutationResult = ApolloReactCommon.MutationResult<TeamDeleteManyMutation>;
export type TeamDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<TeamDeleteManyMutation, TeamDeleteManyMutationVariables>;
export const UserDeviceGetListDocument = gql`
    query UserDeviceGetList($filterBy: UserDeviceFilters, $orderBy: UserDeviceOrder, $pagination: Pagination) {
  data: userDevices(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...UserDeviceFragment
    }
    total: totalCount
  }
}
    ${UserDeviceFragmentFragmentDoc}`;

/**
 * __useUserDeviceGetListQuery__
 *
 * To run a query within a React component, call `useUserDeviceGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDeviceGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDeviceGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserDeviceGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserDeviceGetListQuery, UserDeviceGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<UserDeviceGetListQuery, UserDeviceGetListQueryVariables>(UserDeviceGetListDocument, baseOptions);
      }
export function useUserDeviceGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserDeviceGetListQuery, UserDeviceGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserDeviceGetListQuery, UserDeviceGetListQueryVariables>(UserDeviceGetListDocument, baseOptions);
        }
export type UserDeviceGetListQueryHookResult = ReturnType<typeof useUserDeviceGetListQuery>;
export type UserDeviceGetListLazyQueryHookResult = ReturnType<typeof useUserDeviceGetListLazyQuery>;
export type UserDeviceGetListQueryResult = ApolloReactCommon.QueryResult<UserDeviceGetListQuery, UserDeviceGetListQueryVariables>;
export const UserDeviceDeleteDocument = gql`
    mutation UserDeviceDelete($userID: ID!, $deviceID: String!) {
  data: userDeviceDelete(userID: $userID, deviceID: $deviceID) {
    data: userDevice {
      ...UserDeviceFragment
    }
  }
}
    ${UserDeviceFragmentFragmentDoc}`;
export type UserDeviceDeleteMutationFn = ApolloReactCommon.MutationFunction<UserDeviceDeleteMutation, UserDeviceDeleteMutationVariables>;

/**
 * __useUserDeviceDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeviceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeviceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeviceDeleteMutation, { data, loading, error }] = useUserDeviceDeleteMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      deviceID: // value for 'deviceID'
 *   },
 * });
 */
export function useUserDeviceDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserDeviceDeleteMutation, UserDeviceDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<UserDeviceDeleteMutation, UserDeviceDeleteMutationVariables>(UserDeviceDeleteDocument, baseOptions);
      }
export type UserDeviceDeleteMutationHookResult = ReturnType<typeof useUserDeviceDeleteMutation>;
export type UserDeviceDeleteMutationResult = ApolloReactCommon.MutationResult<UserDeviceDeleteMutation>;
export type UserDeviceDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<UserDeviceDeleteMutation, UserDeviceDeleteMutationVariables>;
export const UserStudyEventGetListDocument = gql`
    query UserStudyEventGetList($filterBy: UserStudyEventFilters, $orderBy: UserStudyEventOrder, $pagination: Pagination) {
  data: userStudyEvents(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...UserStudyEventFragment
    }
    total: totalCount
  }
}
    ${UserStudyEventFragmentFragmentDoc}`;

/**
 * __useUserStudyEventGetListQuery__
 *
 * To run a query within a React component, call `useUserStudyEventGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStudyEventGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStudyEventGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserStudyEventGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserStudyEventGetListQuery, UserStudyEventGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<UserStudyEventGetListQuery, UserStudyEventGetListQueryVariables>(UserStudyEventGetListDocument, baseOptions);
      }
export function useUserStudyEventGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStudyEventGetListQuery, UserStudyEventGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserStudyEventGetListQuery, UserStudyEventGetListQueryVariables>(UserStudyEventGetListDocument, baseOptions);
        }
export type UserStudyEventGetListQueryHookResult = ReturnType<typeof useUserStudyEventGetListQuery>;
export type UserStudyEventGetListLazyQueryHookResult = ReturnType<typeof useUserStudyEventGetListLazyQuery>;
export type UserStudyEventGetListQueryResult = ApolloReactCommon.QueryResult<UserStudyEventGetListQuery, UserStudyEventGetListQueryVariables>;
export const UserStudyEventGetDocument = gql`
    query UserStudyEventGet($id: ID!) {
  data: userStudyEvent(id: $id) {
    ...UserStudyEventFragment
  }
}
    ${UserStudyEventFragmentFragmentDoc}`;

/**
 * __useUserStudyEventGetQuery__
 *
 * To run a query within a React component, call `useUserStudyEventGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStudyEventGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStudyEventGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserStudyEventGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserStudyEventGetQuery, UserStudyEventGetQueryVariables>) {
        return ApolloReactHooks.useQuery<UserStudyEventGetQuery, UserStudyEventGetQueryVariables>(UserStudyEventGetDocument, baseOptions);
      }
export function useUserStudyEventGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStudyEventGetQuery, UserStudyEventGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserStudyEventGetQuery, UserStudyEventGetQueryVariables>(UserStudyEventGetDocument, baseOptions);
        }
export type UserStudyEventGetQueryHookResult = ReturnType<typeof useUserStudyEventGetQuery>;
export type UserStudyEventGetLazyQueryHookResult = ReturnType<typeof useUserStudyEventGetLazyQuery>;
export type UserStudyEventGetQueryResult = ApolloReactCommon.QueryResult<UserStudyEventGetQuery, UserStudyEventGetQueryVariables>;
export const UserStudyMissionGetListDocument = gql`
    query UserStudyMissionGetList($filterBy: UserStudyMissionFilters, $orderBy: UserStudyMissionOrder, $pagination: Pagination) {
  data: userStudyMissions(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...UserStudyMissionFragment
    }
    total: totalCount
  }
}
    ${UserStudyMissionFragmentFragmentDoc}`;

/**
 * __useUserStudyMissionGetListQuery__
 *
 * To run a query within a React component, call `useUserStudyMissionGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStudyMissionGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStudyMissionGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserStudyMissionGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserStudyMissionGetListQuery, UserStudyMissionGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<UserStudyMissionGetListQuery, UserStudyMissionGetListQueryVariables>(UserStudyMissionGetListDocument, baseOptions);
      }
export function useUserStudyMissionGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStudyMissionGetListQuery, UserStudyMissionGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserStudyMissionGetListQuery, UserStudyMissionGetListQueryVariables>(UserStudyMissionGetListDocument, baseOptions);
        }
export type UserStudyMissionGetListQueryHookResult = ReturnType<typeof useUserStudyMissionGetListQuery>;
export type UserStudyMissionGetListLazyQueryHookResult = ReturnType<typeof useUserStudyMissionGetListLazyQuery>;
export type UserStudyMissionGetListQueryResult = ApolloReactCommon.QueryResult<UserStudyMissionGetListQuery, UserStudyMissionGetListQueryVariables>;
export const UserGetListDocument = gql`
    query UserGetList($filterBy: UserFilters, $orderBy: UserOrder, $pagination: Pagination) {
  data: users(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...UserDetailFragment
    }
    total: totalCount
  }
}
    ${UserDetailFragmentFragmentDoc}`;

/**
 * __useUserGetListQuery__
 *
 * To run a query within a React component, call `useUserGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserGetListQuery, UserGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<UserGetListQuery, UserGetListQueryVariables>(UserGetListDocument, baseOptions);
      }
export function useUserGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetListQuery, UserGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserGetListQuery, UserGetListQueryVariables>(UserGetListDocument, baseOptions);
        }
export type UserGetListQueryHookResult = ReturnType<typeof useUserGetListQuery>;
export type UserGetListLazyQueryHookResult = ReturnType<typeof useUserGetListLazyQuery>;
export type UserGetListQueryResult = ApolloReactCommon.QueryResult<UserGetListQuery, UserGetListQueryVariables>;
export const UserGetDocument = gql`
    query UserGet($id: ID!) {
  data: user(id: $id) {
    ...UserDetailFragment
  }
}
    ${UserDetailFragmentFragmentDoc}`;

/**
 * __useUserGetQuery__
 *
 * To run a query within a React component, call `useUserGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
        return ApolloReactHooks.useQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, baseOptions);
      }
export function useUserGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, baseOptions);
        }
export type UserGetQueryHookResult = ReturnType<typeof useUserGetQuery>;
export type UserGetLazyQueryHookResult = ReturnType<typeof useUserGetLazyQuery>;
export type UserGetQueryResult = ApolloReactCommon.QueryResult<UserGetQuery, UserGetQueryVariables>;
export const UserGetManyDocument = gql`
    query UserGetMany($ids: [ID!]!) {
  data: usersByID(ids: $ids) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserGetManyQuery__
 *
 * To run a query within a React component, call `useUserGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUserGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserGetManyQuery, UserGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<UserGetManyQuery, UserGetManyQueryVariables>(UserGetManyDocument, baseOptions);
      }
export function useUserGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetManyQuery, UserGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserGetManyQuery, UserGetManyQueryVariables>(UserGetManyDocument, baseOptions);
        }
export type UserGetManyQueryHookResult = ReturnType<typeof useUserGetManyQuery>;
export type UserGetManyLazyQueryHookResult = ReturnType<typeof useUserGetManyLazyQuery>;
export type UserGetManyQueryResult = ApolloReactCommon.QueryResult<UserGetManyQuery, UserGetManyQueryVariables>;
export const UserPointCreateDocument = gql`
    mutation UserPointCreate($input: UserPointCreateInput!) {
  userPointCreate(input: $input) {
    userPoint {
      id
      createdAt
      point
      message
    }
  }
}
    `;
export type UserPointCreateMutationFn = ApolloReactCommon.MutationFunction<UserPointCreateMutation, UserPointCreateMutationVariables>;

/**
 * __useUserPointCreateMutation__
 *
 * To run a mutation, you first call `useUserPointCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPointCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPointCreateMutation, { data, loading, error }] = useUserPointCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserPointCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserPointCreateMutation, UserPointCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<UserPointCreateMutation, UserPointCreateMutationVariables>(UserPointCreateDocument, baseOptions);
      }
export type UserPointCreateMutationHookResult = ReturnType<typeof useUserPointCreateMutation>;
export type UserPointCreateMutationResult = ApolloReactCommon.MutationResult<UserPointCreateMutation>;
export type UserPointCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<UserPointCreateMutation, UserPointCreateMutationVariables>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($id: ID!, $input: AdminUserInfoUpdate!) {
  data: adminUpdateUserInfo(id: $id, input: $input) {
    data: user {
      ...UserDetailFragment
    }
  }
}
    ${UserDetailFragmentFragmentDoc}`;
export type UserUpdateMutationFn = ApolloReactCommon.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, baseOptions);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = ApolloReactCommon.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserDeleteDocument = gql`
    mutation userDelete($userID: ID!, $reason: LeaveReason!, $message: String) {
  data: deleteUser(userID: $userID, reason: $reason, message: $message) {
    data: userleavelog {
      id
      userID
      reason
      message
    }
  }
}
    `;
export type UserDeleteMutationFn = ApolloReactCommon.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      reason: // value for 'reason'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUserDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, baseOptions);
      }
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = ApolloReactCommon.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const GqlUserExportDataDocument = gql`
    query gqlUserExportData($filterBy: UserFilters, $pagination: Pagination) {
  data: users(filterBy: $filterBy, orderBy: {direction: DESC, field: ID}, pagination: $pagination) {
    data: nodes {
      externalCompany
      id
      username
      name
      email
      phoneNumber
      birth
      examToTake
      residence
      createdAt
      lastLoginAt
      authUser {
        id
      }
      allianceUser {
        schoolName
        major
        grade
        studentId
      }
    }
    total: totalCount
  }
}
    `;

/**
 * __useGqlUserExportDataQuery__
 *
 * To run a query within a React component, call `useGqlUserExportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlUserExportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlUserExportDataQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGqlUserExportDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlUserExportDataQuery, GqlUserExportDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GqlUserExportDataQuery, GqlUserExportDataQueryVariables>(GqlUserExportDataDocument, baseOptions);
      }
export function useGqlUserExportDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlUserExportDataQuery, GqlUserExportDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlUserExportDataQuery, GqlUserExportDataQueryVariables>(GqlUserExportDataDocument, baseOptions);
        }
export type GqlUserExportDataQueryHookResult = ReturnType<typeof useGqlUserExportDataQuery>;
export type GqlUserExportDataLazyQueryHookResult = ReturnType<typeof useGqlUserExportDataLazyQuery>;
export type GqlUserExportDataQueryResult = ApolloReactCommon.QueryResult<GqlUserExportDataQuery, GqlUserExportDataQueryVariables>;
export const StudyMissionCreate_StudyDocument = gql`
    query StudyMissionCreate_Study($id: ID!) {
  study(id: $id) {
    id
    title
    startDate
    endDate
  }
}
    `;

/**
 * __useStudyMissionCreate_StudyQuery__
 *
 * To run a query within a React component, call `useStudyMissionCreate_StudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudyMissionCreate_StudyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudyMissionCreate_StudyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudyMissionCreate_StudyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StudyMissionCreate_StudyQuery, StudyMissionCreate_StudyQueryVariables>) {
        return ApolloReactHooks.useQuery<StudyMissionCreate_StudyQuery, StudyMissionCreate_StudyQueryVariables>(StudyMissionCreate_StudyDocument, baseOptions);
      }
export function useStudyMissionCreate_StudyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudyMissionCreate_StudyQuery, StudyMissionCreate_StudyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StudyMissionCreate_StudyQuery, StudyMissionCreate_StudyQueryVariables>(StudyMissionCreate_StudyDocument, baseOptions);
        }
export type StudyMissionCreate_StudyQueryHookResult = ReturnType<typeof useStudyMissionCreate_StudyQuery>;
export type StudyMissionCreate_StudyLazyQueryHookResult = ReturnType<typeof useStudyMissionCreate_StudyLazyQuery>;
export type StudyMissionCreate_StudyQueryResult = ApolloReactCommon.QueryResult<StudyMissionCreate_StudyQuery, StudyMissionCreate_StudyQueryVariables>;
export const AlliancePermissionByCurrentUserDocument = gql`
    query AlliancePermissionByCurrentUser {
  permissionsByCurrentUser {
    scope
  }
}
    `;

/**
 * __useAlliancePermissionByCurrentUserQuery__
 *
 * To run a query within a React component, call `useAlliancePermissionByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlliancePermissionByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlliancePermissionByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAlliancePermissionByCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AlliancePermissionByCurrentUserQuery, AlliancePermissionByCurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<AlliancePermissionByCurrentUserQuery, AlliancePermissionByCurrentUserQueryVariables>(AlliancePermissionByCurrentUserDocument, baseOptions);
      }
export function useAlliancePermissionByCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AlliancePermissionByCurrentUserQuery, AlliancePermissionByCurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AlliancePermissionByCurrentUserQuery, AlliancePermissionByCurrentUserQueryVariables>(AlliancePermissionByCurrentUserDocument, baseOptions);
        }
export type AlliancePermissionByCurrentUserQueryHookResult = ReturnType<typeof useAlliancePermissionByCurrentUserQuery>;
export type AlliancePermissionByCurrentUserLazyQueryHookResult = ReturnType<typeof useAlliancePermissionByCurrentUserLazyQuery>;
export type AlliancePermissionByCurrentUserQueryResult = ApolloReactCommon.QueryResult<AlliancePermissionByCurrentUserQuery, AlliancePermissionByCurrentUserQueryVariables>;
export const GetAllianceUniversityByCurrentUserDocument = gql`
    query getAllianceUniversityByCurrentUser {
  allianceByUserID {
    allianceName
    templatePath
    expiredAt
  }
}
    `;

/**
 * __useGetAllianceUniversityByCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetAllianceUniversityByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllianceUniversityByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllianceUniversityByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllianceUniversityByCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllianceUniversityByCurrentUserQuery, GetAllianceUniversityByCurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllianceUniversityByCurrentUserQuery, GetAllianceUniversityByCurrentUserQueryVariables>(GetAllianceUniversityByCurrentUserDocument, baseOptions);
      }
export function useGetAllianceUniversityByCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllianceUniversityByCurrentUserQuery, GetAllianceUniversityByCurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllianceUniversityByCurrentUserQuery, GetAllianceUniversityByCurrentUserQueryVariables>(GetAllianceUniversityByCurrentUserDocument, baseOptions);
        }
export type GetAllianceUniversityByCurrentUserQueryHookResult = ReturnType<typeof useGetAllianceUniversityByCurrentUserQuery>;
export type GetAllianceUniversityByCurrentUserLazyQueryHookResult = ReturnType<typeof useGetAllianceUniversityByCurrentUserLazyQuery>;
export type GetAllianceUniversityByCurrentUserQueryResult = ApolloReactCommon.QueryResult<GetAllianceUniversityByCurrentUserQuery, GetAllianceUniversityByCurrentUserQueryVariables>;
export const CampaignsForDataExtractNewDocument = gql`
    query CampaignsForDataExtractNew($filterBy: CampaignFilters!) {
  campaignsForDataExtract(filterBy: $filterBy) {
    nodes {
      id
      name
      data {
        category
        name
        industry
        etc
      }
      type
      campaignUsers {
        id
        joinAt
        name
        phoneNumber
        email
        allowMarketing
      }
    }
  }
}
    `;

/**
 * __useCampaignsForDataExtractNewQuery__
 *
 * To run a query within a React component, call `useCampaignsForDataExtractNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsForDataExtractNewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsForDataExtractNewQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useCampaignsForDataExtractNewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CampaignsForDataExtractNewQuery, CampaignsForDataExtractNewQueryVariables>) {
        return ApolloReactHooks.useQuery<CampaignsForDataExtractNewQuery, CampaignsForDataExtractNewQueryVariables>(CampaignsForDataExtractNewDocument, baseOptions);
      }
export function useCampaignsForDataExtractNewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignsForDataExtractNewQuery, CampaignsForDataExtractNewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CampaignsForDataExtractNewQuery, CampaignsForDataExtractNewQueryVariables>(CampaignsForDataExtractNewDocument, baseOptions);
        }
export type CampaignsForDataExtractNewQueryHookResult = ReturnType<typeof useCampaignsForDataExtractNewQuery>;
export type CampaignsForDataExtractNewLazyQueryHookResult = ReturnType<typeof useCampaignsForDataExtractNewLazyQuery>;
export type CampaignsForDataExtractNewQueryResult = ApolloReactCommon.QueryResult<CampaignsForDataExtractNewQuery, CampaignsForDataExtractNewQueryVariables>;
export const ContentsDocument = gql`
    query Contents($editingContentIDs: [ID!], $startDate: Date!, $endDate: Date!) {
  contents(filterBy: {ids: $editingContentIDs}) {
    nodes {
      id
      type
      service
      subject
      video {
        id
        videoLessonProducts(pagination: {page: 1, pageSize: 10000}) {
          nodes {
            id
            lessonNumber
            subject
            playTime(unit: MINUTES, startDate: $startDate, endDate: $endDate)
          }
        }
      }
    }
  }
}
    `;

/**
 * __useContentsQuery__
 *
 * To run a query within a React component, call `useContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentsQuery({
 *   variables: {
 *      editingContentIDs: // value for 'editingContentIDs'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useContentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContentsQuery, ContentsQueryVariables>) {
        return ApolloReactHooks.useQuery<ContentsQuery, ContentsQueryVariables>(ContentsDocument, baseOptions);
      }
export function useContentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentsQuery, ContentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContentsQuery, ContentsQueryVariables>(ContentsDocument, baseOptions);
        }
export type ContentsQueryHookResult = ReturnType<typeof useContentsQuery>;
export type ContentsLazyQueryHookResult = ReturnType<typeof useContentsLazyQuery>;
export type ContentsQueryResult = ApolloReactCommon.QueryResult<ContentsQuery, ContentsQueryVariables>;
export const PurchaseRankGroupContentPurchaseStatsDocument = gql`
    query PurchaseRankGroupContentPurchaseStats($startDate: Date!, $endDate: Date!, $service: ContentPurchaseRankServiceType!, $contentType: ContentPurchaseRankContentType!, $payType: ContentPurchaseRankPayType!, $field: ContentPurchaseRankOrderField!, $pageSize: Int!, $period: ContentPurchaseRankPeriod!, $page: Int!, $withPlayUserCount: Boolean!) {
  thisYearContentPurchaseRankGroup: contentPurchaseRankGroup(filterBy: {startDate: $startDate, endDate: $endDate, service: $service, contentType: $contentType, payType: $payType, period: $period}, orderBy: {field: $field, direction: DESC}, pagination: {page: $page, pageSize: $pageSize}) {
    nodes {
      date
      summary {
        totalSalesAmount
        totalSalesCount
        totalPlayCount
        totalPlayUserCount @include(if: $withPlayUserCount)
        totalPlayTime(unit: HOURS)
      }
      contentPurchaseRanks {
        contentType
        content {
          id
          subject
        }
        payType
        salesAmount
        salesCount
        playCount
        playUserCount @include(if: $withPlayUserCount)
        playTime(unit: HOURS)
        rateOfOrderField
      }
      totalCount
    }
  }
  lastYearContentPurchaseRankGroup: contentPurchaseRankGroup(filterBy: {startDate: $startDate, endDate: $endDate, service: $service, contentType: $contentType, payType: $payType, period: $period}, orderBy: {field: $field, direction: DESC}, pagination: {page: $page, pageSize: $pageSize}) {
    nodesOfLastYear {
      date
      summary {
        totalSalesAmount
        totalSalesCount
        totalPlayCount
        totalPlayUserCount @include(if: $withPlayUserCount)
        totalPlayTime(unit: HOURS)
      }
      contentPurchaseRanks {
        contentType
        content {
          id
          subject
        }
        payType
        salesAmount
        salesCount
        playCount
        playUserCount @include(if: $withPlayUserCount)
        playTime(unit: HOURS)
        rateOfOrderField
      }
    }
  }
}
    `;

/**
 * __usePurchaseRankGroupContentPurchaseStatsQuery__
 *
 * To run a query within a React component, call `usePurchaseRankGroupContentPurchaseStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseRankGroupContentPurchaseStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseRankGroupContentPurchaseStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      service: // value for 'service'
 *      contentType: // value for 'contentType'
 *      payType: // value for 'payType'
 *      field: // value for 'field'
 *      pageSize: // value for 'pageSize'
 *      period: // value for 'period'
 *      page: // value for 'page'
 *      withPlayUserCount: // value for 'withPlayUserCount'
 *   },
 * });
 */
export function usePurchaseRankGroupContentPurchaseStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseRankGroupContentPurchaseStatsQuery, PurchaseRankGroupContentPurchaseStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseRankGroupContentPurchaseStatsQuery, PurchaseRankGroupContentPurchaseStatsQueryVariables>(PurchaseRankGroupContentPurchaseStatsDocument, baseOptions);
      }
export function usePurchaseRankGroupContentPurchaseStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseRankGroupContentPurchaseStatsQuery, PurchaseRankGroupContentPurchaseStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseRankGroupContentPurchaseStatsQuery, PurchaseRankGroupContentPurchaseStatsQueryVariables>(PurchaseRankGroupContentPurchaseStatsDocument, baseOptions);
        }
export type PurchaseRankGroupContentPurchaseStatsQueryHookResult = ReturnType<typeof usePurchaseRankGroupContentPurchaseStatsQuery>;
export type PurchaseRankGroupContentPurchaseStatsLazyQueryHookResult = ReturnType<typeof usePurchaseRankGroupContentPurchaseStatsLazyQuery>;
export type PurchaseRankGroupContentPurchaseStatsQueryResult = ApolloReactCommon.QueryResult<PurchaseRankGroupContentPurchaseStatsQuery, PurchaseRankGroupContentPurchaseStatsQueryVariables>;
export const PurchaseReportContentPurchaseStatsDocument = gql`
    query PurchaseReportContentPurchaseStats($date: Date!) {
  totalSalesStats: contentPurchaseStats(filterBy: {service: TOTAL, contentType: TOTAL, period: MONTHLY, date: $date}) {
    ...AllReport_ContentPurchaseStats
  }
  weportSalesStats: contentPurchaseStats(filterBy: {service: WEPORT, contentType: TOTAL, period: MONTHLY, date: $date}) {
    ...ThisYearServiceReport_ContentPurchaseStats
  }
  ncsSalesStats: contentPurchaseStats(filterBy: {service: NCS, contentType: TOTAL, period: MONTHLY, date: $date}) {
    ...ThisYearServiceReport_ContentPurchaseStats
  }
  sevenGongSalesStats: contentPurchaseStats(filterBy: {service: SEVEN_GONG, contentType: TOTAL, period: MONTHLY, date: $date}) {
    ...ThisYearServiceReport_ContentPurchaseStats
  }
  enginicSalesStats: contentPurchaseStats(filterBy: {service: ENGINIC, contentType: TOTAL, period: MONTHLY, date: $date}) {
    ...ThisYearServiceReport_ContentPurchaseStats
  }
  talkerbeSalesStats: contentPurchaseStats(filterBy: {service: TALKERBE, contentType: TOTAL, period: MONTHLY, date: $date}) {
    ...ThisYearServiceReport_ContentPurchaseStats
  }
  offlineSalesStats: contentPurchaseStats(filterBy: {service: OFFLINE, contentType: TOTAL, period: MONTHLY, date: $date}) {
    ...ThisYearServiceReport_ContentPurchaseStats
  }
  companySalesStats: contentPurchaseStats(filterBy: {service: COMPANY, contentType: TOTAL, period: MONTHLY, date: $date}) {
    ...ThisYearServiceReport_ContentPurchaseStats
  }
}
    ${AllReport_ContentPurchaseStatsFragmentDoc}
${ThisYearServiceReport_ContentPurchaseStatsFragmentDoc}`;

/**
 * __usePurchaseReportContentPurchaseStatsQuery__
 *
 * To run a query within a React component, call `usePurchaseReportContentPurchaseStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseReportContentPurchaseStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseReportContentPurchaseStatsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function usePurchaseReportContentPurchaseStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseReportContentPurchaseStatsQuery, PurchaseReportContentPurchaseStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseReportContentPurchaseStatsQuery, PurchaseReportContentPurchaseStatsQueryVariables>(PurchaseReportContentPurchaseStatsDocument, baseOptions);
      }
export function usePurchaseReportContentPurchaseStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseReportContentPurchaseStatsQuery, PurchaseReportContentPurchaseStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseReportContentPurchaseStatsQuery, PurchaseReportContentPurchaseStatsQueryVariables>(PurchaseReportContentPurchaseStatsDocument, baseOptions);
        }
export type PurchaseReportContentPurchaseStatsQueryHookResult = ReturnType<typeof usePurchaseReportContentPurchaseStatsQuery>;
export type PurchaseReportContentPurchaseStatsLazyQueryHookResult = ReturnType<typeof usePurchaseReportContentPurchaseStatsLazyQuery>;
export type PurchaseReportContentPurchaseStatsQueryResult = ApolloReactCommon.QueryResult<PurchaseReportContentPurchaseStatsQuery, PurchaseReportContentPurchaseStatsQueryVariables>;


export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;


export type StitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>,
  Int: ResolverTypeWrapper<Scalars['Int']>,
  String: ResolverTypeWrapper<Scalars['String']>,
  AdminBlogPostConnection: ResolverTypeWrapper<AdminBlogPostConnection>,
  AdminBlogPost: ResolverTypeWrapper<AdminBlogPost>,
  Date: ResolverTypeWrapper<Scalars['Date']>,
  ID: ResolverTypeWrapper<Scalars['ID']>,
  Alliance: ResolverTypeWrapper<Alliance>,
  User: ResolverTypeWrapper<User>,
  AuthUser: ResolverTypeWrapper<AuthUser>,
  AllianceUser: ResolverTypeWrapper<AllianceUser>,
  AllianceUserGrade: AllianceUserGrade,
  AllianceUserStatus: AllianceUserStatus,
  AllianceUserSchoolType: AllianceUserSchoolType,
  DuplicatedUser: ResolverTypeWrapper<DuplicatedUser>,
  PhoneNumber: ResolverTypeWrapper<Scalars['PhoneNumber']>,
  PermissionScope: PermissionScope,
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>,
  ExamToTake: ExamToTake,
  JoinRoute: JoinRoute,
  Residence: Residence,
  SubjectCurriculum: SubjectCurriculum,
  UserDevice: ResolverTypeWrapper<UserDevice>,
  PlayerType: PlayerType,
  UserPoint: ResolverTypeWrapper<UserPoint>,
  UserPointState: UserPointState,
  ServiceType: ServiceType,
  Order: ResolverTypeWrapper<Order>,
  ContentPurchase: ResolverTypeWrapper<Omit<ContentPurchase, 'contentPurchaseDetail'> & { contentPurchaseDetail?: Maybe<ResolversTypes['ContentPurchaseDetail']> }>,
  Content: ResolverTypeWrapper<Content>,
  ContentType: ContentType,
  File: ResolverTypeWrapper<File>,
  Visibility: Visibility,
  Teacher: ResolverTypeWrapper<Teacher>,
  ContentFilters: ContentFilters,
  ContentOrder: ContentOrder,
  ContentOrderField: ContentOrderField,
  OrderDirection: OrderDirection,
  Pagination: Pagination,
  ContentConnection: ResolverTypeWrapper<ContentConnection>,
  TeacherCommissions: ResolverTypeWrapper<TeacherCommissions>,
  ContentCategoryParent: ResolverTypeWrapper<ContentCategoryParent>,
  ContentCategory: ResolverTypeWrapper<ContentCategory>,
  ContentTag: ContentTag,
  ReviewFilters: ReviewFilters,
  IntegerRange: IntegerRange,
  DateRange: DateRange,
  ReviewOrder: ReviewOrder,
  ReviewOrderField: ReviewOrderField,
  ReviewConnection: ResolverTypeWrapper<ReviewConnection>,
  Review: ResolverTypeWrapper<Review>,
  Float: ResolverTypeWrapper<Scalars['Float']>,
  SaleStatus: SaleStatus,
  ContentPurchaseFilters: ContentPurchaseFilters,
  ContentPurchaseOrder: ContentPurchaseOrder,
  ContentPurchaseOrderField: ContentPurchaseOrderField,
  ContentPurchaseConnection: ResolverTypeWrapper<ContentPurchaseConnection>,
  VideoProduct: ResolverTypeWrapper<VideoProduct>,
  Package: ResolverTypeWrapper<Package>,
  Book: ResolverTypeWrapper<Book>,
  Exam: ResolverTypeWrapper<Exam>,
  ExamEvent: ResolverTypeWrapper<ExamEvent>,
  ExamSection: ResolverTypeWrapper<ExamSection>,
  PrevScoreForPass: ResolverTypeWrapper<PrevScoreForPass>,
  TimeUnit: TimeUnit,
  ExamQuestionOrder: ExamQuestionOrder,
  ExamQuestionOrderField: ExamQuestionOrderField,
  ExamQuestionConnection: ResolverTypeWrapper<ExamQuestionConnection>,
  ExamQuestion: ResolverTypeWrapper<ExamQuestion>,
  ExamQuestionType: ResolverTypeWrapper<ExamQuestionType>,
  ExamSegment: ExamSegment,
  Question: ResolverTypeWrapper<Question>,
  ExamAttemptConnection: ResolverTypeWrapper<ExamAttemptConnection>,
  ExamAttempt: ResolverTypeWrapper<ExamAttempt>,
  Company: ResolverTypeWrapper<Company>,
  TempAccount: ResolverTypeWrapper<TempAccount>,
  ExamAttemptSectionResult: ResolverTypeWrapper<ExamAttemptSectionResult>,
  ExamAttemptAnswer: ResolverTypeWrapper<ExamAttemptAnswer>,
  ExamAttemptQuestionTypeResult: ResolverTypeWrapper<ExamAttemptQuestionTypeResult>,
  ExamType: ExamType,
  VideoLessonProduct: ResolverTypeWrapper<VideoLessonProduct>,
  VideoLessonProductFilters: VideoLessonProductFilters,
  VideoLessonProductOrder: VideoLessonProductOrder,
  VideoLessonProductOrderField: VideoLessonProductOrderField,
  VideoLessonProductConnection: ResolverTypeWrapper<VideoLessonProductConnection>,
  FreepassProduct: ResolverTypeWrapper<FreepassProduct>,
  PaybackType: PaybackType,
  PaybackSet: ResolverTypeWrapper<PaybackSet>,
  Offline: ResolverTypeWrapper<Offline>,
  OfflineRepurchaseDiscount: ResolverTypeWrapper<OfflineRepurchaseDiscount>,
  PercentageUnit: PercentageUnit,
  DiscountType: DiscountType,
  Weekday: Weekday,
  Time: ResolverTypeWrapper<Scalars['Time']>,
  NotificationSMSSet: ResolverTypeWrapper<NotificationSmsSet>,
  NotificationSMS: ResolverTypeWrapper<NotificationSms>,
  OfflineTag: OfflineTag,
  DocumentProduct: ResolverTypeWrapper<DocumentProduct>,
  CommunityBoard: ResolverTypeWrapper<CommunityBoard>,
  ContentPurchaseDetail: ResolversTypes['Video'] | ResolversTypes['Resume'] | ResolversTypes['Document'] | ResolversTypes['ExamAttempt'] | ResolversTypes['Freepass'],
  Video: ResolverTypeWrapper<Video>,
  VideoLessonPlayLog: ResolverTypeWrapper<VideoLessonPlayLog>,
  VideoLesson: ResolverTypeWrapper<VideoLesson>,
  Resume: ResolverTypeWrapper<Resume>,
  School: ResolverTypeWrapper<School>,
  Major: ResolverTypeWrapper<Major>,
  ResumeCorrectionStep: ResumeCorrectionStep,
  ResumeProduct: ResolverTypeWrapper<ResumeProduct>,
  ResumeCorrectionLimitType: ResumeCorrectionLimitType,
  ResumeProductOption: ResolverTypeWrapper<ResumeProductOption>,
  ResumeProductOptionCategory: ResumeProductOptionCategory,
  ResumeQnA: ResolverTypeWrapper<ResumeQnA>,
  Document: ResolverTypeWrapper<Document>,
  PrintLog: ResolverTypeWrapper<PrintLog>,
  Freepass: ResolverTypeWrapper<Freepass>,
  Refund: ResolverTypeWrapper<Refund>,
  Payment: ResolverTypeWrapper<Payment>,
  PaymentMethod: PaymentMethod,
  PaymentState: PaymentState,
  OrderStatus: OrderStatus,
  OrderType: OrderType,
  CouponNumber: ResolverTypeWrapper<CouponNumber>,
  ConnectedContent: ResolverTypeWrapper<ConnectedContent>,
  ExamSubject: ResolverTypeWrapper<ExamSubject>,
  WishExamCategory: WishExamCategory,
  AllianceFilters: AllianceFilters,
  AllianceConnection: ResolverTypeWrapper<AllianceConnection>,
  AllianceUserFilters: AllianceUserFilters,
  AllianceUserConnection: ResolverTypeWrapper<AllianceUserConnection>,
  Banner: ResolverTypeWrapper<Banner>,
  BannerStatus: BannerStatus,
  BannerGroup: ResolverTypeWrapper<BannerGroup>,
  BannerFilters: BannerFilters,
  BannerOrder: BannerOrder,
  BannerOrderField: BannerOrderField,
  BannerConnection: ResolverTypeWrapper<BannerConnection>,
  BannerGroupFilters: BannerGroupFilters,
  BannerGroupOrder: BannerGroupOrder,
  BannerGroupOrderField: BannerGroupOrderField,
  BannerGroupConnection: ResolverTypeWrapper<BannerGroupConnection>,
  BookFilters: BookFilters,
  BookOrder: BookOrder,
  BookOrderField: BookOrderField,
  BookConnection: ResolverTypeWrapper<BookConnection>,
  Campaign: ResolverTypeWrapper<Campaign>,
  CampaignType: CampaignType,
  CampaignTarget: CampaignTarget,
  CampaignUser: ResolverTypeWrapper<CampaignUser>,
  CampaignBenefit: ResolverTypeWrapper<CampaignBenefit>,
  BenefitType: BenefitType,
  CampaignData: ResolverTypeWrapper<CampaignData>,
  CampaignCategory: CampaignCategory,
  CampaignFilters: CampaignFilters,
  CampaignDataFilter: CampaignDataFilter,
  CampaignOrder: CampaignOrder,
  CampaignOrderField: CampaignOrderField,
  CampaignConnection: ResolverTypeWrapper<CampaignConnection>,
  CampaignConnectionForDataExtract: ResolverTypeWrapper<CampaignConnectionForDataExtract>,
  CompanyFilters: CompanyFilters,
  CompanyOrder: CompanyOrder,
  CompanyOrderField: CompanyOrderField,
  CompanyConnection: ResolverTypeWrapper<CompanyConnection>,
  MissionType: MissionType,
  ContentCuration: ResolverTypeWrapper<ContentCuration>,
  ContentCurationFilters: ContentCurationFilters,
  ContentCurationOrder: ContentCurationOrder,
  ContentCurationOrderField: ContentCurationOrderField,
  ContentCurationConnection: ResolverTypeWrapper<ContentCurationConnection>,
  Coupon: ResolverTypeWrapper<Omit<Coupon, 'couponData'> & { couponData?: Maybe<Array<ResolversTypes['CouponData']>> }>,
  CouponType: CouponType,
  CouponCategory: CouponCategory,
  CouponData: ResolversTypes['CouponDiscount'] | ResolversTypes['CouponCreateOrder'] | ResolversTypes['CouponRegisterCoupon'],
  CouponDiscount: ResolverTypeWrapper<CouponDiscount>,
  CouponCreateOrder: ResolverTypeWrapper<CouponCreateOrder>,
  CouponRegisterCoupon: ResolverTypeWrapper<CouponRegisterCoupon>,
  CouponFilters: CouponFilters,
  CouponOrder: CouponOrder,
  CouponOrderField: CouponOrderField,
  CouponConnection: ResolverTypeWrapper<CouponConnection>,
  DocumentFilters: DocumentFilters,
  DocumentOrder: DocumentOrder,
  DocumentOrderField: DocumentOrderField,
  DocumentConnection: ResolverTypeWrapper<DocumentConnection>,
  DocumentProductFilters: DocumentProductFilters,
  DocumentProductOrder: DocumentProductOrder,
  DocumentProductOrderField: DocumentProductOrderField,
  DocumentProductConnection: ResolverTypeWrapper<DocumentProductConnection>,
  ContentPurchaseRankFilters: ContentPurchaseRankFilters,
  ContentPurchaseRankServiceType: ContentPurchaseRankServiceType,
  ContentPurchaseRankContentType: ContentPurchaseRankContentType,
  ContentPurchaseRankPayType: ContentPurchaseRankPayType,
  ContentPurchaseRankPeriod: ContentPurchaseRankPeriod,
  ContentPurchaseRankOrder: ContentPurchaseRankOrder,
  ContentPurchaseRankOrderField: ContentPurchaseRankOrderField,
  ContentPurchaseRankGroupConnection: ResolverTypeWrapper<ContentPurchaseRankGroupConnection>,
  ContentPurchaseRankGroup: ResolverTypeWrapper<ContentPurchaseRankGroup>,
  ContentPurchaseRank: ResolverTypeWrapper<ContentPurchaseRank>,
  ContentPurchaseRankSummary: ResolverTypeWrapper<ContentPurchaseRankSummary>,
  ContentPurchaseStatFilters: ContentPurchaseStatFilters,
  ContentPurchaseStatServiceFilter: ContentPurchaseStatServiceFilter,
  ContentPurchaseStatContentType: ContentPurchaseStatContentType,
  StatPeriod: StatPeriod,
  ContentPurchaseStatConnection: ResolverTypeWrapper<ContentPurchaseStatConnection>,
  ContentPurchaseStat: ResolverTypeWrapper<ContentPurchaseStat>,
  ContentPurchaseStatGrowth: ResolverTypeWrapper<ContentPurchaseStatGrowth>,
  ContentPurchaseStatGrowthSummary: ResolverTypeWrapper<ContentPurchaseStatGrowthSummary>,
  ContentPurchaseStatSummary: ResolverTypeWrapper<ContentPurchaseStatSummary>,
  ContentRefundStatConnection: ResolverTypeWrapper<ContentRefundStatConnection>,
  ContentRefundStat: ResolverTypeWrapper<ContentRefundStat>,
  ContentRefundDetail: ResolverTypeWrapper<ContentRefundDetail>,
  ContentRefundStatGrowth: ResolverTypeWrapper<ContentRefundStatGrowth>,
  ContentRefundStatGrowthSummary: ResolverTypeWrapper<ContentRefundStatGrowthSummary>,
  ContentRefundStatSummary: ResolverTypeWrapper<ContentRefundStatSummary>,
  ContentRefundDetailSummary: ResolverTypeWrapper<ContentRefundDetailSummary>,
  ExamFilters: ExamFilters,
  ExamOrder: ExamOrder,
  ExamOrderField: ExamOrderField,
  ExamConnection: ResolverTypeWrapper<ExamConnection>,
  ExamSectionFilters: ExamSectionFilters,
  ExamSectionOrder: ExamSectionOrder,
  ExamSectionOrderField: ExamSectionOrderField,
  ExamSectionConnection: ResolverTypeWrapper<ExamSectionConnection>,
  ExamQuestionFilters: ExamQuestionFilters,
  ExamQuestionTypeFilters: ExamQuestionTypeFilters,
  ExamQuestionTypeOrder: ExamQuestionTypeOrder,
  ExamQuestionTypeOrderField: ExamQuestionTypeOrderField,
  ExamQuestionTypeConnection: ResolverTypeWrapper<ExamQuestionTypeConnection>,
  ExamAttemptFilters: ExamAttemptFilters,
  ExamAttemptOrder: ExamAttemptOrder,
  ExamAttemptOrderField: ExamAttemptOrderField,
  FreepassProductFilters: FreepassProductFilters,
  FreepassProductOrder: FreepassProductOrder,
  FreepassProductOrderField: FreepassProductOrderField,
  FreepassProductConnection: ResolverTypeWrapper<FreepassProductConnection>,
  HtmlText: ResolverTypeWrapper<HtmlText>,
  HtmlTextFilters: HtmlTextFilters,
  HtmlTextOrder: HtmlTextOrder,
  HtmlTextOrderField: HtmlTextOrderField,
  HtmlTextConnection: ResolverTypeWrapper<HtmlTextConnection>,
  Invoice: ResolverTypeWrapper<Invoice>,
  FreepassInvoice: ResolverTypeWrapper<FreepassInvoice>,
  InvoiceSaleByContent: ResolverTypeWrapper<InvoiceSaleByContent>,
  InvoiceRefundByContent: ResolverTypeWrapper<InvoiceRefundByContent>,
  ConfirmState: ConfirmState,
  InvoiceFilters: InvoiceFilters,
  OnlineOrOffline: OnlineOrOffline,
  InvoiceOrder: InvoiceOrder,
  InvoiceOrderField: InvoiceOrderField,
  InvoiceConnection: ResolverTypeWrapper<InvoiceConnection>,
  MajorFilters: MajorFilters,
  MajorOrder: MajorOrder,
  MajorOrderField: MajorOrderField,
  MajorConnection: ResolverTypeWrapper<MajorConnection>,
  Menu: ResolverTypeWrapper<Menu>,
  MenuItem: ResolverTypeWrapper<MenuItem>,
  MenuFilters: MenuFilters,
  MenuOrder: MenuOrder,
  MenuOrderField: MenuOrderField,
  MenuConnection: ResolverTypeWrapper<MenuConnection>,
  OfflineFilters: OfflineFilters,
  OfflineOrder: OfflineOrder,
  OfflineOrderField: OfflineOrderField,
  OfflineConnection: ResolverTypeWrapper<OfflineConnection>,
  offlineSendDailyMessagePayload: ResolverTypeWrapper<OfflineSendDailyMessagePayload>,
  InfobankSMSSendResult: ResolverTypeWrapper<InfobankSmsSendResult>,
  OrderFilters: OrderFilters,
  OrderConnection: ResolverTypeWrapper<OrderConnection>,
  PackageFilters: PackageFilters,
  PackageOrder: PackageOrder,
  PackageOrderField: PackageOrderField,
  PackageConnection: ResolverTypeWrapper<PackageConnection>,
  PaymentFilters: PaymentFilters,
  PaymentConnection: ResolverTypeWrapper<PaymentConnection>,
  Permission: ResolverTypeWrapper<Permission>,
  Team: ResolverTypeWrapper<Team>,
  MembershipConnection: ResolverTypeWrapper<MembershipConnection>,
  Membership: ResolverTypeWrapper<Membership>,
  MembershipRole: MembershipRole,
  PermissionConnection: ResolverTypeWrapper<PermissionConnection>,
  PermissionFilters: PermissionFilters,
  PermissionOrder: PermissionOrder,
  PermissionOrderField: PermissionOrderField,
  PostCuration: ResolverTypeWrapper<PostCuration>,
  PostCurationConfig: ResolverTypeWrapper<PostCurationConfig>,
  OrderBy: OrderBy,
  Post: ResolverTypeWrapper<Post>,
  Board: ResolverTypeWrapper<Board>,
  PostCurationFilters: PostCurationFilters,
  PostCurationOrder: PostCurationOrder,
  PostCurationOrderField: PostCurationOrderField,
  PostCurationConnection: ResolverTypeWrapper<PostCurationConnection>,
  RefundFilters: RefundFilters,
  RefundConnection: ResolverTypeWrapper<RefundConnection>,
  ResumeProductFilters: ResumeProductFilters,
  ResumeProductOrder: ResumeProductOrder,
  ResumeProductOrderField: ResumeProductOrderField,
  ResumeProductConnection: ResolverTypeWrapper<ResumeProductConnection>,
  ResumeFilters: ResumeFilters,
  ResumeOrder: ResumeOrder,
  ResumeOrderField: ResumeOrderField,
  ResumeConnection: ResolverTypeWrapper<ResumeConnection>,
  Schedule: ResolverTypeWrapper<Schedule>,
  ScheduleList: ScheduleList,
  ScheduleEventFilters: ScheduleEventFilters,
  ScheduleEventOrder: ScheduleEventOrder,
  ScheduleEventOrderField: ScheduleEventOrderField,
  ScheduleEventOptions: ScheduleEventOptions,
  ScheduleEventConnection: ResolverTypeWrapper<ScheduleEventConnection>,
  ScheduleEvent: ResolverTypeWrapper<ScheduleEvent>,
  ScheduleFilters: ScheduleFilters,
  ScheduleOrder: ScheduleOrder,
  ScheduleOrderField: ScheduleOrderField,
  ScheduleConnection: ResolverTypeWrapper<ScheduleConnection>,
  SchoolFilters: SchoolFilters,
  SchoolOrder: SchoolOrder,
  SchoolOrderField: SchoolOrderField,
  SchoolConnection: ResolverTypeWrapper<SchoolConnection>,
  UserSchoolFilters: UserSchoolFilters,
  UserSchoolOrder: UserSchoolOrder,
  UserSchoolOrderField: UserSchoolOrderField,
  UserSchoolConnection: ResolverTypeWrapper<UserSchoolConnection>,
  UserSchool: ResolverTypeWrapper<UserSchool>,
  UserSchoolMajorFilters: UserSchoolMajorFilters,
  UserSchoolMajorOrder: UserSchoolMajorOrder,
  UserSchoolMajorOrderField: UserSchoolMajorOrderField,
  UserSchoolMajorConnection: ResolverTypeWrapper<UserSchoolMajorConnection>,
  UserSchoolMajor: ResolverTypeWrapper<UserSchoolMajor>,
  UserMajor: ResolverTypeWrapper<UserMajor>,
  SmsReject: ResolverTypeWrapper<SmsReject>,
  Study: ResolverTypeWrapper<Study>,
  StudyBrazeEventProperties: ResolverTypeWrapper<StudyBrazeEventProperties>,
  StudyEventFilters: StudyEventFilters,
  StudyEventOrder: StudyEventOrder,
  StudyEventOrderField: StudyEventOrderField,
  StudyEventConnection: ResolverTypeWrapper<StudyEventConnection>,
  StudyEvent: ResolverTypeWrapper<StudyEvent>,
  StudyBenefit: ResolverTypeWrapper<StudyBenefit>,
  Benefit: ResolverTypeWrapper<Benefit>,
  UserStudyEventFilters: UserStudyEventFilters,
  UserStudyEventState: UserStudyEventState,
  UserStudyEventOrder: UserStudyEventOrder,
  UserStudyEventOrderField: UserStudyEventOrderField,
  UserStudyEventConnection: ResolverTypeWrapper<UserStudyEventConnection>,
  UserStudyEvent: ResolverTypeWrapper<UserStudyEvent>,
  UserStudy: ResolverTypeWrapper<UserStudy>,
  UserStudyState: UserStudyState,
  DailyStudyTime: ResolverTypeWrapper<DailyStudyTime>,
  UserStudyMissionsAndContents: ResolverTypeWrapper<UserStudyMissionsAndContents>,
  UserStudyMission: ResolverTypeWrapper<Omit<UserStudyMission, 'userStudyMissionDetails'> & { userStudyMissionDetails?: Maybe<Array<Maybe<ResolversTypes['UserStudyMissionDetail']>>> }>,
  StudyMission: ResolverTypeWrapper<Omit<StudyMission, 'missionDetails'> & { missionDetails?: Maybe<ResolversTypes['MissionDetails']> }>,
  PassingCriteria: PassingCriteria,
  MissionDetails: ResolversTypes['AssignmentFileMission'] | ResolversTypes['AssignmentLinkMission'] | ResolversTypes['LectureMission'] | ResolversTypes['ExamMission'] | ResolversTypes['ReviewMission'],
  AssignmentFileMission: ResolverTypeWrapper<AssignmentFileMission>,
  AssignmentLinkMission: ResolverTypeWrapper<AssignmentLinkMission>,
  LectureMission: ResolverTypeWrapper<LectureMission>,
  ExamMission: ResolverTypeWrapper<ExamMission>,
  ReviewMission: ResolverTypeWrapper<ReviewMission>,
  UserStudyMissionState: UserStudyMissionState,
  UserStudyMissionDetail: ResolversTypes['UserStudyMissionAssignmentFile'] | ResolversTypes['UserStudyMissionAssignmentLink'] | ResolversTypes['UserStudyMissionLecture'] | ResolversTypes['UserStudyMissionExam'] | ResolversTypes['UserStudyMissionReview'],
  UserStudyMissionAssignmentFile: ResolverTypeWrapper<UserStudyMissionAssignmentFile>,
  UserStudyMissionAssignmentLink: ResolverTypeWrapper<UserStudyMissionAssignmentLink>,
  UserStudyMissionLecture: ResolverTypeWrapper<UserStudyMissionLecture>,
  UserStudyMissionExam: ResolverTypeWrapper<UserStudyMissionExam>,
  UserStudyMissionReview: ResolverTypeWrapper<UserStudyMissionReview>,
  StudyFilters: StudyFilters,
  StudyOrder: StudyOrder,
  StudyOrderField: StudyOrderField,
  StudyConnection: ResolverTypeWrapper<StudyConnection>,
  StudyMissionFilters: StudyMissionFilters,
  StudyMissionOrder: StudyMissionOrder,
  StudyMissionOrderField: StudyMissionOrderField,
  StudyMissionConnection: ResolverTypeWrapper<StudyMissionConnection>,
  UserStudyFilters: UserStudyFilters,
  UserStudyOrder: UserStudyOrder,
  UserStudyOrderField: UserStudyOrderField,
  UserStudyConnection: ResolverTypeWrapper<UserStudyConnection>,
  UserStudyMissionFilters: UserStudyMissionFilters,
  UserStudyMissionOrder: UserStudyMissionOrder,
  UserStudyMissionOrderField: UserStudyMissionOrderField,
  UserStudyMissionConnection: ResolverTypeWrapper<UserStudyMissionConnection>,
  IntegratedCommunityBoardPayload: ResolverTypeWrapper<IntegratedCommunityBoardPayload>,
  IntegratedCommunityBoard: ResolverTypeWrapper<IntegratedCommunityBoard>,
  TeacherFilters: TeacherFilters,
  TeacherOrder: TeacherOrder,
  TeacherOrderField: TeacherOrderField,
  TeacherConnection: ResolverTypeWrapper<TeacherConnection>,
  TeacherCuration: ResolverTypeWrapper<TeacherCuration>,
  TeacherCurationFilters: TeacherCurationFilters,
  TeacherCurationOrder: TeacherCurationOrder,
  TeacherCurationOrderField: TeacherCurationOrderField,
  TeacherCurationConnection: ResolverTypeWrapper<TeacherCurationConnection>,
  TeamFilters: TeamFilters,
  TeamOrder: TeamOrder,
  TeamOrderField: TeamOrderField,
  TeamConnection: ResolverTypeWrapper<TeamConnection>,
  MembershipFilters: MembershipFilters,
  MembershipOrder: MembershipOrder,
  MembershipOrderField: MembershipOrderField,
  UserFilters: UserFilters,
  UserOrder: UserOrder,
  UserOrderField: UserOrderField,
  UserConnection: ResolverTypeWrapper<UserConnection>,
  UserDeviceFilters: UserDeviceFilters,
  UserDeviceOrder: UserDeviceOrder,
  UserDeviceOrderField: UserDeviceOrderField,
  UserDeviceConnection: ResolverTypeWrapper<UserDeviceConnection>,
  UserPointFilters: UserPointFilters,
  UserPointOrder: UserPointOrder,
  UserPointOrderField: UserPointOrderField,
  UserPointConnection: ResolverTypeWrapper<UserPointConnection>,
  UserRegisterStatFilters: UserRegisterStatFilters,
  UserRegisterCategory: UserRegisterCategory,
  UserRegisterStatConnection: ResolverTypeWrapper<UserRegisterStatConnection>,
  UserRegisterStat: ResolverTypeWrapper<UserRegisterStat>,
  UserRegisterStatSummary: ResolverTypeWrapper<UserRegisterStatSummary>,
  UserRegisterStatGrowthRate: ResolverTypeWrapper<UserRegisterStatGrowthRate>,
  UserRegisterStatGrowthRateSummary: ResolverTypeWrapper<UserRegisterStatGrowthRateSummary>,
  UserSignupStatConnection: ResolverTypeWrapper<UserSignupStatConnection>,
  UserVisitStatConnection: ResolverTypeWrapper<UserVisitStatConnection>,
  UserAllianceStatFilters: UserAllianceStatFilters,
  UserAllianceStatConnection: ResolverTypeWrapper<UserAllianceStatConnection>,
  UserAllianceStat: ResolverTypeWrapper<UserAllianceStat>,
  VideoFilters: VideoFilters,
  VideoOrder: VideoOrder,
  VideoOrderField: VideoOrderField,
  VideoConnection: ResolverTypeWrapper<VideoConnection>,
  VideoProductFilters: VideoProductFilters,
  VideoProductOrder: VideoProductOrder,
  VideoProductOrderField: VideoProductOrderField,
  VideoProductConnection: ResolverTypeWrapper<VideoProductConnection>,
  RecoveryPasswordAuth: ResolverTypeWrapper<RecoveryPasswordAuth>,
  SupportTicket: ResolverTypeWrapper<SupportTicket>,
  SupportTicketCategory: ResolverTypeWrapper<SupportTicketCategory>,
  SupportTicketCategoryServiceMap: ResolverTypeWrapper<SupportTicketCategoryServiceMap>,
  SupportTicketProcessState: SupportTicketProcessState,
  SupportTicketType: SupportTicketType,
  SupportTicketFilters: SupportTicketFilters,
  SupportTicketOrder: SupportTicketOrder,
  SupportTicketOrderField: SupportTicketOrderField,
  SupportTicketConnection: ResolverTypeWrapper<SupportTicketConnection>,
  SupportTicketAnswerTemplate: ResolverTypeWrapper<SupportTicketAnswerTemplate>,
  SupportTicketAnswerTemplateFilters: SupportTicketAnswerTemplateFilters,
  SupportTicketAnswerTemplateOrder: SupportTicketAnswerTemplateOrder,
  SupportTicketAnswerTemplateOrderField: SupportTicketAnswerTemplateOrderField,
  SupportTicketAnswerTemplateConnection: ResolverTypeWrapper<SupportTicketAnswerTemplateConnection>,
  SupportTicketCategoryFilters: SupportTicketCategoryFilters,
  SupportTicketCategoryOrder: SupportTicketCategoryOrder,
  SupportTicketCategoryOrderField: SupportTicketCategoryOrderField,
  SupportTicketCategoryConnection: ResolverTypeWrapper<SupportTicketCategoryConnection>,
  SupportTicketCategoryServiceMapFilters: SupportTicketCategoryServiceMapFilters,
  SupportTicketCategoryServiceMapOrder: SupportTicketCategoryServiceMapOrder,
  SupportTicketCategoryServiceMapOrderField: SupportTicketCategoryServiceMapOrderField,
  SupportTicketCategoryServiceMapConnection: ResolverTypeWrapper<SupportTicketCategoryServiceMapConnection>,
  Keyword: ResolverTypeWrapper<Keyword>,
  ServicePurchaseStatFilters: ServicePurchaseStatFilters,
  ServicePurchaseStatConnection: ResolverTypeWrapper<ServicePurchaseStatConnection>,
  ServicePurchaseStat: ResolverTypeWrapper<ServicePurchaseStat>,
  ServicePurchaseStatDetail: ResolverTypeWrapper<ServicePurchaseStatDetail>,
  ServicePurchaseStatServiceType: ServicePurchaseStatServiceType,
  AllianceUserContentHistoryFilters: AllianceUserContentHistoryFilters,
  AllianceUserContentHistoryConnection: ResolverTypeWrapper<AllianceUserContentHistoryConnection>,
  AllianceUserContentHistory: ResolverTypeWrapper<AllianceUserContentHistory>,
  ExamSubjectsInput: ExamSubjectsInput,
  ExamSubjectConnection: ResolverTypeWrapper<ExamSubjectConnection>,
  Mutation: ResolverTypeWrapper<{}>,
  AllianceCreateInput: AllianceCreateInput,
  AllianceCreatePayload: ResolverTypeWrapper<AllianceCreatePayload>,
  AllianceUserCreateInput: AllianceUserCreateInput,
  AllianceUserCreatePayload: ResolverTypeWrapper<AllianceUserCreatePayload>,
  AllianceUserUpdateInput: AllianceUserUpdateInput,
  AllianceUserUpdatePayload: ResolverTypeWrapper<AllianceUserUpdatePayload>,
  BannerInput: BannerInput,
  Upload: ResolverTypeWrapper<Scalars['Upload']>,
  BannerCreatePayload: ResolverTypeWrapper<BannerCreatePayload>,
  BannerUpdatePayload: ResolverTypeWrapper<BannerUpdatePayload>,
  BannerGroupCreateInput: BannerGroupCreateInput,
  BannerGroupCreatePayload: ResolverTypeWrapper<BannerGroupCreatePayload>,
  BannerGroupUpdateInput: BannerGroupUpdateInput,
  BannerGroupUpdatePayload: ResolverTypeWrapper<BannerGroupUpdatePayload>,
  BannerGroupDeleteManyPayload: ResolverTypeWrapper<BannerGroupDeleteManyPayload>,
  BookCreateInput: BookCreateInput,
  ContentCreateInput: ContentCreateInput,
  TeacherCommissionInput: TeacherCommissionInput,
  BookCreatePayload: ResolverTypeWrapper<BookCreatePayload>,
  BookUpdateInput: BookUpdateInput,
  ContentUpdateInput: ContentUpdateInput,
  BookUpdatePayload: ResolverTypeWrapper<BookUpdatePayload>,
  BookDeletePayload: ResolverTypeWrapper<BookDeletePayload>,
  BookDeleteManyPayload: ResolverTypeWrapper<BookDeleteManyPayload>,
  CampaignInput: CampaignInput,
  CampaignDataInput: CampaignDataInput,
  CampaignCreateOrUpdatePayload: ResolverTypeWrapper<CampaignCreateOrUpdatePayload>,
  CampaignDeletePayload: ResolverTypeWrapper<CampaignDeletePayload>,
  CampaignBenefitInput: CampaignBenefitInput,
  CampaignBenefitMutationPayload: ResolverTypeWrapper<CampaignBenefitMutationPayload>,
  CampaignUserCreateInput: CampaignUserCreateInput,
  CampaignUserMutationPayload: ResolverTypeWrapper<CampaignUserMutationPayload>,
  ProvideCampaignBenefitPayload: ResolverTypeWrapper<ProvideCampaignBenefitPayload>,
  ProvideResultProps: ResolverTypeWrapper<ProvideResultProps>,
  CompanyInput: CompanyInput,
  CompanyCreatePayload: ResolverTypeWrapper<CompanyCreatePayload>,
  CompanyUpdatePayload: ResolverTypeWrapper<CompanyUpdatePayload>,
  CompanyDeletePayload: ResolverTypeWrapper<CompanyDeletePayload>,
  CompanyDeleteManyPayload: ResolverTypeWrapper<CompanyDeleteManyPayload>,
  ContentCreatePayload: ResolverTypeWrapper<ContentCreatePayload>,
  ContentUpdatePayload: ResolverTypeWrapper<ContentUpdatePayload>,
  ContentDeletePayload: ResolverTypeWrapper<ContentDeletePayload>,
  ContentDeleteManyPayload: ResolverTypeWrapper<ContentDeleteManyPayload>,
  ContentCurationInput: ContentCurationInput,
  ContentCurationCreatePayload: ResolverTypeWrapper<ContentCurationCreatePayload>,
  ContentCurationUpdatePayload: ResolverTypeWrapper<ContentCurationUpdatePayload>,
  ContentCurationDeletePayload: ResolverTypeWrapper<ContentCurationDeletePayload>,
  ContentCurationDeleteManyPayload: ResolverTypeWrapper<ContentCurationDeleteManyPayload>,
  CouponInput: CouponInput,
  CouponUpdatePayload: ResolverTypeWrapper<CouponUpdatePayload>,
  CouponDeletePayload: ResolverTypeWrapper<CouponDeletePayload>,
  ConnectedContentDeletePayload: ResolverTypeWrapper<ConnectedContentDeletePayload>,
  CreateConnectedContentPayload: ResolverTypeWrapper<CreateConnectedContentPayload>,
  CloneExamCreatePayload: ResolverTypeWrapper<CloneExamCreatePayload>,
  ProvideFreeContentPayload: ResolverTypeWrapper<ProvideFreeContentPayload>,
  provideFreeContentForUsersPayload: ResolverTypeWrapper<ProvideFreeContentForUsersPayload>,
  FailedUser: ResolverTypeWrapper<FailedUser>,
  ExamInput: ExamInput,
  ContentExamUpdateInput: ContentExamUpdateInput,
  ExamUpdatePayload: ResolverTypeWrapper<ExamUpdatePayload>,
  ExamSectionInput: ExamSectionInput,
  PrevScoreForPassInput: PrevScoreForPassInput,
  ExamSectionUpdatePayload: ResolverTypeWrapper<ExamSectionUpdatePayload>,
  ExamQuestionTypeInput: ExamQuestionTypeInput,
  ExamQuestionTypeUpdatePayload: ResolverTypeWrapper<ExamQuestionTypeUpdatePayload>,
  ExamQuestionTypeDeletePayload: ResolverTypeWrapper<ExamQuestionTypeDeletePayload>,
  ExamQuestionCreateInput: ExamQuestionCreateInput,
  QuestionCreateInput: QuestionCreateInput,
  ExamQuestionCreatePayload: ResolverTypeWrapper<ExamQuestionCreatePayload>,
  ExamQuestionUpdateInput: ExamQuestionUpdateInput,
  QuestionUpdateInput: QuestionUpdateInput,
  ExamQuestionUpdatePayload: ResolverTypeWrapper<ExamQuestionUpdatePayload>,
  ExamQuestionDeletePayload: ResolverTypeWrapper<ExamQuestionDeletePayload>,
  ExamQuestionDeleteManyPayload: ResolverTypeWrapper<ExamQuestionDeleteManyPayload>,
  ExamAttemptGetOrCreatePayload: ResolverTypeWrapper<ExamAttemptGetOrCreatePayload>,
  ExamAttemptInput: ExamAttemptInput,
  ExamAttemptUpdatePayload: ResolverTypeWrapper<ExamAttemptUpdatePayload>,
  ExamAttemptAnswerInput: ExamAttemptAnswerInput,
  ExamAttemptSubmitAnswerManyPayload: ResolverTypeWrapper<ExamAttemptSubmitAnswerManyPayload>,
  BucketMode: BucketMode,
  FileUploadPayload: ResolverTypeWrapper<FileUploadPayload>,
  HtmlTextInput: HtmlTextInput,
  HtmlTextCreatePayload: ResolverTypeWrapper<HtmlTextCreatePayload>,
  HtmlTextUpdatePayload: ResolverTypeWrapper<HtmlTextUpdatePayload>,
  HtmlTextDeletePayload: ResolverTypeWrapper<HtmlTextDeletePayload>,
  HtmlTextDeleteManyPayload: ResolverTypeWrapper<HtmlTextDeleteManyPayload>,
  MenuCreateInput: MenuCreateInput,
  MenuItemInput: MenuItemInput,
  MenuCreatePayload: ResolverTypeWrapper<MenuCreatePayload>,
  MenuUpdateInput: MenuUpdateInput,
  MenuUpdatePayload: ResolverTypeWrapper<MenuUpdatePayload>,
  MenuDeletePayload: ResolverTypeWrapper<MenuDeletePayload>,
  MenuDeleteManyPayload: ResolverTypeWrapper<MenuDeleteManyPayload>,
  OfflineCreateInput: OfflineCreateInput,
  NotificationSMSCreateInput: NotificationSmsCreateInput,
  OfflineCreatePayload: ResolverTypeWrapper<OfflineCreatePayload>,
  OfflineUpdateInput: OfflineUpdateInput,
  OfflineUpdatePayload: ResolverTypeWrapper<OfflineUpdatePayload>,
  OfflineRepurchaseDiscountInput: OfflineRepurchaseDiscountInput,
  OfflineRepurchaseDiscountUpdatePayload: ResolverTypeWrapper<OfflineRepurchaseDiscountUpdatePayload>,
  OfflineChildrenContentsUpdatePayload: ResolverTypeWrapper<OfflineChildrenContentsUpdatePayload>,
  OfflineStudentClassChangePayload: ResolverTypeWrapper<OfflineStudentClassChangePayload>,
  PermissionGrantPayload: ResolverTypeWrapper<PermissionGrantPayload>,
  PermissionRevokePayload: ResolverTypeWrapper<PermissionRevokePayload>,
  PostCurationInput: PostCurationInput,
  PostCurationConfigInput: PostCurationConfigInput,
  PostCurationUpdatePayload: ResolverTypeWrapper<PostCurationUpdatePayload>,
  RefundCreateInput: RefundCreateInput,
  RefundCreatePayload: ResolverTypeWrapper<RefundCreatePayload>,
  ResumeProductInput: ResumeProductInput,
  ResumeProductCreatePayload: ResolverTypeWrapper<ResumeProductCreatePayload>,
  ResumeProductUpdatePayload: ResolverTypeWrapper<ResumeProductUpdatePayload>,
  ResumeProductDeletePayload: ResolverTypeWrapper<ResumeProductDeletePayload>,
  ResumeProductDeleteManyPayload: ResolverTypeWrapper<ResumeProductDeleteManyPayload>,
  ResumeCreateInput: ResumeCreateInput,
  UserResumeAnswer: UserResumeAnswer,
  ResumeCreatePayload: ResolverTypeWrapper<ResumeCreatePayload>,
  ResumeUpdateInput: ResumeUpdateInput,
  ResumeUpdatePayload: ResolverTypeWrapper<ResumeUpdatePayload>,
  ReviewCreateInput: ReviewCreateInput,
  ReviewCreatePayload: ResolverTypeWrapper<ReviewCreatePayload>,
  CommunityPost: ResolverTypeWrapper<CommunityPost>,
  CommunityPostRating: ResolverTypeWrapper<CommunityPostRating>,
  ReviewInput: ReviewInput,
  ReviewUpdatePayload: ResolverTypeWrapper<ReviewUpdatePayload>,
  ReviewDeletePayload: ResolverTypeWrapper<ReviewDeletePayload>,
  ReviewDeleteManyPayload: ResolverTypeWrapper<ReviewDeleteManyPayload>,
  ScheduleInput: ScheduleInput,
  ScheduleCreatePayload: ResolverTypeWrapper<ScheduleCreatePayload>,
  ScheduleUpdatePayload: ResolverTypeWrapper<ScheduleUpdatePayload>,
  ScheduleDeletePayload: ResolverTypeWrapper<ScheduleDeletePayload>,
  ScheduleDeleteManyPayload: ResolverTypeWrapper<ScheduleDeleteManyPayload>,
  ScheduleEventCreateInput: ScheduleEventCreateInput,
  ScheduleEventCreatePayload: ResolverTypeWrapper<ScheduleEventCreatePayload>,
  ScheduleEventUpdateInput: ScheduleEventUpdateInput,
  ScheduleEventUpdatePayload: ResolverTypeWrapper<ScheduleEventUpdatePayload>,
  ScheduleEventDeletePayload: ResolverTypeWrapper<ScheduleEventDeletePayload>,
  ScheduleEventDeleteManyPayload: ResolverTypeWrapper<ScheduleEventDeleteManyPayload>,
  ScheduleUpdateLambdaHandlerPayload: ResolverTypeWrapper<ScheduleUpdateLambdaHandlerPayload>,
  SmsRejectInput: SmsRejectInput,
  StudyCreateInput: StudyCreateInput,
  StudyBrazeEventPropertiesInput: StudyBrazeEventPropertiesInput,
  StudyCreatePayload: ResolverTypeWrapper<StudyCreatePayload>,
  StudyEventInput: StudyEventInput,
  StudyEventCreatePayload: ResolverTypeWrapper<StudyEventCreatePayload>,
  StudyBenefitInput: StudyBenefitInput,
  StudyBenefitCreatePayload: ResolverTypeWrapper<StudyBenefitCreatePayload>,
  StudyMissionCreateInput: StudyMissionCreateInput,
  StudyMissionDetailCreateInput: StudyMissionDetailCreateInput,
  AssignmentFileMissionCreateInput: AssignmentFileMissionCreateInput,
  AssignmentLinkMissionCreateInput: AssignmentLinkMissionCreateInput,
  LectureMissionCreateInput: LectureMissionCreateInput,
  ExamMissionCreateInput: ExamMissionCreateInput,
  ReviewMissionCreateInput: ReviewMissionCreateInput,
  StudyMissionCreatePayload: ResolverTypeWrapper<StudyMissionCreatePayload>,
  StudyEventBulkCreatePayload: ResolverTypeWrapper<StudyEventBulkCreatePayload>,
  StudyUpdateInput: StudyUpdateInput,
  StudyUpdatePayload: ResolverTypeWrapper<StudyUpdatePayload>,
  StudyBenefitUpdatePayload: ResolverTypeWrapper<StudyBenefitUpdatePayload>,
  StudyMissionUpdateInput: StudyMissionUpdateInput,
  AssignmentFileMissionUpdateInput: AssignmentFileMissionUpdateInput,
  AssignmentLinkMissionUpdateInput: AssignmentLinkMissionUpdateInput,
  LectureMissionUpdateInput: LectureMissionUpdateInput,
  ExamMissionUpdateInput: ExamMissionUpdateInput,
  ReviewMissionUpdateInput: ReviewMissionUpdateInput,
  StudyMissionUpdatePayload: ResolverTypeWrapper<StudyMissionUpdatePayload>,
  StudyDeletePayload: ResolverTypeWrapper<StudyDeletePayload>,
  StudyDeleteManyPayload: ResolverTypeWrapper<StudyDeleteManyPayload>,
  StudyBenefitDeletePayload: ResolverTypeWrapper<StudyBenefitDeletePayload>,
  StudyMissionDeletePayload: ResolverTypeWrapper<StudyMissionDeletePayload>,
  StudyMissionDeleteManyPayload: ResolverTypeWrapper<StudyMissionDeleteManyPayload>,
  StudyEventBulkInput: StudyEventBulkInput,
  StudyRegisterInput: StudyRegisterInput,
  UserStudyRegisterPayload: ResolverTypeWrapper<UserStudyRegisterPayload>,
  UserStudyBenefitCreateInput: UserStudyBenefitCreateInput,
  UserStudyBenefitCreatePayload: ResolverTypeWrapper<UserStudyBenefitCreatePayload>,
  StudyUserContent: ResolverTypeWrapper<StudyUserContent>,
  UserStudyEventForcePassPayload: ResolverTypeWrapper<UserStudyEventForcePassPayload>,
  UserStudyEventPassPayload: ResolverTypeWrapper<UserStudyEventPassPayload>,
  UserStudyEventFailPayload: ResolverTypeWrapper<UserStudyEventFailPayload>,
  UserStudyMissionUpdateInput: UserStudyMissionUpdateInput,
  UserStudyMissionUpdatePayload: ResolverTypeWrapper<UserStudyMissionUpdatePayload>,
  UserStudyMissionsUpdatePayload: ResolverTypeWrapper<UserStudyMissionsUpdatePayload>,
  UserStudyMissionAssignmentLinkUploadInput: UserStudyMissionAssignmentLinkUploadInput,
  UserStudyMissionAssignmentLinkUploadPayload: ResolverTypeWrapper<UserStudyMissionAssignmentLinkUploadPayload>,
  UserStudyMissionAssignmentLinkUploadCommunityInput: UserStudyMissionAssignmentLinkUploadCommunityInput,
  PostCommentStatus: PostCommentStatus,
  PostStatus: PostStatus,
  UserStudyMissionReviewUploadInput: UserStudyMissionReviewUploadInput,
  UserStudyMissionReviewUploadPayload: ResolverTypeWrapper<UserStudyMissionReviewUploadPayload>,
  UserStudyMissionReviewUploadCommunityInput: UserStudyMissionReviewUploadCommunityInput,
  UserStudyMissionAssignmentFileUploadInput: UserStudyMissionAssignmentFileUploadInput,
  UserStudyMissionAssignmentFileUploadPayload: ResolverTypeWrapper<UserStudyMissionAssignmentFileUploadPayload>,
  AttachmentPrepareUploadInput: AttachmentPrepareUploadInput,
  AttachmentPrepareUploadPayload: ResolverTypeWrapper<AttachmentPrepareUploadPayload>,
  Attachment: ResolverTypeWrapper<Attachment>,
  integratedCommunityComment: ResolverTypeWrapper<IntegratedCommunityComment>,
  integratedCommunityPost: ResolverTypeWrapper<IntegratedCommunityPost>,
  AttachmentCompleteUploadInput: AttachmentCompleteUploadInput,
  AttachmentCompleteUploadPayload: ResolverTypeWrapper<AttachmentCompleteUploadPayload>,
  IntegratedCommunityPostUpdateInput: IntegratedCommunityPostUpdateInput,
  IntegratedCommunityPostUpdatePayload: ResolverTypeWrapper<IntegratedCommunityPostUpdatePayload>,
  TeacherInput: TeacherInput,
  TeacherCreatePayload: ResolverTypeWrapper<TeacherCreatePayload>,
  TeacherUpdatePayload: ResolverTypeWrapper<TeacherUpdatePayload>,
  TeacherDeletePayload: ResolverTypeWrapper<TeacherDeletePayload>,
  TeacherCurationInput: TeacherCurationInput,
  TeacherCurationCreatePayload: ResolverTypeWrapper<TeacherCurationCreatePayload>,
  TeacherCurationUpdatePayload: ResolverTypeWrapper<TeacherCurationUpdatePayload>,
  TeacherCurationDeletePayload: ResolverTypeWrapper<TeacherCurationDeletePayload>,
  TeacherCurationDeleteManyPayload: ResolverTypeWrapper<TeacherCurationDeleteManyPayload>,
  TeamInput: TeamInput,
  TeamCreatePayload: ResolverTypeWrapper<TeamCreatePayload>,
  TeamUpdatePayload: ResolverTypeWrapper<TeamUpdatePayload>,
  TeamDeletePayload: ResolverTypeWrapper<TeamDeletePayload>,
  TeamDeleteManyPayload: ResolverTypeWrapper<TeamDeleteManyPayload>,
  MembershipInput: MembershipInput,
  MembershipCreatePayload: ResolverTypeWrapper<MembershipCreatePayload>,
  MembershipUpdatePayload: ResolverTypeWrapper<MembershipUpdatePayload>,
  MembershipDeletePayload: ResolverTypeWrapper<MembershipDeletePayload>,
  MembershipDeleteManyPayload: ResolverTypeWrapper<MembershipDeleteManyPayload>,
  LoginPayload: ResolverTypeWrapper<LoginPayload>,
  LoginStatus: LoginStatus,
  TempAccountInput: TempAccountInput,
  TempAccountSignupPayload: ResolverTypeWrapper<TempAccountSignupPayload>,
  TempAccountLinkToUserPayload: ResolverTypeWrapper<TempAccountLinkToUserPayload>,
  phoneSendMessageForFindIDPayload: ResolverTypeWrapper<PhoneSendMessageForFindIdPayload>,
  phoneSendMessageForFindPWPayload: ResolverTypeWrapper<PhoneSendMessageForFindPwPayload>,
  EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']>,
  emailSendMessageForFindIDPayload: ResolverTypeWrapper<EmailSendMessageForFindIdPayload>,
  emailSendMessageForFindPWPayload: ResolverTypeWrapper<EmailSendMessageForFindPwPayload>,
  findIDByPhoneNumberPayload: ResolverTypeWrapper<FindIdByPhoneNumberPayload>,
  findPWByPhoneNumberPayload: ResolverTypeWrapper<FindPwByPhoneNumberPayload>,
  UpdatePWPayload: ResolverTypeWrapper<UpdatePwPayload>,
  UpdatePWByUsernamePayload: ResolverTypeWrapper<UpdatePwByUsernamePayload>,
  UserInfoInput: UserInfoInput,
  PhoneUpdateInput: PhoneUpdateInput,
  UserInfoUpdatePayload: ResolverTypeWrapper<UserInfoUpdatePayload>,
  AdminUserInfoUpdate: AdminUserInfoUpdate,
  AdminAllianceUserUpsert: AdminAllianceUserUpsert,
  ActiveUserPayload: ResolverTypeWrapper<ActiveUserPayload>,
  UserDeviceDeletePayload: ResolverTypeWrapper<UserDeviceDeletePayload>,
  UserPointCreateInput: UserPointCreateInput,
  UserPointCreatePayload: ResolverTypeWrapper<UserPointCreatePayload>,
  UserPointUpdateInput: UserPointUpdateInput,
  UserPointUpdatePayload: ResolverTypeWrapper<UserPointUpdatePayload>,
  UserPointDeletePayload: ResolverTypeWrapper<UserPointDeletePayload>,
  UserPointDeleteManyPayload: ResolverTypeWrapper<UserPointDeleteManyPayload>,
  VideoUpdatePayload: ResolverTypeWrapper<VideoUpdatePayload>,
  VideoProductInput: VideoProductInput,
  VideoProductCreatePayload: ResolverTypeWrapper<VideoProductCreatePayload>,
  VideoProductUpdatePayload: ResolverTypeWrapper<VideoProductUpdatePayload>,
  VideoProductDeletePayload: ResolverTypeWrapper<VideoProductDeletePayload>,
  VideoProductDeleteManyPayload: ResolverTypeWrapper<VideoProductDeleteManyPayload>,
  LeaveReason: LeaveReason,
  UserLeavePayload: ResolverTypeWrapper<UserLeavePayload>,
  UserLeaveLog: ResolverTypeWrapper<UserLeaveLog>,
  ActivateDormantUserPayload: ResolverTypeWrapper<ActivateDormantUserPayload>,
  SupportTicketInput: SupportTicketInput,
  SupportTicketCreatePayload: ResolverTypeWrapper<SupportTicketCreatePayload>,
  SupportTicketUpdatePayload: ResolverTypeWrapper<SupportTicketUpdatePayload>,
  SupportTicketAnswerTemplateInput: SupportTicketAnswerTemplateInput,
  SupportTicketAnswerTemplateCreatePayload: ResolverTypeWrapper<SupportTicketAnswerTemplateCreatePayload>,
  SupportTicketAnswerTemplateUpdatePayload: ResolverTypeWrapper<SupportTicketAnswerTemplateUpdatePayload>,
  SupportTicketCategoryInput: SupportTicketCategoryInput,
  SupportTicketCategoryCreatePayload: ResolverTypeWrapper<SupportTicketCategoryCreatePayload>,
  SupportTicketCategoryUpdatePayload: ResolverTypeWrapper<SupportTicketCategoryUpdatePayload>,
  SupportTicketAdminCreateInput: SupportTicketAdminCreateInput,
  SupportTicketAdminCreatePayload: ResolverTypeWrapper<SupportTicketAdminCreatePayload>,
  SupportTicketAdminUpdateInput: SupportTicketAdminUpdateInput,
  SupportTicketAdminUpdatePayload: ResolverTypeWrapper<SupportTicketAdminUpdatePayload>,
  AuthSMSInput: AuthSmsInput,
  NativeRegisterInput: NativeRegisterInput,
  NativeRegisterPayload: ResolverTypeWrapper<NativeRegisterPayload>,
  SNSRegisterInput: SnsRegisterInput,
  SNSCallBack: SnsCallBack,
  Provider: Provider,
  SNSRegisterPayload: ResolverTypeWrapper<SnsRegisterPayload>,
  AllianceRegisterInput: AllianceRegisterInput,
  AllianceRegisterPayload: ResolverTypeWrapper<AllianceRegisterPayload>,
  UserRegisterStatOrderField: UserRegisterStatOrderField,
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {},
  Int: Scalars['Int'],
  String: Scalars['String'],
  AdminBlogPostConnection: AdminBlogPostConnection,
  AdminBlogPost: AdminBlogPost,
  Date: Scalars['Date'],
  ID: Scalars['ID'],
  Alliance: Alliance,
  User: User,
  AuthUser: AuthUser,
  AllianceUser: AllianceUser,
  AllianceUserGrade: AllianceUserGrade,
  AllianceUserStatus: AllianceUserStatus,
  AllianceUserSchoolType: AllianceUserSchoolType,
  DuplicatedUser: DuplicatedUser,
  PhoneNumber: Scalars['PhoneNumber'],
  PermissionScope: PermissionScope,
  Boolean: Scalars['Boolean'],
  ExamToTake: ExamToTake,
  JoinRoute: JoinRoute,
  Residence: Residence,
  SubjectCurriculum: SubjectCurriculum,
  UserDevice: UserDevice,
  PlayerType: PlayerType,
  UserPoint: UserPoint,
  UserPointState: UserPointState,
  ServiceType: ServiceType,
  Order: Order,
  ContentPurchase: Omit<ContentPurchase, 'contentPurchaseDetail'> & { contentPurchaseDetail?: Maybe<ResolversParentTypes['ContentPurchaseDetail']> },
  Content: Content,
  ContentType: ContentType,
  File: File,
  Visibility: Visibility,
  Teacher: Teacher,
  ContentFilters: ContentFilters,
  ContentOrder: ContentOrder,
  ContentOrderField: ContentOrderField,
  OrderDirection: OrderDirection,
  Pagination: Pagination,
  ContentConnection: ContentConnection,
  TeacherCommissions: TeacherCommissions,
  ContentCategoryParent: ContentCategoryParent,
  ContentCategory: ContentCategory,
  ContentTag: ContentTag,
  ReviewFilters: ReviewFilters,
  IntegerRange: IntegerRange,
  DateRange: DateRange,
  ReviewOrder: ReviewOrder,
  ReviewOrderField: ReviewOrderField,
  ReviewConnection: ReviewConnection,
  Review: Review,
  Float: Scalars['Float'],
  SaleStatus: SaleStatus,
  ContentPurchaseFilters: ContentPurchaseFilters,
  ContentPurchaseOrder: ContentPurchaseOrder,
  ContentPurchaseOrderField: ContentPurchaseOrderField,
  ContentPurchaseConnection: ContentPurchaseConnection,
  VideoProduct: VideoProduct,
  Package: Package,
  Book: Book,
  Exam: Exam,
  ExamEvent: ExamEvent,
  ExamSection: ExamSection,
  PrevScoreForPass: PrevScoreForPass,
  TimeUnit: TimeUnit,
  ExamQuestionOrder: ExamQuestionOrder,
  ExamQuestionOrderField: ExamQuestionOrderField,
  ExamQuestionConnection: ExamQuestionConnection,
  ExamQuestion: ExamQuestion,
  ExamQuestionType: ExamQuestionType,
  ExamSegment: ExamSegment,
  Question: Question,
  ExamAttemptConnection: ExamAttemptConnection,
  ExamAttempt: ExamAttempt,
  Company: Company,
  TempAccount: TempAccount,
  ExamAttemptSectionResult: ExamAttemptSectionResult,
  ExamAttemptAnswer: ExamAttemptAnswer,
  ExamAttemptQuestionTypeResult: ExamAttemptQuestionTypeResult,
  ExamType: ExamType,
  VideoLessonProduct: VideoLessonProduct,
  VideoLessonProductFilters: VideoLessonProductFilters,
  VideoLessonProductOrder: VideoLessonProductOrder,
  VideoLessonProductOrderField: VideoLessonProductOrderField,
  VideoLessonProductConnection: VideoLessonProductConnection,
  FreepassProduct: FreepassProduct,
  PaybackType: PaybackType,
  PaybackSet: PaybackSet,
  Offline: Offline,
  OfflineRepurchaseDiscount: OfflineRepurchaseDiscount,
  PercentageUnit: PercentageUnit,
  DiscountType: DiscountType,
  Weekday: Weekday,
  Time: Scalars['Time'],
  NotificationSMSSet: NotificationSmsSet,
  NotificationSMS: NotificationSms,
  OfflineTag: OfflineTag,
  DocumentProduct: DocumentProduct,
  CommunityBoard: CommunityBoard,
  ContentPurchaseDetail: ResolversParentTypes['Video'] | ResolversParentTypes['Resume'] | ResolversParentTypes['Document'] | ResolversParentTypes['ExamAttempt'] | ResolversParentTypes['Freepass'],
  Video: Video,
  VideoLessonPlayLog: VideoLessonPlayLog,
  VideoLesson: VideoLesson,
  Resume: Resume,
  School: School,
  Major: Major,
  ResumeCorrectionStep: ResumeCorrectionStep,
  ResumeProduct: ResumeProduct,
  ResumeCorrectionLimitType: ResumeCorrectionLimitType,
  ResumeProductOption: ResumeProductOption,
  ResumeProductOptionCategory: ResumeProductOptionCategory,
  ResumeQnA: ResumeQnA,
  Document: Document,
  PrintLog: PrintLog,
  Freepass: Freepass,
  Refund: Refund,
  Payment: Payment,
  PaymentMethod: PaymentMethod,
  PaymentState: PaymentState,
  OrderStatus: OrderStatus,
  OrderType: OrderType,
  CouponNumber: CouponNumber,
  ConnectedContent: ConnectedContent,
  ExamSubject: ExamSubject,
  WishExamCategory: WishExamCategory,
  AllianceFilters: AllianceFilters,
  AllianceConnection: AllianceConnection,
  AllianceUserFilters: AllianceUserFilters,
  AllianceUserConnection: AllianceUserConnection,
  Banner: Banner,
  BannerStatus: BannerStatus,
  BannerGroup: BannerGroup,
  BannerFilters: BannerFilters,
  BannerOrder: BannerOrder,
  BannerOrderField: BannerOrderField,
  BannerConnection: BannerConnection,
  BannerGroupFilters: BannerGroupFilters,
  BannerGroupOrder: BannerGroupOrder,
  BannerGroupOrderField: BannerGroupOrderField,
  BannerGroupConnection: BannerGroupConnection,
  BookFilters: BookFilters,
  BookOrder: BookOrder,
  BookOrderField: BookOrderField,
  BookConnection: BookConnection,
  Campaign: Campaign,
  CampaignType: CampaignType,
  CampaignTarget: CampaignTarget,
  CampaignUser: CampaignUser,
  CampaignBenefit: CampaignBenefit,
  BenefitType: BenefitType,
  CampaignData: CampaignData,
  CampaignCategory: CampaignCategory,
  CampaignFilters: CampaignFilters,
  CampaignDataFilter: CampaignDataFilter,
  CampaignOrder: CampaignOrder,
  CampaignOrderField: CampaignOrderField,
  CampaignConnection: CampaignConnection,
  CampaignConnectionForDataExtract: CampaignConnectionForDataExtract,
  CompanyFilters: CompanyFilters,
  CompanyOrder: CompanyOrder,
  CompanyOrderField: CompanyOrderField,
  CompanyConnection: CompanyConnection,
  MissionType: MissionType,
  ContentCuration: ContentCuration,
  ContentCurationFilters: ContentCurationFilters,
  ContentCurationOrder: ContentCurationOrder,
  ContentCurationOrderField: ContentCurationOrderField,
  ContentCurationConnection: ContentCurationConnection,
  Coupon: Omit<Coupon, 'couponData'> & { couponData?: Maybe<Array<ResolversParentTypes['CouponData']>> },
  CouponType: CouponType,
  CouponCategory: CouponCategory,
  CouponData: ResolversParentTypes['CouponDiscount'] | ResolversParentTypes['CouponCreateOrder'] | ResolversParentTypes['CouponRegisterCoupon'],
  CouponDiscount: CouponDiscount,
  CouponCreateOrder: CouponCreateOrder,
  CouponRegisterCoupon: CouponRegisterCoupon,
  CouponFilters: CouponFilters,
  CouponOrder: CouponOrder,
  CouponOrderField: CouponOrderField,
  CouponConnection: CouponConnection,
  DocumentFilters: DocumentFilters,
  DocumentOrder: DocumentOrder,
  DocumentOrderField: DocumentOrderField,
  DocumentConnection: DocumentConnection,
  DocumentProductFilters: DocumentProductFilters,
  DocumentProductOrder: DocumentProductOrder,
  DocumentProductOrderField: DocumentProductOrderField,
  DocumentProductConnection: DocumentProductConnection,
  ContentPurchaseRankFilters: ContentPurchaseRankFilters,
  ContentPurchaseRankServiceType: ContentPurchaseRankServiceType,
  ContentPurchaseRankContentType: ContentPurchaseRankContentType,
  ContentPurchaseRankPayType: ContentPurchaseRankPayType,
  ContentPurchaseRankPeriod: ContentPurchaseRankPeriod,
  ContentPurchaseRankOrder: ContentPurchaseRankOrder,
  ContentPurchaseRankOrderField: ContentPurchaseRankOrderField,
  ContentPurchaseRankGroupConnection: ContentPurchaseRankGroupConnection,
  ContentPurchaseRankGroup: ContentPurchaseRankGroup,
  ContentPurchaseRank: ContentPurchaseRank,
  ContentPurchaseRankSummary: ContentPurchaseRankSummary,
  ContentPurchaseStatFilters: ContentPurchaseStatFilters,
  ContentPurchaseStatServiceFilter: ContentPurchaseStatServiceFilter,
  ContentPurchaseStatContentType: ContentPurchaseStatContentType,
  StatPeriod: StatPeriod,
  ContentPurchaseStatConnection: ContentPurchaseStatConnection,
  ContentPurchaseStat: ContentPurchaseStat,
  ContentPurchaseStatGrowth: ContentPurchaseStatGrowth,
  ContentPurchaseStatGrowthSummary: ContentPurchaseStatGrowthSummary,
  ContentPurchaseStatSummary: ContentPurchaseStatSummary,
  ContentRefundStatConnection: ContentRefundStatConnection,
  ContentRefundStat: ContentRefundStat,
  ContentRefundDetail: ContentRefundDetail,
  ContentRefundStatGrowth: ContentRefundStatGrowth,
  ContentRefundStatGrowthSummary: ContentRefundStatGrowthSummary,
  ContentRefundStatSummary: ContentRefundStatSummary,
  ContentRefundDetailSummary: ContentRefundDetailSummary,
  ExamFilters: ExamFilters,
  ExamOrder: ExamOrder,
  ExamOrderField: ExamOrderField,
  ExamConnection: ExamConnection,
  ExamSectionFilters: ExamSectionFilters,
  ExamSectionOrder: ExamSectionOrder,
  ExamSectionOrderField: ExamSectionOrderField,
  ExamSectionConnection: ExamSectionConnection,
  ExamQuestionFilters: ExamQuestionFilters,
  ExamQuestionTypeFilters: ExamQuestionTypeFilters,
  ExamQuestionTypeOrder: ExamQuestionTypeOrder,
  ExamQuestionTypeOrderField: ExamQuestionTypeOrderField,
  ExamQuestionTypeConnection: ExamQuestionTypeConnection,
  ExamAttemptFilters: ExamAttemptFilters,
  ExamAttemptOrder: ExamAttemptOrder,
  ExamAttemptOrderField: ExamAttemptOrderField,
  FreepassProductFilters: FreepassProductFilters,
  FreepassProductOrder: FreepassProductOrder,
  FreepassProductOrderField: FreepassProductOrderField,
  FreepassProductConnection: FreepassProductConnection,
  HtmlText: HtmlText,
  HtmlTextFilters: HtmlTextFilters,
  HtmlTextOrder: HtmlTextOrder,
  HtmlTextOrderField: HtmlTextOrderField,
  HtmlTextConnection: HtmlTextConnection,
  Invoice: Invoice,
  FreepassInvoice: FreepassInvoice,
  InvoiceSaleByContent: InvoiceSaleByContent,
  InvoiceRefundByContent: InvoiceRefundByContent,
  ConfirmState: ConfirmState,
  InvoiceFilters: InvoiceFilters,
  OnlineOrOffline: OnlineOrOffline,
  InvoiceOrder: InvoiceOrder,
  InvoiceOrderField: InvoiceOrderField,
  InvoiceConnection: InvoiceConnection,
  MajorFilters: MajorFilters,
  MajorOrder: MajorOrder,
  MajorOrderField: MajorOrderField,
  MajorConnection: MajorConnection,
  Menu: Menu,
  MenuItem: MenuItem,
  MenuFilters: MenuFilters,
  MenuOrder: MenuOrder,
  MenuOrderField: MenuOrderField,
  MenuConnection: MenuConnection,
  OfflineFilters: OfflineFilters,
  OfflineOrder: OfflineOrder,
  OfflineOrderField: OfflineOrderField,
  OfflineConnection: OfflineConnection,
  offlineSendDailyMessagePayload: OfflineSendDailyMessagePayload,
  InfobankSMSSendResult: InfobankSmsSendResult,
  OrderFilters: OrderFilters,
  OrderConnection: OrderConnection,
  PackageFilters: PackageFilters,
  PackageOrder: PackageOrder,
  PackageOrderField: PackageOrderField,
  PackageConnection: PackageConnection,
  PaymentFilters: PaymentFilters,
  PaymentConnection: PaymentConnection,
  Permission: Permission,
  Team: Team,
  MembershipConnection: MembershipConnection,
  Membership: Membership,
  MembershipRole: MembershipRole,
  PermissionConnection: PermissionConnection,
  PermissionFilters: PermissionFilters,
  PermissionOrder: PermissionOrder,
  PermissionOrderField: PermissionOrderField,
  PostCuration: PostCuration,
  PostCurationConfig: PostCurationConfig,
  OrderBy: OrderBy,
  Post: Post,
  Board: Board,
  PostCurationFilters: PostCurationFilters,
  PostCurationOrder: PostCurationOrder,
  PostCurationOrderField: PostCurationOrderField,
  PostCurationConnection: PostCurationConnection,
  RefundFilters: RefundFilters,
  RefundConnection: RefundConnection,
  ResumeProductFilters: ResumeProductFilters,
  ResumeProductOrder: ResumeProductOrder,
  ResumeProductOrderField: ResumeProductOrderField,
  ResumeProductConnection: ResumeProductConnection,
  ResumeFilters: ResumeFilters,
  ResumeOrder: ResumeOrder,
  ResumeOrderField: ResumeOrderField,
  ResumeConnection: ResumeConnection,
  Schedule: Schedule,
  ScheduleList: ScheduleList,
  ScheduleEventFilters: ScheduleEventFilters,
  ScheduleEventOrder: ScheduleEventOrder,
  ScheduleEventOrderField: ScheduleEventOrderField,
  ScheduleEventOptions: ScheduleEventOptions,
  ScheduleEventConnection: ScheduleEventConnection,
  ScheduleEvent: ScheduleEvent,
  ScheduleFilters: ScheduleFilters,
  ScheduleOrder: ScheduleOrder,
  ScheduleOrderField: ScheduleOrderField,
  ScheduleConnection: ScheduleConnection,
  SchoolFilters: SchoolFilters,
  SchoolOrder: SchoolOrder,
  SchoolOrderField: SchoolOrderField,
  SchoolConnection: SchoolConnection,
  UserSchoolFilters: UserSchoolFilters,
  UserSchoolOrder: UserSchoolOrder,
  UserSchoolOrderField: UserSchoolOrderField,
  UserSchoolConnection: UserSchoolConnection,
  UserSchool: UserSchool,
  UserSchoolMajorFilters: UserSchoolMajorFilters,
  UserSchoolMajorOrder: UserSchoolMajorOrder,
  UserSchoolMajorOrderField: UserSchoolMajorOrderField,
  UserSchoolMajorConnection: UserSchoolMajorConnection,
  UserSchoolMajor: UserSchoolMajor,
  UserMajor: UserMajor,
  SmsReject: SmsReject,
  Study: Study,
  StudyBrazeEventProperties: StudyBrazeEventProperties,
  StudyEventFilters: StudyEventFilters,
  StudyEventOrder: StudyEventOrder,
  StudyEventOrderField: StudyEventOrderField,
  StudyEventConnection: StudyEventConnection,
  StudyEvent: StudyEvent,
  StudyBenefit: StudyBenefit,
  Benefit: Benefit,
  UserStudyEventFilters: UserStudyEventFilters,
  UserStudyEventState: UserStudyEventState,
  UserStudyEventOrder: UserStudyEventOrder,
  UserStudyEventOrderField: UserStudyEventOrderField,
  UserStudyEventConnection: UserStudyEventConnection,
  UserStudyEvent: UserStudyEvent,
  UserStudy: UserStudy,
  UserStudyState: UserStudyState,
  DailyStudyTime: DailyStudyTime,
  UserStudyMissionsAndContents: UserStudyMissionsAndContents,
  UserStudyMission: Omit<UserStudyMission, 'userStudyMissionDetails'> & { userStudyMissionDetails?: Maybe<Array<Maybe<ResolversParentTypes['UserStudyMissionDetail']>>> },
  StudyMission: Omit<StudyMission, 'missionDetails'> & { missionDetails?: Maybe<ResolversParentTypes['MissionDetails']> },
  PassingCriteria: PassingCriteria,
  MissionDetails: ResolversParentTypes['AssignmentFileMission'] | ResolversParentTypes['AssignmentLinkMission'] | ResolversParentTypes['LectureMission'] | ResolversParentTypes['ExamMission'] | ResolversParentTypes['ReviewMission'],
  AssignmentFileMission: AssignmentFileMission,
  AssignmentLinkMission: AssignmentLinkMission,
  LectureMission: LectureMission,
  ExamMission: ExamMission,
  ReviewMission: ReviewMission,
  UserStudyMissionState: UserStudyMissionState,
  UserStudyMissionDetail: ResolversParentTypes['UserStudyMissionAssignmentFile'] | ResolversParentTypes['UserStudyMissionAssignmentLink'] | ResolversParentTypes['UserStudyMissionLecture'] | ResolversParentTypes['UserStudyMissionExam'] | ResolversParentTypes['UserStudyMissionReview'],
  UserStudyMissionAssignmentFile: UserStudyMissionAssignmentFile,
  UserStudyMissionAssignmentLink: UserStudyMissionAssignmentLink,
  UserStudyMissionLecture: UserStudyMissionLecture,
  UserStudyMissionExam: UserStudyMissionExam,
  UserStudyMissionReview: UserStudyMissionReview,
  StudyFilters: StudyFilters,
  StudyOrder: StudyOrder,
  StudyOrderField: StudyOrderField,
  StudyConnection: StudyConnection,
  StudyMissionFilters: StudyMissionFilters,
  StudyMissionOrder: StudyMissionOrder,
  StudyMissionOrderField: StudyMissionOrderField,
  StudyMissionConnection: StudyMissionConnection,
  UserStudyFilters: UserStudyFilters,
  UserStudyOrder: UserStudyOrder,
  UserStudyOrderField: UserStudyOrderField,
  UserStudyConnection: UserStudyConnection,
  UserStudyMissionFilters: UserStudyMissionFilters,
  UserStudyMissionOrder: UserStudyMissionOrder,
  UserStudyMissionOrderField: UserStudyMissionOrderField,
  UserStudyMissionConnection: UserStudyMissionConnection,
  IntegratedCommunityBoardPayload: IntegratedCommunityBoardPayload,
  IntegratedCommunityBoard: IntegratedCommunityBoard,
  TeacherFilters: TeacherFilters,
  TeacherOrder: TeacherOrder,
  TeacherOrderField: TeacherOrderField,
  TeacherConnection: TeacherConnection,
  TeacherCuration: TeacherCuration,
  TeacherCurationFilters: TeacherCurationFilters,
  TeacherCurationOrder: TeacherCurationOrder,
  TeacherCurationOrderField: TeacherCurationOrderField,
  TeacherCurationConnection: TeacherCurationConnection,
  TeamFilters: TeamFilters,
  TeamOrder: TeamOrder,
  TeamOrderField: TeamOrderField,
  TeamConnection: TeamConnection,
  MembershipFilters: MembershipFilters,
  MembershipOrder: MembershipOrder,
  MembershipOrderField: MembershipOrderField,
  UserFilters: UserFilters,
  UserOrder: UserOrder,
  UserOrderField: UserOrderField,
  UserConnection: UserConnection,
  UserDeviceFilters: UserDeviceFilters,
  UserDeviceOrder: UserDeviceOrder,
  UserDeviceOrderField: UserDeviceOrderField,
  UserDeviceConnection: UserDeviceConnection,
  UserPointFilters: UserPointFilters,
  UserPointOrder: UserPointOrder,
  UserPointOrderField: UserPointOrderField,
  UserPointConnection: UserPointConnection,
  UserRegisterStatFilters: UserRegisterStatFilters,
  UserRegisterCategory: UserRegisterCategory,
  UserRegisterStatConnection: UserRegisterStatConnection,
  UserRegisterStat: UserRegisterStat,
  UserRegisterStatSummary: UserRegisterStatSummary,
  UserRegisterStatGrowthRate: UserRegisterStatGrowthRate,
  UserRegisterStatGrowthRateSummary: UserRegisterStatGrowthRateSummary,
  UserSignupStatConnection: UserSignupStatConnection,
  UserVisitStatConnection: UserVisitStatConnection,
  UserAllianceStatFilters: UserAllianceStatFilters,
  UserAllianceStatConnection: UserAllianceStatConnection,
  UserAllianceStat: UserAllianceStat,
  VideoFilters: VideoFilters,
  VideoOrder: VideoOrder,
  VideoOrderField: VideoOrderField,
  VideoConnection: VideoConnection,
  VideoProductFilters: VideoProductFilters,
  VideoProductOrder: VideoProductOrder,
  VideoProductOrderField: VideoProductOrderField,
  VideoProductConnection: VideoProductConnection,
  RecoveryPasswordAuth: RecoveryPasswordAuth,
  SupportTicket: SupportTicket,
  SupportTicketCategory: SupportTicketCategory,
  SupportTicketCategoryServiceMap: SupportTicketCategoryServiceMap,
  SupportTicketProcessState: SupportTicketProcessState,
  SupportTicketType: SupportTicketType,
  SupportTicketFilters: SupportTicketFilters,
  SupportTicketOrder: SupportTicketOrder,
  SupportTicketOrderField: SupportTicketOrderField,
  SupportTicketConnection: SupportTicketConnection,
  SupportTicketAnswerTemplate: SupportTicketAnswerTemplate,
  SupportTicketAnswerTemplateFilters: SupportTicketAnswerTemplateFilters,
  SupportTicketAnswerTemplateOrder: SupportTicketAnswerTemplateOrder,
  SupportTicketAnswerTemplateOrderField: SupportTicketAnswerTemplateOrderField,
  SupportTicketAnswerTemplateConnection: SupportTicketAnswerTemplateConnection,
  SupportTicketCategoryFilters: SupportTicketCategoryFilters,
  SupportTicketCategoryOrder: SupportTicketCategoryOrder,
  SupportTicketCategoryOrderField: SupportTicketCategoryOrderField,
  SupportTicketCategoryConnection: SupportTicketCategoryConnection,
  SupportTicketCategoryServiceMapFilters: SupportTicketCategoryServiceMapFilters,
  SupportTicketCategoryServiceMapOrder: SupportTicketCategoryServiceMapOrder,
  SupportTicketCategoryServiceMapOrderField: SupportTicketCategoryServiceMapOrderField,
  SupportTicketCategoryServiceMapConnection: SupportTicketCategoryServiceMapConnection,
  Keyword: Keyword,
  ServicePurchaseStatFilters: ServicePurchaseStatFilters,
  ServicePurchaseStatConnection: ServicePurchaseStatConnection,
  ServicePurchaseStat: ServicePurchaseStat,
  ServicePurchaseStatDetail: ServicePurchaseStatDetail,
  ServicePurchaseStatServiceType: ServicePurchaseStatServiceType,
  AllianceUserContentHistoryFilters: AllianceUserContentHistoryFilters,
  AllianceUserContentHistoryConnection: AllianceUserContentHistoryConnection,
  AllianceUserContentHistory: AllianceUserContentHistory,
  ExamSubjectsInput: ExamSubjectsInput,
  ExamSubjectConnection: ExamSubjectConnection,
  Mutation: {},
  AllianceCreateInput: AllianceCreateInput,
  AllianceCreatePayload: AllianceCreatePayload,
  AllianceUserCreateInput: AllianceUserCreateInput,
  AllianceUserCreatePayload: AllianceUserCreatePayload,
  AllianceUserUpdateInput: AllianceUserUpdateInput,
  AllianceUserUpdatePayload: AllianceUserUpdatePayload,
  BannerInput: BannerInput,
  Upload: Scalars['Upload'],
  BannerCreatePayload: BannerCreatePayload,
  BannerUpdatePayload: BannerUpdatePayload,
  BannerGroupCreateInput: BannerGroupCreateInput,
  BannerGroupCreatePayload: BannerGroupCreatePayload,
  BannerGroupUpdateInput: BannerGroupUpdateInput,
  BannerGroupUpdatePayload: BannerGroupUpdatePayload,
  BannerGroupDeleteManyPayload: BannerGroupDeleteManyPayload,
  BookCreateInput: BookCreateInput,
  ContentCreateInput: ContentCreateInput,
  TeacherCommissionInput: TeacherCommissionInput,
  BookCreatePayload: BookCreatePayload,
  BookUpdateInput: BookUpdateInput,
  ContentUpdateInput: ContentUpdateInput,
  BookUpdatePayload: BookUpdatePayload,
  BookDeletePayload: BookDeletePayload,
  BookDeleteManyPayload: BookDeleteManyPayload,
  CampaignInput: CampaignInput,
  CampaignDataInput: CampaignDataInput,
  CampaignCreateOrUpdatePayload: CampaignCreateOrUpdatePayload,
  CampaignDeletePayload: CampaignDeletePayload,
  CampaignBenefitInput: CampaignBenefitInput,
  CampaignBenefitMutationPayload: CampaignBenefitMutationPayload,
  CampaignUserCreateInput: CampaignUserCreateInput,
  CampaignUserMutationPayload: CampaignUserMutationPayload,
  ProvideCampaignBenefitPayload: ProvideCampaignBenefitPayload,
  ProvideResultProps: ProvideResultProps,
  CompanyInput: CompanyInput,
  CompanyCreatePayload: CompanyCreatePayload,
  CompanyUpdatePayload: CompanyUpdatePayload,
  CompanyDeletePayload: CompanyDeletePayload,
  CompanyDeleteManyPayload: CompanyDeleteManyPayload,
  ContentCreatePayload: ContentCreatePayload,
  ContentUpdatePayload: ContentUpdatePayload,
  ContentDeletePayload: ContentDeletePayload,
  ContentDeleteManyPayload: ContentDeleteManyPayload,
  ContentCurationInput: ContentCurationInput,
  ContentCurationCreatePayload: ContentCurationCreatePayload,
  ContentCurationUpdatePayload: ContentCurationUpdatePayload,
  ContentCurationDeletePayload: ContentCurationDeletePayload,
  ContentCurationDeleteManyPayload: ContentCurationDeleteManyPayload,
  CouponInput: CouponInput,
  CouponUpdatePayload: CouponUpdatePayload,
  CouponDeletePayload: CouponDeletePayload,
  ConnectedContentDeletePayload: ConnectedContentDeletePayload,
  CreateConnectedContentPayload: CreateConnectedContentPayload,
  CloneExamCreatePayload: CloneExamCreatePayload,
  ProvideFreeContentPayload: ProvideFreeContentPayload,
  provideFreeContentForUsersPayload: ProvideFreeContentForUsersPayload,
  FailedUser: FailedUser,
  ExamInput: ExamInput,
  ContentExamUpdateInput: ContentExamUpdateInput,
  ExamUpdatePayload: ExamUpdatePayload,
  ExamSectionInput: ExamSectionInput,
  PrevScoreForPassInput: PrevScoreForPassInput,
  ExamSectionUpdatePayload: ExamSectionUpdatePayload,
  ExamQuestionTypeInput: ExamQuestionTypeInput,
  ExamQuestionTypeUpdatePayload: ExamQuestionTypeUpdatePayload,
  ExamQuestionTypeDeletePayload: ExamQuestionTypeDeletePayload,
  ExamQuestionCreateInput: ExamQuestionCreateInput,
  QuestionCreateInput: QuestionCreateInput,
  ExamQuestionCreatePayload: ExamQuestionCreatePayload,
  ExamQuestionUpdateInput: ExamQuestionUpdateInput,
  QuestionUpdateInput: QuestionUpdateInput,
  ExamQuestionUpdatePayload: ExamQuestionUpdatePayload,
  ExamQuestionDeletePayload: ExamQuestionDeletePayload,
  ExamQuestionDeleteManyPayload: ExamQuestionDeleteManyPayload,
  ExamAttemptGetOrCreatePayload: ExamAttemptGetOrCreatePayload,
  ExamAttemptInput: ExamAttemptInput,
  ExamAttemptUpdatePayload: ExamAttemptUpdatePayload,
  ExamAttemptAnswerInput: ExamAttemptAnswerInput,
  ExamAttemptSubmitAnswerManyPayload: ExamAttemptSubmitAnswerManyPayload,
  BucketMode: BucketMode,
  FileUploadPayload: FileUploadPayload,
  HtmlTextInput: HtmlTextInput,
  HtmlTextCreatePayload: HtmlTextCreatePayload,
  HtmlTextUpdatePayload: HtmlTextUpdatePayload,
  HtmlTextDeletePayload: HtmlTextDeletePayload,
  HtmlTextDeleteManyPayload: HtmlTextDeleteManyPayload,
  MenuCreateInput: MenuCreateInput,
  MenuItemInput: MenuItemInput,
  MenuCreatePayload: MenuCreatePayload,
  MenuUpdateInput: MenuUpdateInput,
  MenuUpdatePayload: MenuUpdatePayload,
  MenuDeletePayload: MenuDeletePayload,
  MenuDeleteManyPayload: MenuDeleteManyPayload,
  OfflineCreateInput: OfflineCreateInput,
  NotificationSMSCreateInput: NotificationSmsCreateInput,
  OfflineCreatePayload: OfflineCreatePayload,
  OfflineUpdateInput: OfflineUpdateInput,
  OfflineUpdatePayload: OfflineUpdatePayload,
  OfflineRepurchaseDiscountInput: OfflineRepurchaseDiscountInput,
  OfflineRepurchaseDiscountUpdatePayload: OfflineRepurchaseDiscountUpdatePayload,
  OfflineChildrenContentsUpdatePayload: OfflineChildrenContentsUpdatePayload,
  OfflineStudentClassChangePayload: OfflineStudentClassChangePayload,
  PermissionGrantPayload: PermissionGrantPayload,
  PermissionRevokePayload: PermissionRevokePayload,
  PostCurationInput: PostCurationInput,
  PostCurationConfigInput: PostCurationConfigInput,
  PostCurationUpdatePayload: PostCurationUpdatePayload,
  RefundCreateInput: RefundCreateInput,
  RefundCreatePayload: RefundCreatePayload,
  ResumeProductInput: ResumeProductInput,
  ResumeProductCreatePayload: ResumeProductCreatePayload,
  ResumeProductUpdatePayload: ResumeProductUpdatePayload,
  ResumeProductDeletePayload: ResumeProductDeletePayload,
  ResumeProductDeleteManyPayload: ResumeProductDeleteManyPayload,
  ResumeCreateInput: ResumeCreateInput,
  UserResumeAnswer: UserResumeAnswer,
  ResumeCreatePayload: ResumeCreatePayload,
  ResumeUpdateInput: ResumeUpdateInput,
  ResumeUpdatePayload: ResumeUpdatePayload,
  ReviewCreateInput: ReviewCreateInput,
  ReviewCreatePayload: ReviewCreatePayload,
  CommunityPost: CommunityPost,
  CommunityPostRating: CommunityPostRating,
  ReviewInput: ReviewInput,
  ReviewUpdatePayload: ReviewUpdatePayload,
  ReviewDeletePayload: ReviewDeletePayload,
  ReviewDeleteManyPayload: ReviewDeleteManyPayload,
  ScheduleInput: ScheduleInput,
  ScheduleCreatePayload: ScheduleCreatePayload,
  ScheduleUpdatePayload: ScheduleUpdatePayload,
  ScheduleDeletePayload: ScheduleDeletePayload,
  ScheduleDeleteManyPayload: ScheduleDeleteManyPayload,
  ScheduleEventCreateInput: ScheduleEventCreateInput,
  ScheduleEventCreatePayload: ScheduleEventCreatePayload,
  ScheduleEventUpdateInput: ScheduleEventUpdateInput,
  ScheduleEventUpdatePayload: ScheduleEventUpdatePayload,
  ScheduleEventDeletePayload: ScheduleEventDeletePayload,
  ScheduleEventDeleteManyPayload: ScheduleEventDeleteManyPayload,
  ScheduleUpdateLambdaHandlerPayload: ScheduleUpdateLambdaHandlerPayload,
  SmsRejectInput: SmsRejectInput,
  StudyCreateInput: StudyCreateInput,
  StudyBrazeEventPropertiesInput: StudyBrazeEventPropertiesInput,
  StudyCreatePayload: StudyCreatePayload,
  StudyEventInput: StudyEventInput,
  StudyEventCreatePayload: StudyEventCreatePayload,
  StudyBenefitInput: StudyBenefitInput,
  StudyBenefitCreatePayload: StudyBenefitCreatePayload,
  StudyMissionCreateInput: StudyMissionCreateInput,
  StudyMissionDetailCreateInput: StudyMissionDetailCreateInput,
  AssignmentFileMissionCreateInput: AssignmentFileMissionCreateInput,
  AssignmentLinkMissionCreateInput: AssignmentLinkMissionCreateInput,
  LectureMissionCreateInput: LectureMissionCreateInput,
  ExamMissionCreateInput: ExamMissionCreateInput,
  ReviewMissionCreateInput: ReviewMissionCreateInput,
  StudyMissionCreatePayload: StudyMissionCreatePayload,
  StudyEventBulkCreatePayload: StudyEventBulkCreatePayload,
  StudyUpdateInput: StudyUpdateInput,
  StudyUpdatePayload: StudyUpdatePayload,
  StudyBenefitUpdatePayload: StudyBenefitUpdatePayload,
  StudyMissionUpdateInput: StudyMissionUpdateInput,
  AssignmentFileMissionUpdateInput: AssignmentFileMissionUpdateInput,
  AssignmentLinkMissionUpdateInput: AssignmentLinkMissionUpdateInput,
  LectureMissionUpdateInput: LectureMissionUpdateInput,
  ExamMissionUpdateInput: ExamMissionUpdateInput,
  ReviewMissionUpdateInput: ReviewMissionUpdateInput,
  StudyMissionUpdatePayload: StudyMissionUpdatePayload,
  StudyDeletePayload: StudyDeletePayload,
  StudyDeleteManyPayload: StudyDeleteManyPayload,
  StudyBenefitDeletePayload: StudyBenefitDeletePayload,
  StudyMissionDeletePayload: StudyMissionDeletePayload,
  StudyMissionDeleteManyPayload: StudyMissionDeleteManyPayload,
  StudyEventBulkInput: StudyEventBulkInput,
  StudyRegisterInput: StudyRegisterInput,
  UserStudyRegisterPayload: UserStudyRegisterPayload,
  UserStudyBenefitCreateInput: UserStudyBenefitCreateInput,
  UserStudyBenefitCreatePayload: UserStudyBenefitCreatePayload,
  StudyUserContent: StudyUserContent,
  UserStudyEventForcePassPayload: UserStudyEventForcePassPayload,
  UserStudyEventPassPayload: UserStudyEventPassPayload,
  UserStudyEventFailPayload: UserStudyEventFailPayload,
  UserStudyMissionUpdateInput: UserStudyMissionUpdateInput,
  UserStudyMissionUpdatePayload: UserStudyMissionUpdatePayload,
  UserStudyMissionsUpdatePayload: UserStudyMissionsUpdatePayload,
  UserStudyMissionAssignmentLinkUploadInput: UserStudyMissionAssignmentLinkUploadInput,
  UserStudyMissionAssignmentLinkUploadPayload: UserStudyMissionAssignmentLinkUploadPayload,
  UserStudyMissionAssignmentLinkUploadCommunityInput: UserStudyMissionAssignmentLinkUploadCommunityInput,
  PostCommentStatus: PostCommentStatus,
  PostStatus: PostStatus,
  UserStudyMissionReviewUploadInput: UserStudyMissionReviewUploadInput,
  UserStudyMissionReviewUploadPayload: UserStudyMissionReviewUploadPayload,
  UserStudyMissionReviewUploadCommunityInput: UserStudyMissionReviewUploadCommunityInput,
  UserStudyMissionAssignmentFileUploadInput: UserStudyMissionAssignmentFileUploadInput,
  UserStudyMissionAssignmentFileUploadPayload: UserStudyMissionAssignmentFileUploadPayload,
  AttachmentPrepareUploadInput: AttachmentPrepareUploadInput,
  AttachmentPrepareUploadPayload: AttachmentPrepareUploadPayload,
  Attachment: Attachment,
  integratedCommunityComment: IntegratedCommunityComment,
  integratedCommunityPost: IntegratedCommunityPost,
  AttachmentCompleteUploadInput: AttachmentCompleteUploadInput,
  AttachmentCompleteUploadPayload: AttachmentCompleteUploadPayload,
  IntegratedCommunityPostUpdateInput: IntegratedCommunityPostUpdateInput,
  IntegratedCommunityPostUpdatePayload: IntegratedCommunityPostUpdatePayload,
  TeacherInput: TeacherInput,
  TeacherCreatePayload: TeacherCreatePayload,
  TeacherUpdatePayload: TeacherUpdatePayload,
  TeacherDeletePayload: TeacherDeletePayload,
  TeacherCurationInput: TeacherCurationInput,
  TeacherCurationCreatePayload: TeacherCurationCreatePayload,
  TeacherCurationUpdatePayload: TeacherCurationUpdatePayload,
  TeacherCurationDeletePayload: TeacherCurationDeletePayload,
  TeacherCurationDeleteManyPayload: TeacherCurationDeleteManyPayload,
  TeamInput: TeamInput,
  TeamCreatePayload: TeamCreatePayload,
  TeamUpdatePayload: TeamUpdatePayload,
  TeamDeletePayload: TeamDeletePayload,
  TeamDeleteManyPayload: TeamDeleteManyPayload,
  MembershipInput: MembershipInput,
  MembershipCreatePayload: MembershipCreatePayload,
  MembershipUpdatePayload: MembershipUpdatePayload,
  MembershipDeletePayload: MembershipDeletePayload,
  MembershipDeleteManyPayload: MembershipDeleteManyPayload,
  LoginPayload: LoginPayload,
  LoginStatus: LoginStatus,
  TempAccountInput: TempAccountInput,
  TempAccountSignupPayload: TempAccountSignupPayload,
  TempAccountLinkToUserPayload: TempAccountLinkToUserPayload,
  phoneSendMessageForFindIDPayload: PhoneSendMessageForFindIdPayload,
  phoneSendMessageForFindPWPayload: PhoneSendMessageForFindPwPayload,
  EmailAddress: Scalars['EmailAddress'],
  emailSendMessageForFindIDPayload: EmailSendMessageForFindIdPayload,
  emailSendMessageForFindPWPayload: EmailSendMessageForFindPwPayload,
  findIDByPhoneNumberPayload: FindIdByPhoneNumberPayload,
  findPWByPhoneNumberPayload: FindPwByPhoneNumberPayload,
  UpdatePWPayload: UpdatePwPayload,
  UpdatePWByUsernamePayload: UpdatePwByUsernamePayload,
  UserInfoInput: UserInfoInput,
  PhoneUpdateInput: PhoneUpdateInput,
  UserInfoUpdatePayload: UserInfoUpdatePayload,
  AdminUserInfoUpdate: AdminUserInfoUpdate,
  AdminAllianceUserUpsert: AdminAllianceUserUpsert,
  ActiveUserPayload: ActiveUserPayload,
  UserDeviceDeletePayload: UserDeviceDeletePayload,
  UserPointCreateInput: UserPointCreateInput,
  UserPointCreatePayload: UserPointCreatePayload,
  UserPointUpdateInput: UserPointUpdateInput,
  UserPointUpdatePayload: UserPointUpdatePayload,
  UserPointDeletePayload: UserPointDeletePayload,
  UserPointDeleteManyPayload: UserPointDeleteManyPayload,
  VideoUpdatePayload: VideoUpdatePayload,
  VideoProductInput: VideoProductInput,
  VideoProductCreatePayload: VideoProductCreatePayload,
  VideoProductUpdatePayload: VideoProductUpdatePayload,
  VideoProductDeletePayload: VideoProductDeletePayload,
  VideoProductDeleteManyPayload: VideoProductDeleteManyPayload,
  LeaveReason: LeaveReason,
  UserLeavePayload: UserLeavePayload,
  UserLeaveLog: UserLeaveLog,
  ActivateDormantUserPayload: ActivateDormantUserPayload,
  SupportTicketInput: SupportTicketInput,
  SupportTicketCreatePayload: SupportTicketCreatePayload,
  SupportTicketUpdatePayload: SupportTicketUpdatePayload,
  SupportTicketAnswerTemplateInput: SupportTicketAnswerTemplateInput,
  SupportTicketAnswerTemplateCreatePayload: SupportTicketAnswerTemplateCreatePayload,
  SupportTicketAnswerTemplateUpdatePayload: SupportTicketAnswerTemplateUpdatePayload,
  SupportTicketCategoryInput: SupportTicketCategoryInput,
  SupportTicketCategoryCreatePayload: SupportTicketCategoryCreatePayload,
  SupportTicketCategoryUpdatePayload: SupportTicketCategoryUpdatePayload,
  SupportTicketAdminCreateInput: SupportTicketAdminCreateInput,
  SupportTicketAdminCreatePayload: SupportTicketAdminCreatePayload,
  SupportTicketAdminUpdateInput: SupportTicketAdminUpdateInput,
  SupportTicketAdminUpdatePayload: SupportTicketAdminUpdatePayload,
  AuthSMSInput: AuthSmsInput,
  NativeRegisterInput: NativeRegisterInput,
  NativeRegisterPayload: NativeRegisterPayload,
  SNSRegisterInput: SnsRegisterInput,
  SNSCallBack: SnsCallBack,
  Provider: Provider,
  SNSRegisterPayload: SnsRegisterPayload,
  AllianceRegisterInput: AllianceRegisterInput,
  AllianceRegisterPayload: AllianceRegisterPayload,
  UserRegisterStatOrderField: UserRegisterStatOrderField,
};

export type ActivateDormantUserPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivateDormantUserPayload'] = ResolversParentTypes['ActivateDormantUserPayload']> = {
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
};

export type ActiveUserPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActiveUserPayload'] = ResolversParentTypes['ActiveUserPayload']> = {
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
};

export type AdminBlogPostResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminBlogPost'] = ResolversParentTypes['AdminBlogPost']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type AdminBlogPostConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminBlogPostConnection'] = ResolversParentTypes['AdminBlogPostConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['AdminBlogPost']>, ParentType, ContextType>,
  nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type AllianceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alliance'] = ResolversParentTypes['Alliance']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
  allianceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  expiredAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  prefixUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  templatePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  domain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  siteUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
};

export type AllianceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllianceConnection'] = ResolversParentTypes['AllianceConnection']> = {
  nodes?: Resolver<Array<Maybe<ResolversTypes['Alliance']>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type AllianceCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllianceCreatePayload'] = ResolversParentTypes['AllianceCreatePayload']> = {
  alliance?: Resolver<Maybe<ResolversTypes['Alliance']>, ParentType, ContextType>,
};

export type AllianceRegisterPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllianceRegisterPayload'] = ResolversParentTypes['AllianceRegisterPayload']> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
};

export type AllianceUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllianceUser'] = ResolversParentTypes['AllianceUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  alliance?: Resolver<Maybe<ResolversTypes['Alliance']>, ParentType, ContextType>,
  schoolId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  schoolName?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  major?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  grade?: Resolver<ResolversTypes['AllianceUserGrade'], ParentType, ContextType>,
  status?: Resolver<ResolversTypes['AllianceUserStatus'], ParentType, ContextType>,
  schoolType?: Resolver<Maybe<ResolversTypes['AllianceUserSchoolType']>, ParentType, ContextType>,
  orgUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  studentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  duplicatedUser?: Resolver<Maybe<ResolversTypes['DuplicatedUser']>, ParentType, ContextType>,
};

export type AllianceUserConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllianceUserConnection'] = ResolversParentTypes['AllianceUserConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['AllianceUser']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type AllianceUserContentHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllianceUserContentHistory'] = ResolversParentTypes['AllianceUserContentHistory']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  major?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  grade?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  lastLogin?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  contentName?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  serviceType?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  contentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  contentCategoryType?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  contentPrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type AllianceUserContentHistoryConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllianceUserContentHistoryConnection'] = ResolversParentTypes['AllianceUserContentHistoryConnection']> = {
  nodes?: Resolver<Array<Maybe<ResolversTypes['AllianceUserContentHistory']>>, ParentType, ContextType>,
};

export type AllianceUserCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllianceUserCreatePayload'] = ResolversParentTypes['AllianceUserCreatePayload']> = {
  allianceUser?: Resolver<Maybe<ResolversTypes['AllianceUser']>, ParentType, ContextType>,
};

export type AllianceUserUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllianceUserUpdatePayload'] = ResolversParentTypes['AllianceUserUpdatePayload']> = {
  allianceUser?: Resolver<Maybe<ResolversTypes['AllianceUser']>, ParentType, ContextType>,
};

export type AssignmentFileMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignmentFileMission'] = ResolversParentTypes['AssignmentFileMission']> = {
  useTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  templateFile?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
};

export type AssignmentLinkMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignmentLinkMission'] = ResolversParentTypes['AssignmentLinkMission']> = {
  useUrlCheck?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  hashTags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  siteName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  boardName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  boardUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  minLinkCountLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  comment?: Resolver<Maybe<ResolversTypes['integratedCommunityComment']>, ParentType, ContextType>,
  post?: Resolver<Maybe<ResolversTypes['integratedCommunityPost']>, ParentType, ContextType>,
};

export type AttachmentCompleteUploadPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttachmentCompleteUploadPayload'] = ResolversParentTypes['AttachmentCompleteUploadPayload']> = {
  attachment?: Resolver<ResolversTypes['Attachment'], ParentType, ContextType>,
};

export type AttachmentPrepareUploadPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttachmentPrepareUploadPayload'] = ResolversParentTypes['AttachmentPrepareUploadPayload']> = {
  attachment?: Resolver<ResolversTypes['Attachment'], ParentType, ContextType>,
  signedURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type AuthUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthUser'] = ResolversParentTypes['AuthUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
};

export type BannerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Banner'] = ResolversParentTypes['Banner']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  slideLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  mainImage?: Resolver<ResolversTypes['File'], ParentType, ContextType>,
  backgroundImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  activateNavigationImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  deactivateNavigationImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  click?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  status?: Resolver<ResolversTypes['BannerStatus'], ParentType, ContextType>,
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  bannerGroup?: Resolver<ResolversTypes['BannerGroup'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  backgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type BannerConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerConnection'] = ResolversParentTypes['BannerConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Banner']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type BannerCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerCreatePayload'] = ResolversParentTypes['BannerCreatePayload']> = {
  banner?: Resolver<Maybe<ResolversTypes['Banner']>, ParentType, ContextType>,
};

export type BannerGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerGroup'] = ResolversParentTypes['BannerGroup']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  codeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  service?: Resolver<ResolversTypes['ServiceType'], ParentType, ContextType>,
  activeBanners?: Resolver<Array<ResolversTypes['Banner']>, ParentType, ContextType>,
  advisedMainImageSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  advisedBackgroundImageSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  advisedNavigationImageSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  bannerCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
};

export type BannerGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerGroupConnection'] = ResolversParentTypes['BannerGroupConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['BannerGroup']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type BannerGroupCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerGroupCreatePayload'] = ResolversParentTypes['BannerGroupCreatePayload']> = {
  bannerGroup?: Resolver<Maybe<ResolversTypes['BannerGroup']>, ParentType, ContextType>,
};

export type BannerGroupDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerGroupDeleteManyPayload'] = ResolversParentTypes['BannerGroupDeleteManyPayload']> = {
  bannerGroups?: Resolver<Array<ResolversTypes['BannerGroup']>, ParentType, ContextType>,
};

export type BannerGroupUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerGroupUpdatePayload'] = ResolversParentTypes['BannerGroupUpdatePayload']> = {
  bannerGroup?: Resolver<Maybe<ResolversTypes['BannerGroup']>, ParentType, ContextType>,
};

export type BannerUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerUpdatePayload'] = ResolversParentTypes['BannerUpdatePayload']> = {
  banner?: Resolver<Maybe<ResolversTypes['Banner']>, ParentType, ContextType>,
};

export type BenefitResolvers<ContextType = any, ParentType extends ResolversParentTypes['Benefit'] = ResolversParentTypes['Benefit']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>,
};

export type BoardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Board'] = ResolversParentTypes['Board']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type BookResolvers<ContextType = any, ParentType extends ResolversParentTypes['Book'] = ResolversParentTypes['Book']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  authorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  authorIntroduction?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>,
  expectedQuestionSet?: Resolver<Maybe<ResolversTypes['Exam']>, ParentType, ContextType>,
  mockExams?: Resolver<Array<ResolversTypes['Exam']>, ParentType, ContextType>,
  mockExamTargetCompanies?: Resolver<Array<ResolversTypes['Company']>, ParentType, ContextType>,
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  examDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  year?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  studyLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type BookConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookConnection'] = ResolversParentTypes['BookConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Book']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type BookCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookCreatePayload'] = ResolversParentTypes['BookCreatePayload']> = {
  book?: Resolver<Maybe<ResolversTypes['Book']>, ParentType, ContextType>,
};

export type BookDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookDeleteManyPayload'] = ResolversParentTypes['BookDeleteManyPayload']> = {
  books?: Resolver<Maybe<Array<ResolversTypes['Book']>>, ParentType, ContextType>,
};

export type BookDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookDeletePayload'] = ResolversParentTypes['BookDeletePayload']> = {
  book?: Resolver<Maybe<ResolversTypes['Book']>, ParentType, ContextType>,
};

export type BookUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookUpdatePayload'] = ResolversParentTypes['BookUpdatePayload']> = {
  book?: Resolver<Maybe<ResolversTypes['Book']>, ParentType, ContextType>,
};

export type CampaignResolvers<ContextType = any, ParentType extends ResolversParentTypes['Campaign'] = ResolversParentTypes['Campaign']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  startAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  endAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  noticeTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  pcLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  mobileLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  communityPostURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  possibleDuplicated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  service?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>,
  type?: Resolver<ResolversTypes['CampaignType'], ParentType, ContextType>,
  target?: Resolver<ResolversTypes['CampaignTarget'], ParentType, ContextType>,
  hasUsers?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  campaignUsers?: Resolver<Array<Maybe<ResolversTypes['CampaignUser']>>, ParentType, ContextType>,
  campaignBenefits?: Resolver<Array<Maybe<ResolversTypes['CampaignBenefit']>>, ParentType, ContextType>,
  registeredUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>,
  data?: Resolver<Maybe<ResolversTypes['CampaignData']>, ParentType, ContextType>,
  isForBraze?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
};

export type CampaignBenefitResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignBenefit'] = ResolversParentTypes['CampaignBenefit']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  benefitType?: Resolver<Maybe<ResolversTypes['BenefitType']>, ParentType, ContextType>,
  benefit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
};

export type CampaignBenefitMutationPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignBenefitMutationPayload'] = ResolversParentTypes['CampaignBenefitMutationPayload']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type CampaignConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignConnection'] = ResolversParentTypes['CampaignConnection']> = {
  nodes?: Resolver<Array<Maybe<ResolversTypes['Campaign']>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type CampaignConnectionForDataExtractResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignConnectionForDataExtract'] = ResolversParentTypes['CampaignConnectionForDataExtract']> = {
  nodes?: Resolver<Array<Maybe<ResolversTypes['Campaign']>>, ParentType, ContextType>,
};

export type CampaignCreateOrUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignCreateOrUpdatePayload'] = ResolversParentTypes['CampaignCreateOrUpdatePayload']> = {
  campaign?: Resolver<Maybe<ResolversTypes['Campaign']>, ParentType, ContextType>,
};

export type CampaignDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignData'] = ResolversParentTypes['CampaignData']> = {
  category?: Resolver<ResolversTypes['CampaignCategory'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  etc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type CampaignDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignDeletePayload'] = ResolversParentTypes['CampaignDeletePayload']> = {
  campaign?: Resolver<Maybe<ResolversTypes['Campaign']>, ParentType, ContextType>,
};

export type CampaignUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignUser'] = ResolversParentTypes['CampaignUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  joinAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  allowMarketing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  phoneNumber?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>,
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  isRegistered?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
};

export type CampaignUserMutationPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampaignUserMutationPayload'] = ResolversParentTypes['CampaignUserMutationPayload']> = {
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type CloneExamCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloneExamCreatePayload'] = ResolversParentTypes['CloneExamCreatePayload']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  contentPurchase?: Resolver<Maybe<ResolversTypes['ContentPurchase']>, ParentType, ContextType>,
};

export type CommunityBoardResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityBoard'] = ResolversParentTypes['CommunityBoard']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type CommunityPostResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPost'] = ResolversParentTypes['CommunityPost']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  dislikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  rating?: Resolver<Maybe<ResolversTypes['CommunityPostRating']>, ParentType, ContextType>,
  board?: Resolver<ResolversTypes['CommunityBoard'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type CommunityPostRatingResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPostRating'] = ResolversParentTypes['CommunityPostRating']> = {
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type CompanyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyConnection'] = ResolversParentTypes['CompanyConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Company']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type CompanyCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyCreatePayload'] = ResolversParentTypes['CompanyCreatePayload']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>,
};

export type CompanyDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyDeleteManyPayload'] = ResolversParentTypes['CompanyDeleteManyPayload']> = {
  companies?: Resolver<Maybe<Array<ResolversTypes['Company']>>, ParentType, ContextType>,
};

export type CompanyDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyDeletePayload'] = ResolversParentTypes['CompanyDeletePayload']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>,
};

export type CompanyUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyUpdatePayload'] = ResolversParentTypes['CompanyUpdatePayload']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>,
};

export type ConnectedContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConnectedContent'] = ResolversParentTypes['ConnectedContent']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
};

export type ConnectedContentDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConnectedContentDeletePayload'] = ResolversParentTypes['ConnectedContentDeletePayload']> = {
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type ContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  type?: Resolver<ResolversTypes['ContentType'], ParentType, ContextType>,
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  service?: Resolver<ResolversTypes['ServiceType'], ParentType, ContextType>,
  image?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  expireDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  publishedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  salesPeriod?: Resolver<Array<Maybe<ResolversTypes['Date']>>, ParentType, ContextType>,
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>,
  visibility?: Resolver<ResolversTypes['Visibility'], ParentType, ContextType>,
  includesInvoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  teachers?: Resolver<Array<ResolversTypes['Teacher']>, ParentType, ContextType>,
  teacherCommissions?: Resolver<Maybe<Array<ResolversTypes['TeacherCommissions']>>, ParentType, ContextType>,
  categoryParents?: Resolver<Maybe<Array<ResolversTypes['ContentCategoryParent']>>, ParentType, ContextType>,
  selectedCategoryIDs?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>,
  originalPrice?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  isNotForSale?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  tag?: Resolver<Maybe<ResolversTypes['ContentTag']>, ParentType, ContextType>,
  reviews?: Resolver<Maybe<ResolversTypes['ReviewConnection']>, ParentType, ContextType, ContentReviewsArgs>,
  salesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, ContentSalesCountArgs>,
  maxSellableCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  studentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  hasContent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  isFree?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  saleStatus?: Resolver<Maybe<ResolversTypes['SaleStatus']>, ParentType, ContextType>,
  contentPurchases?: Resolver<Maybe<ResolversTypes['ContentPurchaseConnection']>, ParentType, ContextType, ContentContentPurchasesArgs>,
  video?: Resolver<Maybe<ResolversTypes['VideoProduct']>, ParentType, ContextType>,
  freepass?: Resolver<Maybe<ResolversTypes['FreepassProduct']>, ParentType, ContextType>,
  offline?: Resolver<Maybe<ResolversTypes['Offline']>, ParentType, ContextType>,
  document?: Resolver<Maybe<ResolversTypes['DocumentProduct']>, ParentType, ContextType>,
  book?: Resolver<Maybe<ResolversTypes['Book']>, ParentType, ContextType>,
  exam?: Resolver<Maybe<ResolversTypes['Exam']>, ParentType, ContextType>,
  board?: Resolver<Maybe<ResolversTypes['CommunityBoard']>, ParentType, ContextType>,
};

export type ContentCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCategory'] = ResolversParentTypes['ContentCategory']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  parentCategory?: Resolver<ResolversTypes['ContentCategoryParent'], ParentType, ContextType>,
  isSelected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
};

export type ContentCategoryParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCategoryParent'] = ResolversParentTypes['ContentCategoryParent']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  childrenCategories?: Resolver<Array<ResolversTypes['ContentCategory']>, ParentType, ContextType, ContentCategoryParentChildrenCategoriesArgs>,
};

export type ContentConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentConnection'] = ResolversParentTypes['ContentConnection']> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCreatePayload'] = ResolversParentTypes['ContentCreatePayload']> = {
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>,
};

export type ContentCurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCuration'] = ResolversParentTypes['ContentCuration']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  contents?: Resolver<Array<ResolversTypes['Content']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type ContentCurationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCurationConnection'] = ResolversParentTypes['ContentCurationConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ContentCuration']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentCurationCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCurationCreatePayload'] = ResolversParentTypes['ContentCurationCreatePayload']> = {
  contentCuration?: Resolver<Maybe<ResolversTypes['ContentCuration']>, ParentType, ContextType>,
};

export type ContentCurationDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCurationDeleteManyPayload'] = ResolversParentTypes['ContentCurationDeleteManyPayload']> = {
  contentCurations?: Resolver<Array<ResolversTypes['ContentCuration']>, ParentType, ContextType>,
};

export type ContentCurationDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCurationDeletePayload'] = ResolversParentTypes['ContentCurationDeletePayload']> = {
  contentCuration?: Resolver<Maybe<ResolversTypes['ContentCuration']>, ParentType, ContextType>,
};

export type ContentCurationUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCurationUpdatePayload'] = ResolversParentTypes['ContentCurationUpdatePayload']> = {
  contentCuration?: Resolver<Maybe<ResolversTypes['ContentCuration']>, ParentType, ContextType>,
};

export type ContentDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentDeleteManyPayload'] = ResolversParentTypes['ContentDeleteManyPayload']> = {
  contents?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>,
};

export type ContentDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentDeletePayload'] = ResolversParentTypes['ContentDeletePayload']> = {
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>,
};

export type ContentPurchaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchase'] = ResolversParentTypes['ContentPurchase']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  pointDiscount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  couponDiscount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  parentContent?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>,
  childrenContents?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType>,
  offlineOptionSelectedContentIDs?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  expiredAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  contentPurchaseDetail?: Resolver<Maybe<ResolversTypes['ContentPurchaseDetail']>, ParentType, ContextType>,
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>,
  refund?: Resolver<Maybe<ResolversTypes['Refund']>, ParentType, ContextType>,
};

export type ContentPurchaseConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseConnection'] = ResolversParentTypes['ContentPurchaseConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ContentPurchase']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentPurchaseDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseDetail'] = ResolversParentTypes['ContentPurchaseDetail']> = {
  __resolveType: TypeResolveFn<'Video' | 'Resume' | 'Document' | 'ExamAttempt' | 'Freepass', ParentType, ContextType>
};

export type ContentPurchaseRankResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseRank'] = ResolversParentTypes['ContentPurchaseRank']> = {
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  contentType?: Resolver<ResolversTypes['ContentPurchaseRankContentType'], ParentType, ContextType>,
  salesAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  salesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  playTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<ContentPurchaseRankPlayTimeArgs, 'unit'>>,
  playCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  playUserCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  payType?: Resolver<ResolversTypes['ContentPurchaseRankPayType'], ParentType, ContextType>,
  rateOfOrderField?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  salesAmountRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  salesCountRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  playTimeRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  playCountRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  playUserCountRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
};

export type ContentPurchaseRankGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseRankGroup'] = ResolversParentTypes['ContentPurchaseRankGroup']> = {
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  contentPurchaseRanks?: Resolver<Array<ResolversTypes['ContentPurchaseRank']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  summary?: Resolver<ResolversTypes['ContentPurchaseRankSummary'], ParentType, ContextType>,
};

export type ContentPurchaseRankGroupConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseRankGroupConnection'] = ResolversParentTypes['ContentPurchaseRankGroupConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ContentPurchaseRankGroup']>, ParentType, ContextType>,
  nodesOfLastYear?: Resolver<Array<ResolversTypes['ContentPurchaseRankGroup']>, ParentType, ContextType>,
  nodesTotalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  nodesOfLastYearTotalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentPurchaseRankSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseRankSummary'] = ResolversParentTypes['ContentPurchaseRankSummary']> = {
  totalSalesAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  totalSalesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalPlayTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<ContentPurchaseRankSummaryTotalPlayTimeArgs, 'unit'>>,
  totalPlayCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalPlayUserCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentPurchaseStatResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseStat'] = ResolversParentTypes['ContentPurchaseStat']> = {
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  salesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  salesAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  customerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentPurchaseStatConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseStatConnection'] = ResolversParentTypes['ContentPurchaseStatConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ContentPurchaseStat']>, ParentType, ContextType>,
  nodesOfLastYear?: Resolver<Array<ResolversTypes['ContentPurchaseStat']>, ParentType, ContextType>,
  growthVSLastYear?: Resolver<Array<ResolversTypes['ContentPurchaseStatGrowth']>, ParentType, ContextType>,
  nodesOfLastHalf?: Resolver<Array<ResolversTypes['ContentPurchaseStat']>, ParentType, ContextType>,
  growthVSLastHalf?: Resolver<Array<ResolversTypes['ContentPurchaseStatGrowth']>, ParentType, ContextType>,
  growthSummaryVSLastYear?: Resolver<ResolversTypes['ContentPurchaseStatGrowthSummary'], ParentType, ContextType>,
  growthSummaryVSLastHalf?: Resolver<ResolversTypes['ContentPurchaseStatGrowthSummary'], ParentType, ContextType>,
  summary?: Resolver<ResolversTypes['ContentPurchaseStatSummary'], ParentType, ContextType>,
  weeklySummaryOfLastWeek?: Resolver<ResolversTypes['ContentPurchaseStatSummary'], ParentType, ContextType>,
  weeklySummaryOfLastYear?: Resolver<ResolversTypes['ContentPurchaseStatSummary'], ParentType, ContextType>,
  yearlySummaryOfLastYear?: Resolver<ResolversTypes['ContentPurchaseStatSummary'], ParentType, ContextType>,
  yearlySummaryOfLastHalf?: Resolver<ResolversTypes['ContentPurchaseStatSummary'], ParentType, ContextType>,
};

export type ContentPurchaseStatGrowthResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseStatGrowth'] = ResolversParentTypes['ContentPurchaseStatGrowth']> = {
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  salesAmountGrowthAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  salesCountGrowthAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  customerCountGrowthAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  salesCountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  salesAmountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  customerCountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type ContentPurchaseStatGrowthSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseStatGrowthSummary'] = ResolversParentTypes['ContentPurchaseStatGrowthSummary']> = {
  totalSalesAmountGrowthAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  totalSalesCountGrowthAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalCustomerCountGrowthAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalSalesAmountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  totalSalesCountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  totalCustomerCountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type ContentPurchaseStatSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentPurchaseStatSummary'] = ResolversParentTypes['ContentPurchaseStatSummary']> = {
  totalSalesAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  totalSalesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalCustomerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentRefundDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentRefundDetail'] = ResolversParentTypes['ContentRefundDetail']> = {
  refundRatio?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  netSalesAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentRefundDetailSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentRefundDetailSummary'] = ResolversParentTypes['ContentRefundDetailSummary']> = {
  totalRefundRatio?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  totalNetSalesAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type ContentRefundStatResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentRefundStat'] = ResolversParentTypes['ContentRefundStat']> = {
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  refundCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  refundAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentRefundStatConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentRefundStatConnection'] = ResolversParentTypes['ContentRefundStatConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ContentRefundStat']>, ParentType, ContextType>,
  nodesOfLastYear?: Resolver<Array<ResolversTypes['ContentRefundStat']>, ParentType, ContextType>,
  nodesForSlack?: Resolver<Array<ResolversTypes['ContentRefundStat']>, ParentType, ContextType>,
  refundDetails?: Resolver<Array<ResolversTypes['ContentRefundDetail']>, ParentType, ContextType>,
  refundDetailsOfLastYear?: Resolver<Array<ResolversTypes['ContentRefundDetail']>, ParentType, ContextType>,
  growthVSLastYear?: Resolver<Array<ResolversTypes['ContentRefundStatGrowth']>, ParentType, ContextType>,
  growthSummaryVSLastYear?: Resolver<ResolversTypes['ContentRefundStatGrowthSummary'], ParentType, ContextType>,
  summary?: Resolver<ResolversTypes['ContentRefundStatSummary'], ParentType, ContextType>,
  weeklySummaryOfLastWeek?: Resolver<ResolversTypes['ContentRefundStatSummary'], ParentType, ContextType>,
  weeklySummaryOfLastYear?: Resolver<ResolversTypes['ContentRefundStatSummary'], ParentType, ContextType>,
  yearlySummaryOfLastYear?: Resolver<ResolversTypes['ContentRefundStatSummary'], ParentType, ContextType>,
  refundDetailsSummary?: Resolver<ResolversTypes['ContentRefundDetailSummary'], ParentType, ContextType>,
  refundDetailsSummaryOfLastYear?: Resolver<ResolversTypes['ContentRefundDetailSummary'], ParentType, ContextType>,
};

export type ContentRefundStatGrowthResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentRefundStatGrowth'] = ResolversParentTypes['ContentRefundStatGrowth']> = {
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  refundCountGrowthAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  refundAmountGrowthAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  refundCountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  refundAmountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type ContentRefundStatGrowthSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentRefundStatGrowthSummary'] = ResolversParentTypes['ContentRefundStatGrowthSummary']> = {
  totalRefundAmountGrowthAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalRefundCountGrowthAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalRefundAmountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  totalRefundCountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type ContentRefundStatSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentRefundStatSummary'] = ResolversParentTypes['ContentRefundStatSummary']> = {
  totalRefundAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  totalRefundCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ContentUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentUpdatePayload'] = ResolversParentTypes['ContentUpdatePayload']> = {
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType>,
};

export type CouponResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coupon'] = ResolversParentTypes['Coupon']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  registerablePeriod?: Resolver<Array<Maybe<ResolversTypes['Date']>>, ParentType, ContextType>,
  type?: Resolver<ResolversTypes['CouponType'], ParentType, ContextType>,
  expireDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  couponNumbers?: Resolver<Array<ResolversTypes['CouponNumber']>, ParentType, ContextType>,
  couponCategory?: Resolver<Maybe<ResolversTypes['CouponCategory']>, ParentType, ContextType>,
  couponData?: Resolver<Maybe<Array<ResolversTypes['CouponData']>>, ParentType, ContextType>,
};

export type CouponConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponConnection'] = ResolversParentTypes['CouponConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Coupon']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type CouponCreateOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponCreateOrder'] = ResolversParentTypes['CouponCreateOrder']> = {
  contentID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
};

export type CouponDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponData'] = ResolversParentTypes['CouponData']> = {
  __resolveType: TypeResolveFn<'CouponDiscount' | 'CouponCreateOrder' | 'CouponRegisterCoupon', ParentType, ContextType>
};

export type CouponDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponDeletePayload'] = ResolversParentTypes['CouponDeletePayload']> = {
  coupon?: Resolver<Maybe<ResolversTypes['Coupon']>, ParentType, ContextType>,
};

export type CouponDiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponDiscount'] = ResolversParentTypes['CouponDiscount']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  service?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  contentIDs?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>,
  categories?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>,
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type CouponNumberResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponNumber'] = ResolversParentTypes['CouponNumber']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  connectedContents?: Resolver<Array<Maybe<ResolversTypes['ConnectedContent']>>, ParentType, ContextType>,
};

export type CouponRegisterCouponResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponRegisterCoupon'] = ResolversParentTypes['CouponRegisterCoupon']> = {
  couponNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type CouponUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponUpdatePayload'] = ResolversParentTypes['CouponUpdatePayload']> = {
  coupon?: Resolver<Maybe<ResolversTypes['Coupon']>, ParentType, ContextType>,
};

export type CreateConnectedContentPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateConnectedContentPayload'] = ResolversParentTypes['CreateConnectedContentPayload']> = {
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type DailyStudyTimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyStudyTime'] = ResolversParentTypes['DailyStudyTime']> = {
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  studyTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date'
}

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  printCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  maxPrintLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  printLog?: Resolver<Maybe<Array<ResolversTypes['PrintLog']>>, ParentType, ContextType>,
  documentProduct?: Resolver<ResolversTypes['DocumentProduct'], ParentType, ContextType>,
};

export type DocumentConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentConnection'] = ResolversParentTypes['DocumentConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Document']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type DocumentProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentProduct'] = ResolversParentTypes['DocumentProduct']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  viewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  file?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  printLimitDefault?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  contentSummary?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type DocumentProductConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentProductConnection'] = ResolversParentTypes['DocumentProductConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['DocumentProduct']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type DuplicatedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['DuplicatedUser'] = ResolversParentTypes['DuplicatedUser']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export interface EmailAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
  name: 'EmailAddress'
}

export type EmailSendMessageForFindIdPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['emailSendMessageForFindIDPayload'] = ResolversParentTypes['emailSendMessageForFindIDPayload']> = {
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>,
};

export type EmailSendMessageForFindPwPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['emailSendMessageForFindPWPayload'] = ResolversParentTypes['emailSendMessageForFindPWPayload']> = {
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>,
  recoveryID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type ExamResolvers<ContextType = any, ParentType extends ResolversParentTypes['Exam'] = ResolversParentTypes['Exam']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  defaultEvent?: Resolver<Maybe<ResolversTypes['ExamEvent']>, ParentType, ContextType>,
  events?: Resolver<Array<ResolversTypes['ExamEvent']>, ParentType, ContextType>,
  section?: Resolver<ResolversTypes['ExamSection'], ParentType, ContextType, RequireFields<ExamSectionArgs, 'id'>>,
  sections?: Resolver<Array<ResolversTypes['ExamSection']>, ParentType, ContextType>,
  averageScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, ExamAverageScoreArgs>,
  examQuestions?: Resolver<Maybe<ResolversTypes['ExamQuestionConnection']>, ParentType, ContextType, ExamExamQuestionsArgs>,
  attempts?: Resolver<Maybe<ResolversTypes['ExamAttemptConnection']>, ParentType, ContextType>,
  reportIsReady?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  type?: Resolver<ResolversTypes['ExamType'], ParentType, ContextType>,
  company?: Resolver<ResolversTypes['Company'], ParentType, ContextType>,
};

export type ExamAttemptResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamAttempt'] = ResolversParentTypes['ExamAttempt']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  targetCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>,
  event?: Resolver<ResolversTypes['ExamEvent'], ParentType, ContextType>,
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  tempAccount?: Resolver<Maybe<ResolversTypes['TempAccount']>, ParentType, ContextType>,
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  rank?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, ExamAttemptRankArgs>,
  percentileRank?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, ExamAttemptPercentileRankArgs>,
  sectionResult?: Resolver<Maybe<ResolversTypes['ExamAttemptSectionResult']>, ParentType, ContextType, RequireFields<ExamAttemptSectionResultArgs, 'examSectionID'>>,
  resultsBySection?: Resolver<Array<ResolversTypes['ExamAttemptSectionResult']>, ParentType, ContextType>,
  resultsByQuestionType?: Resolver<Array<ResolversTypes['ExamAttemptQuestionTypeResult']>, ParentType, ContextType, RequireFields<ExamAttemptResultsByQuestionTypeArgs, 'sectionID'>>,
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  takenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
};

export type ExamAttemptAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamAttemptAnswer'] = ResolversParentTypes['ExamAttemptAnswer']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  examQuestion?: Resolver<ResolversTypes['ExamQuestion'], ParentType, ContextType>,
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  isCorrect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
};

export type ExamAttemptConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamAttemptConnection'] = ResolversParentTypes['ExamAttemptConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExamAttempt']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ExamAttemptGetOrCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamAttemptGetOrCreatePayload'] = ResolversParentTypes['ExamAttemptGetOrCreatePayload']> = {
  examAttempt?: Resolver<Maybe<ResolversTypes['ExamAttempt']>, ParentType, ContextType>,
};

export type ExamAttemptQuestionTypeResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamAttemptQuestionTypeResult'] = ResolversParentTypes['ExamAttemptQuestionTypeResult']> = {
  questionType?: Resolver<ResolversTypes['ExamQuestionType'], ParentType, ContextType>,
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  correctRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
};

export type ExamAttemptSectionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamAttemptSectionResult'] = ResolversParentTypes['ExamAttemptSectionResult']> = {
  isTaken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  section?: Resolver<ResolversTypes['ExamSection'], ParentType, ContextType>,
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  correctRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  rank?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, ExamAttemptSectionResultRankArgs>,
  percentileRank?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, ExamAttemptSectionResultPercentileRankArgs>,
  answers?: Resolver<Array<Maybe<ResolversTypes['ExamAttemptAnswer']>>, ParentType, ContextType>,
};

export type ExamAttemptSubmitAnswerManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamAttemptSubmitAnswerManyPayload'] = ResolversParentTypes['ExamAttemptSubmitAnswerManyPayload']> = {
  examAttempt?: Resolver<Maybe<ResolversTypes['ExamAttempt']>, ParentType, ContextType>,
  examAttemptAnswers?: Resolver<Array<ResolversTypes['ExamAttemptAnswer']>, ParentType, ContextType>,
  type?: Resolver<Maybe<ResolversTypes['ExamType']>, ParentType, ContextType>,
};

export type ExamAttemptUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamAttemptUpdatePayload'] = ResolversParentTypes['ExamAttemptUpdatePayload']> = {
  examAttempt?: Resolver<Maybe<ResolversTypes['ExamAttempt']>, ParentType, ContextType>,
};

export type ExamConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamConnection'] = ResolversParentTypes['ExamConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Exam']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ExamEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamEvent'] = ResolversParentTypes['ExamEvent']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  exam?: Resolver<Maybe<ResolversTypes['Exam']>, ParentType, ContextType>,
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
};

export type ExamMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamMission'] = ResolversParentTypes['ExamMission']> = {
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  standardScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type ExamQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestion'] = ResolversParentTypes['ExamQuestion']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  correctRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  type?: Resolver<Maybe<ResolversTypes['ExamQuestionType']>, ParentType, ContextType>,
  question?: Resolver<ResolversTypes['Question'], ParentType, ContextType>,
};

export type ExamQuestionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestionConnection'] = ResolversParentTypes['ExamQuestionConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ExamQuestion']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ExamQuestionCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestionCreatePayload'] = ResolversParentTypes['ExamQuestionCreatePayload']> = {
  examQuestion?: Resolver<Maybe<ResolversTypes['ExamQuestion']>, ParentType, ContextType>,
};

export type ExamQuestionDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestionDeleteManyPayload'] = ResolversParentTypes['ExamQuestionDeleteManyPayload']> = {
  examQuestions?: Resolver<Maybe<Array<ResolversTypes['ExamQuestion']>>, ParentType, ContextType>,
};

export type ExamQuestionDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestionDeletePayload'] = ResolversParentTypes['ExamQuestionDeletePayload']> = {
  examQuestion?: Resolver<Maybe<ResolversTypes['ExamQuestion']>, ParentType, ContextType>,
};

export type ExamQuestionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestionType'] = ResolversParentTypes['ExamQuestionType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  section?: Resolver<ResolversTypes['ExamSection'], ParentType, ContextType>,
  averageScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, ExamQuestionTypeAverageScoreArgs>,
  correctRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, ExamQuestionTypeCorrectRateArgs>,
  contentsRecommendation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type ExamQuestionTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestionTypeConnection'] = ResolversParentTypes['ExamQuestionTypeConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ExamQuestionType']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ExamQuestionTypeDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestionTypeDeletePayload'] = ResolversParentTypes['ExamQuestionTypeDeletePayload']> = {
  examQuestionType?: Resolver<Maybe<ResolversTypes['ExamQuestionType']>, ParentType, ContextType>,
};

export type ExamQuestionTypeUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestionTypeUpdatePayload'] = ResolversParentTypes['ExamQuestionTypeUpdatePayload']> = {
  examQuestionType?: Resolver<Maybe<ResolversTypes['ExamQuestionType']>, ParentType, ContextType>,
};

export type ExamQuestionUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamQuestionUpdatePayload'] = ResolversParentTypes['ExamQuestionUpdatePayload']> = {
  examQuestion?: Resolver<Maybe<ResolversTypes['ExamQuestion']>, ParentType, ContextType>,
};

export type ExamSectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamSection'] = ResolversParentTypes['ExamSection']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  averageScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, ExamSectionAverageScoreArgs>,
  prevScoresForPass?: Resolver<Maybe<Array<ResolversTypes['PrevScoreForPass']>>, ParentType, ContextType>,
  timeLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, ExamSectionTimeLimitArgs>,
  questions?: Resolver<Maybe<ResolversTypes['ExamQuestionConnection']>, ParentType, ContextType, ExamSectionQuestionsArgs>,
  examQuestionTypes?: Resolver<Maybe<Array<ResolversTypes['ExamQuestionType']>>, ParentType, ContextType>,
};

export type ExamSectionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamSectionConnection'] = ResolversParentTypes['ExamSectionConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ExamSection']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ExamSectionUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamSectionUpdatePayload'] = ResolversParentTypes['ExamSectionUpdatePayload']> = {
  examSection?: Resolver<Maybe<ResolversTypes['ExamSection']>, ParentType, ContextType>,
};

export type ExamSubjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamSubject'] = ResolversParentTypes['ExamSubject']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  category?: Resolver<ResolversTypes['WishExamCategory'], ParentType, ContextType>,
  examToTake?: Resolver<ResolversTypes['ExamToTake'], ParentType, ContextType>,
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type ExamSubjectConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamSubjectConnection'] = ResolversParentTypes['ExamSubjectConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExamSubject']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ExamUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExamUpdatePayload'] = ResolversParentTypes['ExamUpdatePayload']> = {
  exam?: Resolver<Maybe<ResolversTypes['Exam']>, ParentType, ContextType>,
};

export type FailedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['FailedUser'] = ResolversParentTypes['FailedUser']> = {
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  error?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['File'] = ResolversParentTypes['File']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type FileUploadPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileUploadPayload'] = ResolversParentTypes['FileUploadPayload']> = {
  file?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
};

export type FindIdByPhoneNumberPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['findIDByPhoneNumberPayload'] = ResolversParentTypes['findIDByPhoneNumberPayload']> = {
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type FindPwByPhoneNumberPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['findPWByPhoneNumberPayload'] = ResolversParentTypes['findPWByPhoneNumberPayload']> = {
  recoveryID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type FreepassResolvers<ContextType = any, ParentType extends ResolversParentTypes['Freepass'] = ResolversParentTypes['Freepass']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  addedDocuments?: Resolver<Array<ResolversTypes['Document']>, ParentType, ContextType>,
  printedDocuments?: Resolver<Array<ResolversTypes['Document']>, ParentType, ContextType>,
  printedDocumentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  freepassProduct?: Resolver<ResolversTypes['FreepassProduct'], ParentType, ContextType>,
};

export type FreepassInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['FreepassInvoice'] = ResolversParentTypes['FreepassInvoice']> = {
  invoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType>,
  playtime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  netSales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  service?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type FreepassProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['FreepassProduct'] = ResolversParentTypes['FreepassProduct']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  commission?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  videoTotalTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  package?: Resolver<ResolversTypes['Package'], ParentType, ContextType>,
  includeInvoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  refundNote?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  detailHTML?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  note?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  maxBuyContentCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  maxPrintableDocumentLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  maxPrintLimitPerDocument?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  paybackType?: Resolver<ResolversTypes['PaybackType'], ParentType, ContextType>,
  paybackSet?: Resolver<ResolversTypes['PaybackSet'], ParentType, ContextType>,
};

export type FreepassProductConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FreepassProductConnection'] = ResolversParentTypes['FreepassProductConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['FreepassProduct']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type HtmlTextResolvers<ContextType = any, ParentType extends ResolversParentTypes['HtmlText'] = ResolversParentTypes['HtmlText']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type HtmlTextConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['HtmlTextConnection'] = ResolversParentTypes['HtmlTextConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['HtmlText']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type HtmlTextCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['HtmlTextCreatePayload'] = ResolversParentTypes['HtmlTextCreatePayload']> = {
  htmlText?: Resolver<Maybe<ResolversTypes['HtmlText']>, ParentType, ContextType>,
};

export type HtmlTextDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['HtmlTextDeleteManyPayload'] = ResolversParentTypes['HtmlTextDeleteManyPayload']> = {
  htmlTexts?: Resolver<Maybe<Array<ResolversTypes['HtmlText']>>, ParentType, ContextType>,
};

export type HtmlTextDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['HtmlTextDeletePayload'] = ResolversParentTypes['HtmlTextDeletePayload']> = {
  htmlText?: Resolver<Maybe<ResolversTypes['HtmlText']>, ParentType, ContextType>,
};

export type HtmlTextUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['HtmlTextUpdatePayload'] = ResolversParentTypes['HtmlTextUpdatePayload']> = {
  htmlText?: Resolver<Maybe<ResolversTypes['HtmlText']>, ParentType, ContextType>,
};

export type InfobankSmsSendResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InfobankSMSSendResult'] = ResolversParentTypes['InfobankSMSSendResult']> = {
  messageID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  errorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type IntegratedCommunityBoardResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegratedCommunityBoard'] = ResolversParentTypes['IntegratedCommunityBoard']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type IntegratedCommunityBoardPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegratedCommunityBoardPayload'] = ResolversParentTypes['IntegratedCommunityBoardPayload']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  nodes?: Resolver<Maybe<Array<ResolversTypes['IntegratedCommunityBoard']>>, ParentType, ContextType>,
};

export type IntegratedCommunityCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['integratedCommunityComment'] = ResolversParentTypes['integratedCommunityComment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
};

export type IntegratedCommunityPostResolvers<ContextType = any, ParentType extends ResolversParentTypes['integratedCommunityPost'] = ResolversParentTypes['integratedCommunityPost']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  allowTrackback?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  anonymous?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  attachmentIDs?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>,
  boardID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  commentStatus?: Resolver<Maybe<ResolversTypes['PostCommentStatus']>, ParentType, ContextType>,
  containsImage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  containsYoutube?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  contentText?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  imageSrc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  isNotice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  notifyMessage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  status?: Resolver<ResolversTypes['PostStatus'], ParentType, ContextType>,
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  youtubeSrc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type IntegratedCommunityPostUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegratedCommunityPostUpdatePayload'] = ResolversParentTypes['IntegratedCommunityPostUpdatePayload']> = {
  post?: Resolver<ResolversTypes['integratedCommunityPost'], ParentType, ContextType>,
};

export type InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  freepassInvoice?: Resolver<Maybe<ResolversTypes['FreepassInvoice']>, ParentType, ContextType>,
  salesByContent?: Resolver<Array<ResolversTypes['InvoiceSaleByContent']>, ParentType, ContextType>,
  refundsByContent?: Resolver<Array<ResolversTypes['InvoiceRefundByContent']>, ParentType, ContextType>,
  freepassNetSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  contentGrossSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  contentPGCommissionAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  contentNetSalesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  refundPriceAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  refundPGCommissionAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  netRefundsAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  paidAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  confirmState?: Resolver<Maybe<ResolversTypes['ConfirmState']>, ParentType, ContextType>,
  incomeTax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  localTax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  totalTax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  totalNetAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  teacher?: Resolver<ResolversTypes['Teacher'], ParentType, ContextType>,
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  month?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type InvoiceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceConnection'] = ResolversParentTypes['InvoiceConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Invoice']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type InvoiceRefundByContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceRefundByContent'] = ResolversParentTypes['InvoiceRefundByContent']> = {
  invoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  refundPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  netRefund?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  pgCommission?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  teacherContractRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  teacherContentParticipationRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type InvoiceSaleByContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceSaleByContent'] = ResolversParentTypes['InvoiceSaleByContent']> = {
  invoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  teacherContractRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  teacherContentParticipationRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  pgCommission?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  grossSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  netSales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type KeywordResolvers<ContextType = any, ParentType extends ResolversParentTypes['Keyword'] = ResolversParentTypes['Keyword']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>,
};

export type LectureMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['LectureMission'] = ResolversParentTypes['LectureMission']> = {
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  standardProgressRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type LoginPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginPayload'] = ResolversParentTypes['LoginPayload']> = {
  status?: Resolver<ResolversTypes['LoginStatus'], ParentType, ContextType>,
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type MajorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Major'] = ResolversParentTypes['Major']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type MajorConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MajorConnection'] = ResolversParentTypes['MajorConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Major']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type MembershipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Membership'] = ResolversParentTypes['Membership']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  role?: Resolver<Maybe<ResolversTypes['MembershipRole']>, ParentType, ContextType>,
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>,
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
};

export type MembershipConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipConnection'] = ResolversParentTypes['MembershipConnection']> = {
  edges?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type MembershipCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipCreatePayload'] = ResolversParentTypes['MembershipCreatePayload']> = {
  membership?: Resolver<Maybe<ResolversTypes['Membership']>, ParentType, ContextType>,
};

export type MembershipDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipDeleteManyPayload'] = ResolversParentTypes['MembershipDeleteManyPayload']> = {
  memberships?: Resolver<Maybe<Array<ResolversTypes['Membership']>>, ParentType, ContextType>,
};

export type MembershipDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipDeletePayload'] = ResolversParentTypes['MembershipDeletePayload']> = {
  membership?: Resolver<Maybe<ResolversTypes['Membership']>, ParentType, ContextType>,
};

export type MembershipUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipUpdatePayload'] = ResolversParentTypes['MembershipUpdatePayload']> = {
  membership?: Resolver<Maybe<ResolversTypes['Membership']>, ParentType, ContextType>,
};

export type MenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['Menu'] = ResolversParentTypes['Menu']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  items?: Resolver<Array<ResolversTypes['MenuItem']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type MenuConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuConnection'] = ResolversParentTypes['MenuConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Menu']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type MenuCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuCreatePayload'] = ResolversParentTypes['MenuCreatePayload']> = {
  menu?: Resolver<Maybe<ResolversTypes['Menu']>, ParentType, ContextType>,
};

export type MenuDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuDeleteManyPayload'] = ResolversParentTypes['MenuDeleteManyPayload']> = {
  menus?: Resolver<Maybe<Array<Maybe<ResolversTypes['Menu']>>>, ParentType, ContextType>,
};

export type MenuDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuDeletePayload'] = ResolversParentTypes['MenuDeletePayload']> = {
  menu?: Resolver<Maybe<ResolversTypes['Menu']>, ParentType, ContextType>,
};

export type MenuItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuItem'] = ResolversParentTypes['MenuItem']> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  open?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  linkURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  children?: Resolver<Array<ResolversTypes['MenuItem']>, ParentType, ContextType>,
};

export type MenuUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuUpdatePayload'] = ResolversParentTypes['MenuUpdatePayload']> = {
  menu?: Resolver<Maybe<ResolversTypes['Menu']>, ParentType, ContextType>,
};

export type MissionDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionDetails'] = ResolversParentTypes['MissionDetails']> = {
  __resolveType: TypeResolveFn<'AssignmentFileMission' | 'AssignmentLinkMission' | 'LectureMission' | 'ExamMission' | 'ReviewMission', ParentType, ContextType>
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  allianceCreate?: Resolver<Maybe<ResolversTypes['AllianceCreatePayload']>, ParentType, ContextType, RequireFields<MutationAllianceCreateArgs, 'input'>>,
  allianceUserCreate?: Resolver<Maybe<ResolversTypes['AllianceUserCreatePayload']>, ParentType, ContextType, RequireFields<MutationAllianceUserCreateArgs, 'input'>>,
  allianceUserUpdate?: Resolver<Maybe<ResolversTypes['AllianceUserUpdatePayload']>, ParentType, ContextType, RequireFields<MutationAllianceUserUpdateArgs, 'input'>>,
  bannerCreate?: Resolver<Maybe<ResolversTypes['BannerCreatePayload']>, ParentType, ContextType, MutationBannerCreateArgs>,
  bannerUpdate?: Resolver<Maybe<ResolversTypes['BannerUpdatePayload']>, ParentType, ContextType, RequireFields<MutationBannerUpdateArgs, 'id' | 'input'>>,
  bannerGroupCreate?: Resolver<Maybe<ResolversTypes['BannerGroupCreatePayload']>, ParentType, ContextType, RequireFields<MutationBannerGroupCreateArgs, 'input'>>,
  bannerGroupUpdate?: Resolver<Maybe<ResolversTypes['BannerGroupUpdatePayload']>, ParentType, ContextType, RequireFields<MutationBannerGroupUpdateArgs, 'id' | 'input'>>,
  bannerClickLog?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationBannerClickLogArgs, 'bannerID'>>,
  bannerGroupDeleteMany?: Resolver<Maybe<ResolversTypes['BannerGroupDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationBannerGroupDeleteManyArgs, 'ids'>>,
  bookCreate?: Resolver<Maybe<ResolversTypes['BookCreatePayload']>, ParentType, ContextType, RequireFields<MutationBookCreateArgs, 'input'>>,
  bookUpdate?: Resolver<Maybe<ResolversTypes['BookUpdatePayload']>, ParentType, ContextType, RequireFields<MutationBookUpdateArgs, 'id' | 'input'>>,
  bookDelete?: Resolver<Maybe<ResolversTypes['BookDeletePayload']>, ParentType, ContextType, RequireFields<MutationBookDeleteArgs, 'id'>>,
  bookDeleteMany?: Resolver<Maybe<ResolversTypes['BookDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationBookDeleteManyArgs, 'ids'>>,
  bookYearInit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  campaignCreate?: Resolver<Maybe<ResolversTypes['CampaignCreateOrUpdatePayload']>, ParentType, ContextType, RequireFields<MutationCampaignCreateArgs, 'input'>>,
  campaignUpdate?: Resolver<Maybe<ResolversTypes['CampaignCreateOrUpdatePayload']>, ParentType, ContextType, RequireFields<MutationCampaignUpdateArgs, 'id' | 'input'>>,
  campaignDelete?: Resolver<Maybe<ResolversTypes['CampaignDeletePayload']>, ParentType, ContextType, RequireFields<MutationCampaignDeleteArgs, 'id'>>,
  campaignBenefitCreate?: Resolver<Maybe<ResolversTypes['CampaignBenefitMutationPayload']>, ParentType, ContextType, RequireFields<MutationCampaignBenefitCreateArgs, 'input'>>,
  campaignBenefitUpdate?: Resolver<Maybe<ResolversTypes['CampaignBenefitMutationPayload']>, ParentType, ContextType, RequireFields<MutationCampaignBenefitUpdateArgs, 'benefitID' | 'input'>>,
  campaignBenefitDelete?: Resolver<Maybe<ResolversTypes['CampaignBenefitMutationPayload']>, ParentType, ContextType, RequireFields<MutationCampaignBenefitDeleteArgs, 'benefitID'>>,
  campaignUserCreateMany?: Resolver<Maybe<ResolversTypes['CampaignUserMutationPayload']>, ParentType, ContextType, RequireFields<MutationCampaignUserCreateManyArgs, 'campaignID' | 'inputs'>>,
  campaignUserDeleteMany?: Resolver<Maybe<ResolversTypes['CampaignUserMutationPayload']>, ParentType, ContextType, RequireFields<MutationCampaignUserDeleteManyArgs, 'campaignID'>>,
  provideCampaignBenefits?: Resolver<ResolversTypes['ProvideCampaignBenefitPayload'], ParentType, ContextType, RequireFields<MutationProvideCampaignBenefitsArgs, 'campaignID' | 'userID'>>,
  companyCreate?: Resolver<Maybe<ResolversTypes['CompanyCreatePayload']>, ParentType, ContextType, RequireFields<MutationCompanyCreateArgs, 'input'>>,
  companyUpdate?: Resolver<Maybe<ResolversTypes['CompanyUpdatePayload']>, ParentType, ContextType, RequireFields<MutationCompanyUpdateArgs, 'id' | 'input'>>,
  companyDelete?: Resolver<Maybe<ResolversTypes['CompanyDeletePayload']>, ParentType, ContextType, RequireFields<MutationCompanyDeleteArgs, 'id'>>,
  companyDeleteMany?: Resolver<Maybe<ResolversTypes['CompanyDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationCompanyDeleteManyArgs, 'ids'>>,
  contentCreate?: Resolver<Maybe<ResolversTypes['ContentCreatePayload']>, ParentType, ContextType, RequireFields<MutationContentCreateArgs, 'input'>>,
  contentUpdate?: Resolver<Maybe<ResolversTypes['ContentUpdatePayload']>, ParentType, ContextType, RequireFields<MutationContentUpdateArgs, 'id' | 'input'>>,
  contentDelete?: Resolver<Maybe<ResolversTypes['ContentDeletePayload']>, ParentType, ContextType, RequireFields<MutationContentDeleteArgs, 'id'>>,
  contentDeleteMany?: Resolver<Maybe<ResolversTypes['ContentDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationContentDeleteManyArgs, 'ids'>>,
  contentCurationCreate?: Resolver<Maybe<ResolversTypes['ContentCurationCreatePayload']>, ParentType, ContextType, RequireFields<MutationContentCurationCreateArgs, 'input'>>,
  contentCurationUpdate?: Resolver<Maybe<ResolversTypes['ContentCurationUpdatePayload']>, ParentType, ContextType, RequireFields<MutationContentCurationUpdateArgs, 'id' | 'input'>>,
  contentCurationDelete?: Resolver<Maybe<ResolversTypes['ContentCurationDeletePayload']>, ParentType, ContextType, RequireFields<MutationContentCurationDeleteArgs, 'id'>>,
  contentCurationDeleteMany?: Resolver<Maybe<ResolversTypes['ContentCurationDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationContentCurationDeleteManyArgs, 'ids'>>,
  couponUpdate?: Resolver<Maybe<ResolversTypes['CouponUpdatePayload']>, ParentType, ContextType, RequireFields<MutationCouponUpdateArgs, 'id' | 'input'>>,
  couponDelete?: Resolver<Maybe<ResolversTypes['CouponDeletePayload']>, ParentType, ContextType, RequireFields<MutationCouponDeleteArgs, 'id'>>,
  couponConnectedContentDelete?: Resolver<Maybe<ResolversTypes['ConnectedContentDeletePayload']>, ParentType, ContextType, RequireFields<MutationCouponConnectedContentDeleteArgs, 'id'>>,
  couponConnectedContentCreate?: Resolver<Maybe<ResolversTypes['CreateConnectedContentPayload']>, ParentType, ContextType, RequireFields<MutationCouponConnectedContentCreateArgs, 'couponNumber' | 'contentID'>>,
  createCloneExam?: Resolver<ResolversTypes['CloneExamCreatePayload'], ParentType, ContextType, RequireFields<MutationCreateCloneExamArgs, 'parentUserContentID'>>,
  provideFreeContent?: Resolver<ResolversTypes['ProvideFreeContentPayload'], ParentType, ContextType, RequireFields<MutationProvideFreeContentArgs, 'userID' | 'contentID'>>,
  provideFreeContentForUsers?: Resolver<ResolversTypes['provideFreeContentForUsersPayload'], ParentType, ContextType, RequireFields<MutationProvideFreeContentForUsersArgs, 'userID' | 'contentID'>>,
  contentPurchaseRanksDailyUpdate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, MutationContentPurchaseRanksDailyUpdateArgs>,
  contentPurchaseStatDailyUpdate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, MutationContentPurchaseStatDailyUpdateArgs>,
  contentPurchaseStatDailyUpdateAll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, MutationContentPurchaseStatDailyUpdateAllArgs>,
  contentRefundStatDailyUpdate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, MutationContentRefundStatDailyUpdateArgs>,
  contentRefundStatDailyUpdateAll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, MutationContentRefundStatDailyUpdateAllArgs>,
  examUpdate?: Resolver<ResolversTypes['ExamUpdatePayload'], ParentType, ContextType, RequireFields<MutationExamUpdateArgs, 'id' | 'input'>>,
  examSectionUpdate?: Resolver<ResolversTypes['ExamSectionUpdatePayload'], ParentType, ContextType, RequireFields<MutationExamSectionUpdateArgs, 'id' | 'input'>>,
  examQuestionTypeUpdate?: Resolver<ResolversTypes['ExamQuestionTypeUpdatePayload'], ParentType, ContextType, RequireFields<MutationExamQuestionTypeUpdateArgs, 'id' | 'input'>>,
  examQuestionTypeDelete?: Resolver<ResolversTypes['ExamQuestionTypeDeletePayload'], ParentType, ContextType, RequireFields<MutationExamQuestionTypeDeleteArgs, 'id'>>,
  examQuestionCreate?: Resolver<ResolversTypes['ExamQuestionCreatePayload'], ParentType, ContextType, RequireFields<MutationExamQuestionCreateArgs, 'input'>>,
  examQuestionUpdate?: Resolver<ResolversTypes['ExamQuestionUpdatePayload'], ParentType, ContextType, RequireFields<MutationExamQuestionUpdateArgs, 'id' | 'input'>>,
  examQuestionDelete?: Resolver<ResolversTypes['ExamQuestionDeletePayload'], ParentType, ContextType, RequireFields<MutationExamQuestionDeleteArgs, 'id'>>,
  examQuestionDeleteMany?: Resolver<ResolversTypes['ExamQuestionDeleteManyPayload'], ParentType, ContextType, RequireFields<MutationExamQuestionDeleteManyArgs, 'ids'>>,
  examAttemptGetOrCreate?: Resolver<ResolversTypes['ExamAttemptGetOrCreatePayload'], ParentType, ContextType, RequireFields<MutationExamAttemptGetOrCreateArgs, 'userID' | 'examEventID'>>,
  examAttemptUpdate?: Resolver<ResolversTypes['ExamAttemptUpdatePayload'], ParentType, ContextType, RequireFields<MutationExamAttemptUpdateArgs, 'id' | 'input'>>,
  examAttemptAnswerSubmitMany?: Resolver<Maybe<ResolversTypes['ExamAttemptSubmitAnswerManyPayload']>, ParentType, ContextType, RequireFields<MutationExamAttemptAnswerSubmitManyArgs, 'examAttemptID' | 'inputs'>>,
  fileUpload?: Resolver<ResolversTypes['FileUploadPayload'], ParentType, ContextType, MutationFileUploadArgs>,
  htmlTextCreate?: Resolver<Maybe<ResolversTypes['HtmlTextCreatePayload']>, ParentType, ContextType, RequireFields<MutationHtmlTextCreateArgs, 'input'>>,
  htmlTextUpdate?: Resolver<Maybe<ResolversTypes['HtmlTextUpdatePayload']>, ParentType, ContextType, RequireFields<MutationHtmlTextUpdateArgs, 'id' | 'input'>>,
  htmlTextDelete?: Resolver<Maybe<ResolversTypes['HtmlTextDeletePayload']>, ParentType, ContextType, RequireFields<MutationHtmlTextDeleteArgs, 'id'>>,
  htmlTextDeleteMany?: Resolver<Maybe<ResolversTypes['HtmlTextDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationHtmlTextDeleteManyArgs, 'ids'>>,
  menuCreate?: Resolver<Maybe<ResolversTypes['MenuCreatePayload']>, ParentType, ContextType, RequireFields<MutationMenuCreateArgs, 'input'>>,
  menuUpdate?: Resolver<Maybe<ResolversTypes['MenuUpdatePayload']>, ParentType, ContextType, RequireFields<MutationMenuUpdateArgs, 'id' | 'input'>>,
  menuDelete?: Resolver<Maybe<ResolversTypes['MenuDeletePayload']>, ParentType, ContextType, RequireFields<MutationMenuDeleteArgs, 'id'>>,
  menuDeleteMany?: Resolver<Maybe<ResolversTypes['MenuDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationMenuDeleteManyArgs, 'ids'>>,
  offlineCreate?: Resolver<Maybe<ResolversTypes['OfflineCreatePayload']>, ParentType, ContextType, RequireFields<MutationOfflineCreateArgs, 'input'>>,
  offlineUpdate?: Resolver<Maybe<ResolversTypes['OfflineUpdatePayload']>, ParentType, ContextType, RequireFields<MutationOfflineUpdateArgs, 'id' | 'input'>>,
  offlineRepurchaseDiscountUpdate?: Resolver<Maybe<ResolversTypes['OfflineRepurchaseDiscountUpdatePayload']>, ParentType, ContextType, RequireFields<MutationOfflineRepurchaseDiscountUpdateArgs, 'offlineID'>>,
  offlineChildrenContentsUpdate?: Resolver<Maybe<ResolversTypes['OfflineChildrenContentsUpdatePayload']>, ParentType, ContextType, RequireFields<MutationOfflineChildrenContentsUpdateArgs, 'offlineID' | 'childrenOfflineIDs'>>,
  offlineStudentClassChange?: Resolver<ResolversTypes['OfflineStudentClassChangePayload'], ParentType, ContextType, RequireFields<MutationOfflineStudentClassChangeArgs, 'userContentID' | 'contentID'>>,
  permissionGrant?: Resolver<Maybe<ResolversTypes['PermissionGrantPayload']>, ParentType, ContextType, RequireFields<MutationPermissionGrantArgs, 'teamID' | 'scope'>>,
  permissionRevoke?: Resolver<Maybe<ResolversTypes['PermissionRevokePayload']>, ParentType, ContextType, RequireFields<MutationPermissionRevokeArgs, 'id'>>,
  postCurationUpdate?: Resolver<Maybe<ResolversTypes['PostCurationUpdatePayload']>, ParentType, ContextType, RequireFields<MutationPostCurationUpdateArgs, 'id' | 'input'>>,
  refundCreate?: Resolver<Maybe<ResolversTypes['RefundCreatePayload']>, ParentType, ContextType, RequireFields<MutationRefundCreateArgs, 'input'>>,
  resumeProductCreate?: Resolver<Maybe<ResolversTypes['ResumeProductCreatePayload']>, ParentType, ContextType, RequireFields<MutationResumeProductCreateArgs, 'input'>>,
  resumeProductUpdate?: Resolver<Maybe<ResolversTypes['ResumeProductUpdatePayload']>, ParentType, ContextType, RequireFields<MutationResumeProductUpdateArgs, 'id' | 'input'>>,
  resumeProductDelete?: Resolver<Maybe<ResolversTypes['ResumeProductDeletePayload']>, ParentType, ContextType, RequireFields<MutationResumeProductDeleteArgs, 'id'>>,
  resumeProductDeleteMany?: Resolver<Maybe<ResolversTypes['ResumeProductDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationResumeProductDeleteManyArgs, 'ids'>>,
  resumeCreate?: Resolver<Maybe<ResolversTypes['ResumeCreatePayload']>, ParentType, ContextType, RequireFields<MutationResumeCreateArgs, 'input'>>,
  resumeUpdate?: Resolver<Maybe<ResolversTypes['ResumeUpdatePayload']>, ParentType, ContextType, RequireFields<MutationResumeUpdateArgs, 'id' | 'input'>>,
  reviewCreate?: Resolver<Maybe<ResolversTypes['ReviewCreatePayload']>, ParentType, ContextType, RequireFields<MutationReviewCreateArgs, 'input'>>,
  reviewUpdate?: Resolver<Maybe<ResolversTypes['ReviewUpdatePayload']>, ParentType, ContextType, RequireFields<MutationReviewUpdateArgs, 'id' | 'input'>>,
  reviewDelete?: Resolver<Maybe<ResolversTypes['ReviewDeletePayload']>, ParentType, ContextType, RequireFields<MutationReviewDeleteArgs, 'id'>>,
  reviewDeleteMany?: Resolver<Maybe<ResolversTypes['ReviewDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationReviewDeleteManyArgs, 'ids'>>,
  scheduleCreate?: Resolver<Maybe<ResolversTypes['ScheduleCreatePayload']>, ParentType, ContextType, RequireFields<MutationScheduleCreateArgs, 'input'>>,
  scheduleUpdate?: Resolver<Maybe<ResolversTypes['ScheduleUpdatePayload']>, ParentType, ContextType, RequireFields<MutationScheduleUpdateArgs, 'id' | 'input'>>,
  scheduleDelete?: Resolver<Maybe<ResolversTypes['ScheduleDeletePayload']>, ParentType, ContextType, RequireFields<MutationScheduleDeleteArgs, 'id'>>,
  scheduleDeleteMany?: Resolver<Maybe<ResolversTypes['ScheduleDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationScheduleDeleteManyArgs, 'ids'>>,
  scheduleEventCreate?: Resolver<Maybe<ResolversTypes['ScheduleEventCreatePayload']>, ParentType, ContextType, RequireFields<MutationScheduleEventCreateArgs, 'input'>>,
  scheduleEventUpdate?: Resolver<Maybe<ResolversTypes['ScheduleEventUpdatePayload']>, ParentType, ContextType, RequireFields<MutationScheduleEventUpdateArgs, 'id' | 'input'>>,
  scheduleEventDelete?: Resolver<Maybe<ResolversTypes['ScheduleEventDeletePayload']>, ParentType, ContextType, RequireFields<MutationScheduleEventDeleteArgs, 'id'>>,
  scheduleEventDeleteMany?: Resolver<Maybe<ResolversTypes['ScheduleEventDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationScheduleEventDeleteManyArgs, 'ids'>>,
  schedulesUpdateIfAutoUpdate?: Resolver<Maybe<ResolversTypes['ScheduleUpdateLambdaHandlerPayload']>, ParentType, ContextType, RequireFields<MutationSchedulesUpdateIfAutoUpdateArgs, 'token'>>,
  smsRejectCreate?: Resolver<Maybe<ResolversTypes['SmsReject']>, ParentType, ContextType, RequireFields<MutationSmsRejectCreateArgs, 'input'>>,
  studyCreate?: Resolver<Maybe<ResolversTypes['StudyCreatePayload']>, ParentType, ContextType, RequireFields<MutationStudyCreateArgs, 'input'>>,
  studyEventCreate?: Resolver<Maybe<ResolversTypes['StudyEventCreatePayload']>, ParentType, ContextType, RequireFields<MutationStudyEventCreateArgs, 'input'>>,
  studyBenefitCreate?: Resolver<Maybe<ResolversTypes['StudyBenefitCreatePayload']>, ParentType, ContextType, RequireFields<MutationStudyBenefitCreateArgs, 'input'>>,
  studyMissionCreate?: Resolver<Maybe<ResolversTypes['StudyMissionCreatePayload']>, ParentType, ContextType, RequireFields<MutationStudyMissionCreateArgs, 'input'>>,
  studyEventBulkCreate?: Resolver<Maybe<ResolversTypes['StudyEventBulkCreatePayload']>, ParentType, ContextType, RequireFields<MutationStudyEventBulkCreateArgs, 'inputs'>>,
  studyUpdate?: Resolver<Maybe<ResolversTypes['StudyUpdatePayload']>, ParentType, ContextType, RequireFields<MutationStudyUpdateArgs, 'id' | 'input'>>,
  studyBenefitUpdate?: Resolver<Maybe<ResolversTypes['StudyBenefitUpdatePayload']>, ParentType, ContextType, RequireFields<MutationStudyBenefitUpdateArgs, 'id' | 'input'>>,
  studyMissionUpdate?: Resolver<Maybe<ResolversTypes['StudyMissionUpdatePayload']>, ParentType, ContextType, RequireFields<MutationStudyMissionUpdateArgs, 'id' | 'input'>>,
  studyDelete?: Resolver<Maybe<ResolversTypes['StudyDeletePayload']>, ParentType, ContextType, RequireFields<MutationStudyDeleteArgs, 'id'>>,
  studyDeleteMany?: Resolver<Maybe<ResolversTypes['StudyDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationStudyDeleteManyArgs, 'ids'>>,
  studyBenefitDelete?: Resolver<Maybe<ResolversTypes['StudyBenefitDeletePayload']>, ParentType, ContextType, RequireFields<MutationStudyBenefitDeleteArgs, 'id'>>,
  studyMissionDelete?: Resolver<Maybe<ResolversTypes['StudyMissionDeletePayload']>, ParentType, ContextType, RequireFields<MutationStudyMissionDeleteArgs, 'id'>>,
  studyMissionDeleteMany?: Resolver<Maybe<ResolversTypes['StudyMissionDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationStudyMissionDeleteManyArgs, 'ids'>>,
  bulkUpdateStudyEventInStudy?: Resolver<Maybe<ResolversTypes['StudyCreatePayload']>, ParentType, ContextType, RequireFields<MutationBulkUpdateStudyEventInStudyArgs, 'studyID' | 'input'>>,
  studyRegister?: Resolver<Maybe<ResolversTypes['UserStudyRegisterPayload']>, ParentType, ContextType, RequireFields<MutationStudyRegisterArgs, 'input'>>,
  provideUserStudyBenefit?: Resolver<Maybe<ResolversTypes['UserStudyBenefitCreatePayload']>, ParentType, ContextType, RequireFields<MutationProvideUserStudyBenefitArgs, 'input'>>,
  userStudyEventsForcePass?: Resolver<Maybe<ResolversTypes['UserStudyEventForcePassPayload']>, ParentType, ContextType, RequireFields<MutationUserStudyEventsForcePassArgs, 'ids'>>,
  userStudyEventsPass?: Resolver<Maybe<ResolversTypes['UserStudyEventPassPayload']>, ParentType, ContextType, RequireFields<MutationUserStudyEventsPassArgs, 'ids'>>,
  userStudyEventsFail?: Resolver<Maybe<ResolversTypes['UserStudyEventFailPayload']>, ParentType, ContextType, RequireFields<MutationUserStudyEventsFailArgs, 'ids'>>,
  userStudyMissionUpdate?: Resolver<Maybe<ResolversTypes['UserStudyMissionUpdatePayload']>, ParentType, ContextType, RequireFields<MutationUserStudyMissionUpdateArgs, 'id' | 'input'>>,
  userStudyMissionsUpdate?: Resolver<Maybe<ResolversTypes['UserStudyMissionsUpdatePayload']>, ParentType, ContextType, RequireFields<MutationUserStudyMissionsUpdateArgs, 'ids'>>,
  userStudyMissionAssignmentLinkUpload?: Resolver<Maybe<ResolversTypes['UserStudyMissionAssignmentLinkUploadPayload']>, ParentType, ContextType, RequireFields<MutationUserStudyMissionAssignmentLinkUploadArgs, 'id' | 'input'>>,
  userStudyMissionAssignmentLinkUploadCommunity?: Resolver<Maybe<ResolversTypes['UserStudyMissionAssignmentLinkUploadPayload']>, ParentType, ContextType, RequireFields<MutationUserStudyMissionAssignmentLinkUploadCommunityArgs, 'id' | 'input'>>,
  userStudyMissionReviewUpload?: Resolver<Maybe<ResolversTypes['UserStudyMissionReviewUploadPayload']>, ParentType, ContextType, RequireFields<MutationUserStudyMissionReviewUploadArgs, 'id' | 'input'>>,
  userStudyMissionReviewUploadCommunity?: Resolver<Maybe<ResolversTypes['UserStudyMissionReviewUploadPayload']>, ParentType, ContextType, RequireFields<MutationUserStudyMissionReviewUploadCommunityArgs, 'id' | 'input'>>,
  userStudyMissionAssignmentFileUpload?: Resolver<Maybe<ResolversTypes['UserStudyMissionAssignmentFileUploadPayload']>, ParentType, ContextType, RequireFields<MutationUserStudyMissionAssignmentFileUploadArgs, 'id' | 'input'>>,
  attachmentPrepareUpload?: Resolver<Maybe<ResolversTypes['AttachmentPrepareUploadPayload']>, ParentType, ContextType, RequireFields<MutationAttachmentPrepareUploadArgs, 'input'>>,
  attachmentCompleteUpload?: Resolver<Maybe<ResolversTypes['AttachmentCompleteUploadPayload']>, ParentType, ContextType, RequireFields<MutationAttachmentCompleteUploadArgs, 'input'>>,
  integratedCommunityPostUpdate?: Resolver<Maybe<ResolversTypes['IntegratedCommunityPostUpdatePayload']>, ParentType, ContextType, RequireFields<MutationIntegratedCommunityPostUpdateArgs, 'id' | 'input'>>,
  userStudyEventUpdateStateWhereEnded?: Resolver<Array<ResolversTypes['UserStudyEvent']>, ParentType, ContextType, MutationUserStudyEventUpdateStateWhereEndedArgs>,
  userStudyUpdatePlaytimesWhereInProgress?: Resolver<Array<ResolversTypes['UserStudy']>, ParentType, ContextType>,
  userStudyMissionDeleteDuplicated?: Resolver<Maybe<Array<Maybe<Array<ResolversTypes['UserStudyMission']>>>>, ParentType, ContextType, RequireFields<MutationUserStudyMissionDeleteDuplicatedArgs, 'studyMissionID'>>,
  teacherCreate?: Resolver<Maybe<ResolversTypes['TeacherCreatePayload']>, ParentType, ContextType, RequireFields<MutationTeacherCreateArgs, 'input'>>,
  teacherUpdate?: Resolver<Maybe<ResolversTypes['TeacherUpdatePayload']>, ParentType, ContextType, RequireFields<MutationTeacherUpdateArgs, 'id' | 'input'>>,
  teacherDelete?: Resolver<Maybe<ResolversTypes['TeacherDeletePayload']>, ParentType, ContextType, RequireFields<MutationTeacherDeleteArgs, 'id'>>,
  teacherCurationCreate?: Resolver<Maybe<ResolversTypes['TeacherCurationCreatePayload']>, ParentType, ContextType, RequireFields<MutationTeacherCurationCreateArgs, 'input'>>,
  teacherCurationUpdate?: Resolver<Maybe<ResolversTypes['TeacherCurationUpdatePayload']>, ParentType, ContextType, RequireFields<MutationTeacherCurationUpdateArgs, 'id' | 'input'>>,
  teacherCurationDelete?: Resolver<Maybe<ResolversTypes['TeacherCurationDeletePayload']>, ParentType, ContextType, RequireFields<MutationTeacherCurationDeleteArgs, 'id'>>,
  teacherCurationDeleteMany?: Resolver<Maybe<ResolversTypes['TeacherCurationDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationTeacherCurationDeleteManyArgs, 'ids'>>,
  teamCreate?: Resolver<Maybe<ResolversTypes['TeamCreatePayload']>, ParentType, ContextType, RequireFields<MutationTeamCreateArgs, 'input'>>,
  teamUpdate?: Resolver<Maybe<ResolversTypes['TeamUpdatePayload']>, ParentType, ContextType, RequireFields<MutationTeamUpdateArgs, 'id' | 'input'>>,
  teamDelete?: Resolver<Maybe<ResolversTypes['TeamDeletePayload']>, ParentType, ContextType, RequireFields<MutationTeamDeleteArgs, 'id'>>,
  teamDeleteMany?: Resolver<Maybe<ResolversTypes['TeamDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationTeamDeleteManyArgs, 'ids'>>,
  membershipCreate?: Resolver<Maybe<ResolversTypes['MembershipCreatePayload']>, ParentType, ContextType, RequireFields<MutationMembershipCreateArgs, 'input'>>,
  membershipUpdate?: Resolver<Maybe<ResolversTypes['MembershipUpdatePayload']>, ParentType, ContextType, RequireFields<MutationMembershipUpdateArgs, 'id' | 'input'>>,
  membershipDelete?: Resolver<Maybe<ResolversTypes['MembershipDeletePayload']>, ParentType, ContextType, RequireFields<MutationMembershipDeleteArgs, 'id'>>,
  membershipDeleteMany?: Resolver<Maybe<ResolversTypes['MembershipDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationMembershipDeleteManyArgs, 'ids'>>,
  login?: Resolver<Maybe<ResolversTypes['LoginPayload']>, ParentType, ContextType, RequireFields<MutationLoginArgs, 'username' | 'password'>>,
  logout?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  tempAccountSignup?: Resolver<Maybe<ResolversTypes['TempAccountSignupPayload']>, ParentType, ContextType, MutationTempAccountSignupArgs>,
  tempAccountLogout?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  tempAccountLinkToUser?: Resolver<Maybe<ResolversTypes['TempAccountLinkToUserPayload']>, ParentType, ContextType>,
  phoneSendMessageForFindID?: Resolver<Maybe<ResolversTypes['phoneSendMessageForFindIDPayload']>, ParentType, ContextType, RequireFields<MutationPhoneSendMessageForFindIdArgs, 'phoneNumber' | 'name'>>,
  phoneSendMessageForFindPW?: Resolver<Maybe<ResolversTypes['phoneSendMessageForFindPWPayload']>, ParentType, ContextType, RequireFields<MutationPhoneSendMessageForFindPwArgs, 'phoneNumber' | 'name' | 'username'>>,
  emailSendMessageForFindID?: Resolver<Maybe<ResolversTypes['emailSendMessageForFindIDPayload']>, ParentType, ContextType, RequireFields<MutationEmailSendMessageForFindIdArgs, 'email' | 'name'>>,
  emailSendMessageForFindPW?: Resolver<Maybe<ResolversTypes['emailSendMessageForFindPWPayload']>, ParentType, ContextType, RequireFields<MutationEmailSendMessageForFindPwArgs, 'email' | 'name' | 'username'>>,
  findIDByPhoneNumber?: Resolver<Maybe<ResolversTypes['findIDByPhoneNumberPayload']>, ParentType, ContextType, RequireFields<MutationFindIdByPhoneNumberArgs, 'phoneNumber' | 'name' | 'code'>>,
  findPWByPhoneNumber?: Resolver<Maybe<ResolversTypes['findPWByPhoneNumberPayload']>, ParentType, ContextType, RequireFields<MutationFindPwByPhoneNumberArgs, 'phoneNumber' | 'name' | 'username' | 'code'>>,
  updatePW?: Resolver<Maybe<ResolversTypes['UpdatePWPayload']>, ParentType, ContextType, RequireFields<MutationUpdatePwArgs, 'currentPW' | 'newPW' | 'checkNewPW'>>,
  updatePWByUsername?: Resolver<Maybe<ResolversTypes['UpdatePWByUsernamePayload']>, ParentType, ContextType, RequireFields<MutationUpdatePwByUsernameArgs, 'recoveryPWAuthID' | 'recoveryPWAuthKey' | 'username' | 'newPW' | 'checkNewPW'>>,
  updateUserInfo?: Resolver<Maybe<ResolversTypes['UserInfoUpdatePayload']>, ParentType, ContextType, RequireFields<MutationUpdateUserInfoArgs, 'id' | 'input'>>,
  adminUpdateUserInfo?: Resolver<Maybe<ResolversTypes['UserInfoUpdatePayload']>, ParentType, ContextType, RequireFields<MutationAdminUpdateUserInfoArgs, 'id' | 'input'>>,
  activateUser?: Resolver<ResolversTypes['ActiveUserPayload'], ParentType, ContextType, RequireFields<MutationActivateUserArgs, 'token'>>,
  userDeviceDelete?: Resolver<Maybe<ResolversTypes['UserDeviceDeletePayload']>, ParentType, ContextType, RequireFields<MutationUserDeviceDeleteArgs, 'userID' | 'deviceID'>>,
  userPointCreate?: Resolver<Maybe<ResolversTypes['UserPointCreatePayload']>, ParentType, ContextType, RequireFields<MutationUserPointCreateArgs, 'input'>>,
  userPointUpdate?: Resolver<Maybe<ResolversTypes['UserPointUpdatePayload']>, ParentType, ContextType, RequireFields<MutationUserPointUpdateArgs, 'id' | 'input'>>,
  userPointDelete?: Resolver<Maybe<ResolversTypes['UserPointDeletePayload']>, ParentType, ContextType, RequireFields<MutationUserPointDeleteArgs, 'id'>>,
  userPointDeleteMany?: Resolver<Maybe<ResolversTypes['UserPointDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationUserPointDeleteManyArgs, 'ids'>>,
  userRegisterStatDailyUpdate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, MutationUserRegisterStatDailyUpdateArgs>,
  videoUpdate?: Resolver<ResolversTypes['VideoUpdatePayload'], ParentType, ContextType, RequireFields<MutationVideoUpdateArgs, 'id'>>,
  videoLessonPlay?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationVideoLessonPlayArgs, 'videoID' | 'lessonNumber' | 'isMobile'>>,
  videoProductCreate?: Resolver<Maybe<ResolversTypes['VideoProductCreatePayload']>, ParentType, ContextType, RequireFields<MutationVideoProductCreateArgs, 'input'>>,
  videoProductUpdate?: Resolver<Maybe<ResolversTypes['VideoProductUpdatePayload']>, ParentType, ContextType, RequireFields<MutationVideoProductUpdateArgs, 'id' | 'input'>>,
  videoProductDelete?: Resolver<Maybe<ResolversTypes['VideoProductDeletePayload']>, ParentType, ContextType, RequireFields<MutationVideoProductDeleteArgs, 'id'>>,
  videoProductDeleteMany?: Resolver<Maybe<ResolversTypes['VideoProductDeleteManyPayload']>, ParentType, ContextType, RequireFields<MutationVideoProductDeleteManyArgs, 'ids'>>,
  passwordCheck?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationPasswordCheckArgs, 'password'>>,
  messageSendToPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationMessageSendToPhoneArgs, 'phoneNumber'>>,
  messageSendToCurrentUserPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationMessageSendToCurrentUserPhoneArgs, 'phoneNumber'>>,
  phoneCodeAuthenticate?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationPhoneCodeAuthenticateArgs, 'phoneNumber' | 'code'>>,
  leave?: Resolver<ResolversTypes['UserLeavePayload'], ParentType, ContextType, RequireFields<MutationLeaveArgs, 'reason'>>,
  deleteUser?: Resolver<ResolversTypes['UserLeavePayload'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'userID' | 'reason'>>,
  activateDormantUser?: Resolver<ResolversTypes['ActivateDormantUserPayload'], ParentType, ContextType, RequireFields<MutationActivateDormantUserArgs, 'token'>>,
  supportTicketCreate?: Resolver<Maybe<ResolversTypes['SupportTicketCreatePayload']>, ParentType, ContextType, RequireFields<MutationSupportTicketCreateArgs, 'input'>>,
  supportTicketUpdate?: Resolver<Maybe<ResolversTypes['SupportTicketUpdatePayload']>, ParentType, ContextType, RequireFields<MutationSupportTicketUpdateArgs, 'id' | 'input'>>,
  supportTicketAnswerTemplateCreate?: Resolver<Maybe<ResolversTypes['SupportTicketAnswerTemplateCreatePayload']>, ParentType, ContextType, RequireFields<MutationSupportTicketAnswerTemplateCreateArgs, 'input'>>,
  supportTicketAnswerTemplateUpdate?: Resolver<Maybe<ResolversTypes['SupportTicketAnswerTemplateUpdatePayload']>, ParentType, ContextType, RequireFields<MutationSupportTicketAnswerTemplateUpdateArgs, 'id' | 'input'>>,
  supportTicketCategoryCreate?: Resolver<Maybe<ResolversTypes['SupportTicketCategoryCreatePayload']>, ParentType, ContextType, RequireFields<MutationSupportTicketCategoryCreateArgs, 'input'>>,
  supportTicketCategoryUpdate?: Resolver<Maybe<ResolversTypes['SupportTicketCategoryUpdatePayload']>, ParentType, ContextType, RequireFields<MutationSupportTicketCategoryUpdateArgs, 'id' | 'input'>>,
  supportTicketAdminCreate?: Resolver<Maybe<ResolversTypes['SupportTicketAdminCreatePayload']>, ParentType, ContextType, RequireFields<MutationSupportTicketAdminCreateArgs, 'input'>>,
  supportTicketAdminUpdate?: Resolver<Maybe<ResolversTypes['SupportTicketAdminUpdatePayload']>, ParentType, ContextType, RequireFields<MutationSupportTicketAdminUpdateArgs, 'id' | 'input'>>,
  validateUsername?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationValidateUsernameArgs, 'userName'>>,
  sendAuthSMS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSendAuthSmsArgs, 'phone'>>,
  verifyAuthSMS?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationVerifyAuthSmsArgs, 'input'>>,
  nativeRegister?: Resolver<Maybe<ResolversTypes['NativeRegisterPayload']>, ParentType, ContextType, RequireFields<MutationNativeRegisterArgs, 'input'>>,
  snsRegister?: Resolver<Maybe<ResolversTypes['SNSRegisterPayload']>, ParentType, ContextType, RequireFields<MutationSnsRegisterArgs, 'input'>>,
  allianceRegister?: Resolver<Maybe<ResolversTypes['AllianceRegisterPayload']>, ParentType, ContextType, RequireFields<MutationAllianceRegisterArgs, 'input'>>,
};

export type NativeRegisterPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['NativeRegisterPayload'] = ResolversParentTypes['NativeRegisterPayload']> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
};

export type NotificationSmsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationSMS'] = ResolversParentTypes['NotificationSMS']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  innerText?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type NotificationSmsSetResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationSMSSet'] = ResolversParentTypes['NotificationSMSSet']> = {
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  classroom?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  notificationSMS?: Resolver<Array<ResolversTypes['NotificationSMS']>, ParentType, ContextType>,
};

export type OfflineResolvers<ContextType = any, ParentType extends ResolversParentTypes['Offline'] = ResolversParentTypes['Offline']> = {
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  descriptionHTML?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  cautionHTML?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  offlineRepurchaseDiscounts?: Resolver<Array<ResolversTypes['OfflineRepurchaseDiscount']>, ParentType, ContextType>,
  contentPurchases?: Resolver<Array<ResolversTypes['ContentPurchase']>, ParentType, ContextType>,
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  shortDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  usePayback?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  isRecruitAllTime?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  coursePeriod?: Resolver<Maybe<Array<ResolversTypes['Date']>>, ParentType, ContextType>,
  courseStartDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  courseEndDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  weekday?: Resolver<Maybe<Array<ResolversTypes['Weekday']>>, ParentType, ContextType>,
  classStartTime?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>,
  classEndTime?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>,
  useMessage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  notificationSMSSet?: Resolver<ResolversTypes['NotificationSMSSet'], ParentType, ContextType>,
  surveyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  isOfflineConsult?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  children?: Resolver<Array<ResolversTypes['Offline']>, ParentType, ContextType>,
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  defaultTeacherForImage?: Resolver<Maybe<ResolversTypes['Teacher']>, ParentType, ContextType>,
  tags?: Resolver<Array<ResolversTypes['OfflineTag']>, ParentType, ContextType>,
};

export type OfflineChildrenContentsUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['OfflineChildrenContentsUpdatePayload'] = ResolversParentTypes['OfflineChildrenContentsUpdatePayload']> = {
  offlineChildrenContents?: Resolver<Maybe<Array<ResolversTypes['Offline']>>, ParentType, ContextType>,
};

export type OfflineConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OfflineConnection'] = ResolversParentTypes['OfflineConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Offline']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type OfflineCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['OfflineCreatePayload'] = ResolversParentTypes['OfflineCreatePayload']> = {
  offline?: Resolver<Maybe<ResolversTypes['Offline']>, ParentType, ContextType>,
};

export type OfflineRepurchaseDiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['OfflineRepurchaseDiscount'] = ResolversParentTypes['OfflineRepurchaseDiscount']> = {
  discountValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType, OfflineRepurchaseDiscountDiscountValueArgs>,
  discountType?: Resolver<ResolversTypes['DiscountType'], ParentType, ContextType>,
  targetOffline?: Resolver<ResolversTypes['Offline'], ParentType, ContextType>,
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
};

export type OfflineRepurchaseDiscountUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['OfflineRepurchaseDiscountUpdatePayload'] = ResolversParentTypes['OfflineRepurchaseDiscountUpdatePayload']> = {
  offlineRepurchaseDiscounts?: Resolver<Maybe<Array<ResolversTypes['OfflineRepurchaseDiscount']>>, ParentType, ContextType>,
};

export type OfflineSendDailyMessagePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['offlineSendDailyMessagePayload'] = ResolversParentTypes['offlineSendDailyMessagePayload']> = {
  offline?: Resolver<ResolversTypes['Offline'], ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  succeedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  failedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  succeedResults?: Resolver<Array<ResolversTypes['InfobankSMSSendResult']>, ParentType, ContextType>,
  failedResults?: Resolver<Array<ResolversTypes['InfobankSMSSendResult']>, ParentType, ContextType>,
};

export type OfflineStudentClassChangePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['OfflineStudentClassChangePayload'] = ResolversParentTypes['OfflineStudentClassChangePayload']> = {
  contentPurchase?: Resolver<Maybe<ResolversTypes['ContentPurchase']>, ParentType, ContextType>,
};

export type OfflineUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['OfflineUpdatePayload'] = ResolversParentTypes['OfflineUpdatePayload']> = {
  offline?: Resolver<Maybe<ResolversTypes['Offline']>, ParentType, ContextType>,
};

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = {
  contentPurchase?: Resolver<Maybe<Array<ResolversTypes['ContentPurchase']>>, ParentType, ContextType>,
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  device?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  phoneNumber?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>,
  status?: Resolver<ResolversTypes['OrderStatus'], ParentType, ContextType>,
  tempAccountID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
  type?: Resolver<Maybe<ResolversTypes['OrderType']>, ParentType, ContextType>,
  userID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
  isTestOrder?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  payment?: Resolver<Maybe<Array<ResolversTypes['Payment']>>, ParentType, ContextType>,
  refund?: Resolver<Maybe<ResolversTypes['Refund']>, ParentType, ContextType>,
  usedCouponNumber?: Resolver<Maybe<ResolversTypes['CouponNumber']>, ParentType, ContextType>,
};

export type OrderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderConnection'] = ResolversParentTypes['OrderConnection']> = {
  nodes?: Resolver<Array<Maybe<ResolversTypes['Order']>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type PackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Package'] = ResolversParentTypes['Package']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  contents?: Resolver<Maybe<ResolversTypes['ContentConnection']>, ParentType, ContextType, PackageContentsArgs>,
};

export type PackageConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackageConnection'] = ResolversParentTypes['PackageConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Package']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type PaybackSetResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaybackSet'] = ResolversParentTypes['PaybackSet']> = {
  usePaybackIfAttendance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  usePaybackIfAchieveScore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  usePeriodExtension?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  minAttendDayForPayback?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  minPlaytimeForAttendance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  scoreLimitForPayback?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
};

export type PaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  paymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>,
  transactionID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  state?: Resolver<Maybe<ResolversTypes['PaymentState']>, ParentType, ContextType>,
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>,
  etag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  isTestPayment?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  refund?: Resolver<Maybe<Array<Maybe<ResolversTypes['Refund']>>>, ParentType, ContextType>,
};

export type PaymentConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentConnection'] = ResolversParentTypes['PaymentConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type PermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Permission'] = ResolversParentTypes['Permission']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
  scope?: Resolver<ResolversTypes['PermissionScope'], ParentType, ContextType>,
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>,
  grantedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
};

export type PermissionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionConnection'] = ResolversParentTypes['PermissionConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type PermissionGrantPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionGrantPayload'] = ResolversParentTypes['PermissionGrantPayload']> = {
  permission?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>,
};

export type PermissionRevokePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionRevokePayload'] = ResolversParentTypes['PermissionRevokePayload']> = {
  permission?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>,
};

export interface PhoneNumberScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PhoneNumber'], any> {
  name: 'PhoneNumber'
}

export type PhoneSendMessageForFindIdPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['phoneSendMessageForFindIDPayload'] = ResolversParentTypes['phoneSendMessageForFindIDPayload']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type PhoneSendMessageForFindPwPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['phoneSendMessageForFindPWPayload'] = ResolversParentTypes['phoneSendMessageForFindPWPayload']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type PostResolvers<ContextType = any, ParentType extends ResolversParentTypes['Post'] = ResolversParentTypes['Post']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  board?: Resolver<Maybe<ResolversTypes['Board']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type PostCurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostCuration'] = ResolversParentTypes['PostCuration']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  maxPostLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  boardURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  configs?: Resolver<Array<ResolversTypes['PostCurationConfig']>, ParentType, ContextType>,
  posts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Post']>>>, ParentType, ContextType>,
  boards?: Resolver<Maybe<Array<Maybe<ResolversTypes['Board']>>>, ParentType, ContextType>,
};

export type PostCurationConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostCurationConfig'] = ResolversParentTypes['PostCurationConfig']> = {
  isAuto?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  postID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
  orderBy?: Resolver<Maybe<ResolversTypes['OrderBy']>, ParentType, ContextType>,
  sourceBoardID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
};

export type PostCurationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostCurationConnection'] = ResolversParentTypes['PostCurationConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['PostCuration']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type PostCurationUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostCurationUpdatePayload'] = ResolversParentTypes['PostCurationUpdatePayload']> = {
  postCuration?: Resolver<Maybe<ResolversTypes['PostCuration']>, ParentType, ContextType>,
};

export type PrevScoreForPassResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrevScoreForPass'] = ResolversParentTypes['PrevScoreForPass']> = {
  seasonName?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type PrintLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrintLog'] = ResolversParentTypes['PrintLog']> = {
  printedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  printInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type ProvideCampaignBenefitPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProvideCampaignBenefitPayload'] = ResolversParentTypes['ProvideCampaignBenefitPayload']> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  provideResults?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProvideResultProps']>>>, ParentType, ContextType>,
};

export type ProvideFreeContentForUsersPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['provideFreeContentForUsersPayload'] = ResolversParentTypes['provideFreeContentForUsersPayload']> = {
  successCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  failureCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  failedUsers?: Resolver<Array<ResolversTypes['FailedUser']>, ParentType, ContextType>,
  results?: Resolver<Array<ResolversTypes['ProvideFreeContentPayload']>, ParentType, ContextType>,
};

export type ProvideFreeContentPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProvideFreeContentPayload'] = ResolversParentTypes['ProvideFreeContentPayload']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  contentPurchase?: Resolver<Maybe<ResolversTypes['ContentPurchase']>, ParentType, ContextType>,
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type ProvideResultPropsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProvideResultProps'] = ResolversParentTypes['ProvideResultProps']> = {
  benefit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  adminBlogPosts?: Resolver<ResolversTypes['AdminBlogPostConnection'], ParentType, ContextType, QueryAdminBlogPostsArgs>,
  alliance?: Resolver<Maybe<ResolversTypes['Alliance']>, ParentType, ContextType, RequireFields<QueryAllianceArgs, 'id'>>,
  alliances?: Resolver<ResolversTypes['AllianceConnection'], ParentType, ContextType, QueryAlliancesArgs>,
  allianceByUserID?: Resolver<Maybe<Array<Maybe<ResolversTypes['Alliance']>>>, ParentType, ContextType, QueryAllianceByUserIdArgs>,
  allianceByCookie?: Resolver<Maybe<ResolversTypes['Alliance']>, ParentType, ContextType>,
  allianceUser?: Resolver<Maybe<ResolversTypes['AllianceUser']>, ParentType, ContextType, RequireFields<QueryAllianceUserArgs, 'id'>>,
  allianceUsers?: Resolver<ResolversTypes['AllianceUserConnection'], ParentType, ContextType, QueryAllianceUsersArgs>,
  banner?: Resolver<Maybe<ResolversTypes['Banner']>, ParentType, ContextType, RequireFields<QueryBannerArgs, 'id'>>,
  banners?: Resolver<ResolversTypes['BannerConnection'], ParentType, ContextType, QueryBannersArgs>,
  bannersByID?: Resolver<Array<ResolversTypes['Banner']>, ParentType, ContextType, RequireFields<QueryBannersByIdArgs, 'ids'>>,
  bannerByCode?: Resolver<Maybe<ResolversTypes['BannerGroup']>, ParentType, ContextType, RequireFields<QueryBannerByCodeArgs, 'codeName'>>,
  bannerGroup?: Resolver<Maybe<ResolversTypes['BannerGroup']>, ParentType, ContextType, RequireFields<QueryBannerGroupArgs, 'id'>>,
  bannerGroups?: Resolver<ResolversTypes['BannerGroupConnection'], ParentType, ContextType, QueryBannerGroupsArgs>,
  bannerGroupsByID?: Resolver<Array<ResolversTypes['BannerGroup']>, ParentType, ContextType, RequireFields<QueryBannerGroupsByIdArgs, 'ids'>>,
  book?: Resolver<Maybe<ResolversTypes['Book']>, ParentType, ContextType, RequireFields<QueryBookArgs, 'id'>>,
  books?: Resolver<ResolversTypes['BookConnection'], ParentType, ContextType, QueryBooksArgs>,
  booksByID?: Resolver<Array<ResolversTypes['Book']>, ParentType, ContextType, RequireFields<QueryBooksByIdArgs, 'ids'>>,
  campaign?: Resolver<Maybe<ResolversTypes['Campaign']>, ParentType, ContextType, RequireFields<QueryCampaignArgs, 'id'>>,
  campaigns?: Resolver<Maybe<ResolversTypes['CampaignConnection']>, ParentType, ContextType, QueryCampaignsArgs>,
  campaignsForDataExtract?: Resolver<Maybe<ResolversTypes['CampaignConnectionForDataExtract']>, ParentType, ContextType, RequireFields<QueryCampaignsForDataExtractArgs, 'filterBy'>>,
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<QueryCompanyArgs, 'id'>>,
  companies?: Resolver<Maybe<ResolversTypes['CompanyConnection']>, ParentType, ContextType, QueryCompaniesArgs>,
  companiesByID?: Resolver<Array<Maybe<ResolversTypes['Company']>>, ParentType, ContextType, RequireFields<QueryCompaniesByIdArgs, 'ids'>>,
  content?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType, RequireFields<QueryContentArgs, 'id'>>,
  contents?: Resolver<Maybe<ResolversTypes['ContentConnection']>, ParentType, ContextType, QueryContentsArgs>,
  contentsByID?: Resolver<Array<ResolversTypes['Content']>, ParentType, ContextType, RequireFields<QueryContentsByIdArgs, 'ids'>>,
  contentsByStudyEventID?: Resolver<Array<ResolversTypes['Content']>, ParentType, ContextType, RequireFields<QueryContentsByStudyEventIdArgs, 'studyEventID' | 'missionType'>>,
  contentsOnSale?: Resolver<Maybe<Array<ResolversTypes['Content']>>, ParentType, ContextType, RequireFields<QueryContentsOnSaleArgs, 'type'>>,
  contentWithValidate?: Resolver<Maybe<ResolversTypes['Content']>, ParentType, ContextType, RequireFields<QueryContentWithValidateArgs, 'id'>>,
  contentCategoryList?: Resolver<Array<ResolversTypes['ContentCategoryParent']>, ParentType, ContextType, RequireFields<QueryContentCategoryListArgs, 'contentType' | 'service'>>,
  contentCuration?: Resolver<Maybe<ResolversTypes['ContentCuration']>, ParentType, ContextType, RequireFields<QueryContentCurationArgs, 'id'>>,
  contentCurationByCode?: Resolver<Maybe<ResolversTypes['ContentCuration']>, ParentType, ContextType, RequireFields<QueryContentCurationByCodeArgs, 'code'>>,
  contentCurations?: Resolver<ResolversTypes['ContentCurationConnection'], ParentType, ContextType, QueryContentCurationsArgs>,
  coupon?: Resolver<Maybe<ResolversTypes['Coupon']>, ParentType, ContextType, RequireFields<QueryCouponArgs, 'id'>>,
  coupons?: Resolver<ResolversTypes['CouponConnection'], ParentType, ContextType, QueryCouponsArgs>,
  couponNumber?: Resolver<Maybe<ResolversTypes['CouponNumber']>, ParentType, ContextType, RequireFields<QueryCouponNumberArgs, 'number'>>,
  document?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<QueryDocumentArgs, 'id'>>,
  documents?: Resolver<ResolversTypes['DocumentConnection'], ParentType, ContextType, QueryDocumentsArgs>,
  documentProduct?: Resolver<Maybe<ResolversTypes['DocumentProduct']>, ParentType, ContextType, RequireFields<QueryDocumentProductArgs, 'id'>>,
  documentProducts?: Resolver<ResolversTypes['DocumentProductConnection'], ParentType, ContextType, QueryDocumentProductsArgs>,
  contentPurchase?: Resolver<Maybe<ResolversTypes['ContentPurchase']>, ParentType, ContextType, RequireFields<QueryContentPurchaseArgs, 'id'>>,
  contentPurchases?: Resolver<ResolversTypes['ContentPurchaseConnection'], ParentType, ContextType, QueryContentPurchasesArgs>,
  contentPurchaseRankGroup?: Resolver<ResolversTypes['ContentPurchaseRankGroupConnection'], ParentType, ContextType, RequireFields<QueryContentPurchaseRankGroupArgs, 'filterBy' | 'orderBy'>>,
  contentPurchaseStats?: Resolver<ResolversTypes['ContentPurchaseStatConnection'], ParentType, ContextType, RequireFields<QueryContentPurchaseStatsArgs, 'filterBy'>>,
  contentRefundStats?: Resolver<ResolversTypes['ContentRefundStatConnection'], ParentType, ContextType, RequireFields<QueryContentRefundStatsArgs, 'filterBy'>>,
  loginPageURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  myPageURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  exam?: Resolver<Maybe<ResolversTypes['Exam']>, ParentType, ContextType, RequireFields<QueryExamArgs, 'id'>>,
  exams?: Resolver<ResolversTypes['ExamConnection'], ParentType, ContextType, QueryExamsArgs>,
  examsByID?: Resolver<Array<Maybe<ResolversTypes['Exam']>>, ParentType, ContextType, RequireFields<QueryExamsByIdArgs, 'ids'>>,
  examSection?: Resolver<Maybe<ResolversTypes['ExamSection']>, ParentType, ContextType, RequireFields<QueryExamSectionArgs, 'id'>>,
  examSections?: Resolver<ResolversTypes['ExamSectionConnection'], ParentType, ContextType, QueryExamSectionsArgs>,
  examQuestion?: Resolver<Maybe<ResolversTypes['ExamQuestion']>, ParentType, ContextType, RequireFields<QueryExamQuestionArgs, 'id'>>,
  examQuestions?: Resolver<ResolversTypes['ExamQuestionConnection'], ParentType, ContextType, QueryExamQuestionsArgs>,
  examQuestionType?: Resolver<Maybe<ResolversTypes['ExamQuestionType']>, ParentType, ContextType, RequireFields<QueryExamQuestionTypeArgs, 'id'>>,
  examQuestionTypes?: Resolver<ResolversTypes['ExamQuestionTypeConnection'], ParentType, ContextType, QueryExamQuestionTypesArgs>,
  examQuestionTypesByID?: Resolver<Array<Maybe<ResolversTypes['ExamQuestionType']>>, ParentType, ContextType, RequireFields<QueryExamQuestionTypesByIdArgs, 'ids'>>,
  examAttempt?: Resolver<Maybe<ResolversTypes['ExamAttempt']>, ParentType, ContextType, RequireFields<QueryExamAttemptArgs, 'id'>>,
  examAttempts?: Resolver<Maybe<ResolversTypes['ExamAttemptConnection']>, ParentType, ContextType, QueryExamAttemptsArgs>,
  getFile?: Resolver<ResolversTypes['File'], ParentType, ContextType, RequireFields<QueryGetFileArgs, 'id'>>,
  freepass?: Resolver<Maybe<ResolversTypes['Freepass']>, ParentType, ContextType, RequireFields<QueryFreepassArgs, 'id'>>,
  freepassProduct?: Resolver<Maybe<ResolversTypes['FreepassProduct']>, ParentType, ContextType, RequireFields<QueryFreepassProductArgs, 'id'>>,
  freepassProducts?: Resolver<ResolversTypes['FreepassProductConnection'], ParentType, ContextType, QueryFreepassProductsArgs>,
  htmlText?: Resolver<Maybe<ResolversTypes['HtmlText']>, ParentType, ContextType, RequireFields<QueryHtmlTextArgs, 'id'>>,
  htmlTexts?: Resolver<ResolversTypes['HtmlTextConnection'], ParentType, ContextType, QueryHtmlTextsArgs>,
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QueryInvoiceArgs, 'id'>>,
  invoices?: Resolver<ResolversTypes['InvoiceConnection'], ParentType, ContextType, QueryInvoicesArgs>,
  invoicesByID?: Resolver<Array<Maybe<ResolversTypes['Invoice']>>, ParentType, ContextType, RequireFields<QueryInvoicesByIdArgs, 'ids'>>,
  major?: Resolver<Maybe<ResolversTypes['Major']>, ParentType, ContextType, RequireFields<QueryMajorArgs, 'id'>>,
  majors?: Resolver<ResolversTypes['MajorConnection'], ParentType, ContextType, QueryMajorsArgs>,
  menu?: Resolver<Maybe<ResolversTypes['Menu']>, ParentType, ContextType, RequireFields<QueryMenuArgs, 'id'>>,
  menuByCode?: Resolver<Maybe<ResolversTypes['Menu']>, ParentType, ContextType, RequireFields<QueryMenuByCodeArgs, 'code'>>,
  menus?: Resolver<ResolversTypes['MenuConnection'], ParentType, ContextType, QueryMenusArgs>,
  menusByIDs?: Resolver<Array<Maybe<ResolversTypes['Menu']>>, ParentType, ContextType, RequireFields<QueryMenusByIDsArgs, 'ids'>>,
  offline?: Resolver<Maybe<ResolversTypes['Offline']>, ParentType, ContextType, RequireFields<QueryOfflineArgs, 'id'>>,
  offlines?: Resolver<ResolversTypes['OfflineConnection'], ParentType, ContextType, QueryOfflinesArgs>,
  offlineSendDailyMessage?: Resolver<Array<ResolversTypes['offlineSendDailyMessagePayload']>, ParentType, ContextType>,
  offlineByIDs?: Resolver<Array<ResolversTypes['Offline']>, ParentType, ContextType, RequireFields<QueryOfflineByIDsArgs, 'ids'>>,
  offlineByContentID?: Resolver<Maybe<ResolversTypes['Offline']>, ParentType, ContextType, RequireFields<QueryOfflineByContentIdArgs, 'contentID'>>,
  offlinesByContentIDs?: Resolver<Array<ResolversTypes['Offline']>, ParentType, ContextType, RequireFields<QueryOfflinesByContentIDsArgs, 'contentIDs'>>,
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<QueryOrderArgs, 'id'>>,
  orders?: Resolver<Maybe<ResolversTypes['OrderConnection']>, ParentType, ContextType, QueryOrdersArgs>,
  package?: Resolver<Maybe<ResolversTypes['Package']>, ParentType, ContextType, RequireFields<QueryPackageArgs, 'id'>>,
  packages?: Resolver<ResolversTypes['PackageConnection'], ParentType, ContextType, QueryPackagesArgs>,
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<QueryPaymentArgs, 'id'>>,
  payments?: Resolver<Maybe<ResolversTypes['PaymentConnection']>, ParentType, ContextType, QueryPaymentsArgs>,
  paymentState?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<QueryPaymentStateArgs, 'id'>>,
  permission?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType, RequireFields<QueryPermissionArgs, 'id'>>,
  permissions?: Resolver<ResolversTypes['PermissionConnection'], ParentType, ContextType, QueryPermissionsArgs>,
  permissionsByID?: Resolver<Array<Maybe<ResolversTypes['Permission']>>, ParentType, ContextType, RequireFields<QueryPermissionsByIdArgs, 'ids'>>,
  permissionsByCurrentUser?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>,
  isCurrentUserAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, QueryIsCurrentUserAllowedArgs>,
  postCuration?: Resolver<Maybe<ResolversTypes['PostCuration']>, ParentType, ContextType, RequireFields<QueryPostCurationArgs, 'id'>>,
  postCurations?: Resolver<ResolversTypes['PostCurationConnection'], ParentType, ContextType, QueryPostCurationsArgs>,
  postCurationsByID?: Resolver<Array<Maybe<ResolversTypes['PostCuration']>>, ParentType, ContextType, RequireFields<QueryPostCurationsByIdArgs, 'ids'>>,
  refund?: Resolver<Maybe<ResolversTypes['Refund']>, ParentType, ContextType, RequireFields<QueryRefundArgs, 'id'>>,
  refunds?: Resolver<Maybe<ResolversTypes['RefundConnection']>, ParentType, ContextType, QueryRefundsArgs>,
  resumeProduct?: Resolver<Maybe<ResolversTypes['ResumeProduct']>, ParentType, ContextType, RequireFields<QueryResumeProductArgs, 'id'>>,
  resumeProducts?: Resolver<ResolversTypes['ResumeProductConnection'], ParentType, ContextType, QueryResumeProductsArgs>,
  resume?: Resolver<Maybe<ResolversTypes['Resume']>, ParentType, ContextType, RequireFields<QueryResumeArgs, 'id'>>,
  resumes?: Resolver<ResolversTypes['ResumeConnection'], ParentType, ContextType, QueryResumesArgs>,
  review?: Resolver<Maybe<ResolversTypes['Review']>, ParentType, ContextType, RequireFields<QueryReviewArgs, 'id'>>,
  reviews?: Resolver<ResolversTypes['ReviewConnection'], ParentType, ContextType, QueryReviewsArgs>,
  schedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType, RequireFields<QueryScheduleArgs, 'id'>>,
  schedules?: Resolver<ResolversTypes['ScheduleConnection'], ParentType, ContextType, QuerySchedulesArgs>,
  schedulesByID?: Resolver<Array<Maybe<ResolversTypes['Schedule']>>, ParentType, ContextType, RequireFields<QuerySchedulesByIdArgs, 'ids'>>,
  scheduleEvent?: Resolver<Maybe<ResolversTypes['ScheduleEvent']>, ParentType, ContextType, RequireFields<QueryScheduleEventArgs, 'id'>>,
  scheduleEvents?: Resolver<ResolversTypes['ScheduleEventConnection'], ParentType, ContextType, QueryScheduleEventsArgs>,
  school?: Resolver<Maybe<ResolversTypes['School']>, ParentType, ContextType, RequireFields<QuerySchoolArgs, 'id'>>,
  schools?: Resolver<ResolversTypes['SchoolConnection'], ParentType, ContextType, QuerySchoolsArgs>,
  userSchools?: Resolver<ResolversTypes['UserSchoolConnection'], ParentType, ContextType, QueryUserSchoolsArgs>,
  majorFromUserSchools?: Resolver<ResolversTypes['UserSchoolMajorConnection'], ParentType, ContextType, QueryMajorFromUserSchoolsArgs>,
  smsReject?: Resolver<Maybe<ResolversTypes['SmsReject']>, ParentType, ContextType, RequireFields<QuerySmsRejectArgs, 'phoneNumber'>>,
  study?: Resolver<Maybe<ResolversTypes['Study']>, ParentType, ContextType, RequireFields<QueryStudyArgs, 'id'>>,
  studies?: Resolver<ResolversTypes['StudyConnection'], ParentType, ContextType, QueryStudiesArgs>,
  studyByIDs?: Resolver<Array<Maybe<ResolversTypes['Study']>>, ParentType, ContextType, RequireFields<QueryStudyByIDsArgs, 'ids'>>,
  openedStudies?: Resolver<Array<ResolversTypes['Study']>, ParentType, ContextType, QueryOpenedStudiesArgs>,
  closedStudies?: Resolver<Array<ResolversTypes['Study']>, ParentType, ContextType, RequireFields<QueryClosedStudiesArgs, 'count'>>,
  studyEvent?: Resolver<Maybe<ResolversTypes['StudyEvent']>, ParentType, ContextType, RequireFields<QueryStudyEventArgs, 'id'>>,
  studyEventsByIDs?: Resolver<Array<Maybe<ResolversTypes['StudyEvent']>>, ParentType, ContextType, RequireFields<QueryStudyEventsByIDsArgs, 'ids'>>,
  studyEvents?: Resolver<ResolversTypes['StudyEventConnection'], ParentType, ContextType, QueryStudyEventsArgs>,
  studyMission?: Resolver<Maybe<ResolversTypes['StudyMission']>, ParentType, ContextType, RequireFields<QueryStudyMissionArgs, 'id'>>,
  studyMissions?: Resolver<ResolversTypes['StudyMissionConnection'], ParentType, ContextType, QueryStudyMissionsArgs>,
  userStudy?: Resolver<Maybe<ResolversTypes['UserStudy']>, ParentType, ContextType, RequireFields<QueryUserStudyArgs, 'id'>>,
  userStudiesByUserID?: Resolver<Maybe<Array<ResolversTypes['UserStudy']>>, ParentType, ContextType, RequireFields<QueryUserStudiesByUserIdArgs, 'userID'>>,
  userStudiesOfCurrentUser?: Resolver<Maybe<Array<ResolversTypes['UserStudy']>>, ParentType, ContextType>,
  userStudyEvent?: Resolver<Maybe<ResolversTypes['UserStudyEvent']>, ParentType, ContextType, RequireFields<QueryUserStudyEventArgs, 'id'>>,
  userStudyEvents?: Resolver<Maybe<ResolversTypes['UserStudyEventConnection']>, ParentType, ContextType, QueryUserStudyEventsArgs>,
  userStudyMission?: Resolver<Maybe<ResolversTypes['UserStudyMission']>, ParentType, ContextType, RequireFields<QueryUserStudyMissionArgs, 'id'>>,
  userStudies?: Resolver<ResolversTypes['UserStudyConnection'], ParentType, ContextType, QueryUserStudiesArgs>,
  userStudyMissions?: Resolver<ResolversTypes['UserStudyMissionConnection'], ParentType, ContextType, QueryUserStudyMissionsArgs>,
  integratedCommunityBoards?: Resolver<ResolversTypes['IntegratedCommunityBoardPayload'], ParentType, ContextType, QueryIntegratedCommunityBoardsArgs>,
  teacher?: Resolver<Maybe<ResolversTypes['Teacher']>, ParentType, ContextType, RequireFields<QueryTeacherArgs, 'id'>>,
  teachers?: Resolver<ResolversTypes['TeacherConnection'], ParentType, ContextType, QueryTeachersArgs>,
  teachersByID?: Resolver<Array<Maybe<ResolversTypes['Teacher']>>, ParentType, ContextType, RequireFields<QueryTeachersByIdArgs, 'ids'>>,
  teacherCuration?: Resolver<Maybe<ResolversTypes['TeacherCuration']>, ParentType, ContextType, RequireFields<QueryTeacherCurationArgs, 'id'>>,
  teacherCurationByCode?: Resolver<Maybe<ResolversTypes['TeacherCuration']>, ParentType, ContextType, RequireFields<QueryTeacherCurationByCodeArgs, 'code'>>,
  teacherCurations?: Resolver<ResolversTypes['TeacherCurationConnection'], ParentType, ContextType, QueryTeacherCurationsArgs>,
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType, RequireFields<QueryTeamArgs, 'id'>>,
  teams?: Resolver<ResolversTypes['TeamConnection'], ParentType, ContextType, QueryTeamsArgs>,
  teamsByID?: Resolver<Array<Maybe<ResolversTypes['Team']>>, ParentType, ContextType, RequireFields<QueryTeamsByIdArgs, 'ids'>>,
  membership?: Resolver<Maybe<ResolversTypes['Membership']>, ParentType, ContextType, RequireFields<QueryMembershipArgs, 'id'>>,
  memberships?: Resolver<Maybe<ResolversTypes['MembershipConnection']>, ParentType, ContextType, QueryMembershipsArgs>,
  membershipsByIDs?: Resolver<Array<Maybe<ResolversTypes['Membership']>>, ParentType, ContextType, RequireFields<QueryMembershipsByIDsArgs, 'ids'>>,
  isAdminMember?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  viewer?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  currentUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  currentTempAccount?: Resolver<Maybe<ResolversTypes['TempAccount']>, ParentType, ContextType>,
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>,
  users?: Resolver<ResolversTypes['UserConnection'], ParentType, ContextType, QueryUsersArgs>,
  usersByID?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QueryUsersByIdArgs, 'ids'>>,
  userDevices?: Resolver<ResolversTypes['UserDeviceConnection'], ParentType, ContextType, QueryUserDevicesArgs>,
  userPoint?: Resolver<Maybe<ResolversTypes['UserPoint']>, ParentType, ContextType, RequireFields<QueryUserPointArgs, 'id'>>,
  userPoints?: Resolver<Maybe<ResolversTypes['UserPointConnection']>, ParentType, ContextType, QueryUserPointsArgs>,
  userPointsByID?: Resolver<Array<Maybe<ResolversTypes['UserPoint']>>, ParentType, ContextType, RequireFields<QueryUserPointsByIdArgs, 'ids'>>,
  userRegisterStats?: Resolver<ResolversTypes['UserRegisterStatConnection'], ParentType, ContextType, RequireFields<QueryUserRegisterStatsArgs, 'filterBy'>>,
  userSignupStats?: Resolver<ResolversTypes['UserSignupStatConnection'], ParentType, ContextType, RequireFields<QueryUserSignupStatsArgs, 'filterBy'>>,
  userVisitStats?: Resolver<ResolversTypes['UserVisitStatConnection'], ParentType, ContextType, RequireFields<QueryUserVisitStatsArgs, 'filterBy'>>,
  userAllianceStats?: Resolver<ResolversTypes['UserAllianceStatConnection'], ParentType, ContextType, RequireFields<QueryUserAllianceStatsArgs, 'filterBy'>>,
  video?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType, RequireFields<QueryVideoArgs, 'id'>>,
  videos?: Resolver<ResolversTypes['VideoConnection'], ParentType, ContextType, QueryVideosArgs>,
  videoProduct?: Resolver<Maybe<ResolversTypes['VideoProduct']>, ParentType, ContextType, RequireFields<QueryVideoProductArgs, 'id'>>,
  videoProducts?: Resolver<ResolversTypes['VideoProductConnection'], ParentType, ContextType, QueryVideoProductsArgs>,
  videoLessonProduct?: Resolver<Maybe<ResolversTypes['VideoLessonProduct']>, ParentType, ContextType, RequireFields<QueryVideoLessonProductArgs, 'id'>>,
  videoLessonProducts?: Resolver<ResolversTypes['VideoLessonProductConnection'], ParentType, ContextType, QueryVideoLessonProductsArgs>,
  recoveryPasswordAuthByIDAndKey?: Resolver<Maybe<ResolversTypes['RecoveryPasswordAuth']>, ParentType, ContextType, RequireFields<QueryRecoveryPasswordAuthByIdAndKeyArgs, 'id' | 'key'>>,
  supportTicket?: Resolver<Maybe<ResolversTypes['SupportTicket']>, ParentType, ContextType, RequireFields<QuerySupportTicketArgs, 'id'>>,
  supportTickets?: Resolver<Maybe<ResolversTypes['SupportTicketConnection']>, ParentType, ContextType, QuerySupportTicketsArgs>,
  supportTicketAnswerTemplate?: Resolver<Maybe<ResolversTypes['SupportTicketAnswerTemplate']>, ParentType, ContextType, RequireFields<QuerySupportTicketAnswerTemplateArgs, 'id'>>,
  supportTicketAnswerTemplates?: Resolver<Maybe<ResolversTypes['SupportTicketAnswerTemplateConnection']>, ParentType, ContextType, QuerySupportTicketAnswerTemplatesArgs>,
  supportTicketAnswerTemplatesByID?: Resolver<Array<Maybe<ResolversTypes['SupportTicketAnswerTemplate']>>, ParentType, ContextType, RequireFields<QuerySupportTicketAnswerTemplatesByIdArgs, 'ids'>>,
  supportTicketCategory?: Resolver<Maybe<ResolversTypes['SupportTicketCategory']>, ParentType, ContextType, RequireFields<QuerySupportTicketCategoryArgs, 'id'>>,
  supportTicketCategories?: Resolver<Maybe<ResolversTypes['SupportTicketCategoryConnection']>, ParentType, ContextType, QuerySupportTicketCategoriesArgs>,
  supportTicketCategoryServiceMap?: Resolver<Maybe<ResolversTypes['SupportTicketCategoryServiceMap']>, ParentType, ContextType, RequireFields<QuerySupportTicketCategoryServiceMapArgs, 'id'>>,
  supportTicketCategoryServiceMaps?: Resolver<Maybe<ResolversTypes['SupportTicketCategoryServiceMapConnection']>, ParentType, ContextType, QuerySupportTicketCategoryServiceMapsArgs>,
  supportTicketCategoryServiceMapsByID?: Resolver<Array<Maybe<ResolversTypes['SupportTicketCategoryServiceMap']>>, ParentType, ContextType, RequireFields<QuerySupportTicketCategoryServiceMapsByIdArgs, 'ids'>>,
  newRegisteredSupportTickets?: Resolver<Array<ResolversTypes['SupportTicket']>, ParentType, ContextType, QueryNewRegisteredSupportTicketsArgs>,
  keyword?: Resolver<Maybe<ResolversTypes['Keyword']>, ParentType, ContextType, RequireFields<QueryKeywordArgs, 'code'>>,
  servicePurchaseStats?: Resolver<ResolversTypes['ServicePurchaseStatConnection'], ParentType, ContextType, RequireFields<QueryServicePurchaseStatsArgs, 'filterBy'>>,
  allianceUserContentHistorys?: Resolver<ResolversTypes['AllianceUserContentHistoryConnection'], ParentType, ContextType, RequireFields<QueryAllianceUserContentHistorysArgs, 'filterBy'>>,
  examSubjects?: Resolver<ResolversTypes['ExamSubjectConnection'], ParentType, ContextType, RequireFields<QueryExamSubjectsArgs, 'input'>>,
};

export type QuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Question'] = ResolversParentTypes['Question']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  questionField1?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  questionField2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  explanation?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  answer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  examQuestion?: Resolver<ResolversTypes['ExamQuestion'], ParentType, ContextType>,
};

export type RecoveryPasswordAuthResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecoveryPasswordAuth'] = ResolversParentTypes['RecoveryPasswordAuth']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  isValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
};

export type RefundResolvers<ContextType = any, ParentType extends ResolversParentTypes['Refund'] = ResolversParentTypes['Refund']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  contentPurchase?: Resolver<ResolversTypes['ContentPurchase'], ParentType, ContextType>,
  payment?: Resolver<ResolversTypes['Payment'], ParentType, ContextType>,
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type RefundConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundConnection'] = ResolversParentTypes['RefundConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Refund']>>>, ParentType, ContextType>,
};

export type RefundCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefundCreatePayload'] = ResolversParentTypes['RefundCreatePayload']> = {
  refund?: Resolver<Maybe<ResolversTypes['Refund']>, ParentType, ContextType>,
};

export type ResumeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Resume'] = ResolversParentTypes['Resume']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  wishCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>,
  school?: Resolver<Maybe<ResolversTypes['School']>, ParentType, ContextType>,
  major?: Resolver<Maybe<ResolversTypes['Major']>, ParentType, ContextType>,
  userGPA?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  maxGPA?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  resumeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  resumeFile?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  correctionFile?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  correctionHTML?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  languageTestSubject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  languageTestScore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  correctionStep?: Resolver<ResolversTypes['ResumeCorrectionStep'], ParentType, ContextType>,
  willOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  writtenAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  reviewedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  isCompanyAccepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  successfulCandidateReward?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  resumeProduct?: Resolver<ResolversTypes['ResumeProduct'], ParentType, ContextType>,
  options?: Resolver<Array<ResolversTypes['ResumeProductOption']>, ParentType, ContextType>,
  otherActivities?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>,
  qna?: Resolver<Maybe<Array<ResolversTypes['ResumeQnA']>>, ParentType, ContextType>,
  resumeHistory?: Resolver<Maybe<Array<ResolversTypes['Resume']>>, ParentType, ContextType>,
};

export type ResumeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeConnection'] = ResolversParentTypes['ResumeConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Resume']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ResumeCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeCreatePayload'] = ResolversParentTypes['ResumeCreatePayload']> = {
  resume?: Resolver<Maybe<ResolversTypes['Resume']>, ParentType, ContextType>,
};

export type ResumeProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeProduct'] = ResolversParentTypes['ResumeProduct']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  defaultCorrectionTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  caution?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  introductionHTML?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  introductionImageFile?: Resolver<ResolversTypes['File'], ParentType, ContextType>,
  successfulCandidateSampleFile?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  successfulCandidateFile?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  exampleCorrectionFile?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  isAvailableInHoliday?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  isAvailableInWeekend?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  correctionLimitType?: Resolver<ResolversTypes['ResumeCorrectionLimitType'], ParentType, ContextType>,
  maxBuyableLimitPerDay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  dailyQuantityLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  isAvailableToSale?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  options?: Resolver<Array<ResolversTypes['ResumeProductOption']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type ResumeProductConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeProductConnection'] = ResolversParentTypes['ResumeProductConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ResumeProduct']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ResumeProductCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeProductCreatePayload'] = ResolversParentTypes['ResumeProductCreatePayload']> = {
  resumeProduct?: Resolver<Maybe<ResolversTypes['ResumeProduct']>, ParentType, ContextType>,
};

export type ResumeProductDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeProductDeleteManyPayload'] = ResolversParentTypes['ResumeProductDeleteManyPayload']> = {
  resumeProducts?: Resolver<Maybe<Array<ResolversTypes['ResumeProduct']>>, ParentType, ContextType>,
};

export type ResumeProductDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeProductDeletePayload'] = ResolversParentTypes['ResumeProductDeletePayload']> = {
  resumeProduct?: Resolver<Maybe<ResolversTypes['ResumeProduct']>, ParentType, ContextType>,
};

export type ResumeProductOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeProductOption'] = ResolversParentTypes['ResumeProductOption']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  category?: Resolver<ResolversTypes['ResumeProductOptionCategory'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ResumeProductUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeProductUpdatePayload'] = ResolversParentTypes['ResumeProductUpdatePayload']> = {
  resumeProduct?: Resolver<Maybe<ResolversTypes['ResumeProduct']>, ParentType, ContextType>,
};

export type ResumeQnAResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeQnA'] = ResolversParentTypes['ResumeQnA']> = {
  questionNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  answer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type ResumeUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeUpdatePayload'] = ResolversParentTypes['ResumeUpdatePayload']> = {
  resume?: Resolver<Maybe<ResolversTypes['Resume']>, ParentType, ContextType>,
};

export type ReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['Review'] = ResolversParentTypes['Review']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  bodyHTML?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  author?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type ReviewConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReviewConnection'] = ResolversParentTypes['ReviewConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Review']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  averageScore?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type ReviewCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReviewCreatePayload'] = ResolversParentTypes['ReviewCreatePayload']> = {
  review?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType>,
};

export type ReviewDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReviewDeleteManyPayload'] = ResolversParentTypes['ReviewDeleteManyPayload']> = {
  reviews?: Resolver<Maybe<Array<ResolversTypes['Review']>>, ParentType, ContextType>,
};

export type ReviewDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReviewDeletePayload'] = ResolversParentTypes['ReviewDeletePayload']> = {
  review?: Resolver<Maybe<ResolversTypes['Review']>, ParentType, ContextType>,
};

export type ReviewMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReviewMission'] = ResolversParentTypes['ReviewMission']> = {
  useUrlCheck?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  hashTags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  siteName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  boardName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  boardUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  minLinkCountLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ReviewUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReviewUpdatePayload'] = ResolversParentTypes['ReviewUpdatePayload']> = {
  review?: Resolver<Maybe<ResolversTypes['Review']>, ParentType, ContextType>,
};

export type ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['ScheduleList'], ParentType, ContextType>,
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  events?: Resolver<Maybe<ResolversTypes['ScheduleEventConnection']>, ParentType, ContextType, ScheduleEventsArgs>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type ScheduleConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleConnection'] = ResolversParentTypes['ScheduleConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Schedule']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ScheduleCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleCreatePayload'] = ResolversParentTypes['ScheduleCreatePayload']> = {
  schedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType>,
};

export type ScheduleDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleDeleteManyPayload'] = ResolversParentTypes['ScheduleDeleteManyPayload']> = {
  schedules?: Resolver<Maybe<Array<ResolversTypes['Schedule']>>, ParentType, ContextType>,
};

export type ScheduleDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleDeletePayload'] = ResolversParentTypes['ScheduleDeletePayload']> = {
  schedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType>,
};

export type ScheduleEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleEvent'] = ResolversParentTypes['ScheduleEvent']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  scheduleID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  sequence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  useAutoUpdate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  autoUpdateWeekday?: Resolver<Maybe<ResolversTypes['Weekday']>, ParentType, ContextType>,
  autoUpdateEndDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type ScheduleEventConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleEventConnection'] = ResolversParentTypes['ScheduleEventConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ScheduleEvent']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ScheduleEventCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleEventCreatePayload'] = ResolversParentTypes['ScheduleEventCreatePayload']> = {
  scheduleEvent?: Resolver<Maybe<ResolversTypes['ScheduleEvent']>, ParentType, ContextType>,
};

export type ScheduleEventDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleEventDeleteManyPayload'] = ResolversParentTypes['ScheduleEventDeleteManyPayload']> = {
  scheduleEvents?: Resolver<Maybe<Array<ResolversTypes['ScheduleEvent']>>, ParentType, ContextType>,
};

export type ScheduleEventDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleEventDeletePayload'] = ResolversParentTypes['ScheduleEventDeletePayload']> = {
  scheduleEvent?: Resolver<Maybe<ResolversTypes['ScheduleEvent']>, ParentType, ContextType>,
};

export type ScheduleEventUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleEventUpdatePayload'] = ResolversParentTypes['ScheduleEventUpdatePayload']> = {
  scheduleEvent?: Resolver<Maybe<ResolversTypes['ScheduleEvent']>, ParentType, ContextType>,
};

export type ScheduleUpdateLambdaHandlerPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleUpdateLambdaHandlerPayload'] = ResolversParentTypes['ScheduleUpdateLambdaHandlerPayload']> = {
  updatedScheduleEvents?: Resolver<Array<Maybe<ResolversTypes['ScheduleEvent']>>, ParentType, ContextType>,
};

export type ScheduleUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleUpdatePayload'] = ResolversParentTypes['ScheduleUpdatePayload']> = {
  schedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType>,
};

export type SchoolResolvers<ContextType = any, ParentType extends ResolversParentTypes['School'] = ResolversParentTypes['School']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  schoolName?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  campusName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type SchoolConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SchoolConnection'] = ResolversParentTypes['SchoolConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['School']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ServicePurchaseStatResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePurchaseStat'] = ResolversParentTypes['ServicePurchaseStat']> = {
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  nodes?: Resolver<Array<ResolversTypes['ServicePurchaseStatDetail']>, ParentType, ContextType>,
};

export type ServicePurchaseStatConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePurchaseStatConnection'] = ResolversParentTypes['ServicePurchaseStatConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['ServicePurchaseStat']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type ServicePurchaseStatDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePurchaseStatDetail'] = ResolversParentTypes['ServicePurchaseStatDetail']> = {
  salesAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  service?: Resolver<ResolversTypes['ServicePurchaseStatServiceType'], ParentType, ContextType>,
};

export type SmsRejectResolvers<ContextType = any, ParentType extends ResolversParentTypes['SmsReject'] = ResolversParentTypes['SmsReject']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  cid?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  phoneNumber?: Resolver<ResolversTypes['PhoneNumber'], ParentType, ContextType>,
  sendPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  orgNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  regDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type SnsRegisterPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SNSRegisterPayload'] = ResolversParentTypes['SNSRegisterPayload']> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
};

export type StudyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Study'] = ResolversParentTypes['Study']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  service?: Resolver<ResolversTypes['ServiceType'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  participantsLimit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  participantsLimitNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  recruitPeriodStartDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  recruitPeriodEndDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  isDuplicateAllow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  duplicateRestrictStudies?: Resolver<Maybe<Array<ResolversTypes['Study']>>, ParentType, ContextType>,
  pcLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  mobileLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  communityPostLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  noticeLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  previousLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  completeBody?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  participantsNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  brazeEventProperties?: Resolver<ResolversTypes['StudyBrazeEventProperties'], ParentType, ContextType>,
  kakaoOpenChatUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  preNoticeUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  events?: Resolver<Maybe<ResolversTypes['StudyEventConnection']>, ParentType, ContextType, StudyEventsArgs>,
  userStudyEvents?: Resolver<Maybe<ResolversTypes['UserStudyEventConnection']>, ParentType, ContextType, StudyUserStudyEventsArgs>,
};

export type StudyBenefitResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyBenefit'] = ResolversParentTypes['StudyBenefit']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  benefitType?: Resolver<ResolversTypes['BenefitType'], ParentType, ContextType>,
  benefit?: Resolver<Maybe<ResolversTypes['Benefit']>, ParentType, ContextType>,
  studyEvent?: Resolver<Maybe<ResolversTypes['StudyEvent']>, ParentType, ContextType>,
  provideDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
};

export type StudyBenefitCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyBenefitCreatePayload'] = ResolversParentTypes['StudyBenefitCreatePayload']> = {
  studyBenefit?: Resolver<Maybe<ResolversTypes['StudyBenefit']>, ParentType, ContextType>,
};

export type StudyBenefitDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyBenefitDeletePayload'] = ResolversParentTypes['StudyBenefitDeletePayload']> = {
  studyBenefit?: Resolver<Maybe<ResolversTypes['StudyBenefit']>, ParentType, ContextType>,
};

export type StudyBenefitUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyBenefitUpdatePayload'] = ResolversParentTypes['StudyBenefitUpdatePayload']> = {
  studyBenefit?: Resolver<Maybe<ResolversTypes['StudyBenefit']>, ParentType, ContextType>,
};

export type StudyBrazeEventPropertiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyBrazeEventProperties'] = ResolversParentTypes['StudyBrazeEventProperties']> = {
  studyClassification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  studyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  studyCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
};

export type StudyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyConnection'] = ResolversParentTypes['StudyConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Study']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type StudyCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyCreatePayload'] = ResolversParentTypes['StudyCreatePayload']> = {
  study?: Resolver<Maybe<ResolversTypes['Study']>, ParentType, ContextType>,
};

export type StudyDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyDeleteManyPayload'] = ResolversParentTypes['StudyDeleteManyPayload']> = {
  studies?: Resolver<Maybe<Array<ResolversTypes['Study']>>, ParentType, ContextType>,
};

export type StudyDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyDeletePayload'] = ResolversParentTypes['StudyDeletePayload']> = {
  study?: Resolver<Maybe<ResolversTypes['Study']>, ParentType, ContextType>,
};

export type StudyEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyEvent'] = ResolversParentTypes['StudyEvent']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>,
  sequence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  benefits?: Resolver<Maybe<Array<ResolversTypes['StudyBenefit']>>, ParentType, ContextType>,
  userStudyEvents?: Resolver<Maybe<ResolversTypes['UserStudyEventConnection']>, ParentType, ContextType, StudyEventUserStudyEventsArgs>,
  missions?: Resolver<Maybe<Array<ResolversTypes['StudyMission']>>, ParentType, ContextType>,
  participantsNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  passedParticipantsNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type StudyEventBulkCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyEventBulkCreatePayload'] = ResolversParentTypes['StudyEventBulkCreatePayload']> = {
  studyEvents?: Resolver<Array<ResolversTypes['StudyEvent']>, ParentType, ContextType>,
};

export type StudyEventConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyEventConnection'] = ResolversParentTypes['StudyEventConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['StudyEvent']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type StudyEventCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyEventCreatePayload'] = ResolversParentTypes['StudyEventCreatePayload']> = {
  studyEvent?: Resolver<Maybe<ResolversTypes['StudyEvent']>, ParentType, ContextType>,
};

export type StudyMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyMission'] = ResolversParentTypes['StudyMission']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  studyEvent?: Resolver<ResolversTypes['StudyEvent'], ParentType, ContextType>,
  dueDateStartAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  dueDateEndAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  missionType?: Resolver<ResolversTypes['MissionType'], ParentType, ContextType>,
  exposeImmediately?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  exposeStartAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  passingCriteria?: Resolver<ResolversTypes['PassingCriteria'], ParentType, ContextType>,
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  missionDetails?: Resolver<Maybe<ResolversTypes['MissionDetails']>, ParentType, ContextType>,
  isMutable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
};

export type StudyMissionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyMissionConnection'] = ResolversParentTypes['StudyMissionConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['StudyMission']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type StudyMissionCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyMissionCreatePayload'] = ResolversParentTypes['StudyMissionCreatePayload']> = {
  studyMission?: Resolver<Maybe<ResolversTypes['StudyMission']>, ParentType, ContextType>,
};

export type StudyMissionDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyMissionDeleteManyPayload'] = ResolversParentTypes['StudyMissionDeleteManyPayload']> = {
  studyMissions?: Resolver<Maybe<Array<ResolversTypes['StudyMission']>>, ParentType, ContextType>,
};

export type StudyMissionDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyMissionDeletePayload'] = ResolversParentTypes['StudyMissionDeletePayload']> = {
  studyMission?: Resolver<Maybe<ResolversTypes['StudyMission']>, ParentType, ContextType>,
};

export type StudyMissionUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyMissionUpdatePayload'] = ResolversParentTypes['StudyMissionUpdatePayload']> = {
  studyMission?: Resolver<Maybe<ResolversTypes['StudyMission']>, ParentType, ContextType>,
};

export type StudyUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyUpdatePayload'] = ResolversParentTypes['StudyUpdatePayload']> = {
  study?: Resolver<Maybe<ResolversTypes['Study']>, ParentType, ContextType>,
};

export type StudyUserContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudyUserContent'] = ResolversParentTypes['StudyUserContent']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  userStudyEvent?: Resolver<ResolversTypes['UserStudyEvent'], ParentType, ContextType>,
  contentPurchase?: Resolver<ResolversTypes['ContentPurchase'], ParentType, ContextType>,
};

export type SupportTicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicket'] = ResolversParentTypes['SupportTicket']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  repliedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  service?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>,
  writer?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  writerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  category?: Resolver<ResolversTypes['SupportTicketCategory'], ParentType, ContextType>,
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  bodyHTML?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  emailReceipt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  file?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  reply?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  replyUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  replyFile?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  processState?: Resolver<Maybe<ResolversTypes['SupportTicketProcessState']>, ParentType, ContextType>,
  ticketType?: Resolver<ResolversTypes['SupportTicketType'], ParentType, ContextType>,
};

export type SupportTicketAdminCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketAdminCreatePayload'] = ResolversParentTypes['SupportTicketAdminCreatePayload']> = {
  supportTicket?: Resolver<Maybe<ResolversTypes['SupportTicket']>, ParentType, ContextType>,
};

export type SupportTicketAdminUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketAdminUpdatePayload'] = ResolversParentTypes['SupportTicketAdminUpdatePayload']> = {
  supportTicket?: Resolver<Maybe<ResolversTypes['SupportTicket']>, ParentType, ContextType>,
};

export type SupportTicketAnswerTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketAnswerTemplate'] = ResolversParentTypes['SupportTicketAnswerTemplate']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  descriptionHTML?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type SupportTicketAnswerTemplateConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketAnswerTemplateConnection'] = ResolversParentTypes['SupportTicketAnswerTemplateConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['SupportTicketAnswerTemplate']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type SupportTicketAnswerTemplateCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketAnswerTemplateCreatePayload'] = ResolversParentTypes['SupportTicketAnswerTemplateCreatePayload']> = {
  supportTicketAnswerTemplate?: Resolver<Maybe<ResolversTypes['SupportTicketAnswerTemplate']>, ParentType, ContextType>,
};

export type SupportTicketAnswerTemplateUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketAnswerTemplateUpdatePayload'] = ResolversParentTypes['SupportTicketAnswerTemplateUpdatePayload']> = {
  supportTicketAnswerTemplate?: Resolver<Maybe<ResolversTypes['SupportTicketAnswerTemplate']>, ParentType, ContextType>,
};

export type SupportTicketCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketCategory'] = ResolversParentTypes['SupportTicketCategory']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  parentID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  services?: Resolver<Maybe<Array<Maybe<ResolversTypes['SupportTicketCategoryServiceMap']>>>, ParentType, ContextType>,
};

export type SupportTicketCategoryConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketCategoryConnection'] = ResolversParentTypes['SupportTicketCategoryConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['SupportTicketCategory']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type SupportTicketCategoryCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketCategoryCreatePayload'] = ResolversParentTypes['SupportTicketCategoryCreatePayload']> = {
  supportTicketCategory?: Resolver<Maybe<ResolversTypes['SupportTicketCategory']>, ParentType, ContextType>,
};

export type SupportTicketCategoryServiceMapResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketCategoryServiceMap'] = ResolversParentTypes['SupportTicketCategoryServiceMap']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  service?: Resolver<ResolversTypes['ServiceType'], ParentType, ContextType>,
  category?: Resolver<Maybe<ResolversTypes['SupportTicketCategory']>, ParentType, ContextType>,
};

export type SupportTicketCategoryServiceMapConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketCategoryServiceMapConnection'] = ResolversParentTypes['SupportTicketCategoryServiceMapConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['SupportTicketCategoryServiceMap']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type SupportTicketCategoryUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketCategoryUpdatePayload'] = ResolversParentTypes['SupportTicketCategoryUpdatePayload']> = {
  supportTicketCategory?: Resolver<Maybe<ResolversTypes['SupportTicketCategory']>, ParentType, ContextType>,
};

export type SupportTicketConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketConnection'] = ResolversParentTypes['SupportTicketConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['SupportTicket']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type SupportTicketCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketCreatePayload'] = ResolversParentTypes['SupportTicketCreatePayload']> = {
  supportTicket?: Resolver<Maybe<ResolversTypes['SupportTicket']>, ParentType, ContextType>,
};

export type SupportTicketUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportTicketUpdatePayload'] = ResolversParentTypes['SupportTicketUpdatePayload']> = {
  supportTicket?: Resolver<Maybe<ResolversTypes['SupportTicket']>, ParentType, ContextType>,
};

export type TeacherResolvers<ContextType = any, ParentType extends ResolversParentTypes['Teacher'] = ResolversParentTypes['Teacher']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  videoContents?: Resolver<Maybe<ResolversTypes['ContentConnection']>, ParentType, ContextType, TeacherVideoContentsArgs>,
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  includeInvoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  mobileMainImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  mobileThumbnailImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  mobileDescriptionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  mobileDescriptionDetail?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  aboutDetailImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  aboutListImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  aboutRollingImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  mainPageImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  offlineLectureThumbnailImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  videoListImage?: Resolver<ResolversTypes['File'], ParentType, ContextType>,
  aboutListText?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  aboutDetailText?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  videoListText?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  reviewScoreURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  mainImageLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  contactURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  hasResumeProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
};

export type TeacherCommissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherCommissions'] = ResolversParentTypes['TeacherCommissions']> = {
  teacherID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  partialContract?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalContract?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type TeacherConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherConnection'] = ResolversParentTypes['TeacherConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Teacher']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type TeacherCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherCreatePayload'] = ResolversParentTypes['TeacherCreatePayload']> = {
  teacher?: Resolver<Maybe<ResolversTypes['Teacher']>, ParentType, ContextType>,
};

export type TeacherCurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherCuration'] = ResolversParentTypes['TeacherCuration']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  teachers?: Resolver<Array<ResolversTypes['Teacher']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type TeacherCurationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherCurationConnection'] = ResolversParentTypes['TeacherCurationConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['TeacherCuration']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type TeacherCurationCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherCurationCreatePayload'] = ResolversParentTypes['TeacherCurationCreatePayload']> = {
  teacherCuration?: Resolver<Maybe<ResolversTypes['TeacherCuration']>, ParentType, ContextType>,
};

export type TeacherCurationDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherCurationDeleteManyPayload'] = ResolversParentTypes['TeacherCurationDeleteManyPayload']> = {
  teacherCurations?: Resolver<Array<ResolversTypes['TeacherCuration']>, ParentType, ContextType>,
};

export type TeacherCurationDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherCurationDeletePayload'] = ResolversParentTypes['TeacherCurationDeletePayload']> = {
  teacherCuration?: Resolver<Maybe<ResolversTypes['TeacherCuration']>, ParentType, ContextType>,
};

export type TeacherCurationUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherCurationUpdatePayload'] = ResolversParentTypes['TeacherCurationUpdatePayload']> = {
  teacherCuration?: Resolver<Maybe<ResolversTypes['TeacherCuration']>, ParentType, ContextType>,
};

export type TeacherDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherDeletePayload'] = ResolversParentTypes['TeacherDeletePayload']> = {
  teacher?: Resolver<Maybe<ResolversTypes['Teacher']>, ParentType, ContextType>,
};

export type TeacherUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeacherUpdatePayload'] = ResolversParentTypes['TeacherUpdatePayload']> = {
  teacher?: Resolver<Maybe<ResolversTypes['Teacher']>, ParentType, ContextType>,
};

export type TeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['Team'] = ResolversParentTypes['Team']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  members?: Resolver<ResolversTypes['MembershipConnection'], ParentType, ContextType>,
  permissions?: Resolver<ResolversTypes['PermissionConnection'], ParentType, ContextType>,
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
};

export type TeamConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamConnection'] = ResolversParentTypes['TeamConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Team']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type TeamCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamCreatePayload'] = ResolversParentTypes['TeamCreatePayload']> = {
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>,
};

export type TeamDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamDeleteManyPayload'] = ResolversParentTypes['TeamDeleteManyPayload']> = {
  teams?: Resolver<Maybe<Array<ResolversTypes['Team']>>, ParentType, ContextType>,
};

export type TeamDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamDeletePayload'] = ResolversParentTypes['TeamDeletePayload']> = {
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>,
};

export type TeamUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamUpdatePayload'] = ResolversParentTypes['TeamUpdatePayload']> = {
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType>,
};

export type TempAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['TempAccount'] = ResolversParentTypes['TempAccount']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  phoneNumber?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>,
  examAttemptByExamID?: Resolver<Maybe<ResolversTypes['ExamAttempt']>, ParentType, ContextType, RequireFields<TempAccountExamAttemptByExamIdArgs, 'examID'>>,
  linkedUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
};

export type TempAccountLinkToUserPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TempAccountLinkToUserPayload'] = ResolversParentTypes['TempAccountLinkToUserPayload']> = {
  tempAccount?: Resolver<Maybe<ResolversTypes['TempAccount']>, ParentType, ContextType>,
};

export type TempAccountSignupPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['TempAccountSignupPayload'] = ResolversParentTypes['TempAccountSignupPayload']> = {
  tempAccount?: Resolver<Maybe<ResolversTypes['TempAccount']>, ParentType, ContextType>,
};

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time'
}

export type UpdatePwByUsernamePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatePWByUsernamePayload'] = ResolversParentTypes['UpdatePWByUsernamePayload']> = {
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
};

export type UpdatePwPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatePWPayload'] = ResolversParentTypes['UpdatePWPayload']> = {
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload'
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  authUser?: Resolver<Maybe<ResolversTypes['AuthUser']>, ParentType, ContextType>,
  allianceUser?: Resolver<Maybe<ResolversTypes['AllianceUser']>, ParentType, ContextType>,
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  phoneNumber?: Resolver<Maybe<ResolversTypes['PhoneNumber']>, ParentType, ContextType>,
  isAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<UserIsAllowedArgs, 'scope'>>,
  totalCouponCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalPoint?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  lastLoginAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  emailReceipt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  smsReceipt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  birth?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  examToTake?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExamToTake']>>>, ParentType, ContextType>,
  joinRoute?: Resolver<Maybe<ResolversTypes['JoinRoute']>, ParentType, ContextType>,
  allianceInfoCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  residence?: Resolver<Maybe<ResolversTypes['Residence']>, ParentType, ContextType>,
  subjectCurriculum?: Resolver<Maybe<ResolversTypes['SubjectCurriculum']>, ParentType, ContextType>,
  externalCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  device?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  devices?: Resolver<Array<Maybe<ResolversTypes['UserDevice']>>, ParentType, ContextType>,
  pointHistory?: Resolver<Array<Maybe<ResolversTypes['UserPoint']>>, ParentType, ContextType>,
  service?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>,
  order?: Resolver<Maybe<Array<ResolversTypes['Order']>>, ParentType, ContextType>,
  didParticipateStudy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  examSubject?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExamSubject']>>>, ParentType, ContextType>,
};

export type UserAllianceStatResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAllianceStat'] = ResolversParentTypes['UserAllianceStat']> = {
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  userRegisterCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  userVisitCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  userVideoCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  userDocumentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  userExamCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserAllianceStatConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAllianceStatConnection'] = ResolversParentTypes['UserAllianceStatConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserAllianceStat']>, ParentType, ContextType>,
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserConnection'] = ResolversParentTypes['UserConnection']> = {
  nodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserDeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDevice'] = ResolversParentTypes['UserDevice']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  userID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>,
  deviceID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  deviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  deviceNickName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  isForFreepass?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  playerType?: Resolver<Maybe<ResolversTypes['PlayerType']>, ParentType, ContextType>,
  deviceNickNameUpdatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  isDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
  isSystem?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
};

export type UserDeviceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDeviceConnection'] = ResolversParentTypes['UserDeviceConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserDevice']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserDeviceDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDeviceDeletePayload'] = ResolversParentTypes['UserDeviceDeletePayload']> = {
  userDevice?: Resolver<Maybe<ResolversTypes['UserDevice']>, ParentType, ContextType>,
};

export type UserInfoUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInfoUpdatePayload'] = ResolversParentTypes['UserInfoUpdatePayload']> = {
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
};

export type UserLeaveLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLeaveLog'] = ResolversParentTypes['UserLeaveLog']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  email?: Resolver<ResolversTypes['EmailAddress'], ParentType, ContextType>,
  phoneNumber?: Resolver<ResolversTypes['PhoneNumber'], ParentType, ContextType>,
  birth?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  reason?: Resolver<ResolversTypes['LeaveReason'], ParentType, ContextType>,
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  userID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type UserLeavePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLeavePayload'] = ResolversParentTypes['UserLeavePayload']> = {
  userleavelog?: Resolver<Maybe<ResolversTypes['UserLeaveLog']>, ParentType, ContextType>,
};

export type UserMajorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMajor'] = ResolversParentTypes['UserMajor']> = {
  major?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  majorName?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type UserPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPoint'] = ResolversParentTypes['UserPoint']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  point?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  state?: Resolver<ResolversTypes['UserPointState'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type UserPointConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPointConnection'] = ResolversParentTypes['UserPointConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserPoint']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserPointCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPointCreatePayload'] = ResolversParentTypes['UserPointCreatePayload']> = {
  userPoint?: Resolver<Maybe<ResolversTypes['UserPoint']>, ParentType, ContextType>,
};

export type UserPointDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPointDeleteManyPayload'] = ResolversParentTypes['UserPointDeleteManyPayload']> = {
  userPoints?: Resolver<Maybe<Array<ResolversTypes['UserPoint']>>, ParentType, ContextType>,
};

export type UserPointDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPointDeletePayload'] = ResolversParentTypes['UserPointDeletePayload']> = {
  userPoint?: Resolver<Maybe<ResolversTypes['UserPoint']>, ParentType, ContextType>,
};

export type UserPointUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPointUpdatePayload'] = ResolversParentTypes['UserPointUpdatePayload']> = {
  userPoint?: Resolver<Maybe<ResolversTypes['UserPoint']>, ParentType, ContextType>,
};

export type UserRegisterStatResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRegisterStat'] = ResolversParentTypes['UserRegisterStat']> = {
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  userRegisterCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserRegisterStatConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRegisterStatConnection'] = ResolversParentTypes['UserRegisterStatConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserRegisterStat']>, ParentType, ContextType>,
  nodesOfLastYear?: Resolver<Array<ResolversTypes['UserRegisterStat']>, ParentType, ContextType>,
  summary?: Resolver<ResolversTypes['UserRegisterStatSummary'], ParentType, ContextType>,
  growthRate?: Resolver<Array<ResolversTypes['UserRegisterStatGrowthRate']>, ParentType, ContextType>,
  growthRateSummary?: Resolver<ResolversTypes['UserRegisterStatGrowthRateSummary'], ParentType, ContextType>,
  weeklySummaryOfLastWeek?: Resolver<ResolversTypes['UserRegisterStatSummary'], ParentType, ContextType>,
  weeklySummaryOfLastYear?: Resolver<ResolversTypes['UserRegisterStatSummary'], ParentType, ContextType>,
  yearlySummaryOfLastYear?: Resolver<ResolversTypes['UserRegisterStatSummary'], ParentType, ContextType>,
};

export type UserRegisterStatGrowthRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRegisterStatGrowthRate'] = ResolversParentTypes['UserRegisterStatGrowthRate']> = {
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  userRegisterCountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type UserRegisterStatGrowthRateSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRegisterStatGrowthRateSummary'] = ResolversParentTypes['UserRegisterStatGrowthRateSummary']> = {
  totalUserRegisterCountGrowthRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type UserRegisterStatSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRegisterStatSummary'] = ResolversParentTypes['UserRegisterStatSummary']> = {
  totalUserRegisterCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserSchoolResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSchool'] = ResolversParentTypes['UserSchool']> = {
  seq?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  schoolType?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  schoolName?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
};

export type UserSchoolConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSchoolConnection'] = ResolversParentTypes['UserSchoolConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserSchool']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserSchoolMajorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSchoolMajor'] = ResolversParentTypes['UserSchoolMajor']> = {
  userMajor?: Resolver<Array<ResolversTypes['UserMajor']>, ParentType, ContextType>,
};

export type UserSchoolMajorConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSchoolMajorConnection'] = ResolversParentTypes['UserSchoolMajorConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserSchoolMajor']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserSignupStatConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSignupStatConnection'] = ResolversParentTypes['UserSignupStatConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserRegisterStat']>, ParentType, ContextType>,
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserStudyResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudy'] = ResolversParentTypes['UserStudy']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>,
  userStudyEvents?: Resolver<Maybe<Array<ResolversTypes['UserStudyEvent']>>, ParentType, ContextType>,
  state?: Resolver<ResolversTypes['UserStudyState'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  totalLearningTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<UserStudyTotalLearningTimeArgs, 'unit'>>,
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  percentileRank?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  dailyStudyTimes?: Resolver<Array<ResolversTypes['DailyStudyTime']>, ParentType, ContextType, UserStudyDailyStudyTimesArgs>,
  totalLecturePlayTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, UserStudyTotalLecturePlayTimeArgs>,
  totalLectureRealPlayTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, UserStudyTotalLectureRealPlayTimeArgs>,
  dailyLectureRealPlayTimes?: Resolver<Array<ResolversTypes['DailyStudyTime']>, ParentType, ContextType, UserStudyDailyLectureRealPlayTimesArgs>,
};

export type UserStudyBenefitCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyBenefitCreatePayload'] = ResolversParentTypes['UserStudyBenefitCreatePayload']> = {
  studyUserContents?: Resolver<Maybe<Array<Maybe<ResolversTypes['StudyUserContent']>>>, ParentType, ContextType>,
};

export type UserStudyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyConnection'] = ResolversParentTypes['UserStudyConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserStudy']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserStudyEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyEvent'] = ResolversParentTypes['UserStudyEvent']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>,
  studyEvent?: Resolver<ResolversTypes['StudyEvent'], ParentType, ContextType>,
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  state?: Resolver<ResolversTypes['UserStudyEventState'], ParentType, ContextType>,
  userStudy?: Resolver<ResolversTypes['UserStudy'], ParentType, ContextType>,
  userStudyMissionsAndContents?: Resolver<ResolversTypes['UserStudyMissionsAndContents'], ParentType, ContextType>,
  isPreviousSequenceFailed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  percentileRank?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  lectureProgressRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  lectureRealTimeProgressRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  totalLecturePlayTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, UserStudyEventTotalLecturePlayTimeArgs>,
  totalLectureRealPlayTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, UserStudyEventTotalLectureRealPlayTimeArgs>,
};

export type UserStudyEventConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyEventConnection'] = ResolversParentTypes['UserStudyEventConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserStudyEvent']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserStudyEventFailPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyEventFailPayload'] = ResolversParentTypes['UserStudyEventFailPayload']> = {
  userStudyEvents?: Resolver<Maybe<Array<ResolversTypes['UserStudyEvent']>>, ParentType, ContextType>,
};

export type UserStudyEventForcePassPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyEventForcePassPayload'] = ResolversParentTypes['UserStudyEventForcePassPayload']> = {
  userStudyEvents?: Resolver<Maybe<Array<ResolversTypes['UserStudyEvent']>>, ParentType, ContextType>,
  forcePassCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserStudyEventPassPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyEventPassPayload'] = ResolversParentTypes['UserStudyEventPassPayload']> = {
  userStudyEvents?: Resolver<Maybe<Array<ResolversTypes['UserStudyEvent']>>, ParentType, ContextType>,
};

export type UserStudyMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMission'] = ResolversParentTypes['UserStudyMission']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  userStudyEvent?: Resolver<ResolversTypes['UserStudyEvent'], ParentType, ContextType>,
  studyMission?: Resolver<ResolversTypes['StudyMission'], ParentType, ContextType>,
  state?: Resolver<ResolversTypes['UserStudyMissionState'], ParentType, ContextType>,
  isSubmitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  userStudyMissionDetails?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserStudyMissionDetail']>>>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
};

export type UserStudyMissionAssignmentFileResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionAssignmentFile'] = ResolversParentTypes['UserStudyMissionAssignmentFile']> = {
  file?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
};

export type UserStudyMissionAssignmentFileUploadPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionAssignmentFileUploadPayload'] = ResolversParentTypes['UserStudyMissionAssignmentFileUploadPayload']> = {
  userStudyMission?: Resolver<ResolversTypes['UserStudyMission'], ParentType, ContextType>,
};

export type UserStudyMissionAssignmentLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionAssignmentLink'] = ResolversParentTypes['UserStudyMissionAssignmentLink']> = {
  communityPostId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  contentText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  attachmentsIds?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>,
  isAnonymous?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
};

export type UserStudyMissionAssignmentLinkUploadPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionAssignmentLinkUploadPayload'] = ResolversParentTypes['UserStudyMissionAssignmentLinkUploadPayload']> = {
  userStudyMission?: Resolver<ResolversTypes['UserStudyMission'], ParentType, ContextType>,
};

export type UserStudyMissionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionConnection'] = ResolversParentTypes['UserStudyMissionConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserStudyMission']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type UserStudyMissionDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionDetail'] = ResolversParentTypes['UserStudyMissionDetail']> = {
  __resolveType: TypeResolveFn<'UserStudyMissionAssignmentFile' | 'UserStudyMissionAssignmentLink' | 'UserStudyMissionLecture' | 'UserStudyMissionExam' | 'UserStudyMissionReview', ParentType, ContextType>
};

export type UserStudyMissionExamResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionExam'] = ResolversParentTypes['UserStudyMissionExam']> = {
  studyUserContent?: Resolver<ResolversTypes['ContentPurchase'], ParentType, ContextType>,
  score?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type UserStudyMissionLectureResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionLecture'] = ResolversParentTypes['UserStudyMissionLecture']> = {
  studyUserContent?: Resolver<ResolversTypes['ContentPurchase'], ParentType, ContextType>,
  completedVideoLessonCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  totalVideoLessonCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  progressRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  realTimeProgressRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type UserStudyMissionReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionReview'] = ResolversParentTypes['UserStudyMissionReview']> = {
  communityPostId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  contentText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  attachmentsIds?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>,
  isAnonymous?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>,
};

export type UserStudyMissionReviewUploadPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionReviewUploadPayload'] = ResolversParentTypes['UserStudyMissionReviewUploadPayload']> = {
  userStudyMission?: Resolver<ResolversTypes['UserStudyMission'], ParentType, ContextType>,
};

export type UserStudyMissionsAndContentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionsAndContents'] = ResolversParentTypes['UserStudyMissionsAndContents']> = {
  userStudyMissions?: Resolver<Maybe<Array<ResolversTypes['UserStudyMission']>>, ParentType, ContextType>,
  studyUserContents?: Resolver<Maybe<Array<ResolversTypes['ContentPurchase']>>, ParentType, ContextType>,
};

export type UserStudyMissionsUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionsUpdatePayload'] = ResolversParentTypes['UserStudyMissionsUpdatePayload']> = {
  userStudyMissions?: Resolver<Maybe<Array<ResolversTypes['UserStudyMission']>>, ParentType, ContextType>,
};

export type UserStudyMissionUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyMissionUpdatePayload'] = ResolversParentTypes['UserStudyMissionUpdatePayload']> = {
  userStudyMission?: Resolver<Maybe<ResolversTypes['UserStudyMission']>, ParentType, ContextType>,
};

export type UserStudyRegisterPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStudyRegisterPayload'] = ResolversParentTypes['UserStudyRegisterPayload']> = {
  userStudy?: Resolver<ResolversTypes['UserStudy'], ParentType, ContextType>,
};

export type UserVisitStatConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserVisitStatConnection'] = ResolversParentTypes['UserVisitStatConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['UserRegisterStat']>, ParentType, ContextType>,
};

export type VideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>,
  expiredAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>,
  videoLessonPlayLogs?: Resolver<Maybe<Array<ResolversTypes['VideoLessonPlayLog']>>, ParentType, ContextType, VideoVideoLessonPlayLogsArgs>,
  videoLessons?: Resolver<Array<ResolversTypes['VideoLesson']>, ParentType, ContextType>,
  totalRealPlayTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<VideoTotalRealPlayTimeArgs, 'unit'>>,
  completedVideoLessonCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<VideoCompletedVideoLessonCountArgs, 'standardProgressRate'>>,
};

export type VideoConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoConnection'] = ResolversParentTypes['VideoConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['Video']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type VideoLessonResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoLesson'] = ResolversParentTypes['VideoLesson']> = {
  videoLessonProduct?: Resolver<ResolversTypes['VideoLessonProduct'], ParentType, ContextType>,
  videoLessonPlayLogs?: Resolver<Maybe<Array<ResolversTypes['VideoLessonPlayLog']>>, ParentType, ContextType, VideoLessonVideoLessonPlayLogsArgs>,
  totalRealPlayTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<VideoLessonTotalRealPlayTimeArgs, 'unit'>>,
  totalPlayTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<VideoLessonTotalPlayTimeArgs, 'unit'>>,
  progressRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType, VideoLessonProgressRateArgs>,
};

export type VideoLessonPlayLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoLessonPlayLog'] = ResolversParentTypes['VideoLessonPlayLog']> = {
  videoLessonProduct?: Resolver<ResolversTypes['VideoLessonProduct'], ParentType, ContextType>,
  playTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<VideoLessonPlayLogPlayTimeArgs, 'unit'>>,
  realPlayTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<VideoLessonPlayLogRealPlayTimeArgs, 'unit'>>,
  progressRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
};

export type VideoLessonProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoLessonProduct'] = ResolversParentTypes['VideoLessonProduct']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  lessonNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  runtime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<VideoLessonProductRuntimeArgs, 'unit'>>,
  videoProduct?: Resolver<ResolversTypes['VideoProduct'], ParentType, ContextType>,
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  teachingMaterialFile?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  playTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<VideoLessonProductPlayTimeArgs, 'unit'>>,
};

export type VideoLessonProductConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoLessonProductConnection'] = ResolversParentTypes['VideoLessonProductConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['VideoLessonProduct']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type VideoProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoProduct'] = ResolversParentTypes['VideoProduct']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  content?: Resolver<ResolversTypes['Content'], ParentType, ContextType>,
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  descriptionHTML?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  package?: Resolver<Maybe<ResolversTypes['Package']>, ParentType, ContextType>,
  videoProductImage?: Resolver<Maybe<ResolversTypes['File']>, ParentType, ContextType>,
  book?: Resolver<Maybe<ResolversTypes['Book']>, ParentType, ContextType>,
  activeVideoLessonProducts?: Resolver<Maybe<Array<ResolversTypes['VideoLessonProduct']>>, ParentType, ContextType>,
  videoLessonProducts?: Resolver<ResolversTypes['VideoLessonProductConnection'], ParentType, ContextType, VideoProductVideoLessonProductsArgs>,
};

export type VideoProductConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoProductConnection'] = ResolversParentTypes['VideoProductConnection']> = {
  nodes?: Resolver<Array<ResolversTypes['VideoProduct']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
};

export type VideoProductCreatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoProductCreatePayload'] = ResolversParentTypes['VideoProductCreatePayload']> = {
  videoProduct?: Resolver<Maybe<ResolversTypes['VideoProduct']>, ParentType, ContextType>,
};

export type VideoProductDeleteManyPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoProductDeleteManyPayload'] = ResolversParentTypes['VideoProductDeleteManyPayload']> = {
  videoProducts?: Resolver<Maybe<Array<ResolversTypes['VideoProduct']>>, ParentType, ContextType>,
};

export type VideoProductDeletePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoProductDeletePayload'] = ResolversParentTypes['VideoProductDeletePayload']> = {
  videoProduct?: Resolver<Maybe<ResolversTypes['VideoProduct']>, ParentType, ContextType>,
};

export type VideoProductUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoProductUpdatePayload'] = ResolversParentTypes['VideoProductUpdatePayload']> = {
  videoProduct?: Resolver<Maybe<ResolversTypes['VideoProduct']>, ParentType, ContextType>,
};

export type VideoUpdatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoUpdatePayload'] = ResolversParentTypes['VideoUpdatePayload']> = {
  video?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType>,
};

export type Resolvers<ContextType = any> = {
  ActivateDormantUserPayload?: ActivateDormantUserPayloadResolvers<ContextType>,
  ActiveUserPayload?: ActiveUserPayloadResolvers<ContextType>,
  AdminBlogPost?: AdminBlogPostResolvers<ContextType>,
  AdminBlogPostConnection?: AdminBlogPostConnectionResolvers<ContextType>,
  Alliance?: AllianceResolvers<ContextType>,
  AllianceConnection?: AllianceConnectionResolvers<ContextType>,
  AllianceCreatePayload?: AllianceCreatePayloadResolvers<ContextType>,
  AllianceRegisterPayload?: AllianceRegisterPayloadResolvers<ContextType>,
  AllianceUser?: AllianceUserResolvers<ContextType>,
  AllianceUserConnection?: AllianceUserConnectionResolvers<ContextType>,
  AllianceUserContentHistory?: AllianceUserContentHistoryResolvers<ContextType>,
  AllianceUserContentHistoryConnection?: AllianceUserContentHistoryConnectionResolvers<ContextType>,
  AllianceUserCreatePayload?: AllianceUserCreatePayloadResolvers<ContextType>,
  AllianceUserUpdatePayload?: AllianceUserUpdatePayloadResolvers<ContextType>,
  AssignmentFileMission?: AssignmentFileMissionResolvers<ContextType>,
  AssignmentLinkMission?: AssignmentLinkMissionResolvers<ContextType>,
  Attachment?: AttachmentResolvers<ContextType>,
  AttachmentCompleteUploadPayload?: AttachmentCompleteUploadPayloadResolvers<ContextType>,
  AttachmentPrepareUploadPayload?: AttachmentPrepareUploadPayloadResolvers<ContextType>,
  AuthUser?: AuthUserResolvers<ContextType>,
  Banner?: BannerResolvers<ContextType>,
  BannerConnection?: BannerConnectionResolvers<ContextType>,
  BannerCreatePayload?: BannerCreatePayloadResolvers<ContextType>,
  BannerGroup?: BannerGroupResolvers<ContextType>,
  BannerGroupConnection?: BannerGroupConnectionResolvers<ContextType>,
  BannerGroupCreatePayload?: BannerGroupCreatePayloadResolvers<ContextType>,
  BannerGroupDeleteManyPayload?: BannerGroupDeleteManyPayloadResolvers<ContextType>,
  BannerGroupUpdatePayload?: BannerGroupUpdatePayloadResolvers<ContextType>,
  BannerUpdatePayload?: BannerUpdatePayloadResolvers<ContextType>,
  Benefit?: BenefitResolvers<ContextType>,
  Board?: BoardResolvers<ContextType>,
  Book?: BookResolvers<ContextType>,
  BookConnection?: BookConnectionResolvers<ContextType>,
  BookCreatePayload?: BookCreatePayloadResolvers<ContextType>,
  BookDeleteManyPayload?: BookDeleteManyPayloadResolvers<ContextType>,
  BookDeletePayload?: BookDeletePayloadResolvers<ContextType>,
  BookUpdatePayload?: BookUpdatePayloadResolvers<ContextType>,
  Campaign?: CampaignResolvers<ContextType>,
  CampaignBenefit?: CampaignBenefitResolvers<ContextType>,
  CampaignBenefitMutationPayload?: CampaignBenefitMutationPayloadResolvers<ContextType>,
  CampaignConnection?: CampaignConnectionResolvers<ContextType>,
  CampaignConnectionForDataExtract?: CampaignConnectionForDataExtractResolvers<ContextType>,
  CampaignCreateOrUpdatePayload?: CampaignCreateOrUpdatePayloadResolvers<ContextType>,
  CampaignData?: CampaignDataResolvers<ContextType>,
  CampaignDeletePayload?: CampaignDeletePayloadResolvers<ContextType>,
  CampaignUser?: CampaignUserResolvers<ContextType>,
  CampaignUserMutationPayload?: CampaignUserMutationPayloadResolvers<ContextType>,
  CloneExamCreatePayload?: CloneExamCreatePayloadResolvers<ContextType>,
  CommunityBoard?: CommunityBoardResolvers<ContextType>,
  CommunityPost?: CommunityPostResolvers<ContextType>,
  CommunityPostRating?: CommunityPostRatingResolvers<ContextType>,
  Company?: CompanyResolvers<ContextType>,
  CompanyConnection?: CompanyConnectionResolvers<ContextType>,
  CompanyCreatePayload?: CompanyCreatePayloadResolvers<ContextType>,
  CompanyDeleteManyPayload?: CompanyDeleteManyPayloadResolvers<ContextType>,
  CompanyDeletePayload?: CompanyDeletePayloadResolvers<ContextType>,
  CompanyUpdatePayload?: CompanyUpdatePayloadResolvers<ContextType>,
  ConnectedContent?: ConnectedContentResolvers<ContextType>,
  ConnectedContentDeletePayload?: ConnectedContentDeletePayloadResolvers<ContextType>,
  Content?: ContentResolvers<ContextType>,
  ContentCategory?: ContentCategoryResolvers<ContextType>,
  ContentCategoryParent?: ContentCategoryParentResolvers<ContextType>,
  ContentConnection?: ContentConnectionResolvers<ContextType>,
  ContentCreatePayload?: ContentCreatePayloadResolvers<ContextType>,
  ContentCuration?: ContentCurationResolvers<ContextType>,
  ContentCurationConnection?: ContentCurationConnectionResolvers<ContextType>,
  ContentCurationCreatePayload?: ContentCurationCreatePayloadResolvers<ContextType>,
  ContentCurationDeleteManyPayload?: ContentCurationDeleteManyPayloadResolvers<ContextType>,
  ContentCurationDeletePayload?: ContentCurationDeletePayloadResolvers<ContextType>,
  ContentCurationUpdatePayload?: ContentCurationUpdatePayloadResolvers<ContextType>,
  ContentDeleteManyPayload?: ContentDeleteManyPayloadResolvers<ContextType>,
  ContentDeletePayload?: ContentDeletePayloadResolvers<ContextType>,
  ContentPurchase?: ContentPurchaseResolvers<ContextType>,
  ContentPurchaseConnection?: ContentPurchaseConnectionResolvers<ContextType>,
  ContentPurchaseDetail?: ContentPurchaseDetailResolvers,
  ContentPurchaseRank?: ContentPurchaseRankResolvers<ContextType>,
  ContentPurchaseRankGroup?: ContentPurchaseRankGroupResolvers<ContextType>,
  ContentPurchaseRankGroupConnection?: ContentPurchaseRankGroupConnectionResolvers<ContextType>,
  ContentPurchaseRankSummary?: ContentPurchaseRankSummaryResolvers<ContextType>,
  ContentPurchaseStat?: ContentPurchaseStatResolvers<ContextType>,
  ContentPurchaseStatConnection?: ContentPurchaseStatConnectionResolvers<ContextType>,
  ContentPurchaseStatGrowth?: ContentPurchaseStatGrowthResolvers<ContextType>,
  ContentPurchaseStatGrowthSummary?: ContentPurchaseStatGrowthSummaryResolvers<ContextType>,
  ContentPurchaseStatSummary?: ContentPurchaseStatSummaryResolvers<ContextType>,
  ContentRefundDetail?: ContentRefundDetailResolvers<ContextType>,
  ContentRefundDetailSummary?: ContentRefundDetailSummaryResolvers<ContextType>,
  ContentRefundStat?: ContentRefundStatResolvers<ContextType>,
  ContentRefundStatConnection?: ContentRefundStatConnectionResolvers<ContextType>,
  ContentRefundStatGrowth?: ContentRefundStatGrowthResolvers<ContextType>,
  ContentRefundStatGrowthSummary?: ContentRefundStatGrowthSummaryResolvers<ContextType>,
  ContentRefundStatSummary?: ContentRefundStatSummaryResolvers<ContextType>,
  ContentUpdatePayload?: ContentUpdatePayloadResolvers<ContextType>,
  Coupon?: CouponResolvers<ContextType>,
  CouponConnection?: CouponConnectionResolvers<ContextType>,
  CouponCreateOrder?: CouponCreateOrderResolvers<ContextType>,
  CouponData?: CouponDataResolvers,
  CouponDeletePayload?: CouponDeletePayloadResolvers<ContextType>,
  CouponDiscount?: CouponDiscountResolvers<ContextType>,
  CouponNumber?: CouponNumberResolvers<ContextType>,
  CouponRegisterCoupon?: CouponRegisterCouponResolvers<ContextType>,
  CouponUpdatePayload?: CouponUpdatePayloadResolvers<ContextType>,
  CreateConnectedContentPayload?: CreateConnectedContentPayloadResolvers<ContextType>,
  DailyStudyTime?: DailyStudyTimeResolvers<ContextType>,
  Date?: GraphQLScalarType,
  Document?: DocumentResolvers<ContextType>,
  DocumentConnection?: DocumentConnectionResolvers<ContextType>,
  DocumentProduct?: DocumentProductResolvers<ContextType>,
  DocumentProductConnection?: DocumentProductConnectionResolvers<ContextType>,
  DuplicatedUser?: DuplicatedUserResolvers<ContextType>,
  EmailAddress?: GraphQLScalarType,
  emailSendMessageForFindIDPayload?: EmailSendMessageForFindIdPayloadResolvers<ContextType>,
  emailSendMessageForFindPWPayload?: EmailSendMessageForFindPwPayloadResolvers<ContextType>,
  Exam?: ExamResolvers<ContextType>,
  ExamAttempt?: ExamAttemptResolvers<ContextType>,
  ExamAttemptAnswer?: ExamAttemptAnswerResolvers<ContextType>,
  ExamAttemptConnection?: ExamAttemptConnectionResolvers<ContextType>,
  ExamAttemptGetOrCreatePayload?: ExamAttemptGetOrCreatePayloadResolvers<ContextType>,
  ExamAttemptQuestionTypeResult?: ExamAttemptQuestionTypeResultResolvers<ContextType>,
  ExamAttemptSectionResult?: ExamAttemptSectionResultResolvers<ContextType>,
  ExamAttemptSubmitAnswerManyPayload?: ExamAttemptSubmitAnswerManyPayloadResolvers<ContextType>,
  ExamAttemptUpdatePayload?: ExamAttemptUpdatePayloadResolvers<ContextType>,
  ExamConnection?: ExamConnectionResolvers<ContextType>,
  ExamEvent?: ExamEventResolvers<ContextType>,
  ExamMission?: ExamMissionResolvers<ContextType>,
  ExamQuestion?: ExamQuestionResolvers<ContextType>,
  ExamQuestionConnection?: ExamQuestionConnectionResolvers<ContextType>,
  ExamQuestionCreatePayload?: ExamQuestionCreatePayloadResolvers<ContextType>,
  ExamQuestionDeleteManyPayload?: ExamQuestionDeleteManyPayloadResolvers<ContextType>,
  ExamQuestionDeletePayload?: ExamQuestionDeletePayloadResolvers<ContextType>,
  ExamQuestionType?: ExamQuestionTypeResolvers<ContextType>,
  ExamQuestionTypeConnection?: ExamQuestionTypeConnectionResolvers<ContextType>,
  ExamQuestionTypeDeletePayload?: ExamQuestionTypeDeletePayloadResolvers<ContextType>,
  ExamQuestionTypeUpdatePayload?: ExamQuestionTypeUpdatePayloadResolvers<ContextType>,
  ExamQuestionUpdatePayload?: ExamQuestionUpdatePayloadResolvers<ContextType>,
  ExamSection?: ExamSectionResolvers<ContextType>,
  ExamSectionConnection?: ExamSectionConnectionResolvers<ContextType>,
  ExamSectionUpdatePayload?: ExamSectionUpdatePayloadResolvers<ContextType>,
  ExamSubject?: ExamSubjectResolvers<ContextType>,
  ExamSubjectConnection?: ExamSubjectConnectionResolvers<ContextType>,
  ExamUpdatePayload?: ExamUpdatePayloadResolvers<ContextType>,
  FailedUser?: FailedUserResolvers<ContextType>,
  File?: FileResolvers<ContextType>,
  FileUploadPayload?: FileUploadPayloadResolvers<ContextType>,
  findIDByPhoneNumberPayload?: FindIdByPhoneNumberPayloadResolvers<ContextType>,
  findPWByPhoneNumberPayload?: FindPwByPhoneNumberPayloadResolvers<ContextType>,
  Freepass?: FreepassResolvers<ContextType>,
  FreepassInvoice?: FreepassInvoiceResolvers<ContextType>,
  FreepassProduct?: FreepassProductResolvers<ContextType>,
  FreepassProductConnection?: FreepassProductConnectionResolvers<ContextType>,
  HtmlText?: HtmlTextResolvers<ContextType>,
  HtmlTextConnection?: HtmlTextConnectionResolvers<ContextType>,
  HtmlTextCreatePayload?: HtmlTextCreatePayloadResolvers<ContextType>,
  HtmlTextDeleteManyPayload?: HtmlTextDeleteManyPayloadResolvers<ContextType>,
  HtmlTextDeletePayload?: HtmlTextDeletePayloadResolvers<ContextType>,
  HtmlTextUpdatePayload?: HtmlTextUpdatePayloadResolvers<ContextType>,
  InfobankSMSSendResult?: InfobankSmsSendResultResolvers<ContextType>,
  IntegratedCommunityBoard?: IntegratedCommunityBoardResolvers<ContextType>,
  IntegratedCommunityBoardPayload?: IntegratedCommunityBoardPayloadResolvers<ContextType>,
  integratedCommunityComment?: IntegratedCommunityCommentResolvers<ContextType>,
  integratedCommunityPost?: IntegratedCommunityPostResolvers<ContextType>,
  IntegratedCommunityPostUpdatePayload?: IntegratedCommunityPostUpdatePayloadResolvers<ContextType>,
  Invoice?: InvoiceResolvers<ContextType>,
  InvoiceConnection?: InvoiceConnectionResolvers<ContextType>,
  InvoiceRefundByContent?: InvoiceRefundByContentResolvers<ContextType>,
  InvoiceSaleByContent?: InvoiceSaleByContentResolvers<ContextType>,
  Keyword?: KeywordResolvers<ContextType>,
  LectureMission?: LectureMissionResolvers<ContextType>,
  LoginPayload?: LoginPayloadResolvers<ContextType>,
  Major?: MajorResolvers<ContextType>,
  MajorConnection?: MajorConnectionResolvers<ContextType>,
  Membership?: MembershipResolvers<ContextType>,
  MembershipConnection?: MembershipConnectionResolvers<ContextType>,
  MembershipCreatePayload?: MembershipCreatePayloadResolvers<ContextType>,
  MembershipDeleteManyPayload?: MembershipDeleteManyPayloadResolvers<ContextType>,
  MembershipDeletePayload?: MembershipDeletePayloadResolvers<ContextType>,
  MembershipUpdatePayload?: MembershipUpdatePayloadResolvers<ContextType>,
  Menu?: MenuResolvers<ContextType>,
  MenuConnection?: MenuConnectionResolvers<ContextType>,
  MenuCreatePayload?: MenuCreatePayloadResolvers<ContextType>,
  MenuDeleteManyPayload?: MenuDeleteManyPayloadResolvers<ContextType>,
  MenuDeletePayload?: MenuDeletePayloadResolvers<ContextType>,
  MenuItem?: MenuItemResolvers<ContextType>,
  MenuUpdatePayload?: MenuUpdatePayloadResolvers<ContextType>,
  MissionDetails?: MissionDetailsResolvers,
  Mutation?: MutationResolvers<ContextType>,
  NativeRegisterPayload?: NativeRegisterPayloadResolvers<ContextType>,
  NotificationSMS?: NotificationSmsResolvers<ContextType>,
  NotificationSMSSet?: NotificationSmsSetResolvers<ContextType>,
  Offline?: OfflineResolvers<ContextType>,
  OfflineChildrenContentsUpdatePayload?: OfflineChildrenContentsUpdatePayloadResolvers<ContextType>,
  OfflineConnection?: OfflineConnectionResolvers<ContextType>,
  OfflineCreatePayload?: OfflineCreatePayloadResolvers<ContextType>,
  OfflineRepurchaseDiscount?: OfflineRepurchaseDiscountResolvers<ContextType>,
  OfflineRepurchaseDiscountUpdatePayload?: OfflineRepurchaseDiscountUpdatePayloadResolvers<ContextType>,
  offlineSendDailyMessagePayload?: OfflineSendDailyMessagePayloadResolvers<ContextType>,
  OfflineStudentClassChangePayload?: OfflineStudentClassChangePayloadResolvers<ContextType>,
  OfflineUpdatePayload?: OfflineUpdatePayloadResolvers<ContextType>,
  Order?: OrderResolvers<ContextType>,
  OrderConnection?: OrderConnectionResolvers<ContextType>,
  Package?: PackageResolvers<ContextType>,
  PackageConnection?: PackageConnectionResolvers<ContextType>,
  PaybackSet?: PaybackSetResolvers<ContextType>,
  Payment?: PaymentResolvers<ContextType>,
  PaymentConnection?: PaymentConnectionResolvers<ContextType>,
  Permission?: PermissionResolvers<ContextType>,
  PermissionConnection?: PermissionConnectionResolvers<ContextType>,
  PermissionGrantPayload?: PermissionGrantPayloadResolvers<ContextType>,
  PermissionRevokePayload?: PermissionRevokePayloadResolvers<ContextType>,
  PhoneNumber?: GraphQLScalarType,
  phoneSendMessageForFindIDPayload?: PhoneSendMessageForFindIdPayloadResolvers<ContextType>,
  phoneSendMessageForFindPWPayload?: PhoneSendMessageForFindPwPayloadResolvers<ContextType>,
  Post?: PostResolvers<ContextType>,
  PostCuration?: PostCurationResolvers<ContextType>,
  PostCurationConfig?: PostCurationConfigResolvers<ContextType>,
  PostCurationConnection?: PostCurationConnectionResolvers<ContextType>,
  PostCurationUpdatePayload?: PostCurationUpdatePayloadResolvers<ContextType>,
  PrevScoreForPass?: PrevScoreForPassResolvers<ContextType>,
  PrintLog?: PrintLogResolvers<ContextType>,
  ProvideCampaignBenefitPayload?: ProvideCampaignBenefitPayloadResolvers<ContextType>,
  provideFreeContentForUsersPayload?: ProvideFreeContentForUsersPayloadResolvers<ContextType>,
  ProvideFreeContentPayload?: ProvideFreeContentPayloadResolvers<ContextType>,
  ProvideResultProps?: ProvideResultPropsResolvers<ContextType>,
  Query?: QueryResolvers<ContextType>,
  Question?: QuestionResolvers<ContextType>,
  RecoveryPasswordAuth?: RecoveryPasswordAuthResolvers<ContextType>,
  Refund?: RefundResolvers<ContextType>,
  RefundConnection?: RefundConnectionResolvers<ContextType>,
  RefundCreatePayload?: RefundCreatePayloadResolvers<ContextType>,
  Resume?: ResumeResolvers<ContextType>,
  ResumeConnection?: ResumeConnectionResolvers<ContextType>,
  ResumeCreatePayload?: ResumeCreatePayloadResolvers<ContextType>,
  ResumeProduct?: ResumeProductResolvers<ContextType>,
  ResumeProductConnection?: ResumeProductConnectionResolvers<ContextType>,
  ResumeProductCreatePayload?: ResumeProductCreatePayloadResolvers<ContextType>,
  ResumeProductDeleteManyPayload?: ResumeProductDeleteManyPayloadResolvers<ContextType>,
  ResumeProductDeletePayload?: ResumeProductDeletePayloadResolvers<ContextType>,
  ResumeProductOption?: ResumeProductOptionResolvers<ContextType>,
  ResumeProductUpdatePayload?: ResumeProductUpdatePayloadResolvers<ContextType>,
  ResumeQnA?: ResumeQnAResolvers<ContextType>,
  ResumeUpdatePayload?: ResumeUpdatePayloadResolvers<ContextType>,
  Review?: ReviewResolvers<ContextType>,
  ReviewConnection?: ReviewConnectionResolvers<ContextType>,
  ReviewCreatePayload?: ReviewCreatePayloadResolvers<ContextType>,
  ReviewDeleteManyPayload?: ReviewDeleteManyPayloadResolvers<ContextType>,
  ReviewDeletePayload?: ReviewDeletePayloadResolvers<ContextType>,
  ReviewMission?: ReviewMissionResolvers<ContextType>,
  ReviewUpdatePayload?: ReviewUpdatePayloadResolvers<ContextType>,
  Schedule?: ScheduleResolvers<ContextType>,
  ScheduleConnection?: ScheduleConnectionResolvers<ContextType>,
  ScheduleCreatePayload?: ScheduleCreatePayloadResolvers<ContextType>,
  ScheduleDeleteManyPayload?: ScheduleDeleteManyPayloadResolvers<ContextType>,
  ScheduleDeletePayload?: ScheduleDeletePayloadResolvers<ContextType>,
  ScheduleEvent?: ScheduleEventResolvers<ContextType>,
  ScheduleEventConnection?: ScheduleEventConnectionResolvers<ContextType>,
  ScheduleEventCreatePayload?: ScheduleEventCreatePayloadResolvers<ContextType>,
  ScheduleEventDeleteManyPayload?: ScheduleEventDeleteManyPayloadResolvers<ContextType>,
  ScheduleEventDeletePayload?: ScheduleEventDeletePayloadResolvers<ContextType>,
  ScheduleEventUpdatePayload?: ScheduleEventUpdatePayloadResolvers<ContextType>,
  ScheduleUpdateLambdaHandlerPayload?: ScheduleUpdateLambdaHandlerPayloadResolvers<ContextType>,
  ScheduleUpdatePayload?: ScheduleUpdatePayloadResolvers<ContextType>,
  School?: SchoolResolvers<ContextType>,
  SchoolConnection?: SchoolConnectionResolvers<ContextType>,
  ServicePurchaseStat?: ServicePurchaseStatResolvers<ContextType>,
  ServicePurchaseStatConnection?: ServicePurchaseStatConnectionResolvers<ContextType>,
  ServicePurchaseStatDetail?: ServicePurchaseStatDetailResolvers<ContextType>,
  SmsReject?: SmsRejectResolvers<ContextType>,
  SNSRegisterPayload?: SnsRegisterPayloadResolvers<ContextType>,
  Study?: StudyResolvers<ContextType>,
  StudyBenefit?: StudyBenefitResolvers<ContextType>,
  StudyBenefitCreatePayload?: StudyBenefitCreatePayloadResolvers<ContextType>,
  StudyBenefitDeletePayload?: StudyBenefitDeletePayloadResolvers<ContextType>,
  StudyBenefitUpdatePayload?: StudyBenefitUpdatePayloadResolvers<ContextType>,
  StudyBrazeEventProperties?: StudyBrazeEventPropertiesResolvers<ContextType>,
  StudyConnection?: StudyConnectionResolvers<ContextType>,
  StudyCreatePayload?: StudyCreatePayloadResolvers<ContextType>,
  StudyDeleteManyPayload?: StudyDeleteManyPayloadResolvers<ContextType>,
  StudyDeletePayload?: StudyDeletePayloadResolvers<ContextType>,
  StudyEvent?: StudyEventResolvers<ContextType>,
  StudyEventBulkCreatePayload?: StudyEventBulkCreatePayloadResolvers<ContextType>,
  StudyEventConnection?: StudyEventConnectionResolvers<ContextType>,
  StudyEventCreatePayload?: StudyEventCreatePayloadResolvers<ContextType>,
  StudyMission?: StudyMissionResolvers<ContextType>,
  StudyMissionConnection?: StudyMissionConnectionResolvers<ContextType>,
  StudyMissionCreatePayload?: StudyMissionCreatePayloadResolvers<ContextType>,
  StudyMissionDeleteManyPayload?: StudyMissionDeleteManyPayloadResolvers<ContextType>,
  StudyMissionDeletePayload?: StudyMissionDeletePayloadResolvers<ContextType>,
  StudyMissionUpdatePayload?: StudyMissionUpdatePayloadResolvers<ContextType>,
  StudyUpdatePayload?: StudyUpdatePayloadResolvers<ContextType>,
  StudyUserContent?: StudyUserContentResolvers<ContextType>,
  SupportTicket?: SupportTicketResolvers<ContextType>,
  SupportTicketAdminCreatePayload?: SupportTicketAdminCreatePayloadResolvers<ContextType>,
  SupportTicketAdminUpdatePayload?: SupportTicketAdminUpdatePayloadResolvers<ContextType>,
  SupportTicketAnswerTemplate?: SupportTicketAnswerTemplateResolvers<ContextType>,
  SupportTicketAnswerTemplateConnection?: SupportTicketAnswerTemplateConnectionResolvers<ContextType>,
  SupportTicketAnswerTemplateCreatePayload?: SupportTicketAnswerTemplateCreatePayloadResolvers<ContextType>,
  SupportTicketAnswerTemplateUpdatePayload?: SupportTicketAnswerTemplateUpdatePayloadResolvers<ContextType>,
  SupportTicketCategory?: SupportTicketCategoryResolvers<ContextType>,
  SupportTicketCategoryConnection?: SupportTicketCategoryConnectionResolvers<ContextType>,
  SupportTicketCategoryCreatePayload?: SupportTicketCategoryCreatePayloadResolvers<ContextType>,
  SupportTicketCategoryServiceMap?: SupportTicketCategoryServiceMapResolvers<ContextType>,
  SupportTicketCategoryServiceMapConnection?: SupportTicketCategoryServiceMapConnectionResolvers<ContextType>,
  SupportTicketCategoryUpdatePayload?: SupportTicketCategoryUpdatePayloadResolvers<ContextType>,
  SupportTicketConnection?: SupportTicketConnectionResolvers<ContextType>,
  SupportTicketCreatePayload?: SupportTicketCreatePayloadResolvers<ContextType>,
  SupportTicketUpdatePayload?: SupportTicketUpdatePayloadResolvers<ContextType>,
  Teacher?: TeacherResolvers<ContextType>,
  TeacherCommissions?: TeacherCommissionsResolvers<ContextType>,
  TeacherConnection?: TeacherConnectionResolvers<ContextType>,
  TeacherCreatePayload?: TeacherCreatePayloadResolvers<ContextType>,
  TeacherCuration?: TeacherCurationResolvers<ContextType>,
  TeacherCurationConnection?: TeacherCurationConnectionResolvers<ContextType>,
  TeacherCurationCreatePayload?: TeacherCurationCreatePayloadResolvers<ContextType>,
  TeacherCurationDeleteManyPayload?: TeacherCurationDeleteManyPayloadResolvers<ContextType>,
  TeacherCurationDeletePayload?: TeacherCurationDeletePayloadResolvers<ContextType>,
  TeacherCurationUpdatePayload?: TeacherCurationUpdatePayloadResolvers<ContextType>,
  TeacherDeletePayload?: TeacherDeletePayloadResolvers<ContextType>,
  TeacherUpdatePayload?: TeacherUpdatePayloadResolvers<ContextType>,
  Team?: TeamResolvers<ContextType>,
  TeamConnection?: TeamConnectionResolvers<ContextType>,
  TeamCreatePayload?: TeamCreatePayloadResolvers<ContextType>,
  TeamDeleteManyPayload?: TeamDeleteManyPayloadResolvers<ContextType>,
  TeamDeletePayload?: TeamDeletePayloadResolvers<ContextType>,
  TeamUpdatePayload?: TeamUpdatePayloadResolvers<ContextType>,
  TempAccount?: TempAccountResolvers<ContextType>,
  TempAccountLinkToUserPayload?: TempAccountLinkToUserPayloadResolvers<ContextType>,
  TempAccountSignupPayload?: TempAccountSignupPayloadResolvers<ContextType>,
  Time?: GraphQLScalarType,
  UpdatePWByUsernamePayload?: UpdatePwByUsernamePayloadResolvers<ContextType>,
  UpdatePWPayload?: UpdatePwPayloadResolvers<ContextType>,
  Upload?: GraphQLScalarType,
  User?: UserResolvers<ContextType>,
  UserAllianceStat?: UserAllianceStatResolvers<ContextType>,
  UserAllianceStatConnection?: UserAllianceStatConnectionResolvers<ContextType>,
  UserConnection?: UserConnectionResolvers<ContextType>,
  UserDevice?: UserDeviceResolvers<ContextType>,
  UserDeviceConnection?: UserDeviceConnectionResolvers<ContextType>,
  UserDeviceDeletePayload?: UserDeviceDeletePayloadResolvers<ContextType>,
  UserInfoUpdatePayload?: UserInfoUpdatePayloadResolvers<ContextType>,
  UserLeaveLog?: UserLeaveLogResolvers<ContextType>,
  UserLeavePayload?: UserLeavePayloadResolvers<ContextType>,
  UserMajor?: UserMajorResolvers<ContextType>,
  UserPoint?: UserPointResolvers<ContextType>,
  UserPointConnection?: UserPointConnectionResolvers<ContextType>,
  UserPointCreatePayload?: UserPointCreatePayloadResolvers<ContextType>,
  UserPointDeleteManyPayload?: UserPointDeleteManyPayloadResolvers<ContextType>,
  UserPointDeletePayload?: UserPointDeletePayloadResolvers<ContextType>,
  UserPointUpdatePayload?: UserPointUpdatePayloadResolvers<ContextType>,
  UserRegisterStat?: UserRegisterStatResolvers<ContextType>,
  UserRegisterStatConnection?: UserRegisterStatConnectionResolvers<ContextType>,
  UserRegisterStatGrowthRate?: UserRegisterStatGrowthRateResolvers<ContextType>,
  UserRegisterStatGrowthRateSummary?: UserRegisterStatGrowthRateSummaryResolvers<ContextType>,
  UserRegisterStatSummary?: UserRegisterStatSummaryResolvers<ContextType>,
  UserSchool?: UserSchoolResolvers<ContextType>,
  UserSchoolConnection?: UserSchoolConnectionResolvers<ContextType>,
  UserSchoolMajor?: UserSchoolMajorResolvers<ContextType>,
  UserSchoolMajorConnection?: UserSchoolMajorConnectionResolvers<ContextType>,
  UserSignupStatConnection?: UserSignupStatConnectionResolvers<ContextType>,
  UserStudy?: UserStudyResolvers<ContextType>,
  UserStudyBenefitCreatePayload?: UserStudyBenefitCreatePayloadResolvers<ContextType>,
  UserStudyConnection?: UserStudyConnectionResolvers<ContextType>,
  UserStudyEvent?: UserStudyEventResolvers<ContextType>,
  UserStudyEventConnection?: UserStudyEventConnectionResolvers<ContextType>,
  UserStudyEventFailPayload?: UserStudyEventFailPayloadResolvers<ContextType>,
  UserStudyEventForcePassPayload?: UserStudyEventForcePassPayloadResolvers<ContextType>,
  UserStudyEventPassPayload?: UserStudyEventPassPayloadResolvers<ContextType>,
  UserStudyMission?: UserStudyMissionResolvers<ContextType>,
  UserStudyMissionAssignmentFile?: UserStudyMissionAssignmentFileResolvers<ContextType>,
  UserStudyMissionAssignmentFileUploadPayload?: UserStudyMissionAssignmentFileUploadPayloadResolvers<ContextType>,
  UserStudyMissionAssignmentLink?: UserStudyMissionAssignmentLinkResolvers<ContextType>,
  UserStudyMissionAssignmentLinkUploadPayload?: UserStudyMissionAssignmentLinkUploadPayloadResolvers<ContextType>,
  UserStudyMissionConnection?: UserStudyMissionConnectionResolvers<ContextType>,
  UserStudyMissionDetail?: UserStudyMissionDetailResolvers,
  UserStudyMissionExam?: UserStudyMissionExamResolvers<ContextType>,
  UserStudyMissionLecture?: UserStudyMissionLectureResolvers<ContextType>,
  UserStudyMissionReview?: UserStudyMissionReviewResolvers<ContextType>,
  UserStudyMissionReviewUploadPayload?: UserStudyMissionReviewUploadPayloadResolvers<ContextType>,
  UserStudyMissionsAndContents?: UserStudyMissionsAndContentsResolvers<ContextType>,
  UserStudyMissionsUpdatePayload?: UserStudyMissionsUpdatePayloadResolvers<ContextType>,
  UserStudyMissionUpdatePayload?: UserStudyMissionUpdatePayloadResolvers<ContextType>,
  UserStudyRegisterPayload?: UserStudyRegisterPayloadResolvers<ContextType>,
  UserVisitStatConnection?: UserVisitStatConnectionResolvers<ContextType>,
  Video?: VideoResolvers<ContextType>,
  VideoConnection?: VideoConnectionResolvers<ContextType>,
  VideoLesson?: VideoLessonResolvers<ContextType>,
  VideoLessonPlayLog?: VideoLessonPlayLogResolvers<ContextType>,
  VideoLessonProduct?: VideoLessonProductResolvers<ContextType>,
  VideoLessonProductConnection?: VideoLessonProductConnectionResolvers<ContextType>,
  VideoProduct?: VideoProductResolvers<ContextType>,
  VideoProductConnection?: VideoProductConnectionResolvers<ContextType>,
  VideoProductCreatePayload?: VideoProductCreatePayloadResolvers<ContextType>,
  VideoProductDeleteManyPayload?: VideoProductDeleteManyPayloadResolvers<ContextType>,
  VideoProductDeletePayload?: VideoProductDeletePayloadResolvers<ContextType>,
  VideoProductUpdatePayload?: VideoProductUpdatePayloadResolvers<ContextType>,
  VideoUpdatePayload?: VideoUpdatePayloadResolvers<ContextType>,
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
*/
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
